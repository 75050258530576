import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "color-picker";
var attributes = {
    VALUE: 'value',
    ALLOW_OPACITY: 'allow-opacity'
};
var classes = {
    SLIDER_THUMB_ACTIVE: 'tyl-color-picker__slider-thumb--active',
    GRADIENT_THUMB_ACTIVE: 'tyl-color-picker__gradient-thumb--active'
};
var selectors = {
    GRADIENT: '.tyl-color-picker__gradient',
    GRADIENT_THUMB: '.tyl-color-picker__gradient-thumb',
    PREVIEW_COLOR: '.tyl-color-picker__color-preview',
    HEX_INPUT: '#tyl-color-picker-hex-input',
    RGBA_INPUT: '#tyl-color-picker-rgba-input',
    HSVA_INPUT: '#tyl-color-picker-hsva-input',
    HUE_SLIDER: '.tyl-color-picker__hue-slider',
    HUE_SLIDER_THUMB: '#tyl-color-picker-hue-thumb',
    OPACITY_SLIDER: '.tyl-color-picker__opacity-slider',
    OPACITY_SLIDER_THUMB: '#tyl-color-picker-opacity-thumb',
    SLIDER: '.tyl-color-picker__slider',
    SLIDER_THUMB: '.tyl-color-picker__slider-thumb',
    COLOR_VALUE_HEX_CONTAINER: '.tyl-color-picker__color-hex',
    COLOR_VALUE_RGBA_CONTAINER: '.tyl-color-picker__color-rgba',
    COLOR_VALUE_HSVA_CONTAINER: '.tyl-color-picker__color-hsva',
    TYPE_BUTTON: '#tyl-color-picker-type-button',
    COLOR_VALUE_RGBA_R: '#tyl-color-picker-rgba-r-input',
    COLOR_VALUE_RGBA_G: '#tyl-color-picker-rgba-g-input',
    COLOR_VALUE_RGBA_B: '#tyl-color-picker-rgba-b-input',
    COLOR_VALUE_RGBA_A: '#tyl-color-picker-rgba-a-input',
    COLOR_VALUE_HSVA_H: '#tyl-color-picker-hsva-h-input',
    COLOR_VALUE_HSVA_S: '#tyl-color-picker-hsva-s-input',
    COLOR_VALUE_HSVA_V: '#tyl-color-picker-hsva-v-input',
    COLOR_VALUE_HSVA_A: '#tyl-color-picker-hsva-a-input'
};
var events = {
    CHANGE: elementName + "-change"
};
export var COLOR_PICKER_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    events: events,
    classes: classes,
    selectors: selectors
};
export var DEFAULT_COLOR = '000000';
export var ColorPickerValueType;
(function (ColorPickerValueType) {
    ColorPickerValueType["HEX"] = "hex";
    ColorPickerValueType["RGB"] = "rgb";
    ColorPickerValueType["RGBA"] = "rgba";
    ColorPickerValueType["HSV"] = "hsv";
    ColorPickerValueType["HSVA"] = "hsva";
})(ColorPickerValueType || (ColorPickerValueType = {}));
