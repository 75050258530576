import { __decorate, __extends } from "tslib";
import { CustomElement, attachLightTemplate, upgradeProperty } from '@tyler-components-web/core';
import { MenuComponent } from '../../menu';
import { OmnibarHelpButtonAdapter } from './omnibar-help-button-adapter';
import { OmnibarHelpButtonFoundation } from './omnibar-help-button-foundation';
import { OMNIBAR_HELP_BUTTON_CONSTANTS } from './omnibar-help-button-constants';
import { IconButtonComponent } from '../../icon-button';
import { TooltipComponent } from '../../tooltip';
var template = require('./omnibar-help-button.html');
var OmnibarHelpButtonComponent = /** @class */ (function (_super) {
    __extends(OmnibarHelpButtonComponent, _super);
    function OmnibarHelpButtonComponent() {
        var _this = _super.call(this) || this;
        _this._foundation = new OmnibarHelpButtonFoundation(new OmnibarHelpButtonAdapter(_this));
        return _this;
    }
    OmnibarHelpButtonComponent.prototype.initializedCallback = function () {
        attachLightTemplate(this, template);
    };
    OmnibarHelpButtonComponent.prototype.connectedCallback = function () {
        upgradeProperty(this, 'options');
        this._foundation.initialize();
    };
    OmnibarHelpButtonComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    Object.defineProperty(OmnibarHelpButtonComponent.prototype, "options", {
        set: function (value) {
            this._foundation.options = value;
        },
        enumerable: true,
        configurable: true
    });
    OmnibarHelpButtonComponent = __decorate([
        CustomElement({
            name: OMNIBAR_HELP_BUTTON_CONSTANTS.elementName,
            dependencies: [
                MenuComponent,
                IconButtonComponent,
                TooltipComponent
            ]
        })
    ], OmnibarHelpButtonComponent);
    return OmnibarHelpButtonComponent;
}(HTMLElement));
export { OmnibarHelpButtonComponent };
