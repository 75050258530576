import { getShadowElement, emitEvent, removeAllChildren, toggleElementPlaceholder } from '@tyler-components-web/core';
import { PAGINATOR_CONSTANTS } from './paginator-constants';
/**
 * Provides facilities for interacting with the internal DOM of `PaginatorComponent`.
 */
var PaginatorAdapter = /** @class */ (function () {
    function PaginatorAdapter(_component) {
        this._component = _component;
        this._labelElement = getShadowElement(_component, PAGINATOR_CONSTANTS.selectors.LABEL);
        this._pageSizeSelect = getShadowElement(_component, PAGINATOR_CONSTANTS.selectors.PAGE_SIZE_SELECT);
        this._rangeLabel = getShadowElement(_component, PAGINATOR_CONSTANTS.selectors.RANGE_LABEL);
        this._firstPageButton = getShadowElement(_component, PAGINATOR_CONSTANTS.selectors.FIRST_PAGE_BUTTON);
        this._firstPageIconButton = getShadowElement(_component, PAGINATOR_CONSTANTS.selectors.FIRST_PAGE_ICON_BUTTON);
        this._previousPageButton = getShadowElement(_component, PAGINATOR_CONSTANTS.selectors.PREVIOUS_PAGE_BUTTON);
        this._nextPageButton = getShadowElement(_component, PAGINATOR_CONSTANTS.selectors.NEXT_PAGE_BUTTON);
        this._lastPageButton = getShadowElement(_component, PAGINATOR_CONSTANTS.selectors.LAST_PAGE_BUTTON);
        this._lastPageIconButton = getShadowElement(_component, PAGINATOR_CONSTANTS.selectors.LAST_PAGE_ICON_BUTTON);
    }
    PaginatorAdapter.prototype.setHostAttribute = function (name, value) {
        if (value === void 0) { value = ''; }
        this._component.setAttribute(name, value);
    };
    PaginatorAdapter.prototype.removeHostAttribute = function (name) {
        this._component.removeAttribute(name);
    };
    PaginatorAdapter.prototype.emitEvent = function (type, data) {
        emitEvent(this._component, type, data);
    };
    PaginatorAdapter.prototype.setLabel = function (value) {
        this._labelElement.textContent = value;
    };
    PaginatorAdapter.prototype.setPageSizeOptions = function (options) {
        removeAllChildren(this._pageSizeSelect);
        this._pageSizeSelect.options = options;
    };
    PaginatorAdapter.prototype.setPageSize = function (value) {
        this._pageSizeSelect.value = value.toString();
    };
    PaginatorAdapter.prototype.setRangeLabel = function (value) {
        this._rangeLabel.innerText = value;
    };
    PaginatorAdapter.prototype.hasFirstPageButton = function () {
        return !!getShadowElement(this._component, PAGINATOR_CONSTANTS.selectors.FIRST_PAGE_ICON_BUTTON);
    };
    PaginatorAdapter.prototype.showFirstPageButton = function () {
        this._firstPagePlaceholder = toggleElementPlaceholder(this._component, true, PAGINATOR_CONSTANTS.elementName, PAGINATOR_CONSTANTS.selectors.FIRST_PAGE_ICON_BUTTON, this._firstPageIconButton, this._firstPagePlaceholder);
    };
    PaginatorAdapter.prototype.hideFirstPageButton = function () {
        this._firstPagePlaceholder = toggleElementPlaceholder(this._component, false, PAGINATOR_CONSTANTS.elementName, PAGINATOR_CONSTANTS.selectors.FIRST_PAGE_ICON_BUTTON, this._firstPageIconButton, this._firstPagePlaceholder);
    };
    PaginatorAdapter.prototype.hasLastPageButton = function () {
        return !!getShadowElement(this._component, PAGINATOR_CONSTANTS.selectors.LAST_PAGE_ICON_BUTTON);
    };
    PaginatorAdapter.prototype.showLastPageButton = function () {
        this._lastPagePlaceholder = toggleElementPlaceholder(this._component, true, PAGINATOR_CONSTANTS.elementName, PAGINATOR_CONSTANTS.selectors.LAST_PAGE_ICON_BUTTON, this._lastPageIconButton, this._lastPagePlaceholder);
    };
    PaginatorAdapter.prototype.hideLastPageButton = function () {
        this._lastPagePlaceholder = toggleElementPlaceholder(this._component, false, PAGINATOR_CONSTANTS.elementName, PAGINATOR_CONSTANTS.selectors.LAST_PAGE_ICON_BUTTON, this._lastPageIconButton, this._lastPagePlaceholder);
    };
    PaginatorAdapter.prototype.attachPageSizeChangeListener = function (listener) {
        this._pageSizeSelect.addEventListener('change', listener);
    };
    PaginatorAdapter.prototype.attachFirstPageListener = function (listener) {
        this._firstPageButton.addEventListener('click', listener);
    };
    PaginatorAdapter.prototype.attachPreviousPageListener = function (listener) {
        this._previousPageButton.addEventListener('click', listener);
    };
    PaginatorAdapter.prototype.attachNextPageListener = function (listener) {
        this._nextPageButton.addEventListener('click', listener);
    };
    PaginatorAdapter.prototype.attachLastPageListener = function (listener) {
        this._lastPageButton.addEventListener('click', listener);
    };
    PaginatorAdapter.prototype.detachPageSizeChangeListener = function (listener) {
        this._pageSizeSelect.removeEventListener('change', listener);
    };
    PaginatorAdapter.prototype.detachFirstPageListener = function (listener) {
        this._firstPageButton.removeEventListener('click', listener);
    };
    PaginatorAdapter.prototype.detachPreviousPageListener = function (listener) {
        this._previousPageButton.removeEventListener('click', listener);
    };
    PaginatorAdapter.prototype.detachNextPageListener = function (listener) {
        this._nextPageButton.removeEventListener('click', listener);
    };
    PaginatorAdapter.prototype.detachLastPageListener = function (listener) {
        this._lastPageButton.removeEventListener('click', listener);
    };
    PaginatorAdapter.prototype.disableFirstPageButton = function () {
        this._firstPageButton.setAttribute('disabled', 'disabled');
    };
    PaginatorAdapter.prototype.enableFirstPageButton = function () {
        this._firstPageButton.removeAttribute('disabled');
    };
    PaginatorAdapter.prototype.disablePreviousPageButton = function () {
        this._previousPageButton.setAttribute('disabled', 'disabled');
    };
    PaginatorAdapter.prototype.enablePreviousPageButton = function () {
        this._previousPageButton.removeAttribute('disabled');
    };
    PaginatorAdapter.prototype.disableNextPageButton = function () {
        this._nextPageButton.setAttribute('disabled', 'disabled');
    };
    PaginatorAdapter.prototype.enableNextPageButton = function () {
        this._nextPageButton.removeAttribute('disabled');
    };
    PaginatorAdapter.prototype.disablePageSizeSelect = function () {
        this._pageSizeSelect.setAttribute('disabled', 'disabled');
    };
    PaginatorAdapter.prototype.enablePageSizeSelect = function () {
        this._pageSizeSelect.removeAttribute('disabled');
    };
    PaginatorAdapter.prototype.setPageSizeVisibility = function (visible) {
        visible ? this._pageSizeSelect.style.removeProperty('display') : this._pageSizeSelect.style.display = 'none';
    };
    PaginatorAdapter.prototype.disableLastPageButton = function () {
        this._lastPageButton.setAttribute('disabled', 'disabled');
    };
    PaginatorAdapter.prototype.enableLastPageButton = function () {
        this._lastPageButton.removeAttribute('disabled');
    };
    return PaginatorAdapter;
}());
export { PaginatorAdapter };
