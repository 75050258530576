import { __spreadArrays } from "tslib";
import { MDCNotchedOutline } from '@material/notched-outline';
import { MDCFloatingLabel } from '@material/floating-label';
import { getShadowElement, emitEvent, removeElement, calculateFontWidth } from '@tyler-components-web/core';
import { SELECT_CONSTANTS } from './select-constants';
import { OPTION_CONSTANTS } from '../option';
import { PopupPlacement, POPUP_CONSTANTS, PopupAnimationType } from '../../popup';
import { LIST_CONSTANTS, LIST_ITEM_CONSTANTS } from '../../list';
import { isOptionType, OptionType } from './select-utils';
import { ICON_CLASS_NAME } from '../../constants';
import { randomChars } from '../../core/utils';
/**
 * The DOM adapter behind the `<tcw-select>` component.
 */
var SelectAdapter = /** @class */ (function () {
    function SelectAdapter(_component) {
        this._component = _component;
        this._selectElement = getShadowElement(_component, SELECT_CONSTANTS.selectors.ROOT);
        this._notchedOutlineElement = getShadowElement(_component, SELECT_CONSTANTS.selectors.NOTCHED_OUTLINE);
        this._selectedTextElement = getShadowElement(_component, SELECT_CONSTANTS.selectors.SELECTED_TEXT);
        this._leadingSlot = getShadowElement(_component, SELECT_CONSTANTS.selectors.LEADING_SLOT);
        this._identifier = randomChars();
    }
    Object.defineProperty(SelectAdapter.prototype, "popupElement", {
        get: function () {
            return this._popupElement;
        },
        enumerable: true,
        configurable: true
    });
    SelectAdapter.prototype.setHostAttribute = function (name, value) {
        if (value === void 0) { value = ''; }
        this._component.setAttribute(name, value);
    };
    SelectAdapter.prototype.removeHostAttribute = function (name) {
        this._component.removeAttribute(name);
    };
    SelectAdapter.prototype.setFocus = function () {
        this._selectedTextElement.focus();
    };
    SelectAdapter.prototype.initializeLabel = function () {
        this._labelElement = getShadowElement(this._component, SELECT_CONSTANTS.selectors.LABEL);
    };
    SelectAdapter.prototype.hasLabel = function () {
        return !!this._labelElement;
    };
    SelectAdapter.prototype.initializeNotchedOutline = function () {
        if (!this._notchedOutlineElement) {
            return;
        }
        return new MDCNotchedOutline(this._notchedOutlineElement);
    };
    SelectAdapter.prototype.initializeFloatingLabel = function () {
        return new MDCFloatingLabel(this._labelElement);
    };
    SelectAdapter.prototype.setLabel = function (value) {
        if (!this._component.hasAttribute('aria-label') || this._component.getAttribute('aria-label') === this._labelElement.textContent) {
            this._component.setAttribute('aria-label', value);
        }
        this._labelElement.textContent = value;
    };
    SelectAdapter.prototype.addClass = function (name) {
        this._selectElement.classList.add(name);
    };
    SelectAdapter.prototype.removeClass = function (name) {
        this._selectElement.classList.remove(name);
    };
    SelectAdapter.prototype.setPlaceholderText = function (value) {
        if (value) {
            this._selectedTextElement.setAttribute('placeholder', value);
        }
        else {
            this._selectedTextElement.removeAttribute('placeholder');
        }
    };
    SelectAdapter.prototype.addRootInteractionListener = function (type, listener) {
        this._selectElement.addEventListener(type, listener);
    };
    SelectAdapter.prototype.removeRootInteractionListener = function (type, listener) {
        this._selectElement.removeEventListener(type, listener);
    };
    SelectAdapter.prototype.addInteractionListener = function (type, listener) {
        this._selectedTextElement.addEventListener(type, listener);
    };
    SelectAdapter.prototype.removeInteractionListener = function (type, listener) {
        this._selectedTextElement.removeEventListener(type, listener);
    };
    SelectAdapter.prototype.getOptions = function () {
        var optionElements = Array.from(this._component.querySelectorAll(OPTION_CONSTANTS.elementName));
        return optionElements.map(function (o) {
            return {
                label: o.innerText,
                value: o.hasAttribute(OPTION_CONSTANTS.attributes.VALUE) ? o.getAttribute(OPTION_CONSTANTS.attributes.VALUE) : o.value,
                disabled: o.hasAttribute(OPTION_CONSTANTS.attributes.DISABLED)
            };
        });
    };
    SelectAdapter.prototype.setOptions = function (options) {
        // Ensure the options are provided in the form a group
        var groups = isOptionType(options, OptionType.Group) ? options : [{ text: '', options: options }];
        var existingOptionElements = Array.from(this._component.querySelectorAll(OPTION_CONSTANTS.elementName));
        existingOptionElements.forEach(function (o) { return removeElement(o); });
        for (var _i = 0, groups_1 = groups; _i < groups_1.length; _i++) {
            var group = groups_1[_i];
            // We don't render headers that don't have text
            if (group.text) {
                var listHeaderElement = document.createElement('h4');
                listHeaderElement.textContent = group.text;
                listHeaderElement.style.margin = '0 8px';
                this._component.appendChild(listHeaderElement);
            }
            for (var _a = 0, _b = group.options; _a < _b.length; _a++) {
                var option = _b[_a];
                var optionElement = document.createElement(OPTION_CONSTANTS.elementName);
                optionElement.value = option.value;
                optionElement.textContent = option.label;
                if (option.disabled) {
                    optionElement.disabled = option.disabled;
                }
                this._component.appendChild(optionElement);
            }
        }
    };
    SelectAdapter.prototype.open = function (options, selectedValue, listener, isMultiple) {
        var _this = this;
        this._component.setAttribute('aria-expanded', 'true');
        this._popupElement = document.createElement(POPUP_CONSTANTS.elementName);
        this._popupElement.targetElement = this._selectElement;
        this._popupElement.placement = PopupPlacement.BottomLeft;
        this._popupElement.id = "tcw-select-" + this._identifier;
        this._component.setAttribute('aria-owns', this._popupElement.id);
        var listElement = document.createElement(LIST_CONSTANTS.elementName);
        listElement.propagateClick = false;
        listElement.setAttribute('role', 'listbox');
        var selectedListItems = [];
        var groups = isOptionType(options, OptionType.Group) ? options : [{ text: '', options: options }];
        for (var _i = 0, groups_2 = groups; _i < groups_2.length; _i++) {
            var group = groups_2[_i];
            // We don't render headers that don't have text
            if (group.text) {
                var listHeaderElement = document.createElement('h4');
                listHeaderElement.textContent = group.text;
                listHeaderElement.style.margin = '0 8px';
                listElement.appendChild(listHeaderElement);
            }
            for (var _a = 0, _b = group.options; _a < _b.length; _a++) {
                var option = _b[_a];
                var index = group.options.indexOf(option);
                var isSelected = selectedValue.includes(option.value);
                var listItemElement = document.createElement(LIST_ITEM_CONSTANTS.elementName);
                listItemElement.value = option.value;
                listItemElement.textContent = option.label;
                listItemElement.style.cursor = 'pointer';
                listItemElement.setAttribute('role', 'option');
                listItemElement.id = "tcw-option-" + this._identifier + "-" + index;
                listItemElement.setAttribute('aria-selected', 'false');
                if (option.disabled) {
                    listItemElement.disabled = option.disabled;
                    listItemElement.setAttribute('aria-disabled', 'true');
                }
                else {
                    listItemElement.setAttribute('aria-disabled', 'false');
                }
                if (isSelected) {
                    selectedListItems.push(listItemElement);
                    listItemElement.setAttribute('aria-selected', 'true');
                }
                if (isMultiple) {
                    var checkboxElement = document.createElement('i');
                    checkboxElement.setAttribute('aria-hidden', 'true');
                    checkboxElement.classList.add(ICON_CLASS_NAME);
                    checkboxElement.slot = 'leading';
                    checkboxElement.style.marginRight = '16px';
                    if (selectedListItems.includes(listItemElement)) {
                        checkboxElement.textContent = 'check_box';
                    }
                    else {
                        checkboxElement.textContent = 'check_box_outline_blank';
                    }
                    listItemElement.setAttribute('aria-selected', '' + isSelected);
                    listItemElement.setAttribute('aria-checked', '' + isSelected);
                    listItemElement.appendChild(checkboxElement);
                }
                listElement.appendChild(listItemElement);
            }
        }
        listElement.addEventListener(LIST_ITEM_CONSTANTS.events.SELECTED, function (evt) {
            var data = evt.detail;
            var flattenedOptions = [].concat.apply([], groups.map(function (group) { return group.options; }));
            var selectedOption = flattenedOptions.find(function (option) { return option.value === data.value; });
            if (isMultiple) {
                _this._toggleSelectedOption(data.listItem);
            }
            data.listItem.setAttribute('aria-selected', 'true');
            _this._component.setAttribute('aria-activedescendant', data.listItem.id);
            listener(selectedOption, flattenedOptions.indexOf(selectedOption));
            if (_this._popupElement) {
                _this._popupElement.style.minWidth = _this._getWidth() + "px";
            }
        });
        this._popupElement.appendChild(listElement);
        this._popupElement.style.minWidth = this._getWidth() + "px";
        this._popupElement.animationType = PopupAnimationType.Dropdown;
        this._popupElement.open = true;
        if (options.length) {
            window.requestAnimationFrame(function () {
                if (selectedListItems.length) {
                    selectedListItems.forEach(function (li) { return li.selected = true; });
                    var activeListItem = selectedListItems[selectedListItems.length - 1];
                    activeListItem.active = true;
                    activeListItem.scrollIntoView({ block: 'center' });
                    _this._component.setAttribute('aria-activedescendant', activeListItem.id);
                }
                else {
                    var activeListItem = listElement.firstElementChild;
                    activeListItem.active = true;
                    _this._component.setAttribute('aria-activedescendant', activeListItem.id);
                }
            });
        }
    };
    SelectAdapter.prototype.toggleOption = function (index) {
        if (!this._popupElement) {
            return;
        }
        var listItems = Array.from(this._popupElement.querySelectorAll(LIST_ITEM_CONSTANTS.elementName));
        if (listItems.length && listItems[index]) {
            this._toggleSelectedOption(listItems[index]);
        }
    };
    SelectAdapter.prototype._toggleSelectedOption = function (listItem) {
        if (!this._popupElement) {
            return;
        }
        // First we need to remove the active state from any other list items
        var listItems = Array.from(this._popupElement.querySelectorAll(LIST_ITEM_CONSTANTS.elementName));
        if (listItems.length) {
            var activeItems = listItems.filter(function (li) { return li !== listItem && li.active; });
            if (activeItems.length) {
                activeItems.forEach(function (ai) { return ai.active = false; });
            }
        }
        // Now we can toggle the selected state and sync the active state
        listItem.selected = !listItem.selected;
        listItem.setAttribute('aria-selected', '' + listItem.selected);
        listItem.setAttribute('aria-checked', '' + listItem.selected);
        if (listItem.selected) {
            listItem.active = true;
        }
        // Toggle the checkbox icon based on the selected state
        var checkboxElement = listItem.querySelector('i[slot=leading]');
        if (checkboxElement) {
            checkboxElement.textContent = listItem.selected ? 'check_box' : 'check_box_outline_blank';
        }
    };
    SelectAdapter.prototype.close = function (hard) {
        if (!this._popupElement) {
            return;
        }
        this._component.setAttribute('aria-expanded', 'false');
        if (hard) {
            removeElement(this._popupElement);
        }
        else {
            this._popupElement.open = false;
        }
        this._popupElement = undefined;
    };
    SelectAdapter.prototype.setPopupKeydownListener = function (listener) {
        if (!this._popupElement) {
            return;
        }
        this._popupElement.addEventListener('keydown', listener);
    };
    SelectAdapter.prototype.setDismissListener = function (listener) {
        if (!this._popupElement) {
            return;
        }
        this._popupElement.addEventListener('keydown', function (evt) {
            var isEscapeKey = evt.key === 'Escape' && evt.keyCode === 27;
            var isTabKey = evt.key === 'Tab' && evt.keyCode === 9;
            if (isEscapeKey || isTabKey) {
                listener();
            }
        });
        this._popupElement.targetElement.addEventListener(POPUP_CONSTANTS.events.BLUR, listener);
    };
    SelectAdapter.prototype.getActiveOptionIndex = function () {
        if (!this._popupElement) {
            return 0;
        }
        var listItems = Array.from(this._popupElement.querySelectorAll(LIST_ITEM_CONSTANTS.elementName));
        var activeListItem = __spreadArrays(listItems).reverse().find(function (li) { return li.active; });
        return activeListItem ? listItems.indexOf(activeListItem) : 0;
    };
    SelectAdapter.prototype.highlightActiveOption = function (index) {
        if (!this._popupElement) {
            return;
        }
        var listItems = Array.from(this._popupElement.querySelectorAll(LIST_ITEM_CONSTANTS.elementName));
        if (listItems.length) {
            var activeListItems = listItems.filter(function (li) { return li.active; });
            activeListItems.forEach(function (li) { return li.active = false; });
            listItems[index].active = true;
            listItems[index].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            this._component.setAttribute('aria-activedescendant', listItems[index].id);
        }
    };
    SelectAdapter.prototype.setSelectedOption = function (index) {
        if (!this._popupElement) {
            return;
        }
        var listItems = Array.from(this._popupElement.querySelectorAll(LIST_ITEM_CONSTANTS.elementName));
        if (listItems.length) {
            var selectedListItemIndex = listItems.findIndex(function (li) { return li.selected; });
            if (selectedListItemIndex !== index) {
                if (selectedListItemIndex >= 0) {
                    listItems[selectedListItemIndex].selected = false;
                    listItems[selectedListItemIndex].setAttribute('aria-selected', 'false');
                }
                listItems[index].selected = true;
                listItems[index].setAttribute('aria-selected', 'true');
            }
        }
    };
    SelectAdapter.prototype.setSelectedValues = function (selectedValues) {
        if (!this._popupElement) {
            return;
        }
        var listItems = Array.from(this._popupElement.querySelectorAll(LIST_ITEM_CONSTANTS.elementName));
        for (var _i = 0, listItems_1 = listItems; _i < listItems_1.length; _i++) {
            var listItem = listItems_1[_i];
            var isSelected = selectedValues.includes(listItem.value);
            listItem.selected = isSelected;
            var checkboxElement = listItem.querySelector('i[slot=leading]');
            if (checkboxElement) {
                checkboxElement.textContent = isSelected ? 'check_box' : 'check_box_outline_blank';
            }
        }
    };
    SelectAdapter.prototype.focus = function () {
        var _this = this;
        window.requestAnimationFrame(function () { return _this._selectedTextElement.focus(); });
    };
    SelectAdapter.prototype.emitEvent = function (type, data) {
        if (data === void 0) { data = {}; }
        emitEvent(this._component, type, data, true);
    };
    SelectAdapter.prototype.setSelectedText = function (value) {
        this._selectedTextElement.textContent = value;
    };
    SelectAdapter.prototype.setLeadingListener = function (listener) {
        this._leadingSlot.addEventListener('slotchange', listener);
    };
    SelectAdapter.prototype.removeLeadingListener = function (listener) {
        this._leadingSlot.removeEventListener('slotchange', listener);
    };
    SelectAdapter.prototype.hasLeadingElement = function () {
        return this._leadingSlot.assignedNodes().length > 0;
    };
    SelectAdapter.prototype.setOptionsListener = function (listener) {
        var _this = this;
        var observer = new MutationObserver(function () { return listener(_this.getOptions()); });
        observer.observe(this._component, { childList: true });
        return function () { return observer.disconnect(); };
    };
    SelectAdapter.prototype.setDisabled = function (isDisabled) {
        if (isDisabled) {
            this._selectElement.classList.add(SELECT_CONSTANTS.classes.SELECT_DISABLED);
            this._selectedTextElement.tabIndex = -1;
            this._component.setAttribute('aria-disabled', 'true');
        }
        else {
            this._selectElement.classList.remove(SELECT_CONSTANTS.classes.SELECT_DISABLED);
            this._selectedTextElement.tabIndex = 0;
            this._component.removeAttribute('aria-disabled');
        }
    };
    SelectAdapter.prototype.setInvalid = function (isInvalid) {
        if (isInvalid) {
            this._selectElement.classList.add(SELECT_CONSTANTS.classes.INVALID);
            this._component.setAttribute('aria-invalid', 'true');
        }
        else {
            this._selectElement.classList.remove(SELECT_CONSTANTS.classes.INVALID);
            this._component.removeAttribute('aria-invalid');
        }
    };
    SelectAdapter.prototype.setRequired = function (isRequired) {
        if (isRequired) {
            this._selectElement.classList.add(SELECT_CONSTANTS.classes.REQUIRED);
            this._component.setAttribute('aria-required', 'true');
        }
        else {
            this._selectElement.classList.remove(SELECT_CONSTANTS.classes.REQUIRED);
            this._component.removeAttribute('aria-required');
        }
    };
    SelectAdapter.prototype.setDense = function (isDense) {
        if (isDense) {
            this._selectElement.classList.add(SELECT_CONSTANTS.classes.DENSE);
        }
        else {
            this._selectElement.classList.remove(SELECT_CONSTANTS.classes.DENSE);
        }
    };
    SelectAdapter.prototype.setRoomy = function (isRoomy) {
        if (isRoomy) {
            this._selectElement.classList.add(SELECT_CONSTANTS.classes.ROOMY);
        }
        else {
            this._selectElement.classList.remove(SELECT_CONSTANTS.classes.ROOMY);
        }
    };
    SelectAdapter.prototype.getLabelWidth = function (fontSize, fontFamily) {
        return calculateFontWidth(this._labelElement.innerText, { fontSize: fontSize, fontFamily: fontFamily });
    };
    SelectAdapter.prototype.getLabelFontMetrics = function () {
        var style = getComputedStyle(this._labelElement);
        return {
            fontSize: parseInt(style.fontSize || '16', 10),
            fontFamily: style.fontFamily || 'Roboto'
        };
    };
    SelectAdapter.prototype._getWidth = function () {
        return this._selectElement.getBoundingClientRect().width;
    };
    return SelectAdapter;
}());
export { SelectAdapter };
