import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "label-value";
var attributes = {
    EMPTY: 'empty',
    ELLIPSIS: 'ellipsis',
    DENSITY: 'density',
    ALIGN: 'align'
};
var classes = {
    EMPTY: 'tyl-label-value--empty',
    ELLIPSIS: 'tyl-label-value--ellipsis',
    ROOMY: 'tyl-label-value--roomy',
    DENSE: 'tyl-label-value--dense',
    ALIGN_CENTER: 'tyl-label-value--align-center',
    ALIGN_RIGHT: 'tyl-label-value--align-right'
};
var selectors = {
    ROOT: '.tyl-label-value'
};
export var LABEL_VALUE_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    classes: classes,
    selectors: selectors
};
