import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, coerceBoolean, upgradeProperty } from '@tyler-components-web/core';
import { BackdropComponent } from '../backdrop';
import { DrawerAdapter } from './drawer-adapter';
import { DrawerFoundation } from './drawer-foundation';
import { DRAWER_CONSTANTS } from './drawer-constants';
var template = require('./drawer.html');
var styles = require('./drawer.css');
var DrawerComponent = /** @class */ (function (_super) {
    __extends(DrawerComponent, _super);
    function DrawerComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, DRAWER_CONSTANTS.elementName, template, styles);
        _this._foundation = new DrawerFoundation(new DrawerAdapter(_this));
        return _this;
    }
    Object.defineProperty(DrawerComponent, "observedAttributes", {
        get: function () {
            return [
                DRAWER_CONSTANTS.attributes.OPEN,
                DRAWER_CONSTANTS.attributes.TYPE,
                DRAWER_CONSTANTS.attributes.DIRECTION
            ];
        },
        enumerable: true,
        configurable: true
    });
    DrawerComponent.prototype.initializedCallback = function () {
        this._foundation.initialize();
    };
    DrawerComponent.prototype.connectedCallback = function () {
        upgradeProperty(this, 'open');
        upgradeProperty(this, 'direction');
        upgradeProperty(this, 'type');
    };
    DrawerComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case DRAWER_CONSTANTS.attributes.OPEN:
                this.open = coerceBoolean(newValue);
                break;
            case DRAWER_CONSTANTS.attributes.TYPE:
                this.type = newValue;
                break;
            case DRAWER_CONSTANTS.attributes.DIRECTION:
                this.direction = newValue;
                break;
        }
    };
    Object.defineProperty(DrawerComponent.prototype, "open", {
        /** Toggles whether a `dismissible` or `modal` drawer is open or not. Has no effect on `permanent` drawers. */
        get: function () {
            return this._foundation.open;
        },
        set: function (value) {
            this._foundation.open = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DrawerComponent.prototype, "type", {
        /** Gets/Sets the type of drawer to use: `permanent` (default), `dimissible`, `modal`. */
        get: function () {
            return this._foundation.type;
        },
        set: function (value) {
            this._foundation.type = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DrawerComponent.prototype, "direction", {
        /** Controls the animation direction of the drawer for positioning on the left vs. right side of the screen. */
        get: function () {
            return this._foundation.direction;
        },
        set: function (value) {
            this._foundation.direction = value;
        },
        enumerable: true,
        configurable: true
    });
    DrawerComponent = __decorate([
        CustomElement({
            name: DRAWER_CONSTANTS.elementName,
            dependencies: [BackdropComponent]
        })
    ], DrawerComponent);
    return DrawerComponent;
}(HTMLElement));
export { DrawerComponent };
