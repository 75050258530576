/**
 * The DOM adapter behind the `<tcw-option>` element.
 */
var OptionAdapter = /** @class */ (function () {
    function OptionAdapter(_component) {
        this._component = _component;
    }
    /**
     * Sets the value of an attribute on the host element.
     * @param {string} name The name of the attribute.
     * @param {string} value The value of the attribute.
     */
    OptionAdapter.prototype.setHostAttribute = function (name, value) {
        this._component.setAttribute(name, value);
    };
    return OptionAdapter;
}());
export { OptionAdapter };
