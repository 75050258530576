import { __decorate, __extends } from "tslib";
import { CustomElement, ensureChildren, upgradeProperty } from '@tyler-components-web/core';
import { DatepickerAdapter } from './datepicker-adapter';
import { DatepickerFoundation } from './datepicker-foundation';
import { DATEPICKER_CONSTANTS } from './datepicker-constants';
import { PopupComponent } from '../popup';
import { TextFieldComponent } from '../text-field';
import { SelectComponent } from '../select';
/**
 * The custom element class behind the `<tcw-datepicker>` element.
 */
var DatepickerComponent = /** @class */ (function (_super) {
    __extends(DatepickerComponent, _super);
    function DatepickerComponent() {
        var _this = _super.call(this) || this;
        _this._foundation = new DatepickerFoundation(new DatepickerAdapter(_this));
        return _this;
    }
    Object.defineProperty(DatepickerComponent, "observedAttributes", {
        get: function () {
            return [
                DATEPICKER_CONSTANTS.attributes.OPEN
            ];
        },
        enumerable: true,
        configurable: true
    });
    DatepickerComponent.prototype.initializedCallback = function () {
        var _this = this;
        upgradeProperty(this, 'open');
        if (this.children.length) {
            this._foundation.initialize();
        }
        else {
            ensureChildren(this).then(function () { return _this._foundation.initialize(); });
        }
    };
    DatepickerComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case DATEPICKER_CONSTANTS.attributes.OPEN:
                this.open = this.hasAttribute(DATEPICKER_CONSTANTS.attributes.OPEN);
                break;
        }
    };
    DatepickerComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    Object.defineProperty(DatepickerComponent.prototype, "min", {
        /** Sets the minimum date the calendar will allow. */
        set: function (value) {
            this._foundation.min = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatepickerComponent.prototype, "max", {
        /** Sets the maximum date the calendar will allow. */
        set: function (value) {
            this._foundation.max = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatepickerComponent.prototype, "open", {
        /**
         * Gets/sets whether the datepicker is open.
         */
        get: function () {
            return this._foundation.open;
        },
        set: function (value) {
            this._foundation.open = value;
        },
        enumerable: true,
        configurable: true
    });
    DatepickerComponent = __decorate([
        CustomElement({
            name: DATEPICKER_CONSTANTS.elementName,
            dependencies: [
                PopupComponent,
                TextFieldComponent,
                SelectComponent
            ]
        })
    ], DatepickerComponent);
    return DatepickerComponent;
}(HTMLElement));
export { DatepickerComponent };
