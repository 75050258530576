import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "slider";
var attributes = {
    TYPE: 'type',
    VALUE: 'value',
    MIN: 'min',
    MAX: 'max',
    STEP: 'step',
    DISABLED: 'disabled',
    COLOR: 'color'
};
var classes = {
    DISPLAY_MARKERS: 'mdc-slider--display-markers',
    TRACK_MARKER_CONTAINER: 'mdc-slider__track-marker-container'
};
var selectors = {
    ROOT: '.mdc-slider',
    TRACK_CONTAINER: '.mdc-slider__track-container'
};
var events = {
    MDC_INPUT: 'MDCSlider:input',
    MDC_CHANGE: 'MDCSlider:change',
    TCW_INPUT: 'tcw-slider-input',
    TCW_CHANGE: 'tcw-slider-change',
};
export var SLIDER_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    classes: classes,
    selectors: selectors,
    events: events
};
