import { defineCustomElement } from '@tyler-components-web/core';
import { TextFieldComponent } from './text-field';
export * from './text-field-adapter';
export * from './text-field-component-delegate';
export * from './text-field-constants';
export * from './text-field-foundation';
export * from './text-field';
export function defineTextFieldComponent() {
    defineCustomElement(TextFieldComponent);
}
