import { __awaiter, __generator } from "tslib";
import { isFunction } from '@tyler-components-web/core';
import { AppLauncherView, APP_LAUNCHER_CONSTANTS } from './app-launcher-constants';
var AppLauncherFoundation = /** @class */ (function () {
    function AppLauncherFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._options = [];
        this._selectListener = function (option) { return _this._onSelect(option); };
        this._searchListener = function (evt) { return _this._onSearch(evt); };
    }
    AppLauncherFoundation.prototype.initialize = function () {
        this._fetchOptions();
        this._adapter.addInputListener('input', this._searchListener);
    };
    AppLauncherFoundation.prototype.disconnect = function () {
        this._adapter.removeInputListener('input', this._searchListener);
    };
    AppLauncherFoundation.prototype.focusFirst = function () {
        this._adapter.focusFirstOption();
    };
    AppLauncherFoundation.prototype._fetchOptions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var val, result, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isFunction(this._optionsCallback)) {
                            throw new Error('Invalid options callback provided.');
                        }
                        this._adapter.setView(AppLauncherView.Loading);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        val = this._optionsCallback();
                        return [4 /*yield*/, Promise.resolve(val)];
                    case 2:
                        result = _a.sent();
                        if (result && result.options && result.options.length) {
                            this._options = result.options;
                            if (this._options.length <= APP_LAUNCHER_CONSTANTS.numbers.MIN_OPTIONS_FOR_SEARCH) {
                                this._adapter.hideSearch();
                            }
                            this._adapter.setOptions(result.options || [], this._selectListener);
                            this._adapter.setView(AppLauncherView.Default);
                        }
                        else {
                            this._adapter.setView(AppLauncherView.Empty);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        this._adapter.setView(AppLauncherView.Error);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AppLauncherFoundation.prototype._onSelect = function (option) {
        var isCancelled = this._adapter.emitEvent(APP_LAUNCHER_CONSTANTS.events.SELECT, option);
        if (!isCancelled) {
            this._openUri(option.uri, option.target || '_blank');
        }
    };
    AppLauncherFoundation.prototype._onSearch = function (evt) {
        evt.stopPropagation();
        var value = evt.target.value.trim().toLowerCase();
        var filteredOptions = value ?
            this._options.filter(function (o) {
                var matchesLabel = o.label.trim().toLowerCase().includes(value);
                var matchesHelpText = o.helpText && o.helpText.trim().toLowerCase().includes(value);
                return matchesLabel && matchesHelpText;
            }) :
            this._options;
        this._adapter.setOptions(filteredOptions, this._selectListener);
        if (value) {
            this._adapter.setTitle(filteredOptions.length ? 'Results' : 'No apps found');
        }
        else {
            this._adapter.setTitle('My apps');
        }
    };
    AppLauncherFoundation.prototype._openUri = function (uri, target) {
        if (!uri) {
            throw new Error('Invalid URI provided.');
        }
        window.open(uri, target);
    };
    Object.defineProperty(AppLauncherFoundation.prototype, "optionsCallback", {
        set: function (cb) {
            this._optionsCallback = cb;
        },
        enumerable: true,
        configurable: true
    });
    return AppLauncherFoundation;
}());
export { AppLauncherFoundation };
