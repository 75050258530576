import { getShadowElement } from '@tyler-components-web/core';
import { BACKDROP_CONSTANTS } from './backdrop-constants';
/**
 * Provides facilities for interacting with the internal DOM of `BackdropComponent`.
 */
var BackdropAdapter = /** @class */ (function () {
    function BackdropAdapter(_component) {
        this._component = _component;
        this._backdropElement = getShadowElement(_component, BACKDROP_CONSTANTS.selectors.CONTAINER);
    }
    /**
     * Sets the backdrop element opacity.
     * @param opacity The opacity amount.
     */
    BackdropAdapter.prototype.setBackdropOpacity = function (opacity) {
        this._backdropElement.style.opacity = opacity.toString();
    };
    /**
     * Adds a click event to the backdrop container element.
     * @param type The event type.
     * @param listener The event listener.
     */
    BackdropAdapter.prototype.addBackdropEventListener = function (type, listener) {
        this._backdropElement.addEventListener('click', listener);
    };
    /**
     * Removes a click event from the backdrop container element.
     * @param type The event type.
     * @param listener The event listener.
     */
    BackdropAdapter.prototype.removeBackdropEventListener = function (type, listener) {
        this._backdropElement.removeEventListener('click', listener);
    };
    /**
     * Emits the provided event type from the backdrop host element.
     * @param type The event type.
     */
    BackdropAdapter.prototype.emitHostEvent = function (type) {
        this._component.dispatchEvent(new Event(type, { bubbles: true, composed: true }));
    };
    /**
     * Sets an attribute on the host element.
     * @param name The attribute name.
     * @param value The attribute value.
     */
    BackdropAdapter.prototype.setHostAttribute = function (name, value) {
        this._component.setAttribute(name, value);
    };
    return BackdropAdapter;
}());
export { BackdropAdapter };
