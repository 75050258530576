import { emitEvent, isDefined, toggleElementPlaceholder, getShadowElement, removeClass, addClass } from '@tyler-components-web/core';
import { OMNIBAR_SEARCH_CONSTANTS } from './omnibar-search-constants';
import { deepQuerySelectorAll } from 'shadow-utils';
/**
 * Provides facilities for interacting with the internal DOM of `OmnibarSearchComponent`.
 */
var OmnibarSearchAdapter = /** @class */ (function () {
    function OmnibarSearchAdapter(_component) {
        this._component = _component;
        this._rootElement = getShadowElement(_component, OMNIBAR_SEARCH_CONSTANTS.selectors.ROOT);
        this._globalIconContainer = getShadowElement(_component, OMNIBAR_SEARCH_CONSTANTS.selectors.GLOBAL_ICON_CONTAINER);
        this._contextContainerElement = getShadowElement(_component, OMNIBAR_SEARCH_CONSTANTS.selectors.CONTEXT_CONTAINER);
    }
    OmnibarSearchAdapter.prototype.initialize = function () {
        var inputElements = deepQuerySelectorAll(this._component, OMNIBAR_SEARCH_CONSTANTS.selectors.INPUT, false);
        if (inputElements.length) {
            this._inputElement = inputElements[0];
        }
    };
    OmnibarSearchAdapter.prototype.hasInputElement = function () {
        return isDefined(this._inputElement);
    };
    /**
     * Sets an attribute value on the host element.
     * @param {string} name The attribute name.
     * @param {string} value The attribute value.
     */
    OmnibarSearchAdapter.prototype.setHostAttribute = function (name, value) {
        this._component.setAttribute(name, value);
    };
    /**
     * Emits the provided event from the component root element.
     * @param {string} type The event type.
     * @param {any} data The event data.
     * @param {boolean} [bubble=false] Should the event bubble up?
     */
    OmnibarSearchAdapter.prototype.emitHostEvent = function (type, data, bubble) {
        if (bubble === void 0) { bubble = false; }
        emitEvent(this._component, type, data, bubble);
    };
    /**
     * Removes a attribute value from the host element.
     * @param {string} name The attribute name.
     */
    OmnibarSearchAdapter.prototype.removeHostAttribute = function (name) {
        this._component.removeAttribute(name);
    };
    OmnibarSearchAdapter.prototype.addRootClass = function (className) {
        addClass(className, this._rootElement);
    };
    OmnibarSearchAdapter.prototype.removeRootClass = function (className) {
        removeClass(className, this._rootElement);
    };
    /**
     * Adds an event to the search input element.
     * @param {string} type The event type.
     * @param {Function} listener The event listener.
     */
    OmnibarSearchAdapter.prototype.addSearchInputEventListener = function (type, listener) {
        this._inputElement.addEventListener(type, listener);
    };
    /**
     * Removes an event from the search input element.
     * @param {string} type The event type.
     * @param {Function} listener The event listener.
     */
    OmnibarSearchAdapter.prototype.removeSearchInputEventListener = function (type, listener) {
        this._inputElement.removeEventListener(type, listener);
    };
    /**
     * Toggles the disabled attribute on the search input.
     * @param {boolean} disabled Whether the attribute should be set or not.
     */
    OmnibarSearchAdapter.prototype.setDisabledAttribute = function (disabled) {
        if (disabled) {
            this._inputElement.setAttribute(OMNIBAR_SEARCH_CONSTANTS.attributes.DISABLED, OMNIBAR_SEARCH_CONSTANTS.attributes.DISABLED);
        }
        else {
            this._inputElement.removeAttribute(OMNIBAR_SEARCH_CONSTANTS.attributes.DISABLED);
        }
    };
    OmnibarSearchAdapter.prototype.getInputValue = function () {
        return this._inputElement.value;
    };
    OmnibarSearchAdapter.prototype.setInputValue = function (value) {
        this._inputElement.value = value;
    };
    OmnibarSearchAdapter.prototype.setInputPlaceholder = function (value) {
        this._inputElement.placeholder = value;
    };
    OmnibarSearchAdapter.prototype.setCombined = function (isCombined) {
    };
    OmnibarSearchAdapter.prototype.setGlobalIconVisibility = function (isVisible) {
        this._globalIconPlaceholder = toggleElementPlaceholder(this._component, isVisible, OMNIBAR_SEARCH_CONSTANTS.elementName, OMNIBAR_SEARCH_CONSTANTS.selectors.GLOBAL_ICON_CONTAINER, this._globalIconContainer, this._globalIconPlaceholder);
    };
    OmnibarSearchAdapter.prototype.setContextVisibility = function (isVisible) {
        this._contextContainerPlaceholder = toggleElementPlaceholder(this._component, isVisible, OMNIBAR_SEARCH_CONSTANTS.elementName, OMNIBAR_SEARCH_CONSTANTS.selectors.CONTEXT_CONTAINER, this._contextContainerElement, this._contextContainerPlaceholder);
    };
    return OmnibarSearchAdapter;
}());
export { OmnibarSearchAdapter };
