import { getActiveElement } from 'shadow-utils';
import { MessageList, isDefined } from '@tyler-components-web/core';
import { BUSY_INDICATOR_CONSTANTS } from './busy-indicator-constants';
/**
 * Provides facilities and helper methods for creating a busy indicator component.
 */
var BusyIndicatorFoundation = /** @class */ (function () {
    function BusyIndicatorFoundation(_adapter) {
        this._adapter = _adapter;
        this._canCancel = false;
        this._showSpinner = true;
        this._showProgressBar = false;
        this._progressBarDeterminate = false;
        this._progress = 0;
        this._buffer = 0;
        this._delay = BUSY_INDICATOR_CONSTANTS.numbers.DELAY;
        this._cancelListenerAttached = false;
        this._isDestroyed = false;
        this._autoEllipsis = true;
        this._visibleStartTime = 0;
        this._messageManager = new MessageList(this._autoEllipsis);
    }
    /** Clean up. */
    BusyIndicatorFoundation.prototype.destroy = function () {
        this._adapter.removeCancelButtonEventListener('click', this._onCancelClick);
    };
    /** Shows the busy indicator with default values. */
    BusyIndicatorFoundation.prototype.show = function () {
        this._adapter.setHostAttribute('tabindex', '0');
        this._adapter.setSurfaceOpacity(0);
        this._adapter.setBackdropDelay(this._delay);
        if (!this._titleText) {
            this._adapter.setTitleVisibility(false);
        }
        if (this._messageManager.messageCount === 0) {
            this._adapter.setMessageVisibility(false);
        }
        if (!this._canCancel) {
            this._adapter.setCancelButtonVisibility(false);
        }
        else {
            this._attachCancelClickListener();
        }
        if (!this._showSpinner) {
            this._adapter.setSpinnerVisibility(false);
        }
        if (!this._showProgressBar) {
            this._adapter.setProgressBarVisibility(false);
        }
        else {
            this._adapter.setProgressBarDeterminate(this._progressBarDeterminate);
            this._adapter.setProgressBarProgress(this._progress);
            this._adapter.setProgressBarBuffer(this._buffer);
        }
        if (this._width) {
            this._adapter.setSurfaceWidth(typeof this._width === 'number' ? this._width + "px" : this._width);
        }
        if (!this._autoEllipsis) {
            this._messageManager.useAutoEllipsis = false;
            this._adapter.setMessage(this._messageManager.message);
        }
        this.fadeIn();
    };
    /**
     * Removes the element from the DOM after the provided delay. The default delay time is 250ms.
     * @param {string | number} messageId The message id to remove.
     * @param {boolean} [force=false] Whether to force removal of the busy indicator or not.
     */
    BusyIndicatorFoundation.prototype.hide = function (messageId, force) {
        var _this = this;
        if (messageId === void 0) { messageId = BUSY_INDICATOR_CONSTANTS.strings.DEFAULT_MESSAGE_ID; }
        if (force === void 0) { force = false; }
        if (this._isDestroyed) {
            return;
        }
        if (!force) {
            this.removeMessage(messageId, this._messageManager.messageCount > 1);
        }
        // If that was the last message to be removed in the indicator, then we destroy the element.
        if (force || !this.hasMessage) {
            // We require the element to be visible for a certain period of time, unless it was hidden before it became visible.
            var visibleLifetime = this._visibleStartTime > 0 ? Date.now() - this._visibleStartTime : 0;
            var delay = visibleLifetime > 0 ? Math.max(0, BUSY_INDICATOR_CONSTANTS.numbers.MINIMUM_VISIBLE_LIFETIME - visibleLifetime) : 0;
            this._isDestroyed = true;
            setTimeout(function () {
                if (_this._visibleStartTime > 0) {
                    _this.fadeOut();
                    _this._adapter.hideBackdrop();
                    setTimeout(function () {
                        _this._releaseFocus();
                        _this._adapter.remove();
                    }, BUSY_INDICATOR_CONSTANTS.numbers.TRANSITION_LENGTH);
                }
                else {
                    _this._releaseFocus();
                    _this._adapter.remove();
                }
            }, delay);
        }
    };
    /** Sets the elements opacity to 0 which triggers the CSS transition to fade out. */
    BusyIndicatorFoundation.prototype.fadeOut = function () {
        this._adapter.setSurfaceOpacity(0);
    };
    /** Sets the elements opacity to 1 which triggers the CSS transition to fade in after the current delay value. */
    BusyIndicatorFoundation.prototype.fadeIn = function () {
        var _this = this;
        setTimeout(function () {
            if (_this._isDestroyed) {
                return;
            }
            _this._visibleStartTime = Date.now();
            _this._adapter.setSurfaceOpacity(1);
            _this._captureFocus();
        }, this.delay);
    };
    /**
     * Adds a message to the busy indicator message list.
     * @param {string} message The message to add to this busy indicator.
     */
    BusyIndicatorFoundation.prototype.addMessage = function (message) {
        if (message.text) {
            var identifier = message.id !== undefined && message.id !== null ? message.id : BUSY_INDICATOR_CONSTANTS.strings.DEFAULT_MESSAGE_ID;
            this._messageManager.add(message.text, identifier);
            this._adapter.setMessage(this._messageManager.message);
        }
    };
    /** Removes a message from the message list. */
    BusyIndicatorFoundation.prototype.removeMessage = function (id, render) {
        if (render === void 0) { render = true; }
        if (!this._messageManager.hasMessage(id)) {
            return;
        }
        this._messageManager.remove(id);
        if (render) {
            this._adapter.setMessage(this._messageManager.message);
        }
    };
    /** Attaches the click listener to the cancel button. */
    BusyIndicatorFoundation.prototype._attachCancelClickListener = function () {
        if (!this._cancelListenerAttached) {
            this._adapter.addCancelButtonEventListener('click', this._onCancelClick.bind(this));
            this._cancelListenerAttached = true;
        }
    };
    /** Handles clicks on the cancel button. */
    BusyIndicatorFoundation.prototype._onCancelClick = function () {
        this._adapter.setCancelButtonAttribute('disabled', 'disabled');
        this._adapter.setCancelButtonText(BUSY_INDICATOR_CONSTANTS.strings.CANCELLING);
        this._adapter.removeCancelButtonEventListener('click', this._onCancelClick);
        this._adapter.emitHostEvent(BUSY_INDICATOR_CONSTANTS.events.CANCEL);
    };
    /** Cache currently focused element, set focus to the busy indicator. */
    BusyIndicatorFoundation.prototype._captureFocus = function () {
        this._previouslyFocusedElement = getActiveElement();
        this._adapter.captureFocus();
    };
    /** Gives focus back to the element that had it when the busy indicator was displayed. */
    BusyIndicatorFoundation.prototype._releaseFocus = function () {
        if (this._previouslyFocusedElement && this._adapter.getHostElement().contains(getActiveElement())) {
            this._previouslyFocusedElement.focus();
        }
    };
    Object.defineProperty(BusyIndicatorFoundation.prototype, "titleText", {
        get: function () {
            return this._titleText;
        },
        /** The title to be displayed. */
        set: function (value) {
            if (this._titleText !== value) {
                this._titleText = value;
                this._adapter.setTitle(this._titleText);
                this._adapter.setTitleVisibility(!!this._titleText);
                this._adapter.setHostAttribute(BUSY_INDICATOR_CONSTANTS.attributes.TITLE_TEXT, this._titleText);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorFoundation.prototype, "message", {
        get: function () {
            return this._message;
        },
        /** The message to be displayed. */
        set: function (value) {
            if (this._message !== value) {
                this._message = value;
                this._messageManager.reset();
                this.addMessage({ text: this._message });
                this._adapter.setMessageVisibility(this._messageManager.messageCount > 0);
                this._adapter.setHostAttribute(BUSY_INDICATOR_CONSTANTS.attributes.MESSAGE, this._message);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorFoundation.prototype, "cancel", {
        get: function () {
            return this._canCancel;
        },
        /** Controls whether the cancel button is visible or not. */
        set: function (canCancel) {
            if (this._canCancel !== canCancel) {
                this._canCancel = canCancel;
                this._adapter.setCancelButtonVisibility(!!this._canCancel);
                if (this._canCancel) {
                    this._attachCancelClickListener();
                }
                else {
                    this._adapter.removeCancelButtonEventListener('click', this._onCancelClick);
                }
                this._adapter.setHostAttribute(BUSY_INDICATOR_CONSTANTS.attributes.CANCEL, this._canCancel.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorFoundation.prototype, "spinner", {
        get: function () {
            return this._showSpinner;
        },
        /** Controls whether the progress spinner is visible or not. */
        set: function (showSpinner) {
            if (this._showSpinner !== showSpinner) {
                this._showSpinner = showSpinner;
                this._adapter.setSpinnerVisibility(!!this._showSpinner);
                this._adapter.setHostAttribute(BUSY_INDICATOR_CONSTANTS.attributes.SPINNER, '' + this._showSpinner);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorFoundation.prototype, "progressBar", {
        get: function () {
            return this._showProgressBar;
        },
        /** Controls whether the progres bar is visible or not. */
        set: function (showProgressBar) {
            if (this._showProgressBar !== showProgressBar) {
                this._showProgressBar = showProgressBar;
                this._adapter.setProgressBarVisibility(!!this._showProgressBar);
                this._adapter.setHostAttribute(BUSY_INDICATOR_CONSTANTS.attributes.PROGRESS_BAR, this._showProgressBar.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorFoundation.prototype, "progressBarDeterminate", {
        get: function () {
            return this._progressBarDeterminate;
        },
        /** Sets the progress bar mode. */
        set: function (determinate) {
            if (this._progressBarDeterminate !== determinate) {
                this._progressBarDeterminate = determinate;
                this._adapter.setProgressBarDeterminate(determinate);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorFoundation.prototype, "progress", {
        get: function () {
            return this._progress;
        },
        /** The progress amount of the progress bar. */
        set: function (value) {
            if (this._progress !== value && this._progressBarDeterminate) {
                this._progress = value;
                this._adapter.setProgressBarProgress(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorFoundation.prototype, "buffer", {
        get: function () {
            return this._buffer;
        },
        /** The buffer amount of the progress bar. */
        set: function (value) {
            if (this._buffer !== value && this._progressBarDeterminate) {
                this._buffer = value;
                this._adapter.setProgressBarBuffer(value);
                this._adapter.setHostAttribute(BUSY_INDICATOR_CONSTANTS.attributes.BUFFER, isDefined(this._buffer) ? this._buffer.toString() : '0');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorFoundation.prototype, "delay", {
        get: function () {
            return this._delay;
        },
        /** The delay value before the element becomes visible after being added to the DOM. */
        set: function (value) {
            this._delay = value;
            this._adapter.setBackdropDelay(this._delay);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorFoundation.prototype, "hasMessage", {
        /** Returns true if there are any existing messages. */
        get: function () {
            return this._messageManager.messageCount > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorFoundation.prototype, "isDestroyed", {
        /** Holds the destroyed status. */
        get: function () {
            return this._isDestroyed;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorFoundation.prototype, "width", {
        get: function () {
            return this._width;
        },
        /** Sets the width of the busy indicator element. */
        set: function (value) {
            if (this._width !== value) {
                if (typeof value === 'number' && value < 0) {
                    value = 'auto';
                }
                this._width = value;
                this._adapter.setSurfaceWidth(typeof this._width === 'number' ? this._width + "px" : this._width);
                this._adapter.setHostAttribute(BUSY_INDICATOR_CONSTANTS.attributes.WIDTH, this._width.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorFoundation.prototype, "autoEllipsis", {
        get: function () {
            return this._autoEllipsis;
        },
        /** Controls if the message manager will automatically display an ellipsis after the last message. */
        set: function (value) {
            if (this._autoEllipsis !== value) {
                this._autoEllipsis = value;
                this._messageManager.useAutoEllipsis = this._autoEllipsis;
                this._adapter.setMessage(this._messageManager.message);
                this._adapter.setHostAttribute(BUSY_INDICATOR_CONSTANTS.attributes.AUTO_ELLIPSIS, this._autoEllipsis.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    return BusyIndicatorFoundation;
}());
export { BusyIndicatorFoundation };
