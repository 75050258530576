import { COMPONENT_NAME_PREFIX } from '../../constants';
export var elementName = COMPONENT_NAME_PREFIX + "omnibar";
var selectors = {
    ROOT: '.tyl-omnibar',
    TITLE: '.tyl-omnibar__title',
    BOTTOM: '.tyl-omnibar__bottom',
    BOTTOM_SLOT: 'slot[name=bottom]',
    CENTER_SLOT: 'slot[name=center]',
    CENTER_SECTION: '#center-section',
    LOGO: '.tyl-omnibar__logo-container'
};
var attributes = {
    TITLE_TEXT: 'title-text',
    PROMINENT: 'prominent',
    FIXED: 'fixed',
    RAISED: 'raised',
    SCROLL_TARGET: 'scroll-target',
    THEME: 'theme',
    LOGO: 'logo'
};
var classes = {
    DENSE: 'tyl-omnibar--dense',
    PROMINENT: 'tyl-omnibar--prominent',
    FIXED: 'tyl-omnibar--fixed',
    RAISED: 'tyl-omnibar--raised',
    ROW: 'tyl-omnibar__row',
    TITLE_INTERACTABLE: 'tyl-omnibar__title--interactable',
    NO_CENTER: 'tyl-omnibar--no-center'
};
export var OMNIBAR_CONSTANTS = {
    elementName: elementName,
    selectors: selectors,
    attributes: attributes,
    classes: classes
};
