import { COMPONENT_NAME_PREFIX } from '../constants';
import { OPEN_ICON_CONSTANTS } from '../open-icon';
var elementName = COMPONENT_NAME_PREFIX + "expansion-panel";
var classes = {
    CONTAINER: 'tyl-expansion-panel',
    HEADER: 'tyl-expansion-panel__header',
    CONTENT: 'tyl-expansion-panel__content'
};
var selectors = {
    CONTAINER: "." + classes.CONTAINER,
    HEADER: "." + classes.HEADER,
    CONTENT: "." + classes.CONTENT,
    HEADER_SLOT: "." + classes.HEADER + " > slot[name=header]",
    OPEN_ICON: "[slot=header] " + OPEN_ICON_CONSTANTS.elementName
};
var events = {
    OPEN: elementName + "-open",
    CLOSE: elementName + "-close"
};
var attributes = {
    OPEN: 'open',
    ORIENTATION: 'orientation'
};
var numbers = {
    COLLAPSE_ANIMATION_DURATION: 400,
    CLICK_DEBOUNCE_THRESHOLD: 200
};
var strings = {
    ORIENTATION_VERTICAL: 'vertical',
    ORIENTATION_HORIZONTAL: 'horizontal',
    EXPANSION_VERTICAL_TRANSITION: "height " + numbers.COLLAPSE_ANIMATION_DURATION + "ms cubic-bezier(0.4, 0, 0.2, 1), opacity " + numbers.COLLAPSE_ANIMATION_DURATION + "ms ease-in-out",
    EXPANSION_HORIZONTAL_TRANSITION: "width " + numbers.COLLAPSE_ANIMATION_DURATION + "ms cubic-bezier(0.4, 0, 0.2, 1), opacity " + numbers.COLLAPSE_ANIMATION_DURATION + "ms ease-in-out",
};
export var EXPANSION_PANEL_CONSTANTS = {
    elementName: elementName,
    classes: classes,
    selectors: selectors,
    events: events,
    attributes: attributes,
    numbers: numbers,
    strings: strings
};
