import { __awaiter, __generator } from "tslib";
import { isArray } from '@tyler-components-web/core';
import { PopupPlacement } from '../popup';
import { MENU_CONSTANTS } from './menu-constants';
import { LIST_ITEM_CONSTANTS } from '../list';
import { ICON_CLASS_NAME } from '../constants';
var MenuFoundation = /** @class */ (function () {
    function MenuFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._options = [];
        this._open = false;
        this._placement = PopupPlacement.BottomLeft;
        this._dense = false;
        this._iconClass = ICON_CLASS_NAME;
        this._clickListener = function (evt) { return _this._toggleInteraction(evt); };
        this._menuInteractionListener = function (evt) { return _this._menuInteraction(evt); };
        this._dismissListener = function () { return _this._onDismiss(); };
        this._keydownListener = function (evt) { return _this._onKeydown(evt); };
    }
    MenuFoundation.prototype.initialize = function () {
        if (!this._adapter.toggleElement) {
            return;
        }
        this._adapter.initializeAccessibility();
        this._adapter.addEventListener('click', this._clickListener, this._adapter.toggleElement);
        this._adapter.addEventListener('keydown', this._keydownListener, this._adapter.toggleElement);
    };
    MenuFoundation.prototype.disconnect = function () {
        this._closeMenu();
        this._adapter.removeEventListener('click', this._clickListener, this._adapter.toggleElement);
        this._adapter.removeEventListener('keydown', this._clickListener, this._adapter.toggleElement);
    };
    Object.defineProperty(MenuFoundation.prototype, "_nonDividerOptions", {
        get: function () {
            return this._options.filter(function (o) { return !o.divider; });
        },
        enumerable: true,
        configurable: true
    });
    MenuFoundation.prototype._toggleInteraction = function (evt) {
        if (this._open) {
            this._closeMenu();
            return;
        }
        this.open = !this.open;
    };
    MenuFoundation.prototype._onDismiss = function () {
        if (this._adapter.isFocusWithinToggle()) {
            return;
        }
        this._closeMenu();
    };
    MenuFoundation.prototype._onKeydown = function (evt) {
        switch (evt.key) {
            case 'Escape':
                if (this._open) {
                    evt.preventDefault();
                    this._closeMenu();
                }
                break;
            case 'Space':
                evt.preventDefault();
                if (this._open) {
                    this._closeMenu();
                }
                else {
                    this._openMenu();
                }
                break;
            case 'Tab':
                if (this._open) {
                    this._closeMenu();
                }
                break;
            case 'Enter':
                evt.preventDefault();
                if (this._open) {
                    this._selectOptionByIndex(this._adapter.getActiveOptionIndex());
                }
                else {
                    this._openMenu();
                }
                break;
            case 'ArrowUp':
            case 'ArrowDown':
                evt.preventDefault();
                var index = this._adapter.getActiveOptionIndex();
                var maxIndex = this._nonDividerOptions.length - 1;
                if (evt.key === 'ArrowUp') {
                    index = index === 0 ? maxIndex : index - 1;
                }
                else {
                    index = index === maxIndex ? 0 : index + 1;
                }
                this._adapter.setActiveOption(index);
                break;
        }
    };
    MenuFoundation.prototype._openMenu = function () {
        return __awaiter(this, void 0, void 0, function () {
            var options;
            return __generator(this, function (_a) {
                this._open = true;
                options = [];
                if (typeof this._optionsFactory === 'function') {
                    this._loadOptionsAsync();
                }
                else {
                    if (!this._options || !this._options.length) {
                        return [2 /*return*/];
                    }
                    options = this._options;
                }
                this._adapter.setAttribute(MENU_CONSTANTS.attributes.OPEN, '');
                this._adapter.attachMenu(options, this._selectedIndex, this._dense, this._iconClass);
                this._adapter.addEventListener(LIST_ITEM_CONSTANTS.events.SELECTED, this._menuInteractionListener, this._adapter.popupElement);
                this._adapter.addEventListener('keydown', this._menuInteractionListener, this._adapter.popupElement);
                this._adapter.addDismissInteraction(this._dismissListener);
                this._adapter.popupElement.open = true;
                return [2 /*return*/];
            });
        });
    };
    MenuFoundation.prototype._closeMenu = function () {
        this._open = false;
        if (this._adapter.popupElement) {
            this._adapter.popupElement.open = false;
            this._adapter.detachMenu();
        }
        this._adapter.removeAttribute(MENU_CONSTANTS.attributes.OPEN);
        this._adapter.removeEventListener(LIST_ITEM_CONSTANTS.events.SELECTED, this._menuInteractionListener, this._adapter.popupElement);
        this._adapter.removeEventListener('keydown', this._menuInteractionListener, this._adapter.popupElement);
        this._adapter.removeDismissInteraction(this._dismissListener);
    };
    MenuFoundation.prototype._loadOptionsAsync = function () {
        var _this = this;
        return Promise.resolve(this._optionsFactory())
            .then(function (results) {
            if (_this._open) {
                if (results && isArray(results) && results.length) {
                    _this._options = results;
                    _this._adapter.setOptions(results, _this._selectedIndex, _this._dense, _this._iconClass);
                }
                else if (_this._open) {
                    _this._closeMenu();
                }
            }
        })
            .catch(function () {
            if (_this._open) {
                _this._closeMenu();
            }
        });
    };
    MenuFoundation.prototype._menuInteraction = function (evt) {
        switch (evt.type) {
            case LIST_ITEM_CONSTANTS.events.SELECTED:
                this._handleSelectEvent(evt);
                break;
            default:
                break;
        }
    };
    MenuFoundation.prototype._handleSelectEvent = function (evt) {
        var data = evt.detail;
        var index = this._adapter.getListElementIndex(data.listItem);
        this._selectOptionByIndex(index);
    };
    MenuFoundation.prototype._selectOptionByIndex = function (index) {
        this._selectedIndex = index;
        this._adapter.dispatchEvent(MENU_CONSTANTS.events.SELECT, { index: index, value: this._nonDividerOptions[index].value });
        this._closeMenu();
    };
    Object.defineProperty(MenuFoundation.prototype, "open", {
        get: function () {
            return this._open;
        },
        set: function (value) {
            value = Boolean(value);
            if (this._open !== value) {
                this._open = value;
                if (value) {
                    this._openMenu();
                }
                else {
                    this._closeMenu();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuFoundation.prototype, "popupElement", {
        get: function () {
            return this._adapter.popupElement;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuFoundation.prototype, "options", {
        get: function () {
            return this._options || [];
        },
        set: function (options) {
            this._options = options;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuFoundation.prototype, "optionsFactory", {
        set: function (factory) {
            this._optionsFactory = factory;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuFoundation.prototype, "selectedIndex", {
        get: function () {
            return this._selectedIndex;
        },
        set: function (value) {
            if (this._selectedIndex !== value) {
                this._selectedIndex = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuFoundation.prototype, "placement", {
        get: function () {
            return this._placement;
        },
        set: function (value) {
            if (this._placement !== value) {
                this._placement = value || PopupPlacement.BottomLeft;
                this._adapter.setAttribute(MENU_CONSTANTS.attributes.PLACEMENT, this._placement);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuFoundation.prototype, "dense", {
        get: function () {
            return this._dense;
        },
        set: function (value) {
            this._dense = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuFoundation.prototype, "iconClass", {
        get: function () {
            return this._iconClass;
        },
        set: function (value) {
            this._iconClass = value;
        },
        enumerable: true,
        configurable: true
    });
    return MenuFoundation;
}());
export { MenuFoundation };
