import { __decorate, __extends } from "tslib";
import { CustomElement, coerceNumber, hideElementVisually, upgradeProperty } from '@tyler-components-web/core';
import { TooltipAdapter } from './tooltip-adapter';
import { TooltipFoundation } from './tooltip-foundation';
import { TOOLTIP_CONSTANTS } from './tooltip-constants';
/**
 * The custom element class behind the `<tcw-tooltip>` element.
 */
var TooltipComponent = /** @class */ (function (_super) {
    __extends(TooltipComponent, _super);
    function TooltipComponent() {
        var _this = _super.call(this) || this;
        _this._foundation = new TooltipFoundation(new TooltipAdapter(_this));
        return _this;
    }
    Object.defineProperty(TooltipComponent, "observedAttributes", {
        get: function () {
            return [
                TOOLTIP_CONSTANTS.attributes.TEXT,
                TOOLTIP_CONSTANTS.attributes.TARGET,
                TOOLTIP_CONSTANTS.attributes.DELAY,
                TOOLTIP_CONSTANTS.attributes.POSITION
            ];
        },
        enumerable: true,
        configurable: true
    });
    TooltipComponent.prototype.connectedCallback = function () {
        var _this = this;
        upgradeProperty(this, 'text');
        upgradeProperty(this, 'builder');
        upgradeProperty(this, 'target');
        upgradeProperty(this, 'delay');
        upgradeProperty(this, 'position');
        hideElementVisually(this);
        requestAnimationFrame(function () { return _this._foundation.initialize(); });
    };
    TooltipComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    TooltipComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case TOOLTIP_CONSTANTS.attributes.TEXT:
                this.text = newValue;
                break;
            case TOOLTIP_CONSTANTS.attributes.TARGET:
                this.target = newValue;
                break;
            case TOOLTIP_CONSTANTS.attributes.DELAY:
                this.delay = coerceNumber(newValue);
                break;
            case TOOLTIP_CONSTANTS.attributes.POSITION:
                this.position = newValue;
                break;
        }
    };
    Object.defineProperty(TooltipComponent.prototype, "text", {
        /** Gets/sets the tooltip text. */
        get: function () {
            return this._foundation.text;
        },
        set: function (value) {
            this._foundation.text = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TooltipComponent.prototype, "builder", {
        /** Sets the tooltip builder function for display complex tooltip content. */
        set: function (value) {
            this._foundation.builder = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TooltipComponent.prototype, "target", {
        /** Gets/sets the target element selector. */
        get: function () {
            return this._foundation.target;
        },
        set: function (value) {
            this._foundation.target = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TooltipComponent.prototype, "delay", {
        /** The tooltip display delay in milliseconds. */
        get: function () {
            return this._foundation.delay;
        },
        set: function (value) {
            this._foundation.delay = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TooltipComponent.prototype, "position", {
        /** Gets/sets the position. */
        get: function () {
            return this._foundation.position;
        },
        set: function (value) {
            this._foundation.position = value;
        },
        enumerable: true,
        configurable: true
    });
    TooltipComponent = __decorate([
        CustomElement({
            name: TOOLTIP_CONSTANTS.elementName
        })
    ], TooltipComponent);
    return TooltipComponent;
}(HTMLElement));
export { TooltipComponent };
