import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty, coerceBoolean } from '@tyler-components-web/core';
import { StepperAdapter } from './stepper-adapter';
import { StepperFoundation } from './stepper-foundation';
import { STEPPER_CONSTANTS } from './stepper-constants';
var template = require('./stepper.html');
var styles = require('./stepper.css');
/**
 * The custom element class behind the `<tcw-stepper>` element.
 */
var StepperComponent = /** @class */ (function (_super) {
    __extends(StepperComponent, _super);
    function StepperComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, STEPPER_CONSTANTS.elementName, template, styles);
        _this._foundation = new StepperFoundation(new StepperAdapter(_this));
        return _this;
    }
    Object.defineProperty(StepperComponent, "observedAttributes", {
        get: function () {
            return [
                STEPPER_CONSTANTS.attributes.SELECTED_INDEX,
                STEPPER_CONSTANTS.attributes.LINEAR,
                STEPPER_CONSTANTS.attributes.ALTERNATIVE,
                STEPPER_CONSTANTS.attributes.LAYOUT_MODE,
                STEPPER_CONSTANTS.attributes.LAYOUT_ALIGN
            ];
        },
        enumerable: true,
        configurable: true
    });
    StepperComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'steps');
        upgradeProperty(this, 'selectedIndex');
        upgradeProperty(this, 'linear');
        upgradeProperty(this, 'alternative');
        upgradeProperty(this, 'layoutMode');
        upgradeProperty(this, 'layoutAlign');
        if (this.hasAttribute(STEPPER_CONSTANTS.attributes.SELECTED_INDEX)) {
            this.selectedIndex = Number(this.getAttribute(STEPPER_CONSTANTS.attributes.SELECTED_INDEX)) || 0;
        }
        if (this.hasAttribute(STEPPER_CONSTANTS.attributes.LINEAR) && this.getAttribute(STEPPER_CONSTANTS.attributes.LINEAR) === 'true') {
            this.linear = coerceBoolean(this.getAttribute(STEPPER_CONSTANTS.attributes.LINEAR));
        }
        if (this.hasAttribute(STEPPER_CONSTANTS.attributes.ALTERNATIVE) && this.getAttribute(STEPPER_CONSTANTS.attributes.ALTERNATIVE) === 'true') {
            this.alternative = coerceBoolean(this.getAttribute(STEPPER_CONSTANTS.attributes.ALTERNATIVE));
        }
        if (this.hasAttribute(STEPPER_CONSTANTS.attributes.LAYOUT_MODE)) {
            this.layoutMode = this.getAttribute(STEPPER_CONSTANTS.attributes.LAYOUT_MODE);
        }
        if (this.hasAttribute(STEPPER_CONSTANTS.attributes.ALTERNATIVE) && this.getAttribute(STEPPER_CONSTANTS.attributes.ALTERNATIVE) === 'true') {
            this.layoutAlign = this.getAttribute(STEPPER_CONSTANTS.attributes.LAYOUT_ALIGN);
        }
        this._foundation.initialize();
    };
    StepperComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    StepperComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case STEPPER_CONSTANTS.attributes.SELECTED_INDEX:
                this.selectedIndex = Number(newValue) || 0;
                break;
            case STEPPER_CONSTANTS.attributes.LINEAR:
                this.linear = coerceBoolean(newValue);
                break;
            case STEPPER_CONSTANTS.attributes.ALTERNATIVE:
                this.alternative = coerceBoolean(newValue);
                break;
            case STEPPER_CONSTANTS.attributes.LAYOUT_MODE:
                this.layoutMode = newValue;
                break;
            case STEPPER_CONSTANTS.attributes.LAYOUT_ALIGN:
                this.layoutAlign = newValue;
                break;
        }
    };
    Object.defineProperty(StepperComponent.prototype, "steps", {
        /** The step configurations. */
        get: function () {
            return this._foundation.steps;
        },
        set: function (value) {
            this._foundation.steps = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperComponent.prototype, "selectedIndex", {
        /** The active step index. */
        get: function () {
            return this._foundation.selectedIndex;
        },
        set: function (value) {
            this._foundation.selectedIndex = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperComponent.prototype, "linear", {
        /** Whether the stepper is linear or non-linear. */
        get: function () {
            return this._foundation.linear;
        },
        set: function (value) {
            this._foundation.linear = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperComponent.prototype, "alternative", {
        /** Whether the stepper uses the default or alternative label layout mode. */
        get: function () {
            return this._foundation.alternative;
        },
        set: function (value) {
            this._foundation.alternative = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperComponent.prototype, "layoutMode", {
        /** The layout mode of the stepper. */
        get: function () {
            return this._foundation.layoutMode;
        },
        set: function (value) {
            this._foundation.layoutMode = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperComponent.prototype, "layoutAlign", {
        /** The layout alignment of the stepper. */
        get: function () {
            return this._foundation.layoutAlign;
        },
        set: function (value) {
            this._foundation.layoutAlign = value;
        },
        enumerable: true,
        configurable: true
    });
    StepperComponent = __decorate([
        CustomElement({
            name: STEPPER_CONSTANTS.elementName
        })
    ], StepperComponent);
    return StepperComponent;
}(HTMLElement));
export { StepperComponent };
