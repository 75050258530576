import { isDefined } from '@tyler-components-web/core';
import { DRAWER_CONSTANTS } from './drawer-constants';
var DrawerFoundation = /** @class */ (function () {
    function DrawerFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._open = true;
        this._type = 'permanent';
        this._direction = 'left';
        this._isInitialized = false;
        this._backdropClickListener = function (evt) { return _this._onBackdropClick(evt); };
    }
    DrawerFoundation.prototype.initialize = function () {
        if (this._type === 'permanent' && !this._open) {
            this._open = true;
        }
        if (!this._open) {
            this._adapter.setOpen(this._type, false, false);
        }
        else {
            if (this._type === 'modal') {
                this._adapter.setBackdrop(this._open, this._backdropClickListener);
            }
        }
        this._setType();
        this._adapter.setDirection(this._direction);
        this._isInitialized = true;
    };
    DrawerFoundation.prototype._setType = function () {
        this._adapter.removeDrawerClass([
            DRAWER_CONSTANTS.classes.MODAL,
            DRAWER_CONSTANTS.classes.DISMISSIBLE,
            DRAWER_CONSTANTS.classes.MINI,
            DRAWER_CONSTANTS.classes.MINI_HOVER
        ]);
        switch (this._type) {
            case 'modal':
                this._adapter.setDrawerClass(DRAWER_CONSTANTS.classes.MODAL);
                break;
            case 'dismissible':
                this._adapter.setDrawerClass(DRAWER_CONSTANTS.classes.DISMISSIBLE);
                break;
            case 'mini':
            case 'mini-hover':
                this._adapter.setDrawerClass(DRAWER_CONSTANTS.classes.MINI);
                if (this._type === 'mini-hover') {
                    this._adapter.setDrawerClass(DRAWER_CONSTANTS.classes.MINI_HOVER);
                }
                break;
        }
    };
    DrawerFoundation.prototype._onBackdropClick = function (evt) {
        this.open = false;
        this._adapter.emitEvent(DRAWER_CONSTANTS.events.CLOSE);
    };
    Object.defineProperty(DrawerFoundation.prototype, "open", {
        /** Toggles whether a `dismissible` or `modal` drawer is open or not. Has no effect on `permanent` drawers. */
        get: function () {
            return this._open;
        },
        set: function (value) {
            if (this._open !== value) {
                if (this._type !== 'permanent') {
                    this._open = value;
                }
                if (this._isInitialized && this._type !== 'permanent') {
                    this._adapter.setOpen(this._type, this._open);
                    if (this._type === 'modal') {
                        this._adapter.setBackdrop(this._open, this._backdropClickListener);
                    }
                }
                this._adapter.setHostAttribute(DRAWER_CONSTANTS.attributes.OPEN, isDefined(this._open) ? this._open.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DrawerFoundation.prototype, "type", {
        /** Gets/Sets the type of drawer to use: `permanent` (default), `dismissible`, `modal`. */
        get: function () {
            return this._type;
        },
        set: function (value) {
            if (this._type !== value) {
                this._type = value;
                this._setType();
                if (this._isInitialized) {
                    // If we are changing to the modal type, we always start with it closed
                    if (this._type === 'modal') {
                        this._open = false;
                        this._adapter.setOpen(this._type, false, false);
                        this._adapter.setBackdrop(false, this._backdropClickListener);
                        this._adapter.setHostAttribute(DRAWER_CONSTANTS.attributes.TYPE, isDefined(this._type) ? this._type.toString() : '');
                        this._adapter.setHostAttribute(DRAWER_CONSTANTS.attributes.OPEN, isDefined(this._open) ? this._open.toString() : '');
                        return;
                    }
                    else {
                        this._adapter.removeBackdrop();
                    }
                    if (this._type === 'permanent') {
                        this._open = true;
                    }
                    this._adapter.setOpen(this._type, this._open, false);
                }
                this._adapter.setHostAttribute(DRAWER_CONSTANTS.attributes.TYPE, isDefined(this._type) ? this._type.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DrawerFoundation.prototype, "direction", {
        /** Controls the animation direction of the drawer for positioning on the left vs. right side of the screen. */
        get: function () {
            return this._direction;
        },
        set: function (value) {
            if (this._direction !== value) {
                this._direction = value;
                this._adapter.setDirection(this._direction);
                this._adapter.setHostAttribute(DRAWER_CONSTANTS.attributes.DIRECTION, isDefined(this._direction) ? this._direction.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    return DrawerFoundation;
}());
export { DrawerFoundation };
