import { __decorate, __extends } from "tslib";
import { CustomElement } from '@tyler-components-web/core';
import { AccordionAdapter } from './accordion-adapter';
import { AccordionFoundation } from './accordion-foundation';
import { ACCORDION_CONSTANTS } from './accordion-constants';
import { ExpansionPanelComponent } from '../expansion-panel';
/**
 * The custom element class behind the `<tcw-accordion>` web component.
 *
 * This component is intended to wrap and orchestrate child expansion panels
 * so that only one expansion panel is open at a time.
 */
var AccordionComponent = /** @class */ (function (_super) {
    __extends(AccordionComponent, _super);
    function AccordionComponent() {
        var _this = _super.call(this) || this;
        _this._foundation = new AccordionFoundation(new AccordionAdapter(_this));
        return _this;
    }
    Object.defineProperty(AccordionComponent, "observedAttributes", {
        get: function () {
            return [
                ACCORDION_CONSTANTS.attributes.PANEL_SELECTOR
            ];
        },
        enumerable: true,
        configurable: true
    });
    AccordionComponent.prototype.initializedCallback = function () {
        this._foundation.initialize();
    };
    AccordionComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    AccordionComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case ACCORDION_CONSTANTS.attributes.PANEL_SELECTOR:
                this.panelSelector = newValue;
                break;
        }
    };
    Object.defineProperty(AccordionComponent.prototype, "panelSelector", {
        /** Gets/sets the selector to use for finding the child expansion panels. Defaults to searching the direct children for `<tcw-expansion-panel>` elements. */
        get: function () {
            return this._foundation.panelSelector;
        },
        set: function (value) {
            this._foundation.panelSelector = value;
        },
        enumerable: true,
        configurable: true
    });
    AccordionComponent = __decorate([
        CustomElement({
            name: ACCORDION_CONSTANTS.elementName,
            dependencies: [ExpansionPanelComponent]
        })
    ], AccordionComponent);
    return AccordionComponent;
}(HTMLElement));
export { AccordionComponent };
