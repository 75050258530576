import { getShadowElement, emitEvent } from '@tyler-components-web/core';
import { PROFILE_CARD_CONSTANTS } from './profile-card-constants';
var ProfileCardAdapter = /** @class */ (function () {
    function ProfileCardAdapter(_component) {
        this._component = _component;
        this._fullNameElement = getShadowElement(_component, PROFILE_CARD_CONSTANTS.selectors.FULL_NAME);
        this._emailElement = getShadowElement(_component, PROFILE_CARD_CONSTANTS.selectors.EMAIL);
        this._avatarElement = getShadowElement(_component, PROFILE_CARD_CONSTANTS.selectors.AVATAR);
        this._actionToolbar = getShadowElement(_component, PROFILE_CARD_CONSTANTS.selectors.ACTION_TOOLBAR);
        this._profileButton = getShadowElement(_component, PROFILE_CARD_CONSTANTS.selectors.PROFILE_BUTTON);
        this._signOutButton = getShadowElement(_component, PROFILE_CARD_CONSTANTS.selectors.SIGN_OUT_BUTTON);
    }
    ProfileCardAdapter.prototype.emitEvent = function (type, data, bubble) {
        if (bubble === void 0) { bubble = true; }
        emitEvent(this._component, type, data, bubble);
    };
    ProfileCardAdapter.prototype.setFullName = function (value) {
        this._fullNameElement.textContent = value;
        this._avatarElement.text = value;
    };
    ProfileCardAdapter.prototype.setEmail = function (value) {
        this._emailElement.textContent = value;
    };
    ProfileCardAdapter.prototype.setActionToolbarVisibility = function (isVisible) {
        if (isVisible) {
            this._actionToolbar.style.removeProperty('display');
        }
        else {
            this._actionToolbar.style.display = 'none';
        }
    };
    ProfileCardAdapter.prototype.setProfileButtonVisibility = function (isVisible) {
        if (isVisible) {
            this._profileButton.style.removeProperty('display');
        }
        else {
            this._profileButton.style.display = 'none';
        }
    };
    ProfileCardAdapter.prototype.setSignOutButtonVisibility = function (isVisible) {
        if (isVisible) {
            this._signOutButton.style.removeProperty('display');
        }
        else {
            this._signOutButton.style.display = 'none';
        }
    };
    ProfileCardAdapter.prototype.setProfileButtonListener = function (listener) {
        this._profileButton.addEventListener('click', listener);
    };
    ProfileCardAdapter.prototype.setSignOutButtonListener = function (listener) {
        this._signOutButton.addEventListener('click', listener);
    };
    ProfileCardAdapter.prototype.removeProfileButtonListener = function (listener) {
        this._profileButton.removeEventListener('click', listener);
    };
    ProfileCardAdapter.prototype.removeSignOutButtonListener = function (listener) {
        this._signOutButton.removeEventListener('click', listener);
    };
    ProfileCardAdapter.prototype.requestProfileButtonFocus = function () {
        var _this = this;
        window.requestAnimationFrame(function () { return _this._profileButton.focus(); });
    };
    ProfileCardAdapter.prototype.requestSignOutButtonFocus = function () {
        var _this = this;
        window.requestAnimationFrame(function () { return _this._signOutButton.focus(); });
    };
    return ProfileCardAdapter;
}());
export { ProfileCardAdapter };
