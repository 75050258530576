var OmnibarHelpButtonFoundation = /** @class */ (function () {
    function OmnibarHelpButtonFoundation(_adapter) {
        this._adapter = _adapter;
        this._options = [];
        this._isInitialized = false;
    }
    OmnibarHelpButtonFoundation.prototype.initialize = function () {
        this._adapter.initialize();
        this._adapter.setMenuOptions(this._options);
        this._isInitialized = true;
    };
    OmnibarHelpButtonFoundation.prototype.disconnect = function () {
        this._isInitialized = false;
    };
    Object.defineProperty(OmnibarHelpButtonFoundation.prototype, "options", {
        set: function (value) {
            this._options = value;
            if (this._isInitialized) {
                this._adapter.setMenuOptions(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    return OmnibarHelpButtonFoundation;
}());
export { OmnibarHelpButtonFoundation };
