import { debounce } from '@tyler-components-web/core';
import { EXPANSION_PANEL_CONSTANTS } from './expansion-panel-constants';
var ExpansionPanelFoundation = /** @class */ (function () {
    function ExpansionPanelFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._open = false;
        this._orientation = EXPANSION_PANEL_CONSTANTS.strings.ORIENTATION_VERTICAL;
        this._isInitialized = false;
        this._clickListener = debounce(function (evt) { return _this._onClick(evt); }, EXPANSION_PANEL_CONSTANTS.numbers.CLICK_DEBOUNCE_THRESHOLD, true);
        this._keydownListener = function (evt) { return _this._onKeydown(evt); };
        this._headerSlotChangeListener = function (evt) { return _this._onHeaderSlotChanged(evt); };
    }
    ExpansionPanelFoundation.prototype.initialize = function () {
        this.connect();
        this._adapter.initialize(this._open, this._orientation);
        this._isInitialized = true;
    };
    ExpansionPanelFoundation.prototype.connect = function () {
        this._adapter.registerHeaderSlotListener(this._headerSlotChangeListener);
        this._adapter.registerClickListener(this._clickListener);
        this._adapter.registerKeydownListener(this._keydownListener);
    };
    ExpansionPanelFoundation.prototype.disconnect = function () {
        this._adapter.deregisterHeaderSlotListener(this._headerSlotChangeListener);
        this._adapter.deregisterClickListener(this._clickListener);
        this._adapter.deregisterKeydownListener(this._keydownListener);
    };
    Object.defineProperty(ExpansionPanelFoundation.prototype, "open", {
        /** Controls the open state of the panel. */
        get: function () {
            return this._open;
        },
        set: function (value) {
            var _this = this;
            value = Boolean(value);
            if (value !== this._open) {
                if (this._isInitialized) {
                    if (value) {
                        if (this._openCallback) {
                            Promise.resolve(this._openCallback()).then(function () {
                                _this._open = value;
                                _this._openPanel();
                            }).catch(function () { });
                        }
                        else {
                            this._open = value;
                            this._openPanel();
                        }
                    }
                    else {
                        if (this._closeCallback) {
                            Promise.resolve(this._closeCallback()).then(function () {
                                _this._open = value;
                                _this._closePanel();
                            }).catch(function () { });
                        }
                        else {
                            this._open = value;
                            this._closePanel();
                        }
                    }
                }
                else {
                    this._open = value;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExpansionPanelFoundation.prototype, "openCallback", {
        set: function (callback) {
            this._openCallback = callback;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExpansionPanelFoundation.prototype, "closeCallback", {
        set: function (callback) {
            this._closeCallback = callback;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExpansionPanelFoundation.prototype, "orientation", {
        get: function () {
            return this._orientation;
        },
        set: function (value) {
            this._orientation = value;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Handles click events on the header element.
     * @param {MouseEvent} evt The click event.
     */
    ExpansionPanelFoundation.prototype._onClick = function (evt) {
        evt.stopPropagation();
        this._toggle();
        this._emitEvent();
    };
    /**
     * Handles keydown events on the header.
     * @param {KeyboardEvent} evt The keydown event
     */
    ExpansionPanelFoundation.prototype._onKeydown = function (evt) {
        evt.stopPropagation();
        if (evt.key === 'Space' || evt.key === 'Enter' || evt.keyCode === 32 || evt.keyCode === 13) {
            evt.preventDefault();
            this._toggle();
            this._emitEvent();
        }
    };
    ExpansionPanelFoundation.prototype._emitEvent = function () {
        if (this._open) {
            this._adapter.emitEvent(EXPANSION_PANEL_CONSTANTS.events.OPEN);
        }
        else {
            this._adapter.emitEvent(EXPANSION_PANEL_CONSTANTS.events.CLOSE);
        }
    };
    /**
     * Toggles the collapsed state of the panel.
     */
    ExpansionPanelFoundation.prototype._toggle = function () {
        this.open = !this.open;
    };
    ExpansionPanelFoundation.prototype._openPanel = function () {
        this._adapter.setHostAttribute(EXPANSION_PANEL_CONSTANTS.attributes.OPEN, '');
        this._adapter.setOpenState(true, this._orientation);
    };
    ExpansionPanelFoundation.prototype._closePanel = function () {
        this._adapter.removeHostAttribute(EXPANSION_PANEL_CONSTANTS.attributes.OPEN);
        this._adapter.setOpenState(false, this._orientation);
    };
    ExpansionPanelFoundation.prototype._onHeaderSlotChanged = function (evt) {
        this._adapter.setHeaderVisibility(!!evt.target.assignedNodes().length);
    };
    return ExpansionPanelFoundation;
}());
export { ExpansionPanelFoundation };
