import { __decorate, __extends } from "tslib";
import { CustomElement, attachLightTemplate, coerceNumber, coerceBoolean, upgradeProperty } from '@tyler-components-web/core';
import { OmnibarNotificationButtonAdapter } from './omnibar-notification-button-adapter';
import { OmnibarNotificationButtonFoundation } from './omnibar-notification-button-foundation';
import { OMNIBAR_NOTIFICATION_BUTTON_CONSTANTS } from './omnibar-notification-button-constants';
import { IconButtonComponent } from '../../icon-button';
import { TooltipComponent } from '../../tooltip';
import { BadgeComponent } from '../../badge';
var template = require('./omnibar-notification-button.html');
var OmnibarNotificationButtonComponent = /** @class */ (function (_super) {
    __extends(OmnibarNotificationButtonComponent, _super);
    function OmnibarNotificationButtonComponent() {
        var _this = _super.call(this) || this;
        _this._foundation = new OmnibarNotificationButtonFoundation(new OmnibarNotificationButtonAdapter(_this));
        return _this;
    }
    Object.defineProperty(OmnibarNotificationButtonComponent, "observedAttributes", {
        get: function () {
            return [
                OMNIBAR_NOTIFICATION_BUTTON_CONSTANTS.attributes.COUNT,
                OMNIBAR_NOTIFICATION_BUTTON_CONSTANTS.attributes.DOT,
                OMNIBAR_NOTIFICATION_BUTTON_CONSTANTS.attributes.THEME,
                OMNIBAR_NOTIFICATION_BUTTON_CONSTANTS.attributes.SHOW_BADGE,
            ];
        },
        enumerable: true,
        configurable: true
    });
    OmnibarNotificationButtonComponent.prototype.initializedCallback = function () {
        attachLightTemplate(this, template);
    };
    OmnibarNotificationButtonComponent.prototype.connectedCallback = function () {
        upgradeProperty(this, 'count');
        upgradeProperty(this, 'dot');
        upgradeProperty(this, 'showBadge');
        upgradeProperty(this, 'theme');
        this._foundation.initialize();
    };
    OmnibarNotificationButtonComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case OMNIBAR_NOTIFICATION_BUTTON_CONSTANTS.attributes.COUNT:
                this.count = coerceNumber(newValue);
                break;
            case OMNIBAR_NOTIFICATION_BUTTON_CONSTANTS.attributes.DOT:
                this.dot = this.hasAttribute(OMNIBAR_NOTIFICATION_BUTTON_CONSTANTS.attributes.DOT);
                break;
            case OMNIBAR_NOTIFICATION_BUTTON_CONSTANTS.attributes.THEME:
                this.theme = newValue;
                break;
            case OMNIBAR_NOTIFICATION_BUTTON_CONSTANTS.attributes.SHOW_BADGE:
                this.showBadge = coerceBoolean(newValue);
                break;
        }
    };
    Object.defineProperty(OmnibarNotificationButtonComponent.prototype, "count", {
        get: function () {
            return this._foundation.count;
        },
        set: function (value) {
            this._foundation.count = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarNotificationButtonComponent.prototype, "dot", {
        get: function () {
            return this._foundation.dot;
        },
        set: function (value) {
            this._foundation.dot = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarNotificationButtonComponent.prototype, "theme", {
        get: function () {
            return this._foundation.theme;
        },
        set: function (value) {
            this._foundation.theme = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarNotificationButtonComponent.prototype, "showBadge", {
        get: function () {
            return this._foundation.showBadge;
        },
        set: function (value) {
            this._foundation.showBadge = value;
        },
        enumerable: true,
        configurable: true
    });
    OmnibarNotificationButtonComponent = __decorate([
        CustomElement({
            name: OMNIBAR_NOTIFICATION_BUTTON_CONSTANTS.elementName,
            dependencies: [
                IconButtonComponent,
                TooltipComponent,
                BadgeComponent
            ]
        })
    ], OmnibarNotificationButtonComponent);
    return OmnibarNotificationButtonComponent;
}(HTMLElement));
export { OmnibarNotificationButtonComponent };
