import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty } from '@tyler-components-web/core';
import { BusyIndicatorFoundation } from './busy-indicator-foundation';
import { BusyIndicatorAdapter } from './busy-indicator-adapter';
import { BUSY_INDICATOR_CONSTANTS } from './busy-indicator-constants';
import { LinearProgressComponent } from '../linear-progress';
import { ProgressSpinnerComponent } from '../progress-spinner';
import { ButtonComponent } from '../button';
import { BackdropComponent } from '../backdrop';
var template = require('./busy-indicator.html');
var styles = require('./busy-indicator.css');
/**
 * A web component that renders a busy indicator covering its parent container.
 */
var BusyIndicatorComponent = /** @class */ (function (_super) {
    __extends(BusyIndicatorComponent, _super);
    function BusyIndicatorComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, BUSY_INDICATOR_CONSTANTS.elementName, template, styles);
        _this._foundation = new BusyIndicatorFoundation(new BusyIndicatorAdapter(_this));
        return _this;
    }
    Object.defineProperty(BusyIndicatorComponent, "observedAttributes", {
        get: function () {
            return [
                BUSY_INDICATOR_CONSTANTS.attributes.TITLE_TEXT,
                BUSY_INDICATOR_CONSTANTS.attributes.MESSAGE,
                BUSY_INDICATOR_CONSTANTS.attributes.CANCEL,
                BUSY_INDICATOR_CONSTANTS.attributes.AUTO_ELLIPSIS
            ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorComponent.prototype, "titleText", {
        get: function () {
            return this._foundation.titleText;
        },
        /** The title to be displayed. */
        set: function (value) {
            this._foundation.titleText = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorComponent.prototype, "message", {
        get: function () {
            return this._foundation.message;
        },
        /** The message to be displayed. */
        set: function (value) {
            this._foundation.message = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorComponent.prototype, "cancel", {
        get: function () {
            return this._foundation.cancel;
        },
        /** Controls whether the cancel button is visible or not. */
        set: function (canCancel) {
            this._foundation.cancel = canCancel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorComponent.prototype, "spinner", {
        get: function () {
            return this._foundation.spinner;
        },
        /** Controls whether the progress spinner is visible or not. */
        set: function (showSpinner) {
            this._foundation.spinner = showSpinner;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorComponent.prototype, "progressBar", {
        get: function () {
            return this._foundation.progressBar;
        },
        /** Controls whether the progres bar is visible or not. */
        set: function (showProgressBar) {
            this._foundation.progressBar = showProgressBar;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorComponent.prototype, "progressBarDeterminate", {
        get: function () {
            return this._foundation.progressBarDeterminate;
        },
        /** Sets the progress bar determinate state. */
        set: function (determinate) {
            this._foundation.progressBarDeterminate = determinate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorComponent.prototype, "progress", {
        get: function () {
            return this._foundation.progress;
        },
        /** The progress amount of the progress bar. */
        set: function (value) {
            this._foundation.progress = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorComponent.prototype, "buffer", {
        get: function () {
            return this._foundation.buffer;
        },
        /** The buffer amount of the progress bar. */
        set: function (value) {
            this._foundation.buffer = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorComponent.prototype, "width", {
        get: function () {
            return this._foundation.width;
        },
        /** Sets the width of the busy indicator element. */
        set: function (value) {
            this._foundation.width = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorComponent.prototype, "isDestroyed", {
        /** Gets the destroyed status. */
        get: function () {
            return this._foundation.isDestroyed;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorComponent.prototype, "autoEllipsis", {
        get: function () {
            return this._foundation.autoEllipsis;
        },
        /** Controls if the message manager will automatically display an ellipsis after the last message. */
        set: function (value) {
            this._foundation.autoEllipsis = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusyIndicatorComponent.prototype, "delay", {
        get: function () {
            return this._foundation.delay;
        },
        /** The delay value before the element becomes visible after being added to the DOM. */
        set: function (value) {
            this._foundation.delay = value;
        },
        enumerable: true,
        configurable: true
    });
    BusyIndicatorComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'titleText');
        upgradeProperty(this, 'message');
        upgradeProperty(this, 'cancel');
        upgradeProperty(this, 'spinner');
        upgradeProperty(this, 'progressBar');
        upgradeProperty(this, 'progressBarDeterminate');
        upgradeProperty(this, 'progress');
        upgradeProperty(this, 'buffer');
        upgradeProperty(this, 'width');
        upgradeProperty(this, 'autoEllipsis');
        upgradeProperty(this, 'delay');
        this._foundation.show();
    };
    BusyIndicatorComponent.prototype.disconnectedCallback = function () {
        this._foundation.destroy();
    };
    BusyIndicatorComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case BUSY_INDICATOR_CONSTANTS.attributes.TITLE_TEXT:
                this.titleText = newValue;
                break;
            case BUSY_INDICATOR_CONSTANTS.attributes.MESSAGE:
                this.message = newValue;
                break;
            case BUSY_INDICATOR_CONSTANTS.attributes.CANCEL:
                this.cancel = newValue === 'true';
                break;
            case BUSY_INDICATOR_CONSTANTS.attributes.AUTO_ELLIPSIS:
                this.autoEllipsis = newValue === 'true';
                break;
        }
    };
    /**
     * Adds a message to the busy indicator message list.
     * @param {string} message The message to add to this busy indicator.
     */
    BusyIndicatorComponent.prototype.addMessage = function (message) {
        this._foundation.addMessage(message);
    };
    /**
     * Adds a message to the busy indicator message list.
     * @param {string} message The message to add to this busy indicator.
     */
    BusyIndicatorComponent.prototype.removeMessage = function (id) {
        this._foundation.removeMessage(id);
    };
    /**
     * Removes the element from the DOM after the provided delay. The default delay time is 250ms.
     * @param {string | number} messageId The message id to remove.
     * @param {boolean} [force=false] Whether to force removal of the busy indicator or not.
     */
    BusyIndicatorComponent.prototype.hide = function (messageId, force) {
        if (force === void 0) { force = false; }
        this._foundation.hide(messageId, force);
    };
    BusyIndicatorComponent = __decorate([
        CustomElement({
            name: BUSY_INDICATOR_CONSTANTS.elementName,
            dependencies: [
                BackdropComponent,
                ProgressSpinnerComponent,
                LinearProgressComponent,
                ButtonComponent
            ]
        })
    ], BusyIndicatorComponent);
    return BusyIndicatorComponent;
}(HTMLElement));
export { BusyIndicatorComponent };
