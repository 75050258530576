import { isDefined, isString } from '@tyler-components-web/core';
import { getTextColor } from '../utils/color-utils';
import { AVATAR_CONSTANTS } from './avatar-constants';
/**
 * The custom element class behind the `<tcw-avatar>` class.
 */
var AvatarFoundation = /** @class */ (function () {
    function AvatarFoundation(_adapter) {
        this._adapter = _adapter;
        this._text = '';
        this._letterCount = AVATAR_CONSTANTS.numbers.DEFAULT_LETTER_COUNT;
        this._autoColor = true;
        this._initialized = false;
    }
    AvatarFoundation.prototype.initialize = function () {
        this._render();
        this._initialized = true;
    };
    AvatarFoundation.prototype.disconnect = function () {
        this._initialized = false;
    };
    AvatarFoundation.prototype._render = function () {
        this._setText();
        this._setBackgroundImageUrl();
    };
    AvatarFoundation.prototype._setBackgroundImageUrl = function () {
        if (this._imageUrl) {
            this._adapter.setBackgroundImageUrl(this._imageUrl);
        }
        else {
            this._adapter.removeBackgroundImage();
        }
    };
    AvatarFoundation.prototype._setText = function () {
        var data = this._getTextContent(this._text, this._letterCount);
        if (data) {
            this._adapter.setText(data);
        }
        else {
            this._adapter.clearText();
        }
        this._adapter.setBackgroundColor(this._autoColor ? getTextColor(data) : 'none');
    };
    /**
     * Gets the text content to display in the avatar.
     * @param {string} value The text to parse.
     */
    AvatarFoundation.prototype._getTextContent = function (value, count) {
        if (!value || !isString(value) || count <= 0) {
            return '';
        }
        var text;
        if (count === 1) {
            text = value[0].toUpperCase();
        }
        else {
            var words = value.match(/\S+/g) || [];
            text = words.slice(0, count).reduce(function (prev, curr) { return prev += curr[0].toUpperCase(); }, '');
        }
        return text;
    };
    Object.defineProperty(AvatarFoundation.prototype, "text", {
        /** Gets/sets the text to display. */
        get: function () {
            return this._text;
        },
        set: function (value) {
            if (this._text !== value) {
                this._text = value;
                this._adapter.setHostAttribute(AVATAR_CONSTANTS.attributes.TEXT, this._text);
                if (this._initialized) {
                    this._render();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarFoundation.prototype, "letterCount", {
        /** Controls the number of letters to display from the text. By default the text is split on spaces and the first character of each word is used. */
        get: function () {
            return this._letterCount;
        },
        set: function (value) {
            if (this._letterCount !== value) {
                this._letterCount = value;
                this._adapter.setHostAttribute(AVATAR_CONSTANTS.attributes.LETTER_COUNT, isDefined(this._letterCount) ? this._letterCount.toString() : '');
                if (this._initialized) {
                    this._render();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarFoundation.prototype, "imageUrl", {
        /** Sets the background image URL to use. */
        get: function () {
            return this._imageUrl;
        },
        set: function (value) {
            if (this._imageUrl !== value) {
                this._imageUrl = value;
                if (this._initialized) {
                    this._render();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarFoundation.prototype, "autoColor", {
        /** Controls whether the background color set automatically based on the text value. Does not have any effect when an image URL is specified. */
        get: function () {
            return this._autoColor;
        },
        set: function (value) {
            if (this._autoColor !== value) {
                this._autoColor = value;
                this._adapter.setHostAttribute(AVATAR_CONSTANTS.attributes.AUTO_COLOR, isDefined(this._autoColor) ? this._autoColor.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    return AvatarFoundation;
}());
export { AvatarFoundation };
