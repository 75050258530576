import { isArray, isDefined, isNumber, getEventPath } from '@tyler-components-web/core';
import { STEPPER_CONSTANTS } from './stepper-constants';
/**
 * The foundation class behind the `<tcw-stepper>` component.
 */
var StepperFoundation = /** @class */ (function () {
    function StepperFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._steps = [];
        this._selectedIndex = 0;
        this._linear = false;
        this._alternative = false;
        this._layoutMode = 'stretched';
        this._layoutAlign = 'center';
        this._stepListener = function (evt) { return _this._onStepSelected(evt); };
    }
    StepperFoundation.prototype.initialize = function () {
        this._adapter.setLayoutMode(this._layoutMode);
        this._adapter.setLayoutAlign(this._layoutAlign);
        this._adapter.setHostAttribute(STEPPER_CONSTANTS.attributes.SELECTED_INDEX, this._selectedIndex.toString());
        this._adapter.setHostAttribute(STEPPER_CONSTANTS.attributes.LINEAR, this._linear.toString());
        this._adapter.setHostAttribute(STEPPER_CONSTANTS.attributes.ALTERNATIVE, this._alternative.toString());
        this._adapter.setHostAttribute(STEPPER_CONSTANTS.attributes.LAYOUT_MODE, this._layoutMode);
        this._adapter.setHostAttribute(STEPPER_CONSTANTS.attributes.LAYOUT_ALIGN, this._layoutAlign);
        this._render();
    };
    StepperFoundation.prototype.disconnect = function () {
        this._adapter.deregisterStepListener(this._stepListener);
    };
    Object.defineProperty(StepperFoundation.prototype, "steps", {
        /** The step configurations. */
        get: function () {
            return this._steps;
        },
        set: function (value) {
            this._steps = isArray(value) ? value : [];
            this._render();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperFoundation.prototype, "selectedIndex", {
        /** The active step index. */
        get: function () {
            return this._selectedIndex;
        },
        set: function (value) {
            if (!isDefined(value)) {
                value = 0;
            }
            else if (!isNumber(value)) {
                var v = Number(value);
                value = !isNaN(v) ? v : 0;
            }
            if (this._selectedIndex !== value) {
                var previousStepIndex = this._selectedIndex;
                this._selectedIndex = value;
                this._render();
                this._adapter.setHostAttribute(STEPPER_CONSTANTS.attributes.SELECTED_INDEX, this._selectedIndex.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperFoundation.prototype, "linear", {
        /** Whether the stepper is linear or non-linear. */
        get: function () {
            return this._linear;
        },
        set: function (value) {
            value = Boolean(value);
            if (this._linear !== value) {
                this._linear = value;
                this._adapter.setLinearState(this._linear);
                this._adapter.setHostAttribute(STEPPER_CONSTANTS.attributes.LINEAR, this._linear.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperFoundation.prototype, "alternative", {
        /** Whether the stepper uses the default or alternative label layout mode. */
        get: function () {
            return this._alternative;
        },
        set: function (value) {
            value = Boolean(value);
            if (this._alternative !== value) {
                this._alternative = value;
                this._adapter.setAlternativeState(this._alternative);
                this._adapter.setHostAttribute(STEPPER_CONSTANTS.attributes.ALTERNATIVE, this._alternative.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperFoundation.prototype, "layoutMode", {
        /** The layout mode of the stepper. */
        get: function () {
            return this._layoutMode;
        },
        set: function (value) {
            if (!/^(fixed|stretched)$/.test(value)) {
                return;
            }
            if (this._layoutMode !== value) {
                this._layoutMode = value;
                this._adapter.setLayoutMode(this._layoutMode);
                this._adapter.setHostAttribute(STEPPER_CONSTANTS.attributes.LAYOUT_MODE, this._layoutMode);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperFoundation.prototype, "layoutAlign", {
        /** The layout alignment of the stepper. */
        get: function () {
            return this._layoutAlign;
        },
        set: function (value) {
            if (!/^(left|center|right)$/.test(value)) {
                return;
            }
            if (this._layoutAlign !== value) {
                this._layoutAlign = value;
                this._adapter.setLayoutAlign(this._layoutAlign);
                this._adapter.setHostAttribute(STEPPER_CONSTANTS.attributes.LAYOUT_ALIGN, this._layoutAlign);
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Renders the stepper using the current configuration.
     */
    StepperFoundation.prototype._render = function () {
        this._adapter.deregisterStepListener(this._stepListener);
        var stepperConfig = {
            steps: this._steps,
            selectedIndex: this._selectedIndex,
            linear: this._linear,
            alternative: this._alternative,
            layoutMode: this._layoutMode,
            layoutAlign: this.layoutAlign
        };
        this._adapter.createStepper(stepperConfig);
        this._adapter.registerStepListener(this._stepListener);
    };
    /**
     * Handles clicking a step.
     * @param {MouseEvent} evt The mouse event.
     */
    StepperFoundation.prototype._onStepSelected = function (evt) {
        evt.stopPropagation();
        // Steps are not selectable in linear mode
        if (this._linear) {
            return;
        }
        var composedPath = getEventPath(evt);
        var stepElement = composedPath.find(function (el) { return el.classList && el.classList.contains(STEPPER_CONSTANTS.classes.STEP); });
        if (!stepElement) {
            return;
        }
        var stepIndex = this._adapter.getStepIndex(stepElement);
        if (stepIndex === -1) {
            throw new Error('Could not find step element index.');
        }
        if (stepIndex === this._selectedIndex || !this._canActivateStep(stepIndex)) {
            return;
        }
        this._adapter.emitEvent(STEPPER_CONSTANTS.events.SELECT, stepIndex);
    };
    /** Determines if the provided step index can be accessed. */
    StepperFoundation.prototype._canActivateStep = function (stepIndex) {
        var stepConfig = this._steps[stepIndex];
        if (stepConfig.completed && !stepConfig.editable) {
            return false;
        }
        return true;
    };
    return StepperFoundation;
}());
export { StepperFoundation };
