import { parseTemplateString, emitEvent } from '@tyler-components-web/core';
import { DATEPICKER_CONSTANTS } from './datepicker-constants';
import { PopupPlacement, POPUP_CONSTANTS, PopupAnimationType } from '../popup';
import { OPTION_CONSTANTS } from '../select';
import { ICON_CLASS_NAME } from '../constants';
var templateCalendar = require('./datepicker-calendar.html');
var DatepickerAdapter = /** @class */ (function () {
    function DatepickerAdapter(_component) {
        this._component = _component;
    }
    Object.defineProperty(DatepickerAdapter.prototype, "inputElement", {
        get: function () {
            if (!this._inputElement) {
                this._inputElement = this._component.querySelector(DATEPICKER_CONSTANTS.selectors.INPUT);
            }
            return this._inputElement;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatepickerAdapter.prototype, "iconElement", {
        get: function () {
            if (!this._iconElement) {
                this._iconElement = this._component.querySelector(DATEPICKER_CONSTANTS.selectors.ICON);
            }
            return this._iconElement;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatepickerAdapter.prototype, "labelElement", {
        get: function () {
            if (!this._labelElement) {
                this._labelElement = this._component.querySelector(DATEPICKER_CONSTANTS.selectors.LABEL);
            }
            return this._labelElement;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatepickerAdapter.prototype, "labelContainer", {
        get: function () {
            if (!this._labelContainer) {
                this._labelContainer = this._component.querySelector(DATEPICKER_CONSTANTS.selectors.LABEL_CONTAINER);
            }
            return this._labelContainer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatepickerAdapter.prototype, "popupElement", {
        get: function () {
            return this._popupElement;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatepickerAdapter.prototype, "prevMonthButtonElement", {
        get: function () {
            if (!this._popupElement) {
                return null;
            }
            return this._popupElement.querySelector(DATEPICKER_CONSTANTS.selectors.PREV_MONTH_BUTTON);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatepickerAdapter.prototype, "nextMonthButtonElement", {
        get: function () {
            if (!this._popupElement) {
                return null;
            }
            return this._popupElement.querySelector(DATEPICKER_CONSTANTS.selectors.NEXT_MONTH_BUTTON);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatepickerAdapter.prototype, "monthSelectElement", {
        get: function () {
            if (!this._popupElement) {
                return null;
            }
            return this._popupElement.querySelector(DATEPICKER_CONSTANTS.selectors.MONTH_SELECT);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatepickerAdapter.prototype, "yearSelectElement", {
        get: function () {
            if (!this._popupElement) {
                return null;
            }
            return this._popupElement.querySelector(DATEPICKER_CONSTANTS.selectors.YEAR_SELECT);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatepickerAdapter.prototype, "calendarBodyElement", {
        get: function () {
            if (!this._popupElement) {
                return null;
            }
            return this._popupElement.querySelector(DATEPICKER_CONSTANTS.selectors.CALENDAR_BODY);
        },
        enumerable: true,
        configurable: true
    });
    DatepickerAdapter.prototype.addClass = function (className, element) {
        if (element) {
            element.classList.add(className);
        }
        else {
            this._component.classList.add(className);
        }
    };
    DatepickerAdapter.prototype.hasAttribute = function (attribute, element) {
        return element.hasAttribute(attribute);
    };
    DatepickerAdapter.prototype.setAttribute = function (attribute, value, element) {
        if (element) {
            element.setAttribute(attribute, value);
        }
        else {
            this._component.setAttribute(attribute, value);
        }
    };
    DatepickerAdapter.prototype.getAttribute = function (attribute, element) {
        if (element) {
            return element.getAttribute(attribute);
        }
        else {
            return this._component.getAttribute(attribute);
        }
    };
    DatepickerAdapter.prototype.removeAttribute = function (attribute, element) {
        if (element) {
            element.removeAttribute(attribute);
        }
        else {
            this._component.removeAttribute(attribute);
        }
    };
    DatepickerAdapter.prototype.addEventListener = function (event, callback, element, bubbles) {
        element.addEventListener(event, callback, bubbles || false);
    };
    DatepickerAdapter.prototype.addDismissInteraction = function (callback) {
        if (!this._popupElement) {
            return;
        }
        this._popupElement.targetElement.addEventListener(POPUP_CONSTANTS.events.BLUR, callback);
    };
    DatepickerAdapter.prototype.removeDismissInteraction = function (callback) {
        if (!this._popupElement) {
            return;
        }
        this._popupElement.targetElement.removeEventListener(POPUP_CONSTANTS.events.BLUR, callback);
    };
    DatepickerAdapter.prototype.attachCalendar = function () {
        if (this._popupElement) {
            return;
        }
        this._popupElement = document.createElement(POPUP_CONSTANTS.elementName);
        this._popupElement.style.top = '-9999px';
        this._popupElement.style.left = '-9999px';
        this._popupElement.targetElement = this._component;
        this._popupElement.placement = PopupPlacement.BottomLeft;
        this._popupElement.animationType = PopupAnimationType.Menu;
        this._popupElement.classList.add(DATEPICKER_CONSTANTS.classes.POPUP);
        var calendarElement = parseTemplateString(templateCalendar).content.cloneNode(true);
        this._popupElement.appendChild(calendarElement);
    };
    DatepickerAdapter.prototype.detachCalendar = function () {
        if (!this._popupElement) {
            return;
        }
        this._popupElement.open = false;
        this._popupElement = null;
    };
    DatepickerAdapter.prototype.renderCalendar = function (month, year, selectedDate, minDate, maxDate) {
        if (!this._popupElement) {
            return;
        }
        var todayDate = new Date();
        var selectedElement;
        var todayElement;
        var currentDate = new Date(year, month, 1);
        if (currentDate.getDay() > 0) {
            currentDate = new Date(year, month, -currentDate.getDay() + 1);
        }
        else {
            currentDate = new Date(year, month, -6);
        }
        this._renderMonthSelect(month);
        this._renderYearSelect(year);
        this._popupElement.querySelectorAll(DATEPICKER_CONSTANTS.selectors.CALENDAR_BUTTONS).forEach(function (button) {
            var currDate = currentDate.getDate();
            var currMonth = currentDate.getMonth();
            var currYear = currentDate.getFullYear();
            button.removeAttribute(DATEPICKER_CONSTANTS.attributes.PREVIOUS_MONTH);
            button.removeAttribute(DATEPICKER_CONSTANTS.attributes.NEXT_MONTH);
            button.removeAttribute(DATEPICKER_CONSTANTS.attributes.SELECTED);
            button.removeAttribute(DATEPICKER_CONSTANTS.attributes.TODAY);
            button.disabled = false;
            if ((currMonth < month && currYear === year) || currYear < year) {
                button.setAttribute(DATEPICKER_CONSTANTS.attributes.PREVIOUS_MONTH, '');
            }
            else if ((currMonth > month && currYear === year) || currYear > year) {
                button.setAttribute(DATEPICKER_CONSTANTS.attributes.NEXT_MONTH, '');
            }
            if (selectedDate && selectedDate.getDate() === currDate && selectedDate.getMonth() === currMonth && selectedDate.getFullYear() === currYear) {
                button.setAttribute(DATEPICKER_CONSTANTS.attributes.SELECTED, '');
                selectedElement = button;
            }
            if (todayDate.getDate() === currDate && todayDate.getMonth() === currMonth && todayDate.getFullYear() === currYear) {
                button.setAttribute(DATEPICKER_CONSTANTS.attributes.TODAY, '');
                todayElement = button;
            }
            if (minDate && currentDate < minDate) {
                button.disabled = true;
            }
            if (maxDate && currentDate > maxDate) {
                button.disabled = true;
            }
            button.innerText = currDate.toString();
            currentDate = new Date(currYear, currMonth, currDate + 1);
        });
        if (selectedElement) {
            selectedElement.focus();
        }
        else if (todayElement) {
            todayElement.focus();
        }
    };
    DatepickerAdapter.prototype.getSelectedValue = function (element) {
        var option = element.options[element.selectedIndex].value;
        return parseInt(option, 10);
    };
    DatepickerAdapter.prototype.getValue = function (element) {
        return parseInt(element.innerText, 10);
    };
    DatepickerAdapter.prototype.dispatchEvent = function (type, element) {
        emitEvent((element || this._component), type, undefined);
    };
    DatepickerAdapter.prototype.createIcon = function () {
        var iconElement = document.createElement('i');
        iconElement.slot = 'trailing';
        iconElement.textContent = 'event';
        iconElement.classList.add(ICON_CLASS_NAME);
        iconElement.tabIndex = -1;
        iconElement.setAttribute('aria-hidden', 'true');
        if (this._inputElement && this._inputElement.parentElement) {
            this._inputElement.parentElement.appendChild(iconElement);
        }
        else {
            this._component.appendChild(iconElement);
        }
    };
    DatepickerAdapter.prototype._renderMonthSelect = function (month) {
        if (!this._popupElement) {
            return;
        }
        var monthSelect = this.monthSelectElement;
        monthSelect.value = month.toString();
    };
    DatepickerAdapter.prototype._renderYearSelect = function (year) {
        if (!this._popupElement) {
            return;
        }
        var yearSelect = this.yearSelectElement;
        var yearOptions = Array.from(yearSelect.querySelectorAll(OPTION_CONSTANTS.elementName));
        var yearSelectOptionsLength = yearOptions.length;
        var optionYear = year - 10;
        for (var i = 0; i < yearSelectOptionsLength; i++) {
            var option = yearOptions[i];
            option.value = optionYear.toString();
            option.textContent = optionYear.toString();
            optionYear++;
        }
        yearSelect.value = year.toString();
    };
    return DatepickerAdapter;
}());
export { DatepickerAdapter };
