import { COLOR_PICKER_CONSTANTS, DEFAULT_COLOR, ColorPickerValueType } from './color-picker-constants';
import { hsvaToRgba, rgbaToHex, formatRgba, hexToRgba, rgbaToHsva, isValidHex, isValidRGBA, isValidHSVA, formatHex } from './color-picker-utils';
import { ColorPickerSlider } from './color-picker-slider';
import { ColorPickerGradientSlider } from './color-picker-gradient-slider';
var ColorPickerFoundation = /** @class */ (function () {
    function ColorPickerFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._value = null;
        this._allowOpacity = true;
        this._hex = DEFAULT_COLOR;
        this._hsva = { h: 0, s: 0, v: 0, a: 1 };
        this._rgba = { r: 0, g: 0, b: 0, a: 1 };
        this._valueType = ColorPickerValueType.HEX;
        this._gradientSliderChangedListener = function (x, y) { return _this._onGradientSliderChanged(x, y); };
        this._hueSliderChangedListener = function (value) { return _this._onHueSliderChanged(value); };
        this._opacitySliderChangedListener = function (value) { return _this._onOpacitySliderChanged(value); };
        this._typeClickListener = function (evt) { return _this._onTypeClicked(evt); };
        this._hexInputChangedListener = function (evt) { return _this._onHexInputChanged(); };
        this._rgbaInputChangedListener = function (evt) { return _this._onRgbaInputChanged(); };
        this._hsvaInputChangedListener = function (evt) { return _this._onHsvaInputChanged(); };
        this._gradientSlider = new ColorPickerGradientSlider(this._adapter.getGradientElement(), this._gradientSliderChangedListener);
        this._hueSlider = new ColorPickerSlider(this._adapter.getHueSliderElement(), this._hueSliderChangedListener);
        this._opacitySlider = new ColorPickerSlider(this._adapter.getOpacitySliderElement(), this._opacitySliderChangedListener);
    }
    ColorPickerFoundation.prototype.initialize = function () {
        this._adapter.setTypeClickListener(this._typeClickListener);
        this._adapter.setHexInputListener('input', this._hexInputChangedListener);
        this._adapter.setRgbaInputListener('input', this._rgbaInputChangedListener);
        this._adapter.setHsvaInputListener('input', this._hsvaInputChangedListener);
        this._initializeOpacity();
        this._setColorFromHex();
        this._adapter.setActiveValueType(this._valueType);
    };
    ColorPickerFoundation.prototype.disconnect = function () {
        this._adapter.removeTypeClickListener(this._typeClickListener);
        this._adapter.removeHexInputListener('input', this._hexInputChangedListener);
        this._adapter.removeRgbaInputListener('input', this._hexInputChangedListener);
        this._adapter.removeHsvaInputListener('input', this._hexInputChangedListener);
        this._gradientSlider.destroy();
        this._hueSlider.destroy();
        this._opacitySlider.destroy();
    };
    ColorPickerFoundation.prototype._initializeOpacity = function () {
        this._hsva.a = 1;
        this._adapter.toggleOpacityControls(this._allowOpacity);
        this._render();
    };
    ColorPickerFoundation.prototype._onTypeClicked = function (evt) {
        if (this._valueType === ColorPickerValueType.HEX) {
            this._valueType = ColorPickerValueType.RGBA;
        }
        else if (this._valueType === ColorPickerValueType.RGBA) {
            this._valueType = ColorPickerValueType.HSVA;
        }
        else if (this._valueType === ColorPickerValueType.HSVA) {
            this._valueType = ColorPickerValueType.HEX;
        }
        this._adapter.setActiveValueType(this._valueType);
        this._adapter.focusValueInput(this._valueType);
    };
    ColorPickerFoundation.prototype._onHexInputChanged = function () {
        var value = this._adapter.getHexInputValue();
        if (isValidHex(value)) {
            this.value = value;
            this._emitChangeEvent();
        }
    };
    ColorPickerFoundation.prototype._onRgbaInputChanged = function () {
        var value = this._adapter.getRgbaInputValue();
        if (isValidRGBA(value)) {
            this.value = rgbaToHex(value);
            this._emitChangeEvent();
        }
    };
    ColorPickerFoundation.prototype._onHsvaInputChanged = function () {
        var value = this._adapter.getHsvaInputValue();
        if (isValidHSVA(value)) {
            this.value = rgbaToHex(hsvaToRgba(value));
            this._emitChangeEvent();
        }
    };
    ColorPickerFoundation.prototype._setColorFromHex = function () {
        this._rgba = hexToRgba(this._hex);
        this._hsva = rgbaToHsva(this._rgba);
        this._gradientSlider.setValue(this._hsva.s, this._hsva.v);
        this._hueSlider.setValue(parseFloat((this._hsva.h / 360).toFixed(2)));
        this._opacitySlider.setValue(this._hsva.a);
        this._render();
    };
    ColorPickerFoundation.prototype._onGradientSliderChanged = function (x, y) {
        this._hsva.s = x;
        this._hsva.v = y;
        this._syncColors();
        this._render();
        this._emitChangeEvent();
    };
    ColorPickerFoundation.prototype._onHueSliderChanged = function (value) {
        this._hsva.h = parseInt((360 * value).toString(), 10);
        this._syncColors();
        this._setGradientColor();
        this._render();
        this._emitChangeEvent();
    };
    ColorPickerFoundation.prototype._onOpacitySliderChanged = function (value) {
        this._hsva.a = value;
        this._syncColors();
        this._render();
        this._emitChangeEvent();
    };
    ColorPickerFoundation.prototype._setGradientColor = function () {
        var rgba = hsvaToRgba({ h: this._hsva.h, s: 100, v: 100, a: 1 });
        this._adapter.setGradientColor("rgb(" + rgba.r + ", " + rgba.g + ", " + rgba.b + ")");
    };
    ColorPickerFoundation.prototype._syncColors = function () {
        this._rgba = hsvaToRgba(this._hsva);
        this._hex = rgbaToHex(this._rgba);
        this._value = this._hex || null;
    };
    ColorPickerFoundation.prototype._render = function () {
        this._setGradientColor();
        this._adapter.setPreviewColor(formatRgba(this._rgba));
        this._adapter.setHexInputValue(this._getFormattedHex());
        this._adapter.setRgbaInputValue(this._rgba);
        this._adapter.setHsvaInputValue(this._hsva);
        this._adapter.updateA11y(this._hsva.h, Math.round(this._hsva.a * 100));
    };
    ColorPickerFoundation.prototype._getFormattedHex = function () {
        return formatHex(this._hex, false);
    };
    ColorPickerFoundation.prototype._emitChangeEvent = function () {
        var data = {
            hex: this._hex,
            rgba: this._rgba,
            hsva: this._hsva
        };
        this._adapter.emitEvent(COLOR_PICKER_CONSTANTS.events.CHANGE, data);
    };
    Object.defineProperty(ColorPickerFoundation.prototype, "value", {
        get: function () {
            return this._getFormattedHex();
        },
        set: function (value) {
            if (this._value !== value) {
                this._value = value || DEFAULT_COLOR;
                if (!isValidHex(this._value)) {
                    throw new Error('Invalid hex value provided.');
                }
                this._hex = this._value.replace(/^#/, '');
                this._setColorFromHex();
                this._adapter.setHostAttribute(COLOR_PICKER_CONSTANTS.attributes.VALUE, this._value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorPickerFoundation.prototype, "rgba", {
        get: function () {
            return JSON.parse(JSON.stringify(this._rgba));
        },
        set: function (value) {
            if (value) {
                if (isValidRGBA(value)) {
                    this.value = rgbaToHex(value);
                }
            }
            else {
                this.value = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorPickerFoundation.prototype, "hsva", {
        get: function () {
            return JSON.parse(JSON.stringify(this._hsva));
        },
        set: function (value) {
            if (value) {
                if (isValidHSVA(value)) {
                    this.value = rgbaToHex(hsvaToRgba(value));
                }
            }
            else {
                this.value = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorPickerFoundation.prototype, "opacity", {
        get: function () {
            return this._hsva ? this._hsva.a : null;
        },
        set: function (value) {
            if (this._hsva.a !== value) {
                if (value !== null && value !== undefined && this._allowOpacity) {
                    if (value >= 0 && value <= 1) {
                        this._hsva.a = value;
                        this._opacitySlider.setValue(this._hsva.a);
                        this._syncColors();
                        this._render();
                    }
                    else {
                        console.warn("The provided opacity value (" + value + ") must be between 0 and 1.");
                    }
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorPickerFoundation.prototype, "allowOpacity", {
        get: function () {
            return this._allowOpacity;
        },
        set: function (value) {
            this._allowOpacity = value;
            this._initializeOpacity();
        },
        enumerable: true,
        configurable: true
    });
    return ColorPickerFoundation;
}());
export { ColorPickerFoundation };
