import { getShadowElement, emitEvent, replaceElement } from '@tyler-components-web/core';
import { BUSY_INDICATOR_CONSTANTS } from './busy-indicator-constants';
/**
 * Provides facilities for interacting with the internal DOM of `BusyIndicatorComponent`.
 */
var BusyIndicatorAdapter = /** @class */ (function () {
    function BusyIndicatorAdapter(_component) {
        this._component = _component;
        this._surfaceElement = getShadowElement(_component, BUSY_INDICATOR_CONSTANTS.selectors.SURFACE);
        this._titleElement = getShadowElement(_component, BUSY_INDICATOR_CONSTANTS.selectors.TITLE);
        this._messageElement = getShadowElement(_component, BUSY_INDICATOR_CONSTANTS.selectors.MESSAGE);
        this._cancelButtonElement = getShadowElement(_component, BUSY_INDICATOR_CONSTANTS.selectors.CANCEL);
        this._spinnerElement = getShadowElement(_component, BUSY_INDICATOR_CONSTANTS.selectors.SPINNER);
        this._progressBarContainerElement = getShadowElement(_component, BUSY_INDICATOR_CONSTANTS.selectors.PROGRESS_BAR_CONTAINER);
        this._progressBarElement = getShadowElement(_component, BUSY_INDICATOR_CONSTANTS.selectors.PROGRESS_BAR);
        this._backdropElement = getShadowElement(_component, BUSY_INDICATOR_CONSTANTS.selectors.BACKDROP);
    }
    /**
     * Sets the opacity of the content element.
     * @param opacity The opacity value.
     */
    BusyIndicatorAdapter.prototype.setSurfaceOpacity = function (opacity) {
        this._surfaceElement.style.opacity = opacity.toString();
    };
    /**
     * Sets the content of the title element.
     * @param title The title text.
     */
    BusyIndicatorAdapter.prototype.setTitle = function (title) {
        this._titleElement.innerText = title;
    };
    /**
     * Sets the content of the message element.
     * @param message The message text.
     */
    BusyIndicatorAdapter.prototype.setMessage = function (message) {
        this._messageElement.innerText = message;
    };
    /**
     * Sets the visibility of the title element by adding or removing it from the DOM.
     * @param visible Whether the title is visible or not.
     */
    BusyIndicatorAdapter.prototype.setTitleVisibility = function (visible) {
        this._titlePlaceholderElement = this._toggleElement(visible, BUSY_INDICATOR_CONSTANTS.selectors.TITLE, this._titleElement, this._titlePlaceholderElement);
    };
    /**
     * Adds or removes the cancel button from the DOM.
     * @param visible The visibility value.
     */
    BusyIndicatorAdapter.prototype.setCancelButtonVisibility = function (visible) {
        this._cancelButtonPlaceholderElement = this._toggleElement(visible, BUSY_INDICATOR_CONSTANTS.selectors.CANCEL, this._cancelButtonElement, this._cancelButtonPlaceholderElement);
    };
    /**
     * Adds or removes the message element from the DOM.
     * @param visible The visibility value.
     */
    BusyIndicatorAdapter.prototype.setMessageVisibility = function (visible) {
        this._messagePlaceholderElement = this._toggleElement(visible, BUSY_INDICATOR_CONSTANTS.selectors.MESSAGE, this._messageElement, this._messagePlaceholderElement);
    };
    /**
     * Adds or removes the spinner element from the DOM.
     * @param visible The visibility value.
     */
    BusyIndicatorAdapter.prototype.setSpinnerVisibility = function (visible) {
        this._spinnerPlaceholderElement = this._toggleElement(visible, BUSY_INDICATOR_CONSTANTS.selectors.SPINNER, this._spinnerElement, this._spinnerPlaceholderElement);
    };
    /**
     * Adds or removes the progress bar element from the DOM.
     * @param visible The visibility value.
     */
    BusyIndicatorAdapter.prototype.setProgressBarVisibility = function (visible) {
        this._progressBarContainerPlaceholderElement = this._toggleElement(visible, BUSY_INDICATOR_CONSTANTS.selectors.PROGRESS_BAR_CONTAINER, this._progressBarContainerElement, this._progressBarContainerPlaceholderElement);
    };
    /**
     * Sets the determinate state of the progress bar component.
     * @param {boolean} determinate The determinate value.
     */
    BusyIndicatorAdapter.prototype.setProgressBarDeterminate = function (determinate) {
        this._progressBarElement.determinate = determinate;
    };
    /**
     * Sets the progress percent for the progress bar component.
     * @param value The progress percent.
     */
    BusyIndicatorAdapter.prototype.setProgressBarProgress = function (value) {
        this._progressBarElement.progress = value;
    };
    /**
     * Sets the progress bar component buffer percent.
     * @param value The buffer percent.
     */
    BusyIndicatorAdapter.prototype.setProgressBarBuffer = function (value) {
        this._progressBarElement.buffer = value;
    };
    /**
     * Adds an event listener to the cancel button.
     * @param type The event type.
     * @param listener The event listener.
     */
    BusyIndicatorAdapter.prototype.addCancelButtonEventListener = function (type, listener) {
        this._cancelButtonElement.addEventListener(type, listener);
    };
    /**
     * Removes an event listener from the cancel button.
     * @param type The event type.
     * @param listener The event listener.
     */
    BusyIndicatorAdapter.prototype.removeCancelButtonEventListener = function (type, listener) {
        this._cancelButtonElement.removeEventListener(type, listener);
    };
    /**
     * Emits an event from the host element.
     * @param type The event name.
     */
    BusyIndicatorAdapter.prototype.emitHostEvent = function (type) {
        emitEvent(this._component, type, null);
    };
    /**
     * Sets an attribute on the cancel button.
     * @param name The attribute name.
     * @param value The attribute value.
     */
    BusyIndicatorAdapter.prototype.setCancelButtonAttribute = function (name, value) {
        this._cancelButtonElement.setAttribute(name, value);
    };
    /**
     * Sets the text displayed in the cancel button element.
     * @param text The text value.
     */
    BusyIndicatorAdapter.prototype.setCancelButtonText = function (text) {
        this._cancelButtonElement.innerText = text;
    };
    /**
     * Sets the width of the surface element.
     * @param value The CSS width.
     */
    BusyIndicatorAdapter.prototype.setSurfaceWidth = function (value) {
        this._surfaceElement.style.width = value.toString();
    };
    /**
     * Sets focus to the busy indicator component element.
     */
    BusyIndicatorAdapter.prototype.captureFocus = function () {
        this._component.focus();
    };
    /**
     * Removes the busy indicator component from its parent.
     */
    BusyIndicatorAdapter.prototype.remove = function () {
        var parentNode = this._component.parentNode;
        if (parentNode) {
            parentNode.removeChild(this._component);
        }
    };
    /**
     * Sets the value of an attribute on the host element.
     * @param name The attribute name.
     * @param value The attribute value.
     */
    BusyIndicatorAdapter.prototype.setHostAttribute = function (name, value) {
        this._component.setAttribute(name, value);
    };
    /**
     * Gets the `BusyIndicatorComponent` instance.
     * @returns The component instance.
     */
    BusyIndicatorAdapter.prototype.getHostElement = function () {
        return this._component;
    };
    /**
     * Sets the backdrop delay value for when it starts its fade-in animation.
     * @param value The backdrop delay in milliseconds.
     */
    BusyIndicatorAdapter.prototype.setBackdropDelay = function (value) {
        this._backdropElement.delay = value;
    };
    /**
     * Hides the backdrop by fading it out.
     */
    BusyIndicatorAdapter.prototype.hideBackdrop = function () {
        this._backdropElement.fadeOut();
    };
    BusyIndicatorAdapter.prototype._toggleElement = function (isVisible, selector, element, placeholder) {
        var exists = !!getShadowElement(this._component, selector);
        if (!placeholder) {
            placeholder = document.createComment("(" + BUSY_INDICATOR_CONSTANTS.elementName + ") " + selector);
        }
        if (isVisible && !exists) {
            replaceElement(element, placeholder);
        }
        else if (!isVisible && exists) {
            replaceElement(placeholder, element);
        }
        return placeholder;
    };
    return BusyIndicatorAdapter;
}());
export { BusyIndicatorAdapter };
