import { AVATAR_CONSTANTS } from '../../avatar';
import { getLightElement, notChildEventListener, emitEvent } from '@tyler-components-web/core';
import { POPUP_CONSTANTS, PopupPlacement, PopupAnimationType } from '../../popup';
import { PROFILE_CARD_CONSTANTS } from '../profile-card';
import { OMNIBAR_PROFILE_BUTTON_CONSTANTS } from './omnibar-profile-button-constants';
import { getActiveElement } from 'shadow-utils';
var OmnibarProfileButtonAdapter = /** @class */ (function () {
    function OmnibarProfileButtonAdapter(_component) {
        this._component = _component;
    }
    OmnibarProfileButtonAdapter.prototype.initialize = function () {
        this._avatarElement = getLightElement(this._component, AVATAR_CONSTANTS.elementName);
        this._buttonElement = getLightElement(this._component, OMNIBAR_PROFILE_BUTTON_CONSTANTS.selectors.BUTTON);
    };
    OmnibarProfileButtonAdapter.prototype.emitEvent = function (type, data, bubble) {
        if (bubble === void 0) { bubble = true; }
        emitEvent(this._component, type, data, bubble);
    };
    OmnibarProfileButtonAdapter.prototype.setClickListener = function (listener) {
        this._component.addEventListener('click', listener);
    };
    OmnibarProfileButtonAdapter.prototype.removeClickListener = function (listener) {
        this._component.removeEventListener('click', listener);
    };
    OmnibarProfileButtonAdapter.prototype.openPopup = function (profileCardConfig, dismissListener, profileListener, signOutListener) {
        var _this = this;
        var profileCardElement = document.createElement(PROFILE_CARD_CONSTANTS.elementName);
        profileCardElement.fullName = profileCardConfig.fullName;
        profileCardElement.email = profileCardConfig.email;
        profileCardElement.signOut = profileCardConfig.signOut;
        profileCardElement.profile = profileCardConfig.profile;
        profileCardElement.addEventListener(PROFILE_CARD_CONSTANTS.events.PROFILE, function () { return profileListener(); });
        profileCardElement.addEventListener(PROFILE_CARD_CONSTANTS.events.SIGN_OUT, function () { return signOutListener(); });
        this._popupElement = document.createElement(POPUP_CONSTANTS.elementName);
        this._popupElement.targetElement = this._component;
        this._popupElement.placement = PopupPlacement.BottomRight;
        this._popupElement.animationType = PopupAnimationType.Menu;
        this._popupElement.style.top = '-999px';
        this._popupElement.style.left = '-999px';
        this._popupElement.appendChild(profileCardElement);
        this._popupElement.open = true;
        return notChildEventListener(this._popupElement, function (activeElement) {
            if (!_this._popupElement) {
                return;
            }
            if (!_this._component.contains(getActiveElement())) {
                dismissListener();
            }
        }, true);
    };
    OmnibarProfileButtonAdapter.prototype.closePopup = function () {
        if (this._popupElement) {
            this._popupElement.open = false;
        }
    };
    OmnibarProfileButtonAdapter.prototype.requestFocus = function () {
        this._buttonElement.focus();
    };
    OmnibarProfileButtonAdapter.prototype.setAvatarText = function (value) {
        this._avatarElement.text = value;
    };
    OmnibarProfileButtonAdapter.prototype.setAvatarLetterCount = function (value) {
        this._avatarElement.letterCount = value;
    };
    OmnibarProfileButtonAdapter.prototype.setAvatarImageUrl = function (value) {
        this._avatarElement.imageUrl = value;
    };
    OmnibarProfileButtonAdapter.prototype.addWindowListener = function (type, listener) {
        window.addEventListener(type, listener);
    };
    OmnibarProfileButtonAdapter.prototype.removeWindowListener = function (type, listener) {
        window.removeEventListener(type, listener);
    };
    return OmnibarProfileButtonAdapter;
}());
export { OmnibarProfileButtonAdapter };
