import { __extends } from "tslib";
import { isDefined } from '@tyler-components-web/core';
import { AbstractComponentDelegate } from '../core/delegates/abstract-component-delegate';
import { TEXT_FIELD_CONSTANTS } from '../text-field/text-field-constants';
import { DATEPICKER_CONSTANTS } from './datepicker-constants';
var DateRangeComponentDelegate = /** @class */ (function (_super) {
    __extends(DateRangeComponentDelegate, _super);
    function DateRangeComponentDelegate(config) {
        if (config === void 0) { config = {}; }
        return _super.call(this, config) || this;
    }
    DateRangeComponentDelegate.prototype.build = function () {
        // TODO(BLJ): figure this out
        var container = document.createElement('div');
        container.style.display = 'flex';
        this._fromDatepicker = document.createElement(DATEPICKER_CONSTANTS.elementName);
        this._fromTextField = document.createElement(TEXT_FIELD_CONSTANTS.elementName);
        this._fromInput = document.createElement('input');
        this._fromInput.type = 'text';
        this._fromInput.placeholder = 'From';
        var fromLabel = document.createElement('label');
        fromLabel.textContent = 'Start';
        this._toDatepicker = document.createElement(DATEPICKER_CONSTANTS.elementName);
        this._toTextField = document.createElement(TEXT_FIELD_CONSTANTS.elementName);
        this._toInput = document.createElement('input');
        this._toInput.type = 'text';
        this._toInput.placeholder = 'To';
        var toLabel = document.createElement('label');
        toLabel.textContent = 'End';
        this._fromDatepicker.appendChild(this._fromTextField);
        this._fromTextField.appendChild(this._fromInput);
        // this._fromTextField.appendChild(fromLabel);
        container.appendChild(this._fromDatepicker);
        this._toDatepicker.appendChild(this._toTextField);
        this._toTextField.appendChild(this._toInput);
        // this._toTextField.appendChild(toLabel);
        container.appendChild(this._toDatepicker);
        return container;
    };
    DateRangeComponentDelegate.prototype.configure = function () {
    };
    DateRangeComponentDelegate.prototype.getValue = function () {
        return { from: this._fromInput.value, to: this._toInput.value };
    };
    DateRangeComponentDelegate.prototype.setValue = function (value) {
        if (isDefined(value.from)) {
            this._fromInput.value = value.from;
        }
        if (isDefined(value.to)) {
            this._toInput.value = value.to;
        }
    };
    DateRangeComponentDelegate.prototype.onChange = function (listener) {
        var _this = this;
        this._fromInput.addEventListener('change', function (evt) { return listener(_this.getValue()); });
        this._toInput.addEventListener('change', function (evt) { return listener(_this.getValue()); });
    };
    DateRangeComponentDelegate.prototype.setDisabled = function (value) {
        this._fromInput.disabled = value;
        this._toInput.disabled = value;
    };
    DateRangeComponentDelegate.prototype.setValidity = function (value) {
        this._fromTextField.invalid = !value;
        this._toTextField.invalid = !value;
    };
    DateRangeComponentDelegate.prototype.setFromValidity = function (value) {
        this._fromTextField.invalid = !value;
    };
    DateRangeComponentDelegate.prototype.setToValidity = function (value) {
        this._toTextField.invalid = !value;
    };
    DateRangeComponentDelegate.prototype.validate = function () {
        var value = this.getValue();
        return !!value.from && !!value.to;
    };
    return DateRangeComponentDelegate;
}(AbstractComponentDelegate));
export { DateRangeComponentDelegate };
