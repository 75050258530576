import { COLOR_PICKER_CONSTANTS } from './color-picker-constants';
import { relativeCoords } from './color-picker-utils';
var ColorPickerGradientSlider = /** @class */ (function () {
    function ColorPickerGradientSlider(_rootElement, _changeListener) {
        var _this = this;
        this._rootElement = _rootElement;
        this._changeListener = _changeListener;
        this._keydownListener = function (evt) { return _this._onKeydown(evt); };
        this._downListener = function (evt) { return _this._onDown(evt); };
        this._moveListener = function (evt) { return _this._onMove(evt); };
        this._upListener = function (evt) { return _this._onUp(evt); };
        this._initialize();
    }
    ColorPickerGradientSlider.prototype.destroy = function () {
        this._unlisten();
    };
    ColorPickerGradientSlider.prototype.setValue = function (saturation, value) {
        var _this = this;
        window.requestAnimationFrame(function () {
            var bounds = _this._rootElement.getBoundingClientRect();
            _this._xPercent = Math.round(bounds.width * (saturation / 100));
            _this._yPercent = bounds.height - Math.round(bounds.height * (value / 100));
            _this._setThumbPosition(_this._xPercent, _this._yPercent);
        });
    };
    ColorPickerGradientSlider.prototype._initialize = function () {
        this._thumbElement = this._rootElement.querySelector(COLOR_PICKER_CONSTANTS.selectors.GRADIENT_THUMB);
        this._listen();
        this._setThumbPosition(this._xPercent, this._yPercent);
    };
    ColorPickerGradientSlider.prototype._listen = function () {
        this._rootElement.addEventListener('keydown', this._keydownListener);
        this._rootElement.addEventListener('mousedown', this._downListener);
        this._rootElement.addEventListener('touchstart', this._downListener);
    };
    ColorPickerGradientSlider.prototype._unlisten = function () {
        this._rootElement.removeEventListener('keydown', this._keydownListener);
        this._rootElement.removeEventListener('mousedown', this._downListener);
        this._rootElement.removeEventListener('touchstart', this._downListener);
        document.removeEventListener('mousemove', this._moveListener);
        document.removeEventListener('touchmove', this._moveListener);
        document.removeEventListener('mouseup', this._upListener);
        document.removeEventListener('touchend', this._upListener);
    };
    ColorPickerGradientSlider.prototype._onKeydown = function (evt) {
        var isEnterKey = evt.key === 'Enter' || evt.keyCode === 13;
        var isArrowLeftKey = evt.key === 'ArrowLeft' || evt.keyCode === 37;
        var isArrowUpKey = evt.key === 'ArrowUp' || evt.keyCode === 38;
        var isArrowRightKey = evt.key === 'ArrowRight' || evt.keyCode === 39;
        var isArrowDownKey = evt.key === 'ArrowDown' || evt.keyCode === 40;
        var bounds = this._rootElement.getBoundingClientRect();
        if (isArrowDownKey) {
            evt.preventDefault();
            this._yPercent++;
        }
        else if (isArrowUpKey) {
            evt.preventDefault();
            this._yPercent--;
        }
        else if (isArrowLeftKey) {
            evt.preventDefault();
            this._xPercent--;
        }
        else if (isArrowRightKey) {
            evt.preventDefault();
            this._xPercent++;
        }
        else if (isEnterKey) {
            evt.preventDefault();
            // TODO(kieran.nichols): Select the current color
        }
        else {
            return;
        }
        if (this._xPercent > bounds.width) {
            this._xPercent = bounds.width;
        }
        else if (this._xPercent < 0) {
            this._xPercent = 0;
        }
        if (this._yPercent > bounds.height) {
            this._yPercent = bounds.height;
        }
        else if (this._yPercent < 0) {
            this._yPercent = 0;
        }
        this._setThumbPosition(this._xPercent, this._yPercent);
        this._notify();
    };
    ColorPickerGradientSlider.prototype._onDown = function (evt) {
        evt.preventDefault();
        document.addEventListener('mousemove', this._moveListener);
        document.addEventListener('touchmove', this._moveListener);
        document.addEventListener('mouseup', this._upListener);
        document.addEventListener('touchend', this._upListener);
        this._updateThumbPosition(evt);
    };
    ColorPickerGradientSlider.prototype._onMove = function (evt) {
        evt.preventDefault();
        this._updateThumbPosition(evt);
    };
    ColorPickerGradientSlider.prototype._onUp = function (evt) {
        document.removeEventListener('mousemove', this._moveListener);
        document.removeEventListener('touchmove', this._moveListener);
        document.removeEventListener('mouseup', this._upListener);
        document.removeEventListener('touchend', this._upListener);
        this._updateThumbPosition(evt);
        this._thumbElement.focus();
    };
    ColorPickerGradientSlider.prototype._updateThumbPosition = function (evt) {
        var isMouseEvent = /^mouse/.test(evt.type);
        var x = isMouseEvent ? evt.clientX : evt.changedTouches[0].clientX;
        var y = isMouseEvent ? evt.clientY : evt.changedTouches[0].clientY;
        var coords = this._calculateSliderPercent(x, y);
        this._setThumbPosition(coords.x, coords.y);
        this._xPercent = parseInt(((coords.x / coords.width) * 100).toString(), 10);
        this._yPercent = Math.abs(parseInt(((coords.y / coords.height) * 100).toString(), 10) - 100);
        this._notify();
    };
    ColorPickerGradientSlider.prototype._calculateSliderPercent = function (absX, absY) {
        return relativeCoords(absX, absY, this._rootElement);
    };
    ColorPickerGradientSlider.prototype._setThumbPosition = function (xPercent, yPercent) {
        this._thumbElement.style.left = xPercent + "px";
        this._thumbElement.style.top = yPercent + "px";
    };
    ColorPickerGradientSlider.prototype._notify = function () {
        if (typeof this._changeListener === 'function') {
            this._changeListener(this._xPercent, this._yPercent);
        }
    };
    return ColorPickerGradientSlider;
}());
export { ColorPickerGradientSlider };
