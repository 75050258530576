import { CHIP_SET_CONSTANTS } from './chip-set-constants';
import { CHIP_CONSTANTS } from '../chip/chip-constants';
/**
 * The foundation class behind the `<tcw-chip-set>` component.
 */
var ChipSetFoundation = /** @class */ (function () {
    function ChipSetFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._orientation = 'horizontal';
        this._dense = false;
        this._selectedListener = function (evt) { return _this._onChipSelected(evt); };
    }
    ChipSetFoundation.prototype.initialize = function () {
        this._adapter.addListener(CHIP_CONSTANTS.events.SELECTED, this._selectedListener);
    };
    ChipSetFoundation.prototype._applyType = function (type) {
        // Remove classes for all types first
        this._adapter.removeHostClass([
            CHIP_SET_CONSTANTS.classes.FILTER,
            CHIP_SET_CONSTANTS.classes.CHOICE,
            CHIP_SET_CONSTANTS.classes.INPUT
        ]);
        switch (type) {
            case 'filter':
                this._adapter.setHostClass(CHIP_SET_CONSTANTS.classes.FILTER);
                break;
            case 'choice':
                this._adapter.setHostClass(CHIP_SET_CONSTANTS.classes.CHOICE);
                break;
            case 'input':
                this._adapter.setHostClass(CHIP_SET_CONSTANTS.classes.INPUT);
                break;
        }
        var chips = this._adapter.getChildChips();
        chips.forEach(function (chip) { return chip.type = type; });
    };
    ChipSetFoundation.prototype._applyDense = function () {
        var _this = this;
        if (this._dense) {
            this._adapter.setHostClass(CHIP_SET_CONSTANTS.classes.CHIP_SET_DENSE);
            this._adapter.setHostAttribute(CHIP_SET_CONSTANTS.attributes.DENSE, '');
        }
        else {
            this._adapter.removeHostClass(CHIP_SET_CONSTANTS.classes.CHIP_SET_DENSE);
            this._adapter.removeHostAttribute(CHIP_SET_CONSTANTS.attributes.DENSE);
        }
        var chips = this._adapter.getChildChips();
        chips.forEach(function (chip) { return chip.dense = _this._dense; });
    };
    ChipSetFoundation.prototype._onChipSelected = function (evt) {
        if (this._type !== 'choice') {
            return;
        }
        this._adapter.getChildChips()
            .filter(function (chip) { return chip.selected && chip !== evt.target; })
            .forEach(function (chip) { return chip.selected = false; });
    };
    Object.defineProperty(ChipSetFoundation.prototype, "orientation", {
        /** Gets/sets the chip type. */
        get: function () {
            return this._orientation;
        },
        set: function (value) {
            if (this._orientation !== value) {
                this._orientation = value;
                this._adapter.setOrientation(this._orientation);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipSetFoundation.prototype, "type", {
        /** Gets/sets the chip type. */
        get: function () {
            return this._type;
        },
        set: function (value) {
            if (this._type !== value) {
                this._type = value;
                this._applyType(this._type);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipSetFoundation.prototype, "dense", {
        /** Gets/sets the chip(s) dense. */
        get: function () {
            return this._dense;
        },
        set: function (value) {
            if (this._dense !== value) {
                this._dense = value;
                this._applyDense();
            }
        },
        enumerable: true,
        configurable: true
    });
    return ChipSetFoundation;
}());
export { ChipSetFoundation };
