import { defineCustomElement } from '@tyler-components-web/core';
import { BusyIndicatorComponent } from './busy-indicator';
export * from './busy-indicator-adapter';
export * from './busy-indicator-constants';
export * from './busy-indicator-controller';
export * from './busy-indicator-foundation';
export * from './busy-indicator';
export function defineBusyIndicatorComponent() {
    defineCustomElement(BusyIndicatorComponent);
}
