import { getActiveElement } from 'shadow-utils';
import { getLightElement, emitEvent, notChildEventListener } from '@tyler-components-web/core';
import { POPUP_CONSTANTS, PopupPlacement, PopupAnimationType } from '../../popup';
import { OMNIBAR_APP_LAUNCHER_BUTTON_CONSTANTS } from './omnibar-app-launcher-button-constants';
import { APP_LAUNCHER_CONSTANTS } from '../app-launcher';
var OmnibarAppLauncherButtonAdapter = /** @class */ (function () {
    function OmnibarAppLauncherButtonAdapter(_component) {
        this._component = _component;
    }
    OmnibarAppLauncherButtonAdapter.prototype.initialize = function () {
        this._buttonElement = getLightElement(this._component, OMNIBAR_APP_LAUNCHER_BUTTON_CONSTANTS.selectors.BUTTON);
    };
    OmnibarAppLauncherButtonAdapter.prototype.emitEvent = function (type, data, bubble, cancelable) {
        if (bubble === void 0) { bubble = true; }
        if (cancelable === void 0) { cancelable = true; }
        return emitEvent(this._component, type, data, bubble, cancelable);
    };
    OmnibarAppLauncherButtonAdapter.prototype.setClickListener = function (listener) {
        this._buttonElement.addEventListener('click', listener);
    };
    OmnibarAppLauncherButtonAdapter.prototype.removeClickListener = function (listener) {
        this._buttonElement.removeEventListener('click', listener);
    };
    OmnibarAppLauncherButtonAdapter.prototype.requestFocus = function () {
        this._buttonElement.focus();
    };
    OmnibarAppLauncherButtonAdapter.prototype.open = function (appLauncherConfig, dismissListener) {
        var _this = this;
        this._appLauncher = document.createElement(APP_LAUNCHER_CONSTANTS.elementName);
        this.setAppLauncherConfig(appLauncherConfig);
        this._appLauncher.addEventListener(APP_LAUNCHER_CONSTANTS.events.SELECT, function (evt) {
            evt.stopPropagation();
            dismissListener();
            var isCancelled = _this.emitEvent(APP_LAUNCHER_CONSTANTS.events.SELECT, evt.detail);
            if (isCancelled) {
                evt.preventDefault();
            }
        });
        this._popupElement = document.createElement(POPUP_CONSTANTS.elementName);
        this._popupElement.placement = PopupPlacement.BottomRight;
        this._popupElement.targetElement = this._component;
        this._popupElement.animationType = PopupAnimationType.Menu;
        this._popupElement.appendChild(this._appLauncher);
        this._popupElement.style.top = '-999px';
        this._popupElement.style.left = '-999px';
        this._popupElement.open = true;
        return notChildEventListener(this._popupElement, function (activeElement) {
            if (!_this._popupElement) {
                return;
            }
            if (!_this._component.contains(getActiveElement())) {
                dismissListener();
            }
        }, true);
    };
    OmnibarAppLauncherButtonAdapter.prototype.close = function () {
        if (this._popupElement) {
            this._popupElement.open = false;
        }
        if (this._appLauncher) {
            this._appLauncher = undefined;
        }
    };
    OmnibarAppLauncherButtonAdapter.prototype.addWindowListener = function (type, listener) {
        window.addEventListener(type, listener);
    };
    OmnibarAppLauncherButtonAdapter.prototype.removeWindowListener = function (type, listener) {
        window.removeEventListener(type, listener);
    };
    OmnibarAppLauncherButtonAdapter.prototype.setAppLauncherConfig = function (config) {
        if (this._appLauncher) {
            Object.assign(this._appLauncher, config);
        }
    };
    OmnibarAppLauncherButtonAdapter.prototype.setAppLauncherFocus = function () {
        if (this._appLauncher) {
            this._appLauncher.focusFirst();
        }
    };
    return OmnibarAppLauncherButtonAdapter;
}());
export { OmnibarAppLauncherButtonAdapter };
