import { COMPONENT_NAME_PREFIX } from '../../constants';
import { APP_LAUNCHER_CONSTANTS } from '../app-launcher';
var elementName = COMPONENT_NAME_PREFIX + "omnibar-app-launcher-button";
var selectors = {
    BUTTON: 'button'
};
var events = {
    OPEN: APP_LAUNCHER_CONSTANTS.elementName + "-open",
    CLOSE: APP_LAUNCHER_CONSTANTS.elementName + "-close"
};
export var OMNIBAR_APP_LAUNCHER_BUTTON_CONSTANTS = {
    elementName: elementName,
    selectors: selectors,
    events: events
};
