import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "open-icon";
var classes = {
    ICON: 'tyl-open-icon',
    ICON_HORIZONTAL: 'tyl-open-icon--horizontal',
    ICON_VERTICAL: 'tyl-open-icon--vertical',
    ICON_OPEN: 'tyl-open-icon--open'
};
var attributes = {
    OPEN: 'open',
    ORIENTATION: 'orientation'
};
var strings = {
    ORIENTATION_VERTICAL: 'vertical',
    ORIENTATION_HORIZONTAL: 'horizontal'
};
export var OPEN_ICON_CONSTANTS = {
    elementName: elementName,
    classes: classes,
    attributes: attributes,
    strings: strings
};
