import { emitEvent, removeAllChildren, isDefined } from '@tyler-components-web/core';
import { POPUP_CONSTANTS, PopupAnimationType } from '../popup';
import { MENU_CONSTANTS } from './menu-constants';
import { LIST_CONSTANTS, LIST_ITEM_CONSTANTS } from '../list';
import { DIVIDER_CONSTANTS } from '../divider';
import { PROGRESS_SPINNER_CONSTANTS } from '../progress-spinner';
import { randomChars } from '../core/utils';
var MenuAdapter = /** @class */ (function () {
    function MenuAdapter(_component) {
        this._component = _component;
        this._identifier = randomChars();
    }
    Object.defineProperty(MenuAdapter.prototype, "toggleElement", {
        get: function () {
            if (!this._toggleElement) {
                this._toggleElement = this._component.querySelector(MENU_CONSTANTS.selectors.TOGGLE);
                if (!this._toggleElement) {
                    this._toggleElement = this._component.firstElementChild;
                }
            }
            return this._toggleElement;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuAdapter.prototype, "popupElement", {
        get: function () {
            return this._popupElement;
        },
        enumerable: true,
        configurable: true
    });
    MenuAdapter.prototype.initializeAccessibility = function () {
        if (!this._toggleElement) {
            return;
        }
        this._toggleElement.setAttribute('role', 'menu');
        this._toggleElement.setAttribute('aria-live', 'polite');
        this._toggleElement.setAttribute('aria-haspopup', 'true');
        this._toggleElement.setAttribute('aria-expanded', 'false');
        this._toggleElement.setAttribute('aria-activedescendant', 'null');
        this._toggleElement.setAttribute('aria-owns', "tcw-menu-" + this._identifier);
        if (!this._toggleElement.hasAttribute('aria-label')) {
            this._toggleElement.setAttribute('aria-label', this._toggleElement.innerText);
        }
    };
    MenuAdapter.prototype.hasClass = function (name, element) {
        if (element) {
            return element.classList.contains(name);
        }
        else {
            return this._component.classList.contains(name);
        }
    };
    MenuAdapter.prototype.hasAttribute = function (attribute, element) {
        if (element) {
            return element.hasAttribute(attribute);
        }
        else {
            return this._component.hasAttribute(attribute);
        }
    };
    MenuAdapter.prototype.setAttribute = function (attribute, value, element) {
        if (element) {
            element.setAttribute(attribute, value);
        }
        else {
            this._component.setAttribute(attribute, value);
        }
    };
    MenuAdapter.prototype.getAttribute = function (attribute, element) {
        if (element) {
            return element.getAttribute(attribute);
        }
        else {
            return this._component.getAttribute(attribute);
        }
    };
    MenuAdapter.prototype.removeAttribute = function (attribute, element) {
        if (element) {
            element.removeAttribute(attribute);
        }
        else {
            this._component.removeAttribute(attribute);
        }
    };
    MenuAdapter.prototype.addEventListener = function (event, callback, element, bubbles) {
        if (element) {
            element.addEventListener(event, callback, bubbles || false);
        }
        else {
            this._component.addEventListener(event, callback, bubbles || false);
        }
    };
    MenuAdapter.prototype.removeEventListener = function (event, callback, element) {
        if (element) {
            element.removeEventListener(event, callback);
        }
        else {
            this._component.addEventListener(event, callback);
        }
    };
    MenuAdapter.prototype.addDismissInteraction = function (callback) {
        if (!this._popupElement || !this._popupElement.targetElement) {
            return;
        }
        this._popupElement.targetElement.addEventListener(POPUP_CONSTANTS.events.BLUR, callback);
    };
    MenuAdapter.prototype.removeDismissInteraction = function (callback) {
        if (!this._popupElement || !this._popupElement.targetElement) {
            return;
        }
        this._popupElement.targetElement.removeEventListener(POPUP_CONSTANTS.events.BLUR, callback);
    };
    MenuAdapter.prototype.attachMenu = function (options, selectedIndex, dense, iconClassName) {
        if (this._popupElement) {
            return;
        }
        this._popupElement = document.createElement(POPUP_CONSTANTS.elementName);
        this._popupElement.targetElement = this._toggleElement;
        this._popupElement.placement = this._component.placement;
        this._popupElement.animationType = PopupAnimationType.Menu;
        this._popupElement.classList.add(MENU_CONSTANTS.classes.POPUP);
        this._popupElement.classList.add(MENU_CONSTANTS.classes.MENU);
        this._popupElement.style.top = '-999px';
        this._popupElement.style.left = '-999px';
        if (options.length) {
            this._popupElement.appendChild(this._buildMenu(options, selectedIndex, dense, iconClassName));
        }
        else {
            this._popupElement.appendChild(this._buildSpinner());
        }
        if (this._toggleElement) {
            this._toggleElement.setAttribute('aria-expanded', 'true');
        }
    };
    MenuAdapter.prototype.setOptions = function (options, selectedIndex, dense, iconClassName) {
        if (!this._popupElement) {
            return;
        }
        removeAllChildren(this._popupElement);
        this._popupElement.appendChild(this._buildMenu(options, selectedIndex, dense, iconClassName));
        this._popupElement.position();
    };
    MenuAdapter.prototype.detachMenu = function () {
        if (!this._popupElement) {
            return;
        }
        this._popupElement.open = false;
        this._popupElement = null;
        if (this._toggleElement) {
            this._toggleElement.removeAttribute('aria-expanded');
        }
    };
    MenuAdapter.prototype._buildSpinner = function () {
        var div = document.createElement('div');
        div.style.display = 'flex';
        div.style.justifyContent = 'center';
        div.style.alignItems = 'center';
        div.style.padding = '8px';
        div.style.boxSizing = 'border-box';
        div.style.width = '112px';
        var spinner = document.createElement(PROGRESS_SPINNER_CONSTANTS.elementName);
        spinner.diameter = 24;
        spinner.strokeWidth = 8;
        spinner.style.height = '24px';
        spinner.style.width = '24px';
        div.appendChild(spinner);
        return div;
    };
    MenuAdapter.prototype._buildMenu = function (options, selectedIndex, dense, iconClassName) {
        var _this = this;
        var listElement = document.createElement(LIST_CONSTANTS.elementName);
        listElement.propagateClick = false;
        listElement.dense = dense;
        listElement.setAttribute('role', 'menu');
        listElement.setAttribute('aria-orientation', 'vertical');
        listElement.id = "tcw-menu-" + this._identifier;
        var nonDividerOptions = options.filter(function (option) { return !option.divider; });
        nonDividerOptions.forEach(function (option) { return option.selected = false; });
        if (isDefined(selectedIndex)) {
            nonDividerOptions[selectedIndex].selected = true;
        }
        options.forEach(function (option, index) {
            if (option.divider) {
                var dividerElement = document.createElement(DIVIDER_CONSTANTS.elementName);
                dividerElement.tabIndex = -1;
                listElement.appendChild(dividerElement);
            }
            else {
                var listItemElement = void 0;
                listItemElement = document.createElement(LIST_ITEM_CONSTANTS.elementName);
                listItemElement.value = option.value;
                listItemElement.textContent = option.label;
                listItemElement.style.cursor = 'pointer';
                listItemElement.id = "tcw-menu-option-" + _this._identifier + "-" + index;
                listItemElement.setAttribute('role', 'menuitem');
                listItemElement.setAttribute('aria-selected', 'false');
                listItemElement.setAttribute('aria-disabled', 'false');
                if (option.leadingBuilder) {
                    var element = option.leadingBuilder();
                    element.slot = 'leading';
                    listItemElement.appendChild(element);
                }
                else if (option.icon) {
                    var optionIconElement = document.createElement('i');
                    optionIconElement.classList.add(iconClassName);
                    optionIconElement.setAttribute('aria-hidden', 'true');
                    optionIconElement.textContent = option.icon;
                    optionIconElement.slot = 'leading';
                    listItemElement.appendChild(optionIconElement);
                }
                if (option.disabled) {
                    listItemElement.disabled = true;
                    listItemElement.setAttribute('aria-disabled', 'true');
                }
                else if (option.selected) {
                    listItemElement.selected = true;
                    listItemElement.active = true;
                    listItemElement.setAttribute('aria-selected', 'true');
                }
                listElement.appendChild(listItemElement);
            }
        });
        // If there are no selected options, then we focus the first option
        if (!options.find(function (o) { return o.selected; })) {
            if (listElement.firstElementChild) {
                var firstListItem = listElement.firstElementChild;
                firstListItem.active = true;
                if (this._toggleElement) {
                    this._toggleElement.setAttribute('aria-activedescendant', firstListItem.id);
                }
            }
        }
        return listElement;
    };
    MenuAdapter.prototype.getListElementIndex = function (element) {
        return Array.from(element.parentElement.children)
            .filter(function (el) { return el.tagName === LIST_ITEM_CONSTANTS.elementName.toUpperCase(); })
            .indexOf(element);
    };
    MenuAdapter.prototype.dispatchEvent = function (type, data) {
        emitEvent(this._component, type, data);
    };
    MenuAdapter.prototype.isFocusWithinToggle = function () {
        return !!this._toggleElement && (this._toggleElement === document.activeElement || this._toggleElement.contains(document.activeElement));
    };
    MenuAdapter.prototype.setActiveOption = function (index) {
        var listItems = this._getListItems();
        listItems.forEach(function (li) { return li.active = false; });
        if (listItems[index]) {
            listItems[index].active = true;
            listItems[index].scrollIntoView({ behavior: 'smooth', block: 'center' });
            if (this._toggleElement) {
                this._toggleElement.setAttribute('aria-activedescendant', listItems[index].id);
            }
        }
    };
    MenuAdapter.prototype.getActiveOptionIndex = function () {
        var listItems = this._getListItems();
        return listItems.findIndex(function (li) { return li.active; });
    };
    MenuAdapter.prototype._getListItems = function () {
        if (!this._popupElement) {
            return [];
        }
        var listElement = this._popupElement.querySelector(LIST_CONSTANTS.elementName);
        if (listElement) {
            return Array.from(listElement.querySelectorAll(LIST_ITEM_CONSTANTS.elementName));
        }
        return [];
    };
    return MenuAdapter;
}());
export { MenuAdapter };
