import { __decorate, __extends } from "tslib";
import { CustomElement, upgradeProperty, coerceBoolean } from '@tyler-components-web/core';
import { TableFoundation } from './table-foundation';
import { TableAdapter } from './table-adapter';
import { TABLE_CONSTANTS } from './table-constants';
import { ExpansionPanelComponent } from '../expansion-panel';
/**
 * The custom element class behind the `tcw-table` component.
 */
var TableComponent = /** @class */ (function (_super) {
    __extends(TableComponent, _super);
    function TableComponent() {
        var _this = _super.call(this) || this;
        _this._foundation = new TableFoundation(new TableAdapter(_this));
        return _this;
    }
    Object.defineProperty(TableComponent, "observedAttributes", {
        get: function () {
            return [
                TABLE_CONSTANTS.attributes.SELECT,
                TABLE_CONSTANTS.attributes.MULTISELECT,
                TABLE_CONSTANTS.attributes.SELECT_KEY,
                TABLE_CONSTANTS.attributes.DENSE,
                TABLE_CONSTANTS.attributes.FILTER,
                TABLE_CONSTANTS.attributes.FIXED_HEADERS,
                TABLE_CONSTANTS.attributes.LAYOUT_TYPE,
                TABLE_CONSTANTS.attributes.WRAP_CONTENT,
                TABLE_CONSTANTS.attributes.RESIZABLE
            ];
        },
        enumerable: true,
        configurable: true
    });
    TableComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'data');
        upgradeProperty(this, 'columnConfigurations');
        upgradeProperty(this, 'select');
        upgradeProperty(this, 'multiselect');
        upgradeProperty(this, 'selectKey');
        upgradeProperty(this, 'dense');
        upgradeProperty(this, 'filter');
        upgradeProperty(this, 'fixedHeaders');
        upgradeProperty(this, 'layoutType');
        upgradeProperty(this, 'wrapContent');
        upgradeProperty(this, 'resizable');
        this._foundation.initialize();
    };
    TableComponent.prototype.connectedCallback = function () {
        this._foundation.connect();
    };
    TableComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    TableComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case TABLE_CONSTANTS.attributes.SELECT:
                this.select = coerceBoolean(newValue);
                break;
            case TABLE_CONSTANTS.attributes.MULTISELECT:
                this.multiselect = coerceBoolean(newValue);
                break;
            case TABLE_CONSTANTS.attributes.SELECT_KEY:
                this.selectKey = newValue;
                break;
            case TABLE_CONSTANTS.attributes.DENSE:
                this.dense = coerceBoolean(newValue);
                break;
            case TABLE_CONSTANTS.attributes.FILTER:
                this.filter = coerceBoolean(newValue);
                break;
            case TABLE_CONSTANTS.attributes.FIXED_HEADERS:
                this.fixedHeaders = this.hasAttribute(TABLE_CONSTANTS.attributes.FIXED_HEADERS);
                break;
            case TABLE_CONSTANTS.attributes.LAYOUT_TYPE:
                this.layoutType = newValue;
                break;
            case TABLE_CONSTANTS.attributes.WRAP_CONTENT:
                this.wrapContent = coerceBoolean(newValue);
                break;
            case TABLE_CONSTANTS.attributes.RESIZABLE:
                this.resizable = this.hasAttribute(TABLE_CONSTANTS.attributes.RESIZABLE);
                break;
        }
    };
    /**
     * Hides a column from the table.
     * @param columnIndex The index of the column to hide.
     */
    TableComponent.prototype.hideColumn = function (columnIndex) {
        this._foundation.hideColumn(columnIndex);
    };
    /**
     * Shows a hidden column in th table.
     * @param columnIndex The index of the column to show.
     */
    TableComponent.prototype.showColumn = function (columnIndex) {
        this._foundation.showColumn(columnIndex);
    };
    /** Returns the selected row instances. */
    TableComponent.prototype.getSelectedRows = function () {
        return this._foundation.getSelectedRows();
    };
    /**
     * Selects a row in the table. Only applicable if `select` is true.
     * @param data The row data behind the row that should be selected.
     */
    TableComponent.prototype.selectRow = function (data) {
        this._foundation.selectRows([data], false);
    };
    /**
     * Selects one or more rows in the table. Only applicable if `select` is true.
     * @param data The row data begind the rows that should be selected.
     * @param [preserveExisting=false] Should existing row selections be preserved or cleared.
     */
    TableComponent.prototype.selectRows = function (data, preserveExisting) {
        if (preserveExisting === void 0) { preserveExisting = false; }
        this._foundation.selectRows(data, !preserveExisting);
    };
    /**
     * Deselects a single row in the table.
     * @param data The data behind the row that should be deselected.
     */
    TableComponent.prototype.deselectRow = function (data) {
        this._foundation.deselectRows([data]);
    };
    /**
     * Deselects one or more rows in the table.
     * @param data The data behind the rows that should be deselected.
     */
    TableComponent.prototype.deselectRows = function (data) {
        this._foundation.deselectRows(data);
    };
    /**
     * Clears all selected table rows.
     */
    TableComponent.prototype.clearSelections = function () {
        this._foundation.clearSelections();
    };
    /**
     * Forces the table to re-render based on its current configuration.
     */
    TableComponent.prototype.render = function () {
        this._foundation.render();
    };
    /**
     * Expands a collapsed row.
     * @param rowIndex The index of the row to expand.
     */
    TableComponent.prototype.expandRow = function (rowIndex, template) {
        return this._foundation.expandRow(rowIndex, template);
    };
    /**
     * Collapses an expanded row.
     * @param rowIndex The index of the row to expand.
     */
    TableComponent.prototype.collapseRow = function (rowIndex) {
        return this._foundation.collapseRow(rowIndex);
    };
    /**
     * Checks if a row is expanded or not.
     * @param rowIndex The row index to check.
     */
    TableComponent.prototype.isRowExpanded = function (rowIndex) {
        return this._foundation.isRowExpanded(rowIndex);
    };
    Object.defineProperty(TableComponent.prototype, "data", {
        get: function () {
            return this._foundation.data;
        },
        /** The data to be display in the table body. */
        set: function (value) {
            this._foundation.data = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableComponent.prototype, "columnConfigurations", {
        get: function () {
            return this._foundation.columnConfigurations;
        },
        /** The column configuration options. */
        set: function (value) {
            this._foundation.columnConfigurations = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableComponent.prototype, "select", {
        get: function () {
            return this._foundation.select;
        },
        /** Controls the visibility of the select column. */
        set: function (value) {
            this._foundation.select = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableComponent.prototype, "multiselect", {
        get: function () {
            return this._foundation.multiselect;
        },
        /** Controls the visibility of the select all checkbox. */
        set: function (value) {
            this._foundation.multiselect = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableComponent.prototype, "selectKey", {
        get: function () {
            return this._foundation.selectKey;
        },
        /** The row key for matching data to selections. */
        set: function (value) {
            this._foundation.selectKey = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableComponent.prototype, "dense", {
        get: function () {
            return this._foundation.dense;
        },
        /** Controls whether the table is dense or not. False by default. */
        set: function (value) {
            this._foundation.dense = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableComponent.prototype, "filter", {
        get: function () {
            return this._foundation.filter;
        },
        /** Controls whether the table filters are visible or not. */
        set: function (value) {
            this._foundation.filter = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableComponent.prototype, "fixedHeaders", {
        get: function () {
            return this._foundation.fixedHeaders;
        },
        /** Controls whether the table applies fixed headers when in scroll containers. */
        set: function (value) {
            this._foundation.fixedHeaders = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableComponent.prototype, "layoutType", {
        get: function () {
            return this._foundation.layoutType;
        },
        /** Controls the table layout algorithm. */
        set: function (value) {
            this._foundation.layoutType = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableComponent.prototype, "wrapContent", {
        get: function () {
            return this._foundation.wrapContent;
        },
        /** Controls whether the content in each cell wraps or not (true by default). */
        set: function (value) {
            this._foundation.wrapContent = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableComponent.prototype, "resizable", {
        get: function () {
            return this._foundation.resizable;
        },
        /** Controls whether the columns are resizable or not. */
        set: function (value) {
            this._foundation.resizable = value;
        },
        enumerable: true,
        configurable: true
    });
    TableComponent = __decorate([
        CustomElement({
            name: TABLE_CONSTANTS.elementName,
            dependencies: [
                ExpansionPanelComponent
            ]
        })
    ], TableComponent);
    return TableComponent;
}(HTMLElement));
export { TableComponent };
