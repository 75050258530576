import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, coerceBoolean, upgradeProperty } from '@tyler-components-web/core';
import { ProfileCardAdapter } from './profile-card-adapter';
import { ProfileCardFoundation } from './profile-card-foundation';
import { PROFILE_CARD_CONSTANTS } from './profile-card-constants';
import { ButtonComponent } from '../../button';
import { AvatarComponent } from '../../avatar';
import { ToolbarComponent } from '../../toolbar';
var template = require('./profile-card.html');
var styles = require('./profile-card.css');
var ProfileCardComponent = /** @class */ (function (_super) {
    __extends(ProfileCardComponent, _super);
    function ProfileCardComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, PROFILE_CARD_CONSTANTS.elementName, template, styles);
        _this._foundation = new ProfileCardFoundation(new ProfileCardAdapter(_this));
        return _this;
    }
    Object.defineProperty(ProfileCardComponent, "observedAttributes", {
        get: function () {
            return [
                PROFILE_CARD_CONSTANTS.attributes.FULL_NAME,
                PROFILE_CARD_CONSTANTS.attributes.EMAIL,
                PROFILE_CARD_CONSTANTS.attributes.SIGN_OUT,
                PROFILE_CARD_CONSTANTS.attributes.PROFILE
            ];
        },
        enumerable: true,
        configurable: true
    });
    ProfileCardComponent.prototype.connectedCallback = function () {
        upgradeProperty(this, 'fullName');
        upgradeProperty(this, 'email');
        upgradeProperty(this, 'signOut');
        upgradeProperty(this, 'profile');
        this._foundation.initialize();
    };
    ProfileCardComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case PROFILE_CARD_CONSTANTS.attributes.FULL_NAME:
                this.fullName = newValue;
                break;
            case PROFILE_CARD_CONSTANTS.attributes.EMAIL:
                this.email = newValue;
                break;
            case PROFILE_CARD_CONSTANTS.attributes.SIGN_OUT:
                this.signOut = coerceBoolean(PROFILE_CARD_CONSTANTS.attributes.SIGN_OUT);
                break;
            case PROFILE_CARD_CONSTANTS.attributes.PROFILE:
                this.profile = coerceBoolean(PROFILE_CARD_CONSTANTS.attributes.PROFILE);
                break;
        }
    };
    Object.defineProperty(ProfileCardComponent.prototype, "fullName", {
        get: function () {
            return this._foundation.fullName;
        },
        set: function (value) {
            this._foundation.fullName = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileCardComponent.prototype, "email", {
        get: function () {
            return this._foundation.email;
        },
        set: function (value) {
            this._foundation.email = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileCardComponent.prototype, "signOut", {
        get: function () {
            return this._foundation.signOut;
        },
        set: function (value) {
            this._foundation.signOut = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileCardComponent.prototype, "profile", {
        get: function () {
            return this._foundation.profile;
        },
        set: function (value) {
            this._foundation.profile = value;
        },
        enumerable: true,
        configurable: true
    });
    ProfileCardComponent = __decorate([
        CustomElement({
            name: PROFILE_CARD_CONSTANTS.elementName,
            dependencies: [
                ToolbarComponent,
                ButtonComponent,
                AvatarComponent
            ]
        })
    ], ProfileCardComponent);
    return ProfileCardComponent;
}(HTMLElement));
export { ProfileCardComponent };
