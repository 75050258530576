import { __decorate, __extends } from "tslib";
import { MDCSwitch } from '@material/switch';
import { CustomElement, attachLightTemplate, ensureChildren, upgradeProperty } from '@tyler-components-web/core';
import { SWITCH_CONSTANTS } from './switch-constants';
var template = require('./switch.html');
/**
 * The custom element class behind the `<tcw-switch>` element.
 */
var SwitchComponent = /** @class */ (function (_super) {
    __extends(SwitchComponent, _super);
    function SwitchComponent() {
        var _this = _super.call(this) || this;
        /** Whether this is a dense switch. */
        _this._dense = false;
        return _this;
    }
    Object.defineProperty(SwitchComponent, "observedAttributes", {
        get: function () {
            return [
                SWITCH_CONSTANTS.attributes.DENSE
            ];
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Synchronizes the component state with the DOM.
     */
    SwitchComponent.prototype.syncDOM = function () {
        if (!this._inputElement) {
            return;
        }
        var hasCheckedClass = this.classList.contains(SWITCH_CONSTANTS.classes.CHECKED);
        var inputChecked = this._inputElement.checked || this._inputElement.hasAttribute('checked');
        if (inputChecked && !hasCheckedClass) {
            this.classList.add(SWITCH_CONSTANTS.classes.CHECKED);
        }
        else if (!inputChecked && hasCheckedClass) {
            this.classList.remove(SWITCH_CONSTANTS.classes.CHECKED);
        }
        var hasDisabledClass = this.classList.contains(SWITCH_CONSTANTS.classes.DISABLED);
        var inputDisabled = this._inputElement.disabled || this._inputElement.hasAttribute('disabled');
        if (inputDisabled && !hasDisabledClass) {
            this.classList.add(SWITCH_CONSTANTS.classes.DISABLED);
        }
        else if (!inputDisabled && hasDisabledClass) {
            this.classList.remove(SWITCH_CONSTANTS.classes.DISABLED);
        }
        this._inputElement.setAttribute('aria-checked', this._inputElement.checked.toString());
    };
    SwitchComponent.prototype._ensureChildren = function (callback) {
        if (this.children.length) {
            callback();
        }
        else {
            // if we don't have a ensure children promise, create one.
            if (!this._ensureChildrenPromise) {
                this._ensureChildrenPromise = ensureChildren(this);
            }
            // chain the promise so that any callbacks occur in the order they are submitted
            this._ensureChildrenPromise = this._ensureChildrenPromise.then(function () { return callback(); });
        }
    };
    SwitchComponent.prototype._initialize = function () {
        this._inputElement = this.querySelector(SWITCH_CONSTANTS.selectors.INPUT);
        if (!this._inputElement) {
            return;
        }
        this.classList.add(SWITCH_CONSTANTS.classes.SWITCH);
        attachLightTemplate(this, template);
    };
    SwitchComponent.prototype._connect = function () {
        var _this = this;
        var inputElement = this.querySelector(SWITCH_CONSTANTS.selectors.INPUT);
        if (!inputElement) {
            return;
        }
        this._inputElement.classList.add(SWITCH_CONSTANTS.classes.NATIVE_CONTROL);
        this._inputElement.setAttribute('role', 'switch');
        this._inputElement.addEventListener('change', function () { return _this._inputElement.setAttribute('aria-checked', _this._inputElement.checked.toString()); });
        var thumbElement = this.querySelector(SWITCH_CONSTANTS.selectors.THUMB);
        thumbElement.appendChild(this._inputElement);
        this.mdcSwitch = new MDCSwitch(this);
        this.mdcSwitch.initialize();
        this.syncDOM();
        this._inputMutationObserver = new MutationObserver(function (event) { return _this.syncDOM(); });
        this._inputMutationObserver.observe(this._inputElement, { attributes: true, attributeFilter: ['disabled', 'checked'] });
    };
    SwitchComponent.prototype.initializedCallback = function () {
        var _this = this;
        upgradeProperty(this, 'dense');
        this._ensureChildren(function () { return _this._initialize(); });
    };
    SwitchComponent.prototype.connectedCallback = function () {
        var _this = this;
        this._ensureChildren(function () { return _this._connect(); });
    };
    SwitchComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case SWITCH_CONSTANTS.attributes.DENSE:
                this.dense = this.hasAttribute(SWITCH_CONSTANTS.attributes.DENSE);
                break;
        }
    };
    SwitchComponent.prototype.disconnectedCallback = function () {
        if (this._inputMutationObserver) {
            this._inputMutationObserver.disconnect();
        }
        this._inputElement = undefined;
        if (this.mdcSwitch) {
            this.mdcSwitch.destroy();
        }
    };
    Object.defineProperty(SwitchComponent.prototype, "dense", {
        /** Gets/sets whether the switch is dense. */
        get: function () {
            return this._dense;
        },
        set: function (value) {
            if (this._dense !== value) {
                this._dense = value;
                if (this._dense) {
                    this.setAttribute(SWITCH_CONSTANTS.attributes.DENSE, '');
                    this.classList.add(SWITCH_CONSTANTS.classes.SWITCH_DENSE);
                }
                else {
                    this.removeAttribute(SWITCH_CONSTANTS.attributes.DENSE);
                    this.classList.remove(SWITCH_CONSTANTS.classes.SWITCH_DENSE);
                }
                // re-layout the ripple for cases where dense was changed after initial layout
                if (this.mdcSwitch) {
                    this.mdcSwitch.ripple.layout();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    SwitchComponent = __decorate([
        CustomElement({
            name: SWITCH_CONSTANTS.elementName
        })
    ], SwitchComponent);
    return SwitchComponent;
}(HTMLElement));
export { SwitchComponent };
