import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, coerceNumber, coerceBoolean, upgradeProperty } from '@tyler-components-web/core';
import { ProductIconAdapter } from './product-icon-adapter';
import { ProductIconFoundation } from './product-icon-foundation';
import { PRODUCT_ICON_CONSTANTS } from './product-icon-constants';
var template = require('./product-icon.html');
var styles = require('./product-icon.css');
var ProductIconComponent = /** @class */ (function (_super) {
    __extends(ProductIconComponent, _super);
    function ProductIconComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, PRODUCT_ICON_CONSTANTS.elementName, template, styles);
        _this._foundation = new ProductIconFoundation(new ProductIconAdapter(_this));
        return _this;
    }
    Object.defineProperty(ProductIconComponent, "observedAttributes", {
        get: function () {
            return [
                PRODUCT_ICON_CONSTANTS.attributes.COLOR,
                PRODUCT_ICON_CONSTANTS.attributes.SIZE,
                PRODUCT_ICON_CONSTANTS.attributes.SHADOW,
                PRODUCT_ICON_CONSTANTS.attributes.ITERATIONS,
                PRODUCT_ICON_CONSTANTS.attributes.MODE
            ];
        },
        enumerable: true,
        configurable: true
    });
    ProductIconComponent.prototype.connectedCallback = function () {
        upgradeProperty(this, 'color');
        upgradeProperty(this, 'size');
        upgradeProperty(this, 'shadow');
        upgradeProperty(this, 'iterations');
        upgradeProperty(this, 'mode');
        this._foundation.initialize();
    };
    ProductIconComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case PRODUCT_ICON_CONSTANTS.attributes.COLOR:
                this.color = newValue;
                break;
            case PRODUCT_ICON_CONSTANTS.attributes.SIZE:
                this.size = coerceNumber(newValue);
                break;
            case PRODUCT_ICON_CONSTANTS.attributes.SHADOW:
                this.shadow = coerceBoolean(newValue);
                break;
            case PRODUCT_ICON_CONSTANTS.attributes.ITERATIONS:
                this.iterations = coerceNumber(newValue);
                break;
            case PRODUCT_ICON_CONSTANTS.attributes.MODE:
                this.mode = newValue;
                break;
        }
    };
    Object.defineProperty(ProductIconComponent.prototype, "color", {
        /**
         * The color of the container element from the material color palette.
         */
        get: function () {
            return this._foundation.color;
        },
        set: function (value) {
            this._foundation.color = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductIconComponent.prototype, "mode", {
        /**
         * Controls what rendering mode to use (text, icon).
         */
        get: function () {
            return this._foundation.mode;
        },
        set: function (value) {
            this._foundation.mode = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductIconComponent.prototype, "size", {
        /**
         * Controls the height and width of the container element.
         */
        get: function () {
            return this._foundation.size;
        },
        set: function (value) {
            this._foundation.size = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductIconComponent.prototype, "shadow", {
        /**
         * Controls the visibility of the text shadow.
         */
        get: function () {
            return this._foundation.shadow;
        },
        set: function (value) {
            this._foundation.shadow = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductIconComponent.prototype, "iterations", {
        /**
         * The number of iterations to apply the text shadow.
         */
        get: function () {
            return this._foundation.iterations;
        },
        set: function (value) {
            this._foundation.iterations = value;
        },
        enumerable: true,
        configurable: true
    });
    ProductIconComponent = __decorate([
        CustomElement({
            name: PRODUCT_ICON_CONSTANTS.elementName
        })
    ], ProductIconComponent);
    return ProductIconComponent;
}(HTMLElement));
export { ProductIconComponent };
