import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, requireParent, upgradeProperty, elementParents, coerceBoolean } from '@tyler-components-web/core';
import { ListItemAdapter } from './list-item-adapter';
import { ListItemFoundation } from './list-item-foundation';
import { LIST_ITEM_CONSTANTS } from './list-item-constants';
import { LIST_CONSTANTS } from '../list/list-constants';
import { DRAWER_CONSTANTS } from '../../drawer';
var defaultTemplate = require('./list-item.html');
var styles = require('./list-item.css');
/**
 * The custom element class behind the `<tcw-list-item>` element.
 */
var ListItemComponent = /** @class */ (function (_super) {
    __extends(ListItemComponent, _super);
    function ListItemComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, LIST_ITEM_CONSTANTS.elementName, defaultTemplate, styles);
        _this._foundation = new ListItemFoundation(new ListItemAdapter(_this));
        return _this;
    }
    Object.defineProperty(ListItemComponent, "observedAttributes", {
        get: function () {
            return [
                LIST_ITEM_CONSTANTS.attributes.STATIC,
                LIST_ITEM_CONSTANTS.attributes.TWO_LINE,
                LIST_ITEM_CONSTANTS.attributes.THREE_LINE,
                LIST_ITEM_CONSTANTS.attributes.ACTIVE,
                LIST_ITEM_CONSTANTS.attributes.SELECTED,
                LIST_ITEM_CONSTANTS.attributes.VALUE,
                LIST_ITEM_CONSTANTS.attributes.HREF,
                LIST_ITEM_CONSTANTS.attributes.TARGET,
                LIST_ITEM_CONSTANTS.attributes.NO_RIPPLE,
                LIST_ITEM_CONSTANTS.attributes.DISABLED,
                LIST_ITEM_CONSTANTS.attributes.DENSE,
                LIST_ITEM_CONSTANTS.attributes.PROPAGATE_CLICK,
                LIST_ITEM_CONSTANTS.attributes.INDENTED
            ];
        },
        enumerable: true,
        configurable: true
    });
    ListItemComponent.prototype.initializedCallback = function () {
        // To simulate the :host-context() selector for Firefox until they implement it, we need to determine if the
        // list item is within a drawer for auto-styling the list item when included within a drawer. Check to see if
        // any of the parents of this element are a drawer.
        if (!this.hasAttribute(LIST_ITEM_CONSTANTS.attributes.DRAWER_CONTEXT) && elementParents(this).some(function (el) { return el.tagName === DRAWER_CONSTANTS.elementName.toUpperCase(); })) {
            this.setAttribute(LIST_ITEM_CONSTANTS.attributes.DRAWER_CONTEXT, 'true');
        }
        upgradeProperty(this, 'static');
        upgradeProperty(this, 'twoLine');
        upgradeProperty(this, 'threeLine');
        upgradeProperty(this, 'active');
        upgradeProperty(this, 'selected');
        upgradeProperty(this, 'value');
        upgradeProperty(this, 'href');
        upgradeProperty(this, 'target');
        upgradeProperty(this, 'ripple');
        upgradeProperty(this, 'disabled');
        upgradeProperty(this, 'dense');
        upgradeProperty(this, 'propagateClick');
        upgradeProperty(this, 'indented');
        var list = requireParent(this, LIST_CONSTANTS.elementName);
        if (list) {
            this._inheritParentListProps(list);
            // Check if this list item value is intended to be selected via the parent list
            if (list.selectedValue) {
                var listValues = list.selectedValue instanceof Array ? list.selectedValue : [list.selectedValue];
                if (listValues.includes(this.value)) {
                    this.selected = true;
                }
            }
        }
        this._foundation.initialize();
    };
    ListItemComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    ListItemComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case LIST_ITEM_CONSTANTS.attributes.STATIC:
                this.static = this.hasAttribute(LIST_ITEM_CONSTANTS.attributes.STATIC);
                break;
            case LIST_ITEM_CONSTANTS.attributes.TWO_LINE:
                this.twoLine = this.hasAttribute(LIST_ITEM_CONSTANTS.attributes.TWO_LINE);
                break;
            case LIST_ITEM_CONSTANTS.attributes.THREE_LINE:
                this.threeLine = this.hasAttribute(LIST_ITEM_CONSTANTS.attributes.THREE_LINE);
                break;
            case LIST_ITEM_CONSTANTS.attributes.ACTIVE:
                this.active = this.hasAttribute(LIST_ITEM_CONSTANTS.attributes.ACTIVE);
                break;
            case LIST_ITEM_CONSTANTS.attributes.SELECTED:
                this.selected = this.hasAttribute(LIST_ITEM_CONSTANTS.attributes.SELECTED);
                break;
            case LIST_ITEM_CONSTANTS.attributes.VALUE:
                this.value = newValue;
                break;
            case LIST_ITEM_CONSTANTS.attributes.HREF:
                this.href = newValue;
                break;
            case LIST_ITEM_CONSTANTS.attributes.TARGET:
                this.target = newValue;
                break;
            case LIST_ITEM_CONSTANTS.attributes.NO_RIPPLE:
                this.ripple = !this.hasAttribute(LIST_ITEM_CONSTANTS.attributes.NO_RIPPLE);
                break;
            case LIST_ITEM_CONSTANTS.attributes.DISABLED:
                this.disabled = this.hasAttribute(LIST_ITEM_CONSTANTS.attributes.DISABLED);
                break;
            case LIST_ITEM_CONSTANTS.attributes.DENSE:
                this.dense = this.hasAttribute(LIST_ITEM_CONSTANTS.attributes.DENSE);
                break;
            case LIST_ITEM_CONSTANTS.attributes.PROPAGATE_CLICK:
                this.propagateClick = coerceBoolean(newValue);
                break;
            case LIST_ITEM_CONSTANTS.attributes.INDENTED:
                this.indented = this.hasAttribute(LIST_ITEM_CONSTANTS.attributes.INDENTED);
                break;
        }
    };
    ListItemComponent.prototype._inheritParentListProps = function (list) {
        if (list.hasAttribute(LIST_CONSTANTS.attributes.STATIC)) {
            this.static = true;
        }
        if (list.hasAttribute(LIST_CONSTANTS.attributes.DENSE)) {
            this.dense = true;
        }
        if (list.getAttribute(LIST_CONSTANTS.attributes.PROPAGATE_CLICK) === 'false') {
            this.propagateClick = false;
        }
        if (list.hasAttribute(LIST_CONSTANTS.attributes.INDENTED)) {
            this.indented = true;
        }
    };
    Object.defineProperty(ListItemComponent.prototype, "static", {
        /** Gets/sets whether the static state of this list item. */
        get: function () {
            return this._foundation.static;
        },
        set: function (value) {
            this._foundation.static = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemComponent.prototype, "twoLine", {
        /** Gets/sets whether the list item displays two lines of text. */
        get: function () {
            return this._foundation.twoLine;
        },
        set: function (value) {
            this._foundation.twoLine = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemComponent.prototype, "threeLine", {
        /** Gets/sets whether the list item displays three lines of text. */
        get: function () {
            return this._foundation.threeLine;
        },
        set: function (value) {
            this._foundation.threeLine = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemComponent.prototype, "active", {
        /** Gets/sets whether the list item is active or not. */
        get: function () {
            return this._foundation.active;
        },
        set: function (value) {
            this._foundation.active = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemComponent.prototype, "selected", {
        /** Gets/sets whether the list item is selected or not. */
        get: function () {
            return this._foundation.selected;
        },
        set: function (value) {
            this._foundation.selected = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemComponent.prototype, "value", {
        /** Gets/sets list item value. */
        get: function () {
            return this._foundation.value;
        },
        set: function (value) {
            this._foundation.value = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemComponent.prototype, "href", {
        /** Gets/sets the href link that this list item will send the browser to when clicked. */
        get: function () {
            return this._foundation.href;
        },
        set: function (value) {
            this._foundation.href = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemComponent.prototype, "target", {
        /** Gets/sets the href link target. Only pertains when `href` is also used. */
        get: function () {
            return this._foundation.target;
        },
        set: function (value) {
            this._foundation.target = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemComponent.prototype, "ripple", {
        /** Gets/sets whether the list item has a ripple or not. */
        get: function () {
            return this._foundation.ripple;
        },
        set: function (value) {
            this._foundation.ripple = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemComponent.prototype, "disabled", {
        /** Gets/sets whether the list item is disabled or not. */
        get: function () {
            return this._foundation.disabled;
        },
        set: function (value) {
            this._foundation.disabled = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemComponent.prototype, "dense", {
        /** Gets/sets whether the list item is using dense styles or not. */
        get: function () {
            return this._foundation.dense;
        },
        set: function (value) {
            this._foundation.dense = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemComponent.prototype, "propagateClick", {
        /** Gets/sets whether the list item allows mousedown events through to the underlying list item element. Default is true. */
        get: function () {
            return this._foundation.propagateClick;
        },
        set: function (value) {
            this._foundation.propagateClick = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemComponent.prototype, "indented", {
        /** Gets/sets whether the list item is indented or not. Default is false. */
        get: function () {
            return this._foundation.indented;
        },
        set: function (value) {
            this._foundation.indented = value;
        },
        enumerable: true,
        configurable: true
    });
    /** Sets focus to this list item. */
    ListItemComponent.prototype.focus = function () {
        this._foundation.setFocus();
    };
    ListItemComponent = __decorate([
        CustomElement({
            name: LIST_ITEM_CONSTANTS.elementName
        })
    ], ListItemComponent);
    return ListItemComponent;
}(HTMLElement));
export { ListItemComponent };
