import { isDefined } from '@tyler-components-web/core';
import { DIALOG_CONSTANTS } from './dialog-constants';
var DialogFoundation = /** @class */ (function () {
    function DialogFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        // Private vars
        this._open = false;
        this._backdropClose = true;
        this._escapeClose = true;
        this._fullscreen = false;
        // Event handler states
        this._attachedDocumentKeydownHandler = false;
        this._attachedBackdropClickHandler = false;
        this._transitionEndHandler = function (evt) { return _this._onTransitionEnd(evt); };
        this._documentKeydownHandler = function (evt) { return _this._onDocumentKeydown(evt); };
        this._backdropClickHandler = function (evt) { return _this._onBackdropClick(evt); };
    }
    DialogFoundation.prototype.destroy = function () {
        if (this._open) {
            this._adapter.deregisterTransitionEndHandler(this._transitionEndHandler);
            if (this._attachedDocumentKeydownHandler) {
                this._adapter.deregisterDocumentKeydownHandler(this._documentKeydownHandler);
            }
            if (this._attachedBackdropClickHandler) {
                this._adapter.deregisterBackdropClickHandler(this._backdropClickHandler);
            }
        }
    };
    Object.defineProperty(DialogFoundation.prototype, "backdropClose", {
        get: function () {
            return this._backdropClose;
        },
        /** Controls whether clicking the backdrop closes the dialog or not. */
        set: function (value) {
            value = Boolean(value);
            if (this._backdropClose !== value) {
                this._backdropClose = value;
                // TODO: Destroy the current focus trap and renable with the new option value
                this._setBackdropClickListener(this._backdropClose);
                if (isDefined(this._backdropClose)) {
                    this._adapter.setHostAttribute(DIALOG_CONSTANTS.attributes.BACKDROP_CLOSE, this._backdropClose.toString());
                }
                else {
                    this._adapter.removeHostAttribute(DIALOG_CONSTANTS.attributes.BACKDROP_CLOSE);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogFoundation.prototype, "escapeClose", {
        get: function () {
            return this._escapeClose;
        },
        /** Controls whether pressing the escape key closes the dialog or not. */
        set: function (value) {
            value = Boolean(value);
            if (this._escapeClose !== value) {
                this._escapeClose = value;
                this._setDocumentKeydownListener(this._escapeClose);
                if (isDefined(this._escapeClose)) {
                    this._adapter.setHostAttribute(DIALOG_CONSTANTS.attributes.ESCAPE_CLOSE, this._escapeClose.toString());
                }
                else {
                    this._adapter.removeHostAttribute(DIALOG_CONSTANTS.attributes.ESCAPE_CLOSE);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogFoundation.prototype, "open", {
        get: function () {
            return this._open;
        },
        set: function (value) {
            var _this = this;
            if (this._open !== value) {
                value = Boolean(value);
                if (value !== this._open) {
                    if (value) {
                        if (this._openCallback) {
                            Promise.resolve(this._openCallback()).then(function () {
                                _this._open = value;
                                _this._openDialog();
                            }).catch(function () { });
                        }
                        else {
                            this._open = value;
                            this._openDialog();
                        }
                    }
                    else {
                        if (this._closeCallback) {
                            Promise.resolve(this._closeCallback()).then(function () {
                                _this._open = value;
                                _this._closeDialog();
                            }).catch(function () { });
                        }
                        else {
                            this._open = value;
                            this._closeDialog();
                        }
                    }
                }
                if (isDefined(this._open)) {
                    this._adapter.setHostAttribute(DIALOG_CONSTANTS.attributes.OPEN, this._open.toString());
                }
                else {
                    this._adapter.removeHostAttribute(DIALOG_CONSTANTS.attributes.OPEN);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogFoundation.prototype, "fullscreen", {
        get: function () {
            return this._fullscreen;
        },
        set: function (value) {
            if (this._fullscreen !== value) {
                this._fullscreen = value;
                this._adapter.setFullscreen(this._fullscreen);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogFoundation.prototype, "openCallback", {
        set: function (callback) {
            this._openCallback = callback;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogFoundation.prototype, "closeCallback", {
        set: function (callback) {
            this._closeCallback = callback;
        },
        enumerable: true,
        configurable: true
    });
    DialogFoundation.prototype._openDialog = function () {
        var _this = this;
        this._adapter.add();
        this._adapter.setBodyAttribute(DIALOG_CONSTANTS.attributes.OPEN, 'true');
        this._adapter.registerTransitionEndHandler(this._transitionEndHandler);
        this._setDocumentKeydownListener(this._escapeClose);
        this._setBackdropClickListener(this._backdropClose);
        this._adapter.setAnimating(true);
        // Ensure transitions are triggered properly
        window.requestAnimationFrame(function () {
            window.requestAnimationFrame(function () {
                _this._adapter.setVisibility(true);
                _this._adapter.emitEvent(DIALOG_CONSTANTS.events.OPEN);
                _this._adapter.trySetInitialFocus();
                if (_this._adapter.isScrollable()) {
                    _this._adapter.addRootClass(DIALOG_CONSTANTS.classes.SCROLLABLE);
                }
            });
        });
    };
    DialogFoundation.prototype._closeDialog = function () {
        var _this = this;
        this._adapter.deregisterTransitionEndHandler(this._transitionEndHandler);
        if (this._attachedDocumentKeydownHandler) {
            this._adapter.deregisterDocumentKeydownHandler(this._documentKeydownHandler);
        }
        if (this._attachedBackdropClickHandler) {
            this._adapter.deregisterBackdropClickHandler(this._backdropClickHandler);
        }
        this._adapter.setAnimating(true);
        this._adapter.setVisibility(false);
        setTimeout(function () {
            _this._adapter.emitEvent(DIALOG_CONSTANTS.events.CLOSE);
            _this._adapter.remove();
            var openDialogs = _this._adapter.getOpenDialogs(DIALOG_CONSTANTS.elementName);
            if (!openDialogs.length) {
                _this._adapter.removeBodyAttribute(DIALOG_CONSTANTS.attributes.OPEN);
            }
        }, DIALOG_CONSTANTS.numbers.ANIMATION_DURATION);
    };
    DialogFoundation.prototype._onTransitionEnd = function (evt) {
        this._adapter.deregisterTransitionEndHandler(this._transitionEndHandler);
        this._adapter.setAnimating(false);
    };
    DialogFoundation.prototype._onDocumentKeydown = function (evt) {
        evt.stopPropagation();
        if (evt.key && (evt.key === 'Escape' || evt.key === 'Esc')) {
            this.open = false;
        }
    };
    DialogFoundation.prototype._onBackdropClick = function (evt) {
        evt.stopPropagation();
        this.open = false;
    };
    DialogFoundation.prototype._setBackdropClickListener = function (attach) {
        if (!this._open) {
            return;
        }
        if (attach && !this._attachedBackdropClickHandler) {
            this._adapter.registerBackdropClickHandler(this._backdropClickHandler);
            this._attachedBackdropClickHandler = true;
        }
        else if (!attach && this._attachedBackdropClickHandler) {
            this._adapter.deregisterBackdropClickHandler(this._backdropClickHandler);
            this._attachedBackdropClickHandler = false;
        }
    };
    DialogFoundation.prototype._setDocumentKeydownListener = function (attach) {
        if (!this._open) {
            return;
        }
        if (attach && !this._attachedDocumentKeydownHandler) {
            this._adapter.registerDocumentKeydownHandler(this._documentKeydownHandler);
            this._attachedDocumentKeydownHandler = true;
        }
        else if (!attach && this._attachedDocumentKeydownHandler) {
            this._adapter.deregisterDocumentKeydownHandler(this._documentKeydownHandler);
            this._attachedDocumentKeydownHandler = false;
        }
    };
    return DialogFoundation;
}());
export { DialogFoundation };
