import { getShadowElement } from '@tyler-components-web/core';
import { PROGRESS_SPINNER_CONSTANTS } from './progress-spinner-constants';
/**
 * Provides facilities for interacting with the internal DOM of `ProgressSpinnerComponent`.
 */
var ProgressSpinnerAdapter = /** @class */ (function () {
    function ProgressSpinnerAdapter(_component) {
        this._component = _component;
        this._animationStyleElement = getShadowElement(_component, PROGRESS_SPINNER_CONSTANTS.selectors.ANIMATION_STYLE_CONTAINER);
        this._containerElement = getShadowElement(_component, PROGRESS_SPINNER_CONSTANTS.selectors.CONTAINER);
        this._svgElement = getShadowElement(_component, 'svg');
        this._circleElement = getShadowElement(_component, 'svg > circle');
    }
    ProgressSpinnerAdapter.prototype.setHostAttribute = function (name, value) {
        this._component.setAttribute(name, value);
    };
    ProgressSpinnerAdapter.prototype.setAnimationStyle = function (content) {
        this._animationStyleElement.textContent = content;
    };
    ProgressSpinnerAdapter.prototype.setContainerSize = function (size) {
        this._containerElement.style.height = size + "px";
        this._containerElement.style.width = size + "px";
    };
    ProgressSpinnerAdapter.prototype.setSvgSize = function (size) {
        this._svgElement.style.height = size + "px";
        this._svgElement.style.width = size + "px";
    };
    ProgressSpinnerAdapter.prototype.setSvgViewBox = function (value) {
        this._svgElement.setAttribute('viewBox', value);
    };
    ProgressSpinnerAdapter.prototype.setCircleRadius = function (radius) {
        this._circleElement.setAttribute('r', radius.toString());
    };
    ProgressSpinnerAdapter.prototype.setCircleStyle = function (styles) {
        var _this = this;
        Object.keys(styles).forEach(function (key) {
            _this._circleElement.style[key] = styles[key];
        });
    };
    ProgressSpinnerAdapter.prototype.setAnimationClass = function (name) {
        this._containerElement.classList.add(name);
    };
    ProgressSpinnerAdapter.prototype.setContainerAttribute = function (name, value) {
        this._containerElement.setAttribute(name, value);
    };
    return ProgressSpinnerAdapter;
}());
export { ProgressSpinnerAdapter };
