import { __decorate, __extends } from "tslib";
import { CustomElement, upgradeProperty, ensureChildren, isDefined } from '@tyler-components-web/core';
import { MenuAdapter } from './menu-adapter';
import { MenuFoundation } from './menu-foundation';
import { MENU_CONSTANTS } from './menu-constants';
import { PopupComponent } from '../popup';
import { ListComponent } from '../list';
var MenuComponent = /** @class */ (function (_super) {
    __extends(MenuComponent, _super);
    function MenuComponent() {
        var _this = _super.call(this) || this;
        _this._foundation = new MenuFoundation(new MenuAdapter(_this));
        return _this;
    }
    Object.defineProperty(MenuComponent, "observedAttributes", {
        get: function () {
            return [
                MENU_CONSTANTS.attributes.OPEN,
                MENU_CONSTANTS.attributes.PLACEMENT,
                MENU_CONSTANTS.attributes.SELECTED_INDEX,
                MENU_CONSTANTS.attributes.DENSE,
                MENU_CONSTANTS.attributes.ICON_CLASS
            ];
        },
        enumerable: true,
        configurable: true
    });
    MenuComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'open');
        upgradeProperty(this, 'options');
        upgradeProperty(this, 'placement');
        upgradeProperty(this, 'selectedIndex');
        upgradeProperty(this, 'dense');
        upgradeProperty(this, 'iconClass');
    };
    MenuComponent.prototype.connectedCallback = function () {
        var _this = this;
        if (this.children.length) {
            this._foundation.initialize();
        }
        else {
            ensureChildren(this).then(function () { return _this._foundation.initialize(); });
        }
    };
    MenuComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case MENU_CONSTANTS.attributes.OPEN:
                this._foundation.open = isDefined(newValue);
                break;
            case MENU_CONSTANTS.attributes.PLACEMENT:
                this._foundation.placement = newValue;
                break;
            case MENU_CONSTANTS.attributes.SELECTED_INDEX:
                this._foundation.selectedIndex = Number(newValue);
                break;
            case MENU_CONSTANTS.attributes.DENSE:
                this._foundation.dense = this.hasAttribute(MENU_CONSTANTS.attributes.DENSE);
                break;
            case MENU_CONSTANTS.attributes.ICON_CLASS:
                this._foundation.iconClass = newValue;
                break;
        }
    };
    MenuComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    Object.defineProperty(MenuComponent.prototype, "open", {
        /**
         * Gets/sets the open state.
         */
        get: function () {
            return this._foundation.open;
        },
        set: function (value) {
            this._foundation.open = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuComponent.prototype, "options", {
        get: function () {
            return this._foundation.options;
        },
        /**
         * Gets/sets the array of options to display in the menu.
         */
        set: function (options) {
            if (typeof options === 'function') {
                this._foundation.optionsFactory = options;
            }
            else {
                this._foundation.optionsFactory = undefined;
                this._foundation.options = options;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuComponent.prototype, "selectedIndex", {
        get: function () {
            return this._foundation.selectedIndex;
        },
        /**
         * Gets/sets the selected option to the index.
         */
        set: function (value) {
            this._foundation.selectedIndex = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuComponent.prototype, "placement", {
        get: function () {
            return this._foundation.placement;
        },
        /**
         * Gets/sets the menu placement (default is bottom-left).
         */
        set: function (value) {
            this._foundation.placement = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuComponent.prototype, "dense", {
        get: function () {
            return this._foundation.dense;
        },
        /**
         * Gets/sets dense state of the list options used in the menu popup.
         */
        set: function (value) {
            this._foundation.dense = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuComponent.prototype, "iconClass", {
        get: function () {
            return this._foundation.iconClass;
        },
        /** Gets/sets the class name to use for option icons. */
        set: function (value) {
            this._foundation.iconClass = value;
        },
        enumerable: true,
        configurable: true
    });
    MenuComponent = __decorate([
        CustomElement({
            name: MENU_CONSTANTS.elementName,
            dependencies: [
                PopupComponent,
                ListComponent
            ]
        })
    ], MenuComponent);
    return MenuComponent;
}(HTMLElement));
export { MenuComponent };
