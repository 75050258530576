import { removeElement, emitEvent, removeAllChildren, addClass, getShadowElement, ScrollAxisObserver, ScrollEvents } from '@tyler-components-web/core';
import { getActiveElement, deepQuerySelectorAll } from 'shadow-utils';
import { AUTOCOMPLETE_CONSTANTS } from './autocomplete-constants';
import { POPUP_CONSTANTS, PopupPlacement, PopupAnimationType } from '../popup';
import { LIST_CONSTANTS, LIST_ITEM_CONSTANTS } from '../list';
import { PROGRESS_SPINNER_CONSTANTS } from '../progress-spinner';
import { isOptionType, OptionType } from './autocomplete-utils';
import { DIVIDER_CONSTANTS } from '../divider';
import { ICON_CLASS_NAME } from '../constants';
import { randomChars } from '../core/utils';
/**
 * The DOM adapter for the `<tcw-autocomplete>` element.
 */
var AutocompleteAdapter = /** @class */ (function () {
    function AutocompleteAdapter(_component) {
        this._component = _component;
        this.setInputElement();
        this._identifier = randomChars();
    }
    AutocompleteAdapter.prototype.setInputElement = function () {
        var inputElements = deepQuerySelectorAll(this._component, AUTOCOMPLETE_CONSTANTS.selectors.INPUT, false);
        if (inputElements.length) {
            this._inputElement = inputElements[0];
        }
        return this._inputElement;
    };
    Object.defineProperty(AutocompleteAdapter.prototype, "popupElement", {
        get: function () {
            return this._popupElement;
        },
        enumerable: true,
        configurable: true
    });
    AutocompleteAdapter.prototype.setHostAttribute = function (name, value) {
        this._component.setAttribute(name, value);
    };
    AutocompleteAdapter.prototype.setInputAttribute = function (name, value) {
        this._inputElement.setAttribute(name, value);
    };
    AutocompleteAdapter.prototype.removeInputAttribute = function (name) {
        this._inputElement.removeAttribute(name);
    };
    AutocompleteAdapter.prototype.addInputListener = function (type, listener) {
        this._inputElement.addEventListener(type, listener);
    };
    AutocompleteAdapter.prototype.removeInputListener = function (type, listener) {
        this._inputElement.removeEventListener(type, listener);
    };
    AutocompleteAdapter.prototype.intializeInputAccessibility = function () {
        this._inputElement.setAttribute('aria-live', 'polite');
        this._inputElement.setAttribute('aria-haspopup', 'true');
        this._inputElement.setAttribute('aria-expanded', 'false');
        this._inputElement.setAttribute('aria-activedescendant', 'null');
        this._inputElement.setAttribute('aria-owns', "tcw-select-" + this._identifier);
    };
    AutocompleteAdapter.prototype.show = function (options, selectedOptions, filterText, optionBuilder, listener, isMultiple, highlightFirst, popupTarget, popupClasses, scrollEndListener) {
        var _this = this;
        var targetElement = popupTarget ? this._component.querySelector(popupTarget) || this._component : this._component;
        this._popupElement = document.createElement(POPUP_CONSTANTS.elementName);
        this._popupElement.targetElement = targetElement;
        this._popupElement.placement = PopupPlacement.BottomLeft;
        this._popupElement.animationType = PopupAnimationType.Dropdown;
        this._popupElement.id = "tcw-select-" + this._identifier;
        this._popupElement.style.top = '-9999px';
        this._popupElement.style.left = '-9999px';
        this._listElement = document.createElement(LIST_CONSTANTS.elementName);
        this._listElement.propagateClick = false;
        this._listElement.setAttribute('role', 'listbox');
        if (options.length) {
            this._createListItems(this._listElement, options, filterText, optionBuilder, isMultiple, selectedOptions);
        }
        else {
            this._setSpinner(this._listElement);
        }
        this._listElement.addEventListener(LIST_ITEM_CONSTANTS.events.SELECTED, function (evt) {
            var data = evt.detail;
            data.listItem.setAttribute('aria-selected', 'true');
            // If in multiselect mode we need to toggle the selected checkbox
            if (isMultiple) {
                _this.toggleOption(data.listItem);
            }
            _this._inputElement.setAttribute('aria-activedescendant', data.listItem.id);
            listener(data.value);
            // Keep the popup width the same as the input if the input size has now changed
            if (_this._popupElement) {
                _this._popupElement.style.minWidth = _this._getTargetElementWidth(targetElement) + "px";
            }
        });
        this._popupElement.appendChild(this._listElement);
        this._popupElement.style.minWidth = this._getTargetElementWidth(targetElement) + "px";
        this._popupElement.open = true;
        if (popupClasses && popupClasses.length) {
            addClass(popupClasses, this._popupElement);
        }
        this._inputElement.setAttribute('aria-expanded', 'true');
    };
    AutocompleteAdapter.prototype.showSpinner = function () {
        if (this._listElement) {
            this._setSpinner(this._listElement);
        }
    };
    AutocompleteAdapter.prototype.toggleOption = function (listItem) {
        if (this._listElement) {
            // First we need to remove the active state from any other list items
            var listItems = Array.from(this._listElement.querySelectorAll(LIST_ITEM_CONSTANTS.elementName));
            if (listItems.length) {
                var activeItems = listItems.filter(function (li) { return li !== listItem && li.active; });
                if (activeItems.length) {
                    activeItems.forEach(function (ai) { return ai.active = false; });
                }
            }
        }
        listItem.selected = !listItem.selected;
        listItem.active = true;
        listItem.setAttribute('aria-selected', '' + listItem.selected);
        this._inputElement.setAttribute('aria-activedescendant', listItem.id);
        var checkboxElement = listItem.querySelector('i[slot=leading]');
        if (checkboxElement) {
            checkboxElement.textContent = listItem.selected ? 'check_box' : 'check_box_outline_blank';
        }
    };
    AutocompleteAdapter.prototype.hide = function (hard) {
        if (!this._popupElement) {
            return;
        }
        this._inputElement.setAttribute('aria-expanded', 'false');
        if (hard) {
            removeElement(this._popupElement);
        }
        else {
            this._popupElement.open = false;
        }
        this._popupElement = undefined;
        this._listElement = undefined;
        this._scrollObserver = undefined;
        return;
    };
    AutocompleteAdapter.prototype.setScrollBottomListener = function (listener, scrollThreshold) {
        if (this._popupElement && this._popupElement.isConnected) {
            var scrollTarget = getShadowElement(this._popupElement, POPUP_CONSTANTS.selectors.CONTAINER);
            var scrollConfig = { scrollThreshold: scrollThreshold };
            this._scrollObserver = new ScrollAxisObserver(scrollTarget, scrollConfig);
            this._scrollObserver.addListener(ScrollEvents.ScrolledEnd, listener);
        }
    };
    AutocompleteAdapter.prototype.setDismissListener = function (listener) {
        var _this = this;
        if (!this._popupElement) {
            return;
        }
        if (this._popupKeydownListener) {
            this._popupElement.removeEventListener('keydown', this._popupKeydownListener);
        }
        this._popupKeydownListener = function (evt) { return _this._onPopupKeydown(evt, listener); };
        this._popupElement.addEventListener('keydown', this._popupKeydownListener);
        this._popupElement.targetElement.addEventListener(POPUP_CONSTANTS.events.BLUR, listener);
        return;
    };
    AutocompleteAdapter.prototype._onPopupKeydown = function (evt, listener) {
        var isEscapeKey = evt.key === 'Escape' && evt.keyCode === 27;
        var isTabKey = evt.key === 'Tab' && evt.keyCode === 9;
        if (isEscapeKey || isTabKey) {
            listener();
        }
    };
    AutocompleteAdapter.prototype.emitEvent = function (type, data) {
        emitEvent(this._component, type, data, true);
    };
    AutocompleteAdapter.prototype.focus = function () {
        var _this = this;
        window.requestAnimationFrame(function () { return _this._inputElement.focus(); });
    };
    AutocompleteAdapter.prototype.setOptions = function (options, selectedOptions, filterText, optionBuilder, isMultiple, replace) {
        if (replace === void 0) { replace = true; }
        if (!this._popupElement || !this._listElement) {
            return;
        }
        if (replace) {
            removeAllChildren(this._listElement);
        }
        this._createListItems(this._listElement, options, filterText, optionBuilder, isMultiple, selectedOptions);
    };
    AutocompleteAdapter.prototype.setSelectedText = function (value) {
        this._inputElement.value = value;
    };
    AutocompleteAdapter.prototype.getOptions = function () {
        if (!this._popupElement) {
            return [];
        }
        return Array.from(this._popupElement.querySelectorAll(LIST_ITEM_CONSTANTS.elementName));
    };
    AutocompleteAdapter.prototype.ensureActiveItemVisible = function (element) {
        if (!this._popupElement) {
            return;
        }
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    };
    AutocompleteAdapter.prototype.getInputValue = function () {
        return this._inputElement.value;
    };
    AutocompleteAdapter.prototype.focusWithinPopup = function (target) {
        if (!this._popupElement) {
            return false;
        }
        return this._popupElement.contains(target);
    };
    AutocompleteAdapter.prototype.selectInputValue = function () {
        var _this = this;
        window.requestAnimationFrame(function () { return _this._inputElement.select(); });
    };
    AutocompleteAdapter.prototype.hasFocus = function () {
        var activeElement = getActiveElement();
        return activeElement === this._inputElement || this.focusWithinPopup(activeElement);
    };
    AutocompleteAdapter.prototype.hasInputElement = function () {
        return !!this._inputElement;
    };
    AutocompleteAdapter.prototype._setSpinner = function (listElement) {
        removeAllChildren(listElement);
        var div = document.createElement('div');
        div.style.display = 'flex';
        div.style.justifyContent = 'center';
        div.style.alignItems = 'center';
        var spinner = document.createElement(PROGRESS_SPINNER_CONSTANTS.elementName);
        spinner.diameter = 24;
        spinner.strokeWidth = 8;
        spinner.style.height = '24px';
        spinner.style.width = '24px';
        div.appendChild(spinner);
        listElement.appendChild(div);
    };
    AutocompleteAdapter.prototype._createListItems = function (listElement, options, filterText, optionBuilder, isMultiple, selectedOptions) {
        // Ensure the options are provided in the form a group
        var groups = isOptionType(options, OptionType.Group) ? options : [{ text: '', options: options }];
        var limitOptions = this._component.optionLimit ? true : false;
        var optionLimit = this._component.optionLimit;
        var optionIndex = 0;
        for (var _i = 0, groups_1 = groups; _i < groups_1.length; _i++) {
            var group = groups_1[_i];
            if (limitOptions && optionLimit < 1) {
                break;
            }
            // We don't render headers that don't have text or a builder function
            if (group.builder && typeof group.builder === 'function') {
                var headerElement = group.builder(group);
                if (headerElement) {
                    listElement.appendChild(headerElement);
                }
            }
            else if (group.text) {
                var listHeaderElement = document.createElement('h4');
                listHeaderElement.textContent = group.text;
                listHeaderElement.style.margin = '0 8px';
                listElement.appendChild(listHeaderElement);
                listElement.appendChild(document.createElement(DIVIDER_CONSTANTS.elementName));
            }
            var _loop_1 = function (option) {
                var listItemElement = document.createElement(LIST_ITEM_CONSTANTS.elementName);
                listItemElement.style.cursor = 'pointer';
                listItemElement.setAttribute('role', 'option');
                listItemElement.id = "tcw-autocomplete-option-" + this_1._identifier + "-" + optionIndex++;
                listItemElement.value = option.value;
                listItemElement.setAttribute('aria-selected', 'false');
                if (optionBuilder && typeof optionBuilder === 'function') {
                    var element = optionBuilder(option, filterText);
                    if (element && typeof element === 'object') {
                        listItemElement.appendChild(element);
                    }
                }
                else {
                    if (!filterText) {
                        listItemElement.textContent = option.label;
                    }
                    else {
                        // Highlight the filter text within the label
                        var text = option.label.toLowerCase();
                        var startIndex = text.indexOf(filterText.toLowerCase());
                        if (startIndex !== -1) {
                            var endIndex = startIndex + filterText.length;
                            var span = document.createElement('span');
                            span.innerHTML = option.label.substring(0, startIndex) + "<span style=\"font-weight: bold;\">" + option.label.substring(startIndex, endIndex) + "</span>" + option.label.substring(endIndex);
                            listItemElement.appendChild(span);
                        }
                        else {
                            listItemElement.textContent = option.label;
                        }
                    }
                }
                var isSelected = selectedOptions.find(function (o) { return o.value === option.value; });
                if (isSelected) {
                    listItemElement.selected = true;
                    listItemElement.setAttribute('aria-selected', 'true');
                }
                if (isMultiple) {
                    var checkboxElement = document.createElement('i');
                    checkboxElement.setAttribute('aria-hidden', 'true');
                    checkboxElement.classList.add(ICON_CLASS_NAME);
                    checkboxElement.slot = 'leading';
                    checkboxElement.style.marginRight = '16px';
                    if (isSelected) {
                        checkboxElement.textContent = 'check_box';
                    }
                    else {
                        checkboxElement.textContent = 'check_box_outline_blank';
                    }
                    listItemElement.appendChild(checkboxElement);
                }
                listElement.appendChild(listItemElement);
                if (limitOptions) {
                    optionLimit--;
                    if (optionLimit < 1) {
                        return "break";
                    }
                }
            };
            var this_1 = this;
            for (var _a = 0, _b = group.options; _a < _b.length; _a++) {
                var option = _b[_a];
                var state_1 = _loop_1(option);
                if (state_1 === "break")
                    break;
            }
        }
    };
    AutocompleteAdapter.prototype.setDropdownListener = function (listener) {
        var _this = this;
        window.requestAnimationFrame(function () {
            var dropdownElement = _this._component.querySelector(AUTOCOMPLETE_CONSTANTS.selectors.DROPDOWN_ICON);
            if (dropdownElement) {
                dropdownElement.addEventListener('click', listener);
            }
        });
    };
    AutocompleteAdapter.prototype._getTargetElementWidth = function (element) {
        return element.getBoundingClientRect().width;
    };
    return AutocompleteAdapter;
}());
export { AutocompleteAdapter };
