import { OMNIBAR_CONSTANTS } from './omnibar-constants';
/**
 * Provides facilities and helper methods for creating an omnibar component.
 */
var OmnibarFoundation = /** @class */ (function () {
    function OmnibarFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._prominent = false;
        this._fixed = false;
        this._raised = true;
        this._logo = true;
        this._bottomSlotListener = function (evt) { return _this._onBottomSlotChanged(evt); };
        this._centerSlotListener = function (evt) { return _this._onCenterSlotChanged(evt); };
    }
    OmnibarFoundation.prototype.initialize = function () {
        this._adapter.setTitleText(this._titleText);
        this._adapter.addBottomSlotListener(this._bottomSlotListener);
        this._adapter.addCenterSlotListener(this._centerSlotListener);
        this._adapter.setCenterSlotVisibility();
        this._adapter.setRaised(this._raised);
        this._adapter.setProminent(this._prominent);
        this._adapter.setFixed(this._fixed);
        this._adapter.setLogoVisibility(this._logo);
    };
    OmnibarFoundation.prototype._onBottomSlotChanged = function (evt) {
        var slotElement = evt.target;
        if (slotElement.assignedNodes().length) {
            this._adapter.addBottomClass(OMNIBAR_CONSTANTS.classes.ROW);
        }
        else {
            this._adapter.removeBottomClass(OMNIBAR_CONSTANTS.classes.ROW);
        }
    };
    OmnibarFoundation.prototype._onCenterSlotChanged = function (evt) {
        this._adapter.setCenterSlotVisibility();
    };
    Object.defineProperty(OmnibarFoundation.prototype, "titleText", {
        /** Gets/sets the title text. */
        get: function () {
            return this._titleText;
        },
        set: function (value) {
            if (this._titleText !== value) {
                this._titleText = value;
                this._adapter.setTitleText(this._titleText);
                this._adapter.setHostAttribute(OMNIBAR_CONSTANTS.attributes.TITLE_TEXT, value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarFoundation.prototype, "prominent", {
        /** Gets/sets the omnibar type. */
        get: function () {
            return this._prominent;
        },
        set: function (value) {
            if (this._prominent !== value) {
                this._prominent = value;
                this._adapter.setProminent(this._prominent);
                this._adapter.setHostAttribute(OMNIBAR_CONSTANTS.attributes.PROMINENT, this._prominent.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarFoundation.prototype, "fixed", {
        /** Gets/sets the omnibar fixed state. */
        get: function () {
            return this._fixed;
        },
        set: function (value) {
            if (this._fixed !== value) {
                this._fixed = value;
                this._adapter.setFixed(this._fixed);
                this._adapter.setHostAttribute(OMNIBAR_CONSTANTS.attributes.FIXED, this._fixed.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarFoundation.prototype, "scrollTarget", {
        /** Gets/sets the scroll target selector. */
        get: function () {
            return this._scrollTarget;
        },
        set: function (selector) {
            this._scrollTarget = selector;
            this._adapter.setScrollTarget(selector);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarFoundation.prototype, "raised", {
        get: function () {
            return this._raised;
        },
        set: function (value) {
            if (this._raised !== value) {
                this._raised = value;
                this._adapter.setRaised(value);
                this._adapter.setHostAttribute(OMNIBAR_CONSTANTS.attributes.RAISED, this._raised.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarFoundation.prototype, "logo", {
        get: function () {
            return this._logo;
        },
        set: function (value) {
            if (this._logo !== value) {
                this._logo = value;
                this._adapter.setLogoVisibility(this._logo);
                this._adapter.setHostAttribute(OMNIBAR_CONSTANTS.attributes.LOGO, this._logo.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    return OmnibarFoundation;
}());
export { OmnibarFoundation };
