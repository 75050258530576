import { COMPONENT_NAME_PREFIX } from '../../constants';
var elementName = COMPONENT_NAME_PREFIX + "option";
var attributes = {
    VALUE: 'value',
    DISABLED: 'disabled'
};
export var OPTION_CONSTANTS = {
    elementName: elementName,
    attributes: attributes
};
