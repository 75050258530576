import { __decorate, __extends } from "tslib";
import { CustomElement, attachLightTemplate } from '@tyler-components-web/core';
import { OMNIBAR_MENU_BUTTON_CONSTANTS } from './omnibar-menu-button-constants';
import { IconButtonComponent } from '../../icon-button';
import { TooltipComponent } from '../../tooltip';
var template = require('./omnibar-menu-button.html');
var OmnibarMenuButtonComponent = /** @class */ (function (_super) {
    __extends(OmnibarMenuButtonComponent, _super);
    function OmnibarMenuButtonComponent() {
        return _super.call(this) || this;
    }
    OmnibarMenuButtonComponent.prototype.initializedCallback = function () {
        attachLightTemplate(this, template);
    };
    OmnibarMenuButtonComponent = __decorate([
        CustomElement({
            name: OMNIBAR_MENU_BUTTON_CONSTANTS.elementName,
            dependencies: [
                IconButtonComponent,
                TooltipComponent
            ]
        })
    ], OmnibarMenuButtonComponent);
    return OmnibarMenuButtonComponent;
}(HTMLElement));
export { OmnibarMenuButtonComponent };
