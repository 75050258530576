import { isDefined } from '@tyler-components-web/core';
import { CHIP_CONSTANTS } from './chip-constants';
/**
 * The foundation class behind the `<tcw-chip>` component.
 */
var ChipFoundation = /** @class */ (function () {
    function ChipFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._delete = false;
        this._selected = false;
        this._dense = false;
        this._keydownListener = function (evt) {
            if (evt.key === 'Enter') {
                _this._onSelected(evt);
            }
        };
        this._clickListener = function (evt) { return _this._onSelected(evt); };
        this._deleteKeydownListener = function (evt) {
            if (evt.key === 'Enter') {
                _this._onDelete(evt);
            }
        };
        this._deleteClickListener = function (evt) { return _this._onDelete(evt); };
    }
    ChipFoundation.prototype.initialize = function () {
        this._adapter.setChipListener('keydown', this._keydownListener);
        this._adapter.setChipListener('click', this._clickListener);
    };
    ChipFoundation.prototype.disconnect = function () {
        if (this._ripple) {
            this._ripple.destroy();
        }
    };
    ChipFoundation.prototype._onSelected = function (evt) {
        if (this._type === 'choice' || this._type === 'filter') {
            this.selected = !this.selected;
        }
        this._adapter.emitEvent(CHIP_CONSTANTS.events.SELECTED, { text: this._text, value: this._value, selected: this._selected });
    };
    ChipFoundation.prototype._onDelete = function (evt) {
        evt.stopPropagation();
        this._adapter.emitEvent(CHIP_CONSTANTS.events.DELETE, { text: this._text, value: this._value });
    };
    Object.defineProperty(ChipFoundation.prototype, "type", {
        /** Gets/sets the chip type. */
        get: function () {
            return this._type;
        },
        set: function (value) {
            if (this._type !== value) {
                this._type = value;
                this._adapter.toggleCheckmark(this._type === 'filter');
                this._adapter.setFiltered(this._selected);
                if (!this._ripple) {
                    this._ripple = this._adapter.createRipple();
                }
                this._adapter.setHostAttribute(CHIP_CONSTANTS.attributes.TYPE, this._type);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipFoundation.prototype, "icon", {
        /** Gets/sets the leading icon. */
        get: function () {
            return this._icon;
        },
        set: function (value) {
            if (this._icon !== value) {
                this._icon = value;
                this._adapter.setLeadingIcon(this._icon);
                this._adapter.setHostAttribute(CHIP_CONSTANTS.attributes.ICON, this._icon);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipFoundation.prototype, "text", {
        /** Gets/sets the chip text. */
        get: function () {
            return this._text;
        },
        set: function (value) {
            if (this._text !== value) {
                this._text = value;
                this._adapter.setText(this._text);
                this._adapter.setHostAttribute(CHIP_CONSTANTS.attributes.TEXT, this._text);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipFoundation.prototype, "delete", {
        /** Controls the visibility of the "delete" button icon at the end of the chip. */
        get: function () {
            return this._delete;
        },
        set: function (value) {
            if (this._delete !== value) {
                this._delete = value;
                this._adapter.removeDeleteListener('click', this._deleteClickListener);
                this._adapter.removeDeleteListener('keydown', this._deleteKeydownListener);
                this._adapter.setDeleteVisibility(this._delete);
                if (this._delete) {
                    this._adapter.setDeleteListener('click', this._deleteClickListener);
                    this._adapter.setDeleteListener('keydown', this._deleteKeydownListener);
                }
                this._adapter.setHostAttribute(CHIP_CONSTANTS.attributes.DELETE, isDefined(this._delete) ? this._delete.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipFoundation.prototype, "selected", {
        /** Gets/sets the selected state of the chip. */
        get: function () {
            return this._selected;
        },
        set: function (value) {
            if (this._selected !== value) {
                this._selected = value;
                this._adapter.setSelected(this._selected);
                if (this._type === 'filter') {
                    this._adapter.setFiltered(this._selected);
                }
                this._adapter.setHostAttribute(CHIP_CONSTANTS.attributes.SELECTED, isDefined(this._selected) ? this._selected.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipFoundation.prototype, "value", {
        /** Gets/sets the chip value. */
        get: function () {
            return this._value;
        },
        set: function (value) {
            if (this._value !== value) {
                this._value = value;
                this._adapter.setHostAttribute(CHIP_CONSTANTS.attributes.VALUE, this._value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipFoundation.prototype, "dense", {
        /** Gets/sets the chip as dense. */
        get: function () {
            return this._dense;
        },
        set: function (value) {
            if (this._dense !== value) {
                this._dense = value;
                this._adapter.setDense(this.dense);
                if (this._dense) {
                    this._adapter.setHostAttribute(CHIP_CONSTANTS.attributes.DENSE, '');
                }
                else {
                    this._adapter.removeHostAttribute(CHIP_CONSTANTS.attributes.DENSE);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    return ChipFoundation;
}());
export { ChipFoundation };
