import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty, coerceBoolean } from '@tyler-components-web/core';
import { ButtonToggleGroupAdapter } from './button-toggle-group-adapter';
import { ButtonToggleGroupFoundation } from './button-toggle-group-foundation';
import { BUTTON_TOGGLE_GROUP_CONSTANTS } from './button-toggle-group-constants';
import { ButtonToggleComponent } from '../button-toggle/button-toggle';
var template = require('./button-toggle-group.html');
var styles = require('./button-toggle-group.css');
var ButtonToggleGroupComponent = /** @class */ (function (_super) {
    __extends(ButtonToggleGroupComponent, _super);
    function ButtonToggleGroupComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, BUTTON_TOGGLE_GROUP_CONSTANTS.elementName, template, styles);
        _this._foundation = new ButtonToggleGroupFoundation(new ButtonToggleGroupAdapter(_this));
        return _this;
    }
    Object.defineProperty(ButtonToggleGroupComponent, "observedAttributes", {
        get: function () {
            return [
                BUTTON_TOGGLE_GROUP_CONSTANTS.attributes.VALUE,
                BUTTON_TOGGLE_GROUP_CONSTANTS.attributes.MULTIPLE,
                BUTTON_TOGGLE_GROUP_CONSTANTS.attributes.MANDATORY,
                BUTTON_TOGGLE_GROUP_CONSTANTS.attributes.VERTICAL,
                BUTTON_TOGGLE_GROUP_CONSTANTS.attributes.STRETCH,
                BUTTON_TOGGLE_GROUP_CONSTANTS.attributes.DISABLED,
                BUTTON_TOGGLE_GROUP_CONSTANTS.attributes.DENSE
            ];
        },
        enumerable: true,
        configurable: true
    });
    ButtonToggleGroupComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'value');
        upgradeProperty(this, 'multiple');
        upgradeProperty(this, 'mandatory');
        upgradeProperty(this, 'vertical');
        upgradeProperty(this, 'stretch');
        upgradeProperty(this, 'dense');
        upgradeProperty(this, 'disabled');
        upgradeProperty(this, 'options');
        this._foundation.initialize();
    };
    ButtonToggleGroupComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    ButtonToggleGroupComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case BUTTON_TOGGLE_GROUP_CONSTANTS.attributes.VALUE:
                this.value = newValue;
                break;
            case BUTTON_TOGGLE_GROUP_CONSTANTS.attributes.MULTIPLE:
                this.multiple = coerceBoolean(newValue);
                break;
            case BUTTON_TOGGLE_GROUP_CONSTANTS.attributes.MANDATORY:
                this.mandatory = this.hasAttribute(BUTTON_TOGGLE_GROUP_CONSTANTS.attributes.MANDATORY);
                break;
            case BUTTON_TOGGLE_GROUP_CONSTANTS.attributes.VERTICAL:
                this.vertical = this.hasAttribute(BUTTON_TOGGLE_GROUP_CONSTANTS.attributes.VERTICAL);
                break;
            case BUTTON_TOGGLE_GROUP_CONSTANTS.attributes.STRETCH:
                this.stretch = this.hasAttribute(BUTTON_TOGGLE_GROUP_CONSTANTS.attributes.STRETCH);
                break;
            case BUTTON_TOGGLE_GROUP_CONSTANTS.attributes.DENSE:
                this.dense = this.hasAttribute(BUTTON_TOGGLE_GROUP_CONSTANTS.attributes.DENSE);
                break;
            case BUTTON_TOGGLE_GROUP_CONSTANTS.attributes.DISABLED:
                this.disabled = this.hasAttribute(BUTTON_TOGGLE_GROUP_CONSTANTS.attributes.DISABLED);
                break;
        }
    };
    Object.defineProperty(ButtonToggleGroupComponent.prototype, "value", {
        get: function () {
            return this._foundation.value;
        },
        set: function (value) {
            this._foundation.value = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleGroupComponent.prototype, "multiple", {
        get: function () {
            return this._foundation.multiple;
        },
        set: function (value) {
            this._foundation.multiple = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleGroupComponent.prototype, "mandatory", {
        get: function () {
            return this._foundation.mandatory;
        },
        set: function (value) {
            this._foundation.mandatory = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleGroupComponent.prototype, "vertical", {
        get: function () {
            return this._foundation.vertical;
        },
        set: function (value) {
            this._foundation.vertical = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleGroupComponent.prototype, "stretch", {
        get: function () {
            return this._foundation.stretch;
        },
        set: function (value) {
            this._foundation.stretch = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleGroupComponent.prototype, "dense", {
        get: function () {
            return this._foundation.dense;
        },
        set: function (value) {
            this._foundation.dense = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleGroupComponent.prototype, "disabled", {
        get: function () {
            return this._foundation.disabled;
        },
        set: function (value) {
            this._foundation.disabled = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleGroupComponent.prototype, "options", {
        get: function () {
            return this._foundation.options;
        },
        set: function (value) {
            this._foundation.options = value;
        },
        enumerable: true,
        configurable: true
    });
    ButtonToggleGroupComponent = __decorate([
        CustomElement({
            name: BUTTON_TOGGLE_GROUP_CONSTANTS.elementName,
            dependencies: [ButtonToggleComponent]
        })
    ], ButtonToggleGroupComponent);
    return ButtonToggleGroupComponent;
}(HTMLElement));
export { ButtonToggleGroupComponent };
