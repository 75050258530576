import { getShadowElement, removeClass, addClass, emitEvent } from '@tyler-components-web/core';
import { DRAWER_CONSTANTS } from './drawer-constants';
import { BACKDROP_CONSTANTS } from '../backdrop';
var DrawerAdapter = /** @class */ (function () {
    function DrawerAdapter(_component) {
        this._component = _component;
        this._drawerElement = getShadowElement(this._component, DRAWER_CONSTANTS.selectors.DRAWER);
        this._shadowRoot = this._component.shadowRoot;
    }
    DrawerAdapter.prototype.setHostAttribute = function (name, value) {
        this._component.setAttribute(name, value);
    };
    DrawerAdapter.prototype.emitEvent = function (type, data) {
        if (data === void 0) { data = null; }
        emitEvent(this._component, type, data);
    };
    DrawerAdapter.prototype.setOpen = function (type, isOpen, animate) {
        var _this = this;
        if (animate === void 0) { animate = true; }
        if (isOpen) {
            // When opening the drawer, we remove the closed class, add the opening class, and wait for the
            // transition to complete before removing the opening class and returning the host element width
            // to its "auto" default state.
            var transitionEndHandler_1 = function (evt) {
                if (timer_1) {
                    window.clearTimeout(timer_1);
                }
                if (!evt || evt.propertyName === 'width') {
                    _this._drawerElement.classList.remove(DRAWER_CONSTANTS.classes.OPENING);
                    _this._drawerElement.removeEventListener('transitionend', transitionEndHandler_1);
                }
            };
            this._drawerElement.addEventListener('transitionend', transitionEndHandler_1);
            var timer_1 = window.setTimeout(transitionEndHandler_1, DRAWER_CONSTANTS.numbers.OPEN_TRANSITION_DURATION); // Fallback to set timeout
            this._drawerElement.classList.remove(DRAWER_CONSTANTS.classes.MODAL_CLOSED);
            this._component.style.removeProperty('display');
            requestAnimationFrame(function () {
                requestAnimationFrame(function () {
                    _this._drawerElement.classList.remove(DRAWER_CONSTANTS.classes.CLOSED);
                    _this._drawerElement.classList.add(DRAWER_CONSTANTS.classes.OPENING);
                });
            });
        }
        else {
            if (!animate) {
                this._drawerElement.classList.add(DRAWER_CONSTANTS.classes.CLOSED);
                if (type === 'modal') {
                    this._drawerElement.classList.add(DRAWER_CONSTANTS.classes.MODAL_CLOSED);
                    this._component.style.display = 'none';
                }
            }
            else {
                if (type === 'modal') {
                    var transitionEndHandler_2 = function (evt) {
                        if (timer_2) {
                            window.clearTimeout(timer_2);
                        }
                        if (!evt || evt.propertyName === 'transform') {
                            _this._component.style.display = 'none';
                            _this._drawerElement.classList.add(DRAWER_CONSTANTS.classes.MODAL_CLOSED);
                            _this._drawerElement.removeEventListener('transitionend', transitionEndHandler_2);
                        }
                    };
                    this._drawerElement.addEventListener('transitionend', transitionEndHandler_2);
                    var timer_2 = window.setTimeout(transitionEndHandler_2, DRAWER_CONSTANTS.numbers.OPEN_TRANSITION_DURATION); // Fallback to set timeout
                }
                requestAnimationFrame(function () {
                    requestAnimationFrame(function () {
                        _this._drawerElement.classList.add(DRAWER_CONSTANTS.classes.CLOSED);
                    });
                });
            }
        }
    };
    DrawerAdapter.prototype.setDirection = function (direction) {
        switch (direction) {
            case 'left':
                this._drawerElement.classList.remove(DRAWER_CONSTANTS.classes.RIGHT);
                this._drawerElement.classList.add(DRAWER_CONSTANTS.classes.LEFT);
                break;
            case 'right':
                this._drawerElement.classList.remove(DRAWER_CONSTANTS.classes.LEFT);
                this._drawerElement.classList.add(DRAWER_CONSTANTS.classes.RIGHT);
                break;
        }
    };
    DrawerAdapter.prototype.removeDrawerClass = function (className) {
        removeClass(className, this._drawerElement);
    };
    DrawerAdapter.prototype.setDrawerClass = function (className) {
        addClass(className, this._drawerElement);
    };
    DrawerAdapter.prototype.setBackdrop = function (isOpen, listener) {
        var _this = this;
        if (isOpen) {
            if (!this._backdropElement) {
                this._backdropElement = document.createElement(BACKDROP_CONSTANTS.elementName);
                if (listener) {
                    this._backdropElement.addEventListener(BACKDROP_CONSTANTS.events.BACKDROP_CLICK, listener);
                }
            }
            this._shadowRoot.insertBefore(this._backdropElement, this._drawerElement);
        }
        else if (this._backdropElement) {
            this._backdropElement.fadeOut().then(function () { return _this.removeBackdrop(); });
        }
    };
    DrawerAdapter.prototype.removeBackdrop = function () {
        if (this._backdropElement && this._backdropElement.isConnected) {
            this._shadowRoot.removeChild(this._backdropElement);
        }
    };
    return DrawerAdapter;
}());
export { DrawerAdapter };
