import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "backdrop";
var selectors = {
    CONTAINER: '.tyl-backdrop'
};
var numbers = {
    OPACITY: 0.54,
    DELAY: 0,
    TRANSITION_DURATION: 150
};
var attributes = {
    DELAY: 'delay',
    MAX_OPACITY: 'max-opacity'
};
var events = {
    BACKDROP_CLICK: elementName + "-click"
};
export var BACKDROP_CONSTANTS = {
    elementName: elementName,
    selectors: selectors,
    numbers: numbers,
    attributes: attributes,
    events: events
};
