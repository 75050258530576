import { getShadowElement, emitEvent, replaceElement } from '@tyler-components-web/core';
import { STEPPER_CONSTANTS } from './stepper-constants';
import { StepperUtils } from './stepper-utils';
/**
 * The DOM adapter for the stepper component.
 */
var StepperAdapter = /** @class */ (function () {
    function StepperAdapter(_component) {
        this._component = _component;
        this._stepperElement = getShadowElement(this._component, STEPPER_CONSTANTS.selectors.STEPPER);
    }
    StepperAdapter.prototype.setHostAttribute = function (name, value) {
        this._component.setAttribute(name, value);
    };
    StepperAdapter.prototype.emitEvent = function (type, data) {
        emitEvent(this._component, type, data);
    };
    StepperAdapter.prototype.registerStepListener = function (listener) {
        this._stepperElement.addEventListener('click', listener);
    };
    StepperAdapter.prototype.deregisterStepListener = function (listener) {
        this._stepperElement.removeEventListener('click', listener);
    };
    StepperAdapter.prototype.getStepIndex = function (element) {
        return this._getStepElements().indexOf(element);
    };
    StepperAdapter.prototype.setLinearState = function (isLinear) {
        StepperUtils.setLinearState(this._stepperElement, isLinear);
    };
    StepperAdapter.prototype.setAlternativeState = function (isAlternative) {
        StepperUtils.setAlternativeState(this._stepperElement, isAlternative);
    };
    StepperAdapter.prototype.setLayoutMode = function (mode) {
        StepperUtils.setLayoutMode(this._stepperElement, mode);
    };
    StepperAdapter.prototype.setLayoutAlign = function (mode) {
        StepperUtils.setLayoutAlign(this._stepperElement, mode);
    };
    StepperAdapter.prototype.createStepper = function (stepperConfiguration) {
        var stepperElement = StepperUtils.createStepper(stepperConfiguration);
        replaceElement(stepperElement, this._stepperElement);
        this._stepperElement = stepperElement;
    };
    StepperAdapter.prototype._getStepElements = function () {
        return Array.from(this._stepperElement.children).filter(function (child) { return child.classList.contains(STEPPER_CONSTANTS.classes.STEP); });
    };
    return StepperAdapter;
}());
export { StepperAdapter };
