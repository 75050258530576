import { isDefined } from '@tyler-components-web/core';
import { LIST_CONSTANTS } from './list-constants';
import { LIST_ITEM_CONSTANTS } from '../list-item';
var ELEMENTS_KEY_ALLOWED_IN = ['input', 'button', 'textarea', 'select'];
/**
 * The foundation class behind the `<tcw-list>` component.
 */
var ListFoundation = /** @class */ (function () {
    function ListFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._static = false;
        this._dense = false;
        this._propagateClick = true;
        this._indented = false;
        this._keydownListener = function (evt) { return _this._onKeydown(evt); };
    }
    ListFoundation.prototype.initialize = function () {
        if (!this._static) {
            this._adapter.addListener('keydown', this._keydownListener);
        }
        if (this._selectedValue) {
            this._adapter.setSelectedListItems(this._selectedValue);
        }
    };
    ListFoundation.prototype._onKeydown = function (evt) {
        var isArrowDown = evt.key === 'ArrowDown' || evt.keyCode === 40;
        var isArrowUp = evt.key === 'ArrowUp' || evt.keyCode === 38;
        var isHome = evt.key === 'Home' || evt.keyCode === 36;
        var isEnd = evt.key === 'End' || evt.keyCode === 35;
        var isEnter = evt.key === 'Enter' || evt.keyCode === 13;
        var isSpace = evt.key === 'Space' || evt.keyCode === 32;
        var isTab = evt.key === 'Tab' || evt.keyCode === 9;
        // We don't capture modifier keys
        if (evt.altKey || evt.ctrlKey || evt.shiftKey || evt.metaKey) {
            return;
        }
        if (!isEnter && !isSpace && !isTab) {
            this._preventDefaultEvent(evt);
        }
        if (isHome) {
            this._adapter.focusFirstListItem();
        }
        else if (isEnd) {
            this._adapter.focusLastListItem();
        }
        else if (isArrowDown) {
            this._adapter.focusNextListItem();
        }
        else if (isArrowUp) {
            this._adapter.focusPreviousListItem();
        }
        else if (isEnter || isSpace) {
            if (evt.target && evt.target.tagName.toLowerCase() === LIST_ITEM_CONSTANTS.elementName) {
                this._preventDefaultEvent(evt);
                var listItem = evt.target;
                var data = {
                    value: listItem.value,
                    listItem: listItem
                };
                this._adapter.emitEvent(LIST_ITEM_CONSTANTS.events.SELECTED, data);
            }
        }
    };
    /**
     * Ensures that preventDefault is only called if the containing element doesn't
     * consume the event, and it will cause an unintended scroll.
     * @param {Event} evt
     */
    ListFoundation.prototype._preventDefaultEvent = function (evt) {
        var tagName = ("" + evt.target.tagName).toLowerCase();
        if (ELEMENTS_KEY_ALLOWED_IN.indexOf(tagName) === -1) {
            evt.preventDefault();
        }
    };
    ListFoundation.prototype._setSelectedValue = function (value) {
        var values = value instanceof Array ? value : [value];
        values = values.filter(function (v) { return isDefined(v); });
        this._adapter.setSelectedListItems(values);
    };
    Object.defineProperty(ListFoundation.prototype, "static", {
        /** Gets/sets whether the list has all static items or not. */
        get: function () {
            return this._static;
        },
        set: function (value) {
            var _this = this;
            if (this._static !== value) {
                this._static = value;
                if (!this._static) {
                    this._adapter.addListener('keydown', this._keydownListener);
                }
                else {
                    this._adapter.removeListener('keydown', this._keydownListener);
                }
                this._adapter.updateListItems(function (li) { return li.static = _this._static; });
                if (this._static) {
                    this._adapter.setHostAttribute(LIST_CONSTANTS.attributes.STATIC);
                }
                else {
                    this._adapter.removeHostAttribute(LIST_CONSTANTS.attributes.STATIC);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListFoundation.prototype, "dense", {
        /** Gets/sets whether the list has all dense items or not. */
        get: function () {
            return this._dense;
        },
        set: function (value) {
            var _this = this;
            if (this._dense !== value) {
                this._dense = value;
                this._adapter.updateListItems(function (li) { return li.dense = _this._dense; });
                if (this._dense) {
                    this._adapter.setHostAttribute(LIST_CONSTANTS.attributes.DENSE);
                }
                else {
                    this._adapter.removeHostAttribute(LIST_CONSTANTS.attributes.DENSE);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListFoundation.prototype, "propagateClick", {
        get: function () {
            return this._propagateClick;
        },
        set: function (value) {
            var _this = this;
            if (this._propagateClick !== value) {
                this._propagateClick = value;
                this._adapter.updateListItems(function (li) { return li.propagateClick = _this._propagateClick; });
                this._adapter.setHostAttribute(LIST_CONSTANTS.attributes.PROPAGATE_CLICK, '' + !!this._propagateClick);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListFoundation.prototype, "indented", {
        get: function () {
            return this._indented;
        },
        set: function (value) {
            var _this = this;
            if (this._indented !== value) {
                this._indented = value;
                this._adapter.updateListItems(function (li) { return li.indented = _this._indented; });
                if (this._indented) {
                    this._adapter.setHostAttribute(LIST_CONSTANTS.attributes.INDENTED);
                }
                else {
                    this._adapter.removeHostAttribute(LIST_CONSTANTS.attributes.INDENTED);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListFoundation.prototype, "selectedValue", {
        get: function () {
            return this._selectedValue;
        },
        set: function (value) {
            this._selectedValue = value;
            this._setSelectedValue(value);
        },
        enumerable: true,
        configurable: true
    });
    return ListFoundation;
}());
export { ListFoundation };
