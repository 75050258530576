import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty } from '@tyler-components-web/core';
import { AppLauncherAdapter } from './app-launcher-adapter';
import { AppLauncherFoundation } from './app-launcher-foundation';
import { APP_LAUNCHER_CONSTANTS } from './app-launcher-constants';
import { ProductIconComponent } from '../../product-icon';
import { ViewSwitcherComponent } from '../../view-switcher';
import { ButtonComponent } from '../../button';
import { SkeletonComponent } from '../../skeleton';
import { PageStateComponent } from '../../page-state';
var template = require('./app-launcher.html');
var styles = require('./app-launcher.css');
var AppLauncherComponent = /** @class */ (function (_super) {
    __extends(AppLauncherComponent, _super);
    function AppLauncherComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, APP_LAUNCHER_CONSTANTS.elementName, template, styles);
        _this._foundation = new AppLauncherFoundation(new AppLauncherAdapter(_this));
        return _this;
    }
    AppLauncherComponent.prototype.connectedCallback = function () {
        upgradeProperty(this, 'optionsCallback');
        this._foundation.initialize();
    };
    AppLauncherComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    Object.defineProperty(AppLauncherComponent.prototype, "optionsCallback", {
        set: function (cb) {
            this._foundation.optionsCallback = cb;
        },
        enumerable: true,
        configurable: true
    });
    AppLauncherComponent.prototype.focusFirst = function () {
        this._foundation.focusFirst();
    };
    AppLauncherComponent = __decorate([
        CustomElement({
            name: APP_LAUNCHER_CONSTANTS.elementName,
            dependencies: [
                ProductIconComponent,
                ViewSwitcherComponent,
                ButtonComponent,
                SkeletonComponent,
                PageStateComponent
            ]
        })
    ], AppLauncherComponent);
    return AppLauncherComponent;
}(HTMLElement));
export { AppLauncherComponent };
