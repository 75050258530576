import { __decorate, __extends } from "tslib";
import { CustomElement, attachLightTemplate, coerceNumber, coerceBoolean, upgradeProperty } from '@tyler-components-web/core';
import { OmnibarProfileButtonAdapter } from './omnibar-profile-button-adapter';
import { OmnibarProfileButtonFoundation } from './omnibar-profile-button-foundation';
import { OMNIBAR_PROFILE_BUTTON_CONSTANTS } from './omnibar-profile-button-constants';
import { ProfileCardComponent } from '../profile-card';
import { IconButtonComponent } from '../../icon-button';
import { AvatarComponent } from '../../avatar';
import { PopupComponent } from '../../popup';
var template = require('./omnibar-profile-button.html');
var OmnibarProfileButtonComponent = /** @class */ (function (_super) {
    __extends(OmnibarProfileButtonComponent, _super);
    function OmnibarProfileButtonComponent() {
        var _this = _super.call(this) || this;
        _this._foundation = new OmnibarProfileButtonFoundation(new OmnibarProfileButtonAdapter(_this));
        return _this;
    }
    Object.defineProperty(OmnibarProfileButtonComponent, "observedAttributes", {
        get: function () {
            return [
                OMNIBAR_PROFILE_BUTTON_CONSTANTS.attributes.FULL_NAME,
                OMNIBAR_PROFILE_BUTTON_CONSTANTS.attributes.EMAIL,
                OMNIBAR_PROFILE_BUTTON_CONSTANTS.attributes.AVATAR_IMAGE_URL,
                OMNIBAR_PROFILE_BUTTON_CONSTANTS.attributes.AVATAR_LETTER_COUNT,
                OMNIBAR_PROFILE_BUTTON_CONSTANTS.attributes.AVATAR_TEXT,
                OMNIBAR_PROFILE_BUTTON_CONSTANTS.attributes.SIGN_OUT_BUTTON,
                OMNIBAR_PROFILE_BUTTON_CONSTANTS.attributes.PROFILE_BUTTON,
            ];
        },
        enumerable: true,
        configurable: true
    });
    OmnibarProfileButtonComponent.prototype.initializedCallback = function () {
        attachLightTemplate(this, template);
    };
    OmnibarProfileButtonComponent.prototype.connectedCallback = function () {
        upgradeProperty(this, 'avatarImageUrl');
        upgradeProperty(this, 'avatarLetterCount');
        upgradeProperty(this, 'avatarText');
        upgradeProperty(this, 'fullName');
        upgradeProperty(this, 'email');
        upgradeProperty(this, 'signOutButton');
        upgradeProperty(this, 'profileButton');
        this._foundation.initialize();
    };
    OmnibarProfileButtonComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    OmnibarProfileButtonComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case OMNIBAR_PROFILE_BUTTON_CONSTANTS.attributes.FULL_NAME:
                this.fullName = newValue;
                break;
            case OMNIBAR_PROFILE_BUTTON_CONSTANTS.attributes.EMAIL:
                this.email = newValue;
                break;
            case OMNIBAR_PROFILE_BUTTON_CONSTANTS.attributes.AVATAR_IMAGE_URL:
                this.avatarImageUrl = newValue;
                break;
            case OMNIBAR_PROFILE_BUTTON_CONSTANTS.attributes.AVATAR_LETTER_COUNT:
                this.avatarLetterCount = coerceNumber(newValue);
                break;
            case OMNIBAR_PROFILE_BUTTON_CONSTANTS.attributes.AVATAR_TEXT:
                this.avatarText = newValue;
                break;
            case OMNIBAR_PROFILE_BUTTON_CONSTANTS.attributes.SIGN_OUT_BUTTON:
                this.signOutButton = coerceBoolean(newValue);
                break;
            case OMNIBAR_PROFILE_BUTTON_CONSTANTS.attributes.PROFILE_BUTTON:
                this.profileButton = coerceBoolean(newValue);
                break;
        }
    };
    Object.defineProperty(OmnibarProfileButtonComponent.prototype, "fullName", {
        get: function () {
            return this._foundation.fullName;
        },
        set: function (value) {
            this._foundation.fullName = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarProfileButtonComponent.prototype, "email", {
        get: function () {
            return this._foundation.email;
        },
        set: function (value) {
            this._foundation.email = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarProfileButtonComponent.prototype, "avatarImageUrl", {
        get: function () {
            return this._foundation.avatarImageUrl;
        },
        set: function (value) {
            this._foundation.avatarImageUrl = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarProfileButtonComponent.prototype, "avatarLetterCount", {
        get: function () {
            return this._foundation.avatarLetterCount;
        },
        set: function (value) {
            this._foundation.avatarLetterCount = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarProfileButtonComponent.prototype, "avatarText", {
        get: function () {
            return this._foundation.avatarText;
        },
        set: function (value) {
            this._foundation.avatarText = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarProfileButtonComponent.prototype, "signOutButton", {
        get: function () {
            return this._foundation.signOutButton;
        },
        set: function (value) {
            this._foundation.signOutButton = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarProfileButtonComponent.prototype, "profileButton", {
        get: function () {
            return this._foundation.profileButton;
        },
        set: function (value) {
            this._foundation.profileButton = value;
        },
        enumerable: true,
        configurable: true
    });
    OmnibarProfileButtonComponent = __decorate([
        CustomElement({
            name: OMNIBAR_PROFILE_BUTTON_CONSTANTS.elementName,
            dependencies: [
                PopupComponent,
                ProfileCardComponent,
                IconButtonComponent,
                AvatarComponent
            ]
        })
    ], OmnibarProfileButtonComponent);
    return OmnibarProfileButtonComponent;
}(HTMLElement));
export { OmnibarProfileButtonComponent };
