import { __spreadArrays } from "tslib";
import { isDefined } from '@tyler-components-web/core';
import { SELECT_CONSTANTS } from './select-constants';
import { isOptionType, OptionType } from './select-utils';
/**
 * The foundation class behind the `<tcw-select>` component.
 */
var SelectFoundation = /** @class */ (function () {
    function SelectFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._label = '';
        this._value = [];
        this._options = [];
        this._multiple = false;
        this._disabled = false;
        this._invalid = false;
        this._required = false;
        this._floatLabelType = 'default';
        this._density = 'default';
        this._selectedValues = [];
        this._selectedLabels = [];
        this._selectedIndexes = [];
        this._isDropdownOpen = false;
        this._filterString = '';
        this._hasFocus = false;
        this._focusListener = function (evt) { return _this._onFocus(evt); };
        this._blurListener = function (evt) { return _this._onBlur(evt); };
        this._clickListener = function (evt) { return _this._onClick(evt); };
        this._keydownListener = function (evt) { return _this._onKeydown(evt); };
        this._leadingChangeListener = function (evt) { return _this._onLeadingChanged(evt); };
        this._optionsChangedListener = function (options) { return _this._onOptionsChanged(options); };
        this._dismissListener = function (keepFocus) { return _this._onDismiss(keepFocus); };
    }
    /**
     * Sets the adapter.
     * @param {ISelectAdapter} adapter The new adapter instance.
     */
    SelectFoundation.prototype.setAdapter = function (adapter) {
        this._adapter = adapter;
    };
    SelectFoundation.prototype.initialize = function () {
        this._initializeLabel();
        this._adapter.setPlaceholderText(this._placeholder);
        this._adapter.addRootInteractionListener('click', this._clickListener);
        this._adapter.addInteractionListener('blur', this._blurListener);
        this._adapter.addInteractionListener('focus', this._focusListener);
        this._adapter.addInteractionListener('keydown', this._keydownListener);
        this._adapter.setLeadingListener(this._leadingChangeListener);
        this._optionListenerDestructor = this._adapter.setOptionsListener(this._optionsChangedListener);
        this._detectLeadingElement();
        this._applyDensity();
        if (this._disabled) {
            this._adapter.setDisabled(true);
        }
        if (this._invalid) {
            this._adapter.setInvalid(true);
        }
        this._initializeValue();
        this._initializeAccessibility();
    };
    SelectFoundation.prototype._initializeLabel = function () {
        this._adapter.initializeLabel();
        this._updateLabel();
        this._notchedOutlineInstance = this._adapter.initializeNotchedOutline();
        this._floatingLabelInstance = this._adapter.initializeFloatingLabel();
        if (this._floatLabelType === 'always') {
            this._floatLabel(true);
        }
    };
    SelectFoundation.prototype._destroyLabel = function () {
        this._notchedOutlineInstance = undefined;
        this._floatingLabelInstance = undefined;
    };
    SelectFoundation.prototype._initializeValue = function () {
        var _this = this;
        var options = (this._options.length && this._options) || this._adapter.getOptions();
        if (isDefined(this._value) && options.length) {
            this._applyValue(this._value);
            if (this._selectedValues.length) {
                window.requestAnimationFrame(function () { return _this._floatLabel(true); });
            }
        }
    };
    SelectFoundation.prototype._initializeAccessibility = function () {
        if (this._multiple) {
            this._adapter.setHostAttribute('aria-multiselectable', 'true');
        }
        if (this._required) {
            this._adapter.setHostAttribute('aria-required', 'true');
        }
        if (this._disabled) {
            this._adapter.setHostAttribute('aria-disabled', 'true');
        }
        if (this._invalid) {
            this._adapter.setHostAttribute('aria-invalid', 'true');
        }
    };
    SelectFoundation.prototype.disconnect = function () {
        this._hasFocus = false;
        this._adapter.removeRootInteractionListener('click', this._clickListener);
        this._adapter.removeInteractionListener('blur', this._blurListener);
        this._adapter.removeInteractionListener('focus', this._focusListener);
        this._adapter.removeInteractionListener('keydown', this._keydownListener);
        this._adapter.removeLeadingListener(this._leadingChangeListener);
        if (this._isDropdownOpen) {
            this._closeDropdown();
        }
        if (this._optionListenerDestructor) {
            this._optionListenerDestructor();
        }
        if (this._notchedOutlineInstance) {
            this._notchedOutlineInstance.destroy();
            this._notchedOutlineInstance = undefined;
        }
        if (this._floatingLabelInstance) {
            this._floatingLabelInstance.destroy();
            this._floatingLabelInstance = undefined;
        }
    };
    Object.defineProperty(SelectFoundation.prototype, "_flatOptions", {
        get: function () {
            if (isOptionType(this._options, OptionType.Group)) {
                return [].concat.apply([], this._options.map(function (g) { return g.options; }));
            }
            return this._options;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Called when the options change.
     * @param options The current options.
     */
    SelectFoundation.prototype._onOptionsChanged = function (options) {
        this._applyValue(this._value);
    };
    /**
     * Called when the `slotchange` event fires on the "leading" slot element.
     * @param evt
     */
    SelectFoundation.prototype._onLeadingChanged = function (evt) {
        this._detectLeadingElement();
    };
    /**
     * Updates the component state based on the existance of elements within the "leading" slot.
     */
    SelectFoundation.prototype._detectLeadingElement = function () {
        if (this._adapter.hasLeadingElement()) {
            this._adapter.addClass(SELECT_CONSTANTS.classes.LEADING_ICON);
        }
        else {
            this._adapter.removeClass(SELECT_CONSTANTS.classes.LEADING_ICON);
        }
    };
    /**
     * Handles clicking the select element.
     * @param evt
     */
    SelectFoundation.prototype._onClick = function (evt) {
        if (this._disabled) {
            return;
        }
        this.setFocus();
        if (!this._isDropdownOpen) {
            this._openDropdown();
        }
        else {
            this._closeDropdown();
        }
    };
    /**
     * Handles keydown events when the select element has focus.
     * @param evt
     */
    SelectFoundation.prototype._onKeydown = function (evt) {
        var _this = this;
        var isEscapeKey = evt.key === 'Escape' && evt.keyCode === 27;
        var isEnter = evt.key === 'Enter' || evt.keyCode === 13;
        var isSpace = evt.key === 'Space' || evt.keyCode === 32;
        var isArrowDown = evt.key === 'ArrowDown' || evt.keyCode === 40;
        var isArrowUp = evt.key === 'ArrowUp' || evt.keyCode === 38;
        var isFilterableCharacter = evt.keyCode >= 48 && evt.keyCode <= 90;
        // If an active filter was started, clear it now
        if (!isFilterableCharacter && this._filterTimeout) {
            window.clearTimeout(this._filterTimeout);
            this._filterString = '';
            this._filterTimeout = undefined;
        }
        if (isEscapeKey) {
            evt.preventDefault();
            if (this._isDropdownOpen) {
                this._closeDropdown();
                return;
            }
        }
        if (isSpace) {
            evt.preventDefault();
            if (!this._isDropdownOpen) {
                this._openDropdown();
            }
            else {
                this._closeDropdown();
            }
        }
        else if (isEnter) {
            evt.preventDefault();
            if (!this._isDropdownOpen) {
                this._openDropdown();
            }
            else {
                var options = this._adapter.getOptions();
                var activeOptionIndex = this._adapter.getActiveOptionIndex();
                this._onSelect(options[activeOptionIndex], activeOptionIndex);
                if (this._multiple) {
                    this._adapter.toggleOption(activeOptionIndex);
                }
            }
        }
        else if (isArrowUp || isArrowDown) {
            evt.preventDefault();
            if (this._multiple && !this._isDropdownOpen) {
                this._openDropdown();
                return;
            }
            var options = this._adapter.getOptions();
            if (options.length === 0) {
                return;
            }
            var optionIndex = 0;
            if (this._isDropdownOpen) {
                optionIndex = this._adapter.getActiveOptionIndex();
            }
            else {
                var selectedOption = options.find(function (option) { return _this._selectedValues.includes(option.value); });
                optionIndex = selectedOption ? options.indexOf(selectedOption) : 0;
            }
            if (isArrowUp) {
                if (optionIndex === 0) {
                    optionIndex = options.length - 1;
                }
                else {
                    optionIndex--;
                }
            }
            else {
                if (optionIndex === options.length - 1) {
                    optionIndex = 0;
                }
                else {
                    optionIndex++;
                }
            }
            if (this._isDropdownOpen) {
                this._adapter.highlightActiveOption(optionIndex);
            }
            else {
                this._onSelect(options[optionIndex], optionIndex);
            }
        }
        else if (isFilterableCharacter) {
            this._filter(evt.key);
        }
    };
    SelectFoundation.prototype._onPopupKeydown = function (evt) {
        var isFilterableCharacter = evt.keyCode >= 48 && evt.keyCode <= 90;
        // If an active filter was started, clear it now
        if (!isFilterableCharacter && this._filterTimeout) {
            window.clearTimeout(this._filterTimeout);
            this._filterString = '';
            this._filterTimeout = undefined;
        }
        if (isFilterableCharacter) {
            this._filter(evt.key);
        }
    };
    SelectFoundation.prototype._filter = function (key) {
        var _this = this;
        // This allows for typing numbers and/or characters while the select is focused to auto-select the closest match
        if (this._filterTimeout) {
            window.clearTimeout(this._filterTimeout);
            this._filterTimeout = undefined;
        }
        this._filterString += key;
        this._filterTimeout = window.setTimeout(function () {
            _this._filterString = '';
            _this._filterTimeout = undefined;
        }, 300);
        var options = this._adapter.getOptions();
        // TODO(kieran.nichols): Enhance this to cycle through closest matches (see the native select)
        var matchedOption = options.find(function (option) { return option.label.toLowerCase().startsWith(_this._filterString); });
        if (matchedOption) {
            var optionIndex = options.indexOf(matchedOption);
            this._onSelect(matchedOption, optionIndex, false);
            if (this._isDropdownOpen) {
                this._adapter.setSelectedOption(optionIndex);
            }
        }
    };
    /**
     * Handles receiving focus on the selected text element.
     * @param evt
     */
    SelectFoundation.prototype._onFocus = function (evt) {
        if (this._disabled) {
            return;
        }
        this._adapter.addClass(SELECT_CONSTANTS.classes.FOCUSED);
        this._floatLabel(true);
        this._hasFocus = true;
    };
    /**
     * Updates the state of the notched outline.
     * @param isNotched Whether the outline is notched or not.
     */
    SelectFoundation.prototype._notch = function (isNotched) {
        if (!this._floatingLabelInstance || !this._notchedOutlineInstance) {
            return;
        }
        if (isNotched) {
            this._notchedOutlineInstance.notch(this._getLabelWidth());
        }
        else if (this._floatLabelType !== 'always') {
            this._notchedOutlineInstance.closeNotch();
        }
    };
    SelectFoundation.prototype._getLabelWidth = function () {
        var width = ((this._floatingLabelInstance && this._floatingLabelInstance.getWidth()) || 0) * SELECT_CONSTANTS.numbers.LABEL_SCALE;
        if (!width) {
            var labelFont = this._adapter.getLabelFontMetrics();
            var fontSize = labelFont.fontSize * SELECT_CONSTANTS.numbers.LABEL_SCALE;
            width = this._adapter.getLabelWidth(fontSize, labelFont.fontFamily);
        }
        return width;
    };
    SelectFoundation.prototype._floatLabel = function (value) {
        if (!this._floatingLabelInstance) {
            return;
        }
        if (value) {
            this._adapter.addClass(SELECT_CONSTANTS.classes.LABEL_FLOAT);
        }
        else {
            this._adapter.removeClass(SELECT_CONSTANTS.classes.LABEL_FLOAT);
        }
        if (this._floatLabelType === 'always') {
            value = true;
        }
        this._floatingLabelInstance.float(value);
        this._notch(value);
    };
    /**
     * Handles losing focus on the selected text element.
     * @param evt
     */
    SelectFoundation.prototype._onBlur = function (evt) {
        if (this._isDropdownOpen) {
            return;
        }
        this._setBlurred();
        this._closeDropdown();
        this._hasFocus = false;
    };
    /**
     * Opens the dropdown with selectable options.
     */
    SelectFoundation.prototype._openDropdown = function () {
        var _this = this;
        if (!this._options.length) {
            this._options = this._adapter.getOptions();
        }
        if (!this._flatOptions.length) {
            return;
        }
        this._isDropdownOpen = true;
        this._adapter.open(this._options, this._selectedValues, function (option, index) { return _this._onSelect(option, index); }, this._multiple);
        window.requestAnimationFrame(function () {
            _this._adapter.setDismissListener(_this._dismissListener);
            if (!_this._multiple) {
                _this._adapter.setPopupKeydownListener(function (evt) { return _this._onPopupKeydown(evt); });
            }
        });
    };
    /**
     * Closes the dropdown.
     */
    SelectFoundation.prototype._closeDropdown = function () {
        this._isDropdownOpen = false;
        this._adapter.close();
    };
    /**
     * Handles selecting an item in the dropdown.
     * @param {IOption} option The selected option.
     * @param {number} optionIndex The index of the selected option.
     */
    SelectFoundation.prototype._onSelect = function (option, optionIndex, closeDropdown) {
        if (closeDropdown === void 0) { closeDropdown = true; }
        var value = option ? option.value : '';
        var label = option ? option.label : '';
        if (this._multiple) {
            if (this._selectedValues.includes(value)) {
                var index = this._selectedValues.indexOf(value);
                this._selectedValues.splice(index, 1);
                this._selectedLabels.splice(index, 1);
                this._selectedIndexes.splice(index, 1);
            }
            else {
                this._selectedValues.push(value);
                this._selectedLabels.push(label);
                this._selectedIndexes.push(optionIndex);
            }
        }
        else {
            if (isDefined(value)) {
                this._selectedValues[0] = value;
                this._selectedLabels[0] = label;
                this._selectedIndexes[0] = optionIndex;
            }
            else {
                this._selectedValues = [];
                this._selectedLabels = [];
                this._selectedIndexes = [];
            }
        }
        // Always keep the internal value in sync in case the component needs to reinitialize with the existing value later on
        this._value = __spreadArrays(this._selectedValues);
        this._adapter.setSelectedText(this._getSelectedText());
        if (closeDropdown && !this._multiple) {
            this._closeDropdown();
            this._adapter.focus();
        }
        this._adapter.emitEvent(SELECT_CONSTANTS.events.CHANGE, this.multiple ? __spreadArrays(this._selectedValues) : this._selectedValues[0]);
    };
    /**
     * Handles the user dismissing the dropdown.
     */
    SelectFoundation.prototype._onDismiss = function (keepFocus) {
        this._setBlurred();
        this._closeDropdown();
    };
    /**
     * Creates the selected text value from the selected label values.
     */
    SelectFoundation.prototype._getSelectedText = function () {
        if (this._multiple) {
            if (this._selectedLabels.length) {
                return this._selectedLabels.length + " " + (this._selectedLabels.length === 1 ? 'option' : 'options') + " selected";
            }
            else {
                return '';
            }
        }
        else {
            return this._selectedLabels.filter(function (v) { return v && v.length; }).join(' ').trim();
        }
    };
    /**
     * Updates the state of the component to not contain focus.
     */
    SelectFoundation.prototype._setBlurred = function () {
        this._adapter.removeClass(SELECT_CONSTANTS.classes.FOCUSED);
        if (!this._selectedValues.length) {
            this._floatLabel(false);
        }
    };
    /**
     * Resets the state of the component to original values.
     */
    SelectFoundation.prototype._reset = function () {
        this._selectedValues = [];
        this._selectedLabels = [];
        this._adapter.setSelectedText('');
        this._floatLabel(false);
    };
    SelectFoundation.prototype._updateLabel = function () {
        if (this._adapter.hasLabel()) {
            this._adapter.setLabel(this._label);
        }
        if (this._label) {
            this._adapter.removeClass(SELECT_CONSTANTS.classes.NO_LABEL);
        }
        else {
            this._adapter.addClass(SELECT_CONSTANTS.classes.NO_LABEL);
        }
    };
    SelectFoundation.prototype._applyValue = function (value) {
        this._selectedValues = [];
        this._selectedLabels = [];
        var options = this._adapter.getOptions();
        if (!Array.isArray(value)) {
            value = [value];
        }
        this._value = [];
        var _loop_1 = function (val) {
            if (!this_1._value.includes(val)) {
                this_1._value.push(val);
            }
            var option = options.find(function (o) { return o.value === val; });
            if (option) {
                this_1._selectedValues.push(option.value);
                this_1._selectedLabels.push(option.label);
            }
        };
        var this_1 = this;
        // Ensure that the values passed are actually existing options
        for (var _i = 0, value_1 = value; _i < value_1.length; _i++) {
            var val = value_1[_i];
            _loop_1(val);
        }
        // Update the selected indexes based on the values that are now selected
        this._selectedIndexes = this._selectedValues.map(function (val) { return options.findIndex(function (o) { return o.value === val; }); });
        // Update the state of the component based on the existence of a selected value
        var text = this._getSelectedText();
        this._adapter.setSelectedText(text);
        this._floatLabel(!!text);
        // Update the selected options in the dropdown if it's open
        if (this._isDropdownOpen) {
            this._adapter.setSelectedValues(this._selectedValues);
        }
    };
    SelectFoundation.prototype._applyDensity = function () {
        this._adapter.setDense(this._density === 'dense');
        this._adapter.setRoomy(this._density === 'roomy');
    };
    SelectFoundation.prototype.setFocus = function () {
        this._adapter.setFocus();
    };
    Object.defineProperty(SelectFoundation.prototype, "label", {
        /** Gets/sets the label text. */
        get: function () {
            return this._label;
        },
        set: function (value) {
            if (this._label !== value) {
                this._label = value;
                this._updateLabel();
                if (this._label) {
                    this._initializeLabel();
                    this._adapter.setHostAttribute(SELECT_CONSTANTS.attributes.LABEL, this._label);
                }
                else {
                    this._destroyLabel();
                    this._adapter.removeHostAttribute(SELECT_CONSTANTS.attributes.LABEL);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectFoundation.prototype, "value", {
        /** Gets/sets the value of the component. */
        get: function () {
            return this._multiple ? __spreadArrays(this._selectedValues) : this._selectedValues[0];
        },
        set: function (value) {
            this._applyValue(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectFoundation.prototype, "selectedIndex", {
        /** Gets/sets the selected index(s). */
        get: function () {
            return this._multiple ? this._selectedIndexes : this._selectedIndexes[0];
        },
        set: function (indexes) {
            var options = this._adapter.getOptions();
            if (!(indexes instanceof Array)) {
                indexes = [indexes];
            }
            indexes.sort();
            if (this.multiple) {
                this.value = indexes.map(function (index) { return options[index]; }).filter(function (o) { return o; }).map(function (o) { return o.value; });
            }
            else {
                var option = options[indexes[indexes.length - 1]];
                if (!option) {
                    return;
                }
                this.value = option.value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectFoundation.prototype, "options", {
        /** Gets/sets the available options. */
        get: function () {
            return this._adapter.getOptions();
        },
        set: function (value) {
            this._options = value;
            this._initializeValue();
            this._adapter.setOptions(value);
            if (this._isDropdownOpen) {
                this._closeDropdown();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectFoundation.prototype, "multiple", {
        /** Gets/sets the multiple select state. */
        get: function () {
            return this._multiple;
        },
        set: function (value) {
            if (this._multiple !== value) {
                this._multiple = value;
                this._reset();
                if (this._isDropdownOpen) {
                    this._closeDropdown();
                }
                if (this._multiple) {
                    this._adapter.setHostAttribute('aria-multiselectable', 'true');
                }
                else {
                    this._adapter.removeHostAttribute('aria-multiselectable');
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectFoundation.prototype, "open", {
        /** Gets the open state of the dropdown. */
        get: function () {
            return this._isDropdownOpen;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectFoundation.prototype, "disabled", {
        /** Gets/sets the disabled state. */
        get: function () {
            return this._disabled;
        },
        set: function (value) {
            if (this._disabled !== value) {
                this._disabled = value;
                this._adapter.setDisabled(this._disabled);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectFoundation.prototype, "invalid", {
        /** Gets/sets the invalid state. */
        get: function () {
            return this._invalid;
        },
        set: function (value) {
            if (this._invalid !== value) {
                this._invalid = value;
                this._adapter.setInvalid(this._invalid);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectFoundation.prototype, "required", {
        /** Gets/sets the required state which controls the visibility of the asterisk in the label. */
        get: function () {
            return this._required;
        },
        set: function (value) {
            var _this = this;
            if (this._required !== value) {
                this._required = value;
                if (this._notchedOutlineInstance && this._floatingLabelInstance && (this._hasFocus || this._selectedValues.length)) {
                    window.requestAnimationFrame(function () { return _this._notch(true); });
                }
                this._adapter.setRequired(this._required);
                if (this._required) {
                    this._adapter.setHostAttribute(SELECT_CONSTANTS.attributes.REQUIRED);
                }
                else {
                    this._adapter.removeHostAttribute(SELECT_CONSTANTS.attributes.REQUIRED);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectFoundation.prototype, "density", {
        /** Gets/sets the density type. */
        get: function () {
            return this._density;
        },
        set: function (value) {
            if (this._density !== value) {
                this._density = value;
                this._applyDensity();
                this._adapter.setHostAttribute(SELECT_CONSTANTS.attributes.DENSITY, isDefined(this._density) ? this._density.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectFoundation.prototype, "floatLabelType", {
        /** Gets/sets the floating label type. */
        get: function () {
            return this._floatLabelType;
        },
        set: function (value) {
            if (this._floatLabelType !== value) {
                this._floatLabelType = value;
                this._floatLabel(this._floatLabelType === 'always');
                this._adapter.setHostAttribute(SELECT_CONSTANTS.attributes.FLOAT_LABEL_TYPE, isDefined(this._floatLabelType) ? this._floatLabelType.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectFoundation.prototype, "placeholder", {
        /** Gets/sets the placeholder text. */
        get: function () {
            return this._placeholder;
        },
        set: function (value) {
            if (this._placeholder !== value) {
                this._placeholder = value;
                this._adapter.setPlaceholderText(this._placeholder);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectFoundation.prototype, "popupElement", {
        /** Gets the currently active popup element when the dropdown is open. */
        get: function () {
            return this._adapter.popupElement;
        },
        enumerable: true,
        configurable: true
    });
    return SelectFoundation;
}());
export { SelectFoundation };
