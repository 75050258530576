import { getShadowElement, emitEvent, removeElement } from '@tyler-components-web/core';
import { deepQuerySelectorAll } from 'shadow-utils';
import { BACKDROP_CONSTANTS } from '../backdrop';
import { DIALOG_CONSTANTS } from './dialog-constants';
/**
 * Provides facilities for interacting with the internal DOM of `DialogComponent`.
 */
var DialogAdapter = /** @class */ (function () {
    function DialogAdapter(_component) {
        this._component = _component;
        this._backdropElement = getShadowElement(_component, DIALOG_CONSTANTS.selectors.BACKDROP);
        this._containerElement = getShadowElement(_component, DIALOG_CONSTANTS.selectors.CONTAINER);
        this._surfaceElement = getShadowElement(_component, DIALOG_CONSTANTS.selectors.SURFACE);
    }
    DialogAdapter.prototype.setHostAttribute = function (name, value) {
        this._component.setAttribute(name, value);
    };
    DialogAdapter.prototype.removeHostAttribute = function (name) {
        this._component.removeAttribute(name);
    };
    DialogAdapter.prototype.setAnimating = function (animating) {
        if (animating) {
            this._containerElement.classList.add(DIALOG_CONSTANTS.classes.ANIMATING);
        }
        else {
            this._containerElement.classList.remove(DIALOG_CONSTANTS.classes.ANIMATING);
        }
    };
    DialogAdapter.prototype.setVisibility = function (visible) {
        if (visible) {
            this._containerElement.classList.add(DIALOG_CONSTANTS.classes.OPEN);
        }
        else {
            this._containerElement.classList.remove(DIALOG_CONSTANTS.classes.OPEN);
        }
    };
    DialogAdapter.prototype.add = function () {
        document.body.appendChild(this._component);
    };
    DialogAdapter.prototype.remove = function () {
        removeElement(this._component);
    };
    DialogAdapter.prototype.registerTransitionEndHandler = function (handler) {
        this._surfaceElement.addEventListener('transitionend', handler);
    };
    DialogAdapter.prototype.deregisterTransitionEndHandler = function (handler) {
        this._surfaceElement.removeEventListener('transitionend', handler);
    };
    DialogAdapter.prototype.registerDocumentKeydownHandler = function (handler) {
        document.addEventListener('keydown', handler);
    };
    DialogAdapter.prototype.deregisterDocumentKeydownHandler = function (handler) {
        document.removeEventListener('keydown', handler);
    };
    DialogAdapter.prototype.registerBackdropClickHandler = function (handler) {
        this._backdropElement.addEventListener(BACKDROP_CONSTANTS.events.BACKDROP_CLICK, handler);
    };
    DialogAdapter.prototype.deregisterBackdropClickHandler = function (handler) {
        this._backdropElement.removeEventListener(BACKDROP_CONSTANTS.events.BACKDROP_CLICK, handler);
    };
    DialogAdapter.prototype.getSurfaceElement = function () {
        return this._surfaceElement;
    };
    DialogAdapter.prototype.emitEvent = function (type, data) {
        if (data === void 0) { data = null; }
        emitEvent(this._component, type, data);
    };
    DialogAdapter.prototype.getOpenDialogs = function (selector) {
        return document.querySelectorAll(selector);
    };
    DialogAdapter.prototype.setBodyAttribute = function (name, value) {
        document.body.setAttribute(name, value);
    };
    DialogAdapter.prototype.removeBodyAttribute = function (name) {
        document.body.removeAttribute(name);
    };
    DialogAdapter.prototype.trySetInitialFocus = function () {
        var elements = deepQuerySelectorAll(this._component, DIALOG_CONSTANTS.selectors.INITIAL_FOCUS);
        if (elements && elements.length) {
            var initialElement = elements[elements.length - 1];
            initialElement.focus();
        }
    };
    DialogAdapter.prototype.isScrollable = function () {
        var contentElement = this._component.querySelector(DIALOG_CONSTANTS.selectors.CONTENT);
        if (contentElement) {
            return contentElement.scrollHeight > contentElement.offsetHeight;
        }
        return false;
    };
    DialogAdapter.prototype.addRootClass = function (name) {
        this._component.classList.add(name);
    };
    DialogAdapter.prototype.getHostElement = function () {
        return this._component;
    };
    DialogAdapter.prototype.setFullscreen = function (value) {
        if (value) {
            this._containerElement.classList.add(DIALOG_CONSTANTS.classes.FULLSCREEN);
        }
        else {
            this._containerElement.classList.remove(DIALOG_CONSTANTS.classes.FULLSCREEN);
        }
    };
    return DialogAdapter;
}());
export { DialogAdapter };
