import { getShadowElement, removeAllChildren, Platform } from '@tyler-components-web/core';
import { AVATAR_CONSTANTS } from './avatar-constants';
/**
 * The DOM adapter behind the `<tcw-avatar>` element.
 */
var AvatarAdapter = /** @class */ (function () {
    function AvatarAdapter(_component) {
        this._component = _component;
        this._root = getShadowElement(this._component, AVATAR_CONSTANTS.selectors.ROOT);
        this._defaultSlot = getShadowElement(this._component, AVATAR_CONSTANTS.selectors.DEFAULT_SLOT);
    }
    /**
     * Sets an attribute value on the host element.
     * @param name The attribute name.
     * @param value The attribute value.
     */
    AvatarAdapter.prototype.setHostAttribute = function (name, value) {
        this._component.setAttribute(name, value);
    };
    /**
     * Sets the `backgroundColor` style on the content element.
     * @param {string} value The background color.
     */
    AvatarAdapter.prototype.setBackgroundColor = function (value) {
        if (Platform.TRIDENT) {
            this._root.style.backgroundColor = value;
        }
        else {
            this._root.style.backgroundColor = "var(--tyl-avatar-background-color, " + value + ")";
        }
    };
    /**
     * Sets the background image URL.
     * @param url The URL.
     */
    AvatarAdapter.prototype.setBackgroundImageUrl = function (url) {
        this._root.style.backgroundImage = "url(" + url + ")";
    };
    /**
     * Removes the background image URL.
     */
    AvatarAdapter.prototype.removeBackgroundImage = function () {
        this._root.style.removeProperty('background-image');
    };
    /**
     * Sets the avatar text content.
     * @param value The text value.
     */
    AvatarAdapter.prototype.setText = function (value) {
        this._defaultSlot.textContent = value;
    };
    AvatarAdapter.prototype.clearText = function () {
        removeAllChildren(this._defaultSlot);
    };
    return AvatarAdapter;
}());
export { AvatarAdapter };
