import { getShadowElement, addClass, removeClass } from '@tyler-components-web/core';
import { OPEN_ICON_CONSTANTS } from './open-icon-constants';
var OpenIconAdapter = /** @class */ (function () {
    function OpenIconAdapter(_component) {
        this._component = _component;
        this._openIcon = getShadowElement(_component, "." + OPEN_ICON_CONSTANTS.classes.ICON);
    }
    OpenIconAdapter.prototype.initialize = function (orientation) {
        if (orientation === OPEN_ICON_CONSTANTS.strings.ORIENTATION_HORIZONTAL) {
            addClass(OPEN_ICON_CONSTANTS.classes.ICON_HORIZONTAL, this._openIcon);
        }
        else {
            addClass(OPEN_ICON_CONSTANTS.classes.ICON_VERTICAL, this._openIcon);
        }
    };
    OpenIconAdapter.prototype.setOpenState = function (open) {
        if (open) {
            addClass(OPEN_ICON_CONSTANTS.classes.ICON_OPEN, this._openIcon);
        }
        else {
            removeClass(OPEN_ICON_CONSTANTS.classes.ICON_OPEN, this._openIcon);
        }
    };
    return OpenIconAdapter;
}());
export { OpenIconAdapter };
