import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "icon-button";
var attributes = {
    TOGGLE: 'toggle',
    IS_ON: 'is-on',
    ICON_ON: 'tcw-icon-button-on',
    DENSE: 'dense'
};
var selectors = {
    BUTTON: 'button'
};
var classes = {
    BUTTON: 'tyl-icon-button',
    BUTTON_ON: 'tyl-icon-button--on',
    BUTTON_DENSE: 'tyl-icon-button--dense',
    ICON: 'tyl-icon-button__icon',
    ICON_ON: 'tyl-icon-button__icon--on'
};
var events = {
    CHANGE: 'change'
};
export var ICON_BUTTON_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    selectors: selectors,
    classes: classes,
    events: events
};
