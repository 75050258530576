import { COMPONENT_NAME_PREFIX } from '../constants';
import { BACKDROP_CONSTANTS } from '../backdrop';
var elementName = COMPONENT_NAME_PREFIX + "dialog";
var classes = {
    ANIMATING: 'tyl-dialog--animating',
    OPEN: 'tyl-dialog--open',
    ACTION_BUTTON: 'tyl-dialog__action__button',
    BUTTON_TEXT: 'tyl-button__text',
    SCROLLABLE: 'tyl-dialog--scrollable',
    FULLSCREEN: 'tyl-dialog--fullscreen'
};
var selectors = {
    CONTAINER: '.tyl-dialog',
    SURFACE: '.tyl-dialog__surface',
    BACKDROP: BACKDROP_CONSTANTS.elementName,
    INITIAL_FOCUS: '[tcw-dialog-focus]',
    CONTENT: '.tyl-dialog__body'
};
var attributes = {
    BACKDROP_CLOSE: 'backdrop-close',
    ESCAPE_CLOSE: 'escape-close',
    FULLSCREEN: 'fullscreen',
    OPEN: 'tcw-dialog-open',
    INITIAL_FOCUS: 'tcw-dialog-focus'
};
var events = {
    OPEN: elementName + "-open",
    CLOSE: elementName + "-close"
};
var numbers = {
    ANIMATION_DURATION: 150,
    BACKDROP_MAX_OPACITY: 0.3
};
export var DIALOG_CONSTANTS = {
    elementName: elementName,
    classes: classes,
    selectors: selectors,
    attributes: attributes,
    events: events,
    numbers: numbers
};
