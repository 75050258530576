import { __decorate, __extends, __spreadArrays } from "tslib";
import { CustomElement, ensureChildren, coerceNumber, upgradeProperty, coerceBoolean, attachShadowTemplate } from '@tyler-components-web/core';
import { AutocompleteAdapter } from './autocomplete-adapter';
import { AutocompleteFoundation } from './autocomplete-foundation';
import { AUTOCOMPLETE_CONSTANTS } from './autocomplete-constants';
import { TextFieldComponent } from '../text-field';
import { PopupComponent } from '../popup';
import { ListComponent, ListItemComponent } from '../list';
import { DividerComponent } from '../divider';
import { ProgressSpinnerComponent } from '../progress-spinner';
var template = require('./autocomplete.html');
var styles = require('./autocomplete.css');
/**
 * The custom element class behind the `<tcw-autocomplete>` element.
 */
var AutocompleteComponent = /** @class */ (function (_super) {
    __extends(AutocompleteComponent, _super);
    function AutocompleteComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, AUTOCOMPLETE_CONSTANTS.elementName, template, styles);
        _this._foundation = new AutocompleteFoundation(new AutocompleteAdapter(_this));
        return _this;
    }
    Object.defineProperty(AutocompleteComponent, "observedAttributes", {
        get: function () {
            return [
                AUTOCOMPLETE_CONSTANTS.attributes.MULTIPLE,
                AUTOCOMPLETE_CONSTANTS.attributes.DEBOUNCE,
                AUTOCOMPLETE_CONSTANTS.attributes.HIGHLIGHT_FIRST,
                AUTOCOMPLETE_CONSTANTS.attributes.FILTER_ON_FOCUS,
                AUTOCOMPLETE_CONSTANTS.attributes.ALLOW_UNMATCHED,
                AUTOCOMPLETE_CONSTANTS.attributes.POPUP_TARGET,
                AUTOCOMPLETE_CONSTANTS.attributes.POPUP_CLASSES,
                AUTOCOMPLETE_CONSTANTS.attributes.OBSERVE_SCROLL,
                AUTOCOMPLETE_CONSTANTS.attributes.OBSERVE_SCROLL_THRESHOLD,
                AUTOCOMPLETE_CONSTANTS.attributes.OPTION_LIMIT
            ];
        },
        enumerable: true,
        configurable: true
    });
    AutocompleteComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'multiple');
        upgradeProperty(this, 'value');
        upgradeProperty(this, 'debounce');
        upgradeProperty(this, 'highlightFirst');
        upgradeProperty(this, 'filterOnFocus');
        upgradeProperty(this, 'allowUnmatched');
        upgradeProperty(this, 'popupTarget');
        upgradeProperty(this, 'popupClasses');
        upgradeProperty(this, 'optionBuilder');
        upgradeProperty(this, 'optionLimit');
        upgradeProperty(this, 'filter');
        upgradeProperty(this, 'selectedTextBuilder');
        upgradeProperty(this, 'observeScroll');
        upgradeProperty(this, 'observeScrollThreshold');
    };
    AutocompleteComponent.prototype.connectedCallback = function () {
        var _this = this;
        if (this.children.length) {
            this._foundation.initialize();
        }
        else {
            ensureChildren(this).then(function () { return _this._foundation.initialize(); });
        }
    };
    AutocompleteComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    AutocompleteComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case AUTOCOMPLETE_CONSTANTS.attributes.MULTIPLE:
                this.multiple = coerceBoolean(newValue);
                break;
            case AUTOCOMPLETE_CONSTANTS.attributes.DEBOUNCE:
                this.debounce = coerceNumber(newValue);
                break;
            case AUTOCOMPLETE_CONSTANTS.attributes.HIGHLIGHT_FIRST:
                this.highlightFirst = coerceBoolean(newValue);
                break;
            case AUTOCOMPLETE_CONSTANTS.attributes.FILTER_ON_FOCUS:
                this.filterOnFocus = coerceBoolean(newValue);
                break;
            case AUTOCOMPLETE_CONSTANTS.attributes.ALLOW_UNMATCHED:
                this.allowUnmatched = coerceBoolean(newValue);
                break;
            case AUTOCOMPLETE_CONSTANTS.attributes.POPUP_TARGET:
                this.popupTarget = newValue;
                break;
            case AUTOCOMPLETE_CONSTANTS.attributes.POPUP_CLASSES:
                this.popupClasses = newValue;
                break;
            case AUTOCOMPLETE_CONSTANTS.attributes.OPTION_LIMIT:
                this.optionLimit = coerceNumber(newValue);
                break;
            case AUTOCOMPLETE_CONSTANTS.attributes.OBSERVE_SCROLL:
                this.observeScroll = this.hasAttribute(AUTOCOMPLETE_CONSTANTS.attributes.OBSERVE_SCROLL);
                break;
            case AUTOCOMPLETE_CONSTANTS.attributes.OBSERVE_SCROLL_THRESHOLD:
                this.observeScrollThreshold = coerceNumber(newValue);
                break;
        }
    };
    Object.defineProperty(AutocompleteComponent.prototype, "multiple", {
        /** Gets/sets the multi-select state. */
        get: function () {
            return this._foundation.multiple;
        },
        set: function (value) {
            this._foundation.multiple = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteComponent.prototype, "value", {
        /** Gets/sets the value. */
        get: function () {
            return this._foundation.value;
        },
        set: function (value) {
            this._foundation.value = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteComponent.prototype, "debounce", {
        /** Gets/sets the debounce delay (milliseconds) for keyboard events. */
        get: function () {
            return this._foundation.debounce;
        },
        set: function (value) {
            this._foundation.debounce = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteComponent.prototype, "highlightFirst", {
        /** Gets/sets the highlight first option setting. */
        get: function () {
            return this._foundation.highlightFirst;
        },
        set: function (value) {
            this._foundation.highlightFirst = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteComponent.prototype, "filterOnFocus", {
        /** Gets/sets filter on focus settings which controls whether the dropdown displays automatically when focused. */
        get: function () {
            return this._foundation.filterOnFocus;
        },
        set: function (value) {
            this._foundation.filterOnFocus = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteComponent.prototype, "allowUnmatched", {
        /** Controls whether unmatched text entered by the user will stay visible an option in the dropdown is not found. */
        get: function () {
            return this._foundation.allowUnmatched;
        },
        set: function (value) {
            this._foundation.allowUnmatched = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteComponent.prototype, "popupTarget", {
        /** Gets/sets the selector that will be used to find an element to attach the popup to. Defaults to the input element. */
        get: function () {
            return this._foundation.popupTarget;
        },
        set: function (value) {
            this._foundation.popupTarget = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteComponent.prototype, "popupClasses", {
        /** Gets/sets the list of classes to apply to the popup element. */
        get: function () {
            return this._foundation.popupClasses;
        },
        set: function (value) {
            this._foundation.popupClasses = value instanceof Array ? value : __spreadArrays(value.split(' '));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteComponent.prototype, "optionLimit", {
        /** Gets/sets the maximum number of options to display in the dropdown. */
        get: function () {
            return this._foundation.optionLimit;
        },
        set: function (value) {
            this._foundation.optionLimit = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteComponent.prototype, "optionBuilder", {
        /** Sets the option builder callback that will be executed when building the option list in the dropdown. */
        set: function (fn) {
            this._foundation.optionBuilder = fn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteComponent.prototype, "filter", {
        /** Sets the filter callback that will be executed when fetching options for the autocomplete dropdown. */
        set: function (cb) {
            this._foundation.filter = cb;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteComponent.prototype, "selectedTextBuilder", {
        /** Sets the selected text builder callback that will be executed when getting the selected text. */
        set: function (fn) {
            this._foundation.selectedTextBuilder = fn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteComponent.prototype, "observeScroll", {
        /** Controls the observation of scroll events on the dropdown. */
        get: function () {
            return this._foundation.observeScroll;
        },
        set: function (value) {
            this._foundation.observeScroll = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteComponent.prototype, "observeScrollThreshold", {
        /** The number of pixels from the bottom to trigger the scroll bottom event. Only applicable if `observeScroll` is true. */
        get: function () {
            return this._foundation.observeScrollThreshold;
        },
        set: function (value) {
            this._foundation.observeScrollThreshold = value;
        },
        enumerable: true,
        configurable: true
    });
    /** Adds options to the dropdown while it is open. Has no effect if the dropdown is closed.  */
    AutocompleteComponent.prototype.appendOptions = function (options) {
        this._foundation.appendOptions(options);
    };
    AutocompleteComponent = __decorate([
        CustomElement({
            name: AUTOCOMPLETE_CONSTANTS.elementName,
            dependencies: [
                TextFieldComponent,
                PopupComponent,
                ListComponent,
                ListItemComponent,
                DividerComponent,
                ProgressSpinnerComponent
            ]
        })
    ], AutocompleteComponent);
    return AutocompleteComponent;
}(HTMLElement));
export { AutocompleteComponent };
