import { Platform, isDefined } from '@tyler-components-web/core';
import { VIEW_SWITCHER_CONSTANTS, ViewAnimationDirection, ViewSwitcherAnimationType } from './view-switcher-constants';
var ViewSwitcherFoundation = /** @class */ (function () {
    function ViewSwitcherFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._viewCount = 0;
        this._viewIndex = 0;
        this._animationType = ViewSwitcherAnimationType.None;
        this._viewsChangedListener = function () { return _this._onViewsChanged(); };
    }
    ViewSwitcherFoundation.prototype.initialize = function () {
        // IE and Edge are restricting our fanciness...
        if (Platform.TRIDENT || Platform.EDGE) {
            this._animationType = ViewSwitcherAnimationType.None;
        }
        this._viewCount = this._adapter.getViewCount();
        this._adapter.startViewObserver(this._viewsChangedListener);
        this._adapter.setAnimationType(this._animationType);
        if (this._viewCount) {
            this._initializeAnimationType();
        }
    };
    ViewSwitcherFoundation.prototype.disconnect = function () {
        this._adapter.stopViewObserver();
    };
    ViewSwitcherFoundation.prototype._initializeAnimationType = function () {
        switch (this._animationType) {
            case ViewSwitcherAnimationType.Slide:
                this._adapter.initializeSlideViews(this._viewIndex);
                break;
            case ViewSwitcherAnimationType.Fade:
                this._adapter.initializeFadeViews(this._viewIndex);
                break;
            case ViewSwitcherAnimationType.None:
            default:
                this._adapter.hideInactiveViews(this._viewIndex);
                break;
        }
    };
    ViewSwitcherFoundation.prototype._onViewsChanged = function () {
        this._viewCount = this._adapter.getViewCount();
        if (this._viewCount) {
            this._initializeAnimationType();
        }
        if (this._viewIndex > this._viewCount) {
            var currIndex = this._viewIndex;
            this._viewIndex = this._viewCount > 0 ? this._viewCount - 1 : 0;
            this._goToView(currIndex, this._viewCount);
        }
    };
    ViewSwitcherFoundation.prototype._goToView = function (fromIndex, viewCount) {
        var _this = this;
        this._viewCount = !isDefined(viewCount) ? this._adapter.getViewCount() : viewCount;
        if (this._viewIndex < 0) {
            this._viewIndex = 0;
        }
        else if (this._viewIndex > this._viewCount - 1) {
            this._viewIndex = this._viewCount - 1;
        }
        switch (this._animationType) {
            case ViewSwitcherAnimationType.Slide:
                var animationDirection = this._viewIndex > fromIndex ? ViewAnimationDirection.Left : ViewAnimationDirection.Right;
                this._adapter.slideToView(fromIndex, this._viewIndex, animationDirection).then(function () {
                    _this._adapter.initializeSlideViews(_this._viewIndex);
                });
                break;
            case ViewSwitcherAnimationType.Fade:
                this._adapter.fadeToView(fromIndex, this._viewIndex).then(function () {
                    _this._adapter.initializeFadeViews(_this._viewIndex);
                });
                break;
            case ViewSwitcherAnimationType.None:
            default:
                this._adapter.setActiveView(this._viewIndex);
                break;
        }
    };
    ViewSwitcherFoundation.prototype.next = function () {
        this.index++;
    };
    ViewSwitcherFoundation.prototype.previous = function () {
        this.index--;
    };
    ViewSwitcherFoundation.prototype.goToStart = function () {
        this.index = 0;
    };
    ViewSwitcherFoundation.prototype.goToEnd = function () {
        this.index = this._viewCount - 1;
    };
    Object.defineProperty(ViewSwitcherFoundation.prototype, "index", {
        get: function () {
            return this._viewIndex;
        },
        set: function (value) {
            if (this._viewIndex !== value) {
                var currIndex = this._viewIndex;
                this._viewIndex = value;
                this._goToView(currIndex);
                this._adapter.setHostAttribute(VIEW_SWITCHER_CONSTANTS.attributes.INDEX, this._viewIndex.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewSwitcherFoundation.prototype, "animationType", {
        get: function () {
            return this._animationType;
        },
        set: function (value) {
            if (this._animationType !== value) {
                this._animationType = value;
                // IE and Edge are restricting our fanciness...
                if (Platform.TRIDENT || Platform.EDGE) {
                    this._animationType = ViewSwitcherAnimationType.None;
                    this._adapter.setHostAttribute(VIEW_SWITCHER_CONSTANTS.attributes.ANIMATION_TYPE, this._animationType);
                    return;
                }
                this._initializeAnimationType();
                this._adapter.setAnimationType(this._animationType);
                this._adapter.setHostAttribute(VIEW_SWITCHER_CONSTANTS.attributes.ANIMATION_TYPE, this._animationType);
            }
        },
        enumerable: true,
        configurable: true
    });
    return ViewSwitcherFoundation;
}());
export { ViewSwitcherFoundation };
