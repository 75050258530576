import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "popup";
var attributes = {
    CONTAINER: 'tyl-popup',
    OPEN: 'open',
    PLACEMENT: 'placement',
    MANAGE_FOCUS: 'manage-focus',
    ANIMATION_TYPE: 'animation-type',
    STATIC: 'static',
    HOST: 'tcw-popup-host',
    INITIAL_FOCUS: 'tcw-popup-focus'
};
var classes = {
    OPEN: 'tyl-popup--open',
    OPENING: 'tyl-popup--opening',
    CLOSED: 'tyl-popup--closed',
    SELECT: 'tyl-popup--select'
};
var selectors = {
    HOST: ".tyl-popup-host, [" + attributes.HOST + "]",
    CONTAINER: '.tyl-popup',
    INITIAL_FOCUS: "[" + attributes.INITIAL_FOCUS + "]"
};
var events = {
    OPEN: elementName + "-open",
    CLOSE: elementName + "-close",
    POSITION: elementName + "-position",
    BLUR: elementName + "-blur"
};
var numbers = {
    ANIMATION_DURATION: 120
};
export var POPUP_CONSTANTS = {
    elementName: elementName,
    classes: classes,
    selectors: selectors,
    attributes: attributes,
    events: events,
    numbers: numbers
};
export var PopupPlacement;
(function (PopupPlacement) {
    PopupPlacement["Top"] = "top";
    PopupPlacement["Bottom"] = "bottom";
    PopupPlacement["Left"] = "left";
    PopupPlacement["Right"] = "right";
    PopupPlacement["TopLeft"] = "top-left";
    PopupPlacement["TopRight"] = "top-right";
    PopupPlacement["BottomLeft"] = "bottom-left";
    PopupPlacement["BottomRight"] = "bottom-right";
    PopupPlacement["LeftTop"] = "left-top";
    PopupPlacement["LeftBottom"] = "left-bottom";
    PopupPlacement["RightTop"] = "right-top";
    PopupPlacement["RightBottom"] = "right-bottom";
})(PopupPlacement || (PopupPlacement = {}));
export var PopupAnimationType;
(function (PopupAnimationType) {
    PopupAnimationType["None"] = "none";
    PopupAnimationType["Menu"] = "menu";
    PopupAnimationType["Dropdown"] = "dropdown";
})(PopupAnimationType || (PopupAnimationType = {}));
