import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty, ensureChildren } from '@tyler-components-web/core';
import { OmnibarSearchFoundation } from './omnibar-search-foundation';
import { OmnibarSearchAdapter } from './omnibar-search-adapter';
import { OMNIBAR_SEARCH_CONSTANTS } from './omnibar-search-constants';
import { TooltipComponent } from '../../tooltip';
var template = require('./omnibar-search.html');
var styles = require('./omnibar-search.css');
/**
 * The web component class behind the `<tyl-omnibar-search>` custom element.
 */
var OmnibarSearchComponent = /** @class */ (function (_super) {
    __extends(OmnibarSearchComponent, _super);
    function OmnibarSearchComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, OMNIBAR_SEARCH_CONSTANTS.elementName, template, styles);
        _this._foundation = new OmnibarSearchFoundation(new OmnibarSearchAdapter(_this));
        return _this;
    }
    Object.defineProperty(OmnibarSearchComponent, "observedAttributes", {
        get: function () {
            return [
                OMNIBAR_SEARCH_CONSTANTS.attributes.DISABLED,
                OMNIBAR_SEARCH_CONSTANTS.attributes.VALUE,
                OMNIBAR_SEARCH_CONSTANTS.attributes.PLACEHOLDER,
                OMNIBAR_SEARCH_CONSTANTS.attributes.COMBINED,
                OMNIBAR_SEARCH_CONSTANTS.attributes.GLOBAL
            ];
        },
        enumerable: true,
        configurable: true
    });
    OmnibarSearchComponent.prototype.connectedCallback = function () {
        var _this = this;
        upgradeProperty(this, 'disabled');
        upgradeProperty(this, 'value');
        upgradeProperty(this, 'placeholder');
        upgradeProperty(this, 'combined');
        upgradeProperty(this, 'combinedOptions');
        upgradeProperty(this, 'selectedCombinedOption');
        upgradeProperty(this, 'global');
        if (this.children.length) {
            this._initialize();
        }
        else {
            ensureChildren(this).then(function () { return _this._initialize(); });
        }
    };
    OmnibarSearchComponent.prototype._initialize = function () {
        this._foundation.initialize();
    };
    OmnibarSearchComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    OmnibarSearchComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case OMNIBAR_SEARCH_CONSTANTS.attributes.DISABLED:
                this.disabled = this.hasAttribute(OMNIBAR_SEARCH_CONSTANTS.attributes.DISABLED);
                break;
            case OMNIBAR_SEARCH_CONSTANTS.attributes.VALUE:
                this.value = newValue;
                break;
            case OMNIBAR_SEARCH_CONSTANTS.attributes.PLACEHOLDER:
                this.placeholder = newValue;
                break;
            case OMNIBAR_SEARCH_CONSTANTS.attributes.COMBINED:
                this.combined = this.hasAttribute(OMNIBAR_SEARCH_CONSTANTS.attributes.COMBINED);
                break;
            case OMNIBAR_SEARCH_CONSTANTS.attributes.GLOBAL:
                this.global = this.hasAttribute(OMNIBAR_SEARCH_CONSTANTS.attributes.GLOBAL);
                break;
        }
    };
    Object.defineProperty(OmnibarSearchComponent.prototype, "value", {
        /** Gets/sets the value. */
        get: function () {
            return this._foundation.value;
        },
        set: function (value) {
            this._foundation.value = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarSearchComponent.prototype, "disabled", {
        /** Gets/sets the disabled state of the search input. */
        get: function () {
            return this._foundation.disabled;
        },
        set: function (value) {
            this._foundation.disabled = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarSearchComponent.prototype, "placeholder", {
        /** Sets the input placeholder value. */
        set: function (value) {
            this._foundation.placeholder = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarSearchComponent.prototype, "combined", {
        /** Gets/sets whether this is a combined search box with search options. */
        get: function () {
            return this._foundation.disabled;
        },
        set: function (value) {
            this._foundation.combined = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarSearchComponent.prototype, "combinedOptions", {
        /** Gets/sets seelcted option for the combined search dropdown. */
        get: function () {
            return this._foundation.combinedOptions;
        },
        set: function (value) {
            this._foundation.combinedOptions = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarSearchComponent.prototype, "selectedCombinedOption", {
        /** Gets/sets selected option for the combined search dropdown. */
        get: function () {
            return this._foundation.selectedCombinedOption;
        },
        set: function (value) {
            this._foundation.selectedCombinedOption = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarSearchComponent.prototype, "global", {
        /** Gets/sets whether the global icon is displayed at the end of the input. */
        get: function () {
            return this._foundation.global;
        },
        set: function (value) {
            this._foundation.global = value;
        },
        enumerable: true,
        configurable: true
    });
    OmnibarSearchComponent = __decorate([
        CustomElement({
            name: OMNIBAR_SEARCH_CONSTANTS.elementName,
            dependencies: [
                TooltipComponent
            ]
        })
    ], OmnibarSearchComponent);
    return OmnibarSearchComponent;
}(HTMLElement));
export { OmnibarSearchComponent };
