import { COMPONENT_NAME_PREFIX } from '../constants';
import { COLOR_CONSTANTS } from '../theme/color-constants';
var elementName = COMPONENT_NAME_PREFIX + "product-icon";
var classes = {
    PRODUCT_ICON_BACKGROUND: 'tyl-product-icon__background',
    PRODUCT_ICON: 'tyl-product-icon__icon'
};
var selectors = {
    PRODUCT_ICON_BACKGROUND: "." + classes.PRODUCT_ICON_BACKGROUND,
    PRODUCT_ICON: "." + classes.PRODUCT_ICON
};
var attributes = {
    COLOR: 'color',
    SIZE: 'size',
    SHADOW: 'shadow',
    ITERATIONS: 'iterations',
    MODE: 'mode'
};
var numbers = {
    DEFAULT_SIZE: 64,
    DEFAULT_ITERATIONS: 32,
    ACCESSIBILITY_COLOR_THRESHOLD: 300,
    ICON_FONT_SIZE_MODIFIER: 0.75,
    TEXT_FONT_SIZE_MODIFIER: 0.5
};
var strings = {
    DEFAULT_COLOR_VALUE: 'indigo-500',
    DEFAULT_COLOR_PALETTE: 'indigo',
    DEFAULT_COLOR_SHADE: 500,
    DEFAULT_BACKGROUND_COLOR: COLOR_CONSTANTS.indigoPalette[500],
    DEFAULT_MODE: 'icon'
};
export var PRODUCT_ICON_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    classes: classes,
    selectors: selectors,
    numbers: numbers,
    strings: strings
};
