import { COMPONENT_NAME_PREFIX } from '../../constants';
var elementName = COMPONENT_NAME_PREFIX + "list";
var attributes = {
    STATIC: 'static',
    DENSE: 'dense',
    PROPAGATE_CLICK: 'propagate-click',
    INDENTED: 'indented',
    SELECTED_VALUE: 'selected-value'
};
var selectors = {
    FOCUSABLE_LIST_ITEMS: '.tyl-list-item:not(.tyl-list-item--static):not(.tyl-list-item--disabled)'
};
export var LIST_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    selectors: selectors
};
