import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty } from '@tyler-components-web/core';
import { SelectAdapter } from './select-adapter';
import { SelectFoundation } from './select-foundation';
import { SELECT_CONSTANTS } from './select-constants';
import { OptionComponent } from '../option';
import { PopupComponent } from '../../popup';
import { ListComponent, ListItemComponent } from '../../list';
var template = require('./select.html');
var styles = require('./select.css');
/**
 * The custom element class behind the `<tcw-select>` component.
 */
var SelectComponent = /** @class */ (function (_super) {
    __extends(SelectComponent, _super);
    function SelectComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, SELECT_CONSTANTS.elementName, template, styles);
        _this._foundation = new SelectFoundation(new SelectAdapter(_this));
        return _this;
    }
    Object.defineProperty(SelectComponent, "observedAttributes", {
        get: function () {
            return [
                SELECT_CONSTANTS.attributes.LABEL,
                SELECT_CONSTANTS.attributes.MULTIPLE,
                SELECT_CONSTANTS.attributes.VALUE,
                SELECT_CONSTANTS.attributes.DISABLED,
                SELECT_CONSTANTS.attributes.INVALID,
                SELECT_CONSTANTS.attributes.REQUIRED,
                SELECT_CONSTANTS.attributes.DENSITY,
                SELECT_CONSTANTS.attributes.FLOAT_LABEL_TYPE,
                SELECT_CONSTANTS.attributes.PLACEHOLDER
            ];
        },
        enumerable: true,
        configurable: true
    });
    SelectComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'value');
        upgradeProperty(this, 'selectedIndex');
        upgradeProperty(this, 'options');
        upgradeProperty(this, 'label');
        upgradeProperty(this, 'multiple');
        upgradeProperty(this, 'open');
        upgradeProperty(this, 'disabled');
        upgradeProperty(this, 'invalid');
        upgradeProperty(this, 'required');
        upgradeProperty(this, 'density');
        upgradeProperty(this, 'popupElement');
        upgradeProperty(this, 'floatLabelType');
        upgradeProperty(this, 'placeholder');
    };
    SelectComponent.prototype.connectedCallback = function () {
        this.setAttribute('role', 'listbox');
        this.setAttribute('aria-live', 'polite');
        this.setAttribute('aria-activedescendant', 'null');
        this.setAttribute('aria-haspopup', 'true');
        this.setAttribute('aria-expanded', 'false');
        this._foundation.initialize();
    };
    SelectComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    SelectComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case SELECT_CONSTANTS.attributes.LABEL:
                this.label = newValue;
                break;
            case SELECT_CONSTANTS.attributes.MULTIPLE:
                this.multiple = this.hasAttribute(SELECT_CONSTANTS.attributes.MULTIPLE);
                break;
            case SELECT_CONSTANTS.attributes.VALUE:
                this.value = newValue;
                break;
            case SELECT_CONSTANTS.attributes.DISABLED:
                this.disabled = this.hasAttribute(SELECT_CONSTANTS.attributes.DISABLED);
                break;
            case SELECT_CONSTANTS.attributes.INVALID:
                this.invalid = this.hasAttribute(SELECT_CONSTANTS.attributes.INVALID);
                break;
            case SELECT_CONSTANTS.attributes.REQUIRED:
                this.required = this.hasAttribute(SELECT_CONSTANTS.attributes.REQUIRED);
                break;
            case SELECT_CONSTANTS.attributes.DENSITY:
                this.density = newValue;
                break;
            case SELECT_CONSTANTS.attributes.FLOAT_LABEL_TYPE:
                this.floatLabelType = newValue;
                break;
            case SELECT_CONSTANTS.attributes.PLACEHOLDER:
                this.placeholder = newValue;
                break;
        }
    };
    Object.defineProperty(SelectComponent.prototype, "label", {
        /** Gets/sets the label text. */
        get: function () {
            return this._foundation.label;
        },
        set: function (value) {
            this._foundation.label = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "value", {
        /** Gets/sets the value. */
        get: function () {
            return this._foundation.value;
        },
        set: function (value) {
            this._foundation.value = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "selectedIndex", {
        /** Gets/sets the selected index. */
        get: function () {
            return this._foundation.selectedIndex;
        },
        set: function (value) {
            this._foundation.selectedIndex = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "options", {
        /** Gets/sets the available options. */
        get: function () {
            return this._foundation.options;
        },
        set: function (value) {
            this._foundation.options = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "multiple", {
        /** Gets/sets the multiple select state. */
        get: function () {
            return this._foundation.multiple;
        },
        set: function (value) {
            this._foundation.multiple = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "open", {
        /** Gets the open state of the dropdown. */
        get: function () {
            return this._foundation.open;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "disabled", {
        /** Gets/sets the disabled state. */
        get: function () {
            return this._foundation.disabled;
        },
        set: function (value) {
            this._foundation.disabled = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "invalid", {
        /** Gets/sets the invalid state. */
        get: function () {
            return this._foundation.invalid;
        },
        set: function (value) {
            this._foundation.invalid = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "required", {
        /** Gets/sets the required state which controls the visibility of the asterisk in the label. */
        get: function () {
            return this._foundation.required;
        },
        set: function (value) {
            this._foundation.required = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "density", {
        /** Gets/sets the density type. */
        get: function () {
            return this._foundation.density;
        },
        set: function (value) {
            this._foundation.density = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "floatLabelType", {
        /** Gets/sets the floating label type. */
        get: function () {
            return this._foundation.floatLabelType;
        },
        set: function (value) {
            this._foundation.floatLabelType = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "placeholder", {
        /** Gets/sets the placeholder text. */
        get: function () {
            return this._foundation.placeholder;
        },
        set: function (value) {
            this._foundation.placeholder = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "popupElement", {
        /** Gets the popup element (when the dropdown is open). */
        get: function () {
            return this._foundation.popupElement;
        },
        enumerable: true,
        configurable: true
    });
    /** Sets focus to the internal button element. */
    SelectComponent.prototype.setFocus = function () {
        this._foundation.setFocus();
    };
    /** Sets focus to the internal button element. */
    SelectComponent.prototype.focus = function () {
        this._foundation.setFocus();
    };
    SelectComponent = __decorate([
        CustomElement({
            name: SELECT_CONSTANTS.elementName,
            dependencies: [
                OptionComponent,
                PopupComponent,
                ListComponent,
                ListItemComponent
            ]
        })
    ], SelectComponent);
    return SelectComponent;
}(HTMLElement));
export { SelectComponent };
