import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, coerceBoolean, upgradeProperty } from '@tyler-components-web/core';
import { BadgeAdapter } from './badge-adapter';
import { BadgeFoundation } from './badge-foundation';
import { BADGE_CONSTANTS } from './badge-constants';
var template = require('./badge.html');
var styles = require('./badge.css');
var BadgeComponent = /** @class */ (function (_super) {
    __extends(BadgeComponent, _super);
    function BadgeComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, BADGE_CONSTANTS.elementName, template, styles);
        _this._foundation = new BadgeFoundation(new BadgeAdapter(_this));
        return _this;
    }
    Object.defineProperty(BadgeComponent, "observedAttributes", {
        get: function () {
            return [
                BADGE_CONSTANTS.attributes.DOT,
                BADGE_CONSTANTS.attributes.OPEN
            ];
        },
        enumerable: true,
        configurable: true
    });
    BadgeComponent.prototype.connectedCallback = function () {
        upgradeProperty(this, 'dot');
        upgradeProperty(this, 'open');
        this._foundation.initialize();
    };
    BadgeComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case BADGE_CONSTANTS.attributes.DOT:
                this.dot = this.hasAttribute(BADGE_CONSTANTS.attributes.DOT);
                break;
            case BADGE_CONSTANTS.attributes.DOT:
                this.open = coerceBoolean(newValue);
                break;
        }
    };
    Object.defineProperty(BadgeComponent.prototype, "dot", {
        get: function () {
            return this._foundation.dot;
        },
        set: function (value) {
            this._foundation.dot = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BadgeComponent.prototype, "open", {
        get: function () {
            return this._foundation.open;
        },
        set: function (value) {
            this._foundation.open = value;
        },
        enumerable: true,
        configurable: true
    });
    BadgeComponent = __decorate([
        CustomElement({
            name: BADGE_CONSTANTS.elementName
        })
    ], BadgeComponent);
    return BadgeComponent;
}(HTMLElement));
export { BadgeComponent };
