import { emitEvent, removeAllChildren, addClass, removeClass } from '@tyler-components-web/core';
import { TableUtils } from './table-utils';
import { TABLE_CONSTANTS } from './table-constants';
/**
 * Provides facilities for interacting with the internal DOM of `TableComponent`.
 */
var TableAdapter = /** @class */ (function () {
    function TableAdapter(_component) {
        this._component = _component;
    }
    TableAdapter.prototype.initialize = function () {
        if (!this._tableElement) {
            this._tableElement = document.createElement('table');
            this._tableElement.classList.add(TABLE_CONSTANTS.classes.TABLE);
            if (this._component.children.length) {
                removeAllChildren(this._component);
            }
            this._component.appendChild(this._tableElement);
        }
    };
    TableAdapter.prototype.emitEvent = function (type, data) {
        emitEvent(this._component, type, data);
    };
    TableAdapter.prototype.setHostAttribute = function (name, value) {
        this._component.setAttribute(name, value);
    };
    TableAdapter.prototype.removeHostAttribute = function (name) {
        this._component.removeAttribute(name);
    };
    TableAdapter.prototype.getTableElement = function () {
        if (!this._tableElement) {
            this.initialize();
        }
        return this._tableElement;
    };
    TableAdapter.prototype.createTable = function (configuration) {
        return TableUtils.createTable(configuration);
    };
    TableAdapter.prototype.recreateTableBody = function (configuration) {
        TableUtils.recreateTableBody(configuration);
    };
    TableAdapter.prototype.setSelectedRows = function (tableElement, key, data, selectedRows, preserveExisting) {
        if (preserveExisting === void 0) { preserveExisting = false; }
        TableUtils.setSelectedRows(tableElement, key, data, selectedRows, preserveExisting);
    };
    TableAdapter.prototype.clearSelectedRows = function (tableElement) {
        TableUtils.clearSelectedRows(tableElement);
    };
    TableAdapter.prototype.updateSelectedState = function (rowElement, isSelected) {
        TableUtils.updateSelectedState(rowElement, isSelected);
    };
    TableAdapter.prototype.updateSelectAllState = function (tableElement, isAllSelected) {
        TableUtils.updateSelectAllState(tableElement, isAllSelected);
    };
    TableAdapter.prototype.setSortedColumn = function (tableElement, columnIndex, sortDirection) {
        TableUtils.setSortedColumn(tableElement, columnIndex, sortDirection);
    };
    TableAdapter.prototype.removeColumnSort = function (tableElement, columnIndex) {
        TableUtils.removeColumnSort(tableElement, columnIndex);
    };
    TableAdapter.prototype.setSortDirection = function (tableElement, columnIndex, sortDirection) {
        TableUtils.setSortDirection(tableElement, columnIndex, sortDirection);
    };
    TableAdapter.prototype.setSelectColumnVisibility = function (tableElement, isVisible, selectListener, selectDoubleListener, selectAllListener) {
        TableUtils.setSelectColumnVisibility(tableElement, isVisible, selectListener, selectDoubleListener, selectAllListener || null);
    };
    TableAdapter.prototype.setDense = function (tableElement, isDense) {
        TableUtils.setDenseState(tableElement, isDense);
    };
    TableAdapter.prototype.setResizable = function (configuration) {
        TableUtils.setResizable(configuration);
    };
    TableAdapter.prototype.setSelectAllVisibility = function (tableElement, isVisible, listener) {
        TableUtils.setSelectAllVisibility(tableElement, isVisible, listener);
    };
    TableAdapter.prototype.setFilterRow = function (configuration) {
        TableUtils.setFilterRow(configuration);
    };
    TableAdapter.prototype.expandRow = function (configuration, rowIndex, template) {
        return TableUtils.expandRow(configuration, rowIndex, template);
    };
    TableAdapter.prototype.collapseRow = function (configuration, rowIndex) {
        return TableUtils.collapseRow(configuration, rowIndex);
    };
    TableAdapter.prototype.isRowExpanded = function (configuration, rowIndex) {
        return TableUtils.isRowExpanded(configuration, rowIndex);
    };
    TableAdapter.prototype.setFixedHeaders = function (configuration) {
        TableUtils.setFixedHeaders(configuration);
    };
    TableAdapter.prototype.setTableLayoutType = function (configuration) {
        TableUtils.setLayoutType(configuration);
    };
    TableAdapter.prototype.setWrapContentState = function (configuration) {
        TableUtils.setWrapContentState(configuration.tableElement, configuration.wrapContent);
    };
    TableAdapter.prototype.addDocumentListener = function (type, listener) {
        TableUtils.getOwnerDocument(this._component).addEventListener(type, listener);
    };
    TableAdapter.prototype.removeDocumentListener = function (type, listener) {
        TableUtils.getOwnerDocument(this._component).removeEventListener(type, listener);
    };
    TableAdapter.prototype.normalizeColumnWidths = function (configuration) {
        TableUtils.normalizeColumnWidths(configuration);
    };
    TableAdapter.prototype.setResizeColumnVisibility = function (configuration, columnIndex, isVisible) {
        TableUtils.setColumnResizeIndicatorVisibility(configuration, columnIndex, isVisible);
    };
    TableAdapter.prototype.addTableClass = function (classes) {
        addClass(classes, this._tableElement);
    };
    TableAdapter.prototype.removeTableClass = function (classes) {
        removeClass(classes, this._tableElement);
    };
    return TableAdapter;
}());
export { TableAdapter };
