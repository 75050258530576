import { __decorate, __extends } from "tslib";
import { MDCRadio } from '@material/radio';
import { CustomElement, attachLightTemplate, ensureChildren, upgradeProperty } from '@tyler-components-web/core';
import { RADIO_CONSTANTS } from './radio-constants';
var template = require('./radio.html');
/**
 * The custom element class behind the `<tcw-radio>` element.
 */
var RadioComponent = /** @class */ (function (_super) {
    __extends(RadioComponent, _super);
    function RadioComponent() {
        var _this = _super.call(this) || this;
        /** Whether this is a dense radio button. */
        _this._dense = false;
        return _this;
    }
    Object.defineProperty(RadioComponent, "observedAttributes", {
        get: function () {
            return [
                RADIO_CONSTANTS.attributes.DENSE
            ];
        },
        enumerable: true,
        configurable: true
    });
    RadioComponent.prototype._ensureChildren = function (callback) {
        if (this.children.length) {
            callback();
        }
        else {
            // if we don't have a ensure children promise, create one.
            if (!this._ensureChildrenPromise) {
                this._ensureChildrenPromise = ensureChildren(this);
            }
            // chain the promise so that any callbacks occur in the order they are submitted
            this._ensureChildrenPromise = this._ensureChildrenPromise.then(function () { return callback(); });
        }
    };
    RadioComponent.prototype._initialize = function () {
        var inputElement = this.querySelector(RADIO_CONSTANTS.selectors.INPUT);
        if (!inputElement) {
            return;
        }
        this.classList.add(RADIO_CONSTANTS.classes.RADIO);
        attachLightTemplate(this, template);
        inputElement.classList.add(RADIO_CONSTANTS.classes.NATIVE_CONTROL);
    };
    RadioComponent.prototype.initializedCallback = function () {
        var _this = this;
        upgradeProperty(this, 'dense');
        this._ensureChildren(function () { return _this._initialize(); });
    };
    RadioComponent.prototype._connect = function () {
        var inputElement = this.querySelector(RADIO_CONSTANTS.selectors.INPUT);
        if (!inputElement) {
            return;
        }
        this.mdcRadio = new MDCRadio(this);
    };
    RadioComponent.prototype.connectedCallback = function () {
        var _this = this;
        this._ensureChildren(function () { return _this._connect(); });
    };
    RadioComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case RADIO_CONSTANTS.attributes.DENSE:
                this.dense = this.hasAttribute(RADIO_CONSTANTS.attributes.DENSE);
                break;
        }
    };
    RadioComponent.prototype.disconnectedCallback = function () {
        if (this.mdcRadio) {
            this.mdcRadio.destroy();
        }
    };
    Object.defineProperty(RadioComponent.prototype, "dense", {
        /** Gets/sets whether the radio button is dense. */
        get: function () {
            return this._dense;
        },
        set: function (value) {
            if (this._dense !== value) {
                this._dense = value;
                if (this._dense) {
                    this.setAttribute(RADIO_CONSTANTS.attributes.DENSE, '');
                    this.classList.add(RADIO_CONSTANTS.classes.RADIO_DENSE);
                }
                else {
                    this.removeAttribute(RADIO_CONSTANTS.attributes.DENSE);
                    this.classList.remove(RADIO_CONSTANTS.classes.RADIO_DENSE);
                }
                // re-layout the ripple for cases where dense was changed after initial layout
                if (this.mdcRadio) {
                    this.mdcRadio.ripple.layout();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    RadioComponent = __decorate([
        CustomElement({
            name: RADIO_CONSTANTS.elementName
        })
    ], RadioComponent);
    return RadioComponent;
}(HTMLElement));
export { RadioComponent };
