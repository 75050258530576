import { BADGE_CONSTANTS } from './badge-constants';
var BadgeFoundation = /** @class */ (function () {
    function BadgeFoundation(_adapter) {
        this._adapter = _adapter;
        this._dot = false;
        this._open = true;
    }
    BadgeFoundation.prototype.initialize = function () {
        this._applyDot();
        this._setOpen();
    };
    BadgeFoundation.prototype._applyDot = function () {
        if (this._dot) {
            this._adapter.setRootClass(BADGE_CONSTANTS.classes.DOT);
        }
        else {
            this._adapter.removeRootClass(BADGE_CONSTANTS.classes.DOT);
        }
    };
    BadgeFoundation.prototype._setOpen = function () {
        if (this._open) {
            this._adapter.setRootClass(BADGE_CONSTANTS.classes.OPEN);
        }
        else {
            this._adapter.removeRootClass(BADGE_CONSTANTS.classes.OPEN);
        }
    };
    Object.defineProperty(BadgeFoundation.prototype, "dot", {
        get: function () {
            return this._dot;
        },
        set: function (value) {
            if (this._dot !== value) {
                this._dot = value;
                this._applyDot();
                if (value) {
                    this._adapter.setHostAttribute(BADGE_CONSTANTS.attributes.DOT);
                }
                else {
                    this._adapter.removeHostAttribute(BADGE_CONSTANTS.attributes.DOT);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BadgeFoundation.prototype, "open", {
        get: function () {
            return this._open;
        },
        set: function (value) {
            if (this._open !== value) {
                this._open = value;
                this._setOpen();
                this._adapter.setHostAttribute(BADGE_CONSTANTS.attributes.OPEN);
            }
        },
        enumerable: true,
        configurable: true
    });
    return BadgeFoundation;
}());
export { BadgeFoundation };
