import { ItemManager, createPredicate, matchesPredicate, getEventPath } from '@tyler-components-web/core';
import { TABLE_CONSTANTS } from './table-constants';
import { SortDirection } from './types';
import { TableUtils } from './table-utils';
import { TableRow } from './table-row';
/**
 * The state class behind the `TableComponent` custom element.
 */
var TableFoundation = /** @class */ (function () {
    function TableFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        // Backing model values
        this._tableRows = [];
        this._data = [];
        this._columnConfigurations = [];
        this._select = false;
        this._multiselect = true;
        this._dense = false;
        this._filter = false;
        this._fixedHeaders = false;
        this._layoutType = TABLE_CONSTANTS.strings.DEFAULT_LAYOUT_TYPE;
        this._wrapContent = true;
        this._resizable = false;
        // State variables
        this._isAllSelected = false;
        this._sortedColumnIndex = -1;
        this._rendered = false;
        // Create all listeners
        this._selectRowListener = function (evt) { return _this._onRowSelected(evt); };
        this._selectDoubleRowListener = function (evt) { return _this._onRowSelectedDouble(evt); };
        this._selectAllListener = function (evt) { return _this._onSelectAll(evt); };
        this._headRowMouseDownListener = function (evt) { return _this._onHeadRowMouseDown(evt); };
        this._documentMouseMoveListener = function (evt) { return _this._onMouseMove(evt); };
        this._documentMouseUpListener = function (evt) { return _this._onMouseUp(evt); };
        this._filterListener = function (value, columnIndex) { return _this._onFilter(value, columnIndex); };
        // Create helpers
        this._selectionManager = new ItemManager(this._selectKey);
        this._hiddenColumnManager = new ItemManager();
    }
    /** Called when the component is connected to the DOM. */
    TableFoundation.prototype.initialize = function () {
        this.connect();
        this._adapter.setHostAttribute(TABLE_CONSTANTS.attributes.SELECT, this._select.toString());
        this._adapter.setHostAttribute(TABLE_CONSTANTS.attributes.MULTISELECT, this._multiselect.toString());
        this._adapter.setHostAttribute(TABLE_CONSTANTS.attributes.DENSE, this._dense.toString());
        this._adapter.setHostAttribute(TABLE_CONSTANTS.attributes.FILTER, this._filter.toString());
        this._adapter.emitEvent(TABLE_CONSTANTS.events.INITIALIZED);
    };
    TableFoundation.prototype.connect = function () {
        this._adapter.initialize();
    };
    TableFoundation.prototype.disconnect = function () {
        this._resizeDescriptor = undefined;
        this._adapter.removeDocumentListener('mousemove', this._documentMouseMoveListener);
        this._adapter.removeDocumentListener('mouseup', this._documentMouseUpListener);
    };
    Object.defineProperty(TableFoundation.prototype, "data", {
        get: function () {
            return this._data;
        },
        /** The data to be display in the table body. */
        set: function (value) {
            var _this = this;
            this._data = value || [];
            this._tableRows = value.map(function (data) { return new TableRow(data, _this._selectionManager.exists(data)); });
            if (this._rendered) {
                this._renderBody();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableFoundation.prototype, "columnConfigurations", {
        get: function () {
            return this._columnConfigurations;
        },
        /** The column configuration options. */
        set: function (value) {
            var _this = this;
            this._columnConfigurations = value;
            this._sortedColumnIndex = this._visibleColumnConfigurations.findIndex(function (c) { return c.initialSort === true; });
            this._sortDirection = this._sortedColumnIndex >= 0 ? this._visibleColumnConfigurations[this._sortedColumnIndex].sortDirection : undefined;
            // Update hidden column manager
            this._hiddenColumnManager.clear();
            this._columnConfigurations.forEach(function (cc) {
                if (cc.hidden) {
                    _this._hiddenColumnManager.add(cc);
                }
            });
            this.render();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableFoundation.prototype, "_visibleColumnConfigurations", {
        /** Gets the visible column configurations. */
        get: function () {
            var _this = this;
            return this._columnConfigurations.filter(function (cc) { return !_this._hiddenColumnManager.exists(cc); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableFoundation.prototype, "_tableConfiguration", {
        /** Gets the table configuration. */
        get: function () {
            return {
                tableElement: this._adapter.getTableElement(),
                columnConfigurations: this._visibleColumnConfigurations,
                data: this._tableRows,
                selectListener: this._select ? this._selectRowListener : null,
                selectDoubleListener: this._select ? this._selectDoubleRowListener : null,
                selectAllListener: this._multiselect ? this._selectAllListener : null,
                headRowMouseDownListener: this._headRowMouseDownListener,
                filterListener: this._filter ? this._filterListener : null,
                sortDirection: this._sortDirection,
                sortedColumn: this._sortedColumnIndex,
                filter: this._filter,
                wrapContent: this._wrapContent,
                fixedHeaders: this._fixedHeaders,
                layoutType: this._layoutType,
                dense: this._dense,
                resizable: this._resizable
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableFoundation.prototype, "select", {
        get: function () {
            return this._select;
        },
        /** Controls the visibility of the select column. */
        set: function (value) {
            if (this._select !== value) {
                var previousValue = this._select;
                this._select = value;
                // First we make sure to clear any existing selections and update row state if applicable
                if (!this._select && this._selectionManager.count() > 0) {
                    this._removeSelections();
                }
                // Now we can add/remove the column
                if ((previousValue && !this._select) || (!previousValue && this._select)) {
                    this._adapter.setSelectColumnVisibility(this._adapter.getTableElement(), this._select, this._selectRowListener, this._selectDoubleRowListener, this._multiselect ? this._selectAllListener : undefined);
                }
                // Update the attribute on the host element
                if (this._select) {
                    this._adapter.setHostAttribute(TABLE_CONSTANTS.attributes.SELECT, this._select.toString());
                }
                else {
                    this._adapter.removeHostAttribute(TABLE_CONSTANTS.attributes.SELECT);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableFoundation.prototype, "multiselect", {
        get: function () {
            return this._multiselect;
        },
        /** Controls the visibility of the select all checkbox. */
        set: function (value) {
            if (this._multiselect !== value) {
                this._multiselect = value;
                if (this._select) {
                    this._adapter.setSelectAllVisibility(this._adapter.getTableElement(), this._multiselect, this._multiselect ? this._selectAllListener : null);
                    if (!this._multiselect) {
                        this.clearSelections();
                    }
                    else {
                        this._updateSelections(true);
                    }
                    // Update the attribute on the host element
                    this._adapter.setHostAttribute(TABLE_CONSTANTS.attributes.MULTISELECT, this._multiselect.toString());
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableFoundation.prototype, "selectKey", {
        get: function () {
            return this._selectKey;
        },
        /** The row key for matching data to selections. */
        set: function (value) {
            if (this._originalSelectKey !== value) {
                this._originalSelectKey = value;
                this._selectKey = Array.isArray(value) ? value : [value];
                this._selectionManager.setKey(this._selectKey);
                this._updateSelections(false);
                // Update the attribute on the host element
                if (typeof this._originalSelectKey === 'string') {
                    this._adapter.setHostAttribute(TABLE_CONSTANTS.attributes.SELECT_KEY, this._originalSelectKey);
                }
                else {
                    this._adapter.removeHostAttribute(TABLE_CONSTANTS.attributes.SELECT_KEY);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableFoundation.prototype, "dense", {
        /** Controls whether the table is dense or not. False by default. */
        get: function () {
            return this._dense;
        },
        set: function (value) {
            if (this._dense !== value) {
                this._dense = value;
                this._adapter.setDense(this._adapter.getTableElement(), this._dense);
                this._adapter.setHostAttribute(TABLE_CONSTANTS.attributes.DENSE, this._dense.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableFoundation.prototype, "filter", {
        get: function () {
            return this._filter;
        },
        /** Controls whether the table filters are visible or not. */
        set: function (value) {
            if (this._filter !== value) {
                this._filter = value;
                this._adapter.setFilterRow(this._tableConfiguration);
                this._adapter.setHostAttribute(TABLE_CONSTANTS.attributes.FILTER, this._filter.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableFoundation.prototype, "fixedHeaders", {
        get: function () {
            return this._fixedHeaders;
        },
        /** Controls whether the table has fixed headers or not. */
        set: function (value) {
            if (this._fixedHeaders !== value) {
                this._fixedHeaders = value;
                this._adapter.setFixedHeaders(this._tableConfiguration);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableFoundation.prototype, "layoutType", {
        /** Controls the table layout algorithm */
        get: function () {
            return this._layoutType;
        },
        set: function (value) {
            if (this._layoutType !== value) {
                this._layoutType = value;
                this._adapter.setTableLayoutType(this._tableConfiguration);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableFoundation.prototype, "wrapContent", {
        /** Controls whether the content in each cell wraps or not */
        get: function () {
            return this._wrapContent;
        },
        set: function (value) {
            if (this._wrapContent !== value) {
                this._wrapContent = value;
                this._adapter.setWrapContentState(this._tableConfiguration);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableFoundation.prototype, "resizable", {
        /** Controls whether the columns are resizable or not. */
        get: function () {
            return this._resizable;
        },
        set: function (value) {
            if (this._resizable !== value) {
                this._resizable = value;
                this._adapter.setResizable(this._tableConfiguration);
            }
        },
        enumerable: true,
        configurable: true
    });
    /** Returns the selected row instances. */
    TableFoundation.prototype.getSelectedRows = function () {
        return this._selectionManager.getItems();
    };
    /**
     * Sets the selected items in the table.
     * @param {any[]} data The data to select.
     * @param {boolean} clearCurrentSelections True to clear the current selections before setting the new selections.
     */
    TableFoundation.prototype.selectRows = function (data, clearCurrentSelections) {
        if (!this._select) {
            return;
        }
        if (data.length > 1 && !this._multiselect) {
            throw new Error('Cannot select multiple rows when not in multiselect mode.');
        }
        if (!this._multiselect || clearCurrentSelections) {
            this.clearSelections();
        }
        if (data && data.length) {
            this._selectionManager.add(data);
            this._updateSelections(!clearCurrentSelections);
        }
    };
    /**
     * Deselects rows from in the table data.
     * @param {any[]} data The array of items to deselect.
     */
    TableFoundation.prototype.deselectRows = function (data) {
        if (!this._select) {
            return;
        }
        if (data && data.length) {
            this._selectionManager.remove(data);
            this._updateSelections(false);
        }
    };
    /**
     * Clears all selections from the table.
     */
    TableFoundation.prototype.clearSelections = function () {
        var tableElement = this._adapter.getTableElement();
        this._selectionManager.clear();
        this._tableRows.forEach(function (tableRow) { return tableRow.selected = false; });
        this._isAllSelected = false;
        this._adapter.clearSelectedRows(tableElement);
        if (this._multiselect) {
            this._adapter.updateSelectAllState(tableElement, false);
        }
    };
    /**
     * Hides a table column.
     * @param {number} columnIndex The index of the column to hide.
     */
    TableFoundation.prototype.hideColumn = function (columnIndex) {
        var columnConfig = this._columnConfigurations[columnIndex];
        if (this._sortedColumnIndex === columnIndex) {
            this._sortedColumnIndex = -1;
        }
        if (!this._hiddenColumnManager.exists(columnConfig)) {
            this._hiddenColumnManager.add(columnConfig);
            this.render();
        }
    };
    /**
     * Shows a column that is currently hidden.
     * @param {number} columnIndex The index of the column to show.
     */
    TableFoundation.prototype.showColumn = function (columnIndex) {
        var columnConfig = this._columnConfigurations[columnIndex];
        if (this._hiddenColumnManager.exists(columnConfig)) {
            this._hiddenColumnManager.remove(columnConfig);
            this.render();
        }
    };
    /**
     * Creates and renders the table with the current column configuration and data.
     */
    TableFoundation.prototype.render = function () {
        this._adapter.createTable(this._tableConfiguration);
        if (this.select) {
            this._isAllSelected = this._getSelectAllState();
            this._adapter.setSelectedRows(this._adapter.getTableElement(), this._selectKey, this._data, this._selectionManager.getItems(), false);
        }
        this._rendered = true;
    };
    /**
     * Renders the table body only.
     */
    TableFoundation.prototype._renderBody = function () {
        if (!this._rendered) {
            return;
        }
        this._adapter.recreateTableBody(this._tableConfiguration);
        if (this.select) {
            this._isAllSelected = this._getSelectAllState();
            this._adapter.setSelectedRows(this._adapter.getTableElement(), this._selectKey, this._data, this._selectionManager.getItems(), false);
        }
    };
    /**
     * Expands a collapsed row.
     * @param rowIndex The index of the row to expand.
     */
    TableFoundation.prototype.expandRow = function (rowIndex, template) {
        if (!this._rendered) {
            throw new Error('Cannot expand a row before the table has rendered.');
        }
        if (rowIndex < 0 || rowIndex > this._tableRows.length) {
            throw new Error("Invalid row index: " + rowIndex + ".");
        }
        return this._adapter.expandRow(this._tableConfiguration, rowIndex, template);
    };
    /**
     * Collapses an expanded row.
     * @param rowIndex The index of the row to expand.
     */
    TableFoundation.prototype.collapseRow = function (rowIndex) {
        if (!this._rendered) {
            return Promise.resolve();
        }
        if (rowIndex < 0 || rowIndex > this._tableRows.length) {
            return Promise.resolve();
        }
        return this._adapter.collapseRow(this._tableConfiguration, rowIndex);
    };
    /**
     * Check if a row is expanded or not.
     * @param rowIndex The row index to check.
     */
    TableFoundation.prototype.isRowExpanded = function (rowIndex) {
        return this._adapter.isRowExpanded(this._tableConfiguration, rowIndex);
    };
    /**
     * Determines if every row is selected or not.
     */
    TableFoundation.prototype._getSelectAllState = function () {
        return this._tableRows.length > 0 && this._tableRows.every(function (tr) { return tr.selected; });
    };
    /**
     * Updates the internal selection state as well as UI state.
     * @param {boolean} preserveExisting Should existing selections be preserved when updating selection state.
     */
    TableFoundation.prototype._updateSelections = function (preserveExisting) {
        var _this = this;
        if (!this._select) {
            return;
        }
        this._tableRows.forEach(function (tableRow) { return tableRow.selected = _this._selectionManager.exists(tableRow.data); });
        var isAllSelected = this._getSelectAllState();
        this._adapter.setSelectedRows(this._adapter.getTableElement(), this._selectKey, this._data, this._selectionManager.getItems(), preserveExisting);
        if (isAllSelected !== this._isAllSelected) {
            this._isAllSelected = isAllSelected;
            if (this._multiselect) {
                this._adapter.updateSelectAllState(this._adapter.getTableElement(), this._isAllSelected);
            }
        }
    };
    /**
     * Removes all selections from the table.
     */
    TableFoundation.prototype._removeSelections = function () {
        var tableElement = this._adapter.getTableElement();
        // Update our internal state
        this._isAllSelected = false;
        this._selectionManager.clear();
        this._tableRows.forEach(function (item) { return item.selected = false; });
        // Update the UI to reflect our new state
        this._adapter.clearSelectedRows(tableElement);
        this._adapter.updateSelectAllState(tableElement, false);
    };
    /**
     * Handles a row being selected/deselected.
     */
    TableFoundation.prototype._onRowSelected = function (evt) {
        evt.stopPropagation();
        var rowSelectedInfo = this._getSelectedRowFromEvent(evt);
        // Deselect the previously selected row when not in multiselect mode (only if it's different than the newly selected row)
        if (!this._multiselect) {
            var existingSelection = this._tableRows.find(function (item) { return item.selected; });
            if (existingSelection && !matchesPredicate(existingSelection.data, createPredicate(this._selectKey, rowSelectedInfo.data))) {
                this.deselectRows([existingSelection.data]);
            }
        }
        // Determine what the new selected state should be for this row (opposite of current state)
        var isSelected = !this._selectionManager.exists(rowSelectedInfo.data);
        // Update the table row based on the new selected state
        this._adapter.updateSelectedState(rowSelectedInfo.rowElement, isSelected);
        // Update the selection manager
        isSelected ? this._selectionManager.add(rowSelectedInfo.data) : this._selectionManager.remove(rowSelectedInfo.data);
        // Update the state for this row
        this._tableRows[rowSelectedInfo.index].selected = isSelected;
        // Emit a table row selection changed event
        var detail = {
            selected: isSelected,
            index: rowSelectedInfo.index,
            data: rowSelectedInfo.data
        };
        this._adapter.emitEvent(TABLE_CONSTANTS.events.SELECT, detail);
        // If we have multiselect on, then we need to update the select all state
        if (this._multiselect) {
            // Update the state of the select all checkbox
            var isAllSelected = this._getSelectAllState();
            this._adapter.updateSelectAllState(this._adapter.getTableElement(), isAllSelected);
            // If selecting/deselecting this row causes the select all state to be changed, then emit the select all changed event.
            if (this._isAllSelected !== isAllSelected) {
                this._isAllSelected = isAllSelected;
                this._adapter.emitEvent(TABLE_CONSTANTS.events.SELECT_ALL, { status: this._isAllSelected, count: this._tableRows.filter(function (row) { return row.selected; }).length });
            }
        }
    };
    /**
     * Handles a row being double clicked.
     */
    TableFoundation.prototype._onRowSelectedDouble = function (evt) {
        evt.stopPropagation();
        // Ignore if the element being clicked is the checkbox element
        if (evt.target.tagName === 'INPUT' && evt.target.type === 'checkbox') {
            return;
        }
        var rowSelectedInfo = this._getSelectedRowFromEvent(evt);
        var detail = {
            index: rowSelectedInfo.index,
            data: rowSelectedInfo.data
        };
        this._adapter.emitEvent(TABLE_CONSTANTS.events.SELECT_DOUBLE, detail);
    };
    /**
     * Determines which row was chosen from the given event.
     * @param {Event} evt The click event.
     */
    TableFoundation.prototype._getSelectedRowFromEvent = function (evt) {
        var composedPath = getEventPath(evt);
        var rowElement = composedPath.find(function (el) { return el.tagName === 'TR'; });
        var tableElement = this._adapter.getTableElement();
        var rowIndex = Array.from(tableElement.tBodies[0].rows).findIndex(function (el) { return el === rowElement; });
        var rowData = this._tableRows[rowIndex].data;
        return {
            rowElement: rowElement,
            index: rowIndex,
            data: rowData
        };
    };
    /**
     * Handles the select all checkbox being checked/unchecked.
     */
    TableFoundation.prototype._onSelectAll = function (evt) {
        var _this = this;
        evt.stopPropagation();
        // Toggle the status of our select all state
        this._isAllSelected = !this._isAllSelected;
        var tableElement = this._adapter.getTableElement();
        // Update the select all checkbox in the table based on the new state
        this._adapter.updateSelectAllState(tableElement, this._isAllSelected);
        // Select/deselect every row based on our new select all state
        this._tableRows.forEach(function (tableRow, index) {
            // Update the state for each row
            tableRow.selected = _this._isAllSelected;
            // Update the selection manager
            _this._isAllSelected ? _this._selectionManager.add(tableRow.data) : _this._selectionManager.remove(tableRow.data);
            // Update the table row selected state
            var rowElement = TableUtils.getRowByIndex(tableElement, index);
            if (rowElement) {
                _this._adapter.updateSelectedState(rowElement, _this._isAllSelected);
            }
        });
        // Emit the select all changed event
        this._adapter.emitEvent(TABLE_CONSTANTS.events.SELECT_ALL, { status: this._isAllSelected, count: this._tableRows.filter(function (row) { return row.selected; }).length });
    };
    TableFoundation.prototype._onHeadRowMouseDown = function (evt) {
        var composedPath = getEventPath(evt);
        var target = evt.target;
        // Find the row and cell elements that the event originated within
        var rowElement = composedPath.find(function (el) { return el.tagName === 'TR'; });
        var thElement = composedPath.find(function (el) { return el.tagName === 'TH'; });
        var cellIndex = Array.from(rowElement.cells).findIndex(function (c) { return c === thElement; });
        this._headCellMouseDownIndex = cellIndex;
        if (target !== thElement && target.classList.contains(TABLE_CONSTANTS.classes.TABLE_RESIZE_HANDLE)) {
            this._resizeDescriptor = {
                element: thElement,
                startWidth: thElement.offsetWidth,
                startX: evt.pageX
            };
            this._adapter.addDocumentListener('mousemove', this._documentMouseMoveListener);
            this._adapter.setResizeColumnVisibility(this._tableConfiguration, cellIndex, true);
        }
        this._adapter.addDocumentListener('mouseup', this._documentMouseUpListener);
    };
    TableFoundation.prototype._onMouseMove = function (evt) {
        if (this._resizeDescriptor) {
            var newWidth = this._resizeDescriptor.startWidth + (evt.pageX - this._resizeDescriptor.startX);
            this._resizeDescriptor.element.style.width = newWidth + "px";
            this._adapter.addTableClass(TABLE_CONSTANTS.classes.TABLE_RESIZING);
        }
    };
    TableFoundation.prototype._onMouseUp = function (evt) {
        this._adapter.removeDocumentListener('mousemove', this._documentMouseMoveListener);
        this._adapter.removeDocumentListener('mouseup', this._documentMouseUpListener);
        this._adapter.removeTableClass(TABLE_CONSTANTS.classes.TABLE_RESIZING);
        if (this._headCellMouseDownIndex === undefined) {
            return;
        }
        if (this._resizeDescriptor) {
            this._adapter.normalizeColumnWidths(this._tableConfiguration);
            this._adapter.setResizeColumnVisibility(this._tableConfiguration, this._headCellMouseDownIndex, false);
            this._resizeDescriptor = undefined;
        }
        else if (this._tableConfiguration.tableElement.tHead.contains(evt.target)) {
            this._onSort(this._headCellMouseDownIndex);
        }
        this._headCellMouseDownIndex = undefined;
    };
    /**
     * Called when a click event is triggered on the table header row.
     * We use this to capture all click events on the row, and determine which
     * header cell was clicked.
     */
    TableFoundation.prototype._onSort = function (cellIndex) {
        // Get the column index so we can access the config for that column (this depends on whether there is a select column or not)
        var columnIndex = this._select ? cellIndex - 1 : cellIndex;
        // Get the column config
        var columnConfig = columnIndex >= 0 ? this._visibleColumnConfigurations[columnIndex] : null;
        // If this column was not sortable, or was the select column then we just return
        if (!columnConfig || !columnConfig.sortable) {
            return;
        }
        // Toggle the sort direction class
        if (columnIndex === this._sortedColumnIndex) {
            // Toggle the sort direction
            if (this._sortDirection === SortDirection.Ascending) {
                this._sortDirection = SortDirection.Descending;
            }
            else {
                this._sortDirection = SortDirection.Ascending;
            }
            // Update the table with the new sort direction
            this._adapter.setSortDirection(this._adapter.getTableElement(), cellIndex, this._sortDirection);
        }
        else {
            // Always default to descending sort on a newly sorted column
            this._sortDirection = SortDirection.Descending;
            // If we have a column this is currently sorted, remove the sort from that column
            if (this._sortedColumnIndex >= 0) {
                this._adapter.removeColumnSort(this._adapter.getTableElement(), this._select ? this._sortedColumnIndex + 1 : this._sortedColumnIndex);
            }
            // Set this column as the sorted column
            this._sortedColumnIndex = columnIndex;
            // Set the new sort direction on the newly sorted column
            this._adapter.setSortedColumn(this._adapter.getTableElement(), cellIndex, this._sortDirection);
        }
        // Emit the sort event for this column
        var data = {
            direction: this._sortDirection,
            columnIndex: this._columnConfigurations.indexOf(columnConfig)
        };
        this._adapter.emitEvent(TABLE_CONSTANTS.events.SORT, data);
    };
    /**
     * Handles filter events and emits the filter information event.
     * @param evt The event.
     */
    TableFoundation.prototype._onFilter = function (value, columnIndex) {
        var data = { value: value, columnIndex: columnIndex };
        this._adapter.emitEvent(TABLE_CONSTANTS.events.FILTER, data);
    };
    return TableFoundation;
}());
export { TableFoundation };
