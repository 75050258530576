import { defineCustomElement } from '@tyler-components-web/core';
import { TableComponent } from './table';
export * from './table-adapter';
export * from './table-constants';
export * from './table-foundation';
export * from './table-row';
export * from './table';
export * from './types';
export function defineTableComponent() {
    defineCustomElement(TableComponent);
}
