import { isElement } from '@tyler-components-web/core';
import { POPUP_CONSTANTS as constants, PopupPlacement, POPUP_CONSTANTS, PopupAnimationType } from './popup-constants';
var PopupFoundation = /** @class */ (function () {
    function PopupFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._placement = PopupPlacement.BottomLeft;
        this._open = false;
        this._manageFocus = false;
        this._animationType = PopupAnimationType.None;
        this._static = false;
        this._blurListener = function () { return _this._onBlur(); };
    }
    PopupFoundation.prototype.disconnect = function () {
        if (this.open) {
            this.open = false;
        }
    };
    Object.defineProperty(PopupFoundation.prototype, "targetElement", {
        get: function () {
            return this._targetElement;
        },
        set: function (element) {
            if (!element || !isElement(element)) {
                throw new Error('targetElement on Popup component must be an HTMLElement');
            }
            this._targetElement = element;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupFoundation.prototype, "placement", {
        get: function () {
            return this._placement;
        },
        set: function (value) {
            if (this._placement !== value) {
                this._placement = value || PopupPlacement.BottomLeft;
                this._adapter.setAttribute(POPUP_CONSTANTS.attributes.PLACEMENT, this._placement);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupFoundation.prototype, "open", {
        get: function () {
            return this._open;
        },
        set: function (value) {
            var _this = this;
            value = Boolean(value);
            if (value !== this._open) {
                this._open = value;
                if (value) {
                    if (!this.targetElement || !isElement(this.targetElement)) {
                        throw new Error('targetElement on Popup component must be an HTMLElement');
                    }
                    if (this._openCallback) {
                        Promise.resolve(this._openCallback()).then(function () {
                            _this._openPopup();
                        }).catch(function () {
                            _this._open = false;
                        });
                    }
                    else {
                        this._openPopup();
                    }
                }
                else {
                    if (this._closeCallback) {
                        Promise.resolve(this._closeCallback()).then(function () {
                            _this._closePopup();
                        }).catch(function () {
                            _this._open = true;
                        });
                    }
                    else {
                        this._closePopup();
                    }
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupFoundation.prototype, "manageFocus", {
        get: function () {
            return this._manageFocus;
        },
        set: function (value) {
            if (this._manageFocus !== value) {
                this._manageFocus = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupFoundation.prototype, "animationType", {
        get: function () {
            return this._animationType;
        },
        set: function (value) {
            if (this._animationType !== value) {
                this._animationType = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupFoundation.prototype, "static", {
        get: function () {
            return this._static;
        },
        set: function (value) {
            if (this._static !== value) {
                this._static = value;
                if (!this._static && this._destroyBlurListener) {
                    this._destroyBlurListener();
                    this._destroyBlurListener = undefined;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    PopupFoundation.prototype.position = function () {
        this._adapter.positionPopup();
    };
    Object.defineProperty(PopupFoundation.prototype, "openCallback", {
        set: function (callback) {
            this._openCallback = callback;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupFoundation.prototype, "closeCallback", {
        set: function (callback) {
            this._closeCallback = callback;
        },
        enumerable: true,
        configurable: true
    });
    PopupFoundation.prototype._openPopup = function () {
        if (this._animationType !== PopupAnimationType.None) {
            this._applyOpenAnimation();
            switch (this._animationType) {
                case PopupAnimationType.Dropdown:
                    this._adapter.addClass(POPUP_CONSTANTS.classes.SELECT);
                    break;
            }
        }
        else {
            this._adapter.addClass(POPUP_CONSTANTS.classes.OPEN);
        }
        this._adapter.setAttribute(POPUP_CONSTANTS.attributes.OPEN, '');
        this._adapter.addPopup(this.targetElement, this._manageFocus);
        this._adapter.trySetInitialFocus();
        if (this._destroyBlurListener) {
            this._destroyBlurListener();
        }
        if (!this._static) {
            this._destroyBlurListener = this._adapter.setBlurListener(this._blurListener);
        }
        this._adapter.manageWindowEvents(true);
        this._adapter.dispatchEvent(constants.events.OPEN);
    };
    PopupFoundation.prototype._closePopup = function () {
        if (this._destroyBlurListener) {
            this._destroyBlurListener();
            this._destroyBlurListener = undefined;
        }
        if (this._animationType !== PopupAnimationType.None) {
            this._applyCloseAnimation();
        }
        else {
            this._destroyPopup();
        }
    };
    PopupFoundation.prototype._destroyPopup = function () {
        this._adapter.manageWindowEvents(false);
        this._adapter.removePopup(this._manageFocus);
        this._adapter.dispatchEvent(constants.events.CLOSE);
        this._adapter.removeAttribute(POPUP_CONSTANTS.attributes.OPEN);
    };
    PopupFoundation.prototype._applyOpenAnimation = function () {
        var _this = this;
        switch (this._animationType) {
            case PopupAnimationType.Dropdown:
            case PopupAnimationType.Menu:
                this._adapter.addClass(POPUP_CONSTANTS.classes.OPENING);
                var transitionEndListener_1 = function (evt) {
                    if (evt.propertyName === 'transform') {
                        _this._adapter.removeClass(POPUP_CONSTANTS.classes.OPENING);
                        _this._adapter.removeEventListener('transitionend', transitionEndListener_1);
                    }
                };
                this._adapter.setAnimationEndListener(transitionEndListener_1, POPUP_CONSTANTS.classes.OPEN);
                break;
        }
    };
    PopupFoundation.prototype._applyCloseAnimation = function () {
        var _this = this;
        switch (this._animationType) {
            case PopupAnimationType.Dropdown:
            case PopupAnimationType.Menu:
                this._adapter.removeClass(POPUP_CONSTANTS.classes.OPEN);
                this._adapter.addClass(POPUP_CONSTANTS.classes.CLOSED);
                var transitionEndListener = function (evt) {
                    if (evt.propertyName === 'opacity') {
                        if (fallbackTimer_1) {
                            clearTimeout(fallbackTimer_1);
                        }
                        _this._destroyPopup();
                    }
                };
                this._adapter.setAnimationEndListener(transitionEndListener);
                var fallbackTimer_1 = setTimeout(function () { return _this._destroyPopup(); }, POPUP_CONSTANTS.numbers.ANIMATION_DURATION);
                break;
        }
    };
    PopupFoundation.prototype._onBlur = function () {
        var cancelled = this._adapter.dispatchEvent(POPUP_CONSTANTS.events.BLUR, undefined, true, true);
        if (!cancelled) {
            this.open = false;
        }
    };
    return PopupFoundation;
}());
export { PopupFoundation };
