/**
 * @license @tylertech/tyler-components-web
 * Copyright (C) 2019 Tyler Technologies, Inc.
 */
import { defineCustomElements } from '@tyler-components-web/core';
import { AccordionComponent } from './accordion';
import { AutocompleteComponent } from './autocomplete';
import { BackdropComponent } from './backdrop';
import { BusyIndicatorComponent } from './busy-indicator';
import { ButtonComponent } from './button';
import { CheckboxComponent } from './checkbox';
import { ChipComponent, ChipSetComponent } from './chips';
import { DatepickerComponent } from './datepicker';
import { DialogComponent } from './dialog';
import { DividerComponent } from './divider';
import { DrawerComponent } from './drawer';
import { ExpansionPanelComponent } from './expansion-panel';
import { IconButtonComponent } from './icon-button';
import { LinearProgressComponent } from './linear-progress';
import { ListComponent } from './list';
import { ListItemComponent } from './list/list-item';
import { MenuComponent } from './menu';
import { AppLauncherComponent, OmnibarComponent, OmnibarSearchComponent, OmnibarMenuButtonComponent, OmnibarAppLauncherButtonComponent, OmnibarNotificationButtonComponent, OmnibarHelpButtonComponent, ProfileCardComponent, OmnibarProfileButtonComponent } from './omni';
import { OpenIconComponent } from './open-icon';
import { PaginatorComponent } from './paginator';
import { PopupComponent } from './popup';
import { ProgressSpinnerComponent } from './progress-spinner';
import { RadioComponent } from './radio';
import { ScaffoldComponent } from './scaffold';
import { SelectComponent } from './select';
import { OptionComponent } from './select';
import { StepperComponent } from './stepper';
import { SwitchComponent } from './switch';
import { TabBarComponent } from './tab-bar';
import { TableComponent } from './table';
import { TextFieldComponent } from './text-field';
import { ToastComponent } from './toast';
import { TooltipComponent } from './tooltip';
import { FloatingActionButton } from './floating-action-button';
import { SliderComponent } from './slider';
import { CardComponent } from './card';
import { AvatarComponent } from './avatar';
import { ViewSwitcherComponent, ViewComponent } from './view-switcher';
import { ProductIconComponent } from './product-icon';
import { ColorPickerComponent } from './color-picker';
import { LabelValueComponent } from './label-value';
import { ToolbarComponent } from './toolbar';
import { PageStateComponent } from './page-state';
import { BadgeComponent } from './badge';
import { SkeletonComponent } from './skeleton';
import { ButtonToggleComponent, ButtonToggleGroupComponent } from './button-toggle';
export * from './accordion';
export * from './autocomplete';
export * from './avatar';
export * from './backdrop';
export * from './badge';
export * from './busy-indicator';
export * from './button';
export * from './button-toggle';
export * from './checkbox';
export * from './chips';
export * from './color-picker';
export * from './core';
export * from './datepicker';
export * from './divider';
export * from './drawer';
export * from './expansion-panel';
export * from './icon-button';
export * from './linear-progress';
export * from './list';
export * from './menu';
export * from './omni';
export * from './open-icon';
export * from './paginator';
export * from './popup';
export * from './progress-spinner';
export * from './radio';
export * from './scaffold';
export * from './select';
export * from './stepper';
export * from './switch';
export * from './tab-bar';
export * from './table';
export * from './text-field';
export * from './theme';
export * from './toast';
export * from './tooltip';
export * from './dialog';
export * from './floating-action-button';
export * from './slider';
export * from './card';
export * from './utils';
export * from './view-switcher';
export * from './product-icon';
export * from './label-value';
export * from './toolbar';
export * from './page-state';
export * from './skeleton';
export { VERSION } from './constants';
var CUSTOM_ELEMENTS = [
    AccordionComponent,
    AvatarComponent,
    BackdropComponent,
    BadgeComponent,
    BusyIndicatorComponent,
    ButtonComponent,
    ButtonToggleComponent,
    ButtonToggleGroupComponent,
    CardComponent,
    CheckboxComponent,
    ChipComponent,
    ChipSetComponent,
    ColorPickerComponent,
    DatepickerComponent,
    DialogComponent,
    DividerComponent,
    DrawerComponent,
    ExpansionPanelComponent,
    FloatingActionButton,
    ProductIconComponent,
    IconButtonComponent,
    LabelValueComponent,
    LinearProgressComponent,
    ListComponent,
    ListItemComponent,
    MenuComponent,
    AppLauncherComponent,
    OmnibarComponent,
    OmnibarSearchComponent,
    OmnibarMenuButtonComponent,
    OmnibarAppLauncherButtonComponent,
    OmnibarNotificationButtonComponent,
    OmnibarHelpButtonComponent,
    OmnibarProfileButtonComponent,
    ProfileCardComponent,
    OpenIconComponent,
    OptionComponent,
    PageStateComponent,
    PaginatorComponent,
    PopupComponent,
    ProgressSpinnerComponent,
    RadioComponent,
    ScaffoldComponent,
    SelectComponent,
    SkeletonComponent,
    SliderComponent,
    StepperComponent,
    SwitchComponent,
    TabBarComponent,
    TableComponent,
    TextFieldComponent,
    ToastComponent,
    ToolbarComponent,
    TooltipComponent,
    ViewSwitcherComponent,
    ViewComponent,
    AutocompleteComponent // TODO(kieran.nichols): Causing issue when specified above datepicker. Naming conflict maybe?
];
/**
 * Registers all components in the library with the browser.
 */
export function defineComponents() {
    defineCustomElements(CUSTOM_ELEMENTS);
}
