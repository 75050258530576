import { __extends } from "tslib";
import { isDefined } from '@tyler-components-web/core';
import { AbstractComponentDelegate } from '../core/delegates/abstract-component-delegate';
import { SELECT_CONSTANTS } from './select/select-constants';
var SelectComponentDelegate = /** @class */ (function (_super) {
    __extends(SelectComponentDelegate, _super);
    function SelectComponentDelegate(config) {
        return _super.call(this, config) || this;
    }
    SelectComponentDelegate.prototype.build = function () {
        return document.createElement(SELECT_CONSTANTS.elementName);
    };
    SelectComponentDelegate.prototype.configure = function () {
        if (isDefined(this._config.placeholder)) {
            this._component.placeholder = this._config.placeholder;
        }
        if (isDefined(this._config.multiple)) {
            this._component.multiple = this._config.multiple;
        }
        if (isDefined(this._config.options)) {
            this._component.options = this._config.options;
        }
        if (isDefined(this._config.required)) {
            this._component.required = this._config.required;
        }
        if (isDefined(this._config.invalid)) {
            this._component.invalid = this._config.invalid;
        }
        if (isDefined(this._config.label) && this._config.label) {
            this._component.label = this._config.label;
        }
        if (isDefined(this._config.floatLabelType)) {
            this._component.floatLabelType = this._config.floatLabelType;
        }
        if (isDefined(this._config.helperText) && this._config.helperText) {
            this.setHelperText(this._config.helperText);
        }
    };
    SelectComponentDelegate.prototype.getValue = function () {
        return this._component.value;
    };
    SelectComponentDelegate.prototype.setValue = function (value) {
        this._component.value = value;
    };
    SelectComponentDelegate.prototype.onChange = function (listener) {
        this._component.addEventListener('change', function (evt) { return listener(evt.target.value); });
    };
    SelectComponentDelegate.prototype.onFocus = function (listener) {
        this._component.addEventListener('focus', function (evt) { return listener(evt); });
    };
    SelectComponentDelegate.prototype.onBlur = function (listener) {
        this._component.addEventListener('blur', function (evt) { return listener(evt); });
    };
    SelectComponentDelegate.prototype.setDisabled = function (value) {
        this._component.disabled = value;
    };
    SelectComponentDelegate.prototype.setValidity = function (value) {
        this._component.invalid = !value;
    };
    SelectComponentDelegate.prototype.validate = function () {
        return this._component.multiple ? !!this._component.value.length : !!this._component.value;
    };
    SelectComponentDelegate.prototype.setHelperText = function (text) {
        if (text) {
            if (!this._helperText) {
                this._helperText = document.createElement('span');
                this._helperText.slot = 'helper-text';
                this._component.appendChild(this._helperText);
            }
            this._helperText.textContent = text;
        }
        else if (this._helperText && this._helperText.isConnected) {
            this._component.removeChild(this._helperText);
            this._helperText = undefined;
        }
    };
    return SelectComponentDelegate;
}(AbstractComponentDelegate));
export { SelectComponentDelegate };
