import { getShadowElement, removeAllChildren, emitEvent, Platform, removeElement } from '@tyler-components-web/core';
import { MDCRipple } from '@material/ripple';
import { VIEW_SWITCHER_CONSTANTS } from '../../view-switcher';
import { PRODUCT_ICON_CONSTANTS } from '../../product-icon';
import { APP_LAUNCHER_CONSTANTS } from './app-launcher-constants';
var AppLauncherAdapter = /** @class */ (function () {
    function AppLauncherAdapter(_component) {
        this._component = _component;
        this._rootElement = getShadowElement(this._component, APP_LAUNCHER_CONSTANTS.selectors.ROOT);
        this._viewSwitcher = getShadowElement(this._component, VIEW_SWITCHER_CONSTANTS.elementName);
        this._optionsContainer = getShadowElement(this._component, APP_LAUNCHER_CONSTANTS.selectors.OPTIONS_CONTAINER);
        this._inputElement = getShadowElement(this._component, APP_LAUNCHER_CONSTANTS.selectors.SEARCH_INPUT);
        this._titleElement = getShadowElement(this._component, APP_LAUNCHER_CONSTANTS.selectors.TITLE);
        this._searchContainer = getShadowElement(this._component, APP_LAUNCHER_CONSTANTS.selectors.SEARCH_CONTAINER);
    }
    AppLauncherAdapter.prototype.setView = function (view) {
        this._viewSwitcher.index = view;
    };
    AppLauncherAdapter.prototype.setOptions = function (options, listener) {
        this._setOptions(options, this._optionsContainer, listener);
    };
    AppLauncherAdapter.prototype._setOptions = function (options, container, listener) {
        var gridElement = container.querySelector(APP_LAUNCHER_CONSTANTS.selectors.GRID);
        removeAllChildren(gridElement);
        var rowNum = 1;
        for (var _i = 0, options_1 = options; _i < options_1.length; _i++) {
            var option = options_1[_i];
            var element = this._createOption(option, listener);
            gridElement.appendChild(element);
            // Add support for grid in IE by setting the column and row positions
            if (Platform.TRIDENT) {
                var index = options.indexOf(option);
                var column = (index + 1) % 4 || 4;
                element.style.setProperty('-ms-grid-column', column.toString());
                element.style.setProperty('-ms-grid-row', rowNum.toString());
                if (column % 4 === 0) {
                    rowNum++;
                }
            }
        }
    };
    AppLauncherAdapter.prototype._createOption = function (option, listener) {
        var container = document.createElement('div');
        container.classList.add(APP_LAUNCHER_CONSTANTS.classes.GRID_ITEM);
        container.tabIndex = 0;
        container.addEventListener('click', function () { return listener(option); });
        container.addEventListener('keydown', function (evt) {
            if (evt.key === 'Enter' || evt.keyCode === 13) {
                listener(option);
            }
        });
        MDCRipple.attachTo(container);
        var iconElement;
        if (option.icon && (option.icon.name || option.icon.uri)) {
            var type = option.icon.type || '';
            switch (type.toLowerCase()) {
                case 'svg':
                case 'image':
                    iconElement = this._createImageIcon(option.icon.uri);
                    break;
                case 'font':
                default:
                    iconElement = this._createFontIcon(option.icon.name, option.icon.color);
                    break;
            }
        }
        else if (option.iconURI) {
            if (option.iconURI.lastIndexOf('.') >= 0) {
                iconElement = this._createImageIcon(option.iconURI);
            }
            else {
                iconElement = this._createFontIcon(option.iconURI);
            }
        }
        else {
            iconElement = this._createFontIcon();
        }
        container.appendChild(iconElement);
        var label = document.createElement('div');
        label.classList.add(APP_LAUNCHER_CONSTANTS.classes.GRID_ITEM_TITLE);
        label.textContent = option.label;
        container.appendChild(label);
        return container;
    };
    AppLauncherAdapter.prototype._createFontIcon = function (name, color) {
        var productIcon = document.createElement(PRODUCT_ICON_CONSTANTS.elementName);
        productIcon.size = 48;
        productIcon.textContent = name || APP_LAUNCHER_CONSTANTS.strings.DEFAULT_FONT_ICON_NAME;
        if (color) {
            productIcon.color = color;
        }
        return productIcon;
    };
    AppLauncherAdapter.prototype._createImageIcon = function (uri) {
        var img = document.createElement('img');
        img.src = uri;
        return img;
    };
    AppLauncherAdapter.prototype.emitEvent = function (type, data, bubble, cancelable) {
        if (bubble === void 0) { bubble = true; }
        if (cancelable === void 0) { cancelable = true; }
        return emitEvent(this._component, type, data, bubble, cancelable);
    };
    AppLauncherAdapter.prototype.focusFirstOption = function () {
        if (this._inputElement.isConnected) {
            this._inputElement.focus();
        }
        else {
            var gridItems = Array.from(this._rootElement.querySelectorAll(APP_LAUNCHER_CONSTANTS.selectors.GRID_ITEM));
            if (gridItems && gridItems.length) {
                gridItems[0].focus();
            }
        }
    };
    AppLauncherAdapter.prototype.addInputListener = function (type, listener) {
        this._inputElement.addEventListener(type, listener);
    };
    AppLauncherAdapter.prototype.removeInputListener = function (type, listener) {
        this._inputElement.removeEventListener(type, listener);
    };
    AppLauncherAdapter.prototype.setTitle = function (value) {
        this._titleElement.textContent = value;
    };
    AppLauncherAdapter.prototype.hideSearch = function () {
        removeElement(this._searchContainer);
    };
    return AppLauncherAdapter;
}());
export { AppLauncherAdapter };
