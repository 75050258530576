import { MDCRipple } from '@material/ripple';
import { getShadowElement, addClass, removeClass, emitEvent } from '@tyler-components-web/core';
import { LIST_ITEM_CONSTANTS } from './list-item-constants';
var ListItemAdapter = /** @class */ (function () {
    function ListItemAdapter(_component) {
        this._component = _component;
        this._initialize();
    }
    ListItemAdapter.prototype._initialize = function () {
        this._listItemElement = getShadowElement(this._component, LIST_ITEM_CONSTANTS.selectors.LIST_ITEM);
        this._defaultSlot = getShadowElement(this._component, LIST_ITEM_CONSTANTS.selectors.DEFAULT_SLOT);
    };
    ListItemAdapter.prototype.getListItem = function () {
        return this._component;
    };
    /**
     * Sets an attribute value on the `<tcw-list-item>` host element.
     * @param {string} name The attribute name.
     * @param {string} [value=''] The attribute value.
     */
    ListItemAdapter.prototype.setHostAttribute = function (name, value) {
        if (value === void 0) { value = ''; }
        this._component.setAttribute(name, value);
    };
    /**
     * Removes an attribute from the `<tcw-list-item>` host element.
     * @param {string} name The attribute name.
     */
    ListItemAdapter.prototype.removeHostAttribute = function (name) {
        this._component.removeAttribute(name);
    };
    /**
     * Emits an event from the `<tcw-list-item>` host element.
     * @param {string} natypeme The attribute name.
     * @param {object} [data={}] The attribute value.
     */
    ListItemAdapter.prototype.emitEvent = function (type, data) {
        if (data === void 0) { data = {}; }
        emitEvent(this._component, type, data, true);
    };
    /**
     * Adds an event listener to the `<tcw-list-item>` host element.
     * @param {string} type The event type.
     * @param {Function} listener The event callback.
     */
    ListItemAdapter.prototype.addListener = function (type, listener, options) {
        this._listItemElement.addEventListener(type, listener, options);
    };
    /**
     * Removes an event listener from the `<tcw-list-item>` host element.
     * @param {string} type The event type.
     * @param {Function} listener The event callback.
     */
    ListItemAdapter.prototype.removeListener = function (type, listener) {
        this._listItemElement.removeEventListener(type, listener);
    };
    /**
     * Creates a ripple instance on the list item.
     * @returns {MDCRipple}
     */
    ListItemAdapter.prototype.createRipple = function () {
        return new MDCRipple(this._listItemElement);
    };
    /**
     * Toggles the static state of this list item.
     * @param {boolean} value The static state.
     */
    ListItemAdapter.prototype.setStatic = function (value) {
        if (value) {
            this._listItemElement.tabIndex = -1;
            addClass(LIST_ITEM_CONSTANTS.classes.STATIC, this._listItemElement);
        }
        else {
            this._listItemElement.tabIndex = 0;
            removeClass(LIST_ITEM_CONSTANTS.classes.STATIC, this._listItemElement);
        }
    };
    /**
     * Sets this list item as a two-line list item.
     * @param {boolean} value The two-line state.
     */
    ListItemAdapter.prototype.setTwoLine = function (value) {
        if (value) {
            addClass(LIST_ITEM_CONSTANTS.classes.TWO_LINE, this._listItemElement);
        }
        else {
            removeClass(LIST_ITEM_CONSTANTS.classes.TWO_LINE, this._listItemElement);
        }
    };
    /**
     * Sets this list item as a three-line list item.
     * @param {boolean} value The three-line state.
     */
    ListItemAdapter.prototype.setThreeLine = function (value) {
        if (value) {
            addClass(LIST_ITEM_CONSTANTS.classes.THREE_LINE, this._listItemElement);
        }
        else {
            removeClass(LIST_ITEM_CONSTANTS.classes.THREE_LINE, this._listItemElement);
        }
    };
    /**
     * Determines how many lines the list item is displaying.
     */
    ListItemAdapter.prototype.getLineCount = function () {
        return this._defaultSlot.assignedNodes().filter(function (e) { return e.nodeType === Node.ELEMENT_NODE; }).length; // assignedElements does not exist in the polyfilled version so filtering nodes instead
    };
    /**
     * Toggles the active class of the list item.
     * @param {boolean} value The active state.
     */
    ListItemAdapter.prototype.setActive = function (value) {
        if (value) {
            addClass(LIST_ITEM_CONSTANTS.classes.ACTIVE, this._listItemElement);
        }
        else {
            removeClass(LIST_ITEM_CONSTANTS.classes.ACTIVE, this._listItemElement);
        }
    };
    /**
     * Toggles the selected class of the list item.
     * @param {boolean} value The active state.
     */
    ListItemAdapter.prototype.setSelected = function (value) {
        if (value) {
            addClass(LIST_ITEM_CONSTANTS.classes.SELECTED, this._listItemElement);
        }
        else {
            removeClass(LIST_ITEM_CONSTANTS.classes.SELECTED, this._listItemElement);
        }
    };
    /**
     * Attemps to toggle a checkbox or radio button within the list item if it can find one.
     */
    ListItemAdapter.prototype.tryToggleCheckboxRadio = function () {
        var checkable = this._component.querySelector(LIST_ITEM_CONSTANTS.selectors.CHECKBOX_RADIO_SELECTOR);
        if (checkable) {
            checkable.checked = !checkable.checked;
        }
    };
    /**
     * Attempts to set focus to this list item.
     */
    ListItemAdapter.prototype.setFocus = function () {
        this._listItemElement.focus();
    };
    /**
     * Sets the role on the list item element.
     * @param role The role.
     */
    ListItemAdapter.prototype.setRole = function (role) {
        this._listItemElement.setAttribute('role', role);
    };
    ListItemAdapter.prototype.setDisabled = function (disabled) {
        if (disabled) {
            this._listItemElement.classList.add(LIST_ITEM_CONSTANTS.classes.DISABLED);
        }
        else {
            this._listItemElement.classList.remove(LIST_ITEM_CONSTANTS.classes.DISABLED);
        }
    };
    ListItemAdapter.prototype.setDense = function (dense) {
        if (dense) {
            this._listItemElement.classList.add(LIST_ITEM_CONSTANTS.classes.DENSE);
        }
        else {
            this._listItemElement.classList.remove(LIST_ITEM_CONSTANTS.classes.DENSE);
        }
    };
    ListItemAdapter.prototype.setIndented = function (indented) {
        if (indented) {
            this._listItemElement.classList.add(LIST_ITEM_CONSTANTS.classes.INDENTED);
        }
        else {
            this._listItemElement.classList.remove(LIST_ITEM_CONSTANTS.classes.INDENTED);
        }
    };
    return ListItemAdapter;
}());
export { ListItemAdapter };
