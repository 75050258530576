import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "checkbox";
var classes = {
    CHECKBOX: 'mdc-checkbox',
    NATIVE_CONTROL: 'mdc-checkbox__native-control',
    CHECKBOX_DENSE: 'tyl-checkbox--dense'
};
var selectors = {
    INPUT: 'input'
};
var attributes = {
    DENSE: 'dense'
};
export var CHECKBOX_CONSTANTS = {
    elementName: elementName,
    classes: classes,
    selectors: selectors,
    attributes: attributes
};
