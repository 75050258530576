import { MDCFloatingLabel } from '@material/floating-label';
import { MDCNotchedOutline } from '@material/notched-outline';
import { getActiveElement } from 'shadow-utils';
import { getShadowElement, listenOwnProperty, removeClass, addClass, calculateFontWidth } from '@tyler-components-web/core';
import { TEXT_FIELD_CONSTANTS } from './text-field-constants';
var TextFieldAdapter = /** @class */ (function () {
    function TextFieldAdapter(_component) {
        this._component = _component;
    }
    TextFieldAdapter.prototype.initialize = function () {
        this._rootElement = getShadowElement(this._component, TEXT_FIELD_CONSTANTS.selectors.ROOT);
        this._labelSlot = getShadowElement(this._component, 'slot[name=label]');
        this._leadingSlot = getShadowElement(this._component, 'slot[name=leading]');
        this._trailingSlot = getShadowElement(this._component, 'slot[name=trailing]');
        this._inputElement = this._component.querySelector('input, textarea');
        this._notchedOutlineElement = getShadowElement(this._component, TEXT_FIELD_CONSTANTS.selectors.NOTCHED_OUTLINE);
        this.detectLabel();
    };
    TextFieldAdapter.prototype.destroy = function () {
        if (this._inputMutationObserver) {
            this._inputMutationObserver.disconnect();
        }
    };
    TextFieldAdapter.prototype.detectTextarea = function () {
        if (this._inputElement instanceof HTMLTextAreaElement) {
            this._rootElement.classList.add('tyl-text-field--textarea');
        }
        else {
            this._rootElement.classList.remove('tyl-text-field--textarea');
        }
    };
    TextFieldAdapter.prototype.hasLabel = function () {
        return !!this._labelElement;
    };
    TextFieldAdapter.prototype.ensureSlottedLabel = function () {
        this._labelElement.slot = 'label';
    };
    TextFieldAdapter.prototype.setHostAttribute = function (name, value) {
        if (value === void 0) { value = ''; }
        this._component.setAttribute(name, value);
    };
    TextFieldAdapter.prototype.removeHostAttribute = function (name) {
        this._component.removeAttribute(name);
    };
    TextFieldAdapter.prototype.ensureLabelOrder = function () {
        if (this._labelElement) {
            var children = Array.from(this._component.children);
            if (children.length > 1 && children.indexOf(this._labelElement) < children.indexOf(this._inputElement)) {
                this._component.appendChild(this._labelElement);
            }
        }
    };
    TextFieldAdapter.prototype.initializeAccessibility = function () {
        if (this._labelElement) {
            // Due to the order of the input and label elements in the DOM we need to manually associate the input
            // with the label using the aria-owns attribute and pass it the same id value as the existing "for" attribute
            if (this._labelElement.hasAttribute('for')) {
                this._labelElement.setAttribute('aria-owns', this._labelElement.getAttribute('for'));
            }
        }
    };
    TextFieldAdapter.prototype.addLabelSlotListener = function (listener) {
        this._labelSlot.addEventListener('slotchange', listener);
    };
    TextFieldAdapter.prototype.removeLabelSlotListener = function (listener) {
        if (this._labelSlot) {
            this._labelSlot.removeEventListener('slotchange', listener);
        }
    };
    TextFieldAdapter.prototype.addLeadingSlotListener = function (listener) {
        this._leadingSlot.addEventListener('slotchange', listener);
    };
    TextFieldAdapter.prototype.removeLeadingSlotListener = function (listener) {
        if (this._leadingSlot) {
            this._leadingSlot.removeEventListener('slotchange', listener);
        }
    };
    TextFieldAdapter.prototype.addTrailingSlotListener = function (listener) {
        this._trailingSlot.addEventListener('slotchange', listener);
    };
    TextFieldAdapter.prototype.removeTrailingSlotListener = function (listener) {
        if (this._trailingSlot) {
            this._trailingSlot.removeEventListener('slotchange', listener);
        }
    };
    TextFieldAdapter.prototype.addInputListener = function (type, listener) {
        this._inputElement.addEventListener(type, listener);
    };
    TextFieldAdapter.prototype.removeInputListener = function (type, listener) {
        if (this._inputElement) {
            this._inputElement.removeEventListener(type, listener);
        }
    };
    TextFieldAdapter.prototype.setValueChangedListener = function (context, listener) {
        if (this._inputElement.hasOwnProperty('value')) {
            return;
        }
        listenOwnProperty(context, this._inputElement, 'value', listener);
    };
    TextFieldAdapter.prototype.detectLabel = function () {
        this._labelElement = this._component.querySelector('label');
    };
    TextFieldAdapter.prototype.initializeFloatingLabel = function () {
        this._labelElement.classList.add(TEXT_FIELD_CONSTANTS.classes.MDC_FLOATING_LABEL);
        return new MDCFloatingLabel(this._labelElement);
    };
    TextFieldAdapter.prototype.initializeNotchedOutline = function () {
        return new MDCNotchedOutline(this._notchedOutlineElement);
    };
    TextFieldAdapter.prototype.hasValue = function () {
        return this._inputElement && this._inputElement.value ? this._inputElement.value.trim().length > 0 : false;
    };
    TextFieldAdapter.prototype.hasPlaceholder = function () {
        return this._inputElement && this._inputElement.placeholder ? this._inputElement.placeholder.trim().length > 0 : false;
    };
    TextFieldAdapter.prototype.hasFocus = function () {
        return this._inputElement ? getActiveElement() === this._inputElement : false;
    };
    TextFieldAdapter.prototype.setLabelClass = function (name) {
        if (this._labelElement) {
            this._labelElement.classList.add(name);
        }
    };
    TextFieldAdapter.prototype.removeLabelClass = function (name) {
        if (this._labelElement) {
            this._labelElement.classList.remove(name);
        }
    };
    TextFieldAdapter.prototype.setRoomy = function (isRoomy) {
        if (isRoomy) {
            this._rootElement.classList.add(TEXT_FIELD_CONSTANTS.classes.ROOMY);
        }
        else {
            this._rootElement.classList.remove(TEXT_FIELD_CONSTANTS.classes.ROOMY);
        }
    };
    TextFieldAdapter.prototype.setDense = function (isDense) {
        if (isDense) {
            this._rootElement.classList.add(TEXT_FIELD_CONSTANTS.classes.DENSE);
        }
        else {
            this._rootElement.classList.remove(TEXT_FIELD_CONSTANTS.classes.DENSE);
        }
    };
    TextFieldAdapter.prototype.hasLeadingNodes = function () {
        if (!this._leadingSlot) {
            return false;
        }
        return this._leadingSlot.assignedNodes().length > 0;
    };
    TextFieldAdapter.prototype.hasTrailingNodes = function () {
        if (!this._trailingSlot) {
            return false;
        }
        return this._trailingSlot.assignedNodes().length > 0;
    };
    TextFieldAdapter.prototype.addClass = function (classes) {
        addClass(classes, this._rootElement);
    };
    TextFieldAdapter.prototype.removeClass = function (classes) {
        removeClass(classes, this._rootElement);
    };
    TextFieldAdapter.prototype.setInputClass = function (className) {
        this._inputElement.classList.add(className);
    };
    TextFieldAdapter.prototype.removeInputClass = function (className) {
        this._inputElement.classList.remove(className);
    };
    TextFieldAdapter.prototype.setRootClass = function (classes) {
        addClass(classes, this._rootElement);
    };
    TextFieldAdapter.prototype.removeRootClass = function (classes) {
        removeClass(classes, this._rootElement);
    };
    TextFieldAdapter.prototype.setInputAttributeObserver = function (listener) {
        var _this = this;
        this._inputMutationObserver = new MutationObserver(function (mutations) {
            for (var _i = 0, mutations_1 = mutations; _i < mutations_1.length; _i++) {
                var mutation = mutations_1[_i];
                if (mutation.attributeName) {
                    listener(mutation.attributeName, _this._inputElement.getAttribute(mutation.attributeName));
                }
            }
        });
        this._inputMutationObserver.observe(this._inputElement, { attributes: true, attributeFilter: ['disabled', 'readonly', 'value'] });
    };
    TextFieldAdapter.prototype.isDisabled = function () {
        return this._inputElement.hasAttribute('disabled');
    };
    TextFieldAdapter.prototype.isReadonly = function () {
        return this._inputElement.hasAttribute('readonly');
    };
    TextFieldAdapter.prototype.getLabelWidth = function (fontSize, fontFamily) {
        return calculateFontWidth(this._labelElement.innerText, { fontSize: fontSize, fontFamily: fontFamily });
    };
    TextFieldAdapter.prototype.getLabelFontMetrics = function () {
        var style = getComputedStyle(this._labelElement);
        return {
            fontSize: parseInt(style.fontSize || '16', 10),
            fontFamily: style.fontFamily || 'Roboto'
        };
    };
    return TextFieldAdapter;
}());
export { TextFieldAdapter };
