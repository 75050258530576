import { getShadowElement, emitEvent, toggleElementPlaceholder, removeClass, removeElement, isString, removeAllChildren } from '@tyler-components-web/core';
import { PopupPlacement } from '../popup';
import { TOAST_CONSTANTS } from './toast-constants';
/**
 * The DOM adapter for the `ToastComponent` web component.
 */
var ToastAdapter = /** @class */ (function () {
    function ToastAdapter(_component) {
        this._component = _component;
        this._containerElement = getShadowElement(_component, TOAST_CONSTANTS.selectors.CONTAINER);
        this._messageElement = getShadowElement(_component, TOAST_CONSTANTS.selectors.MESSAGE);
        this._actionButtonElement = getShadowElement(_component, TOAST_CONSTANTS.selectors.ACTION_BUTTON);
        this._closeButtonElement = getShadowElement(_component, TOAST_CONSTANTS.selectors.CLOSE_BUTTON);
    }
    /**
     * Sets an attrinbute on the host element.
     * @param name The attribute name.
     * @param value The attribute value.
     */
    ToastAdapter.prototype.setHostAttribute = function (name, value) {
        if (this._component.getAttribute(name) !== value) {
            this._component.setAttribute(name, value);
        }
    };
    /**
     * Retrieves an attribute value from the host element.
     * @param name The attribute name.
     */
    ToastAdapter.prototype.getHostAttribute = function (name) {
        return this._component.getAttribute(name);
    };
    /**
     * Emits an event from the toast host element.
     * @param type The event type.
     * @param data The event data.
     */
    ToastAdapter.prototype.emitEvent = function (type, data) {
        if (data === void 0) { data = null; }
        emitEvent(this._component, type, data);
    };
    /**
     * Sets the message text on the toast element.
     * @param value The message text.
     */
    ToastAdapter.prototype.setMessage = function (value) {
        this._messageElement.innerText = value;
    };
    /**
     * Sets a custom template on the message element.
     * @param tpl The message template.
     */
    ToastAdapter.prototype.setMessageTemplate = function (tpl) {
        this._containerElement.classList.add(TOAST_CONSTANTS.classes.CUSTOM);
        if (isString(tpl)) {
            this._component.innerHTML = tpl;
        }
        else {
            removeAllChildren(this._messageElement);
            this._component.appendChild(tpl);
        }
    };
    /**
     * Toggles the visibility of the action button element.
     * @param isVisible The visibility.
     */
    ToastAdapter.prototype.setActionVisibility = function (isVisible) {
        this._actionButtonPlaceholder = toggleElementPlaceholder(this._component, isVisible, TOAST_CONSTANTS.elementName, TOAST_CONSTANTS.selectors.ACTION_BUTTON, this._actionButtonElement, this._actionButtonPlaceholder);
    };
    /**
     * Sets the action button text.
     * @param message The message text.
     */
    ToastAdapter.prototype.setActionText = function (message) {
        this._actionButtonElement.innerText = message;
    };
    /**
     * Adds an event listener to the action button element.
     * @param type The event type.
     * @param listener The event listener.
     */
    ToastAdapter.prototype.registerActionListener = function (type, listener) {
        this._actionButtonElement.addEventListener(type, listener);
    };
    /**
     * Removes an event listener from the action button.
     * @param type The event type.
     * @param listener The event listener.
     */
    ToastAdapter.prototype.deregisterActionListener = function (type, listener) {
        this._actionButtonElement.removeEventListener(type, listener);
    };
    /**
     * Adjusts the class applied to the toast container to position it properly.
     * @param placement The screen placement position.
     */
    ToastAdapter.prototype.setPlacement = function (placement) {
        removeClass([
            TOAST_CONSTANTS.classes.TOP,
            TOAST_CONSTANTS.classes.TOP_LEFT,
            TOAST_CONSTANTS.classes.TOP_RIGHT,
            TOAST_CONSTANTS.classes.BOTTOM,
            TOAST_CONSTANTS.classes.BOTTOM_LEFT,
            TOAST_CONSTANTS.classes.BOTTOM_RIGHT
        ], this._containerElement);
        var placementClass = this._getPlacementClass(placement);
        if (placementClass) {
            this._containerElement.classList.add(placementClass);
        }
    };
    /**
     * Triggers the addition of classes to the container element to hide or show the toast using CSS transitions.
     * @param isActive Active or not.
     */
    ToastAdapter.prototype.setActive = function (isActive) {
        var _this = this;
        if (isActive) {
            // We need to force a reflow here to make sure that our new transform gets applied by the active class
            window.getComputedStyle(this._containerElement).getPropertyValue('bottom');
            this._containerElement.classList.add(TOAST_CONSTANTS.classes.ACTIVE);
        }
        else {
            this._containerElement.classList.remove(TOAST_CONSTANTS.classes.ACTIVE);
            var animationCompletedListener_1 = function () {
                _this._containerElement.removeEventListener('transitionend', animationCompletedListener_1);
                removeElement(_this._component);
            };
            this._containerElement.addEventListener('transitionend', animationCompletedListener_1);
        }
    };
    ToastAdapter.prototype.setCloseButtonVisibility = function (visible) {
        visible ? this._closeButtonElement.style.removeProperty('display') : this._closeButtonElement.style.display = 'none';
    };
    ToastAdapter.prototype.registerCloseListener = function (listener) {
        this._closeButtonElement.addEventListener('click', listener);
    };
    ToastAdapter.prototype._getPlacementClass = function (placement) {
        switch (placement) {
            case PopupPlacement.Top:
                return TOAST_CONSTANTS.classes.TOP;
            case PopupPlacement.TopLeft:
                return TOAST_CONSTANTS.classes.TOP_LEFT;
            case PopupPlacement.TopRight:
                return TOAST_CONSTANTS.classes.TOP_RIGHT;
            case PopupPlacement.Bottom:
                return TOAST_CONSTANTS.classes.BOTTOM;
            case PopupPlacement.BottomLeft:
                return TOAST_CONSTANTS.classes.BOTTOM_LEFT;
            case PopupPlacement.BottomRight:
                return TOAST_CONSTANTS.classes.BOTTOM_RIGHT;
        }
        return null;
    };
    return ToastAdapter;
}());
export { ToastAdapter };
