import { __decorate, __extends } from "tslib";
import { CustomElement, coerceBoolean, attachLightTemplate } from '@tyler-components-web/core';
import { ChipAdapter } from './chip-adapter';
import { ChipFoundation } from './chip-foundation';
import { CHIP_CONSTANTS } from './chip-constants';
import { CHIP_SET_CONSTANTS } from '../chip-set';
var template = require('./chip.html');
/**
 * The custom element class behind the `<tcw-chip>` component.
 */
var ChipComponent = /** @class */ (function (_super) {
    __extends(ChipComponent, _super);
    function ChipComponent() {
        var _this = _super.call(this) || this;
        _this._delete = false;
        _this._selected = false;
        _this._dense = false;
        return _this;
    }
    Object.defineProperty(ChipComponent, "observedAttributes", {
        get: function () {
            return [
                CHIP_CONSTANTS.attributes.TYPE,
                CHIP_CONSTANTS.attributes.ICON,
                CHIP_CONSTANTS.attributes.TEXT,
                CHIP_CONSTANTS.attributes.DELETE,
                CHIP_CONSTANTS.attributes.SELECTED,
                CHIP_CONSTANTS.attributes.VALUE,
                CHIP_CONSTANTS.attributes.DENSE
            ];
        },
        enumerable: true,
        configurable: true
    });
    ChipComponent.prototype.initializedCallback = function () {
        // Attempt to determine the type from the chip set parent element
        var chipSet = this.parentElement;
        if (chipSet) {
            this._type = chipSet.type || chipSet.getAttribute(CHIP_SET_CONSTANTS.attributes.TYPE);
        }
        // Create the chip template
        attachLightTemplate(this, template);
        // Create the foundation and set default state
        this._foundation = new ChipFoundation(new ChipAdapter(this));
        this._foundation.type = this._type;
        this._foundation.icon = this._icon;
        this._foundation.text = this._text;
        this._foundation.delete = this._delete;
        this._foundation.selected = this._selected;
        this._foundation.value = this._value;
        this._foundation.dense = this._dense;
        this._foundation.initialize();
    };
    ChipComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    ChipComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case CHIP_CONSTANTS.attributes.TYPE:
                this.type = newValue;
                break;
            case CHIP_CONSTANTS.attributes.ICON:
                this.icon = newValue;
                break;
            case CHIP_CONSTANTS.attributes.TEXT:
                this.text = newValue;
                break;
            case CHIP_CONSTANTS.attributes.DELETE:
                this.delete = coerceBoolean(newValue);
                break;
            case CHIP_CONSTANTS.attributes.SELECTED:
                this.selected = coerceBoolean(newValue);
                break;
            case CHIP_CONSTANTS.attributes.VALUE:
                this.value = newValue;
                break;
            case CHIP_CONSTANTS.attributes.DENSE:
                this.dense = this.hasAttribute(CHIP_CONSTANTS.attributes.DENSE);
                break;
        }
    };
    Object.defineProperty(ChipComponent.prototype, "type", {
        /** Gets/sets the chip type. */
        get: function () {
            return this._foundation.type;
        },
        set: function (value) {
            this._type = value;
            if (this._foundation) {
                this._foundation.type = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipComponent.prototype, "icon", {
        /** Gets/sets the leading icon. */
        get: function () {
            return this._foundation.icon;
        },
        set: function (value) {
            this._icon = value;
            if (this._foundation) {
                this._foundation.icon = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipComponent.prototype, "text", {
        /** Gets/sets the chip text. */
        get: function () {
            return this._foundation.text;
        },
        set: function (value) {
            this._text = value;
            if (this._foundation) {
                this._foundation.text = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipComponent.prototype, "delete", {
        /** Controls the visibility of the "delete" button icon at the end of the chip. */
        get: function () {
            return this._foundation.delete;
        },
        set: function (value) {
            this._delete = value;
            if (this._foundation) {
                this._foundation.delete = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipComponent.prototype, "selected", {
        /** Gets/sets the selected state of the chip. */
        get: function () {
            return this._foundation.selected;
        },
        set: function (value) {
            this._selected = value;
            if (this._foundation) {
                this._foundation.selected = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipComponent.prototype, "value", {
        /** Gets/sets the chip value. */
        get: function () {
            return this._value;
        },
        set: function (value) {
            this._value = value;
            if (this._foundation) {
                this._foundation.value = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipComponent.prototype, "dense", {
        /** Gets/sets the chip as dense. */
        get: function () {
            return this._dense;
        },
        set: function (value) {
            this._dense = value;
            if (this._foundation) {
                this._foundation.dense = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    ChipComponent = __decorate([
        CustomElement({
            name: CHIP_CONSTANTS.elementName
        })
    ], ChipComponent);
    return ChipComponent;
}(HTMLElement));
export { ChipComponent };
