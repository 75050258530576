import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty, coerceBoolean, getShadowElement } from '@tyler-components-web/core';
import { ButtonToggleAdapter } from './button-toggle-adapter';
import { ButtonToggleFoundation } from './button-toggle-foundation';
import { BUTTON_TOGGLE_CONSTANTS } from './button-toggle-constants';
var template = require('./button-toggle.html');
var styles = require('./button-toggle.css');
var ButtonToggleComponent = /** @class */ (function (_super) {
    __extends(ButtonToggleComponent, _super);
    function ButtonToggleComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, BUTTON_TOGGLE_CONSTANTS.elementName, template, styles);
        _this._foundation = new ButtonToggleFoundation(new ButtonToggleAdapter(_this));
        _this._buttonElement = getShadowElement(_this, BUTTON_TOGGLE_CONSTANTS.selectors.BUTTON);
        return _this;
    }
    Object.defineProperty(ButtonToggleComponent, "observedAttributes", {
        get: function () {
            return [
                BUTTON_TOGGLE_CONSTANTS.attributes.VALUE,
                BUTTON_TOGGLE_CONSTANTS.attributes.SELECTED,
                BUTTON_TOGGLE_CONSTANTS.attributes.DISABLED,
                BUTTON_TOGGLE_CONSTANTS.attributes.DENSE,
                'aria-label'
            ];
        },
        enumerable: true,
        configurable: true
    });
    ButtonToggleComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'value');
        upgradeProperty(this, 'selected');
        upgradeProperty(this, 'disabled');
        upgradeProperty(this, 'dense');
        this._foundation.initialize();
    };
    ButtonToggleComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    ButtonToggleComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case BUTTON_TOGGLE_CONSTANTS.attributes.VALUE:
                this.value = newValue;
                break;
            case BUTTON_TOGGLE_CONSTANTS.attributes.SELECTED:
                this.selected = coerceBoolean(newValue);
                break;
            case BUTTON_TOGGLE_CONSTANTS.attributes.DISABLED:
                this.disabled = coerceBoolean(newValue);
                break;
            case BUTTON_TOGGLE_CONSTANTS.attributes.DENSE:
                this.dense = coerceBoolean(newValue);
                break;
            case 'aria-label':
                this._buttonElement.setAttribute('aria-label', newValue);
                break;
        }
    };
    Object.defineProperty(ButtonToggleComponent.prototype, "value", {
        get: function () {
            return this._foundation.value;
        },
        set: function (value) {
            this._foundation.value = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleComponent.prototype, "selected", {
        get: function () {
            return this._foundation.selected;
        },
        set: function (value) {
            this._foundation.selected = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleComponent.prototype, "disabled", {
        get: function () {
            return this._foundation.disabled;
        },
        set: function (value) {
            this._foundation.disabled = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleComponent.prototype, "dense", {
        get: function () {
            return this._foundation.dense;
        },
        set: function (value) {
            this._foundation.dense = value;
        },
        enumerable: true,
        configurable: true
    });
    ButtonToggleComponent.prototype.setFocus = function () {
        this._foundation.setFocus();
    };
    ButtonToggleComponent = __decorate([
        CustomElement({
            name: BUTTON_TOGGLE_CONSTANTS.elementName
        })
    ], ButtonToggleComponent);
    return ButtonToggleComponent;
}(HTMLElement));
export { ButtonToggleComponent };
