import { isDefined } from '@tyler-components-web/core';
import { LABEL_VALUE_CONSTANTS } from './label-value-constants';
var LabelValueFoundation = /** @class */ (function () {
    function LabelValueFoundation(_adapter) {
        this._adapter = _adapter;
        this._empty = false;
        this._ellipsis = false;
        this._density = 'default';
        this._align = 'left';
    }
    LabelValueFoundation.prototype.initialize = function () {
        this._adapter.setEmpty(this._empty);
        this._adapter.setEllipsis(this._ellipsis);
        this._applyDensity();
        this._adapter.setAlignment(this._align);
    };
    LabelValueFoundation.prototype._applyDensity = function () {
        this._adapter.setRoomy(this._density === 'roomy');
        this._adapter.setDense(this._density === 'dense');
    };
    Object.defineProperty(LabelValueFoundation.prototype, "empty", {
        get: function () {
            return this._empty;
        },
        set: function (value) {
            if (this._empty !== value) {
                this._empty = value;
                this._adapter.setEmpty(this._empty);
                if (this._empty) {
                    this._adapter.setHostAttribute(LABEL_VALUE_CONSTANTS.attributes.EMPTY, '');
                }
                else {
                    this._adapter.removeHostAttribute(LABEL_VALUE_CONSTANTS.attributes.EMPTY);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LabelValueFoundation.prototype, "ellipsis", {
        get: function () {
            return this._ellipsis;
        },
        set: function (value) {
            if (this._ellipsis !== value) {
                this._ellipsis = value;
                this._adapter.setEllipsis(this._ellipsis);
                if (this._ellipsis) {
                    this._adapter.setHostAttribute(LABEL_VALUE_CONSTANTS.attributes.ELLIPSIS, '');
                }
                else {
                    this._adapter.removeHostAttribute(LABEL_VALUE_CONSTANTS.attributes.ELLIPSIS);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LabelValueFoundation.prototype, "density", {
        get: function () {
            return this._density;
        },
        set: function (value) {
            if (this._density !== value) {
                this._density = value;
                this._applyDensity();
                this._adapter.setHostAttribute(LABEL_VALUE_CONSTANTS.attributes.DENSITY, isDefined(this._density) ? this._density.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LabelValueFoundation.prototype, "align", {
        get: function () {
            return this._align;
        },
        set: function (value) {
            if (this._align !== value) {
                this._align = value;
                this._adapter.setAlignment(this._align);
                this._adapter.setHostAttribute(LABEL_VALUE_CONSTANTS.attributes.ALIGN, this._align);
            }
        },
        enumerable: true,
        configurable: true
    });
    return LabelValueFoundation;
}());
export { LabelValueFoundation };
