import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty } from '@tyler-components-web/core';
import { LabelValueAdapter } from './label-value-adapter';
import { LabelValueFoundation } from './label-value-foundation';
import { LABEL_VALUE_CONSTANTS } from './label-value-constants';
var template = require('./label-value.html');
var styles = require('./label-value.css');
var LabelValueComponent = /** @class */ (function (_super) {
    __extends(LabelValueComponent, _super);
    function LabelValueComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, LABEL_VALUE_CONSTANTS.elementName, template, styles);
        _this._foundation = new LabelValueFoundation(new LabelValueAdapter(_this));
        return _this;
    }
    Object.defineProperty(LabelValueComponent, "observedAttributes", {
        get: function () {
            return [
                LABEL_VALUE_CONSTANTS.attributes.EMPTY,
                LABEL_VALUE_CONSTANTS.attributes.ELLIPSIS,
                LABEL_VALUE_CONSTANTS.attributes.DENSITY,
                LABEL_VALUE_CONSTANTS.attributes.ALIGN
            ];
        },
        enumerable: true,
        configurable: true
    });
    LabelValueComponent.prototype.connectedCallback = function () {
        upgradeProperty(this, 'empty');
        upgradeProperty(this, 'ellipsis');
        upgradeProperty(this, 'density');
        upgradeProperty(this, 'align');
        this._foundation.initialize();
    };
    LabelValueComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case LABEL_VALUE_CONSTANTS.attributes.EMPTY:
                this.empty = this.hasAttribute(LABEL_VALUE_CONSTANTS.attributes.EMPTY);
                break;
            case LABEL_VALUE_CONSTANTS.attributes.ELLIPSIS:
                this.ellipsis = this.hasAttribute(LABEL_VALUE_CONSTANTS.attributes.ELLIPSIS);
                break;
            case LABEL_VALUE_CONSTANTS.attributes.DENSITY:
                this.density = newValue;
                break;
            case LABEL_VALUE_CONSTANTS.attributes.ALIGN:
                this.align = newValue;
                break;
        }
    };
    Object.defineProperty(LabelValueComponent.prototype, "empty", {
        /** Gets/sets the empty state. */
        get: function () {
            return this._foundation.empty;
        },
        set: function (value) {
            this._foundation.empty = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LabelValueComponent.prototype, "ellipsis", {
        /** Gets/sets the wrap-content attribute. */
        get: function () {
            return this._foundation.ellipsis;
        },
        set: function (value) {
            this._foundation.ellipsis = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LabelValueComponent.prototype, "density", {
        /** Gets/sets the density state. */
        get: function () {
            return this._foundation.density;
        },
        set: function (value) {
            this._foundation.density = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LabelValueComponent.prototype, "align", {
        /** Gets/sets the alignment. */
        get: function () {
            return this._foundation.align;
        },
        set: function (value) {
            this._foundation.align = value;
        },
        enumerable: true,
        configurable: true
    });
    LabelValueComponent = __decorate([
        CustomElement({
            name: LABEL_VALUE_CONSTANTS.elementName
        })
    ], LabelValueComponent);
    return LabelValueComponent;
}(HTMLElement));
export { LabelValueComponent };
