import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty, getShadowElement } from '@tyler-components-web/core';
import { TOOLBAR_CONSTANTS } from './toolbar-constants';
var template = require('./toolbar.html');
var styles = require('./toolbar.css');
var ToolbarComponent = /** @class */ (function (_super) {
    __extends(ToolbarComponent, _super);
    function ToolbarComponent() {
        var _this = _super.call(this) || this;
        _this._inverted = false;
        attachShadowTemplate(_this, TOOLBAR_CONSTANTS.elementName, template, styles);
        _this._rootElement = getShadowElement(_this, TOOLBAR_CONSTANTS.selectors.TOOLBAR);
        return _this;
    }
    Object.defineProperty(ToolbarComponent, "observedAttributes", {
        get: function () {
            return [
                TOOLBAR_CONSTANTS.attributes.INVERTED
            ];
        },
        enumerable: true,
        configurable: true
    });
    ToolbarComponent.prototype.connectedCallback = function () {
        upgradeProperty(this, 'inverted');
        this._initialize();
    };
    ToolbarComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case TOOLBAR_CONSTANTS.attributes.INVERTED:
                this.inverted = this.hasAttribute(TOOLBAR_CONSTANTS.attributes.INVERTED);
                break;
        }
    };
    ToolbarComponent.prototype._initialize = function () {
        this._setInverted(this._inverted);
    };
    ToolbarComponent.prototype._setInverted = function (isInverted) {
        if (isInverted) {
            this._rootElement.classList.add(TOOLBAR_CONSTANTS.classes.INVERTED);
        }
        else {
            this._rootElement.classList.remove(TOOLBAR_CONSTANTS.classes.INVERTED);
        }
    };
    Object.defineProperty(ToolbarComponent.prototype, "inverted", {
        get: function () {
            return this._inverted;
        },
        set: function (value) {
            this._inverted = value;
            this._setInverted(this._inverted);
        },
        enumerable: true,
        configurable: true
    });
    ToolbarComponent = __decorate([
        CustomElement({
            name: TOOLBAR_CONSTANTS.elementName
        })
    ], ToolbarComponent);
    return ToolbarComponent;
}(HTMLElement));
export { ToolbarComponent };
