import { __extends } from "tslib";
import { isDefined } from '@tyler-components-web/core';
import { AbstractComponentDelegate } from '../core/delegates/abstract-component-delegate';
import { TEXT_FIELD_CONSTANTS } from './text-field-constants';
var TextFieldComponentDelegate = /** @class */ (function (_super) {
    __extends(TextFieldComponentDelegate, _super);
    function TextFieldComponentDelegate(config) {
        if (config === void 0) { config = {}; }
        return _super.call(this, config) || this;
    }
    TextFieldComponentDelegate.prototype.build = function () {
        var textField = document.createElement(TEXT_FIELD_CONSTANTS.elementName);
        this._input = document.createElement('input');
        this._input.type = this._config.type || 'text';
        textField.appendChild(this._input);
        return textField;
    };
    TextFieldComponentDelegate.prototype.configure = function () {
        if (isDefined(this._config.label) && this._config.label) {
            var label = document.createElement('label');
            label.textContent = this._config.label;
            this._component.appendChild(label);
        }
        if (isDefined(this._config.helperText) && this._config.helperText) {
            this.setHelperText(this._config.helperText);
        }
        if (isDefined(this._config.required)) {
            this._component.required = this._config.required;
        }
        if (isDefined(this._config.invalid)) {
            this._component.invalid = this._config.invalid;
        }
        if (isDefined(this._config.floatLabelType)) {
            this._component.floatLabelType = this._config.floatLabelType;
        }
        if (isDefined(this._config.placeholder)) {
            this._input.placeholder = this._config.placeholder;
        }
    };
    TextFieldComponentDelegate.prototype.getValue = function () {
        return this._input.value;
    };
    TextFieldComponentDelegate.prototype.setValue = function (value) {
        this._input.value = value;
    };
    TextFieldComponentDelegate.prototype.onChange = function (listener) {
        this._input.addEventListener('input', function (evt) { return listener(evt.target.value); });
    };
    TextFieldComponentDelegate.prototype.onFocus = function (listener) {
        this._input.addEventListener('focus', function (evt) { return listener(evt); });
    };
    TextFieldComponentDelegate.prototype.onBlur = function (listener) {
        this._input.addEventListener('blur', function (evt) { return listener(evt); });
    };
    TextFieldComponentDelegate.prototype.setDisabled = function (value) {
        this._input.disabled = value;
    };
    TextFieldComponentDelegate.prototype.validate = function () {
        return !!this._input.value.trim();
    };
    TextFieldComponentDelegate.prototype.setHelperText = function (text) {
        if (text) {
            if (!this._helperText) {
                this._helperText = document.createElement('span');
                this._helperText.slot = 'helper-text';
                this._component.appendChild(this._helperText);
            }
            this._helperText.textContent = text;
        }
        else if (this._helperText && this._helperText.isConnected) {
            this._component.removeChild(this._helperText);
            this._helperText = undefined;
        }
    };
    TextFieldComponentDelegate.prototype.setValidity = function (value) {
        this._component.invalid = !value;
    };
    TextFieldComponentDelegate.prototype.floatLabel = function (value) {
        this._component.floatLabel(value);
    };
    return TextFieldComponentDelegate;
}(AbstractComponentDelegate));
export { TextFieldComponentDelegate };
