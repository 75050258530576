import { coerceNumber, isDefined } from '@tyler-components-web/core';
import { COLOR_CONSTANTS } from '../theme';
import { PRODUCT_ICON_CONSTANTS } from './product-icon-constants';
import { ICON_CLASS_NAME } from '../constants';
var ProductIconFoundation = /** @class */ (function () {
    function ProductIconFoundation(_adapter) {
        this._adapter = _adapter;
        this._colorValue = PRODUCT_ICON_CONSTANTS.strings.DEFAULT_COLOR_VALUE;
        this._colorPalette = PRODUCT_ICON_CONSTANTS.strings.DEFAULT_COLOR_PALETTE;
        this._colorShade = PRODUCT_ICON_CONSTANTS.strings.DEFAULT_COLOR_SHADE;
        this._size = PRODUCT_ICON_CONSTANTS.numbers.DEFAULT_SIZE;
        this._shadow = true;
        this._backgroundColor = PRODUCT_ICON_CONSTANTS.strings.DEFAULT_BACKGROUND_COLOR;
        this._iterations = PRODUCT_ICON_CONSTANTS.numbers.DEFAULT_ITERATIONS;
        this._mode = PRODUCT_ICON_CONSTANTS.strings.DEFAULT_MODE;
        this._usingCustomIterations = false;
    }
    ProductIconFoundation.prototype.initialize = function () {
        this._adapter.setElementSize(this._size);
        this._parseColor();
        this._render();
    };
    ProductIconFoundation.prototype._parseColor = function () {
        if (this._colorValue.includes('-')) {
            this._colorPalette = this._colorValue.split('-')[0];
            this._colorShade = coerceNumber(this._colorValue.split('-')[1]);
        }
        else {
            this._colorPalette = this._colorValue;
            this._colorShade = PRODUCT_ICON_CONSTANTS.strings.DEFAULT_COLOR_SHADE;
        }
    };
    ProductIconFoundation.prototype._updateSize = function () {
        this._adapter.setElementSize(this._size);
        // If not using a custom iteration value, then we need to adjust the shadow length
        if (!this._usingCustomIterations) {
            this._iterations = this._size / 2;
            this._updateShadow();
        }
    };
    ProductIconFoundation.prototype._updateFontSize = function () {
        var size = this._mode === 'icon' ?
            this._size * PRODUCT_ICON_CONSTANTS.numbers.ICON_FONT_SIZE_MODIFIER :
            this._size * PRODUCT_ICON_CONSTANTS.numbers.TEXT_FONT_SIZE_MODIFIER;
        this._adapter.setFontSize(size);
    };
    ProductIconFoundation.prototype._updateShadow = function () {
        if (this._shadow) {
            this._adapter.setTextShadow(this._shadow, this._iterations, this._shadowColor);
        }
    };
    ProductIconFoundation.prototype._applyMode = function () {
        if (this._mode === 'icon') {
            this._adapter.setIconClass(ICON_CLASS_NAME);
        }
        else {
            this._adapter.removeIconClass(ICON_CLASS_NAME);
        }
        this._updateFontSize();
    };
    ProductIconFoundation.prototype._render = function () {
        if (!this._colorValue) {
            return;
        }
        var iconColor = this._colorShade <= PRODUCT_ICON_CONSTANTS.numbers.ACCESSIBILITY_COLOR_THRESHOLD ? '#000000' : '#ffffff';
        var shadowIncrement = this._colorShade + 300;
        if (!COLOR_CONSTANTS[this._colorPalette + "Palette"]) {
            this._colorPalette = PRODUCT_ICON_CONSTANTS.strings.DEFAULT_COLOR_PALETTE;
        }
        this._backgroundColor = COLOR_CONSTANTS[this._colorPalette + "Palette"][this._colorShade];
        this._shadowColor = COLOR_CONSTANTS[this._colorPalette + "Palette"][shadowIncrement];
        this._adapter.setBackgroundColor(this._backgroundColor);
        this._adapter.setIconColor(iconColor);
        this._updateShadow();
    };
    Object.defineProperty(ProductIconFoundation.prototype, "color", {
        get: function () {
            return this._colorValue;
        },
        set: function (value) {
            if (this._colorValue !== value) {
                this._colorValue = value;
                this._parseColor();
                this._render();
                this._adapter.setHostAttribute(PRODUCT_ICON_CONSTANTS.attributes.COLOR, this._colorValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductIconFoundation.prototype, "mode", {
        get: function () {
            return this._mode;
        },
        set: function (value) {
            if (this._mode !== value) {
                this._mode = value;
                this._applyMode();
                this._adapter.setHostAttribute(PRODUCT_ICON_CONSTANTS.attributes.MODE, this._mode);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductIconFoundation.prototype, "size", {
        get: function () {
            return this._size;
        },
        set: function (value) {
            if (this._size !== value) {
                this._size = value;
                this._updateSize();
                this._updateFontSize();
                this._adapter.setHostAttribute(PRODUCT_ICON_CONSTANTS.attributes.SIZE, this._size.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductIconFoundation.prototype, "shadow", {
        get: function () {
            return this._shadow;
        },
        set: function (value) {
            if (this._shadow !== value) {
                this._shadow = value;
                this._adapter.setTextShadow(this._shadow, this._iterations, this._shadowColor);
                this._adapter.setHostAttribute(PRODUCT_ICON_CONSTANTS.attributes.SHADOW, isDefined(this._shadow) ? this._shadow.toString() : 'false');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductIconFoundation.prototype, "iterations", {
        get: function () {
            return this._iterations;
        },
        set: function (value) {
            if (this._iterations !== value) {
                this._usingCustomIterations = true;
                this._iterations = value;
                this._updateShadow();
                this._adapter.setHostAttribute(PRODUCT_ICON_CONSTANTS.attributes.ITERATIONS, this._iterations.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    return ProductIconFoundation;
}());
export { ProductIconFoundation };
