import { getShadowElement, emitEvent } from '@tyler-components-web/core';
import { EXPANSION_PANEL_CONSTANTS } from './expansion-panel-constants';
var ExpansionPanelAdapter = /** @class */ (function () {
    function ExpansionPanelAdapter(_component) {
        this._component = _component;
        this._headerElement = getShadowElement(this._component, EXPANSION_PANEL_CONSTANTS.selectors.HEADER);
        this._contentElement = getShadowElement(this._component, EXPANSION_PANEL_CONSTANTS.selectors.CONTENT);
        this._headerSlotElement = getShadowElement(this._component, EXPANSION_PANEL_CONSTANTS.selectors.HEADER_SLOT);
    }
    ExpansionPanelAdapter.prototype.setHostAttribute = function (name, value) {
        this._component.setAttribute(name, value);
    };
    ExpansionPanelAdapter.prototype.removeHostAttribute = function (name) {
        this._component.removeAttribute(name);
    };
    ExpansionPanelAdapter.prototype.emitEvent = function (type, data) {
        emitEvent(this._component, type, data);
    };
    ExpansionPanelAdapter.prototype.initialize = function (open, orientation) {
        if (orientation === void 0) { orientation = 'vertical'; }
        var assignedNodes = this._headerElement.children[0].assignedNodes();
        this.setHeaderVisibility(!!assignedNodes.length);
        if (orientation === EXPANSION_PANEL_CONSTANTS.strings.ORIENTATION_HORIZONTAL) {
            this._contentElement.style.height = '';
            this._contentElement.style.width = '0px';
        }
        if (open) {
            if (orientation === 'vertical') {
                this._contentElement.style.height = '';
            }
            else {
                this._contentElement.style.width = '';
            }
            this._contentElement.style.opacity = null;
            var openIconElement = this._component.querySelector(EXPANSION_PANEL_CONSTANTS.selectors.OPEN_ICON);
            if (openIconElement) {
                openIconElement.open = true;
            }
        }
        this._component.setAttribute('aria-expanded', open ? 'true' : 'false');
    };
    ExpansionPanelAdapter.prototype.setHeaderVisibility = function (visible) {
        if (visible) {
            this._headerElement.style.display = '';
        }
        else {
            this._headerElement.style.display = 'none';
        }
    };
    ExpansionPanelAdapter.prototype.setOpenState = function (open, orientation) {
        var _this = this;
        if (orientation === void 0) { orientation = EXPANSION_PANEL_CONSTANTS.strings.ORIENTATION_VERTICAL; }
        var assignedNodes = this._contentElement.children[0].assignedNodes();
        var openIconElement = this._component.querySelector(EXPANSION_PANEL_CONSTANTS.selectors.OPEN_ICON);
        if (assignedNodes && assignedNodes.length && assignedNodes[0]) {
            var transitionEndListener_1 = function (evt) {
                // If the state has changed since it started, then ignore the transition
                if (_this._component.open !== open) {
                    _this._contentElement.removeEventListener('transitionend', transitionEndListener_1);
                    return;
                }
                if (evt.propertyName === 'height' || evt.propertyName === 'width') {
                    _this._contentElement.removeEventListener('transitionend', transitionEndListener_1);
                    _this._contentElement.style.transition = null;
                    if (open) {
                        if (orientation === EXPANSION_PANEL_CONSTANTS.strings.ORIENTATION_HORIZONTAL) {
                            _this._contentElement.style.width = '';
                        }
                        else {
                            _this._contentElement.style.height = '';
                        }
                        _this._contentElement.style.opacity = null;
                    }
                }
            };
            this._contentElement.addEventListener('transitionend', transitionEndListener_1);
            if (open) {
                if (orientation === EXPANSION_PANEL_CONSTANTS.strings.ORIENTATION_HORIZONTAL) {
                    this._contentElement.style.width = '0px';
                }
                else {
                    this._contentElement.style.height = '0px';
                }
                this._contentElement.style.opacity = '0';
            }
            else {
                if (orientation === EXPANSION_PANEL_CONSTANTS.strings.ORIENTATION_HORIZONTAL) {
                    this._contentElement.style.width = this._contentElement.scrollWidth + "px";
                }
                else {
                    this._contentElement.style.height = this._contentElement.scrollHeight + "px";
                }
                this._contentElement.style.opacity = '1';
            }
            if (orientation === EXPANSION_PANEL_CONSTANTS.strings.ORIENTATION_HORIZONTAL) {
                this._contentElement.style.transition = EXPANSION_PANEL_CONSTANTS.strings.EXPANSION_HORIZONTAL_TRANSITION;
            }
            else {
                this._contentElement.style.transition = EXPANSION_PANEL_CONSTANTS.strings.EXPANSION_VERTICAL_TRANSITION;
            }
            requestAnimationFrame(function () {
                if (open) {
                    if (orientation === EXPANSION_PANEL_CONSTANTS.strings.ORIENTATION_HORIZONTAL) {
                        _this._contentElement.style.width = _this._contentElement.scrollWidth + "px";
                    }
                    else {
                        _this._contentElement.style.height = _this._contentElement.scrollHeight + "px";
                    }
                    _this._contentElement.style.opacity = '1';
                    _this._component.setAttribute('aria-expanded', 'true');
                    if (openIconElement) {
                        openIconElement.open = true;
                    }
                }
                else {
                    if (orientation === EXPANSION_PANEL_CONSTANTS.strings.ORIENTATION_HORIZONTAL) {
                        _this._contentElement.style.width = '0px';
                    }
                    else {
                        _this._contentElement.style.height = '0px';
                    }
                    _this._contentElement.style.opacity = '0';
                    _this._component.setAttribute('aria-expanded', 'false');
                    if (openIconElement) {
                        openIconElement.open = false;
                    }
                }
            });
        }
        else {
            if (open) {
                if (orientation === EXPANSION_PANEL_CONSTANTS.strings.ORIENTATION_HORIZONTAL) {
                    this._contentElement.style.width = '';
                }
                else {
                    this._contentElement.style.height = '';
                }
                this._contentElement.style.opacity = null;
                this._component.setAttribute('aria-expanded', 'true');
                if (openIconElement) {
                    openIconElement.open = true;
                }
            }
            else {
                this._component.setAttribute('aria-expanded', 'false');
                if (openIconElement) {
                    openIconElement.open = false;
                }
            }
        }
    };
    ExpansionPanelAdapter.prototype.registerClickListener = function (listener) {
        this._headerElement.addEventListener('click', listener);
    };
    ExpansionPanelAdapter.prototype.deregisterClickListener = function (listener) {
        this._headerElement.removeEventListener('click', listener);
    };
    ExpansionPanelAdapter.prototype.registerKeydownListener = function (listener) {
        this._headerElement.addEventListener('keydown', listener);
    };
    ExpansionPanelAdapter.prototype.deregisterKeydownListener = function (listener) {
        this._headerElement.removeEventListener('keydown', listener);
    };
    ExpansionPanelAdapter.prototype.registerHeaderSlotListener = function (listener) {
        this._headerSlotElement.addEventListener('slotchange', listener);
    };
    ExpansionPanelAdapter.prototype.deregisterHeaderSlotListener = function (listener) {
        this._headerSlotElement.removeEventListener('slotchange', listener);
    };
    return ExpansionPanelAdapter;
}());
export { ExpansionPanelAdapter };
