import { STEPPER_CONSTANTS } from './stepper-constants';
import { ICON_CLASS_NAME } from '../constants';
var StepperUtils = /** @class */ (function () {
    function StepperUtils() {
    }
    /**
     * Creates a step element using the provided configuration.
     * @param {IStepConfiguration} step The step configuration
     * @param {number} index The index of the step.
     * @param {number} activeStepIndex The currently active step index.
     * @param {boolean} linear Whether the stepper is in linear mode or not.
     */
    StepperUtils.createStepElement = function (step, index, activeStepIndex, linear) {
        var stepElement = document.createElement('button');
        stepElement.classList.add(STEPPER_CONSTANTS.classes.STEP);
        stepElement.setAttribute('role', 'tab');
        // Don't allow focus to elements that can't be selected
        if (linear && index > activeStepIndex) {
            stepElement.setAttribute('tabindex', '-1');
        }
        if (step.completed) {
            stepElement.classList.add(STEPPER_CONSTANTS.classes.STEP_COMPLETED);
        }
        if (index === activeStepIndex) {
            StepperUtils.activateStep(stepElement);
        }
        return stepElement;
    };
    /**
     * Creates the step icon element using the provided configuration.
     * @param {IStepConfiguration} step The step configuration
     * @param {number} index The index of the step.
     * @param {number} activeStepIndex The currently active step index.
     * @param {boolean} linear Whether the stepper is in linear mode or not.
     */
    StepperUtils.createStepIcon = function (step, index, activeStepIndex, linear) {
        var iconWrapperElement = document.createElement('div');
        iconWrapperElement.classList.add('tyl-stepper__step-icon-container');
        var iconContainerElement = document.createElement('div');
        iconContainerElement.classList.add(STEPPER_CONSTANTS.classes.STEP_ICON);
        if (!step.completed || index === activeStepIndex) {
            iconContainerElement.innerText = (index + 1).toString();
        }
        else {
            var iconElement = document.createElement('i');
            iconElement.classList.add(ICON_CLASS_NAME);
            iconElement.innerText = step.editable ? STEPPER_CONSTANTS.icons.EDIT : STEPPER_CONSTANTS.icons.CHECK;
            iconContainerElement.appendChild(iconElement);
        }
        iconWrapperElement.appendChild(iconContainerElement);
        return iconWrapperElement;
    };
    /**
     * Creates the step label element using the provided configuration.
     * @param {IStepConfiguration} step The step configuration.
     */
    StepperUtils.createStepLabel = function (step) {
        var labelContainerElement = document.createElement('div');
        labelContainerElement.classList.add(STEPPER_CONSTANTS.classes.STEP_LABEL_CONTAINER);
        var labelElement = document.createElement('div');
        labelElement.classList.add(STEPPER_CONSTANTS.classes.STEP_LABEL);
        labelElement.innerHTML = step.label;
        labelContainerElement.appendChild(labelElement);
        if (step.optionalLabel) {
            var optionalLabelElement = document.createElement('div');
            optionalLabelElement.classList.add(STEPPER_CONSTANTS.classes.STEP_OPTIONAL_LABEL);
            optionalLabelElement.innerText = step.optionalLabel;
            labelContainerElement.appendChild(optionalLabelElement);
        }
        return labelContainerElement;
    };
    /**
     * Returns the element to its deselected state.
     * @param {HTMLElement} element The step element to deactivate.
     */
    StepperUtils.deactivateStep = function (element) {
        element.classList.remove(STEPPER_CONSTANTS.classes.STEP_SELECTED);
        element.removeAttribute('aria-selected');
    };
    /**
     * Sets the element to its selected state.
     * @param {HTMLElement} element The step element to activate.
     */
    StepperUtils.activateStep = function (element) {
        element.classList.add(STEPPER_CONSTANTS.classes.STEP_SELECTED);
        element.setAttribute('aria-selected', 'true');
    };
    /**
     * Sets the stepper to the provided linear state.
     * @param {HTMLElement} stepperElement The stepper element.
     * @param {boolean} isLinear Whether the stepper is in linear mode or not.
     */
    StepperUtils.setLinearState = function (stepperElement, isLinear) {
        var hasLinearClass = stepperElement.classList.contains(STEPPER_CONSTANTS.classes.STEPPER_LINEAR);
        if (hasLinearClass && !isLinear) {
            stepperElement.classList.remove(STEPPER_CONSTANTS.classes.STEPPER_LINEAR);
            hasLinearClass = false;
        }
        if (!hasLinearClass && isLinear) {
            stepperElement.classList.add(STEPPER_CONSTANTS.classes.STEPPER_LINEAR);
        }
    };
    /**
     * Sets the stepper to the provided label state.
     * @param {HTMLElement} stepperElement The stepper element.
     * @param {boolean} isAlternative Whether the stepper is in alternative mode or not.
     */
    StepperUtils.setAlternativeState = function (stepperElement, isAlternative) {
        var hasAlternativeClass = stepperElement.classList.contains(STEPPER_CONSTANTS.classes.STEPPER_ALTERNATIVE);
        if (hasAlternativeClass && !isAlternative) {
            stepperElement.classList.remove(STEPPER_CONSTANTS.classes.STEPPER_ALTERNATIVE);
            hasAlternativeClass = false;
        }
        if (!hasAlternativeClass && isAlternative) {
            stepperElement.classList.add(STEPPER_CONSTANTS.classes.STEPPER_ALTERNATIVE);
        }
    };
    /**
     * Sets the stepper layout mode.
     * @param {HTMLElement} stepperElement The stepper element.
     * @param {StepperLayoutMode} mode The layout mode.
     */
    StepperUtils.setLayoutMode = function (stepperElement, mode) {
        switch (mode) {
            case 'fixed':
                if (stepperElement.classList.contains(STEPPER_CONSTANTS.classes.STEPPER_STRETCHED)) {
                    stepperElement.classList.remove(STEPPER_CONSTANTS.classes.STEPPER_STRETCHED);
                }
                stepperElement.classList.add(STEPPER_CONSTANTS.classes.STEPPER_FIXED);
                break;
            case 'stretched':
                if (stepperElement.classList.contains(STEPPER_CONSTANTS.classes.STEPPER_FIXED)) {
                    stepperElement.classList.remove(STEPPER_CONSTANTS.classes.STEPPER_FIXED);
                }
                stepperElement.classList.add(STEPPER_CONSTANTS.classes.STEPPER_STRETCHED);
                break;
        }
    };
    /**
     * Sets the stepper layout alignment.
     * @param {HTMLElement} stepperElement The stepper element.
     * @param {StepperLayoutAlign} mode The layout alignment.
     */
    StepperUtils.setLayoutAlign = function (stepperElement, mode) {
        if (stepperElement.classList.contains(STEPPER_CONSTANTS.classes.STEPPER_ALIGN_LEFT)) {
            stepperElement.classList.remove(STEPPER_CONSTANTS.classes.STEPPER_ALIGN_LEFT);
        }
        if (stepperElement.classList.contains(STEPPER_CONSTANTS.classes.STEPPER_ALIGN_CENTER)) {
            stepperElement.classList.remove(STEPPER_CONSTANTS.classes.STEPPER_ALIGN_CENTER);
        }
        if (stepperElement.classList.contains(STEPPER_CONSTANTS.classes.STEPPER_ALIGN_RIGHT)) {
            stepperElement.classList.remove(STEPPER_CONSTANTS.classes.STEPPER_ALIGN_RIGHT);
        }
        switch (mode) {
            case 'left':
                stepperElement.classList.add(STEPPER_CONSTANTS.classes.STEPPER_ALIGN_LEFT);
                break;
            case 'center':
                stepperElement.classList.add(STEPPER_CONSTANTS.classes.STEPPER_ALIGN_CENTER);
                break;
            case 'right':
                stepperElement.classList.add(STEPPER_CONSTANTS.classes.STEPPER_ALIGN_RIGHT);
                break;
        }
    };
    /**
     * Creates a stepper with all of its step elements.
     * @param {IStepperConfiguration} stepperConfiguration The stepper configuration.
     */
    StepperUtils.createStepper = function (stepperConfiguration) {
        var stepperElement = StepperUtils._createStepperElement(stepperConfiguration);
        stepperConfiguration.steps.forEach(function (step, index) {
            var stepElement = StepperUtils.createStepElement(step, index, stepperConfiguration.selectedIndex, stepperConfiguration.linear);
            var iconElement = StepperUtils.createStepIcon(step, index, stepperConfiguration.selectedIndex, stepperConfiguration.linear);
            var labelElement = StepperUtils.createStepLabel(step);
            stepElement.appendChild(iconElement);
            stepElement.appendChild(labelElement);
            stepperElement.appendChild(stepElement);
        });
        return stepperElement;
    };
    /**
     * Creates a new stepper container element.
     * @param {IStepperConfiguration} stepperConfiguration The stepper configuration.
     */
    StepperUtils._createStepperElement = function (stepperConfiguration) {
        var stepperElement = document.createElement('div');
        stepperElement.classList.add(STEPPER_CONSTANTS.classes.STEPPER);
        stepperElement.setAttribute('role', 'tablist');
        this.setLinearState(stepperElement, stepperConfiguration.linear);
        this.setAlternativeState(stepperElement, stepperConfiguration.alternative);
        this.setLayoutMode(stepperElement, stepperConfiguration.layoutMode);
        this.setLayoutAlign(stepperElement, stepperConfiguration.layoutAlign);
        return stepperElement;
    };
    return StepperUtils;
}());
export { StepperUtils };
