// css-to-string-loader: transforms styles from css-loader to a string output

// Get the styles
var styles = require("!!../../../../../../../../tyler-components-web-cli/node_modules/css-loader/dist/cjs.js??ref--7-1!../../../../../../../../tyler-components-web-cli/node_modules/postcss-loader/src/index.js??ref--7-2!./omnibar-search.css");

if (typeof styles === 'string') {
  // Return an existing string
  module.exports = styles;
} else {
  // Call the custom toString method from css-loader module
  module.exports = styles.toString();
}