import { COMPONENT_NAME_PREFIX } from '../../constants';
var elementName = COMPONENT_NAME_PREFIX + "omnibar-profile-button";
var attributes = {
    FULL_NAME: 'full-name',
    EMAIL: 'email',
    AVATAR_IMAGE_URL: 'avatar-image-url',
    AVATAR_LETTER_COUNT: 'avatar-letter-count',
    AVATAR_TEXT: 'avatar-text',
    SIGN_OUT_BUTTON: 'sign-out-button',
    PROFILE_BUTTON: 'profile-button'
};
var selectors = {
    BUTTON: 'button'
};
export var OMNIBAR_PROFILE_BUTTON_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    selectors: selectors
};
