import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty, coerceBoolean } from '@tyler-components-web/core';
import { OmnibarAdapter } from './omnibar-adapter';
import { OmnibarFoundation } from './omnibar-foundation';
import { OMNIBAR_CONSTANTS } from './omnibar-constants';
var template = require('./omnibar.html');
var styles = require('./omnibar.css');
/**
 * The web component class behind the <tyl-omnibar> custom element.
 */
var OmnibarComponent = /** @class */ (function (_super) {
    __extends(OmnibarComponent, _super);
    function OmnibarComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, OMNIBAR_CONSTANTS.elementName, template, styles);
        _this._foundation = new OmnibarFoundation(new OmnibarAdapter(_this));
        return _this;
    }
    Object.defineProperty(OmnibarComponent, "observedAttributes", {
        get: function () {
            return [
                OMNIBAR_CONSTANTS.attributes.TITLE_TEXT,
                OMNIBAR_CONSTANTS.attributes.PROMINENT,
                OMNIBAR_CONSTANTS.attributes.FIXED,
                OMNIBAR_CONSTANTS.attributes.RAISED,
                OMNIBAR_CONSTANTS.attributes.SCROLL_TARGET,
                OMNIBAR_CONSTANTS.attributes.LOGO
            ];
        },
        enumerable: true,
        configurable: true
    });
    OmnibarComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'titleText');
        upgradeProperty(this, 'prominent');
        upgradeProperty(this, 'fixed');
        upgradeProperty(this, 'raised');
        upgradeProperty(this, 'scrollTarget');
        upgradeProperty(this, 'logo');
        this._foundation.initialize();
    };
    OmnibarComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case OMNIBAR_CONSTANTS.attributes.TITLE_TEXT:
                this.titleText = newValue;
                break;
            case OMNIBAR_CONSTANTS.attributes.PROMINENT:
                this.prominent = coerceBoolean(newValue);
                break;
            case OMNIBAR_CONSTANTS.attributes.FIXED:
                this.fixed = coerceBoolean(newValue);
                break;
            case OMNIBAR_CONSTANTS.attributes.RAISED:
                this.raised = coerceBoolean(newValue);
                break;
            case OMNIBAR_CONSTANTS.attributes.SCROLL_TARGET:
                this.scrollTarget = newValue;
                break;
            case OMNIBAR_CONSTANTS.attributes.LOGO:
                this.logo = coerceBoolean(newValue);
                break;
        }
    };
    Object.defineProperty(OmnibarComponent.prototype, "titleText", {
        /** Gets/sets the title of the app-bar. */
        get: function () {
            return this._foundation.titleText;
        },
        set: function (value) {
            this._foundation.titleText = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarComponent.prototype, "prominent", {
        /** Gets/sets the prominent variant. */
        get: function () {
            return this._foundation.prominent;
        },
        set: function (value) {
            this._foundation.prominent = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarComponent.prototype, "fixed", {
        /** Gets/sets the fixed variant. */
        get: function () {
            return this._foundation.fixed;
        },
        set: function (value) {
            this._foundation.fixed = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarComponent.prototype, "raised", {
        /** Gets/sets the omnibar raised state. */
        get: function () {
            return this._foundation.raised;
        },
        set: function (value) {
            this._foundation.raised = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarComponent.prototype, "scrollTarget", {
        /** Gets/sets the scroll target element. */
        get: function () {
            return this._foundation.scrollTarget;
        },
        set: function (value) {
            this._foundation.scrollTarget = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarComponent.prototype, "logo", {
        /** Gets/sets the visibility of the default logo. */
        get: function () {
            return this._foundation.logo;
        },
        set: function (value) {
            this._foundation.logo = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarComponent.prototype, "theme", {
        /** Convenience property to allow for easily getting/setting the theme color from JavaScript. */
        get: function () {
            return this.getAttribute(OMNIBAR_CONSTANTS.attributes.THEME) || null;
        },
        set: function (value) {
            if (value) {
                this.setAttribute(OMNIBAR_CONSTANTS.attributes.THEME, value);
            }
            else {
                this.removeAttribute(OMNIBAR_CONSTANTS.attributes.THEME);
            }
        },
        enumerable: true,
        configurable: true
    });
    OmnibarComponent = __decorate([
        CustomElement({
            name: OMNIBAR_CONSTANTS.elementName
        })
    ], OmnibarComponent);
    return OmnibarComponent;
}(HTMLElement));
export { OmnibarComponent };
