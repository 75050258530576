import { getActiveElement, deepQuerySelectorAll } from 'shadow-utils';
import { emitEvent, positionElement, addClass, removeClass, removeElement, getShadowElement, notChildEventListener, closestElement } from '@tyler-components-web/core';
import { setPopupPosition } from '../utils/positioning-utils';
import { POPUP_CONSTANTS as constants, POPUP_CONSTANTS } from './popup-constants';
var PopupAdapter = /** @class */ (function () {
    function PopupAdapter(_component) {
        this._component = _component;
        this._rootElement = getShadowElement(_component, POPUP_CONSTANTS.selectors.CONTAINER);
    }
    PopupAdapter.prototype.setAttribute = function (attribute, value, element) {
        if (element) {
            element.setAttribute(attribute, value);
        }
        else {
            this._component.setAttribute(attribute, value);
        }
    };
    PopupAdapter.prototype.removeAttribute = function (attribute, element) {
        if (element) {
            element.removeAttribute(attribute);
        }
        else {
            this._component.removeAttribute(attribute);
        }
    };
    PopupAdapter.prototype.positionPopup = function () {
        var position = positionElement(this._component, this._component.targetElement, "auto " + this._component.placement, this._hostElement);
        setPopupPosition(this._component, position);
        emitEvent(this._component, constants.events.POSITION, position);
    };
    PopupAdapter.prototype.addPopup = function (targetElement, manageFocus) {
        var _this = this;
        this._component.setAttribute('tabindex', '0');
        this._component.setAttribute(POPUP_CONSTANTS.attributes.HOST, '');
        var hostDocument = targetElement.ownerDocument || document;
        this._hostElement = closestElement(POPUP_CONSTANTS.selectors.HOST, targetElement) || hostDocument.body;
        this._hostElement.appendChild(this._component);
        window.requestAnimationFrame(function () { return _this.positionPopup(); });
        if (manageFocus) {
            this._previouslyFocusedElement = getActiveElement();
            this._component.focus();
        }
    };
    PopupAdapter.prototype.removePopup = function (manageFocus) {
        var _this = this;
        removeElement(this._component);
        if (manageFocus) {
            window.requestAnimationFrame(function () {
                if (_this._previouslyFocusedElement) {
                    var activeElement = getActiveElement();
                    if (!activeElement || activeElement === document.body) {
                        _this._previouslyFocusedElement.focus();
                    }
                    _this._previouslyFocusedElement = undefined;
                }
            });
        }
        this._hostElement = undefined;
    };
    PopupAdapter.prototype.manageWindowEvents = function (add) {
        var _this = this;
        if (add === void 0) { add = false; }
        if (add) {
            this._windowEventCallback = function (evt) { return _this._windowInteraction(evt); };
            window.addEventListener('scroll', this._windowEventCallback, true);
            window.addEventListener('resize', this._windowEventCallback, true);
        }
        else {
            if (this._windowEventCallback) {
                window.removeEventListener('scroll', this._windowEventCallback, true);
                window.removeEventListener('resize', this._windowEventCallback, true);
                this._windowEventCallback = undefined;
            }
        }
    };
    PopupAdapter.prototype.dispatchEvent = function (type, data, bubbles, cancelable) {
        return !emitEvent(this._component.targetElement, type, data, bubbles, cancelable);
    };
    PopupAdapter.prototype.addClass = function (classes) {
        addClass(classes, this._rootElement);
    };
    PopupAdapter.prototype.removeClass = function (classes) {
        removeClass(classes, this._rootElement);
    };
    PopupAdapter.prototype.setAnimationEndListener = function (listener, classes) {
        var _this = this;
        window.requestAnimationFrame(function () {
            if (classes && classes.length) {
                addClass(classes, _this._rootElement);
            }
            _this._rootElement.addEventListener('transitionend', listener);
        });
    };
    PopupAdapter.prototype.removeEventListener = function (type, listener) {
        this._rootElement.removeEventListener(type, listener);
    };
    PopupAdapter.prototype.setBlurListener = function (listener) {
        var _this = this;
        return notChildEventListener(this._component, function (activeElement) {
            if (!_this._component.contains(activeElement)) {
                listener();
            }
        }, true);
    };
    PopupAdapter.prototype.trySetInitialFocus = function () {
        var _this = this;
        window.requestAnimationFrame(function () {
            var elements = deepQuerySelectorAll(_this._component, POPUP_CONSTANTS.selectors.INITIAL_FOCUS);
            if (elements && elements.length) {
                var initialElement = elements[elements.length - 1];
                initialElement.focus();
            }
        });
    };
    PopupAdapter.prototype._windowInteraction = function (evt) {
        if (!this._component.open) {
            return;
        }
        switch (evt.type) {
            case 'scroll':
            case 'resize':
                this.positionPopup();
                break;
            default:
                break;
        }
    };
    return PopupAdapter;
}());
export { PopupAdapter };
