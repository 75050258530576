import { __decorate, __extends } from "tslib";
import { CustomElement, attachLightTemplate, upgradeProperty } from '@tyler-components-web/core';
import { OMNIBAR_APP_LAUNCHER_BUTTON_CONSTANTS } from './omnibar-app-launcher-button-constants';
import { OmnibarAppLauncherButtonFoundation } from './omnibar-app-launcher-button-foundation';
import { OmnibarAppLauncherButtonAdapter } from './omnibar-app-launcher-button-adapter';
import { AppLauncherComponent } from '../app-launcher';
import { IconButtonComponent } from '../../icon-button';
import { TooltipComponent } from '../../tooltip';
import { PopupComponent } from '../../popup';
var template = require('./omnibar-app-launcher-button.html');
var OmnibarAppLauncherButtonComponent = /** @class */ (function (_super) {
    __extends(OmnibarAppLauncherButtonComponent, _super);
    function OmnibarAppLauncherButtonComponent() {
        var _this = _super.call(this) || this;
        _this._foundation = new OmnibarAppLauncherButtonFoundation(new OmnibarAppLauncherButtonAdapter(_this));
        return _this;
    }
    OmnibarAppLauncherButtonComponent.prototype.initializedCallback = function () {
        attachLightTemplate(this, template);
    };
    OmnibarAppLauncherButtonComponent.prototype.connectedCallback = function () {
        upgradeProperty(this, 'optionsCallback');
        this._foundation.initialize();
    };
    OmnibarAppLauncherButtonComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    Object.defineProperty(OmnibarAppLauncherButtonComponent.prototype, "optionsCallback", {
        set: function (cb) {
            this._foundation.optionsCallback = cb;
        },
        enumerable: true,
        configurable: true
    });
    OmnibarAppLauncherButtonComponent.prototype.focusFirst = function () {
        this._foundation.focusFirst();
    };
    OmnibarAppLauncherButtonComponent = __decorate([
        CustomElement({
            name: OMNIBAR_APP_LAUNCHER_BUTTON_CONSTANTS.elementName,
            dependencies: [
                PopupComponent,
                AppLauncherComponent,
                IconButtonComponent,
                TooltipComponent
            ]
        })
    ], OmnibarAppLauncherButtonComponent);
    return OmnibarAppLauncherButtonComponent;
}(HTMLElement));
export { OmnibarAppLauncherButtonComponent };
