import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate } from '@tyler-components-web/core';
import { SKELETON_CONSTANTS } from './skeleton-constants';
var template = require('./skeleton.html');
var styles = require('./skeleton.css');
var SkeletonComponent = /** @class */ (function (_super) {
    __extends(SkeletonComponent, _super);
    function SkeletonComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, SKELETON_CONSTANTS.elementName, template, styles);
        return _this;
    }
    SkeletonComponent = __decorate([
        CustomElement({
            name: SKELETON_CONSTANTS.elementName
        })
    ], SkeletonComponent);
    return SkeletonComponent;
}(HTMLElement));
export { SkeletonComponent };
