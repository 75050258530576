import { addClass, removeClass } from '@tyler-components-web/core';
import { CHIP_CONSTANTS } from '../chip/chip-constants';
/**
 * The DOM adapter for the `<tcw-chip-set>` component.
 */
var ChipSetAdapter = /** @class */ (function () {
    function ChipSetAdapter(_component) {
        this._component = _component;
    }
    /**
     * Gets the host element.
     */
    ChipSetAdapter.prototype.getHostElement = function () {
        return this._component;
    };
    /**
     * Sets a CSS class or list of classes on the host element.
     * @param {string | string[]} classes The class(es) to set on the host element.
     */
    ChipSetAdapter.prototype.setHostClass = function (classes) {
        addClass(classes, this._component);
    };
    /**
     * Removes a CSS class or list of classes from the host element.
     * @param {string | string[]} classes The class(es) to remove from the host element.
     */
    ChipSetAdapter.prototype.removeHostClass = function (classes) {
        removeClass(classes, this._component);
    };
    /**
     * Sets an attribute on the host element.
     * @param {string} name The name of the attribute.
     * @param {string} value The value for the attribute.
     */
    ChipSetAdapter.prototype.setHostAttribute = function (name, value) {
        if (this._component.getAttribute(name) !== value) {
            this._component.setAttribute(name, value);
        }
    };
    /**
     * Removes an attribute from the host element.
     * @param {string} name The attribute name.
     */
    ChipSetAdapter.prototype.removeHostAttribute = function (name) {
        this._component.removeAttribute(name);
    };
    /**
     * Gets all of the child `<tcw-chip>` components.
     */
    ChipSetAdapter.prototype.getChildChips = function () {
        return Array.from(this._component.children).filter(function (child) { return child.tagName === CHIP_CONSTANTS.elementName.toUpperCase(); });
    };
    /**
     * Sets the orientation direction of the chip set.
     * @param {ChipSetOrientation} orientation The orientation.
     */
    ChipSetAdapter.prototype.setOrientation = function (orientation) {
        switch (orientation) {
            case 'horizontal':
                this._component.style.removeProperty('flex-direction');
                break;
            case 'vertical':
                this._component.style.setProperty('flex-direction', 'column');
                break;
        }
    };
    /**
     * Sets an event listener on the host element.
     * @param {string} type The event type.
     * @param {Function} listener The event listener.
     */
    ChipSetAdapter.prototype.addListener = function (type, listener) {
        this._component.addEventListener(type, listener);
    };
    return ChipSetAdapter;
}());
export { ChipSetAdapter };
