import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "card";
var attributes = {
    HAS_PADDING: 'has-padding',
    OUTLINED: 'outlined'
};
var classes = {
    PADDED: 'tyl-card--padded',
    OUTLINED: 'tyl-card--outlined'
};
var selectors = {
    CARD: '.tyl-card'
};
export var CARD_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    classes: classes,
    selectors: selectors
};
