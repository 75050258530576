import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "menu";
var classes = {
    POPUP: 'tyl-menu__popup',
    MENU: 'mdc-menu'
};
var selectors = {
    TOGGLE: "." + COMPONENT_NAME_PREFIX + "menu__toggle, [" + elementName + "-toggle]",
    MENU_LIST: 'tcw-list'
};
var attributes = {
    OPEN: 'open',
    PLACEMENT: 'placement',
    SELECTED_INDEX: 'selected-index',
    DENSE: 'dense',
    ICON_CLASS: 'icon-class'
};
var events = {
    SELECT: elementName + "-select"
};
export var MENU_CONSTANTS = {
    elementName: elementName,
    classes: classes,
    selectors: selectors,
    attributes: attributes,
    events: events
};
