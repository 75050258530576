import { TEXT_FIELD_CONSTANTS } from './text-field-constants';
var TextFieldFoundation = /** @class */ (function () {
    function TextFieldFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._density = 'default';
        this._floatLabelType = 'auto';
        this._shape = 'default';
        this._invalid = false;
        this._required = false;
        this._isInitialized = false;
        this._labelSlotListener = function (evt) { return _this._onLabelSlotChanged(evt); };
        this._leadingSlotListener = function (evt) { return _this._onLeadingSlotChanged(evt); };
        this._trailingSlotListener = function (evt) { return _this._onTrailingSlotChanged(evt); };
        this._focusListener = function () { return _this._onFocus(); };
        this._blurListener = function () { return _this._onBlur(); };
        this._valueChangedListener = function (value) { return _this._onValueChanged(value); };
        this._inputAttributeChangedListener = function (name, value) { return _this._onInputAttributeChanged(name, value); };
    }
    TextFieldFoundation.prototype.initialize = function () {
        this._adapter.initialize();
        this._adapter.detectTextarea();
        this._notchedOutline = this._adapter.initializeNotchedOutline();
        if (this._adapter.hasLabel()) {
            this._adapter.ensureSlottedLabel();
        }
        this._initializeLabel();
        if (this._adapter.hasPlaceholder()) {
            this._floatLabelType = 'always';
        }
        this._detectSlottedContent();
        this._adapter.addLabelSlotListener(this._labelSlotListener);
        this._adapter.addLeadingSlotListener(this._leadingSlotListener);
        this._adapter.addTrailingSlotListener(this._trailingSlotListener);
        this._adapter.setValueChangedListener(this, this._valueChangedListener);
        this._adapter.addInputListener('focus', this._focusListener);
        this._adapter.addInputListener('blur', this._blurListener);
        this._adapter.setInputAttributeObserver(this._inputAttributeChangedListener);
        this._applyDensity();
        this._setShapeType();
        this._setValidity();
        if (this._adapter.isDisabled()) {
            this._adapter.setRootClass(TEXT_FIELD_CONSTANTS.classes.DISABLED);
        }
        else {
            this._adapter.removeRootClass(TEXT_FIELD_CONSTANTS.classes.DISABLED);
        }
        if (this._adapter.isReadonly()) {
            this._adapter.setRootClass(TEXT_FIELD_CONSTANTS.classes.READONLY);
        }
        else {
            this._adapter.removeRootClass(TEXT_FIELD_CONSTANTS.classes.READONLY);
        }
        if (this._floatLabelType === 'always' || this._adapter.hasValue()) {
            this.floatLabel(true);
        }
        this._isInitialized = true;
    };
    TextFieldFoundation.prototype.disconnect = function () {
        this._isInitialized = false;
        this._adapter.destroy();
        if (this._floatingLabel) {
            this._floatingLabel.destroy();
        }
        if (this._notchedOutline) {
            this._notchedOutline.destroy();
        }
        this._adapter.removeLabelSlotListener(this._labelSlotListener);
        this._adapter.removeLeadingSlotListener(this._leadingSlotListener);
        this._adapter.removeTrailingSlotListener(this._trailingSlotListener);
        this._adapter.removeInputListener('focus', this._focusListener);
        this._adapter.removeInputListener('blur', this._blurListener);
    };
    TextFieldFoundation.prototype._onLabelSlotChanged = function (evt) {
        this._initializeLabel();
    };
    TextFieldFoundation.prototype._initializeLabel = function () {
        if (this._floatingLabel) {
            this._floatingLabel.destroy();
        }
        this._adapter.detectLabel();
        if (this._adapter.hasLabel()) {
            this._floatingLabel = this._adapter.initializeFloatingLabel();
            this._adapter.initializeAccessibility();
            this._adapter.ensureLabelOrder();
            this.floatLabel(this._adapter.hasValue());
        }
        else {
            this._adapter.removeHostAttribute(TEXT_FIELD_CONSTANTS.attributes.HOST_LABEL_FLOATING);
            this._floatingLabel = undefined;
            this._notch();
        }
    };
    TextFieldFoundation.prototype._onLeadingSlotChanged = function (evt) {
        this._detectLeadingContent();
    };
    TextFieldFoundation.prototype._onTrailingSlotChanged = function (evt) {
        this._detectTrailingContent();
    };
    TextFieldFoundation.prototype._detectLeadingContent = function () {
        if (this._adapter.hasLeadingNodes()) {
            this._adapter.addClass(TEXT_FIELD_CONSTANTS.classes.LEADING_ICON);
        }
        else {
            this._adapter.removeClass(TEXT_FIELD_CONSTANTS.classes.LEADING_ICON);
        }
    };
    TextFieldFoundation.prototype._detectTrailingContent = function () {
        if (this._adapter.hasTrailingNodes()) {
            this._adapter.addClass(TEXT_FIELD_CONSTANTS.classes.TRAILING_ICON);
        }
        else {
            this._adapter.removeClass(TEXT_FIELD_CONSTANTS.classes.TRAILING_ICON);
        }
    };
    TextFieldFoundation.prototype._detectSlottedContent = function () {
        this._detectLeadingContent();
        this._detectTrailingContent();
    };
    TextFieldFoundation.prototype._onFocus = function () {
        this._adapter.setRootClass(TEXT_FIELD_CONSTANTS.classes.FOCUSED);
        this._adapter.setLabelClass(TEXT_FIELD_CONSTANTS.classes.LABEL_FOCUSED);
        this.floatLabel(true);
    };
    TextFieldFoundation.prototype._onBlur = function () {
        if (this._adapter.hasFocus()) {
            return;
        }
        this._adapter.removeRootClass(TEXT_FIELD_CONSTANTS.classes.FOCUSED);
        this._adapter.removeLabelClass(TEXT_FIELD_CONSTANTS.classes.LABEL_FOCUSED);
        if (!this._adapter.hasValue()) {
            this.floatLabel(false);
        }
    };
    TextFieldFoundation.prototype._onValueChanged = function (value) {
        if (this._adapter.hasValue()) {
            this.floatLabel(true);
        }
        else if (!this._adapter.hasFocus()) {
            this.floatLabel(false);
        }
    };
    TextFieldFoundation.prototype._onInputAttributeChanged = function (name, value) {
        if (this._adapter.isDisabled()) {
            this._adapter.setRootClass(TEXT_FIELD_CONSTANTS.classes.DISABLED);
            this._adapter.removeRootClass(TEXT_FIELD_CONSTANTS.classes.FOCUSED);
            this._adapter.removeInputClass(TEXT_FIELD_CONSTANTS.classes.INPUT_FOCUSED);
            this._adapter.removeLabelClass(TEXT_FIELD_CONSTANTS.classes.LABEL_FOCUSED);
            if (!this._adapter.hasValue() && this._floatingLabel) {
                this.floatLabel(false);
            }
        }
        else {
            this._adapter.removeRootClass(TEXT_FIELD_CONSTANTS.classes.DISABLED);
        }
        if (this._adapter.isReadonly()) {
            this._adapter.setRootClass(TEXT_FIELD_CONSTANTS.classes.READONLY);
        }
        else {
            this._adapter.removeRootClass(TEXT_FIELD_CONSTANTS.classes.READONLY);
            this._adapter.removeRootClass(TEXT_FIELD_CONSTANTS.classes.FOCUSED);
            this._adapter.removeInputClass(TEXT_FIELD_CONSTANTS.classes.INPUT_FOCUSED);
        }
        if (name === 'value') {
            if (this._floatingLabel) {
                this.floatLabel(this._adapter.hasValue());
            }
        }
    };
    TextFieldFoundation.prototype.floatLabel = function (value) {
        if (!value && this._floatLabelType === 'always') {
            this._adapter.setHostAttribute(TEXT_FIELD_CONSTANTS.attributes.HOST_LABEL_FLOATING, '');
            return;
        }
        if (this._floatingLabel) {
            this._floatingLabel.float(value);
            if (value) {
                this._adapter.setInputClass(TEXT_FIELD_CONSTANTS.classes.INPUT_FOCUSED);
                this._adapter.setHostAttribute(TEXT_FIELD_CONSTANTS.attributes.HOST_LABEL_FLOATING, '');
            }
            else {
                this._adapter.removeInputClass(TEXT_FIELD_CONSTANTS.classes.INPUT_FOCUSED);
                this._adapter.removeHostAttribute(TEXT_FIELD_CONSTANTS.attributes.HOST_LABEL_FLOATING);
            }
            if (this._notchedOutline) {
                if (value) {
                    this._notch();
                }
                else {
                    this._notchedOutline.closeNotch();
                }
            }
        }
    };
    TextFieldFoundation.prototype._notch = function () {
        this._notchedOutline.notch(this._getLabelWidth());
    };
    TextFieldFoundation.prototype._getLabelWidth = function () {
        if (!this._floatingLabel) {
            return 0;
        }
        var width = (this._floatingLabel.getWidth() || 0) * TEXT_FIELD_CONSTANTS.numbers.FLOATING_LABEL_SCALE_FACTOR;
        if (!width) {
            var labelFont = this._adapter.getLabelFontMetrics();
            var fontSize = labelFont.fontSize * TEXT_FIELD_CONSTANTS.numbers.FLOATING_LABEL_SCALE_FACTOR;
            width = this._adapter.getLabelWidth(fontSize, labelFont.fontFamily);
        }
        return width;
    };
    TextFieldFoundation.prototype._setShapeType = function () {
        if (this._shape === 'rounded') {
            this._adapter.setRootClass(TEXT_FIELD_CONSTANTS.classes.SHAPE_ROUNDED);
        }
        else {
            this._adapter.removeRootClass(TEXT_FIELD_CONSTANTS.classes.SHAPE_ROUNDED);
        }
    };
    TextFieldFoundation.prototype._setValidity = function () {
        if (this._invalid) {
            this._adapter.setRootClass(TEXT_FIELD_CONSTANTS.classes.INVALID);
        }
        else {
            this._adapter.removeRootClass(TEXT_FIELD_CONSTANTS.classes.INVALID);
        }
        if (this._required) {
            this._adapter.setRootClass(TEXT_FIELD_CONSTANTS.classes.REQUIRED);
        }
        else {
            this._adapter.removeRootClass(TEXT_FIELD_CONSTANTS.classes.REQUIRED);
        }
    };
    TextFieldFoundation.prototype._applyDensity = function () {
        this._adapter.setRoomy(this._density === 'roomy');
        this._adapter.setDense(this._density === 'dense');
    };
    Object.defineProperty(TextFieldFoundation.prototype, "density", {
        get: function () {
            return this._density;
        },
        set: function (value) {
            if (this._density !== value) {
                this._density = value;
                if (this._isInitialized) {
                    this._applyDensity();
                }
                this._adapter.setHostAttribute(TEXT_FIELD_CONSTANTS.attributes.DENSITY, this._density.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextFieldFoundation.prototype, "floatLabelType", {
        get: function () {
            return this._floatLabelType;
        },
        set: function (value) {
            if (this._floatLabelType !== value) {
                this._floatLabelType = value;
                if (this._isInitialized) {
                    this.floatLabel(this._floatLabelType === 'always');
                }
                this._adapter.setHostAttribute(TEXT_FIELD_CONSTANTS.attributes.FLOAT_LABEL_TYPE, this._floatLabelType);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextFieldFoundation.prototype, "shape", {
        get: function () {
            return this._shape;
        },
        set: function (value) {
            if (this._shape !== value) {
                this._shape = value;
                if (this._isInitialized) {
                    this._setShapeType();
                }
                this._adapter.setHostAttribute(TEXT_FIELD_CONSTANTS.attributes.SHAPE, this._shape);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextFieldFoundation.prototype, "invalid", {
        get: function () {
            return this._invalid;
        },
        set: function (value) {
            if (this._invalid !== value) {
                this._invalid = value;
                if (this._isInitialized) {
                    this._setValidity();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextFieldFoundation.prototype, "required", {
        get: function () {
            return this._required;
        },
        set: function (value) {
            if (this._required !== value) {
                this._required = value;
                if (this._isInitialized) {
                    this._setValidity();
                    // If the label is floating, we need to adjust the notch width
                    if (this._notchedOutline && (this._adapter.hasFocus() || this._adapter.hasValue())) {
                        this._notch();
                    }
                }
                if (this._required) {
                    this._adapter.setHostAttribute(TEXT_FIELD_CONSTANTS.attributes.REQUIRED);
                }
                else {
                    this._adapter.removeHostAttribute(TEXT_FIELD_CONSTANTS.attributes.REQUIRED);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    return TextFieldFoundation;
}());
export { TextFieldFoundation };
