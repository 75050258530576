import { __decorate, __extends } from "tslib";
import { MDCRipple } from '@material/ripple';
import { CustomElement, coerceBoolean, emitEvent, upgradeProperty } from '@tyler-components-web/core';
import { ICON_BUTTON_CONSTANTS } from './icon-button-constants';
/**
 * The custom element class behind the `<tcw-icon-button>` element.
 */
var IconButtonComponent = /** @class */ (function (_super) {
    __extends(IconButtonComponent, _super);
    function IconButtonComponent() {
        var _this = _super.call(this) || this;
        _this._toggle = false;
        _this._isOn = false;
        /** Whether this is a dense icon button. */
        _this._dense = false;
        return _this;
    }
    Object.defineProperty(IconButtonComponent, "observedAttributes", {
        get: function () {
            return [
                ICON_BUTTON_CONSTANTS.attributes.IS_ON,
                ICON_BUTTON_CONSTANTS.attributes.DENSE
            ];
        },
        enumerable: true,
        configurable: true
    });
    IconButtonComponent.prototype._initialize = function () {
        var _this = this;
        this._buttonElement = this.querySelector(ICON_BUTTON_CONSTANTS.selectors.BUTTON);
        if (!this._buttonElement) {
            return;
        }
        this._buttonElement.classList.add(ICON_BUTTON_CONSTANTS.classes.BUTTON);
        this._applyToggle();
        this._applyDensity();
        if ((this.hasAttribute(ICON_BUTTON_CONSTANTS.attributes.TOGGLE) && coerceBoolean(this.getAttribute(ICON_BUTTON_CONSTANTS.attributes.TOGGLE))) || this._toggle) {
            this._toggle = true;
            var icons = Array.from(this._buttonElement.querySelectorAll('i, span, svg, img'));
            // We require two icon/image elements to be specified for the "on" and "off" states
            if (icons.length !== 2) {
                throw new Error('You must specify two icons, one for "on" and one for "off".');
            }
            // Add the icon class to each icon
            icons.forEach(function (icon) { return icon.classList.add(ICON_BUTTON_CONSTANTS.classes.ICON); });
            // If there are no icons that specify the "on" class, then automatically choose the first icon as the "on" icon and add the class,
            // alternatively we check for the existence of a `tcw-icon-button-on` attribute on any of the icons and use that.
            if (!icons.some(function (icon) { return icon.classList.contains(ICON_BUTTON_CONSTANTS.classes.ICON_ON); })) {
                var requestedOnIcon = icons.find(function (icon) { return icon.hasAttribute(ICON_BUTTON_CONSTANTS.attributes.ICON_ON); });
                if (requestedOnIcon) {
                    requestedOnIcon.classList.add(ICON_BUTTON_CONSTANTS.classes.ICON_ON);
                }
                else {
                    icons[0].classList.add(ICON_BUTTON_CONSTANTS.classes.ICON_ON);
                }
            }
            this._buttonElement.addEventListener('click', function (evt) {
                _this._toggleValue();
                emitEvent(_this, ICON_BUTTON_CONSTANTS.events.CHANGE, _this._isOn, true);
            });
            // Wait a frame to ensure the value of the `on` property has been set
            window.requestAnimationFrame(function () {
                if (_this._isOn) {
                    _this._buttonElement.classList.add(ICON_BUTTON_CONSTANTS.classes.BUTTON_ON);
                    _this._buttonElement.setAttribute('aria-pressed', "" + _this._isOn);
                }
            });
        }
        this._mdcRipple = new MDCRipple(this._buttonElement);
        this._mdcRipple.unbounded = true;
    };
    IconButtonComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'isOn');
        upgradeProperty(this, 'dense');
    };
    IconButtonComponent.prototype.connectedCallback = function () {
        var _this = this;
        window.requestAnimationFrame(function () { return _this._initialize(); });
    };
    IconButtonComponent.prototype.disconnectedCallback = function () {
        if (this._mdcRipple) {
            this._mdcRipple.destroy();
        }
    };
    IconButtonComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case ICON_BUTTON_CONSTANTS.attributes.IS_ON:
                this.isOn = coerceBoolean(newValue);
                break;
            case ICON_BUTTON_CONSTANTS.attributes.DENSE:
                this.dense = coerceBoolean(newValue);
                break;
        }
    };
    IconButtonComponent.prototype._toggleValue = function () {
        this._isOn = !this._isOn;
        this._applyToggle();
    };
    IconButtonComponent.prototype._applyToggle = function () {
        if (!this._buttonElement) {
            return;
        }
        if (this._isOn) {
            this._buttonElement.classList.add(ICON_BUTTON_CONSTANTS.classes.BUTTON_ON);
        }
        else {
            this._buttonElement.classList.remove(ICON_BUTTON_CONSTANTS.classes.BUTTON_ON);
        }
        this._buttonElement.setAttribute('aria-pressed', "" + this._isOn);
    };
    Object.defineProperty(IconButtonComponent.prototype, "toggle", {
        /**
         * Gets/sets whether the button is togglable.
         */
        get: function () {
            return this._toggle;
        },
        set: function (value) {
            this._toggle = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconButtonComponent.prototype, "isOn", {
        /**
         * Gets/sets the toggled state of the icon button. Only applies when `toggle = true`.
         */
        get: function () {
            return this._isOn;
        },
        set: function (value) {
            if (this._isOn !== value) {
                this._isOn = value;
                this._applyToggle();
            }
        },
        enumerable: true,
        configurable: true
    });
    IconButtonComponent.prototype._applyDensity = function () {
        if (!this._buttonElement) {
            return;
        }
        if (this._dense) {
            this.setAttribute(ICON_BUTTON_CONSTANTS.attributes.DENSE, '');
            this._buttonElement.classList.add(ICON_BUTTON_CONSTANTS.classes.BUTTON_DENSE);
        }
        else {
            this.removeAttribute(ICON_BUTTON_CONSTANTS.attributes.DENSE);
            this._buttonElement.classList.remove(ICON_BUTTON_CONSTANTS.classes.BUTTON_DENSE);
        }
        // re-layout the ripple for cases where dense was changed after initial layout
        if (this._mdcRipple) {
            this._mdcRipple.layout();
        }
    };
    Object.defineProperty(IconButtonComponent.prototype, "dense", {
        /** Gets/sets whether the icon button is dense. */
        get: function () {
            return this._dense;
        },
        set: function (value) {
            if (this._dense !== value) {
                this._dense = value;
                this._applyDensity();
            }
        },
        enumerable: true,
        configurable: true
    });
    IconButtonComponent = __decorate([
        CustomElement({
            name: ICON_BUTTON_CONSTANTS.elementName
        })
    ], IconButtonComponent);
    return IconButtonComponent;
}(HTMLElement));
export { IconButtonComponent };
