import { __awaiter, __generator } from "tslib";
import { removeAllChildren, addClass, isDefined, isNumber, safeCssWidth, getPropertyValue, createPredicate, findWhere, removeElement, replaceElement, isString, isFunction, debounce, isObject } from '@tyler-components-web/core';
import { CellAlign, SortDirection } from './types';
import { TABLE_CONSTANTS } from './table-constants';
import { CHECKBOX_CONSTANTS } from '../checkbox';
import { EXPANSION_PANEL_CONSTANTS } from '../expansion-panel';
import { AbstractComponentDelegate } from '../core';
import { ICON_CLASS_NAME } from '../constants';
/**
 * Provides facilities for creating and manipulating a table component.
 */
var TableUtils = /** @class */ (function () {
    function TableUtils() {
    }
    /**
     * Creates a table using the provided configuration.
     * @param {ITableConfiguration} configuration The table configuration.
     */
    TableUtils.createTable = function (configuration) {
        // Reset the table back to its original unpopulated state
        TableUtils._resetTable(configuration.tableElement);
        // Create the header and body rows
        var thead = TableUtils._createTableHead(configuration);
        var tbody = TableUtils._createTableBody(configuration);
        // Set the fixed state
        if (configuration.fixedHeaders) {
            TableUtils.setFixedHeaders(configuration);
        }
        // Set the resizable state
        if (configuration.resizable) {
            TableUtils.setResizable(configuration);
        }
        // Set the dense state
        if (configuration.dense) {
            TableUtils.setDenseState(configuration.tableElement, configuration.dense);
        }
        // Set the wrap state
        if (configuration.wrapContent) {
            TableUtils.setWrapContentState(configuration.tableElement, configuration.wrapContent);
        }
        // Add the select column
        if (configuration.selectListener) {
            TableUtils._addSelectColumn(thead, tbody, configuration.selectListener, configuration.selectDoubleListener, configuration.selectAllListener);
        }
        if (configuration.resizable || configuration.columnConfigurations.some(function (c) { return !!c.sortable; })) {
            TableUtils._attachHeadRowMouseDownListener(thead, configuration.headRowMouseDownListener);
        }
        TableUtils.setLayoutType(configuration);
        TableUtils._setTableHead(configuration.tableElement, thead);
        // Add the filter row (must come after adding the select column and table head)
        if (configuration.filter) {
            TableUtils.setFilterRow(configuration);
        }
        TableUtils._setTableBody(configuration.tableElement, tbody);
    };
    TableUtils._setTableHead = function (tableElement, thead) {
        if (tableElement.tHead) {
            replaceElement(thead, tableElement.tHead);
        }
        else {
            tableElement.appendChild(thead);
        }
    };
    TableUtils._setTableBody = function (tableElement, tbody) {
        if (tableElement.tBodies.length) {
            replaceElement(tbody, tableElement.tBodies[0]);
        }
        else {
            tableElement.appendChild(tbody);
        }
    };
    /**
     * Destroys and recreates the table body section only.
     * @param {ITableConfiguration} configuration The table configuration.
     */
    TableUtils.recreateTableBody = function (configuration) {
        // Create the table body
        var tbody = TableUtils._createTableBody(configuration);
        // Add the select column if necessary
        if (configuration.selectListener) {
            TableUtils._createBodySelectColumn(tbody);
            TableUtils._attachRowClickListeners(tbody, configuration.selectListener, configuration.selectDoubleListener);
        }
        TableUtils._setTableBody(configuration.tableElement, tbody);
    };
    /**
     * Removes all DOM nodes from the table.
     * @param {HTMLTableElement} tableElement The table element to remove all children from.
     */
    TableUtils._resetTable = function (tableElement) {
        removeAllChildren(tableElement);
    };
    /**
     * Creates the table header section by adding a row for the column headers based on column configuration.
     * @param columnDataMap The column based data map.
     * @param tableElement The table element.
     */
    TableUtils._createTableHead = function (tableConfiguration) {
        var thead = document.createElement('thead');
        // Create the table head row for our column headers with required class
        var tr = thead.insertRow();
        addClass([
            TABLE_CONSTANTS.classes.TABLE_ROW,
            TABLE_CONSTANTS.classes.TABLE_HEAD_ROW
        ], tr);
        // We use this to determine if the initial sort column has already been set during the loop.
        // We set the initial sort to the first column that requests it.
        var setInitialSort = false;
        // Create a header cell for each column in our column data map
        for (var i = 0; i < tableConfiguration.columnConfigurations.length; i++) {
            var columnConfig = tableConfiguration.columnConfigurations[i];
            // Create the th element with required classes
            var th = document.createElement('th');
            th.scope = 'col';
            addClass([
                TABLE_CONSTANTS.classes.TABLE_CELL,
                TABLE_CONSTANTS.classes.TABLE_HEAD_CELL
            ], th);
            // We wrap the header text in a div for ease of alignment
            var cellContainer = document.createElement('div');
            cellContainer.classList.add(TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_CONTAINER);
            // Set the cell alignment from config
            if (columnConfig.align) {
                TableUtils._setCellAlignmentClass(cellContainer, columnConfig.align);
            }
            // Check if width was specified
            if (isDefined(columnConfig.width)) {
                var width = safeCssWidth(columnConfig.width);
                if (width) {
                    th.style.width = width;
                }
            }
            // Check if the resizable column handle should be appended
            if (tableConfiguration.resizable && columnConfig.resizable !== false) {
                var resizeHandle = document.createElement('div');
                resizeHandle.classList.add(TABLE_CONSTANTS.classes.TABLE_RESIZE_HANDLE);
                th.appendChild(resizeHandle);
            }
            // Check if we were provided any inline style declarations and apply to th AND wrapper content div
            if (isDefined(columnConfig.headerCellStyle) && isObject(columnConfig.headerCellStyle)) {
                Object.assign(th.style, columnConfig.headerCellStyle);
                Object.assign(cellContainer.style, columnConfig.headerCellStyle);
            }
            var span = document.createElement('span');
            span.classList.add(TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_TEXT);
            span.textContent = columnConfig.header && typeof columnConfig.header === 'string' ? columnConfig.header.trim() : '';
            // Add the sort icon if this column is sortable
            if (columnConfig.sortable) {
                th.classList.add(TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_SORTABLE);
                var iconElement = document.createElement('i');
                addClass([
                    ICON_CLASS_NAME,
                    TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_SORT_ICON
                ], iconElement);
                iconElement.textContent = TABLE_CONSTANTS.icons.SORT_DOWN;
                if (tableConfiguration.sortedColumn === i && !setInitialSort) {
                    th.classList.add(TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_SORTED_DESCENDING);
                    iconElement.classList.add(TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_SORT_ICON_ACTIVE);
                    TableUtils._setColumnSortDirection(th, tableConfiguration.sortDirection);
                    setInitialSort = true;
                }
                cellContainer.appendChild(iconElement);
            }
            cellContainer.appendChild(span);
            th.appendChild(cellContainer);
            tr.appendChild(th);
        }
        return thead;
    };
    /**
     * Sets the sort direction on the table to the provided column.
     * @param tableElement
     * @param columnIndex
     * @param sortDirection
     */
    TableUtils.setSortDirection = function (tableElement, columnIndex, sortDirection) {
        var cell = TableUtils._getHeaderCellByIndex(tableElement, columnIndex);
        TableUtils._setColumnSortDirection(cell, sortDirection);
    };
    /**
     * Sets the sort direction on the provided table header cell element.
     * @param thElement
     * @param sortDirection
     */
    TableUtils._setColumnSortDirection = function (thElement, sortDirection) {
        if (thElement.classList.contains(TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_SORTED_ASCENDING)) {
            thElement.classList.remove(TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_SORTED_ASCENDING);
        }
        if (thElement.classList.contains(TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_SORTED_DESCENDING)) {
            thElement.classList.remove(TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_SORTED_DESCENDING);
        }
        if (!sortDirection || sortDirection === SortDirection.Descending) {
            thElement.classList.add(TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_SORTED_DESCENDING);
        }
        else {
            thElement.classList.add(TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_SORTED_ASCENDING);
        }
    };
    /**
     * Creates the table body section by adding rows/cells for our data/column configuration.
     * @param columnDataMap The column based data map.
     * @param tableElement The table element.
     */
    TableUtils._createTableBody = function (configuration) {
        var tbody = document.createElement('tbody');
        var rowData = TableUtils._getOrderedRowData(configuration.columnConfigurations, configuration.data);
        // Create the rows
        rowData.forEach(function (cellData, rowIndex) {
            var tr = tbody.insertRow();
            addClass([
                TABLE_CONSTANTS.classes.TABLE_ROW,
                TABLE_CONSTANTS.classes.TABLE_BODY_ROW
            ], tr);
            // Create the row data cells
            TableUtils._populateRowCells(configuration, tr, cellData, rowIndex);
        });
        return tbody;
    };
    /**
     * Populates a table row element with provided data.
     * @param configuration
     * @param tr
     * @param cellData
     * @param rowIndex
     */
    TableUtils._populateRowCells = function (configuration, tr, cellData, rowIndex) {
        var _loop_1 = function (i) {
            // Find the configuration for this column
            var columnConfig = configuration.columnConfigurations[i];
            // Create the row data cell with required classes
            var td = tr.insertCell();
            addClass([
                TABLE_CONSTANTS.classes.TABLE_CELL,
                TABLE_CONSTANTS.classes.TABLE_BODY_CELL
            ], td);
            // Check if width was specified
            if (isDefined(columnConfig.width)) {
                var width = safeCssWidth(columnConfig.width);
                if (width) {
                    td.style.width = width;
                }
            }
            // We wrap the value in a div to allow for flex styling
            var div = document.createElement('div');
            div.classList.add(TABLE_CONSTANTS.classes.TABLE_CELL_CONTAINER);
            // Check if we were provided any inline style declarations and apply to BOTH td and content wrapper div
            if (isDefined(columnConfig.cellStyle) && isObject(columnConfig.cellStyle)) {
                Object.assign(td.style, columnConfig.cellStyle);
                Object.assign(div.style, columnConfig.cellStyle);
            }
            // Add the cell content. If there is a template function, then use that.
            // Otherwise use the property to go get the value from the row data..
            if (columnConfig.template && typeof columnConfig.template === 'function') {
                Promise.resolve(columnConfig.template(rowIndex, div)).then(function (element) {
                    if (element) {
                        if (typeof element === 'string') {
                            div.innerHTML = element;
                        }
                        else {
                            div.appendChild(element);
                        }
                    }
                });
            }
            else if (columnConfig.property) {
                // Place the text content in a span
                var span_1 = document.createElement('span');
                span_1.classList.add(TABLE_CONSTANTS.classes.TABLE_CELL_CONTAINER_TEXT);
                div.appendChild(span_1);
                if (columnConfig.transform && typeof columnConfig.transform === 'function') {
                    Promise.resolve(columnConfig.transform(cellData[i])).then(function (value) { return span_1.textContent = value; });
                }
                else {
                    if (cellData[i] === undefined || cellData[i] === null) {
                        span_1.textContent = '';
                    }
                    else {
                        span_1.textContent = cellData[i].toString();
                    }
                }
            }
            // Set the cell alignment from config
            if (columnConfig.align) {
                TableUtils._setCellAlignmentClass(div, columnConfig.align);
            }
            td.appendChild(div);
            // Check for column span
            if (isDefined(columnConfig.columnSpan)) {
                if (columnConfig.columnSpan === 'all') {
                    td.colSpan = cellData.length - i;
                    return out_i_1 = i, "break";
                }
                else if (typeof columnConfig.columnSpan === 'number' && columnConfig.columnSpan > 0) {
                    var colspan = columnConfig.columnSpan;
                    if (columnConfig.columnSpan > cellData.length - i) {
                        colspan = cellData.length;
                    }
                    td.colSpan = colspan;
                    i = i + (colspan - 1);
                }
            }
            out_i_1 = i;
        };
        var out_i_1;
        for (var i = 0; i < cellData.length; i++) {
            var state_1 = _loop_1(i);
            i = out_i_1;
            if (state_1 === "break")
                break;
        }
    };
    /**
     * Sets the proper alignment class on the provided element.
     * @param el The element to add the class to.
     * @param align The alignment value.
     */
    TableUtils._setCellAlignmentClass = function (el, align) {
        switch (align) {
            case CellAlign.Center:
                el.classList.add(TABLE_CONSTANTS.classes.TABLE_CELL_CENTER);
                break;
            case CellAlign.Right:
                el.classList.add(TABLE_CONSTANTS.classes.TABLE_CELL_RIGHT);
                break;
        }
    };
    /**
     * Returns all non-expanded rows in a tbody.
     * @param rows All rows in the tbody.
     */
    TableUtils._getNonExpandedRows = function (rows) {
        return Array.from(rows).filter(function (row) { return !row.classList.contains(TABLE_CONSTANTS.classes.TABLE_ROW_EXPANDABLE_CONTENT); });
    };
    /**
     * Returns all expanded rows in a tbody.
     * @param rows All rows in the tbody.
     */
    TableUtils._getExpandedRows = function (rows) {
        return Array.from(rows).filter(function (row) { return row.classList.contains(TABLE_CONSTANTS.classes.TABLE_ROW_EXPANDABLE_CONTENT); });
    };
    /**
     * Creates the column data map which organizes the data by column.
     * @param {IColumnConfiguration[]} columnConfigurations The column configurations.
     * @param {IColumnData[]} data The row data.
     */
    TableUtils._createColumnDataMap = function (columnConfigurations, data) {
        return columnConfigurations.map(function (columnConfig) {
            return {
                config: columnConfig,
                data: data.map(function (item) {
                    if (columnConfig.property) {
                        var value = getPropertyValue(item.data, columnConfig.property);
                        return isDefined(value) ? value : null;
                    }
                    return null;
                })
            };
        });
    };
    /**
     * Returns the row data in a column ordered fashion.
     * @param columnConfigurations
     * @param data
     */
    TableUtils._getOrderedRowData = function (columnConfigurations, data) {
        var columnDataMap = TableUtils._createColumnDataMap(columnConfigurations, data);
        var rowData = [];
        for (var _i = 0, columnDataMap_1 = columnDataMap; _i < columnDataMap_1.length; _i++) {
            var columnData = columnDataMap_1[_i];
            for (var j = 0; j < columnData.data.length; j++) {
                if (!rowData[j]) {
                    rowData[j] = [];
                }
                rowData[j].push(columnData.data[j]);
            }
        }
        return rowData;
    };
    /**
     * Attaches a click listener to each row in the table.
     * @param tbodyElement
     * @param listener
     */
    TableUtils._attachRowClickListeners = function (tbodyElement, clickListener, doubleClickListener) {
        var nonExpandedRows = TableUtils._getNonExpandedRows(tbodyElement.rows);
        nonExpandedRows.forEach(function (row) { return TableUtils._attachRowClickListener(row, clickListener, doubleClickListener); });
    };
    TableUtils._attachRowClickListener = function (row, clickListener, doubleClickListener) {
        var checkboxElement = TableUtils._getCheckboxElement(row);
        checkboxElement.addEventListener('change', clickListener);
        row.addEventListener('dblclick', doubleClickListener);
    };
    /**
     * Removes the click listeners from every table row.
     * @param tbodyElement The table body element.
     * @param listener The click listener.
     */
    TableUtils._detachRowClickListeners = function (tbodyElement, clickListener, doubleClickListener) {
        Array.from(tbodyElement.rows).forEach(function (row) {
            var checkboxElement = TableUtils._getCheckboxElement(row);
            if (checkboxElement) {
                checkboxElement.addEventListener('change', clickListener);
                row.removeEventListener('dblclick', doubleClickListener);
            }
        });
    };
    /**
     * Attaches a click listener to the last table header row select all cell.
     */
    TableUtils._attachSelectAllListener = function (theadElement, listener) {
        var lastTheadRow = theadElement.rows[theadElement.rows.length - 1];
        var checkboxElement = TableUtils._getCheckboxElement(lastTheadRow);
        if (!checkboxElement) {
            throw new Error('Checkbox element not found.');
        }
        checkboxElement.addEventListener('change', listener);
    };
    /**
     * Removes the select all click listener.
     * @param theadElement The table head element.
     * @param listener The click listener.
     */
    TableUtils._detachSelectAllListener = function (theadElement, listener) {
        var lastTheadRow = theadElement.rows[theadElement.rows.length - 1];
        var checkboxElement = TableUtils._getCheckboxElement(lastTheadRow);
        if (!checkboxElement) {
            return;
        }
        checkboxElement.removeEventListener('change', listener);
    };
    /**
     * Attaches a click listener to the first row of the table header to handle mouse events.
     * @param theadElement
     * @param listener
     */
    TableUtils._attachHeadRowMouseDownListener = function (theadElement, listener) {
        var firstRow = theadElement.rows[0];
        if (!firstRow) {
            throw new Error('Missing table header row. Unable to attach sort listener.');
        }
        firstRow.addEventListener('mousedown', listener);
    };
    /**
     * Creates the select column as the first column in the table.
     * @param theadElement
     * @param tbodyElement
     */
    TableUtils._createSelectColumn = function (theadElement, tbodyElement, showSelectAll) {
        if (theadElement) {
            TableUtils._createHeadSelectColumn(theadElement, showSelectAll);
        }
        if (tbodyElement) {
            TableUtils._createBodySelectColumn(tbodyElement);
        }
    };
    /**
     * Creates the select column in the table head.
     * @param {HTMLTableSectionElement} theadElement The table head element.
     * @param {boolean} showSelectAll Whether to show the select all checkbox or not.
     */
    TableUtils._createHeadSelectColumn = function (theadElement, showSelectAll) {
        Array.from(theadElement.rows).forEach(function (row) {
            var th = document.createElement('th');
            addClass([
                TABLE_CONSTANTS.classes.TABLE_CELL,
                TABLE_CONSTANTS.classes.TABLE_HEAD_CELL
            ], th);
            row.insertBefore(th, row.cells.item(0));
        });
        if (showSelectAll) {
            var lastRowFirstCell = theadElement.rows.item(theadElement.rows.length - 1).cells.item(0);
            lastRowFirstCell.classList.add(TABLE_CONSTANTS.classes.TABLE_CELL_SELECT);
            lastRowFirstCell.appendChild(TableUtils._createCheckboxElement(true));
        }
    };
    /**
     * Creates the select column in the table body.
     * @param {HTMLTableSectionElement} tbodyElement The table body element.
     */
    TableUtils._createBodySelectColumn = function (tbodyElement) {
        var nonExpandedRows = TableUtils._getNonExpandedRows(tbodyElement.rows);
        nonExpandedRows.forEach(function (row) { return TableUtils._addRowSelectColumn(row); });
        // Update the colspan on the expanded rows
        if (tbodyElement.rows.length) {
            var firstRow = tbodyElement.rows.item(0);
            if (firstRow) {
                TableUtils._updateExpandedRowColspan(tbodyElement.rows, firstRow.cells.length);
            }
        }
    };
    TableUtils._addRowSelectColumn = function (row) {
        var td = row.insertCell(0);
        addClass([
            TABLE_CONSTANTS.classes.TABLE_CELL,
            TABLE_CONSTANTS.classes.TABLE_BODY_CELL,
            TABLE_CONSTANTS.classes.TABLE_CELL_SELECT
        ], td);
        td.appendChild(TableUtils._createCheckboxElement(false));
    };
    /**
     * Removes the first cell (select cell) in every row in the table head and table body elements.
     * @param {HTMLTableSectionElement} theadElement The table head element.
     * @param {HTMLTableSectionElement} tbodyElement The table body element.
     */
    TableUtils._destroySelectColumn = function (theadElement, tbodyElement) {
        var nonExpandedRows = TableUtils._getNonExpandedRows(tbodyElement.rows);
        Array.from(theadElement.rows).forEach(function (row) { return row.removeChild(row.cells.item(0)); });
        Array.from(nonExpandedRows).forEach(function (row) { return row.removeChild(row.cells.item(0)); });
        // Update the colspan on the expanded rows
        if (tbodyElement.rows.length) {
            var firstRow = tbodyElement.rows.item(0);
            if (firstRow) {
                TableUtils._updateExpandedRowColspan(tbodyElement.rows, firstRow.cells.length);
            }
        }
    };
    /**
     * Creates a checkbox element for the select column.
     */
    TableUtils._createCheckboxElement = function (isHeader) {
        var checkboxContainer = document.createElement('div');
        checkboxContainer.classList.add(TABLE_CONSTANTS.classes.TABLE_CELL_SELECT_CHECKBOX_CONTAINER);
        var checkboxElement = document.createElement(CHECKBOX_CONSTANTS.elementName);
        var checkboxInputElement = document.createElement('input');
        checkboxInputElement.type = 'checkbox';
        checkboxInputElement.setAttribute('aria-label', 'Select row');
        checkboxElement.appendChild(checkboxInputElement);
        checkboxContainer.appendChild(checkboxElement);
        return checkboxContainer;
    };
    /**
     * Retrieves the checkbox element from the given table row. Used in select mode only.
     * @param rowElement
     */
    TableUtils._getCheckboxElement = function (rowElement) {
        return rowElement.querySelector(TABLE_CONSTANTS.selectors.CHECKBOX_INPUT);
    };
    /**
     * Sets the checked state of the select checkbox.
     * @param checkboxElement
     * @param isSelected
     */
    TableUtils._setSelectedCheckboxState = function (checkboxElement, isSelected) {
        checkboxElement.checked = isSelected;
    };
    /**
     * Updates the selected state on the provided table row element.
     * @param rowElement
     * @param isSelected
     */
    TableUtils._setRowSelectedState = function (rowElement, isSelected) {
        if (isSelected) {
            if (!rowElement.classList.contains(TABLE_CONSTANTS.classes.TABLE_BODY_ROW_SELECTED)) {
                rowElement.classList.add(TABLE_CONSTANTS.classes.TABLE_BODY_ROW_SELECTED);
            }
        }
        else {
            rowElement.classList.remove(TABLE_CONSTANTS.classes.TABLE_BODY_ROW_SELECTED);
        }
    };
    /**
     * Resets the colspan on all expanded rows to the proper value.
     */
    TableUtils._updateExpandedRowColspan = function (rows, cellCount) {
        var expandedRows = TableUtils._getExpandedRows(rows);
        expandedRows.forEach(function (row) {
            var cell = row.cells.item(0);
            if (cell) {
                cell.colSpan = cellCount;
            }
        });
    };
    /**
     * Updates the state of a table row element to be selected or not.
     * @param {HTMLTableRowElement} rowElement The row element.
     * @param {boolean} isSelected Whether the row is selected or not.
     */
    TableUtils.updateSelectedState = function (rowElement, isSelected) {
        TableUtils._setRowSelectedState(rowElement, isSelected);
        TableUtils._setSelectedCheckboxState(TableUtils._getCheckboxElement(rowElement), isSelected);
    };
    /**
     * Get a row from the first table body by index.
     */
    TableUtils.getRowByIndex = function (tableElement, index) {
        return TableUtils._getNonExpandedRows(tableElement.tBodies[0].rows)[index];
    };
    /**
     * Updates the state of the select all checkbox.
     * @param {HTMLTableElement} tableElement The table element.
     * @param {boolean} isAllSelected Whether the checkboxes should be checked or not.
     */
    TableUtils.updateSelectAllState = function (tableElement, isAllSelected) {
        if (!tableElement.tHead) {
            return;
        }
        var lastTheadRow = tableElement.tHead.rows[tableElement.tHead.rows.length - 1];
        var selectAllCheckboxElement = lastTheadRow.cells[0].querySelector(TABLE_CONSTANTS.selectors.CHECKBOX_INPUT);
        if (selectAllCheckboxElement) {
            TableUtils._setSelectedCheckboxState(selectAllCheckboxElement, isAllSelected);
        }
    };
    /**
     * Sets the selected rows in the table.
     * @param tableElement
     * @param key
     * @param data
     * @param selectedRows
     * @param preserveExisting
     */
    TableUtils.setSelectedRows = function (tableElement, key, data, selectedRows, preserveExisting) {
        if (preserveExisting === void 0) { preserveExisting = false; }
        if (!tableElement.tBodies.length) {
            return;
        }
        if (!preserveExisting) {
            TableUtils.clearSelectedRows(tableElement);
        }
        var rows = tableElement.tBodies[0].rows;
        var selectedRowCount = 0;
        if (selectedRows.length) {
            Array.from(rows).forEach(function (row, index) {
                var rowData = data[index];
                var existingSelection = findWhere(selectedRows, createPredicate(key, rowData));
                if (existingSelection) {
                    TableUtils._setRowSelectedState(row, true);
                    TableUtils._setSelectedCheckboxState(TableUtils._getCheckboxElement(row), true);
                    selectedRowCount++;
                }
            });
        }
        TableUtils.updateSelectAllState(tableElement, selectedRowCount > 0 && rows.length === selectedRowCount);
    };
    /**
     * Clears all selected rows in the table.
     * @param tableElement
     */
    TableUtils.clearSelectedRows = function (tableElement) {
        if (!tableElement.tBodies.length) {
            return;
        }
        var nonExpandedRows = TableUtils._getNonExpandedRows(tableElement.tBodies[0].rows);
        nonExpandedRows.forEach(function (row) {
            TableUtils._setRowSelectedState(row, false);
            TableUtils._setSelectedCheckboxState(TableUtils._getCheckboxElement(row), false);
        });
    };
    /**
     * Sets the sorted column in the table.
     * @param tableElement
     * @param columnIndex
     * @param sortDirection
     */
    TableUtils.setSortedColumn = function (tableElement, columnIndex, sortDirection) {
        var cell = TableUtils._getHeaderCellByIndex(tableElement, columnIndex);
        // Set the active class on the icon
        var iconElement = TableUtils._getSortIconElementFromHeaderCell(cell);
        iconElement.classList.add(TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_SORT_ICON_ACTIVE);
        // Set the sort direction on the header cell
        TableUtils._setColumnSortDirection(cell, sortDirection);
    };
    /**
     * Removes the sorted column in the table.
     * @param tableElement
     * @param columnIndex
     */
    TableUtils.removeColumnSort = function (tableElement, columnIndex) {
        var cell = TableUtils._getHeaderCellByIndex(tableElement, columnIndex);
        // Remove any existing sort direction classes from the existing th element
        if (cell.classList.contains(TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_SORTED_ASCENDING)) {
            cell.classList.remove(TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_SORTED_ASCENDING);
        }
        if (cell.classList.contains(TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_SORTED_DESCENDING)) {
            cell.classList.remove(TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_SORTED_DESCENDING);
        }
        // Remove the active class from the existing active sorted icon element
        var existingIconElement = TableUtils._getSortIconElementFromHeaderCell(cell);
        if (existingIconElement && existingIconElement.classList.contains(TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_SORT_ICON_ACTIVE)) {
            existingIconElement.classList.remove(TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_SORT_ICON_ACTIVE);
        }
    };
    /**
     * Retrieves a header cell from the table based on index.
     * @param {HTMLTableElement} tableElement The table element.
     * @param {number} index The column index.
     */
    TableUtils._getHeaderCellByIndex = function (tableElement, index) {
        if (!tableElement.tHead) {
            throw new Error('Table head element cannot be null.');
        }
        var headerRow = tableElement.tHead.rows.item(0);
        return headerRow.cells.item(index);
    };
    /**
     * Gets the sort icon element from the table cell that contains the select all checkbox.
     * @param {HTMLTableHeaderCellElement} cell The table header cell element.
     */
    TableUtils._getSortIconElementFromHeaderCell = function (cell) {
        return cell.querySelector("." + TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_SORT_ICON);
    };
    /**
     * Adds/removes the select column on the table.
     */
    TableUtils.setSelectColumnVisibility = function (tableElement, isVisible, selectListener, selectDoubleListener, selectAllListener) {
        var theadElement = tableElement.tHead;
        var tbodyElement = tableElement.tBodies[0];
        if (!theadElement || !tbodyElement) {
            return;
        }
        if (isVisible) {
            TableUtils._addSelectColumn(theadElement, tbodyElement, selectListener, selectDoubleListener, selectAllListener);
        }
        else {
            if (selectListener) {
                TableUtils._detachRowClickListeners(tbodyElement, selectListener, selectDoubleListener);
            }
            if (selectAllListener) {
                TableUtils._detachSelectAllListener(theadElement, selectAllListener);
            }
            TableUtils._destroySelectColumn(theadElement, tbodyElement);
        }
    };
    /**
     * Adds the select column to the thead/tbody elements and attaches the provided click listeners.
     * @param theadElement The table head element.
     * @param tbodyElement The table body element.
     * @param selectListener The row select listener.
     * @param selectAllListener The select all checkbox listener.
     */
    TableUtils._addSelectColumn = function (theadElement, tbodyElement, selectListener, selectDoubleListener, selectAllListener) {
        TableUtils._createSelectColumn(theadElement, tbodyElement, !!selectAllListener);
        if (selectListener) {
            TableUtils._attachRowClickListeners(tbodyElement, selectListener, selectDoubleListener);
        }
        if (selectAllListener) {
            TableUtils._attachSelectAllListener(theadElement, selectAllListener);
        }
    };
    /**
     * Adds/removes the dense class on the table.
     */
    TableUtils.setDenseState = function (tableElement, isDense) {
        if (tableElement.classList.contains(TABLE_CONSTANTS.classes.TABLE_DENSE)) {
            tableElement.classList.remove(TABLE_CONSTANTS.classes.TABLE_DENSE);
        }
        if (isDense) {
            tableElement.classList.add(TABLE_CONSTANTS.classes.TABLE_DENSE);
        }
    };
    TableUtils.setResizable = function (configuration) {
        var thead = configuration.tableElement.tHead;
        if (configuration.resizable) {
            configuration.tableElement.classList.add(TABLE_CONSTANTS.classes.TABLE_RESIZABLE);
            TableUtils._addResizeHandles(thead, configuration);
        }
        else {
            configuration.tableElement.classList.remove(TABLE_CONSTANTS.classes.TABLE_RESIZABLE);
            TableUtils._removeResizeHandles(thead);
        }
    };
    TableUtils._addResizeHandles = function (thead, configuration) {
        if (!thead) {
            return;
        }
        var firstRow = thead.rows.item(0);
        if (firstRow) {
            var cells = Array.from(firstRow.cells);
            // If the select column is on, we need to skip the first cell
            if (configuration.selectListener) {
                cells = cells.slice(1);
            }
            for (var _i = 0, cells_1 = cells; _i < cells_1.length; _i++) {
                var cell = cells_1[_i];
                var index = cells.indexOf(cell);
                var columnConfig = configuration.columnConfigurations[index];
                if (columnConfig.resizable !== false) {
                    var resizeHandle = document.createElement('div');
                    resizeHandle.classList.add(TABLE_CONSTANTS.classes.TABLE_RESIZE_HANDLE);
                    cell.appendChild(resizeHandle);
                }
            }
        }
    };
    TableUtils._removeResizeHandles = function (thead) {
        if (!thead) {
            return;
        }
        var firstRow = thead.rows.item(0);
        if (firstRow) {
            var cells = Array.from(firstRow.cells);
            for (var _i = 0, cells_2 = cells; _i < cells_2.length; _i++) {
                var cell = cells_2[_i];
                var resizeHandle = document.querySelector(TABLE_CONSTANTS.classes.TABLE_RESIZE_HANDLE);
                if (resizeHandle) {
                    cell.appendChild(resizeHandle);
                }
            }
        }
    };
    /**
     * Adds/removes the wrap class on the table.
     */
    TableUtils.setWrapContentState = function (tableElement, wrapContent) {
        if (wrapContent) {
            tableElement.classList.remove(TABLE_CONSTANTS.classes.TABLE_NO_WRAP_CONTENT);
        }
        else {
            tableElement.classList.add(TABLE_CONSTANTS.classes.TABLE_NO_WRAP_CONTENT);
        }
    };
    /**
     *
     * @param {HTMLTableElement} tableElement The table element.
     * @param {boolean} isVisible Whether the select all option is visible or not.
     */
    TableUtils.setSelectAllVisibility = function (tableElement, isVisible, listener) {
        var theadElement = tableElement.tHead;
        if (!theadElement) {
            return;
        }
        if (!isVisible && listener) {
            TableUtils._detachSelectAllListener(theadElement, listener);
        }
        var lastTheadRow = theadElement.rows[theadElement.rows.length - 1];
        var selectAllCell = lastTheadRow.cells.item(0);
        if (isVisible) {
            // Only add the checkbox if it doesn't already exist
            if (!selectAllCell.childElementCount) {
                selectAllCell.classList.add(TABLE_CONSTANTS.classes.TABLE_CELL_SELECT);
                selectAllCell.appendChild(TableUtils._createCheckboxElement(true));
                if (listener) {
                    TableUtils._attachSelectAllListener(theadElement, listener);
                }
            }
        }
        else {
            if (listener) {
                TableUtils._detachSelectAllListener(theadElement, listener);
            }
            removeAllChildren(selectAllCell);
        }
    };
    /**
     * Controls the visibility of the table filter row.
     * @param {ITableConfiguration} configuration The table configuration.
     */
    TableUtils.setFilterRow = function (configuration) {
        if (!configuration.tableElement.tHead) {
            return;
        }
        var filterRowElement = TableUtils._getFilterRowElement(configuration.tableElement.tHead);
        var selectAllCell;
        // Toggle the filter visible class on the table element to control styles of rows/cells
        if (configuration.filter) {
            configuration.tableElement.classList.add(TABLE_CONSTANTS.classes.TABLE_FILTER_VISIBLE);
        }
        else {
            configuration.tableElement.classList.remove(TABLE_CONSTANTS.classes.TABLE_FILTER_VISIBLE);
        }
        if (configuration.filter && !filterRowElement) {
            // We can stop here if there are no filterable columns
            if (configuration.columnConfigurations.every(function (cc) { return !cc.filter; })) {
                return;
            }
            // Gets the select all cell contents so it can be moved to the new filter row after creating the row element
            if (configuration.selectListener && configuration.selectAllListener) {
                var lastTheadRow = configuration.tableElement.tHead.rows[configuration.tableElement.tHead.rows.length - 1];
                selectAllCell = lastTheadRow.cells.item(0);
            }
            var rowElement_1 = TableUtils._createFilterRowElement(configuration);
            // Move the select all cell to the new filter row
            if (rowElement_1 && selectAllCell) {
                Array.from(selectAllCell.children).forEach(function (child) {
                    rowElement_1.cells.item(0).appendChild(child);
                });
            }
        }
        else if (!configuration.filter && filterRowElement) {
            // Move the select all cell to the previous row
            if (configuration.selectListener && configuration.selectAllListener) {
                selectAllCell = filterRowElement.cells.item(0);
                var previousRowElementIndex = Array.from(configuration.tableElement.tHead.rows).indexOf(filterRowElement) - 1;
                var previousRowElement_1 = configuration.tableElement.tHead.rows.item(previousRowElementIndex);
                if (previousRowElement_1) {
                    Array.from(selectAllCell.children).forEach(function (child) {
                        previousRowElement_1.cells.item(0).appendChild(child);
                    });
                }
            }
            removeElement(filterRowElement);
        }
    };
    /**
     * Creates the table filter row element.
     * @param {ITableConfiguration} configuration The table configuration.
     */
    TableUtils._createFilterRowElement = function (configuration) {
        if (!configuration.tableElement.tHead) {
            throw new Error('Table head element cannot be null.');
        }
        var filterRow = configuration.tableElement.tHead.insertRow();
        addClass([
            TABLE_CONSTANTS.classes.TABLE_ROW,
            TABLE_CONSTANTS.classes.TABLE_HEAD_ROW_FILTER
        ], filterRow);
        if (configuration.selectListener) {
            var th = document.createElement('th');
            addClass([
                TABLE_CONSTANTS.classes.TABLE_CELL,
                TABLE_CONSTANTS.classes.TABLE_HEAD_CELL
            ], th);
            filterRow.insertBefore(th, filterRow.cells[0]);
            th.classList.add(TABLE_CONSTANTS.classes.TABLE_CELL_SELECT);
        }
        configuration.columnConfigurations.forEach(function (columnConfig, columnIndex) {
            var th = document.createElement('th');
            addClass([
                TABLE_CONSTANTS.classes.TABLE_CELL,
                TABLE_CONSTANTS.classes.TABLE_HEAD_CELL
            ], th);
            if (columnConfig.filter && isDefined(columnConfig.filterDelegate)) {
                var container = document.createElement('div');
                container.classList.add(TABLE_CONSTANTS.classes.TABLE_HEAD_CELL_CONTAINER);
                if (columnConfig.align) {
                    TableUtils._setCellAlignmentClass(container, columnConfig.align);
                }
                var element = TableUtils._createFilterElement(columnConfig, columnIndex, configuration.filterListener);
                container.appendChild(element);
                th.appendChild(container);
            }
            filterRow.appendChild(th);
        });
        return filterRow;
    };
    /**
     * Creates the element that will be used as the filter for this column.
     * @param {IColumnConfiguration} columnConfig The column configuration.
     */
    TableUtils._createFilterElement = function (columnConfig, columnIndex, filterListener) {
        var delegate;
        if (isFunction(columnConfig.filterDelegate)) {
            delegate = columnConfig.filterDelegate();
        }
        else if (columnConfig.filterDelegate instanceof AbstractComponentDelegate) {
            delegate = columnConfig.filterDelegate;
        }
        else {
            throw new Error('Invalid filter delegate.');
        }
        if (filterListener) {
            if (!isDefined(columnConfig.filterDebounceTime) || isNumber(columnConfig.filterDebounceTime)) {
                var debounceTime = isDefined(columnConfig.filterDebounceTime) ? columnConfig.filterDebounceTime : TABLE_CONSTANTS.numbers.DEFAULT_FILTER_DEBOUNCE_TIME;
                delegate.onChange(debounce(function (value) { return filterListener(value, columnIndex); }, debounceTime));
            }
            else {
                delegate.onChange(function (value) { return filterListener(value, columnIndex); });
            }
        }
        return delegate.component;
    };
    /**
     * Gets the filter row from the table head element rows, or undefined if not found.
     * @param {HTMLTableRowElement | undefined} tHeadElement The thead element.
     */
    TableUtils._getFilterRowElement = function (tHeadElement) {
        if (!tHeadElement) {
            return;
        }
        return Array.from(tHeadElement.rows).find(function (r) { return r.classList.contains(TABLE_CONSTANTS.classes.TABLE_HEAD_ROW_FILTER); });
    };
    /**
     * Expands a row by inserting a new row beneath it and displaying the provided template content.
     * @param configuration The table configuration.
     * @param rowIndex The index of the row to expand.
     * @param template The template for the expanded row content.
     */
    TableUtils.expandRow = function (configuration, rowIndex, template) {
        return __awaiter(this, void 0, void 0, function () {
            var tbody, nonExpandedRows, requestedRow, actualRowIndex, expandedRow, td, contentDiv, expansionPanel, content, templateValue, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tbody = configuration.tableElement.tBodies[0];
                        nonExpandedRows = TableUtils._getNonExpandedRows(tbody.rows);
                        requestedRow = nonExpandedRows[rowIndex];
                        requestedRow.classList.add(TABLE_CONSTANTS.classes.TABLE_ROW_EXPANDED);
                        actualRowIndex = Array.from(tbody.rows).indexOf(requestedRow);
                        expandedRow = tbody.insertRow(actualRowIndex + 1);
                        addClass([
                            TABLE_CONSTANTS.classes.TABLE_ROW,
                            TABLE_CONSTANTS.classes.TABLE_BODY_ROW,
                            TABLE_CONSTANTS.classes.TABLE_ROW_EXPANDABLE_CONTENT
                        ], expandedRow);
                        td = expandedRow.insertCell();
                        td.setAttribute('colspan', requestedRow.cells.length.toString());
                        td.classList.add(TABLE_CONSTANTS.classes.TABLE_ROW_EXPANDABLE_CONTENT_CELL);
                        contentDiv = document.createElement('div');
                        expansionPanel = document.createElement(EXPANSION_PANEL_CONSTANTS.elementName);
                        expansionPanel.appendChild(contentDiv);
                        td.appendChild(expansionPanel);
                        content = template;
                        if (!isFunction(template)) return [3 /*break*/, 4];
                        templateValue = template(rowIndex, contentDiv);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, Promise.resolve(templateValue)];
                    case 2:
                        content = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error('Failed to load row template ' + e_1.message);
                        content = '';
                        return [3 /*break*/, 4];
                    case 4:
                        if (content) {
                            TableUtils._setRowTemplate(contentDiv, content);
                        }
                        window.requestAnimationFrame(function () { return expansionPanel.open = true; });
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Sets the template content in the table row element.
     * @param {HTMLElement} element The element to the append the template to.
     * @param {string | HTMLElement} template The template content.
     */
    TableUtils._setRowTemplate = function (element, template) {
        if (isString(template)) {
            element.innerHTML = template;
        }
        else {
            element.appendChild(template);
        }
    };
    /**
     * Collapses an expanded table row.
     * @param configuration The table configuration.
     * @param rowIndex The index of the row to collapse.
     */
    TableUtils.collapseRow = function (configuration, rowIndex) {
        if (!configuration.tableElement || !configuration.tableElement.tBodies.length || !configuration.tableElement.tBodies[0].rows.length) {
            return Promise.resolve();
        }
        var tbody = configuration.tableElement.tBodies[0];
        var nonExpandedRows = TableUtils._getNonExpandedRows(tbody.rows);
        var requestedRow = nonExpandedRows[rowIndex];
        var actualRowIndex = Array.from(tbody.rows).indexOf(requestedRow);
        if (requestedRow && requestedRow.classList.contains(TABLE_CONSTANTS.classes.TABLE_ROW_EXPANDED)) {
            var expandableRow_1 = tbody.rows[actualRowIndex + 1];
            var expansionPanel = expandableRow_1.querySelector(EXPANSION_PANEL_CONSTANTS.elementName);
            if (expansionPanel && expansionPanel.open) {
                expansionPanel.open = false;
                return new Promise(function (resolve) {
                    setTimeout(function () {
                        requestedRow.classList.remove(TABLE_CONSTANTS.classes.TABLE_ROW_EXPANDED);
                        removeElement(expandableRow_1);
                        resolve();
                    }, EXPANSION_PANEL_CONSTANTS.numbers.COLLAPSE_ANIMATION_DURATION);
                });
            }
        }
        return Promise.resolve();
    };
    /**
     * Checks if a row is expanded or not.
     * @param configuration The table configuration.
     * @param rowIndex The row index.
     * @returns {boolean}
     */
    TableUtils.isRowExpanded = function (configuration, rowIndex) {
        var tbody = configuration.tableElement.tBodies[0];
        var nonExpandedRows = TableUtils._getNonExpandedRows(tbody.rows);
        var requestedRow = nonExpandedRows[rowIndex];
        return requestedRow && requestedRow.classList.contains(TABLE_CONSTANTS.classes.TABLE_ROW_EXPANDED);
    };
    /**
     * Sets the fixed class on the table element based on whether fixed headers were requested or not.
     * @param configuration The table configuration.
     */
    TableUtils.setFixedHeaders = function (configuration) {
        if (configuration.fixedHeaders) {
            configuration.tableElement.classList.add(TABLE_CONSTANTS.classes.TABLE_FIXED);
        }
        else {
            configuration.tableElement.classList.remove(TABLE_CONSTANTS.classes.TABLE_FIXED);
        }
    };
    /**
     * Sets the table layout algorithm.
     * @param configuration The table configuration.
     */
    TableUtils.setLayoutType = function (configuration) {
        if (configuration.layoutType === 'fixed') {
            configuration.tableElement.classList.add(TABLE_CONSTANTS.classes.TABLE_LAYOUT_FIXED);
        }
        else {
            configuration.tableElement.classList.remove(TABLE_CONSTANTS.classes.TABLE_LAYOUT_FIXED);
        }
    };
    /**
     * Ensures that any columns width styles are within the bounds of available space.
     * @param configuration The table configuration.
     */
    TableUtils.normalizeColumnWidths = function (configuration) {
        var tableElement = configuration.tableElement;
        if (tableElement.tHead && tableElement.tHead.rows.length) {
            var cells = Array.from(tableElement.tHead.rows[0].cells);
            for (var _i = 0, cells_3 = cells; _i < cells_3.length; _i++) {
                var cell = cells_3[_i];
                var expectedWidth = getComputedStyle(cell).width;
                if (cell.style.width && cell.style.width !== expectedWidth) {
                    cell.style.width = expectedWidth;
                }
            }
        }
    };
    /**
     * Toggles the visibility of the resize cell indicator for all table cells.
     * @param configuration The table configuration.
     * @param columnIndex The cell index of the column being resized.
     * @param isVisible Whether to show the indicator or not.
     */
    TableUtils.setColumnResizeIndicatorVisibility = function (configuration, columnIndex, isVisible) {
        var tableElement = configuration.tableElement;
        if (tableElement.tHead && tableElement.tHead.rows.length) {
            var rows = Array.from(tableElement.tHead.rows);
            for (var _i = 0, rows_1 = rows; _i < rows_1.length; _i++) {
                var row = rows_1[_i];
                var cell = row.cells[columnIndex];
                if (cell) {
                    if (isVisible) {
                        cell.classList.add(TABLE_CONSTANTS.classes.TABLE_CELL_RESIZABLE);
                    }
                    else {
                        cell.classList.remove(TABLE_CONSTANTS.classes.TABLE_CELL_RESIZABLE);
                    }
                }
            }
        }
        if (tableElement.tBodies.length) {
            var rows = Array.from(tableElement.tBodies[0].rows);
            for (var _a = 0, rows_2 = rows; _a < rows_2.length; _a++) {
                var row = rows_2[_a];
                var cell = row.cells[columnIndex];
                if (cell) {
                    if (isVisible) {
                        cell.classList.add(TABLE_CONSTANTS.classes.TABLE_CELL_RESIZABLE);
                    }
                    else {
                        cell.classList.remove(TABLE_CONSTANTS.classes.TABLE_CELL_RESIZABLE);
                    }
                }
            }
        }
    };
    TableUtils.getOwnerDocument = function (el) {
        return el.ownerDocument || document;
    };
    return TableUtils;
}());
export { TableUtils };
