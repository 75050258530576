import { __decorate, __extends } from "tslib";
import { MDCRipple } from '@material/ripple';
import { CustomElement, coerceBoolean, ensureChildren } from '@tyler-components-web/core';
import { FLOATING_ACTION_BUTTON_CONSTANTS } from './floating-action-button-constants';
import { getThemeColor } from '../theme';
/**
 * The custom element class behind the `<tcw-fab>` element.
 */
var FloatingActionButton = /** @class */ (function (_super) {
    __extends(FloatingActionButton, _super);
    function FloatingActionButton() {
        var _this = _super.call(this) || this;
        _this._isExtended = false;
        _this._isMini = false;
        _this._isExited = false;
        return _this;
    }
    Object.defineProperty(FloatingActionButton, "observedAttributes", {
        get: function () {
            return [
                FLOATING_ACTION_BUTTON_CONSTANTS.attributes.EXITED,
                FLOATING_ACTION_BUTTON_CONSTANTS.attributes.MINI,
                FLOATING_ACTION_BUTTON_CONSTANTS.attributes.EXTENDED,
                FLOATING_ACTION_BUTTON_CONSTANTS.attributes.COLOR
            ];
        },
        enumerable: true,
        configurable: true
    });
    FloatingActionButton.prototype.connectedCallback = function () {
        var _this = this;
        if (this.children.length) {
            this._initialize();
        }
        else {
            ensureChildren(this).then(function () { return _this._initialize(); });
        }
    };
    FloatingActionButton.prototype.disconnectedCallback = function () {
        if (this._mdcRipple) {
            this._mdcRipple.destroy();
        }
    };
    FloatingActionButton.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case FLOATING_ACTION_BUTTON_CONSTANTS.attributes.EXITED:
                this.exited = coerceBoolean(newValue);
                break;
            case FLOATING_ACTION_BUTTON_CONSTANTS.attributes.MINI:
                this.mini = coerceBoolean(newValue);
                break;
            case FLOATING_ACTION_BUTTON_CONSTANTS.attributes.EXTENDED:
                this.extended = coerceBoolean(newValue);
                break;
            case FLOATING_ACTION_BUTTON_CONSTANTS.attributes.COLOR:
                this.color = newValue;
                break;
        }
    };
    Object.defineProperty(FloatingActionButton.prototype, "exited", {
        get: function () {
            return this._isExited;
        },
        /** Gets/sets the exited state. */
        set: function (value) {
            if (this._isExited !== value) {
                this._isExited = value;
                this.setAttribute(FLOATING_ACTION_BUTTON_CONSTANTS.attributes.EXITED, this._isExited.toString());
                this._sync();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FloatingActionButton.prototype, "mini", {
        get: function () {
            return this._isMini;
        },
        /** Gets/sets the mini state. */
        set: function (value) {
            if (this._isMini !== value) {
                this._isMini = value;
                this.setAttribute(FLOATING_ACTION_BUTTON_CONSTANTS.attributes.MINI, this._isMini.toString());
                this._sync();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FloatingActionButton.prototype, "extended", {
        get: function () {
            return this._isExtended;
        },
        /** Gets/sets the extended state. */
        set: function (value) {
            if (this._isExtended !== value) {
                this._isExtended = value;
                this.setAttribute(FLOATING_ACTION_BUTTON_CONSTANTS.attributes.EXTENDED, this._isExtended.toString());
                this._sync();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FloatingActionButton.prototype, "color", {
        get: function () {
            return this._color;
        },
        /** Gets/sets the color theme type. */
        set: function (value) {
            if (this._color !== value) {
                this._color = value;
                this.setAttribute(FLOATING_ACTION_BUTTON_CONSTANTS.attributes.COLOR, this._color.toString());
                this._sync();
            }
        },
        enumerable: true,
        configurable: true
    });
    FloatingActionButton.prototype._initialize = function () {
        // Make sure we have a button element
        this._buttonElement = this.querySelector(FLOATING_ACTION_BUTTON_CONSTANTS.selectors.BUTTON);
        if (!this._buttonElement) {
            return;
        }
        this._buttonElement.classList.add(FLOATING_ACTION_BUTTON_CONSTANTS.classes.BUTTON);
        // Check if we need to set the extended class
        if (this.hasAttribute(FLOATING_ACTION_BUTTON_CONSTANTS.attributes.EXTENDED)) {
            this.extended = coerceBoolean(this.getAttribute(FLOATING_ACTION_BUTTON_CONSTANTS.attributes.EXTENDED));
        }
        // Check if we need to set the mini class
        if (this.hasAttribute(FLOATING_ACTION_BUTTON_CONSTANTS.attributes.MINI)) {
            this.mini = coerceBoolean(this.getAttribute(FLOATING_ACTION_BUTTON_CONSTANTS.attributes.MINI));
        }
        // Check if we need to set the exited class
        if (this.hasAttribute(FLOATING_ACTION_BUTTON_CONSTANTS.attributes.EXITED)) {
            this.exited = coerceBoolean(this.getAttribute(FLOATING_ACTION_BUTTON_CONSTANTS.attributes.EXITED));
        }
        this._sync();
        this._mdcRipple = new MDCRipple(this._buttonElement);
    };
    FloatingActionButton.prototype._sync = function () {
        if (this._buttonElement) {
            if (this._isExited) {
                this._buttonElement.classList.add(FLOATING_ACTION_BUTTON_CONSTANTS.classes.EXITED);
            }
            else {
                this._buttonElement.classList.remove(FLOATING_ACTION_BUTTON_CONSTANTS.classes.EXITED);
            }
            if (this._isMini) {
                this._buttonElement.classList.add(FLOATING_ACTION_BUTTON_CONSTANTS.classes.BUTTON_MINI);
            }
            else {
                this._buttonElement.classList.remove(FLOATING_ACTION_BUTTON_CONSTANTS.classes.BUTTON_MINI);
            }
            if (this._isExtended) {
                this._buttonElement.classList.add(FLOATING_ACTION_BUTTON_CONSTANTS.classes.BUTTON_EXTENDED);
            }
            else {
                this._buttonElement.classList.remove(FLOATING_ACTION_BUTTON_CONSTANTS.classes.BUTTON_EXTENDED);
            }
            if (this._color) {
                this.style.setProperty('--mdc-theme-secondary', getThemeColor(this._color));
            }
            else {
                this._buttonElement.style.removeProperty('--mdc-theme-secondary');
            }
            // Check if we need to set the label class
            var labelElement = this.querySelector(FLOATING_ACTION_BUTTON_CONSTANTS.selectors.LABEL);
            if (labelElement) {
                labelElement.classList.add(FLOATING_ACTION_BUTTON_CONSTANTS.classes.LABEL);
            }
            // Check if we need to set the icon class
            var iconElement = this.querySelector(FLOATING_ACTION_BUTTON_CONSTANTS.selectors.ICON);
            if (iconElement) {
                iconElement.classList.add(FLOATING_ACTION_BUTTON_CONSTANTS.classes.ICON);
                iconElement.setAttribute('aria-hidden', 'true');
            }
        }
    };
    FloatingActionButton = __decorate([
        CustomElement({
            name: FLOATING_ACTION_BUTTON_CONSTANTS.elementName
        })
    ], FloatingActionButton);
    return FloatingActionButton;
}(HTMLElement));
export { FloatingActionButton };
