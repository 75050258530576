import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "text-field";
var attributes = {
    DENSITY: 'density',
    FLOAT_LABEL_TYPE: 'float-label-type',
    SHAPE: 'shape',
    INVALID: 'invalid',
    REQUIRED: 'required',
    HOST_LABEL_FLOATING: elementName + "-label-floating"
};
var classes = {
    ROOT: 'tyl-text-field',
    FOCUSED: 'tyl-text-field--focused',
    MDC_FLOATING_LABEL: 'mdc-floating-label',
    LABEL_FOCUSED: 'tyl-text-field__label--focused',
    INVALID: 'tyl-text-field--invalid',
    REQUIRED: 'tyl-text-field--required',
    DISABLED: 'tyl-text-field--disabled',
    READONLY: 'tyl-text-field--readonly',
    ROOMY: 'tyl-text-field--roomy',
    DENSE: 'tyl-text-field--dense',
    LEADING_ICON: 'tyl-text-field--leading-icon',
    TRAILING_ICON: 'tyl-text-field--trailing-icon',
    INPUT_FOCUSED: 'tyl-text-field__input--focused',
    SHAPE_ROUNDED: 'tyl-text-field--shape-rounded'
};
var selectors = {
    ROOT: "." + classes.ROOT,
    LABEL: "." + classes.MDC_FLOATING_LABEL,
    NOTCHED_OUTLINE: '.mdc-notched-outline'
};
var numbers = {
    FLOATING_LABEL_SCALE_FACTOR: 0.75
};
export var TEXT_FIELD_CONSTANTS = {
    elementName: elementName,
    classes: classes,
    selectors: selectors,
    numbers: numbers,
    attributes: attributes
};
