import { getShadowElement, removeClass } from '@tyler-components-web/core';
import { LABEL_VALUE_CONSTANTS } from './label-value-constants';
var LabelValueAdapter = /** @class */ (function () {
    function LabelValueAdapter(_component) {
        this._component = _component;
        this._rootElement = getShadowElement(_component, LABEL_VALUE_CONSTANTS.selectors.ROOT);
    }
    LabelValueAdapter.prototype.setHostAttribute = function (name, value) {
        this._component.setAttribute(name, value);
    };
    LabelValueAdapter.prototype.removeHostAttribute = function (name) {
        this._component.removeAttribute(name);
    };
    LabelValueAdapter.prototype.setEmpty = function (isEmpty) {
        if (isEmpty) {
            this._rootElement.classList.add(LABEL_VALUE_CONSTANTS.classes.EMPTY);
        }
        else {
            this._rootElement.classList.remove(LABEL_VALUE_CONSTANTS.classes.EMPTY);
        }
    };
    LabelValueAdapter.prototype.setEllipsis = function (ellipsis) {
        if (ellipsis) {
            this._rootElement.classList.add(LABEL_VALUE_CONSTANTS.classes.ELLIPSIS);
        }
        else {
            this._rootElement.classList.remove(LABEL_VALUE_CONSTANTS.classes.ELLIPSIS);
        }
    };
    LabelValueAdapter.prototype.setRoomy = function (isRoomy) {
        if (isRoomy) {
            this._rootElement.classList.add(LABEL_VALUE_CONSTANTS.classes.ROOMY);
        }
        else {
            this._rootElement.classList.remove(LABEL_VALUE_CONSTANTS.classes.ROOMY);
        }
    };
    LabelValueAdapter.prototype.setDense = function (isDense) {
        if (isDense) {
            this._rootElement.classList.add(LABEL_VALUE_CONSTANTS.classes.DENSE);
        }
        else {
            this._rootElement.classList.remove(LABEL_VALUE_CONSTANTS.classes.DENSE);
        }
    };
    LabelValueAdapter.prototype.setAlignment = function (value) {
        removeClass([
            LABEL_VALUE_CONSTANTS.classes.ALIGN_CENTER,
            LABEL_VALUE_CONSTANTS.classes.ALIGN_RIGHT
        ], this._rootElement);
        switch (value) {
            case 'center':
                this._rootElement.classList.add(LABEL_VALUE_CONSTANTS.classes.ALIGN_CENTER);
                break;
            case 'right':
                this._rootElement.classList.add(LABEL_VALUE_CONSTANTS.classes.ALIGN_RIGHT);
                break;
        }
    };
    return LabelValueAdapter;
}());
export { LabelValueAdapter };
