import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty, coerceBoolean } from '@tyler-components-web/core';
import { ExpansionPanelAdapter } from './expansion-panel-adapter';
import { ExpansionPanelFoundation } from './expansion-panel-foundation';
import { EXPANSION_PANEL_CONSTANTS } from './expansion-panel-constants';
var template = require('./expansion-panel.html');
var styles = require('./expansion-panel.css');
/**
 * A web component that encapsulates the functionality of expanding/collapsing content when clicked.
 */
var ExpansionPanelComponent = /** @class */ (function (_super) {
    __extends(ExpansionPanelComponent, _super);
    function ExpansionPanelComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, EXPANSION_PANEL_CONSTANTS.elementName, template, styles);
        _this._foundation = new ExpansionPanelFoundation(new ExpansionPanelAdapter(_this));
        return _this;
    }
    Object.defineProperty(ExpansionPanelComponent, "observedAttributes", {
        get: function () {
            return [
                EXPANSION_PANEL_CONSTANTS.attributes.OPEN,
                EXPANSION_PANEL_CONSTANTS.attributes.ORIENTATION
            ];
        },
        enumerable: true,
        configurable: true
    });
    ExpansionPanelComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'open');
        upgradeProperty(this, 'orientation');
        this._foundation.initialize();
    };
    ExpansionPanelComponent.prototype.connectedCallback = function () {
        this._foundation.connect();
    };
    ExpansionPanelComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case EXPANSION_PANEL_CONSTANTS.attributes.OPEN:
                this.open = coerceBoolean(newValue);
                break;
            case EXPANSION_PANEL_CONSTANTS.attributes.ORIENTATION:
                this.orientation = newValue;
                break;
        }
    };
    ExpansionPanelComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    Object.defineProperty(ExpansionPanelComponent.prototype, "open", {
        /** Controls the open state of the panel. */
        get: function () {
            return this._foundation.open;
        },
        set: function (value) {
            this._foundation.open = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExpansionPanelComponent.prototype, "openCallback", {
        /**
         * Sets the function to call when the panel wants to open.
         * The function must return a promise which can be resolved to
         * open the panel or rejected which cancels the panel open.
         */
        set: function (callback) {
            this._foundation.openCallback = callback;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExpansionPanelComponent.prototype, "closeCallback", {
        /**
         * Sets the function to call when the panel wants to close.
         * The function must return a promise which can be resolved to
         * close the panel or rejected which cancels the panel close.
         */
        set: function (callback) {
            this._foundation.closeCallback = callback;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExpansionPanelComponent.prototype, "orientation", {
        get: function () {
            return this._foundation.orientation;
        },
        /**
         * Sets the orientation of the panel expansion.
         * Valid values are 'vertical' (default) or 'horizontal'.
         */
        set: function (value) {
            this._foundation.orientation = value;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Toggles the collapsed state.
     */
    ExpansionPanelComponent.prototype.toggle = function () {
        this.open = !this.open;
    };
    ExpansionPanelComponent = __decorate([
        CustomElement({
            name: EXPANSION_PANEL_CONSTANTS.elementName
        })
    ], ExpansionPanelComponent);
    return ExpansionPanelComponent;
}(HTMLElement));
export { ExpansionPanelComponent };
