/**
 * The foundation class behind the `<tcw-option>` element.
 */
var OptionFoundation = /** @class */ (function () {
    function OptionFoundation(_adapter) {
        this._adapter = _adapter;
        this._disabled = false;
    }
    Object.defineProperty(OptionFoundation.prototype, "value", {
        /** Gets/sets the value of this option. */
        get: function () {
            return this._value;
        },
        set: function (value) {
            if (this._value !== value) {
                this._value = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OptionFoundation.prototype, "disabled", {
        /** Gets/sets the disabled status of this option. */
        get: function () {
            return this._disabled;
        },
        set: function (value) {
            if (this._disabled !== value) {
                this._disabled = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    return OptionFoundation;
}());
export { OptionFoundation };
