import { PopupPlacement } from '../popup/popup-constants';
/**
 * Positions the popup around the provided target element relevant to the host element container.
 * @param popupElement The popup element to position around target element.
 * @param position The tooltip position around the target element.
 */
export function setPopupPosition(popupElement, position) {
    popupElement.style.top = 'auto';
    popupElement.style.bottom = 'auto';
    popupElement.style.left = 'auto';
    popupElement.style.right = 'auto';
    switch (position.placement.primary) {
        case PopupPlacement.Top:
            popupElement.style.bottom = position.bottom + 'px';
            break;
        case PopupPlacement.Bottom:
            popupElement.style.top = position.top + 'px';
            break;
        case PopupPlacement.Left:
            popupElement.style.right = position.right + 'px';
            break;
        case PopupPlacement.Right:
            popupElement.style.left = position.left + 'px';
            break;
    }
    switch (position.placement.secondary) {
        case PopupPlacement.Top:
            popupElement.style.top = position.top + 'px';
            break;
        case PopupPlacement.Bottom:
            popupElement.style.bottom = position.bottom + 'px';
            break;
        case PopupPlacement.Left:
            popupElement.style.left = position.left + 'px';
            break;
        case PopupPlacement.Right:
            popupElement.style.right = position.right + 'px';
            break;
        case 'center':
            if (position.placement.primary === 'top' || position.placement.primary === 'bottom') {
                popupElement.style.left = position.left + 'px';
            }
            else {
                popupElement.style.top = position.top + 'px';
            }
            break;
    }
}
