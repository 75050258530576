import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "badge";
var attributes = {
    DOT: 'dot',
    OPEN: 'open',
    THEME: 'theme',
    STRONG: 'strong'
};
var classes = {
    DOT: 'tyl-badge--dot',
    OPEN: 'tyl-badge--open'
};
var selectors = {
    ROOT: '.tyl-badge'
};
export var BADGE_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    classes: classes,
    selectors: selectors
};
