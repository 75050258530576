import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "radio";
var classes = {
    RADIO: 'mdc-radio',
    NATIVE_CONTROL: 'mdc-radio__native-control',
    RADIO_DENSE: 'tyl-radio--dense'
};
var selectors = {
    INPUT: 'input'
};
var attributes = {
    DENSE: 'dense'
};
export var RADIO_CONSTANTS = {
    elementName: elementName,
    classes: classes,
    selectors: selectors,
    attributes: attributes
};
