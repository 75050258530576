import { matchesSelectors } from 'shadow-utils';
import { removeElement, removeAllChildren } from '@tyler-components-web/core';
import { attachTooltip } from './tooltip-utils';
/**
 * The DOM adapter for the tooltip component.
 */
var TooltipAdapter = /** @class */ (function () {
    function TooltipAdapter(_component) {
        this._component = _component;
    }
    /**
     * Sets an attribute value on the host element.
     */
    TooltipAdapter.prototype.setHostAttribute = function (name, value) {
        this._component.setAttribute(name, value);
    };
    /**
     * Gets an attribute value on the host element.
     */
    TooltipAdapter.prototype.getHostAttribute = function (name) {
        return this._component.getAttribute(name);
    };
    /**
     * Sets the text content of the host element to the provided text.
     * @param text The text content.
     */
    TooltipAdapter.prototype.setTextContent = function (text) {
        removeAllChildren(this._component);
        if (text) {
            this._component.appendChild(document.createTextNode(text));
        }
    };
    /**
     * Adds an event listener to the target element.
     * @param targetElement The target element instance.
     * @param type The event type.
     * @param listener The event listener.
     */
    TooltipAdapter.prototype.addTargetEventListener = function (targetElement, type, listener) {
        targetElement.addEventListener(type, listener);
    };
    /**
     * Removes an event listener from the target element.
     * @param targetElement The target element instance.
     * @param type The event type.
     * @param listener The event listener.
     */
    TooltipAdapter.prototype.removeTargetEventListener = function (targetElement, type, listener) {
        targetElement.removeEventListener(type, listener);
    };
    /**
     * Gets the target element based on the provided CSS selector.
     * @param {string | undefined} selector The target element selector.
     */
    TooltipAdapter.prototype.getTargetElement = function (selector) {
        if (selector) {
            if (this._component.parentElement) {
                if (matchesSelectors(this._component.parentElement, selector)) {
                    return this._component.parentElement;
                }
                return this._component.parentElement.querySelector(selector);
            }
        }
        else {
            return (this._component.previousElementSibling || this._component.parentElement);
        }
        return null;
    };
    /**
     * Displays the tooltip around the target element based on the provided configuration.
     * @param targetElement The target element instance.
     * @param position The position.
     * @param content The tooltip content.
     */
    TooltipAdapter.prototype.showTooltip = function (targetElement, position, content) {
        if (!content) {
            var child = this._component.firstElementChild || this._component.firstChild || document.createTextNode('');
            content = child.cloneNode(true);
        }
        return attachTooltip(targetElement, position, content);
    };
    TooltipAdapter.prototype.getInnerText = function () {
        return this._component.innerText;
    };
    /**
     * Removes the tooltip from the DOM.
     * @param tooltipElement The target element instance.
     */
    TooltipAdapter.prototype.hideTooltip = function (tooltipElement) {
        removeElement(tooltipElement);
    };
    /**
     * Adds an event listener to the window.
     * @param type The event type.
     * @param listener The event listener.
     */
    TooltipAdapter.prototype.addWindowListener = function (type, listener) {
        window.addEventListener(type, listener, true);
    };
    /**
     * Removes an event listener from the window.
     * @param type The event type.
     * @param listener Then event listener.
     */
    TooltipAdapter.prototype.removeWindowListener = function (type, listener) {
        window.removeEventListener(type, listener, true);
    };
    return TooltipAdapter;
}());
export { TooltipAdapter };
