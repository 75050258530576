import { PROFILE_CARD_CONSTANTS } from './profile-card-constants';
var ProfileCardFoundation = /** @class */ (function () {
    function ProfileCardFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._showSignOutButton = PROFILE_CARD_CONSTANTS.defaults.SHOW_SIGN_OUT_BUTTON;
        this._showProfileButton = PROFILE_CARD_CONSTANTS.defaults.SHOW_PROFILE_BUTTON;
        this._profileListener = function (evt) { return _this._onProfileButtonClick(evt); };
        this._signOutListener = function (evt) { return _this._onSignOutButtonClick(evt); };
    }
    ProfileCardFoundation.prototype.initialize = function () {
        this._adapter.setProfileButtonListener(this._profileListener);
        this._adapter.setSignOutButtonListener(this._signOutListener);
        if (this._showSignOutButton || this._showProfileButton) {
            this._requestInitialFocus();
        }
        this._setActionVisibility();
    };
    ProfileCardFoundation.prototype._requestInitialFocus = function () {
        if (this._showSignOutButton) {
            this._adapter.requestSignOutButtonFocus();
        }
        else if (this._showProfileButton) {
            this._adapter.requestProfileButtonFocus();
        }
    };
    ProfileCardFoundation.prototype._setActionVisibility = function () {
        var showActionToolbar = this._showSignOutButton || this._showProfileButton;
        this._adapter.setActionToolbarVisibility(showActionToolbar);
        this._adapter.setSignOutButtonVisibility(this._showSignOutButton);
        this._adapter.setProfileButtonVisibility(this._showProfileButton);
    };
    ProfileCardFoundation.prototype._onProfileButtonClick = function (evt) {
        evt.stopPropagation();
        this._adapter.emitEvent(PROFILE_CARD_CONSTANTS.events.PROFILE);
    };
    ProfileCardFoundation.prototype._onSignOutButtonClick = function (evt) {
        evt.stopPropagation();
        this._adapter.emitEvent(PROFILE_CARD_CONSTANTS.events.SIGN_OUT);
    };
    Object.defineProperty(ProfileCardFoundation.prototype, "fullName", {
        get: function () {
            return this._fullName;
        },
        set: function (value) {
            if (this._fullName !== value) {
                this._fullName = value;
                this._adapter.setFullName(this._fullName);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileCardFoundation.prototype, "email", {
        get: function () {
            return this._email;
        },
        set: function (value) {
            if (this._email !== value) {
                this._email = value;
                this._adapter.setEmail(this._email);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileCardFoundation.prototype, "signOut", {
        get: function () {
            return this._showSignOutButton;
        },
        set: function (value) {
            if (this._showSignOutButton !== value) {
                this._showSignOutButton = value;
                this._setActionVisibility();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileCardFoundation.prototype, "profile", {
        get: function () {
            return this._showProfileButton;
        },
        set: function (value) {
            if (this._showProfileButton !== value) {
                this._showProfileButton = value;
                this._setActionVisibility();
            }
        },
        enumerable: true,
        configurable: true
    });
    return ProfileCardFoundation;
}());
export { ProfileCardFoundation };
