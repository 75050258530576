import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "drawer";
var attributes = {
    OPEN: 'open',
    TYPE: 'type',
    DIRECTION: 'direction'
};
var classes = {
    DRAWER: 'tyl-drawer',
    DISMISSIBLE: 'tyl-drawer--dismissible',
    MINI: 'tyl-drawer--mini',
    MINI_HOVER: 'tyl-drawer--mini-hover',
    MODAL: 'tyl-drawer--modal',
    OPEN: 'tyl-drawer--open',
    OPENING: 'tyl-drawer--opening',
    CLOSED: 'tyl-drawer--closed',
    MODAL_CLOSED: 'tyl-drawer--modal-closed',
    LEFT: 'tyl-drawer--left',
    RIGHT: 'tyl-drawer--right'
};
var selectors = {
    DRAWER: "." + classes.DRAWER
};
var events = {
    CLOSE: elementName + "-close"
};
var numbers = {
    OPEN_TRANSITION_DURATION: 250
};
export var DRAWER_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    classes: classes,
    selectors: selectors,
    events: events,
    numbers: numbers
};
