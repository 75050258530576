import { COMPONENT_NAME_PREFIX } from '../constants';
import { PopupPlacement } from '../popup';
var elementName = COMPONENT_NAME_PREFIX + "tooltip";
var attributes = {
    TEXT: 'text',
    TARGET: 'target',
    DELAY: 'delay',
    POSITION: 'position',
    HOST: 'tcw-tooltip-host'
};
var classes = {
    TOOLTIP: 'tyl-tooltip',
    TOOLTIP_OPEN: 'tyl-tooltip--open',
    TOOLTIP_TOP: 'tyl-tooltip--top',
    TOOLTIP_RIGHT: 'tyl-tooltip--right',
    TOOLTIP_BOTTOM: 'tyl-tooltip--bottom',
    TOOLTIP_LEFT: 'tyl-tooltip--left'
};
var selectors = {
    HOST: "[" + attributes.HOST + "]"
};
var numbers = {
    DEFAULT_DELAY: 500,
    LONGPRESS_THRESHOLD: 750,
    LONGPRESS_VISIBILITY_DURATION: 3000
};
var strings = {
    DEFAULT_POSITION: PopupPlacement.Right
};
export var TOOLTIP_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    classes: classes,
    selectors: selectors,
    numbers: numbers,
    strings: strings
};
