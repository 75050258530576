import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "tab-bar";
var attributes = {
    ACTIVE_TAB: 'active-tab'
};
var classes = {
    TAB_BAR: 'mdc-tab-bar',
    TAB: 'mdc-tab',
    TAB_ACTIVE: 'mdc-tab--active',
    TAB_INDICATOR_ACTIVE: 'mdc-tab-indicator--active',
    TAB_ICON: 'mdc-tab__icon',
    TAB_LABEL: 'mdc-tab__text-label'
};
var selectors = {
    TAB_SCROLLER_CONTENT: '.mdc-tab-scroller__scroll-content',
    TAB_CONTENT: '.mdc-tab__content',
    TAB_INDICATOR: '.mdc-tab-indicator',
    TAB: "." + classes.TAB,
    TAB_ICON: 'i',
    TAB_LABEL: 'span'
};
var events = {
    ACTIVATE: elementName + "-activate"
};
export var TAB_BAR_CONSTANTS = {
    attributes: attributes,
    elementName: elementName,
    classes: classes,
    selectors: selectors,
    events: events
};
