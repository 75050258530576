import { defineCustomElement } from '@tyler-components-web/core';
import { DatepickerComponent } from './datepicker';
export * from './datepicker-adapter';
export * from './datepicker-component-delegate';
export * from './date-range-component-delegate';
export * from './datepicker-constants';
export * from './datepicker-foundation';
export * from './datepicker';
export function defineDatepickerComponent() {
    defineCustomElement(DatepickerComponent);
}
