import { COMPONENT_NAME_PREFIX, KEYSTROKE_DEBOUNCE_THRESHOLD } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "autocomplete";
var attributes = {
    MULTIPLE: 'multiple',
    DEBOUNCE: 'debounce',
    HIGHLIGHT_FIRST: 'highlight-first',
    FILTER_ON_FOCUS: 'filter-on-focus',
    ALLOW_UNMATCHED: 'allow-unmatched',
    POPUP_TARGET: 'popup-target',
    POPUP_CLASSES: 'popup-classes',
    OPTION_LIMIT: 'option-limit',
    OBSERVE_SCROLL: 'observe-scroll',
    OBSERVE_SCROLL_THRESHOLD: 'observe-scroll-threshold'
};
var selectors = {
    INPUT: 'input, textarea',
    DROPDOWN_ICON: '[data-tyl-dropdown-icon], .tyl-dropdown-icon'
};
var numbers = {
    DEFAULT_DEBOUNCE_TIME: KEYSTROKE_DEBOUNCE_THRESHOLD
};
var events = {
    CHANGE: elementName + "-change",
    SCROLLED_BOTTOM: elementName + "-scrolled-bottom"
};
export var AUTOCOMPLETE_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    selectors: selectors,
    numbers: numbers,
    events: events
};
