import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty, coerceNumber } from '@tyler-components-web/core';
import { ProgressSpinnerFoundation } from './progress-spinner-foundation';
import { ProgressSpinnerAdapter } from './progress-spinner-adapter';
import { PROGRESS_SPINNER_CONSTANTS } from './progress-spinner-constants';
var template = require('./progress-spinner.html');
var styles = require('./progress-spinner.css');
/**
 * The custom element class behind the `<tcw-progress-spinner>` component.
 */
var ProgressSpinnerComponent = /** @class */ (function (_super) {
    __extends(ProgressSpinnerComponent, _super);
    function ProgressSpinnerComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, PROGRESS_SPINNER_CONSTANTS.elementName, template, styles);
        _this._foundation = new ProgressSpinnerFoundation(new ProgressSpinnerAdapter(_this));
        return _this;
    }
    Object.defineProperty(ProgressSpinnerComponent, "observedAttributes", {
        get: function () {
            return [
                PROGRESS_SPINNER_CONSTANTS.attributes.MODE,
                PROGRESS_SPINNER_CONSTANTS.attributes.PROGRESS,
                PROGRESS_SPINNER_CONSTANTS.attributes.DIAMETER,
                PROGRESS_SPINNER_CONSTANTS.attributes.STROKE_WIDTH
            ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressSpinnerComponent.prototype, "diameter", {
        get: function () {
            return this._foundation.diameter;
        },
        /** Gets/sets the diameter. */
        set: function (value) {
            this._foundation.diameter = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressSpinnerComponent.prototype, "mode", {
        get: function () {
            return this._foundation.mode;
        },
        /** Gets/sets the mode. */
        set: function (mode) {
            this._foundation.mode = mode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressSpinnerComponent.prototype, "progress", {
        /** Gets the progress amount percentage when in determinate mode. */
        get: function () {
            return this.mode === 'determinate' ? this._foundation.progress : 0;
        },
        /** Sets the progress amount percentage for determinate mode. */
        set: function (value) {
            this._foundation.progress = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressSpinnerComponent.prototype, "strokeWidth", {
        get: function () {
            return this._foundation.strokeWidth;
        },
        /** Gets/sets the stroke width. */
        set: function (value) {
            this._foundation.strokeWidth = value;
        },
        enumerable: true,
        configurable: true
    });
    ProgressSpinnerComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'diameter');
        upgradeProperty(this, 'mode');
        upgradeProperty(this, 'progress');
        upgradeProperty(this, 'strokeWidth');
        this._foundation.initialize();
    };
    ProgressSpinnerComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case PROGRESS_SPINNER_CONSTANTS.attributes.MODE:
                this.mode = newValue;
                break;
            case PROGRESS_SPINNER_CONSTANTS.attributes.PROGRESS:
                this.progress = coerceNumber(newValue);
                break;
            case PROGRESS_SPINNER_CONSTANTS.attributes.DIAMETER:
                this.diameter = coerceNumber(newValue);
                break;
            case PROGRESS_SPINNER_CONSTANTS.attributes.STROKE_WIDTH:
                this.strokeWidth = coerceNumber(newValue);
                break;
        }
    };
    ProgressSpinnerComponent = __decorate([
        CustomElement({
            name: PROGRESS_SPINNER_CONSTANTS.elementName
        })
    ], ProgressSpinnerComponent);
    return ProgressSpinnerComponent;
}(HTMLElement));
export { ProgressSpinnerComponent };
