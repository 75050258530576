import { COMPONENT_NAME_PREFIX } from '../constants';
import { LINEAR_PROGRESS_CONSTANTS } from '../linear-progress';
import { BACKDROP_CONSTANTS } from '../backdrop';
var elementName = COMPONENT_NAME_PREFIX + "busy-indicator";
var selectors = {
    SURFACE: '.tyl-busy-indicator__surface',
    TITLE: '.tyl-busy-indicator__title',
    MESSAGE: '.tyl-busy-indicator__message',
    CANCEL: '.tyl-busy-indicator__cancel-button',
    SPINNER: '.tyl-busy-indicator__spinner',
    PROGRESS_BAR_CONTAINER: '.tyl-busy-indicator__progress-container',
    PROGRESS_BAR: ".tyl-busy-indicator__progress-container " + LINEAR_PROGRESS_CONSTANTS.elementName,
    BACKDROP: BACKDROP_CONSTANTS.elementName
};
var events = {
    CANCEL: elementName + "-cancel"
};
var strings = {
    CANCELLING: 'Cancelling...',
    DEFAULT_MESSAGE_ID: '__DEFAULT_MESSAGE_ID__'
};
var attributes = {
    TITLE_TEXT: 'title-text',
    MESSAGE: 'message',
    CANCEL: 'cancel',
    SPINNER: 'spinner',
    PROGRESS_BAR: 'progress-bar',
    PROGRESS_BAR_DETERMINATE: 'progress-bar-determinate',
    PROGRESS: 'progress',
    BUFFER: 'buffer',
    WIDTH: 'width',
    AUTO_ELLIPSIS: 'auto-ellipsis'
};
var numbers = {
    DELAY: 500,
    TRANSITION_LENGTH: 120,
    MINIMUM_VISIBLE_LIFETIME: 1000
};
export var BUSY_INDICATOR_CONSTANTS = {
    elementName: elementName,
    selectors: selectors,
    events: events,
    strings: strings,
    attributes: attributes,
    numbers: numbers
};
