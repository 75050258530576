import { Platform } from '@tyler-components-web/core';
import { PROGRESS_SPINNER_CONSTANTS } from './progress-spinner-constants';
var ProgressSpinnerFoundation = /** @class */ (function () {
    function ProgressSpinnerFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._progress = 0;
        this._diameter = PROGRESS_SPINNER_CONSTANTS.numbers.BASE_SIZE;
        this._strokeWidth = PROGRESS_SPINNER_CONSTANTS.numbers.BASE_STROKE_WIDTH;
        this._mode = 'indeterminate';
        this._fallbackAnimation = false;
        this._elementSize = PROGRESS_SPINNER_CONSTANTS.numbers.BASE_SIZE;
        this._fallbackAnimation = Platform.EDGE || Platform.TRIDENT;
        requestAnimationFrame(function () {
            // On IE and Edge, we can't animate the `stroke-dashoffset` reliably so we fall back to a non-spec animation.
            var animationClass = "tyl-progress-spinner-indeterminate" + (_this._fallbackAnimation ? '-fallback' : '') + "-animation";
            _this._adapter.setAnimationClass(animationClass);
        });
    }
    ProgressSpinnerFoundation.prototype.initialize = function () {
        this.render();
    };
    Object.defineProperty(ProgressSpinnerFoundation.prototype, "diameter", {
        get: function () {
            return this._diameter;
        },
        /** The width in diameter of the SVG circle. */
        set: function (value) {
            if (this._diameter !== value) {
                this._diameter = value;
                this._adapter.setHostAttribute(PROGRESS_SPINNER_CONSTANTS.attributes.DIAMETER, this._diameter.toString());
                this.render();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressSpinnerFoundation.prototype, "mode", {
        get: function () {
            return this._mode;
        },
        /** The display mode for the animation. */
        set: function (value) {
            if (this._mode !== value) {
                this._mode = value;
                this._adapter.setHostAttribute(PROGRESS_SPINNER_CONSTANTS.attributes.MODE, this._mode.toString());
                this.render();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressSpinnerFoundation.prototype, "progress", {
        get: function () {
            return this.mode === 'determinate' ? this._progress : 0;
        },
        /** The progress amount percentage for determinate mode. */
        set: function (newValue) {
            if (this._progress !== newValue) {
                if (this._progress === null) {
                    this._progress = 0;
                }
                this._progress = Math.max(0, Math.min(100, newValue));
                this._adapter.setHostAttribute(PROGRESS_SPINNER_CONSTANTS.attributes.PROGRESS, this._progress.toString());
                if (this.mode === 'determinate') {
                    this.render();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressSpinnerFoundation.prototype, "strokeWidth", {
        get: function () {
            return this._strokeWidth;
        },
        /** The width of the stroke. */
        set: function (value) {
            if (this._strokeWidth !== value) {
                this._strokeWidth = value;
                this._adapter.setHostAttribute(PROGRESS_SPINNER_CONSTANTS.attributes.STROKE_WIDTH, this._strokeWidth.toString());
                this.render();
            }
        },
        enumerable: true,
        configurable: true
    });
    /** Renders the SVG animation with the current values. */
    ProgressSpinnerFoundation.prototype.render = function () {
        this._elementSize = this._diameter + Math.max(this.strokeWidth - PROGRESS_SPINNER_CONSTANTS.numbers.BASE_STROKE_WIDTH, 0);
        this._setDiameterAndInitStyles(this._diameter);
        this._adapter.setContainerAttribute(PROGRESS_SPINNER_CONSTANTS.attributes.MODE, this._mode);
        this._adapter.setContainerSize(this._elementSize);
        this._adapter.setSvgSize(this._elementSize);
        this._adapter.setSvgViewBox(this._viewBox);
        this._adapter.setCircleRadius(this._circleRadius);
        this._adapter.setCircleStyle({
            'animation-name': 'tyl-progress-spinner-stroke-rotate-' + this._diameter,
            'stroke-dashoffset': this._strokeDashOffset + "px",
            'stroke-dasharray': this._strokeCircumference + "px",
            'stroke-width': this._strokeWidth + "%"
        });
    };
    Object.defineProperty(ProgressSpinnerFoundation.prototype, "_circleRadius", {
        /** The radius of the spinner, adjusted for stroke width. */
        get: function () {
            return (this.diameter - PROGRESS_SPINNER_CONSTANTS.numbers.BASE_STROKE_WIDTH) / 2;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressSpinnerFoundation.prototype, "_viewBox", {
        /** The view box of the spinner's svg element. */
        get: function () {
            var viewBox = this._circleRadius * 2 + this.strokeWidth;
            return "0 0 " + viewBox + " " + viewBox;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressSpinnerFoundation.prototype, "_strokeCircumference", {
        /** The stroke circumference of the svg circle. */
        get: function () {
            return 2 * Math.PI * this._circleRadius;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressSpinnerFoundation.prototype, "_strokeDashOffset", {
        /** The dash offset of the svg circle. */
        get: function () {
            if (this.mode === 'determinate') {
                return this._strokeCircumference * (100 - this._progress) / 100;
            }
            // In fallback mode set the circle to 80% and rotate it with CSS.
            if (this._fallbackAnimation && this.mode === 'indeterminate') {
                return this._strokeCircumference * 0.2;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    /** Sets the diameter and adds diameter-specific styles if necessary. */
    ProgressSpinnerFoundation.prototype._setDiameterAndInitStyles = function (size) {
        this._diameter = size;
        if (!this._fallbackAnimation) {
            this._setAnimation();
        }
    };
    /** Dynamically generates the correct animation for this diameter. */
    ProgressSpinnerFoundation.prototype._setAnimation = function () {
        this._adapter.setAnimationStyle(this._getAnimationText());
    };
    /** Generates animation styles adjusted for the spinner's diameter. */
    ProgressSpinnerFoundation.prototype._getAnimationText = function () {
        return PROGRESS_SPINNER_CONSTANTS.indeterminateAnimationTemplate
            // Animation should begin at 5% and end at 80%
            .replace(/START_VALUE/g, "" + 0.95 * this._strokeCircumference)
            .replace(/END_VALUE/g, "" + 0.2 * this._strokeCircumference)
            .replace(/DIAMETER/g, "" + this.diameter);
    };
    return ProgressSpinnerFoundation;
}());
export { ProgressSpinnerFoundation };
