import { __decorate, __extends } from "tslib";
import { CustomElement, upgradeProperty } from '@tyler-components-web/core';
import { OptionAdapter } from './option-adapter';
import { OptionFoundation } from './option-foundation';
import { OPTION_CONSTANTS } from './option-constants';
/**
 * The custom element class behind the `<tcw-option>` element.
 */
var OptionComponent = /** @class */ (function (_super) {
    __extends(OptionComponent, _super);
    function OptionComponent() {
        var _this = _super.call(this) || this;
        _this._foundation = new OptionFoundation(new OptionAdapter(_this));
        return _this;
    }
    Object.defineProperty(OptionComponent, "observedAttributes", {
        get: function () {
            return [
                OPTION_CONSTANTS.attributes.VALUE,
                OPTION_CONSTANTS.attributes.DISABLED
            ];
        },
        enumerable: true,
        configurable: true
    });
    OptionComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'value');
        upgradeProperty(this, 'disabled');
    };
    OptionComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case OPTION_CONSTANTS.attributes.VALUE:
                this.value = newValue;
                break;
            case OPTION_CONSTANTS.attributes.DISABLED:
                this.disabled = this.hasAttribute(OPTION_CONSTANTS.attributes.DISABLED);
                break;
        }
    };
    Object.defineProperty(OptionComponent.prototype, "value", {
        /** Gets/sets the value of this option. */
        get: function () {
            return this._foundation.value;
        },
        set: function (value) {
            this._foundation.value = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OptionComponent.prototype, "disabled", {
        /** Gets/sets the disabled status of this option. */
        get: function () {
            return this._foundation.disabled;
        },
        set: function (value) {
            this._foundation.disabled = value;
        },
        enumerable: true,
        configurable: true
    });
    OptionComponent = __decorate([
        CustomElement({
            name: OPTION_CONSTANTS.elementName
        })
    ], OptionComponent);
    return OptionComponent;
}(HTMLElement));
export { OptionComponent };
