import { __decorate, __extends } from "tslib";
import { CustomElement, upgradeProperty, attachShadowTemplate, coerceBoolean } from '@tyler-components-web/core';
import { PopupPlacement } from '../popup';
import { ButtonComponent } from '../button';
import { IconButtonComponent } from '../icon-button';
import { ToastAdapter } from './toast-adapter';
import { TOAST_CONSTANTS } from './toast-constants';
import { ToastFoundation } from './toast-foundation';
var template = require('./toast.html');
var styles = require('./toast.css');
/**
 * The custom element class behind the `<tcw-toast>` web component.
 */
var ToastComponent = /** @class */ (function (_super) {
    __extends(ToastComponent, _super);
    function ToastComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, TOAST_CONSTANTS.elementName, template, styles);
        _this._foundation = new ToastFoundation(new ToastAdapter(_this));
        return _this;
    }
    Object.defineProperty(ToastComponent, "observedAttributes", {
        get: function () {
            return [
                TOAST_CONSTANTS.attributes.MESSAGE,
                TOAST_CONSTANTS.attributes.ACTION_TEXT,
                TOAST_CONSTANTS.attributes.DURATION,
                TOAST_CONSTANTS.attributes.PLACEMENT,
                TOAST_CONSTANTS.attributes.SHOW_CLOSE
            ];
        },
        enumerable: true,
        configurable: true
    });
    ToastComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'message');
        upgradeProperty(this, 'actionText');
        upgradeProperty(this, 'duration');
        upgradeProperty(this, 'placement');
        upgradeProperty(this, 'showClose');
        upgradeProperty(this, 'builder');
        this._foundation.initialize();
    };
    ToastComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case TOAST_CONSTANTS.attributes.MESSAGE:
                this.message = newValue;
                break;
            case TOAST_CONSTANTS.attributes.ACTION_TEXT:
                this.actionText = newValue;
                break;
            case TOAST_CONSTANTS.attributes.DURATION:
                var value = Number(newValue);
                this.duration = value && value > 0 ? value : TOAST_CONSTANTS.defaults.DURATION;
                break;
            case TOAST_CONSTANTS.attributes.PLACEMENT:
                this.placement = Object.values(PopupPlacement).includes(newValue) ? newValue : TOAST_CONSTANTS.defaults.PLACEMENT;
                break;
            case TOAST_CONSTANTS.attributes.SHOW_CLOSE:
                this.showClose = coerceBoolean(newValue);
                break;
        }
    };
    Object.defineProperty(ToastComponent.prototype, "message", {
        /** The message to display in the toast. */
        get: function () {
            return this._foundation.message;
        },
        set: function (value) {
            this._foundation.message = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToastComponent.prototype, "actionText", {
        /** The text to display in the action button. */
        get: function () {
            return this._foundation.actionText;
        },
        set: function (value) {
            this._foundation.actionText = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToastComponent.prototype, "duration", {
        /** The time in milliseconds to show the toast. */
        get: function () {
            return this._foundation.duration;
        },
        set: function (value) {
            this._foundation.duration = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToastComponent.prototype, "placement", {
        /** The placement of the toast. */
        get: function () {
            return this._foundation.placement;
        },
        set: function (value) {
            this._foundation.placement = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToastComponent.prototype, "builder", {
        /** Sets the toast builder function for displaying custom content. */
        set: function (value) {
            this._foundation.builder = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToastComponent.prototype, "showClose", {
        /** Controls the visibility of the close button. */
        get: function () {
            return this._foundation.showClose;
        },
        set: function (value) {
            this._foundation.showClose = value;
        },
        enumerable: true,
        configurable: true
    });
    /** Hides the toast and removes it from the DOM. */
    ToastComponent.prototype.hide = function () {
        this._foundation.hide();
    };
    ToastComponent = __decorate([
        CustomElement({
            name: TOAST_CONSTANTS.elementName,
            dependencies: [
                ButtonComponent,
                IconButtonComponent
            ]
        })
    ], ToastComponent);
    return ToastComponent;
}(HTMLElement));
export { ToastComponent };
