import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate } from '@tyler-components-web/core';
import { VIEW_CONSTANTS } from './view-constants';
var template = require('./view.html');
var styles = require('./view.css');
var ViewComponent = /** @class */ (function (_super) {
    __extends(ViewComponent, _super);
    function ViewComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, VIEW_CONSTANTS.elementName, template, styles);
        return _this;
    }
    ViewComponent = __decorate([
        CustomElement({
            name: VIEW_CONSTANTS.elementName
        })
    ], ViewComponent);
    return ViewComponent;
}(HTMLElement));
export { ViewComponent };
