import { COMPONENT_NAME_PREFIX } from '../../constants';
var elementName = COMPONENT_NAME_PREFIX + "chip";
var attributes = {
    TYPE: 'type',
    ICON: 'icon',
    TEXT: 'text',
    DELETE: 'delete',
    SELECTED: 'selected',
    VALUE: 'value',
    DENSE: 'dense'
};
var classes = {
    CHIP: 'mdc-chip',
    CHIP_SELECTED: 'mdc-chip--selected',
    CHIP_DENSE: 'tyl-chip--dense',
    TEXT: 'mdc-chip__text',
    LEADING_ICON: 'mdc-chip__icon--leading',
    LEADING_ICON_HIDDEN: 'mdc-chip__icon--leading-hidden',
    TRAILING_ICON: 'mdc-chip__icon--trailing',
    CHECKMARK: 'mdc-chip__checkmark',
    CHECKMARK_SVG: 'mdc-chip__checkmark-svg',
    CHECKMARK_PATH: 'mdc-chip__checkmark-path'
};
var selectors = {
    CHIP: "." + classes.CHIP,
    TEXT: "." + classes.TEXT,
    LEADING_ICON: "." + classes.LEADING_ICON,
    TRAILING_ICON: "." + classes.TRAILING_ICON,
    CHECKMARK: "." + classes.CHECKMARK
};
var events = {
    DELETE: elementName + "-delete",
    SELECTED: elementName + "-select"
};
export var CHIP_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    classes: classes,
    selectors: selectors,
    events: events
};
