import { COMPONENT_NAME_PREFIX } from '../../constants';
var elementName = COMPONENT_NAME_PREFIX + "chip-set";
var attributes = {
    ORIENTATION: 'orientation',
    TYPE: 'type',
    DENSE: 'dense'
};
var classes = {
    CHIP_SET: 'mdc-chip-set',
    CHIP_SET_DENSE: 'tyl-chip-set--dense',
    FILTER: 'mdc-chip-set--filter',
    CHOICE: 'mdc-chip-set--choice',
    INPUT: 'mdc-chip-set--input'
};
export var CHIP_SET_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    classes: classes
};
