import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "datepicker";
var classes = {
    TEXT_FIELD: 'mdc-text-field',
    TEXT_FIELD_OUTLINED: 'mdc-text-field--outlined',
    TEXT_FIELD_FULLWIDTH: 'mdc-text-field--fullwidth',
    NATIVE_CONTROL: 'mdc-text-field__input',
    LABEL: 'mdc-floating-label',
    LABEL_FLOAT: 'mdc-floating-label--float-above',
    ICON_TRAILING: 'tyl-text-field--with-trailing-icon',
    POPUP: 'tyl-datepicker__popup',
};
var selectors = {
    INPUT: 'input',
    ICON: 'i',
    LABEL: 'label',
    LABEL_CONTAINER: '.mdc-notched-outline__notch',
    CALENDAR_BUTTONS: '.tyl-datepicker__body .tyl-button',
    PREV_MONTH_BUTTON: '.tyl-datepicker__header .tyl-button:first-of-type',
    MONTH_SELECT: '.tyl-datepicker__header #tyl-datepicker-month-select',
    YEAR_SELECT: '.tyl-datepicker__header #tyl-datepicker-year-select',
    NEXT_MONTH_BUTTON: '.tyl-datepicker__header .tyl-button:last-of-type',
    CALENDAR_BODY: '.tyl-datepicker__body tbody'
};
var events = {
    OPEN: elementName + "-open",
    CLOSE: elementName + "-close"
};
var attributes = {
    PREVIOUS_MONTH: 'prev-month',
    NEXT_MONTH: 'next-month',
    SELECTED: 'selected',
    TODAY: 'today',
    OPEN: 'open'
};
export var DATEPICKER_CONSTANTS = {
    elementName: elementName,
    classes: classes,
    selectors: selectors,
    events: events,
    attributes: attributes
};
