import { isDefined, isObject } from '@tyler-components-web/core';
/** The available option types. */
export var OptionType;
(function (OptionType) {
    OptionType[OptionType["Option"] = 0] = "Option";
    OptionType[OptionType["Group"] = 1] = "Group";
})(OptionType || (OptionType = {}));
/**
 * Determines if the provided options are of the specified type.
 * @param options The options either grouped or individual.
 * @param type The type of option to detect.
 */
export function isOptionType(options, type) {
    var isOptionGroups = options.some(function (o) { return isDefined(o) && isObject(o) && o.hasOwnProperty('options') && (o.hasOwnProperty('text') || o.hasOwnProperty('builder')); });
    var isOptionTypes = options.some(function (o) { return isDefined(o) && isObject(o) && o.hasOwnProperty('label') && (o.hasOwnProperty('value')); });
    return (isOptionGroups && type === OptionType.Group) || (isOptionTypes && type === OptionType.Option);
}
/** A utility function to find the matching option in the provided options by the value provided. */
export function getSelectedOption(options, value) {
    return options.find(function (o) { return o.value === value; });
}
