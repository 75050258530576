import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty } from '@tyler-components-web/core';
import { BackdropFoundation } from './backdrop-foundation';
import { BackdropAdapter } from './backdrop-adapter';
import { BACKDROP_CONSTANTS } from './backdrop-constants';
var template = require('./backdrop.html');
var styles = require('./backdrop.css');
/**
 * A web component that renders a backdrop covering its parent container with a semi-opaque element.
 */
var BackdropComponent = /** @class */ (function (_super) {
    __extends(BackdropComponent, _super);
    function BackdropComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, BACKDROP_CONSTANTS.elementName, template, styles);
        _this._foundation = new BackdropFoundation(new BackdropAdapter(_this));
        return _this;
    }
    Object.defineProperty(BackdropComponent, "observedAttributes", {
        get: function () {
            return [
                BACKDROP_CONSTANTS.attributes.DELAY,
                BACKDROP_CONSTANTS.attributes.MAX_OPACITY
            ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BackdropComponent.prototype, "delay", {
        get: function () {
            return this._foundation.delay;
        },
        /**
         * The time in milliseconds to delay the fade in animation of the opacity.
         * Note: The element will start with 0% opacity when the component is rendered.
         */
        set: function (value) {
            this._foundation.delay = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BackdropComponent.prototype, "maxOpacity", {
        get: function () {
            return this._foundation.maxOpacity;
        },
        /**
         * The max opacity to fade the element to. This defines how opaque the backdrop is when visible.
         */
        set: function (value) {
            this._foundation.maxOpacity = value;
        },
        enumerable: true,
        configurable: true
    });
    BackdropComponent.prototype.connectedCallback = function () {
        upgradeProperty(this, 'delay');
        upgradeProperty(this, 'maxOpacity');
        if (this.hasAttribute(BACKDROP_CONSTANTS.attributes.DELAY) && this.getAttribute(BACKDROP_CONSTANTS.attributes.DELAY)) {
            var inDelay = Number(this.getAttribute(BACKDROP_CONSTANTS.attributes.DELAY));
            this.delay = inDelay >= 0 ? inDelay : BACKDROP_CONSTANTS.numbers.DELAY;
        }
        if (this.hasAttribute(BACKDROP_CONSTANTS.attributes.MAX_OPACITY)) {
            var inMaxOpacity = Number(this.getAttribute(BACKDROP_CONSTANTS.attributes.MAX_OPACITY));
            this.maxOpacity = inMaxOpacity >= 0 && inMaxOpacity <= 1 ? inMaxOpacity : BACKDROP_CONSTANTS.numbers.OPACITY;
        }
        this._foundation.initialize();
    };
    BackdropComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    BackdropComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case BACKDROP_CONSTANTS.attributes.DELAY:
                var delayValue = Number(newValue);
                this.delay = delayValue >= 0 ? delayValue : BACKDROP_CONSTANTS.numbers.DELAY;
                break;
            case BACKDROP_CONSTANTS.attributes.MAX_OPACITY:
                var opacityValue = Number(newValue);
                this.maxOpacity = opacityValue >= 0 && opacityValue <= 1 ? opacityValue : BACKDROP_CONSTANTS.numbers.OPACITY;
                break;
        }
    };
    /**
     * Starts the fade-out animation to animate the backdrop to 0% opacity.
     */
    BackdropComponent.prototype.fadeOut = function () {
        return this._foundation.fadeOut();
    };
    /**
     * Starts the fade-in animation to animate the backdrop to max opacity.
     */
    BackdropComponent.prototype.fadeIn = function () {
        return this._foundation.fadeIn();
    };
    BackdropComponent = __decorate([
        CustomElement({
            name: BACKDROP_CONSTANTS.elementName
        })
    ], BackdropComponent);
    return BackdropComponent;
}(HTMLElement));
export { BackdropComponent };
