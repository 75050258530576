import { COMPONENT_NAME_PREFIX } from '../../constants';
var elementName = COMPONENT_NAME_PREFIX + "button-toggle";
var attributes = {
    VALUE: 'value',
    SELECTED: 'selected',
    DISABLED: 'disabled',
    DENSE: 'dense',
    SELECTED_ADJACENT: 'selected-adjacent',
    SELECTED_ADJACENT_VERTICAL: 'selected-adjacent-vertical'
};
var classes = {
    SELECTED: 'tyl-button-toggle__selected',
    DENSE: 'tyl-button-toggle--dense'
};
var selectors = {
    BUTTON: '.tyl-button-toggle',
    SELECTED: "." + classes.SELECTED
};
var events = {
    SELECT: elementName + "-select"
};
export var BUTTON_TOGGLE_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    classes: classes,
    selectors: selectors,
    events: events
};
