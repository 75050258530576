import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "fab";
var selectors = {
    BUTTON: 'button',
    ICON: 'i',
    LABEL: 'span'
};
var classes = {
    BUTTON: 'tyl-fab',
    BUTTON_EXTENDED: 'tyl-fab--extended',
    ICON: 'tyl-fab__icon',
    LABEL: 'tyl-fab__label',
    BUTTON_MINI: 'tyl-fab--mini',
    EXITED: 'tyl-fab--exited'
};
var attributes = {
    EXTENDED: 'extended',
    MINI: 'mini',
    EXITED: 'exited',
    COLOR: 'color'
};
export var FLOATING_ACTION_BUTTON_CONSTANTS = {
    elementName: elementName,
    selectors: selectors,
    classes: classes,
    attributes: attributes
};
