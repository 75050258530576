import { __awaiter, __generator } from "tslib";
import { getShadowElement, removeClass } from '@tyler-components-web/core';
import { VIEW_SWITCHER_CONSTANTS, ViewAnimationDirection, ViewSwitcherAnimationType } from './view-switcher-constants';
var ViewSwitcherAdapter = /** @class */ (function () {
    function ViewSwitcherAdapter(_component) {
        this._component = _component;
        this._rootElement = getShadowElement(_component, VIEW_SWITCHER_CONSTANTS.selectors.ROOT);
        this._slotElement = getShadowElement(_component, VIEW_SWITCHER_CONSTANTS.selectors.DEFAULT_SLOT);
    }
    ViewSwitcherAdapter.prototype.getViewCount = function () {
        return this._getViews().length;
    };
    ViewSwitcherAdapter.prototype.startViewObserver = function (callback) {
        this._viewObserver = new MutationObserver(callback);
        this._viewObserver.observe(this._component, { childList: true });
    };
    ViewSwitcherAdapter.prototype.stopViewObserver = function () {
        if (this._viewObserver) {
            this._viewObserver.disconnect();
        }
    };
    /**
     * Initializes the views with styles for a slide transition.
     * @param index The active view index.
     */
    ViewSwitcherAdapter.prototype.initializeSlideViews = function (index) {
        var _this = this;
        var views = this._getViews();
        views.forEach(function (view, i) {
            // Make sure that we remove properties if they were set from another animation type
            view.style.removeProperty('opacity');
            view.style.removeProperty('display');
            // Ensure that the currently active view is visible
            if (i === index) {
                views[index].classList.remove(VIEW_SWITCHER_CONSTANTS.classes.VIEW_HIDDEN);
                view.style.removeProperty('visibility');
                return;
            }
            // We disable the transition when resetting the hidden views so that the animation doesn't occur
            view.style.transition = 'none';
            // Ensure the view is hidden
            view.classList.add(VIEW_SWITCHER_CONSTANTS.classes.VIEW_HIDDEN);
            view.style.visibility = 'hidden';
            // Reset the position of the view to the left or right of the switcher
            if (i > index) {
                view.style.transform = 'translate3d(100%, 0, 0)';
            }
            else {
                view.style.transform = 'translate3d(-100%, 0, 0)';
            }
        });
        // Wait a frame for the views to reposition, then re-enable the transition for all hidden views
        window.requestAnimationFrame(function () {
            views = _this._getViews();
            views.forEach(function (v, i) {
                if (i === index) {
                    return;
                }
                v.style.removeProperty('transition');
            });
        });
    };
    /**
     * Initializes the view styles when using a fade transition.
     * @param index The active view index.
     */
    ViewSwitcherAdapter.prototype.initializeFadeViews = function (index) {
        var views = this._getViews();
        views.forEach(function (view, i) {
            // Make sure that we remove properties if they were set from another animation type
            view.style.removeProperty('transform');
            view.style.removeProperty('display');
            // Ensure that the visible view doesn't have the hidden class
            if (i === index) {
                view.classList.remove(VIEW_SWITCHER_CONSTANTS.classes.VIEW_HIDDEN);
                view.style.removeProperty('visibility');
                return;
            }
            // Ensure the hidden views have the proper class
            view.classList.add(VIEW_SWITCHER_CONSTANTS.classes.VIEW_HIDDEN);
            view.style.visibility = 'hidden';
        });
        // Ensure that the visible view has the proper opacity
        if (views[index]) {
            views[index].style.opacity = '1';
        }
    };
    /**
     * This is used when no animation type is set to allow for snapping the view into place.
     * @param index The currently active view index.
     */
    ViewSwitcherAdapter.prototype.setActiveView = function (index) {
        var views = this._getViews();
        views.forEach(function (view, i) {
            if (i === index) {
                return;
            }
            // Hide all non-visible views
            view.classList.add(VIEW_SWITCHER_CONSTANTS.classes.VIEW_HIDDEN);
            view.style.display = 'none';
        });
        // Ensure that the active view is visible
        views[index].classList.remove(VIEW_SWITCHER_CONSTANTS.classes.VIEW_HIDDEN);
        views[index].style.removeProperty('display');
    };
    /**
     * This is used when no animation type is specific to hide all non-visible views.
     * @param index The currently active view index.
     */
    ViewSwitcherAdapter.prototype.hideInactiveViews = function (index) {
        var views = this._getViews();
        views.forEach(function (view, i) {
            // Make sure that we remove properties if they were set from another animation type
            view.style.removeProperty('opacity');
            view.style.removeProperty('transform');
            view.style.removeProperty('visibility');
            // Ensure the active view is visible
            if (i === index) {
                view.classList.remove(VIEW_SWITCHER_CONSTANTS.classes.VIEW_HIDDEN);
                return;
            }
            // Ensure all non-active views are hidden
            view.classList.add(VIEW_SWITCHER_CONSTANTS.classes.VIEW_HIDDEN);
            view.style.display = 'none';
        });
    };
    /**
     * Initiates a slide transition from one view to another.
     * @param fromIndex The view index to transition from.
     * @param toIndex The view index to transition to.
     * @param direction The transition animation direction.
     */
    ViewSwitcherAdapter.prototype.slideToView = function (fromIndex, toIndex, direction) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        var views = _this._getViews();
                        // Show the view so we can calculate the height and start the transition in the next frame
                        views[toIndex].style.removeProperty('visibility');
                        window.requestAnimationFrame(function () {
                            // Use a CSS transform on the view to slide it in the requested direction
                            if (direction === ViewAnimationDirection.Left) {
                                views[fromIndex].style.transform = 'translate3d(-100%, 0, 0)';
                                views[toIndex].style.transform = 'translate3d(0, 0, 0)';
                            }
                            else {
                                views[fromIndex].style.transform = 'translate3d(100%, 0, 0)';
                                views[toIndex].style.transform = 'translate3d(0, 0, 0)';
                            }
                            // Start the height transition (this will no animate if the height doesn't change)
                            _this.animateHeight(fromIndex, toIndex);
                            // Wait for the transform transition to complete before resolving and completing transition
                            views[fromIndex].addEventListener('transitionend', transitionEndHandler);
                            function transitionEndHandler(evt) {
                                if (evt.propertyName === 'transform') {
                                    views[fromIndex].removeEventListener('transitionend', transitionEndHandler);
                                    views[toIndex].classList.remove(VIEW_SWITCHER_CONSTANTS.classes.VIEW_HIDDEN);
                                    resolve();
                                }
                            }
                        });
                    })];
            });
        });
    };
    ViewSwitcherAdapter.prototype.fadeToView = function (fromIndex, toIndex) {
        var _this = this;
        return new Promise(function (resolve) {
            var views = _this._getViews();
            // Show the view so we can calculate the height and start the transition in the next frame
            views[toIndex].style.removeProperty('visibility');
            window.requestAnimationFrame(function () {
                // Start the fade transition from the currently active view to the next view
                views[fromIndex].style.opacity = '0';
                views[toIndex].style.opacity = '1';
                // Start the height transition animation (if applicable)
                _this.animateHeight(fromIndex, toIndex);
                // When the opacity transition is complete, we resolve and finalize state
                views[fromIndex].addEventListener('transitionend', transitionEndHandler);
                function transitionEndHandler(evt) {
                    if (evt.propertyName === 'opacity') {
                        views[fromIndex].removeEventListener('transitionend', transitionEndHandler);
                        views[toIndex].classList.remove(VIEW_SWITCHER_CONSTANTS.classes.VIEW_HIDDEN);
                        resolve();
                    }
                }
            });
        });
    };
    /**
     * Attempts to animate the height of our container element to match that of the next view.
     * @param fromIndex The view index we are transitioning from.
     * @param toIndex The view index we are transitioning to.
     */
    ViewSwitcherAdapter.prototype.animateHeight = function (fromIndex, toIndex) {
        var _this = this;
        var views = this._getViews();
        var fromViewHeight = this._getViewHeight(views[fromIndex]);
        var toViewHeight = this._getViewHeight(views[toIndex]);
        // We only animate the height if it is different...
        if (fromViewHeight !== toViewHeight) {
            this._rootElement.addEventListener('transitionend', this._onRootTransitionEnd.bind(this));
            // Set to the height of the current view so we can animate the height from something static
            this._setHeight(fromViewHeight);
            // We need to wait two frames before animating to the new height due to event loop
            window.requestAnimationFrame(function () {
                window.requestAnimationFrame(function () {
                    // Start the transition to the new view height
                    _this._setHeight(toViewHeight);
                });
            });
        }
    };
    /**
     * Handles the root container transitionend event.
     * @param evt The transition event.
     */
    ViewSwitcherAdapter.prototype._onRootTransitionEnd = function (evt) {
        if (evt.propertyName === 'height') {
            this._rootElement.removeEventListener('transitionend', this._onRootTransitionEnd);
            // Removes the static height from the root element so that it can grow with content
            this._rootElement.style.removeProperty('height');
        }
    };
    ViewSwitcherAdapter.prototype.setHostAttribute = function (name, value) {
        this._component.setAttribute(name, value);
    };
    /**
     * Resets the animation type on the root element.
     * @param type The animation type.
     */
    ViewSwitcherAdapter.prototype.setAnimationType = function (type) {
        removeClass([
            VIEW_SWITCHER_CONSTANTS.classes.VIEW_SWITCHER_SLIDE,
            VIEW_SWITCHER_CONSTANTS.classes.VIEW_SWITCHER_FADE
        ], this._component);
        switch (type) {
            case ViewSwitcherAnimationType.Slide:
                this._component.classList.add(VIEW_SWITCHER_CONSTANTS.classes.VIEW_SWITCHER_SLIDE);
                break;
            case ViewSwitcherAnimationType.Fade:
                this._component.classList.add(VIEW_SWITCHER_CONSTANTS.classes.VIEW_SWITCHER_FADE);
                break;
        }
    };
    /**
     * Get all view elements.
     */
    ViewSwitcherAdapter.prototype._getViews = function () {
        return this._slotElement.assignedNodes().filter(function (n) { return n.nodeType === 1; });
    };
    /**
     * Sets the height on the root container element.
     * @param viewHeight The new height.
     */
    ViewSwitcherAdapter.prototype._setHeight = function (viewHeight) {
        this._rootElement.style.height = viewHeight + "px";
    };
    /**
     * Calculate the absolute height of a view.
     * @param view The view to calculate height from.
     */
    ViewSwitcherAdapter.prototype._getViewHeight = function (view) {
        var height = 0;
        var elements = view.children.length ? Array.from(view.children) : [view];
        for (var _i = 0, elements_1 = elements; _i < elements_1.length; _i++) {
            var element = elements_1[_i];
            var styles = window.getComputedStyle(element);
            var margin = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);
            height += Math.ceil(element.offsetHeight + margin);
        }
        return height;
    };
    return ViewSwitcherAdapter;
}());
export { ViewSwitcherAdapter };
