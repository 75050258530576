import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "progress-spinner";
var attributes = {
    MODE: 'mode',
    PROGRESS: 'progress',
    DIAMETER: 'diameter',
    STROKE_WIDTH: 'stroke-width'
};
var selectors = {
    CONTAINER: '.tyl-progress-spinner',
    ANIMATION_STYLE_CONTAINER: 'style#animation-style-container'
};
var numbers = {
    BASE_SIZE: 75,
    BASE_STROKE_WIDTH: 10
};
var indeterminateAnimationTemplate = "\n@keyframes tyl-progress-spinner-stroke-rotate-DIAMETER {\n   0%      { stroke-dashoffset: START_VALUE;  transform: rotate(0); }\n   12.5%   { stroke-dashoffset: END_VALUE;    transform: rotate(0); }\n   12.51%  { stroke-dashoffset: END_VALUE;    transform: rotateX(180deg) rotate(72.5deg); }\n   25%     { stroke-dashoffset: START_VALUE;  transform: rotateX(180deg) rotate(72.5deg); }\n   25.1%   { stroke-dashoffset: START_VALUE;  transform: rotate(270deg); }\n   37.5%   { stroke-dashoffset: END_VALUE;    transform: rotate(270deg); }\n   37.51%  { stroke-dashoffset: END_VALUE;    transform: rotateX(180deg) rotate(161.5deg); }\n   50%     { stroke-dashoffset: START_VALUE;  transform: rotateX(180deg) rotate(161.5deg); }\n   50.01%  { stroke-dashoffset: START_VALUE;  transform: rotate(180deg); }\n   62.5%   { stroke-dashoffset: END_VALUE;    transform: rotate(180deg); }\n   62.51%  { stroke-dashoffset: END_VALUE;    transform: rotateX(180deg) rotate(251.5deg); }\n   75%     { stroke-dashoffset: START_VALUE;  transform: rotateX(180deg) rotate(251.5deg); }\n   75.01%  { stroke-dashoffset: START_VALUE;  transform: rotate(90deg); }\n   87.5%   { stroke-dashoffset: END_VALUE;    transform: rotate(90deg); }\n   87.51%  { stroke-dashoffset: END_VALUE;    transform: rotateX(180deg) rotate(341.5deg); }\n   100%    { stroke-dashoffset: START_VALUE;  transform: rotateX(180deg) rotate(341.5deg); }\n }\n";
export var PROGRESS_SPINNER_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    selectors: selectors,
    numbers: numbers,
    indeterminateAnimationTemplate: indeterminateAnimationTemplate
};
