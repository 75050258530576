import { __decorate, __extends } from "tslib";
import { MDCLinearProgress } from '@material/linear-progress';
import { CustomElement, coerceBoolean, coerceNumber, upgradeProperty, attachShadowTemplate } from '@tyler-components-web/core';
import { LINEAR_PROGRESS_CONSTANTS } from './linear-progress-constants';
var template = require('./linear-progress.html');
var styles = require('./linear-progress.css');
var LinearProgressComponent = /** @class */ (function (_super) {
    __extends(LinearProgressComponent, _super);
    function LinearProgressComponent() {
        var _this = _super.call(this) || this;
        _this._determinate = false;
        _this._progress = 0;
        _this._buffer = 0;
        attachShadowTemplate(_this, LINEAR_PROGRESS_CONSTANTS.elementName, template, styles);
        return _this;
    }
    Object.defineProperty(LinearProgressComponent, "observedAttributes", {
        get: function () {
            return [
                LINEAR_PROGRESS_CONSTANTS.attributes.DETERMINATE,
                LINEAR_PROGRESS_CONSTANTS.attributes.PROGRESS,
                LINEAR_PROGRESS_CONSTANTS.attributes.BUFFER
            ];
        },
        enumerable: true,
        configurable: true
    });
    LinearProgressComponent.prototype.connectedCallback = function () {
        this._initialize();
    };
    LinearProgressComponent.prototype.disconnectedCallback = function () {
        if (this._mdcLinearProgress) {
            this._mdcLinearProgress.destroy();
        }
    };
    LinearProgressComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case LINEAR_PROGRESS_CONSTANTS.attributes.DETERMINATE:
                this.determinate = coerceBoolean(newValue);
                break;
            case LINEAR_PROGRESS_CONSTANTS.attributes.PROGRESS:
                this.progress = coerceNumber(newValue);
                break;
            case LINEAR_PROGRESS_CONSTANTS.attributes.BUFFER:
                this.buffer = coerceNumber(newValue);
                break;
        }
    };
    Object.defineProperty(LinearProgressComponent.prototype, "determinate", {
        get: function () {
            return this._determinate;
        },
        set: function (value) {
            if (this._determinate !== value) {
                this._determinate = value;
                if (this._mdcLinearProgress) {
                    this._mdcLinearProgress.determinate = value;
                }
                this.setAttribute(LINEAR_PROGRESS_CONSTANTS.attributes.DETERMINATE, value.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LinearProgressComponent.prototype, "progress", {
        get: function () {
            return this._progress;
        },
        set: function (value) {
            if (this._progress !== value) {
                this._progress = value;
                if (this._mdcLinearProgress) {
                    this._mdcLinearProgress.progress = value;
                }
                this.setAttribute(LINEAR_PROGRESS_CONSTANTS.attributes.PROGRESS, value.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LinearProgressComponent.prototype, "buffer", {
        get: function () {
            return this._buffer;
        },
        set: function (value) {
            if (this._buffer !== value) {
                this._buffer = value;
                if (this._mdcLinearProgress) {
                    this._mdcLinearProgress.buffer = value;
                }
                this.setAttribute(LINEAR_PROGRESS_CONSTANTS.attributes.BUFFER, value.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    LinearProgressComponent.prototype.open = function () {
        this._mdcLinearProgress.open();
    };
    LinearProgressComponent.prototype.close = function () {
        this._mdcLinearProgress.close();
    };
    LinearProgressComponent.prototype._initialize = function () {
        upgradeProperty(this, 'determinate');
        upgradeProperty(this, 'progress');
        upgradeProperty(this, 'buffer');
        var root = this.shadowRoot.querySelector(LINEAR_PROGRESS_CONSTANTS.selectors.ROOT);
        this._mdcLinearProgress = new MDCLinearProgress(root);
        this._mdcLinearProgress.initialize();
        this._mdcLinearProgress.determinate = this._determinate;
        this._mdcLinearProgress.progress = this._progress;
        this._mdcLinearProgress.buffer = this._buffer;
    };
    LinearProgressComponent = __decorate([
        CustomElement({
            name: LINEAR_PROGRESS_CONSTANTS.elementName
        })
    ], LinearProgressComponent);
    return LinearProgressComponent;
}(HTMLElement));
export { LinearProgressComponent };
