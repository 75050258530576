import { EXPANSION_PANEL_CONSTANTS } from '../expansion-panel';
var AccordionAdapter = /** @class */ (function () {
    function AccordionAdapter(_component) {
        this._component = _component;
    }
    /**
     * Sets an attribute value on the host element.
     * @param name The attribute name.
     * @param value The attribute value.
     */
    AccordionAdapter.prototype.setHostAttribute = function (name, value) {
        this._component.setAttribute(name, value);
    };
    /**
     * Gets the `<tcw-accordion>` host element.
     * @returns An instance of the `AccordionComponent`.
     */
    AccordionAdapter.prototype.getHostElement = function () {
        return this._component;
    };
    /**
     * Finds all child `<tcw-expansion-panel>` components within the host element.
     * @returns An array of `ExpansionPanelComponent` child instances.
     */
    AccordionAdapter.prototype.getChildPanels = function (selector) {
        if (selector) {
            return Array.from(this._component.querySelectorAll(selector));
        }
        else {
            var children = Array.from(this._component.children);
            return children.filter(function (child) { return child.tagName.toLocaleLowerCase() === EXPANSION_PANEL_CONSTANTS.elementName; });
        }
    };
    /**
     * Adds an event listener to the provided element.
     * @param event The event name.
     * @param callback The event callback.
     * @param element The element to emit the event from.
     * @param [bubbles] Whether the event bubbles or not.
     */
    AccordionAdapter.prototype.addEventListener = function (event, callback, element, bubbles) {
        element.addEventListener(event, callback, bubbles || false);
    };
    /**
     * Removes an event listener from the provided element.
     * @param event The event name.
     * @param callback The event callback.
     * @param element The event to remove the event from.
     */
    AccordionAdapter.prototype.removeEventListener = function (event, callback, element) {
        element.removeEventListener(event, callback);
    };
    return AccordionAdapter;
}());
export { AccordionAdapter };
