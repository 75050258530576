import { __decorate, __extends } from "tslib";
import { CustomElement, attachLightTemplate } from '@tyler-components-web/core';
import { ChipSetAdapter } from './chip-set-adapter';
import { ChipSetFoundation } from './chip-set-foundation';
import { CHIP_SET_CONSTANTS } from './chip-set-constants';
var template = require('./chip-set.html');
/**
 * The custom element class behind the `<tcw-chip-set>` element.
 */
var ChipSetComponent = /** @class */ (function (_super) {
    __extends(ChipSetComponent, _super);
    function ChipSetComponent() {
        var _this = _super.call(this) || this;
        _this._foundation = new ChipSetFoundation(new ChipSetAdapter(_this));
        return _this;
    }
    Object.defineProperty(ChipSetComponent, "observedAttributes", {
        get: function () {
            return [
                CHIP_SET_CONSTANTS.attributes.TYPE,
                CHIP_SET_CONSTANTS.attributes.ORIENTATION,
                CHIP_SET_CONSTANTS.attributes.DENSE
            ];
        },
        enumerable: true,
        configurable: true
    });
    ChipSetComponent.prototype.initializedCallback = function () {
        // Ensure the mdc-chip-set class is the first class on the element
        if (!this.classList.contains(CHIP_SET_CONSTANTS.classes.CHIP_SET)) {
            this.setAttribute('class', CHIP_SET_CONSTANTS.classes.CHIP_SET + " " + (this.getAttribute('class') || ''));
        }
        attachLightTemplate(this, template);
        this._foundation.initialize();
    };
    ChipSetComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case CHIP_SET_CONSTANTS.attributes.ORIENTATION:
                this.orientation = newValue;
                break;
            case CHIP_SET_CONSTANTS.attributes.TYPE:
                this.type = newValue;
                break;
            case CHIP_SET_CONSTANTS.attributes.DENSE:
                this.dense = this.hasAttribute(CHIP_SET_CONSTANTS.attributes.DENSE);
                break;
        }
    };
    Object.defineProperty(ChipSetComponent.prototype, "orientation", {
        /** Gets/sets the chip orientation of the chips within the set. */
        get: function () {
            return this._foundation.orientation;
        },
        set: function (value) {
            this._foundation.orientation = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipSetComponent.prototype, "type", {
        /** Gets/sets the chip type for all child chips. */
        get: function () {
            return this._foundation.type;
        },
        set: function (value) {
            this._foundation.type = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipSetComponent.prototype, "dense", {
        /** Gets/sets dense for all child chips. */
        get: function () {
            return this._foundation.dense;
        },
        set: function (value) {
            this._foundation.dense = value;
        },
        enumerable: true,
        configurable: true
    });
    ChipSetComponent = __decorate([
        CustomElement({
            name: CHIP_SET_CONSTANTS.elementName
        })
    ], ChipSetComponent);
    return ChipSetComponent;
}(HTMLElement));
export { ChipSetComponent };
