import { __decorate, __extends } from "tslib";
import { MDCTabBar } from '@material/tab-bar';
import { MDCTab } from '@material/tab';
import { CustomElement, upgradeProperty, attachShadowTemplate, getShadowElement, parseTemplateString, elementIndex, emitEvent } from '@tyler-components-web/core';
import { TAB_BAR_CONSTANTS } from './tab-bar-constants';
var template = require('./tab-bar.html');
var templateTab = require('./tab.html');
var styles = require('./tab-bar.css');
/**
 * The custom element class behind the `<tcw-tab-bar>` element.
 */
var TabBarComponent = /** @class */ (function (_super) {
    __extends(TabBarComponent, _super);
    function TabBarComponent() {
        var _this = _super.call(this) || this;
        _this._activeTab = 0;
        attachShadowTemplate(_this, TAB_BAR_CONSTANTS.elementName, template, styles);
        return _this;
    }
    TabBarComponent_1 = TabBarComponent;
    Object.defineProperty(TabBarComponent, "observedAttributes", {
        get: function () {
            return [
                TAB_BAR_CONSTANTS.attributes.ACTIVE_TAB
            ];
        },
        enumerable: true,
        configurable: true
    });
    TabBarComponent.prototype._buildTabElement = function (element, isActive) {
        if (isActive === void 0) { isActive = false; }
        element.classList.add(TAB_BAR_CONSTANTS.classes.TAB);
        element.setAttribute('role', 'tab');
        element.setAttribute('type', 'button');
        if (!element.hasAttribute('id')) {
            TabBarComponent_1.tabId++;
            element.setAttribute('id', "tcw-tab-" + TabBarComponent_1.tabId);
        }
        var tabElement = parseTemplateString(templateTab).content.cloneNode(true);
        if (isActive) {
            element.classList.add(TAB_BAR_CONSTANTS.classes.TAB_ACTIVE);
            element.setAttribute('aria-selected', 'true');
            element.setAttribute('tabindex', '0');
            tabElement.querySelector(TAB_BAR_CONSTANTS.selectors.TAB_INDICATOR).classList.add(TAB_BAR_CONSTANTS.classes.TAB_INDICATOR_ACTIVE);
        }
        else {
            element.setAttribute('aria-selected', 'false');
            element.setAttribute('tabindex', '-1');
        }
        var iconElement = element.querySelector(TAB_BAR_CONSTANTS.selectors.TAB_ICON);
        if (iconElement) {
            iconElement.classList.add(TAB_BAR_CONSTANTS.classes.TAB_ICON);
            tabElement.querySelector(TAB_BAR_CONSTANTS.selectors.TAB_CONTENT).appendChild(iconElement);
        }
        var labelElement = element.querySelector(TAB_BAR_CONSTANTS.selectors.TAB_LABEL);
        if (labelElement) {
            labelElement.classList.add(TAB_BAR_CONSTANTS.classes.TAB_LABEL);
            tabElement.querySelector(TAB_BAR_CONSTANTS.selectors.TAB_CONTENT).appendChild(labelElement);
        }
        element.appendChild(tabElement);
    };
    TabBarComponent.prototype._connectObserver = function () {
        var _this = this;
        this._observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                mutation.removedNodes.forEach(function (element) {
                    if (element.tagName === 'BUTTON') {
                        var len = _this.mdcTabBar['tabList_'].length;
                        for (var i = len - 1; i >= 0; i--) {
                            if (_this.mdcTabBar['tabList_'][i].root_ === element) {
                                _this.mdcTabBar['tabList_'][i].destroy();
                                _this.mdcTabBar['tabList_'].splice(i, 1);
                            }
                        }
                    }
                });
                mutation.addedNodes.forEach(function (element) {
                    if (element.tagName === 'BUTTON') {
                        var index = elementIndex(element);
                        _this._buildTabElement(element, index === _this.activeTab);
                        var mdcTab = new MDCTab(element);
                        _this.mdcTabBar['tabList_'].splice(index, 0, mdcTab);
                    }
                });
            });
        });
        this._observer.observe(this, { childList: true, subtree: true });
    };
    Object.defineProperty(TabBarComponent.prototype, "activeTab", {
        /** Gets and sets the active tab index. */
        get: function () {
            return this._activeTab;
        },
        set: function (value) {
            var _this = this;
            if (this._activeTab !== value) {
                this._activeTab = value;
                if (this.mdcTabBar) {
                    window.requestAnimationFrame(function () { return _this.mdcTabBar.activateTab(_this._activeTab); });
                }
                this.setAttribute(TAB_BAR_CONSTANTS.attributes.ACTIVE_TAB, this._activeTab.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    TabBarComponent.prototype.initializedCallback = function () {
        var _this = this;
        upgradeProperty(this, 'activeTab');
        var root = getShadowElement(this, '.mdc-tab-bar');
        this.mdcTabBar = new MDCTabBar(root);
        // Patch the MDCTabBar.getTabElements_ internal function to allow for discovering slotted tab elements
        this.mdcTabBar['getTabElements_'] = function () { return Array.from(_this.querySelectorAll(TAB_BAR_CONSTANTS.selectors.TAB)); };
        this.mdcTabBar.initialize();
        // Capture and re-emit the tab bar events
        root.addEventListener('MDCTabBar:activated', function (evt) {
            _this._activeTab = evt.detail.index;
            emitEvent(_this, TAB_BAR_CONSTANTS.events.ACTIVATE, { index: evt.detail.index }, true);
        });
        if (this.children.length) {
            Array.from(this.children).forEach(function (element, index) {
                if (element.tagName === 'BUTTON') {
                    _this._buildTabElement(element, index === _this.activeTab);
                    var mdcTab = new MDCTab(element);
                    _this.mdcTabBar['tabList_'].push(mdcTab);
                }
            });
            window.requestAnimationFrame(function () {
                _this._connectObserver();
            });
        }
        else {
            this._connectObserver();
        }
    };
    TabBarComponent.prototype.disconnectedCallback = function () {
        if (this.mdcTabBar) {
            this.mdcTabBar.destroy();
        }
        if (this._observer) {
            this._observer.disconnect();
        }
    };
    TabBarComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case TAB_BAR_CONSTANTS.attributes.ACTIVE_TAB:
                this.activeTab = Number(newValue);
        }
    };
    var TabBarComponent_1;
    TabBarComponent.tabId = 0;
    TabBarComponent = TabBarComponent_1 = __decorate([
        CustomElement({
            name: TAB_BAR_CONSTANTS.elementName
        })
    ], TabBarComponent);
    return TabBarComponent;
}(HTMLElement));
export { TabBarComponent };
