import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty, coerceBoolean } from '@tyler-components-web/core';
import { ListAdapter } from './list-adapter';
import { ListFoundation } from './list-foundation';
import { LIST_CONSTANTS } from './list-constants';
import { ListItemComponent } from '../list-item';
var template = require('./list.html');
var styles = require('./list.css');
/**
 * The custom element class behind the `<tcw-list>` element.
 */
var ListComponent = /** @class */ (function (_super) {
    __extends(ListComponent, _super);
    function ListComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, LIST_CONSTANTS.elementName, template, styles);
        _this._foundation = new ListFoundation(new ListAdapter(_this));
        return _this;
    }
    Object.defineProperty(ListComponent, "observedAttributes", {
        get: function () {
            return [
                LIST_CONSTANTS.attributes.STATIC,
                LIST_CONSTANTS.attributes.DENSE,
                LIST_CONSTANTS.attributes.SELECTED_VALUE,
                LIST_CONSTANTS.attributes.PROPAGATE_CLICK,
                LIST_CONSTANTS.attributes.INDENTED
            ];
        },
        enumerable: true,
        configurable: true
    });
    ListComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'static');
        upgradeProperty(this, 'dense');
        upgradeProperty(this, 'propagateClick');
        upgradeProperty(this, 'indented');
        upgradeProperty(this, 'selectedValue');
        this._foundation.initialize();
    };
    ListComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case LIST_CONSTANTS.attributes.STATIC:
                this.static = this.hasAttribute(LIST_CONSTANTS.attributes.STATIC);
                break;
            case LIST_CONSTANTS.attributes.DENSE:
                this.dense = this.hasAttribute(LIST_CONSTANTS.attributes.DENSE);
                break;
            case LIST_CONSTANTS.attributes.PROPAGATE_CLICK:
                this.propagateClick = coerceBoolean(newValue);
                break;
            case LIST_CONSTANTS.attributes.INDENTED:
                this.indented = this.hasAttribute(LIST_CONSTANTS.attributes.INDENTED);
                break;
            case LIST_CONSTANTS.attributes.SELECTED_VALUE:
                this.selectedValue = newValue;
                break;
        }
    };
    Object.defineProperty(ListComponent.prototype, "static", {
        /** Gets/sets whether the list has all static items or not. */
        get: function () {
            return this._foundation.static;
        },
        set: function (value) {
            this._foundation.static = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListComponent.prototype, "dense", {
        /** Gets/sets whether the list has all dense items or not. */
        get: function () {
            return this._foundation.dense;
        },
        set: function (value) {
            this._foundation.dense = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListComponent.prototype, "propagateClick", {
        /** Gets/sets whether the list items allow mousedown events through to their underlying list item elements. Default is true. */
        get: function () {
            return this._foundation.propagateClick;
        },
        set: function (value) {
            this._foundation.propagateClick = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListComponent.prototype, "indented", {
        /** Gets/sets whether the list items within this list are indented. Default is false. */
        get: function () {
            return this._foundation.indented;
        },
        set: function (value) {
            this._foundation.indented = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListComponent.prototype, "selectedValue", {
        /** Gets/sets the selected list item value(s) */
        get: function () {
            return this._foundation.selectedValue;
        },
        set: function (value) {
            this._foundation.selectedValue = value;
        },
        enumerable: true,
        configurable: true
    });
    ListComponent = __decorate([
        CustomElement({
            name: LIST_CONSTANTS.elementName,
            dependencies: [ListItemComponent]
        })
    ], ListComponent);
    return ListComponent;
}(HTMLElement));
export { ListComponent };
