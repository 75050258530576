import { COMPONENT_NAME_PREFIX } from '../../constants';
var elementName = COMPONENT_NAME_PREFIX + "app-launcher";
var classes = {
    GRID_ITEM_TITLE: 'tyl-app-launcher__grid-item-title',
    GRID_ITEM: 'tyl-app-launcher__grid-item'
};
var selectors = {
    ROOT: '.tyl-app-launcher',
    OPTIONS_CONTAINER: '#tyl-app-launcher-options',
    GRID: '.tyl-app-launcher__grid',
    GRID_ITEM: "." + classes.GRID_ITEM,
    SEARCH_CONTAINER: '.tyl-app-launcher__search-container',
    SEARCH_INPUT: 'input#app-launcher-search-input',
    TITLE: '#app-launcher-apps-title'
};
var events = {
    SELECT: elementName + "-select"
};
var strings = {
    DEFAULT_FONT_ICON_NAME: 'exit_to_app'
};
var numbers = {
    MIN_OPTIONS_FOR_SEARCH: 16
};
export var APP_LAUNCHER_CONSTANTS = {
    elementName: elementName,
    classes: classes,
    selectors: selectors,
    events: events,
    strings: strings,
    numbers: numbers
};
export var AppLauncherView;
(function (AppLauncherView) {
    AppLauncherView[AppLauncherView["Loading"] = 0] = "Loading";
    AppLauncherView[AppLauncherView["Default"] = 1] = "Default";
    AppLauncherView[AppLauncherView["Empty"] = 2] = "Empty";
    AppLauncherView[AppLauncherView["Error"] = 3] = "Error";
})(AppLauncherView || (AppLauncherView = {}));
