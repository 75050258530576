import { __extends } from "tslib";
import { isDefined, addClass } from '@tyler-components-web/core';
import { AbstractComponentDelegate } from '../core/delegates/abstract-component-delegate';
import { TEXT_FIELD_CONSTANTS } from '../text-field';
import { AUTOCOMPLETE_CONSTANTS } from './autocomplete-constants';
import { ICON_CLASS_NAME } from '../constants';
var AutocompleteComponentDelegate = /** @class */ (function (_super) {
    __extends(AutocompleteComponentDelegate, _super);
    function AutocompleteComponentDelegate(config) {
        return _super.call(this, config) || this;
    }
    AutocompleteComponentDelegate.prototype.build = function () {
        var autocomplete = document.createElement(AUTOCOMPLETE_CONSTANTS.elementName);
        autocomplete.filter = this._config.filter;
        this._textField = document.createElement(TEXT_FIELD_CONSTANTS.elementName);
        this._input = document.createElement('input');
        this._input.type = this._config.type || 'text';
        this._textField.appendChild(this._input);
        var dropdownIcon = document.createElement('i');
        dropdownIcon.slot = 'trailing';
        dropdownIcon.textContent = 'arrow_drop_down';
        addClass([ICON_CLASS_NAME, 'tyl-dropdown-icon'], dropdownIcon);
        dropdownIcon.setAttribute('aria-hidden', 'true');
        dropdownIcon.setAttribute('data-tyl-dropdown-icon', '');
        this._textField.appendChild(dropdownIcon);
        if (isDefined(this._config.label) && this._config.label) {
            var label = document.createElement('label');
            label.textContent = this._config.label;
            this._textField.appendChild(label);
        }
        if (isDefined(this._config.helperText) && this._config.helperText) {
            this.setHelperText(this._config.helperText);
        }
        autocomplete.appendChild(this._textField);
        return autocomplete;
    };
    AutocompleteComponentDelegate.prototype.configure = function () {
        // Configure the autocomplete
        if (isDefined(this._config.multiple)) {
            this._component.multiple = this._config.multiple;
        }
        if (isDefined(this._config.debounce)) {
            this._component.debounce = this._config.debounce;
        }
        if (isDefined(this._config.filterOnFocus)) {
            this._component.filterOnFocus = this._config.filterOnFocus;
        }
        if (isDefined(this._config.allowUnmatched)) {
            this._component.allowUnmatched = this._config.allowUnmatched;
        }
        if (isDefined(this._config.optionLimit)) {
            this._component.optionLimit = this._config.optionLimit;
        }
        if (isDefined(this._config.optionBuilder)) {
            this._component.optionBuilder = this._config.optionBuilder;
        }
        if (isDefined(this._config.selectedTextBuilder)) {
            this._component.selectedTextBuilder = this._config.selectedTextBuilder;
        }
        // Configure the text-field
        if (isDefined(this._config.placeholder)) {
            this._input.placeholder = this._config.placeholder;
        }
        if (isDefined(this._config.required)) {
            this._textField.required = this._config.required;
        }
        if (isDefined(this._config.invalid)) {
            this._textField.invalid = this._config.invalid;
        }
        if (isDefined(this._config.floatLabelType)) {
            this._textField.floatLabelType = this._config.floatLabelType;
        }
        if (isDefined(this._config.placeholder)) {
            this._input.placeholder = this._config.placeholder;
        }
    };
    AutocompleteComponentDelegate.prototype.getValue = function () {
        return this._component.value;
    };
    AutocompleteComponentDelegate.prototype.getInputValue = function () {
        return this._input.value;
    };
    AutocompleteComponentDelegate.prototype.setValue = function (value) {
        this._input.value = value;
    };
    AutocompleteComponentDelegate.prototype.onChange = function (listener) {
        this._component.addEventListener(AUTOCOMPLETE_CONSTANTS.events.CHANGE, function (evt) { return listener(evt.detail); });
    };
    AutocompleteComponentDelegate.prototype.onInput = function (listener) {
        this._input.addEventListener('input', function (evt) { return listener(evt); });
    };
    AutocompleteComponentDelegate.prototype.onFocus = function (listener) {
        this._input.addEventListener('focus', function (evt) { return listener(evt); });
    };
    AutocompleteComponentDelegate.prototype.onBlur = function (listener) {
        this._input.addEventListener('blur', function (evt) { return listener(evt); });
    };
    AutocompleteComponentDelegate.prototype.setDisabled = function (value) {
        this._input.disabled = value;
    };
    AutocompleteComponentDelegate.prototype.validate = function () {
        return !!this._input.value.trim();
    };
    AutocompleteComponentDelegate.prototype.setHelperText = function (text) {
        if (text) {
            if (!this._helperText) {
                this._helperText = document.createElement('span');
                this._helperText.slot = 'helper-text';
                this._textField.appendChild(this._helperText);
            }
            this._helperText.textContent = text;
        }
        else if (this._helperText && this._helperText.isConnected) {
            this._component.removeChild(this._helperText);
            this._helperText = undefined;
        }
    };
    AutocompleteComponentDelegate.prototype.setValidity = function (value) {
        this._textField.invalid = !value;
    };
    AutocompleteComponentDelegate.prototype.floatLabel = function (value) {
        this._textField.floatLabel(value);
    };
    return AutocompleteComponentDelegate;
}(AbstractComponentDelegate));
export { AutocompleteComponentDelegate };
