import { defineCustomElement } from '@tyler-components-web/core';
import { TooltipComponent } from './tooltip';
export * from './tooltip-adapter';
export * from './tooltip-constants';
export * from './tooltip-foundation';
export * from './tooltip-utils';
export * from './tooltip';
export function defineTooltipComponent() {
    defineCustomElement(TooltipComponent);
}
