import { COLOR_PICKER_CONSTANTS } from './color-picker-constants';
import { relativeCoords } from './color-picker-utils';
var ColorPickerSlider = /** @class */ (function () {
    function ColorPickerSlider(_rootElement, _changeListener) {
        var _this = this;
        this._rootElement = _rootElement;
        this._changeListener = _changeListener;
        this._percent = 1;
        this._min = 0;
        this._max = 1;
        this._step = 0.01;
        this._keydownListener = function (evt) { return _this._onKeydown(evt); };
        this._downListener = function (evt) { return _this._onDown(evt); };
        this._moveListener = function (evt) { return _this._onMove(evt); };
        this._upListener = function (evt) { return _this._onUp(evt); };
        this._initialize();
    }
    ColorPickerSlider.prototype.destroy = function () {
        this._unlisten();
    };
    ColorPickerSlider.prototype.setValue = function (value) {
        this._percent = value;
        this._setThumbPosition(this._percent);
    };
    ColorPickerSlider.prototype._initialize = function () {
        this._thumbElement = this._rootElement.querySelector(COLOR_PICKER_CONSTANTS.selectors.SLIDER_THUMB);
        this._listen();
        this._setThumbPosition(this._percent);
    };
    ColorPickerSlider.prototype._listen = function () {
        this._thumbElement.addEventListener('keydown', this._keydownListener);
        this._rootElement.addEventListener('mousedown', this._downListener);
        this._rootElement.addEventListener('touchstart', this._downListener);
    };
    ColorPickerSlider.prototype._unlisten = function () {
        this._thumbElement.removeEventListener('keydown', this._keydownListener);
        this._rootElement.removeEventListener('mousedown', this._downListener);
        this._rootElement.removeEventListener('touchstart', this._downListener);
        document.removeEventListener('mousemove', this._moveListener);
        document.removeEventListener('touchmove', this._moveListener);
        document.removeEventListener('mouseup', this._upListener);
        document.removeEventListener('touchend', this._upListener);
    };
    ColorPickerSlider.prototype._onKeydown = function (evt) {
        var isArrowLeftKey = evt.key === 'ArrowLeft' || evt.keyCode === 37;
        var isArrowRightKey = evt.key === 'ArrowRight' || evt.keyCode === 39;
        var isHomeKey = evt.key === 'Home' || evt.keyCode === 36;
        var isEndKey = evt.key === 'End' || evt.keyCode === 35;
        if (isArrowLeftKey) {
            evt.preventDefault();
            this._percent -= this._step;
        }
        else if (isArrowRightKey) {
            evt.preventDefault();
            this._percent += this._step;
        }
        else if (isHomeKey) {
            evt.preventDefault();
            this._percent = this._min;
        }
        else if (isEndKey) {
            evt.preventDefault();
            this._percent = this._max;
        }
        if (this._percent < this._min) {
            this._percent = this._min;
        }
        else if (this._percent > this._max) {
            this._percent = this._max;
        }
        this._setThumbPosition(this._percent);
        this._notify();
    };
    ColorPickerSlider.prototype._onDown = function (evt) {
        evt.preventDefault();
        document.addEventListener('mousemove', this._moveListener);
        document.addEventListener('touchmove', this._moveListener);
        document.addEventListener('mouseup', this._upListener);
        document.addEventListener('touchend', this._upListener);
        this._updateThumbPosition(evt);
    };
    ColorPickerSlider.prototype._onMove = function (evt) {
        evt.preventDefault();
        this._thumbElement.classList.add(COLOR_PICKER_CONSTANTS.classes.SLIDER_THUMB_ACTIVE);
        this._updateThumbPosition(evt);
    };
    ColorPickerSlider.prototype._onUp = function (evt) {
        this._thumbElement.classList.remove(COLOR_PICKER_CONSTANTS.classes.SLIDER_THUMB_ACTIVE);
        document.removeEventListener('mousemove', this._moveListener);
        document.removeEventListener('touchmove', this._moveListener);
        document.removeEventListener('mouseup', this._upListener);
        document.removeEventListener('touchend', this._upListener);
        this._updateThumbPosition(evt);
        this._thumbElement.focus();
    };
    ColorPickerSlider.prototype._updateThumbPosition = function (evt) {
        var x = /^mouse/.test(evt.type) ? evt.clientX : evt.changedTouches[0].clientX;
        this._percent = this._calculateSliderPercent(x);
        this._setThumbPosition(this._percent);
        this._notify();
    };
    ColorPickerSlider.prototype._calculateSliderPercent = function (absX) {
        var coords = relativeCoords(absX, 0, this._rootElement);
        return parseFloat((coords.x / coords.width).toFixed(2));
    };
    ColorPickerSlider.prototype._setThumbPosition = function (percent) {
        this._thumbElement.style.left = percent * 100 + "%";
    };
    ColorPickerSlider.prototype._notify = function () {
        if (typeof this._changeListener === 'function') {
            this._changeListener(this._percent);
        }
    };
    return ColorPickerSlider;
}());
export { ColorPickerSlider };
