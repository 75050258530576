import { isDefined, isArray, coerceNumber } from '@tyler-components-web/core';
import { PAGINATOR_CONSTANTS } from './paginator-constants';
/**
 * The foundation class behind the `<tcw-paginator>` component.
 */
var PaginatorFoundation = /** @class */ (function () {
    function PaginatorFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        // Backing models
        this._pageIndex = PAGINATOR_CONSTANTS.numbers.DEFAULT_PAGE_INDEX;
        this._pageSize = PAGINATOR_CONSTANTS.numbers.DEFAULT_PAGE_SIZE;
        this._total = PAGINATOR_CONSTANTS.numbers.DEFAULT_TOTAL;
        this._pageSizeOptions = [];
        this._label = PAGINATOR_CONSTANTS.strings.DEFAULT_LABEL;
        this._firstLast = false;
        this._first = false;
        this._disabled = false;
        // Create listeners
        this._pageSizeListener = function (evt) { return _this._onPageSizeChanged(evt); };
        this._firstPageListener = function (evt) { return _this._onFirstPage(evt); };
        this._previousPageListener = function (evt) { return _this._onPreviousPage(evt); };
        this._nextPageListener = function (evt) { return _this._onNextPage(evt); };
        this._lastPageListener = function (evt) { return _this._onLastPage(evt); };
        this._pageSizeOptions = PAGINATOR_CONSTANTS.numbers.DEFAULT_PAGE_SIZE_OPTIONS.map(function (o) { return ({ label: o.toString(), value: o.toString() }); });
    }
    Object.defineProperty(PaginatorFoundation.prototype, "pageIndex", {
        get: function () {
            return this._pageIndex;
        },
        /** The zero-based page index. Default is 0. */
        set: function (value) {
            if (this._pageIndex !== value) {
                if (isDefined(value)) {
                    this._pageIndex = value;
                    this._update();
                    this._adapter.setHostAttribute(PAGINATOR_CONSTANTS.attributes.PAGE_INDEX, this._pageIndex.toString());
                }
                else {
                    this._adapter.removeHostAttribute(PAGINATOR_CONSTANTS.attributes.PAGE_INDEX);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorFoundation.prototype, "pageSize", {
        get: function () {
            return this._pageSize;
        },
        /** Number of items to display on a page. By default set to 25. */
        set: function (value) {
            if (this._pageSize !== value) {
                this._pageSize = value;
                this._adapter.setPageSize(this._pageSize);
                // this.pageIndex = 0;
                this._update();
                this._adapter.setHostAttribute(PAGINATOR_CONSTANTS.attributes.PAGE_SIZE, this._pageSize.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorFoundation.prototype, "total", {
        get: function () {
            return this._total;
        },
        /** The total number of items to be paginated. Default is 0. */
        set: function (value) {
            if (this._total !== value) {
                this._total = value;
                this._update();
                this._adapter.setHostAttribute(PAGINATOR_CONSTANTS.attributes.TOTAL, this._total.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorFoundation.prototype, "pageSizeOptions", {
        get: function () {
            return this._pageSizeOptions.map(function (o) { return Number(o.value); });
        },
        /** The set of provided page size options to display to the user. */
        set: function (options) {
            var _this = this;
            if (isArray(options)) {
                this._pageSizeOptions = options
                    .map(function (o) { return ({ label: o.toString(), value: o.toString() }); })
                    .sort(function (a, b) { return coerceNumber(a.value) - coerceNumber(b.value); });
                if (isDefined(this._pageSize) && this._pageSizeOptions.length && !this._pageSizeOptions.find(function (o) { return coerceNumber(o.value) === _this._pageSize; })) {
                    this._pageSize = coerceNumber(this._pageSizeOptions[0].value);
                }
                this._adapter.setPageSizeOptions(this._pageSizeOptions);
                this._adapter.attachPageSizeChangeListener(this._pageSizeListener);
                this._adapter.setPageSizeVisibility(true);
            }
            else if (options.toString().toLowerCase() === 'false') {
                this._adapter.detachPageSizeChangeListener(this._pageSizeListener);
                this._adapter.setPageSizeVisibility(false);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorFoundation.prototype, "label", {
        get: function () {
            return this._label;
        },
        /** A label for the paginator. Default is "Rows per page:". */
        set: function (value) {
            if (this._label !== value) {
                this._label = value;
                this._adapter.setLabel(this._label);
                this._adapter.setHostAttribute(PAGINATOR_CONSTANTS.attributes.LABEL, isDefined(this._label) ? this._label.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorFoundation.prototype, "firstLast", {
        get: function () {
            return this._firstLast;
        },
        /** Whether to show the first page and last page buttons. Default is false. */
        set: function (value) {
            if (this._firstLast !== value) {
                this._firstLast = value;
                this._toggleFirstLastButtons();
                if (this._firstLast) {
                    this._adapter.setHostAttribute(PAGINATOR_CONSTANTS.attributes.FIRST_LAST);
                }
                else {
                    this._adapter.removeHostAttribute(PAGINATOR_CONSTANTS.attributes.FIRST_LAST);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorFoundation.prototype, "first", {
        get: function () {
            return this._first;
        },
        /** Whether to show the first page button. Default is false. */
        set: function (value) {
            if (this._first !== value) {
                this._first = value;
                this._toggleFirstButton();
                if (this._first) {
                    this._adapter.setHostAttribute(PAGINATOR_CONSTANTS.attributes.FIRST);
                }
                else {
                    this._adapter.removeHostAttribute(PAGINATOR_CONSTANTS.attributes.FIRST);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorFoundation.prototype, "disabled", {
        get: function () {
            return this._disabled;
        },
        /** Whether the paginator is disabled. Default is false. */
        set: function (value) {
            if (this._disabled !== value) {
                this._disabled = value;
                this._update();
                if (this._disabled) {
                    this._adapter.setHostAttribute(PAGINATOR_CONSTANTS.attributes.DISABLED);
                }
                else {
                    this._adapter.removeHostAttribute(PAGINATOR_CONSTANTS.attributes.DISABLED);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Intializes the internal state when the component loads.
     */
    PaginatorFoundation.prototype.initialize = function () {
        this._update();
        this._adapter.setLabel(this._label);
        this._adapter.setPageSizeOptions(this._pageSizeOptions);
        this._adapter.setPageSize(this._pageSize);
        this._attachListeners();
        this._toggleFirstLastButtons();
    };
    PaginatorFoundation.prototype.disconnect = function () {
        this._detachListeners();
    };
    PaginatorFoundation.prototype._attachListeners = function () {
        this._adapter.attachPageSizeChangeListener(this._pageSizeListener);
        this._adapter.attachFirstPageListener(this._firstPageListener);
        this._adapter.attachPreviousPageListener(this._previousPageListener);
        this._adapter.attachNextPageListener(this._nextPageListener);
        this._adapter.attachLastPageListener(this._lastPageListener);
    };
    PaginatorFoundation.prototype._detachListeners = function () {
        this._adapter.detachPageSizeChangeListener(this._pageSizeListener);
        this._adapter.detachFirstPageListener(this._firstPageListener);
        this._adapter.detachPreviousPageListener(this._previousPageListener);
        this._adapter.detachNextPageListener(this._nextPageListener);
        this._adapter.detachLastPageListener(this._lastPageListener);
    };
    /**
     * Handles clicking the first page button.
     * @param evt The click event.
     */
    PaginatorFoundation.prototype._onFirstPage = function (evt) {
        evt.stopPropagation();
        if (!this._hasFirstPage()) {
            return;
        }
        var detail = {
            type: PAGINATOR_CONSTANTS.strings.FIRST_PAGE,
            pageSize: this._pageSize,
            pageIndex: this.pageIndex = 0
        };
        this._adapter.emitEvent(PAGINATOR_CONSTANTS.events.CHANGE, detail);
    };
    /**
     * Handles clicking the previous page button.
     * @param evt The click event.
     */
    PaginatorFoundation.prototype._onPreviousPage = function (evt) {
        evt.stopPropagation();
        if (!this._hasPreviousPage()) {
            return;
        }
        var detail = {
            type: PAGINATOR_CONSTANTS.strings.PREVIOUS_PAGE,
            pageSize: this._pageSize,
            pageIndex: --this.pageIndex
        };
        this._adapter.emitEvent(PAGINATOR_CONSTANTS.events.CHANGE, detail);
    };
    /**
     * Handles clicking the next page button.
     * @param evt The click event.
     */
    PaginatorFoundation.prototype._onNextPage = function (evt) {
        evt.stopPropagation();
        if (!this._hasNextPage()) {
            return;
        }
        var detail = {
            type: PAGINATOR_CONSTANTS.strings.NEXT_PAGE,
            pageSize: this._pageSize,
            pageIndex: ++this.pageIndex
        };
        this._adapter.emitEvent(PAGINATOR_CONSTANTS.events.CHANGE, detail);
    };
    /**
     * Handles clicking the last page button.
     * @param evt The click event.
     */
    PaginatorFoundation.prototype._onLastPage = function (evt) {
        evt.stopPropagation();
        if (!this._hasLastPage()) {
            return;
        }
        var detail = {
            type: PAGINATOR_CONSTANTS.strings.LAST_PAGE,
            pageSize: this._pageSize,
            pageIndex: this.pageIndex = this._getMaxPages()
        };
        this._adapter.emitEvent(PAGINATOR_CONSTANTS.events.CHANGE, detail);
    };
    /**
     * Handles selecting a new item in the page size options.
     * @param evt The select custom event.
     */
    PaginatorFoundation.prototype._onPageSizeChanged = function (evt) {
        evt.stopPropagation();
        this.pageIndex = 0;
        this.pageSize = Number(evt.target.value);
        var detail = {
            type: PAGINATOR_CONSTANTS.strings.PAGE_SIZE,
            pageSize: this._pageSize,
            pageIndex: this._pageIndex
        };
        this._adapter.emitEvent(PAGINATOR_CONSTANTS.events.CHANGE, detail);
    };
    /**
     * Returns the max number of pages based on our current parameters.
     */
    PaginatorFoundation.prototype._getMaxPages = function () {
        return Math.ceil(this._total / this._pageSize) - 1;
    };
    /**
     * Updates our internal state as well as updating the UI.
     */
    PaginatorFoundation.prototype._update = function () {
        // Create and update the range label
        if (this.pageSize > 1) {
            var startIndex = this._pageIndex * this._pageSize;
            var indexStart = Math.floor(startIndex / this._pageSize) || 0;
            var indexEnd = indexStart + this._pageSize;
            var pageStart = (indexStart * this._pageSize) + 1;
            var pageEnd = startIndex < this._total ? Math.min(startIndex + this._pageSize, this._total) : startIndex + this._pageSize;
            this._rangeLabel = pageStart + "-" + pageEnd + " " + PAGINATOR_CONSTANTS.strings.RANGE_SEPARATOR_LABEL + " " + this._total;
        }
        else {
            this._rangeLabel = this._pageIndex + 1 + " " + PAGINATOR_CONSTANTS.strings.RANGE_SEPARATOR_LABEL + " " + this._total;
        }
        this._adapter.setRangeLabel(this._rangeLabel);
        if (this.disabled) {
            this._adapter.disablePageSizeSelect();
            this._adapter.disableFirstPageButton();
            this._adapter.disablePreviousPageButton();
            this._adapter.disableNextPageButton();
            this._adapter.disableLastPageButton();
        }
        else {
            this._adapter.enablePageSizeSelect();
            // Check if first page button needs to be enabled/disabled
            if (this._hasFirstPage()) {
                this._adapter.enableFirstPageButton();
            }
            else {
                this._adapter.disableFirstPageButton();
            }
            // Check if previous page button needs to be enabled/disabled
            if (this._hasPreviousPage()) {
                this._adapter.enablePreviousPageButton();
            }
            else {
                this._adapter.disablePreviousPageButton();
            }
            // Check if next page button needs to be enabled/disabled
            if (this._hasNextPage()) {
                this._adapter.enableNextPageButton();
            }
            else {
                this._adapter.disableNextPageButton();
            }
            // Check if last page button needs to be enabled/disabled
            if (this._hasLastPage()) {
                this._adapter.enableLastPageButton();
            }
            else {
                this._adapter.disableLastPageButton();
            }
        }
    };
    /**
     * Toggle showing/hiding first and last buttons based on the show first/last buttons flag.
     */
    PaginatorFoundation.prototype._toggleFirstLastButtons = function () {
        this._toggleFirstButton();
        if (this._firstLast) {
            if (!this._adapter.hasLastPageButton()) {
                this._adapter.showLastPageButton();
            }
        }
        else {
            if (this._adapter.hasLastPageButton()) {
                this._adapter.hideLastPageButton();
            }
        }
    };
    /**
     * Toggle showing/hiding first button based on the show first or first/last buttons flags.
     */
    PaginatorFoundation.prototype._toggleFirstButton = function () {
        if (this._first || this._firstLast) {
            if (!this._adapter.hasFirstPageButton()) {
                this._adapter.showFirstPageButton();
            }
        }
        else {
            if (this._adapter.hasFirstPageButton()) {
                this._adapter.hideFirstPageButton();
            }
        }
    };
    /** Checks if a first page exists. */
    PaginatorFoundation.prototype._hasFirstPage = function () {
        // same as has previous page
        return this._hasPreviousPage();
    };
    /**
     * Checks if a previous page exists.
     */
    PaginatorFoundation.prototype._hasPreviousPage = function () {
        return this._pageIndex >= 1 && this._pageSize !== 0;
    };
    /** Checks if a next page exists */
    PaginatorFoundation.prototype._hasNextPage = function () {
        var maxPages = this._getMaxPages();
        return this._pageIndex < maxPages && this._pageSize !== 0;
    };
    /** Checks if a last page exists. */
    PaginatorFoundation.prototype._hasLastPage = function () {
        // same as has next page
        return this._hasNextPage();
    };
    return PaginatorFoundation;
}());
export { PaginatorFoundation };
