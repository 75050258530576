import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, coerceBoolean, getShadowElement, isDefined } from '@tyler-components-web/core';
import { CARD_CONSTANTS } from './card-constants';
var template = require('./card.html');
var styles = require('./card.css');
/**
 * The custom element class behind the `tcw-card` component.
 */
var CardComponent = /** @class */ (function (_super) {
    __extends(CardComponent, _super);
    function CardComponent() {
        var _this = _super.call(this) || this;
        _this._hasPadding = true;
        _this._outlined = false;
        attachShadowTemplate(_this, CARD_CONSTANTS.elementName, template, styles);
        _this._root = getShadowElement(_this, CARD_CONSTANTS.selectors.CARD);
        return _this;
    }
    Object.defineProperty(CardComponent, "observedAttributes", {
        get: function () {
            return [
                CARD_CONSTANTS.attributes.HAS_PADDING,
                CARD_CONSTANTS.attributes.OUTLINED
            ];
        },
        enumerable: true,
        configurable: true
    });
    CardComponent.prototype.connectedCallback = function () {
        this._setPaddingClass();
        this._setOutlinedClass();
    };
    CardComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case CARD_CONSTANTS.attributes.HAS_PADDING:
                this.hasPadding = coerceBoolean(newValue);
                break;
            case CARD_CONSTANTS.attributes.OUTLINED:
                this.outlined = coerceBoolean(newValue);
                break;
        }
    };
    CardComponent.prototype._setPaddingClass = function () {
        if (this._hasPadding) {
            this._root.classList.add(CARD_CONSTANTS.classes.PADDED);
        }
        else {
            this._root.classList.remove(CARD_CONSTANTS.classes.PADDED);
        }
    };
    CardComponent.prototype._setOutlinedClass = function () {
        if (this._outlined) {
            this._root.classList.add(CARD_CONSTANTS.classes.OUTLINED);
        }
        else {
            this._root.classList.remove(CARD_CONSTANTS.classes.OUTLINED);
        }
    };
    Object.defineProperty(CardComponent.prototype, "hasPadding", {
        /** Gets/sets whether the card container has padding applied or not. */
        get: function () {
            return this._hasPadding;
        },
        set: function (value) {
            if (this._hasPadding !== value) {
                this._hasPadding = value;
                if (isDefined(this._hasPadding)) {
                    this.setAttribute(CARD_CONSTANTS.attributes.HAS_PADDING, this._hasPadding.toString());
                }
                else {
                    this._hasPadding = false;
                    this.removeAttribute(CARD_CONSTANTS.attributes.HAS_PADDING);
                }
                this._setPaddingClass();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CardComponent.prototype, "outlined", {
        /** Gets/sets whether the card is using the outlined style or not. */
        get: function () {
            return this._outlined;
        },
        set: function (value) {
            if (this._outlined !== value) {
                this._outlined = value;
                if (isDefined(this._outlined)) {
                    this.setAttribute(CARD_CONSTANTS.attributes.OUTLINED, this._outlined.toString());
                }
                else {
                    this._outlined = false;
                    this.removeAttribute(CARD_CONSTANTS.attributes.OUTLINED);
                }
                this._setOutlinedClass();
            }
        },
        enumerable: true,
        configurable: true
    });
    CardComponent = __decorate([
        CustomElement({
            name: CARD_CONSTANTS.elementName
        })
    ], CardComponent);
    return CardComponent;
}(HTMLElement));
export { CardComponent };
