var OmnibarMenuButtonFoundation = /** @class */ (function () {
    function OmnibarMenuButtonFoundation(_adapter) {
        this._adapter = _adapter;
    }
    OmnibarMenuButtonFoundation.prototype.initialize = function () {
    };
    OmnibarMenuButtonFoundation.prototype.connect = function () {
    };
    return OmnibarMenuButtonFoundation;
}());
export { OmnibarMenuButtonFoundation };
