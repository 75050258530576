import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "avatar";
var attributes = {
    IMAGE_URL: 'image-url',
    TEXT: 'text',
    LETTER_COUNT: 'letter-count',
    AUTO_COLOR: 'auto-color'
};
var numbers = {
    DEFAULT_LETTER_COUNT: 2
};
var selectors = {
    ROOT: '.tyl-avatar',
    DEFAULT_SLOT: '.tyl-avatar > slot:not([name])'
};
export var AVATAR_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    numbers: numbers,
    selectors: selectors
};
