import { __decorate, __extends } from "tslib";
import { MDCRipple } from '@material/ripple';
import { CustomElement, ensureChildren } from '@tyler-components-web/core';
import { BUTTON_CONSTANTS } from './button-constants';
/**
 * The custom element class behind the `<tcw-button>` element.
 */
var ButtonComponent = /** @class */ (function (_super) {
    __extends(ButtonComponent, _super);
    function ButtonComponent() {
        return _super.call(this) || this;
    }
    Object.defineProperty(ButtonComponent, "observedAttributes", {
        get: function () {
            return [
                BUTTON_CONSTANTS.attributes.TYPE
            ];
        },
        enumerable: true,
        configurable: true
    });
    ButtonComponent.prototype._applyType = function (type) {
        if (this._buttonElement) {
            this._buttonElement.classList.remove(BUTTON_CONSTANTS.classes.BUTTON_RAISED);
            this._buttonElement.classList.remove(BUTTON_CONSTANTS.classes.BUTTON_UNELEVATED);
            this._buttonElement.classList.remove(BUTTON_CONSTANTS.classes.BUTTON_OUTLINED);
            this._buttonElement.classList.remove(BUTTON_CONSTANTS.classes.BUTTON_DENSE);
            if (type.includes('raised')) {
                this._buttonElement.classList.add(BUTTON_CONSTANTS.classes.BUTTON_RAISED);
            }
            if (type.includes('unelevated')) {
                this._buttonElement.classList.add(BUTTON_CONSTANTS.classes.BUTTON_UNELEVATED);
            }
            if (type.includes('outlined')) {
                this._buttonElement.classList.add(BUTTON_CONSTANTS.classes.BUTTON_OUTLINED);
            }
            if (type.includes('dense')) {
                this._buttonElement.classList.add(BUTTON_CONSTANTS.classes.BUTTON_DENSE);
            }
        }
    };
    ButtonComponent.prototype._initialize = function () {
        this._buttonElement = this.querySelector(BUTTON_CONSTANTS.selectors.BUTTON);
        if (!this._buttonElement) {
            return;
        }
        if (this.hasAttribute(BUTTON_CONSTANTS.attributes.TYPE)) {
            this._type = this.getAttribute(BUTTON_CONSTANTS.attributes.TYPE);
            this._applyType(this._type);
        }
        this._buttonElement.classList.add(BUTTON_CONSTANTS.classes.BUTTON);
        var labelElement = this.querySelector(BUTTON_CONSTANTS.selectors.LABEL);
        if (labelElement) {
            labelElement.classList.add(BUTTON_CONSTANTS.classes.LABEL);
        }
        var iconElements = Array.from(this.querySelectorAll(BUTTON_CONSTANTS.selectors.ICON));
        iconElements.forEach(function (iconElement) {
            iconElement.classList.add(BUTTON_CONSTANTS.classes.ICON);
            iconElement.setAttribute('aria-hidden', 'true');
        });
        this.mdcRipple = new MDCRipple(this._buttonElement);
    };
    ButtonComponent.prototype.initializedCallback = function () {
        var _this = this;
        if (this.children.length) {
            this._initialize();
        }
        else {
            ensureChildren(this).then(function () { return _this._initialize(); });
        }
    };
    ButtonComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case BUTTON_CONSTANTS.attributes.TYPE:
                this.type = newValue;
                break;
        }
    };
    ButtonComponent.prototype.disconnectedCallback = function () {
        if (this.mdcRipple) {
            this.mdcRipple.destroy();
        }
    };
    Object.defineProperty(ButtonComponent.prototype, "type", {
        get: function () {
            return this._type;
        },
        /**
         * Sets the type of button decoration.
         * Possbile values are: raised, elevated, outlined, and dense.
         * Can be combined as: outlined-dense.
         */
        set: function (value) {
            if (this._type !== value) {
                this._type = value;
                this._applyType(value);
                this.setAttribute(BUTTON_CONSTANTS.attributes.TYPE, this._type);
            }
        },
        enumerable: true,
        configurable: true
    });
    ButtonComponent = __decorate([
        CustomElement({
            name: BUTTON_CONSTANTS.elementName
        })
    ], ButtonComponent);
    return ButtonComponent;
}(HTMLElement));
export { ButtonComponent };
