import { __decorate, __extends } from "tslib";
import { MDCSlider } from '@material/slider';
import { CustomElement, coerceBoolean, coerceNumber, emitEvent, attachShadowTemplate, getShadowElement, isDefined, upgradeProperty } from '@tyler-components-web/core';
import { SLIDER_CONSTANTS } from './slider-constants';
var continuousTemplate = require('./slider-continuous.html');
var discreteTemplate = require('./slider-discrete.html');
var styles = require('./slider.css');
/**
 * The custom element class behind the `<tcw-slider>` component.
 */
var SliderComponent = /** @class */ (function (_super) {
    __extends(SliderComponent, _super);
    function SliderComponent() {
        var _this = _super.call(this) || this;
        _this._type = 'continuous';
        _this._value = 0;
        _this._min = 0;
        _this._max = 100;
        _this._step = 0;
        _this._disabled = false;
        return _this;
    }
    Object.defineProperty(SliderComponent, "observedAttributes", {
        get: function () {
            return [
                SLIDER_CONSTANTS.attributes.TYPE,
                SLIDER_CONSTANTS.attributes.VALUE,
                SLIDER_CONSTANTS.attributes.MAX,
                SLIDER_CONSTANTS.attributes.MIN,
                SLIDER_CONSTANTS.attributes.STEP,
                SLIDER_CONSTANTS.attributes.DISABLED
            ];
        },
        enumerable: true,
        configurable: true
    });
    SliderComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'type');
        upgradeProperty(this, 'value');
        upgradeProperty(this, 'min');
        upgradeProperty(this, 'max');
        upgradeProperty(this, 'step');
        upgradeProperty(this, 'disabled');
        this._initialize();
    };
    SliderComponent.prototype.disconnectedCallback = function () {
        if (this._mdcSlider) {
            this._mdcSlider.destroy();
        }
    };
    SliderComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case SLIDER_CONSTANTS.attributes.TYPE:
                this.type = newValue;
                break;
            case SLIDER_CONSTANTS.attributes.VALUE:
                this.value = coerceNumber(newValue);
                break;
            case SLIDER_CONSTANTS.attributes.MIN:
                this.min = coerceNumber(newValue);
                break;
            case SLIDER_CONSTANTS.attributes.MAX:
                this.max = coerceNumber(newValue);
                break;
            case SLIDER_CONSTANTS.attributes.STEP:
                this.step = coerceNumber(newValue);
                break;
            case SLIDER_CONSTANTS.attributes.DISABLED:
                this.disabled = coerceBoolean(newValue);
                break;
        }
    };
    SliderComponent.prototype._initialize = function () {
        var _this = this;
        switch (this._type) {
            case 'continuous':
                attachShadowTemplate(this, SLIDER_CONSTANTS.elementName, continuousTemplate, styles);
                break;
            case 'discrete':
            case 'discrete-markers':
                attachShadowTemplate(this, SLIDER_CONSTANTS.elementName, discreteTemplate, styles);
                break;
        }
        var rootElement = getShadowElement(this, SLIDER_CONSTANTS.selectors.ROOT);
        if (this._type === 'discrete-markers') {
            rootElement.classList.add(SLIDER_CONSTANTS.classes.DISPLAY_MARKERS);
            var trackContainer = getShadowElement(this, SLIDER_CONSTANTS.selectors.TRACK_CONTAINER);
            if (trackContainer) {
                var trackMarkerContainerElement = document.createElement('div');
                trackMarkerContainerElement.classList.add(SLIDER_CONSTANTS.classes.TRACK_MARKER_CONTAINER);
                trackContainer.appendChild(trackMarkerContainerElement);
            }
        }
        // TODO: Figure out why we need to wait a frame for the MDC slider to render properly
        window.requestAnimationFrame(function () {
            _this._mdcSlider = new MDCSlider(rootElement);
            _this._mdcSlider.initialize();
            rootElement.addEventListener(SLIDER_CONSTANTS.events.MDC_INPUT, function (evt) {
                _this._mdcSlider.layout(); // TIN-399 fixes pointer not syncing with slider handle after resize
                // TODO - This should be replaced with the ResizeObserver once it is broadly available:
                //        https://developers.google.com/web/updates/2016/10/resizeobserver
                //        https://caniuse.com/#feat=resizeobserver
                evt.stopPropagation();
                _this._value = _this._mdcSlider.value;
                _this.setAttribute(SLIDER_CONSTANTS.attributes.VALUE, _this._value.toString());
                emitEvent(_this, SLIDER_CONSTANTS.events.TCW_INPUT, { value: _this._value });
            });
            rootElement.addEventListener(SLIDER_CONSTANTS.events.MDC_CHANGE, function (evt) {
                evt.stopPropagation();
                _this._value = _this._mdcSlider.value;
                _this.setAttribute(SLIDER_CONSTANTS.attributes.VALUE, _this._value.toString());
                emitEvent(_this, SLIDER_CONSTANTS.events.TCW_CHANGE, { value: _this._value });
            });
            _this._sync();
        });
    };
    SliderComponent.prototype._sync = function () {
        this._mdcSlider.value = this._value;
        this._mdcSlider.min = this._min;
        this._mdcSlider.max = this._max;
        this._mdcSlider.step = this._step;
        this._mdcSlider.disabled = this._disabled;
        this.setAttribute(SLIDER_CONSTANTS.attributes.VALUE, this._value.toString());
    };
    Object.defineProperty(SliderComponent.prototype, "type", {
        /**
         * Gets/sets the type of slider this is: `continuous` (default), `discrete`, `discrete-markers`
         */
        get: function () {
            return this._type;
        },
        set: function (value) {
            this._type = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SliderComponent.prototype, "value", {
        /**
         * Gets/sets the value of the slider.
         */
        get: function () {
            return this._value;
        },
        set: function (value) {
            if (this._value !== value) {
                this._value = value;
                if (this._mdcSlider) {
                    this._mdcSlider.value = value;
                }
                this.setAttribute(SLIDER_CONSTANTS.attributes.VALUE, isDefined(this._value) ? this._value.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SliderComponent.prototype, "min", {
        /**
         * Gets/sets the minimum value for the slider.
         */
        get: function () {
            return this._min;
        },
        set: function (value) {
            if (this._min !== value) {
                this._min = value;
                if (this._mdcSlider) {
                    this._mdcSlider.min = value;
                }
                this.setAttribute(SLIDER_CONSTANTS.attributes.MIN, isDefined(this._min) ? this._min.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SliderComponent.prototype, "max", {
        /**
         * Gets/sets the maximum value for the slider.
         */
        get: function () {
            return this._max;
        },
        set: function (value) {
            if (this._max !== value) {
                this._max = value;
                if (this._mdcSlider) {
                    this._mdcSlider.max = value;
                }
                this.setAttribute(SLIDER_CONSTANTS.attributes.MAX, isDefined(this._max) ? this._max.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SliderComponent.prototype, "step", {
        /**
         * Gets/sets the step value for the slider when in discrete mode.
         */
        get: function () {
            return this._step;
        },
        set: function (value) {
            if (this._step !== value) {
                this._step = value;
                if (this._mdcSlider) {
                    this._mdcSlider.step = value;
                }
                this.setAttribute(SLIDER_CONSTANTS.attributes.STEP, isDefined(this._step) ? this._step.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SliderComponent.prototype, "disabled", {
        /**
         * Gets/sets the disabled state of the slider.
         */
        get: function () {
            return this._disabled;
        },
        set: function (value) {
            if (this._disabled !== value) {
                this._disabled = value;
                if (this._mdcSlider) {
                    this._mdcSlider.disabled = value;
                }
                this.setAttribute(SLIDER_CONSTANTS.attributes.DISABLED, isDefined(this._disabled) ? this._disabled.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    SliderComponent = __decorate([
        CustomElement({
            name: SLIDER_CONSTANTS.elementName
        })
    ], SliderComponent);
    return SliderComponent;
}(HTMLElement));
export { SliderComponent };
