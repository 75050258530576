import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "view-switcher";
var classes = {
    ROOT: 'tyl-view-switcher',
    VIEW_HIDDEN: 'tyl-view-switcher__view--hidden',
    VIEW_SWITCHER_SLIDE: 'tyl-view-switcher--slide',
    VIEW_SWITCHER_FADE: 'tyl-view-switcher--fade'
};
var selectors = {
    ROOT: "." + classes.ROOT,
    DEFAULT_SLOT: 'slot:not([name])',
    VIEW_HIDDEN: "." + classes.VIEW_HIDDEN
};
var attributes = {
    INDEX: 'index',
    ANIMATION_TYPE: 'animation-type'
};
export var VIEW_SWITCHER_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    classes: classes,
    selectors: selectors
};
export var ViewAnimationDirection;
(function (ViewAnimationDirection) {
    ViewAnimationDirection["Left"] = "left";
    ViewAnimationDirection["Right"] = "right";
})(ViewAnimationDirection || (ViewAnimationDirection = {}));
export var ViewSwitcherAnimationType;
(function (ViewSwitcherAnimationType) {
    ViewSwitcherAnimationType["None"] = "none";
    ViewSwitcherAnimationType["Slide"] = "slide";
    ViewSwitcherAnimationType["Fade"] = "fade";
})(ViewSwitcherAnimationType || (ViewSwitcherAnimationType = {}));
