/**
 * Represents a row within the table.
 */
var TableRow = /** @class */ (function () {
    function TableRow(data, selected) {
        if (selected === void 0) { selected = false; }
        this.data = data;
        this.selected = selected;
    }
    return TableRow;
}());
export { TableRow };
