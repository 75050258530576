import { COMPONENT_NAME_PREFIX } from '../../constants';
var elementName = COMPONENT_NAME_PREFIX + "omnibar-search";
var classes = {
    FOCUSED: 'tyl-omnibar-search--focused'
};
var selectors = {
    ROOT: '.tyl-omnibar-search',
    INPUT: 'input',
    GLOBAL_ICON_CONTAINER: '.tyl-omnibar-search__global-icon-container',
    CONTEXT_CONTAINER: '.tyl-omnibar-search__context',
};
var attributes = {
    DISABLED: 'disabled',
    VALUE: 'value',
    PLACEHOLDER: 'placeholder',
    COMBINED: 'combined',
    GLOBAL: 'global',
};
var events = {
    SEARCH: elementName + "-search"
};
var strings = {
    DEFAULT_CONTEXT: 'global'
};
export var OMNIBAR_SEARCH_CONSTANTS = {
    elementName: elementName,
    classes: classes,
    selectors: selectors,
    attributes: attributes,
    events: events,
    strings: strings
};
export var DEFAULT_COMBINED_OPTIONED = [
    { label: 'in this application', value: 'local' },
    { label: 'in all Tyler products', value: strings.DEFAULT_CONTEXT }
];
