import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty, coerceNumber } from '@tyler-components-web/core';
import { ViewSwitcherAdapter } from './view-switcher-adapter';
import { ViewSwitcherFoundation } from './view-switcher-foundation';
import { VIEW_SWITCHER_CONSTANTS } from './view-switcher-constants';
import { ViewComponent } from './view/view';
var template = require('./view-switcher.html');
var styles = require('./view-switcher.css');
var ViewSwitcherComponent = /** @class */ (function (_super) {
    __extends(ViewSwitcherComponent, _super);
    function ViewSwitcherComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, VIEW_SWITCHER_CONSTANTS.elementName, template, styles);
        _this._foundation = new ViewSwitcherFoundation(new ViewSwitcherAdapter(_this));
        return _this;
    }
    Object.defineProperty(ViewSwitcherComponent, "observedAttributes", {
        get: function () {
            return [
                VIEW_SWITCHER_CONSTANTS.attributes.INDEX,
                VIEW_SWITCHER_CONSTANTS.attributes.ANIMATION_TYPE
            ];
        },
        enumerable: true,
        configurable: true
    });
    ViewSwitcherComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'index');
        upgradeProperty(this, 'animationType');
        this._foundation.initialize();
    };
    ViewSwitcherComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case VIEW_SWITCHER_CONSTANTS.attributes.INDEX:
                this.index = coerceNumber(newValue);
                break;
            case VIEW_SWITCHER_CONSTANTS.attributes.ANIMATION_TYPE:
                this.animationType = newValue;
                break;
        }
    };
    /**
     * Transitions to the next view.
     */
    ViewSwitcherComponent.prototype.next = function () {
        this._foundation.next();
    };
    /**
     * Transitions to the previous view.
     */
    ViewSwitcherComponent.prototype.previous = function () {
        this._foundation.previous();
    };
    /**
     * Transitions to the first view.
     */
    ViewSwitcherComponent.prototype.goToStart = function () {
        this._foundation.goToStart();
    };
    /**
     * Transitions to the last view.
     */
    ViewSwitcherComponent.prototype.goToEnd = function () {
        this._foundation.goToEnd();
    };
    Object.defineProperty(ViewSwitcherComponent.prototype, "index", {
        /**
         * Gets/sets the currently visible view index.
         */
        get: function () {
            return this._foundation.index;
        },
        set: function (value) {
            this._foundation.index = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewSwitcherComponent.prototype, "animationType", {
        /**
         * Gets/sets the animation type.
         */
        get: function () {
            return this._foundation.animationType;
        },
        set: function (value) {
            this._foundation.animationType = value;
        },
        enumerable: true,
        configurable: true
    });
    ViewSwitcherComponent = __decorate([
        CustomElement({
            name: VIEW_SWITCHER_CONSTANTS.elementName,
            dependencies: [ViewComponent]
        })
    ], ViewSwitcherComponent);
    return ViewSwitcherComponent;
}(HTMLElement));
export { ViewSwitcherComponent };
