import { getShadowElement, emitEvent } from '@tyler-components-web/core';
import { COLOR_PICKER_CONSTANTS, ColorPickerValueType } from './color-picker-constants';
var ColorPickerAdapter = /** @class */ (function () {
    function ColorPickerAdapter(_component) {
        this._component = _component;
        this._gradientElement = getShadowElement(_component, COLOR_PICKER_CONSTANTS.selectors.GRADIENT);
        this._previewColorElement = getShadowElement(_component, COLOR_PICKER_CONSTANTS.selectors.PREVIEW_COLOR);
        this._hexInputElement = getShadowElement(_component, COLOR_PICKER_CONSTANTS.selectors.HEX_INPUT);
        this._rgbaInputRElement = getShadowElement(_component, COLOR_PICKER_CONSTANTS.selectors.COLOR_VALUE_RGBA_R);
        this._rgbaInputGElement = getShadowElement(_component, COLOR_PICKER_CONSTANTS.selectors.COLOR_VALUE_RGBA_G);
        this._rgbaInputBElement = getShadowElement(_component, COLOR_PICKER_CONSTANTS.selectors.COLOR_VALUE_RGBA_B);
        this._rgbaInputAElement = getShadowElement(_component, COLOR_PICKER_CONSTANTS.selectors.COLOR_VALUE_RGBA_A);
        this._hsvaInputHElement = getShadowElement(_component, COLOR_PICKER_CONSTANTS.selectors.COLOR_VALUE_HSVA_H);
        this._hsvaInputSElement = getShadowElement(_component, COLOR_PICKER_CONSTANTS.selectors.COLOR_VALUE_HSVA_S);
        this._hsvaInputVElement = getShadowElement(_component, COLOR_PICKER_CONSTANTS.selectors.COLOR_VALUE_HSVA_V);
        this._hsvaInputAElement = getShadowElement(_component, COLOR_PICKER_CONSTANTS.selectors.COLOR_VALUE_HSVA_A);
        this._hueSliderElement = getShadowElement(_component, COLOR_PICKER_CONSTANTS.selectors.HUE_SLIDER);
        this._hueSliderThumbElement = getShadowElement(_component, COLOR_PICKER_CONSTANTS.selectors.HUE_SLIDER_THUMB);
        this._opacitySliderElement = getShadowElement(_component, COLOR_PICKER_CONSTANTS.selectors.OPACITY_SLIDER);
        this._opacitySliderThumbElement = getShadowElement(_component, COLOR_PICKER_CONSTANTS.selectors.OPACITY_SLIDER_THUMB);
        this._hexValueContainerElement = getShadowElement(_component, COLOR_PICKER_CONSTANTS.selectors.COLOR_VALUE_HEX_CONTAINER);
        this._rgbaValueContainerElement = getShadowElement(_component, COLOR_PICKER_CONSTANTS.selectors.COLOR_VALUE_RGBA_CONTAINER);
        this._hsvaValueContainerElement = getShadowElement(_component, COLOR_PICKER_CONSTANTS.selectors.COLOR_VALUE_HSVA_CONTAINER);
        this._typeButtonElement = getShadowElement(_component, COLOR_PICKER_CONSTANTS.selectors.TYPE_BUTTON);
    }
    ColorPickerAdapter.prototype.setHostAttribute = function (name, value) {
        this._component.setAttribute(name, value);
    };
    ColorPickerAdapter.prototype.setPreviewColor = function (rgbaStyle) {
        this._previewColorElement.style.backgroundColor = rgbaStyle;
    };
    ColorPickerAdapter.prototype.setHexInputValue = function (value) {
        this._hexInputElement.value = value;
    };
    ColorPickerAdapter.prototype.setRgbaInputValue = function (rgba) {
        this._rgbaInputRElement.value = rgba.r.toString();
        this._rgbaInputGElement.value = rgba.g.toString();
        this._rgbaInputBElement.value = rgba.b.toString();
        this._rgbaInputAElement.value = rgba.a.toString();
    };
    ColorPickerAdapter.prototype.setHsvaInputValue = function (hsva) {
        this._hsvaInputHElement.value = hsva.h.toString();
        this._hsvaInputSElement.value = hsva.s.toString();
        this._hsvaInputVElement.value = hsva.v.toString();
        this._hsvaInputAElement.value = hsva.a.toString();
    };
    ColorPickerAdapter.prototype.updateA11y = function (hueValue, opacityValue) {
        this._hueSliderThumbElement.setAttribute('aria-valuenow', hueValue.toString());
        this._hueSliderThumbElement.setAttribute('aria-valuetext', hueValue.toString());
        this._opacitySliderThumbElement.setAttribute('aria-valuenow', opacityValue.toString());
        this._opacitySliderThumbElement.setAttribute('aria-valuetext', opacityValue.toString());
    };
    ColorPickerAdapter.prototype.setGradientColor = function (value) {
        this._gradientElement.style.backgroundColor = value;
    };
    ColorPickerAdapter.prototype.getHueSliderElement = function () {
        return this._hueSliderElement;
    };
    ColorPickerAdapter.prototype.getOpacitySliderElement = function () {
        return this._opacitySliderElement;
    };
    ColorPickerAdapter.prototype.getGradientElement = function () {
        return this._gradientElement;
    };
    ColorPickerAdapter.prototype.setActiveValueType = function (type) {
        this._hexValueContainerElement.style.display = 'none';
        this._rgbaValueContainerElement.style.display = 'none';
        this._hsvaValueContainerElement.style.display = 'none';
        switch (type) {
            case ColorPickerValueType.HEX:
                this._hexValueContainerElement.style.removeProperty('display');
                break;
            case ColorPickerValueType.RGBA:
                this._rgbaValueContainerElement.style.removeProperty('display');
                break;
            case ColorPickerValueType.HSVA:
                this._hsvaValueContainerElement.style.removeProperty('display');
                break;
        }
    };
    ColorPickerAdapter.prototype.focusValueInput = function (type) {
        switch (type) {
            case ColorPickerValueType.HEX:
                this._hexInputElement.focus();
                this._hexInputElement.select();
                break;
            case ColorPickerValueType.RGBA:
                this._rgbaInputRElement.focus();
                this._rgbaInputRElement.select();
                break;
            case ColorPickerValueType.HSVA:
                this._hsvaInputHElement.focus();
                this._hsvaInputHElement.select();
                break;
        }
    };
    ColorPickerAdapter.prototype.setTypeClickListener = function (listener) {
        this._typeButtonElement.addEventListener('click', listener);
    };
    ColorPickerAdapter.prototype.removeTypeClickListener = function (listener) {
        this._typeButtonElement.removeEventListener('click', listener);
    };
    ColorPickerAdapter.prototype.setHexInputListener = function (type, listener) {
        this._hexInputElement.addEventListener(type, listener);
    };
    ColorPickerAdapter.prototype.removeHexInputListener = function (type, listener) {
        this._hexInputElement.removeEventListener(type, listener);
    };
    ColorPickerAdapter.prototype.getHexInputValue = function () {
        return this._hexInputElement.value;
    };
    ColorPickerAdapter.prototype.setRgbaInputListener = function (type, listener) {
        this._rgbaInputRElement.addEventListener(type, listener);
        this._rgbaInputGElement.addEventListener(type, listener);
        this._rgbaInputBElement.addEventListener(type, listener);
        this._rgbaInputAElement.addEventListener(type, listener);
    };
    ColorPickerAdapter.prototype.removeRgbaInputListener = function (type, listener) {
        this._rgbaInputRElement.removeEventListener(type, listener);
        this._rgbaInputGElement.removeEventListener(type, listener);
        this._rgbaInputBElement.removeEventListener(type, listener);
        this._rgbaInputAElement.removeEventListener(type, listener);
    };
    ColorPickerAdapter.prototype.getRgbaInputValue = function () {
        return {
            r: parseInt(this._rgbaInputRElement.value, 10),
            g: parseInt(this._rgbaInputGElement.value, 10),
            b: parseInt(this._rgbaInputBElement.value, 10),
            a: parseFloat(this._rgbaInputAElement.value),
        };
    };
    ColorPickerAdapter.prototype.setHsvaInputListener = function (type, listener) {
        this._hsvaInputHElement.addEventListener(type, listener);
        this._hsvaInputSElement.addEventListener(type, listener);
        this._hsvaInputVElement.addEventListener(type, listener);
        this._hsvaInputAElement.addEventListener(type, listener);
    };
    ColorPickerAdapter.prototype.removeHsvaInputListener = function (type, listener) {
        this._hsvaInputHElement.removeEventListener(type, listener);
        this._hsvaInputSElement.removeEventListener(type, listener);
        this._hsvaInputVElement.removeEventListener(type, listener);
        this._hsvaInputAElement.removeEventListener(type, listener);
    };
    ColorPickerAdapter.prototype.getHsvaInputValue = function () {
        return {
            h: parseInt(this._hsvaInputHElement.value, 10),
            s: parseInt(this._hsvaInputSElement.value, 10),
            v: parseInt(this._hsvaInputVElement.value, 10),
            a: parseFloat(this._hsvaInputAElement.value),
        };
    };
    ColorPickerAdapter.prototype.toggleOpacityControls = function (visible) {
        if (visible) {
            this._opacitySliderElement.style.removeProperty('display');
            this._rgbaInputAElement.parentElement.style.removeProperty('display');
            this._hsvaInputAElement.parentElement.style.removeProperty('display');
        }
        else {
            this._opacitySliderElement.style.display = 'none';
            this._rgbaInputAElement.parentElement.style.display = 'none';
            this._hsvaInputAElement.parentElement.style.display = 'none';
        }
    };
    ColorPickerAdapter.prototype.emitEvent = function (type, data) {
        emitEvent(this._component, type, data, true);
    };
    return ColorPickerAdapter;
}());
export { ColorPickerAdapter };
