import { isDefined } from '@tyler-components-web/core';
var AbstractComponentDelegate = /** @class */ (function () {
    function AbstractComponentDelegate(_config) {
        this._config = _config;
        this._initialize();
    }
    AbstractComponentDelegate.prototype.destroy = function () { };
    AbstractComponentDelegate.prototype.onFocus = function (listener) { };
    AbstractComponentDelegate.prototype.onBlur = function (listener) { };
    AbstractComponentDelegate.prototype.setDisabled = function (value) { };
    AbstractComponentDelegate.prototype.setInvalid = function (value) { };
    Object.defineProperty(AbstractComponentDelegate.prototype, "component", {
        get: function () {
            return this._component;
        },
        enumerable: true,
        configurable: true
    });
    AbstractComponentDelegate.prototype.validate = function () {
        return true;
    };
    AbstractComponentDelegate.prototype._initialize = function () {
        this._component = this.build();
        if (!this._component) {
            throw new Error("Invalid component instance: " + this._component);
        }
        this.configure();
        if (isDefined(this._config.style)) {
            Object.assign(this._component.style, this._config.style);
        }
        if (isDefined(this._config.value)) {
            this.setValue(this._config.value);
        }
    };
    return AbstractComponentDelegate;
}());
export { AbstractComponentDelegate };
