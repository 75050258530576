import { OMNIBAR_NOTIFICATION_BUTTON_CONSTANTS } from './omnibar-notification-button-constants';
var OmnibarNotificationButtonFoundation = /** @class */ (function () {
    function OmnibarNotificationButtonFoundation(_adapter) {
        this._adapter = _adapter;
        this._count = 0;
        this._dot = false;
        this._theme = 'secondary';
        this._showBadge = false;
        this._isInitialized = false;
    }
    OmnibarNotificationButtonFoundation.prototype.initialize = function () {
        this._adapter.initialize();
        this._adapter.setCount(this._count);
        this._adapter.setBadgeType(this._dot);
        this._adapter.setBadgeTheme(this._theme);
        this._adapter.setBadgeVisible(this._showBadge);
        this._isInitialized = true;
    };
    OmnibarNotificationButtonFoundation.prototype.disconnect = function () {
        this._isInitialized = false;
    };
    Object.defineProperty(OmnibarNotificationButtonFoundation.prototype, "count", {
        get: function () {
            return this._count;
        },
        set: function (value) {
            if (this._count !== value) {
                this._count = value;
                if (!this._showBadge) {
                    this.showBadge = true;
                }
                if (this._isInitialized) {
                    this._adapter.setCount(this._count);
                    this._adapter.setHostAttribute(OMNIBAR_NOTIFICATION_BUTTON_CONSTANTS.attributes.COUNT, this._count);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarNotificationButtonFoundation.prototype, "dot", {
        get: function () {
            return this._dot;
        },
        set: function (value) {
            if (this._dot !== value) {
                this._dot = value;
                if (this._isInitialized) {
                    this._adapter.setBadgeType(this._dot);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarNotificationButtonFoundation.prototype, "theme", {
        get: function () {
            return this._theme;
        },
        set: function (value) {
            if (this._theme !== value) {
                this._theme = value;
                if (this._isInitialized) {
                    this._adapter.setBadgeTheme(this._theme);
                    this._adapter.setHostAttribute(OMNIBAR_NOTIFICATION_BUTTON_CONSTANTS.attributes.THEME, this._theme);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarNotificationButtonFoundation.prototype, "showBadge", {
        get: function () {
            return this._showBadge;
        },
        set: function (value) {
            if (this._showBadge !== value) {
                this._showBadge = value;
                if (this._isInitialized) {
                    this._adapter.setBadgeVisible(this._showBadge);
                    this._adapter.setHostAttribute(OMNIBAR_NOTIFICATION_BUTTON_CONSTANTS.attributes.SHOW_BADGE, this._showBadge);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    return OmnibarNotificationButtonFoundation;
}());
export { OmnibarNotificationButtonFoundation };
