import { COMPONENT_NAME_PREFIX } from '../../constants';
var elementName = COMPONENT_NAME_PREFIX + "button-toggle-group";
var attributes = {
    VALUE: 'value',
    MULTIPLE: 'multiple',
    MANDATORY: 'mandatory',
    VERTICAL: 'vertical',
    STRETCH: 'stretch',
    DISABLED: 'disabled',
    DENSE: 'dense'
};
var classes = {
    VERTICAL: 'tyl-button-toggle-group--vertical',
    STRETCH: 'tyl-button-toggle-group--stretch'
};
var selectors = {
    ROOT: '.tyl-button-toggle-group'
};
export var BUTTON_TOGGLE_GROUP_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    classes: classes,
    selectors: selectors
};
