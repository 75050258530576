import { isFunction } from '@tyler-components-web/core';
var OmnibarAppLauncherButtonFoundation = /** @class */ (function () {
    function OmnibarAppLauncherButtonFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._isOpen = false;
        this._clickListener = function (evt) { return _this._onClick(evt); };
        this._dismissListener = function () { return _this._onDismiss(); };
        this._keydownListener = function (evt) { return _this._onKeydown(evt); };
    }
    OmnibarAppLauncherButtonFoundation.prototype.initialize = function () {
        this._adapter.initialize();
        this._adapter.setClickListener(this._clickListener);
    };
    OmnibarAppLauncherButtonFoundation.prototype.disconnect = function () {
        if (this._isOpen) {
            this._close();
        }
        this._adapter.removeWindowListener('keydown', this._keydownListener);
        this._adapter.removeClickListener(this._clickListener);
    };
    OmnibarAppLauncherButtonFoundation.prototype.focusFirst = function () {
        if (this._isOpen) {
            this._adapter.setAppLauncherFocus();
        }
    };
    OmnibarAppLauncherButtonFoundation.prototype._onClick = function (evt) {
        if (!this._isOpen) {
            this._open();
        }
        else {
            this._close();
        }
    };
    OmnibarAppLauncherButtonFoundation.prototype._onKeydown = function (evt) {
        if (evt.key === 'Escape' || evt.keyCode === 27) {
            this._close();
        }
        else if (evt.key === 'Tab' || evt.keyCode === 9) {
            evt.preventDefault();
            this._adapter.removeWindowListener('keydown', this._keydownListener);
            this._adapter.setAppLauncherFocus();
        }
    };
    OmnibarAppLauncherButtonFoundation.prototype._onDismiss = function () {
        this._close();
    };
    OmnibarAppLauncherButtonFoundation.prototype._open = function () {
        if (isFunction(this._cancelDismissListener)) {
            this._cancelDismissListener();
        }
        var appLauncherConfig = {
            optionsCallback: this._optionsCallback
        };
        this._cancelDismissListener = this._adapter.open(appLauncherConfig, this._dismissListener);
        this._adapter.addWindowListener('keydown', this._keydownListener);
        this._isOpen = true;
    };
    OmnibarAppLauncherButtonFoundation.prototype._close = function () {
        if (isFunction(this._cancelDismissListener)) {
            this._cancelDismissListener();
        }
        this._adapter.removeWindowListener('keydown', this._keydownListener);
        this._adapter.close();
        this._adapter.requestFocus();
        this._isOpen = false;
    };
    Object.defineProperty(OmnibarAppLauncherButtonFoundation.prototype, "optionsCallback", {
        set: function (cb) {
            this._optionsCallback = cb;
        },
        enumerable: true,
        configurable: true
    });
    return OmnibarAppLauncherButtonFoundation;
}());
export { OmnibarAppLauncherButtonFoundation };
