import { getShadowElement, emitEvent } from '@tyler-components-web/core';
import { MDCRipple } from '@material/ripple';
import { BUTTON_TOGGLE_CONSTANTS } from './button-toggle-constants';
var ButtonToggleAdapter = /** @class */ (function () {
    function ButtonToggleAdapter(_component) {
        this._component = _component;
        this._buttonElement = getShadowElement(_component, BUTTON_TOGGLE_CONSTANTS.selectors.BUTTON);
    }
    ButtonToggleAdapter.prototype.setSelected = function (value) {
        if (value) {
            this._buttonElement.classList.add(BUTTON_TOGGLE_CONSTANTS.classes.SELECTED);
        }
        else {
            this._buttonElement.classList.remove(BUTTON_TOGGLE_CONSTANTS.classes.SELECTED);
        }
        this._buttonElement.setAttribute('aria-pressed', value.toString());
    };
    ButtonToggleAdapter.prototype.setHostAttribute = function (name, value) {
        if (value === void 0) { value = ''; }
        this._component.setAttribute(name, value);
    };
    ButtonToggleAdapter.prototype.removeHostAttribute = function (name) {
        this._component.removeAttribute(name);
    };
    ButtonToggleAdapter.prototype.emitEvent = function (type, data, bubble) {
        if (bubble === void 0) { bubble = true; }
        emitEvent(this._component, type, data, bubble);
    };
    ButtonToggleAdapter.prototype.addEventListener = function (type, listener) {
        this._buttonElement.addEventListener(type, listener);
    };
    ButtonToggleAdapter.prototype.removeEventListener = function (type, listener) {
        this._buttonElement.removeEventListener(type, listener);
    };
    ButtonToggleAdapter.prototype.initializeRipple = function () {
        return new MDCRipple(this._buttonElement);
    };
    ButtonToggleAdapter.prototype.setDisabled = function (value) {
        this._buttonElement.disabled = value;
        if (value) {
            this._buttonElement.setAttribute('aria-disabled', value.toString());
        }
        else {
            this._buttonElement.removeAttribute('aria-disabled');
        }
    };
    ButtonToggleAdapter.prototype.setDense = function (value) {
        if (value) {
            this._buttonElement.classList.add(BUTTON_TOGGLE_CONSTANTS.classes.DENSE);
        }
        else {
            this._buttonElement.classList.remove(BUTTON_TOGGLE_CONSTANTS.classes.DENSE);
        }
    };
    ButtonToggleAdapter.prototype.requestFocus = function () {
        this._buttonElement.focus();
    };
    return ButtonToggleAdapter;
}());
export { ButtonToggleAdapter };
