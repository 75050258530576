import { COMPONENT_NAME_PREFIX } from '../../constants';
var elementName = COMPONENT_NAME_PREFIX + "list-item";
var attributes = {
    STATIC: 'static',
    TWO_LINE: 'two-line',
    THREE_LINE: 'three-line',
    ACTIVE: 'active',
    SELECTED: 'selected',
    VALUE: 'value',
    HREF: 'href',
    TARGET: 'target',
    NO_RIPPLE: 'no-ripple',
    DISABLED: 'disabled',
    DENSE: 'dense',
    PROPAGATE_CLICK: 'propagate-click',
    INDENTED: 'indented',
    DRAWER_CONTEXT: 'tcw-drawer-context'
};
var classes = {
    LIST_ITEM: 'tyl-list-item',
    STATIC: 'tyl-list-item--static',
    TEXT: 'tyl-list-item__text',
    TWO_LINE: 'tyl-list-item--two-line',
    THREE_LINE: 'tyl-list-item--three-line',
    ACTIVE: 'tyl-list-item--active',
    SELECTED: 'tyl-list-item--selected',
    DISABLED: 'tyl-list-item--disabled',
    DENSE: 'tyl-list-item--dense',
    INDENTED: 'tyl-list-item--indented'
};
var selectors = {
    LIST_ITEM: "." + classes.LIST_ITEM,
    DEFAULT_SLOT: "." + classes.TEXT + " > slot",
    CHECKBOX_RADIO_SELECTOR: 'input[type=checkbox]:not(:disabled), input[type=radio]:not(:disabled)',
};
var events = {
    SELECTED: elementName + "-select"
};
var roles = {
    LINK: 'link',
    LIST_ITEM: 'listitem'
};
export var LIST_ITEM_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    classes: classes,
    selectors: selectors,
    events: events,
    roles: roles
};
