import { BUTTON_TOGGLE_CONSTANTS } from '../button-toggle/button-toggle-constants';
var ButtonToggleGroupFoundation = /** @class */ (function () {
    function ButtonToggleGroupFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._isInitialized = false;
        this._multiple = false;
        this._mandatory = false;
        this._vertical = false;
        this._stretch = false;
        this._dense = false;
        this._disabled = false;
        this._selectListener = function (evt) { return _this._onSelect(evt); };
    }
    ButtonToggleGroupFoundation.prototype.initialize = function () {
        if (this._options && this._options.length) {
            this._applyOptions(false);
        }
        this._adapter.addListener(BUTTON_TOGGLE_CONSTANTS.events.SELECT, this._selectListener);
        this._adapter.setVertical(this._vertical);
        this._adapter.setStretch(this._stretch);
        this._adapter.setDense(this._dense);
        this._adapter.setDisabled(this._disabled);
        this._adapter.applyAdjacentSelections(this._vertical);
        this._isInitialized = true;
    };
    ButtonToggleGroupFoundation.prototype.disconnect = function () {
        this._isInitialized = false;
        this._adapter.removeListener(BUTTON_TOGGLE_CONSTANTS.events.SELECT, this._selectListener);
    };
    ButtonToggleGroupFoundation.prototype._onSelect = function (evt) {
        evt.stopPropagation();
        var target = evt.target;
        // When in mandatory mode we need to ensure at least one element is selected. If there are no selections
        // then we need to reselect the target toggle because it was deselected
        if (this._mandatory) {
            var values = this._adapter.getSelectedValues();
            if (!values.length) {
                target.selected = true;
                return;
            }
        }
        // When not in multiple mode, we deselect all toggles, except for the one that was just changed
        if (!this._multiple) {
            this._adapter.deselect(target);
        }
        this._adapter.applyAdjacentSelections(this._vertical);
        this._adapter.emitEvent(evt.type, this._getValue());
    };
    ButtonToggleGroupFoundation.prototype._getValue = function () {
        var selections = this._adapter.getSelectedValues();
        return this._multiple ? Array.from(new Set(selections)) : selections.slice(0, 1)[0] || null;
    };
    ButtonToggleGroupFoundation.prototype._applyValue = function (value) {
        var values = value instanceof Array ? value : [value];
        if (!this._multiple && values.length > 1) {
            values = values[0];
        }
        this._adapter.applyValues(values);
    };
    ButtonToggleGroupFoundation.prototype._applyOptions = function (init) {
        if (init === void 0) { init = true; }
        if (this._options) {
            this._adapter.createOptions(this._options);
            if (init) {
                this._adapter.setStretch(this._stretch);
                this._adapter.setDense(this._dense);
                this._adapter.setDisabled(this._disabled);
                this._adapter.applyAdjacentSelections(this._vertical);
            }
        }
    };
    Object.defineProperty(ButtonToggleGroupFoundation.prototype, "value", {
        get: function () {
            return this._getValue();
        },
        set: function (value) {
            this._applyValue(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleGroupFoundation.prototype, "multiple", {
        get: function () {
            return this._multiple;
        },
        set: function (value) {
            if (this._multiple !== value) {
                this._multiple = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleGroupFoundation.prototype, "mandatory", {
        get: function () {
            return this._mandatory;
        },
        set: function (value) {
            if (this._mandatory !== value) {
                this._mandatory = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleGroupFoundation.prototype, "vertical", {
        get: function () {
            return this._vertical;
        },
        set: function (value) {
            if (this._vertical !== value) {
                this._vertical = value;
                this._adapter.setVertical(this._vertical);
                this._adapter.applyAdjacentSelections(this._vertical);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleGroupFoundation.prototype, "stretch", {
        get: function () {
            return this._stretch;
        },
        set: function (value) {
            if (this._stretch !== value) {
                this._stretch = value;
                this._adapter.setStretch(this._stretch);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleGroupFoundation.prototype, "dense", {
        get: function () {
            return this._dense;
        },
        set: function (value) {
            if (this._dense !== value) {
                this._dense = value;
                this._adapter.setDense(this._dense);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleGroupFoundation.prototype, "disabled", {
        get: function () {
            return this._disabled;
        },
        set: function (value) {
            if (this._disabled !== value) {
                this._disabled = value;
                this._adapter.setDisabled(this._disabled);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleGroupFoundation.prototype, "options", {
        get: function () {
            return this._options;
        },
        set: function (value) {
            this._options = value;
            if (this._isInitialized) {
                this._applyOptions();
            }
        },
        enumerable: true,
        configurable: true
    });
    return ButtonToggleGroupFoundation;
}());
export { ButtonToggleGroupFoundation };
