import { throttle, Platform, isDefined } from '@tyler-components-web/core';
import { TOOLTIP_CONSTANTS } from './tooltip-constants';
/**
 * The foundation class behind the tooltip component.
 */
var TooltipFoundation = /** @class */ (function () {
    function TooltipFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._delay = TOOLTIP_CONSTANTS.numbers.DEFAULT_DELAY;
        this._position = TOOLTIP_CONSTANTS.strings.DEFAULT_POSITION;
        this._isOpen = false;
        this._mouseOverListener = function (evt) { return _this._onMouseOver(evt); };
        this._mouseOutListener = function (evt) { return _this._onMouseOut(evt); };
        this._touchStartListener = function (evt) { return _this._onTouchStart(evt); };
        this._touchEndListener = function (evt) { return _this._onTouchEnd(evt); };
        this._scrollListener = throttle(function (evt) { return _this._onScroll(evt); }, 100);
        this._clickListener = function (evt) { return _this._onClick(evt); };
    }
    TooltipFoundation.prototype.initialize = function () {
        this._targetElement = this._adapter.getTargetElement(this._target);
        if (!this._targetElement) {
            throw new Error('Unable to locate target element.');
        }
        // Set a unique id for this tooltip (unless it already has one)
        this._identifier = this._adapter.getHostAttribute('id');
        if (!this._identifier) {
            this._identifier = this._getNextIdentifier();
            this._adapter.setHostAttribute('id', this._identifier);
        }
        this._addTargetListeners(this._targetElement);
        // For accessibility we need to associate the tooltip with the target element
        if (!this._targetElement.hasAttribute('aria-describedby')) {
            this._targetElement.setAttribute('aria-describedby', this._identifier);
        }
    };
    TooltipFoundation.prototype.disconnect = function () {
        if (this._mouseOverTimeout) {
            window.clearTimeout(this._mouseOverTimeout);
            this._mouseOverTimeout = undefined;
        }
        if (this._touchTimeout) {
            window.clearTimeout(this._touchTimeout);
            this._touchTimeout = undefined;
        }
        if (this._targetElement && this._targetElement.getAttribute('aria-describedby') === this._identifier) {
            this._targetElement.removeAttribute('aria-describedby');
        }
        if (this._isOpen) {
            this._hide();
        }
        if (this._targetElement) {
            this._removeTargetListeners(this._targetElement);
        }
    };
    /**
     * Generates the next available tooltip identifier.
     */
    TooltipFoundation.prototype._getNextIdentifier = function () {
        return "tcw-tooltip-" + TooltipFoundation._tooltipIdentifier++;
    };
    /**
     * Adds event listeners to the target element.
     * @param targetElement The target element instance.
     */
    TooltipFoundation.prototype._addTargetListeners = function (targetElement) {
        if (Platform.isMobile) {
            this._adapter.addTargetEventListener(targetElement, 'touchstart', this._touchStartListener);
            this._adapter.addTargetEventListener(targetElement, 'touchend', this._touchEndListener);
        }
        else {
            this._adapter.addTargetEventListener(targetElement, 'mouseover', this._mouseOverListener);
            this._adapter.addTargetEventListener(targetElement, 'mouseout', this._mouseOutListener);
        }
        this._adapter.addTargetEventListener(targetElement, 'click', this._clickListener);
    };
    /**
     * Removes the event listeners from the target element.
     * @param targetElement The target element instance.
     */
    TooltipFoundation.prototype._removeTargetListeners = function (targetElement) {
        if (Platform.isMobile) {
            this._adapter.removeTargetEventListener(targetElement, 'touchstart', this._touchStartListener);
            this._adapter.removeTargetEventListener(targetElement, 'touchend', this._touchEndListener);
        }
        else {
            this._adapter.removeTargetEventListener(targetElement, 'mouseover', this._mouseOverListener);
            this._adapter.removeTargetEventListener(targetElement, 'mouseout', this._mouseOutListener);
        }
        this._adapter.removeTargetEventListener(targetElement, 'click', this._clickListener);
    };
    /**
     * Handles the touchstart event on the target element to detect long press.
     */
    TooltipFoundation.prototype._onTouchStart = function (evt) {
        var _this = this;
        this._touchTimeout = window.setTimeout(function () {
            _this._show();
            window.setTimeout(function () { return _this._hide(); }, TOOLTIP_CONSTANTS.numbers.LONGPRESS_VISIBILITY_DURATION);
            _this._touchTimeout = undefined;
        }, TOOLTIP_CONSTANTS.numbers.LONGPRESS_THRESHOLD);
    };
    /**
     * Handles the touchend event on the target element to cancel a long press action.
     */
    TooltipFoundation.prototype._onTouchEnd = function (evt) {
        if (this._touchTimeout) {
            window.clearTimeout(this._touchTimeout);
            this._touchTimeout = undefined;
        }
    };
    /**
     * Handles the mouseover event on the target element.
     */
    TooltipFoundation.prototype._onMouseOver = function (evt) {
        var _this = this;
        if (this._isOpen || !this._targetElement || !this._targetElement.isConnected) {
            return;
        }
        if (this._delay) {
            this._mouseOverTimeout = window.setTimeout(function () {
                _this._show();
                _this._mouseOverTimeout = undefined;
            }, this._delay);
        }
        else {
            this._show();
        }
    };
    /**
     * Handles the mouseout event on the target element.
     */
    TooltipFoundation.prototype._onMouseOut = function (evt) {
        if (this._mouseOverTimeout) {
            window.clearTimeout(this._mouseOverTimeout);
            this._mouseOverTimeout = undefined;
            return;
        }
        this._hide();
    };
    /**
     * Displays the tooltip.
     */
    TooltipFoundation.prototype._show = function () {
        var content;
        if (this._builder && typeof this._builder === 'function') {
            content = this._builder();
        }
        this._isOpen = true;
        this._tooltipElement = this._adapter.showTooltip(this._targetElement, this._position, content);
        this._adapter.addWindowListener('scroll', this._scrollListener);
    };
    /**
     * Hides the tooltip.
     */
    TooltipFoundation.prototype._hide = function () {
        if (!this._isOpen) {
            return;
        }
        this._isOpen = false;
        this._adapter.removeWindowListener('scroll', this._scrollListener);
        this._adapter.hideTooltip(this._tooltipElement);
        this._tooltipElement = undefined;
    };
    /**
     * Handles scrolling events when the tooltip is open.
     */
    TooltipFoundation.prototype._onScroll = function (evt) {
        if (!this._isOpen) {
            return;
        }
        this._hide();
    };
    /**
     * Handles clicking the target element.
     * @param evt The mouse event.
     */
    TooltipFoundation.prototype._onClick = function (evt) {
        if (this._mouseOverTimeout) {
            window.clearTimeout(this._mouseOverTimeout);
        }
        if (this._isOpen) {
            this._hide();
        }
    };
    Object.defineProperty(TooltipFoundation.prototype, "text", {
        /** Gets/sets the tooltip text. */
        get: function () {
            return this._text || this._adapter.getInnerText();
        },
        set: function (value) {
            if (this._text !== value) {
                this._text = value;
                this._adapter.setTextContent(this._text);
                this._adapter.setHostAttribute(TOOLTIP_CONSTANTS.attributes.TEXT, this._text);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TooltipFoundation.prototype, "builder", {
        /** Sets the tooltip builder function. */
        set: function (value) {
            this._builder = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TooltipFoundation.prototype, "target", {
        /** Gets/sets the target element CSS selector. */
        get: function () {
            return this._target;
        },
        set: function (value) {
            if (this._target !== value) {
                this._target = value;
                if (this._targetElement) {
                    this._removeTargetListeners(this._targetElement);
                }
                this._targetElement = this._adapter.getTargetElement(this._target);
                if (this._targetElement) {
                    this._addTargetListeners(this._targetElement);
                }
                this._adapter.setHostAttribute(TOOLTIP_CONSTANTS.attributes.TARGET, isDefined(this._target) ? this._target : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TooltipFoundation.prototype, "delay", {
        /** Gets/sets the interaction delay. */
        get: function () {
            return this._delay;
        },
        set: function (value) {
            if (this._delay !== value) {
                this._delay = value;
                this._adapter.setHostAttribute(TOOLTIP_CONSTANTS.attributes.DELAY, isDefined(this._delay) ? this._delay.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TooltipFoundation.prototype, "position", {
        /** Gets/sets the tooltip position. */
        get: function () {
            return this._position;
        },
        set: function (value) {
            if (this._position !== value) {
                this._position = value;
                this._adapter.setHostAttribute(TOOLTIP_CONSTANTS.attributes.POSITION, isDefined(this._position) ? this._position.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    TooltipFoundation._tooltipIdentifier = 0;
    return TooltipFoundation;
}());
export { TooltipFoundation };
