import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty, coerceBoolean } from '@tyler-components-web/core';
import { OpenIconFoundation } from './open-icon-foundation';
import { OpenIconAdapter } from './open-icon-adapter';
import { OPEN_ICON_CONSTANTS } from './open-icon-constants';
var template = require('./open-icon.html');
var styles = require('./open-icon.css');
var OpenIconComponent = /** @class */ (function (_super) {
    __extends(OpenIconComponent, _super);
    function OpenIconComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, OPEN_ICON_CONSTANTS.elementName, template, styles);
        _this._foundation = new OpenIconFoundation(new OpenIconAdapter(_this));
        return _this;
    }
    Object.defineProperty(OpenIconComponent, "observedAttributes", {
        get: function () {
            return [
                OPEN_ICON_CONSTANTS.attributes.OPEN,
                OPEN_ICON_CONSTANTS.attributes.ORIENTATION
            ];
        },
        enumerable: true,
        configurable: true
    });
    OpenIconComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'open');
        upgradeProperty(this, 'orientation');
        this._foundation.initialize();
    };
    OpenIconComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case OPEN_ICON_CONSTANTS.attributes.OPEN:
                if (coerceBoolean(newValue)) {
                    this.open = true;
                }
                else {
                    this.open = false;
                }
                break;
            case OPEN_ICON_CONSTANTS.attributes.ORIENTATION:
                this.orientation = newValue;
                break;
        }
    };
    Object.defineProperty(OpenIconComponent.prototype, "open", {
        get: function () {
            return this._foundation.open;
        },
        /** Controls the open state of the icon. */
        set: function (value) {
            this._foundation.open = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpenIconComponent.prototype, "orientation", {
        /**
         * Gets/sets the orientation of the icon.
         * Valid values are 'vertical' (default) or 'horizontal'.
         */
        get: function () {
            return this._foundation.orientation;
        },
        set: function (value) {
            this._foundation.orientation = value;
        },
        enumerable: true,
        configurable: true
    });
    OpenIconComponent = __decorate([
        CustomElement({
            name: OPEN_ICON_CONSTANTS.elementName
        })
    ], OpenIconComponent);
    return OpenIconComponent;
}(HTMLElement));
export { OpenIconComponent };
