import { positionElement } from '@tyler-components-web/core';
import { setPopupPosition } from '../utils/positioning-utils';
import { TOOLTIP_CONSTANTS } from './tooltip-constants';
/**
 * Attaches a positioned tooltip to the provided target element.
 * @param targetElement The element to position the tooltip around.
 * @param placement The placement of the tooltip relative to the target element.
 * @param content The content of the tooltip.
 */
export function attachTooltip(targetElement, placement, content) {
    if (typeof content === 'string') {
        content = document.createTextNode(content);
    }
    var tooltipElement = document.createElement('div');
    tooltipElement.setAttribute('role', 'tooltip');
    tooltipElement.classList.add(TOOLTIP_CONSTANTS.classes.TOOLTIP);
    tooltipElement.appendChild(content);
    tooltipElement.setAttribute('role', 'tooltip');
    tooltipElement.setAttribute('aria-hidden', 'true');
    var hostDocument = targetElement.ownerDocument || document;
    hostDocument.body.appendChild(tooltipElement);
    switch (placement) {
        case 'top':
            tooltipElement.classList.add(TOOLTIP_CONSTANTS.classes.TOOLTIP_TOP);
            break;
        case 'right':
            tooltipElement.classList.add(TOOLTIP_CONSTANTS.classes.TOOLTIP_RIGHT);
            break;
        case 'bottom':
            tooltipElement.classList.add(TOOLTIP_CONSTANTS.classes.TOOLTIP_BOTTOM);
            break;
        case 'left':
            tooltipElement.classList.add(TOOLTIP_CONSTANTS.classes.TOOLTIP_LEFT);
            break;
    }
    window.requestAnimationFrame(function () {
        window.requestAnimationFrame(function () {
            tooltipElement.classList.add(TOOLTIP_CONSTANTS.classes.TOOLTIP_OPEN);
            var position = positionElement(tooltipElement, targetElement, "auto " + placement);
            setPopupPosition(tooltipElement, position);
        });
    });
    return tooltipElement;
}
