import { getShadowElement, emitEvent } from '@tyler-components-web/core';
import { OMNIBAR_CONSTANTS } from './omnibar-constants';
/**
 * Provides facilities for interacting with the internal DOM of `OmnibarComponent`.
 */
var OmnibarAdapter = /** @class */ (function () {
    function OmnibarAdapter(_component) {
        this._component = _component;
        this._rootElement = getShadowElement(_component, OMNIBAR_CONSTANTS.selectors.ROOT);
        this._titleElement = getShadowElement(_component, OMNIBAR_CONSTANTS.selectors.TITLE);
        this._bottomElement = getShadowElement(_component, OMNIBAR_CONSTANTS.selectors.BOTTOM);
        this._bottomSlotElement = getShadowElement(_component, OMNIBAR_CONSTANTS.selectors.BOTTOM_SLOT);
        this._centerSlotElement = getShadowElement(_component, OMNIBAR_CONSTANTS.selectors.CENTER_SLOT);
        this._centerSectionElement = getShadowElement(_component, OMNIBAR_CONSTANTS.selectors.CENTER_SECTION);
        this._logoElement = getShadowElement(_component, OMNIBAR_CONSTANTS.selectors.LOGO);
    }
    /**
     * Emits the provided event from the component host element.
     * @param {string} type The event type.
     * @param {any} data The event data.
     * @param {boolean} [bubble=false] Should the event bubble up?
     */
    OmnibarAdapter.prototype.emitHostEvent = function (type, data, bubble) {
        if (bubble === void 0) { bubble = false; }
        emitEvent(this._component, type, data, bubble);
    };
    /**
     * Sets an attribute value on the host element.
     * @param {string} name The attribute name.
     * @param {string} value The attribute value.
     */
    OmnibarAdapter.prototype.setHostAttribute = function (name, value) {
        this._component.setAttribute(name, value);
    };
    /**
     * Sets the title text value.
     * @param {string} value The title text.
     */
    OmnibarAdapter.prototype.setTitleText = function (value) {
        this._titleElement.textContent = value;
    };
    OmnibarAdapter.prototype.setScrollTarget = function (selector) {
        // this._rootElement.setAttribute(OMNIBAR_CONSTANTS.attributes.SCROLL_TARGET, selector);
    };
    OmnibarAdapter.prototype.setDense = function (value) {
        value ? this._rootElement.classList.add(OMNIBAR_CONSTANTS.classes.DENSE) : this._rootElement.classList.remove(OMNIBAR_CONSTANTS.classes.DENSE);
    };
    OmnibarAdapter.prototype.setRaised = function (value) {
        value ? this._rootElement.classList.add(OMNIBAR_CONSTANTS.classes.RAISED) : this._rootElement.classList.remove(OMNIBAR_CONSTANTS.classes.RAISED);
    };
    OmnibarAdapter.prototype.setProminent = function (value) {
        value ? this._rootElement.classList.add(OMNIBAR_CONSTANTS.classes.PROMINENT) : this._rootElement.classList.remove(OMNIBAR_CONSTANTS.classes.PROMINENT);
    };
    OmnibarAdapter.prototype.setFixed = function (value) {
        value ? this._rootElement.classList.add(OMNIBAR_CONSTANTS.classes.FIXED) : this._rootElement.classList.remove(OMNIBAR_CONSTANTS.classes.FIXED);
    };
    OmnibarAdapter.prototype.addBottomClass = function (name) {
        this._bottomElement.classList.add(name);
    };
    OmnibarAdapter.prototype.removeBottomClass = function (name) {
        this._bottomElement.classList.remove(name);
    };
    OmnibarAdapter.prototype.addBottomSlotListener = function (listener) {
        this._bottomSlotElement.addEventListener('slotchange', listener);
    };
    OmnibarAdapter.prototype.addCenterSlotListener = function (listener) {
        this._centerSlotElement.addEventListener('slotchange', listener);
    };
    OmnibarAdapter.prototype.setCenterSlotVisibility = function () {
        if (this._centerSlotElement.assignedNodes().length) {
            this._centerSectionElement.style.removeProperty('display');
            this._rootElement.classList.remove(OMNIBAR_CONSTANTS.classes.NO_CENTER);
        }
        else {
            this._centerSectionElement.style.display = 'none';
            this._rootElement.classList.add(OMNIBAR_CONSTANTS.classes.NO_CENTER);
        }
    };
    OmnibarAdapter.prototype.setLogoVisibility = function (visible) {
        if (visible) {
            this._logoElement.style.removeProperty('display');
        }
        else {
            this._logoElement.style.display = 'none';
        }
    };
    return OmnibarAdapter;
}());
export { OmnibarAdapter };
