import { OPEN_ICON_CONSTANTS } from './open-icon-constants';
var OpenIconFoundation = /** @class */ (function () {
    function OpenIconFoundation(_adapter) {
        this._adapter = _adapter;
        this._open = false;
        this._orientation = OPEN_ICON_CONSTANTS.strings.ORIENTATION_VERTICAL;
    }
    OpenIconFoundation.prototype.initialize = function () {
        this._adapter.initialize(this._orientation);
        this._adapter.setOpenState(this._open);
    };
    Object.defineProperty(OpenIconFoundation.prototype, "open", {
        /** Controls the open state of the icon. */
        get: function () {
            return this._open;
        },
        set: function (value) {
            value = Boolean(value);
            if (this._open !== value) {
                this._open = value;
                this._adapter.setOpenState(this._open);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpenIconFoundation.prototype, "orientation", {
        get: function () {
            return this._orientation;
        },
        set: function (value) {
            this._orientation = value;
        },
        enumerable: true,
        configurable: true
    });
    return OpenIconFoundation;
}());
export { OpenIconFoundation };
