import { EXPANSION_PANEL_CONSTANTS } from '../expansion-panel/expansion-panel-constants';
import { ACCORDION_CONSTANTS } from './accordion-constants';
/**
 * The foundation class behind the `AccordionComponent`.
 */
var AccordionFoundation = /** @class */ (function () {
    function AccordionFoundation(_adapter) {
        this._adapter = _adapter;
    }
    AccordionFoundation.prototype.initialize = function () {
        this._attachListeners();
    };
    AccordionFoundation.prototype.disconnect = function () {
        this._detachListeners();
    };
    AccordionFoundation.prototype._attachListeners = function () {
        var _this = this;
        this._hostInteractionCallback = function (evt) { return _this._hostInteraction(evt); };
        this._adapter.addEventListener(EXPANSION_PANEL_CONSTANTS.events.OPEN, this._hostInteractionCallback, this._adapter.getHostElement());
    };
    AccordionFoundation.prototype._detachListeners = function () {
        if (this._hostInteractionCallback) {
            this._adapter.removeEventListener(EXPANSION_PANEL_CONSTANTS.events.OPEN, this._hostInteractionCallback, this._adapter.getHostElement());
            this._hostInteractionCallback = undefined;
        }
    };
    AccordionFoundation.prototype._hostInteraction = function (evt) {
        switch (evt.type) {
            case EXPANSION_PANEL_CONSTANTS.events.OPEN:
                evt.stopPropagation();
                var evtTarget_1 = evt.target;
                this._adapter.getChildPanels(this._panelSelector).forEach(function (panel) {
                    if (evtTarget_1 !== panel) {
                        panel.open = false;
                    }
                });
                break;
            default:
                break;
        }
    };
    Object.defineProperty(AccordionFoundation.prototype, "panelSelector", {
        /** Gets/sets the selector to use for finding the child expansion panels. Defaults to searching the direct children for `<tcw-expansion-panel>` elements. */
        get: function () {
            return this._panelSelector;
        },
        set: function (value) {
            if (this._panelSelector !== value) {
                this._panelSelector = value;
                this._adapter.setHostAttribute(ACCORDION_CONSTANTS.attributes.PANEL_SELECTOR, this._panelSelector);
            }
        },
        enumerable: true,
        configurable: true
    });
    return AccordionFoundation;
}());
export { AccordionFoundation };
