import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "toolbar";
var attributes = {
    INVERTED: 'inverted'
};
var classes = {
    INVERTED: 'tyl-toolbar--inverted'
};
var selectors = {
    TOOLBAR: '.tyl-toolbar'
};
export var TOOLBAR_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    classes: classes,
    selectors: selectors
};
