import { COMPONENT_NAME_PREFIX } from '../../constants';
var elementName = COMPONENT_NAME_PREFIX + "select";
var classes = {
    ROOT: 'tyl-select',
    FOCUSED: 'tyl-select--focused',
    SELECT_DISABLED: 'tyl-select--disabled',
    SELECTED_TEXT: 'tyl-select__selected-text',
    MDC_NOTCHED_OUTLINE: 'mdc-notched-outline',
    MDC_FLOATING_LABEL: 'mdc-floating-label',
    LEADING_ICON: 'tyl-select--with-leading-icon',
    DROPDOWN: 'tyl-select__dropdown',
    NO_LABEL: 'tyl-select--no-label',
    DENSE: 'tyl-select--dense',
    ROOMY: 'tyl-select--roomy',
    INVALID: 'tyl-select--invalid',
    REQUIRED: 'tyl-select--required',
    LABEL_FLOAT: 'tyl-select--label-float'
};
var selectors = {
    ROOT: "." + classes.ROOT,
    SELECTED_TEXT: "." + classes.SELECTED_TEXT,
    NOTCHED_OUTLINE: "." + classes.MDC_NOTCHED_OUTLINE,
    LABEL: "." + classes.MDC_FLOATING_LABEL,
    LEADING_SLOT: 'slot[name=leading]'
};
var attributes = {
    TYPE: 'type',
    VALUE: 'value',
    DENSITY: 'density',
    LABEL: 'label',
    MULTIPLE: 'multiple',
    DISABLED: 'disabled',
    INVALID: 'invalid',
    REQUIRED: 'required',
    FLOAT_LABEL_TYPE: 'float-label-type',
    PLACEHOLDER: 'placeholder'
};
var events = {
    CHANGE: 'change'
};
var numbers = {
    LABEL_SCALE: 0.75
};
export var SELECT_CONSTANTS = {
    elementName: elementName,
    classes: classes,
    selectors: selectors,
    attributes: attributes,
    events: events,
    numbers: numbers
};
