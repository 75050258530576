import { COMPONENT_NAME_PREFIX } from '../../constants';
var elementName = COMPONENT_NAME_PREFIX + "profile-card";
var attributes = {
    FULL_NAME: 'full-name',
    EMAIL: 'email',
    SIGN_OUT: 'sign-out',
    PROFILE: 'profile'
};
var selectors = {
    FULL_NAME: '.tyl-profile-card__full-name',
    EMAIL: '.tyl-profile-card__email',
    AVATAR: '.tyl-profile-card__info-avatar > tcw-avatar',
    ACTION_TOOLBAR: '#action-toolbar',
    PROFILE_BUTTON: '#profile-button',
    SIGN_OUT_BUTTON: '#sign-out-button',
};
var defaults = {
    SHOW_SIGN_OUT_BUTTON: true,
    SHOW_PROFILE_BUTTON: false
};
var events = {
    SIGN_OUT: elementName + "-sign-out",
    PROFILE: elementName + "-profile"
};
export var PROFILE_CARD_CONSTANTS = {
    elementName: elementName,
    attributes: attributes,
    selectors: selectors,
    defaults: defaults,
    events: events
};
