import { OMNIBAR_SEARCH_CONSTANTS } from './omnibar-search-constants';
/**
 * Provides facilities and helper methods for creating an omnibar search component.
 */
var OmnibarSearchFoundation = /** @class */ (function () {
    function OmnibarSearchFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._disabled = false;
        this._combined = false;
        this._combinedContext = OMNIBAR_SEARCH_CONSTANTS.strings.DEFAULT_CONTEXT;
        this._combinedOptions = [];
        this._global = false;
        this._keydownListener = function (evt) { return _this._onKeyDown(evt); };
        this._focusListener = function (evt) { return _this._onFocus(evt); };
        this._blurListener = function (evt) { return _this._onBlur(evt); };
    }
    OmnibarSearchFoundation.prototype.initialize = function () {
        this._adapter.initialize();
        if (!this._adapter.hasInputElement()) {
            throw new Error('An input element is required as a child of the omnibar-search component.');
        }
        this._adapter.setGlobalIconVisibility(this._global);
        this._adapter.setContextVisibility(this._combined);
        this._adapter.addSearchInputEventListener('keydown', this._keydownListener);
        this._adapter.addSearchInputEventListener('focus', this._focusListener);
        this._adapter.addSearchInputEventListener('blur', this._blurListener);
    };
    /**
     * Handles keydown events on our search input.
     * @param {KeyboardEvent} evt
     */
    OmnibarSearchFoundation.prototype._onKeyDown = function (evt) {
        if (evt.key === 'Enter' || evt.keyCode === 13) {
            var data = {
                value: evt.target.value,
                combined: this._combined,
                context: this._combinedContext
            };
            this._adapter.emitHostEvent(OMNIBAR_SEARCH_CONSTANTS.events.SEARCH, data, true);
        }
    };
    OmnibarSearchFoundation.prototype._onFocus = function (evt) {
        this._adapter.addRootClass(OMNIBAR_SEARCH_CONSTANTS.classes.FOCUSED);
    };
    OmnibarSearchFoundation.prototype._onBlur = function (evt) {
        this._adapter.removeRootClass(OMNIBAR_SEARCH_CONSTANTS.classes.FOCUSED);
    };
    OmnibarSearchFoundation.prototype.disconnect = function () {
        this._adapter.removeSearchInputEventListener('keydown', this._keydownListener);
        this._adapter.removeSearchInputEventListener('focus', this._focusListener);
        this._adapter.removeSearchInputEventListener('blur', this._blurListener);
    };
    Object.defineProperty(OmnibarSearchFoundation.prototype, "disabled", {
        /** Gets/sets the disabled state of the input. */
        get: function () {
            return this._disabled;
        },
        set: function (value) {
            if (value !== this._disabled) {
                this._disabled = value;
                this._adapter.setDisabledAttribute(this._disabled);
                if (this._disabled) {
                    this._adapter.setHostAttribute(OMNIBAR_SEARCH_CONSTANTS.attributes.DISABLED, OMNIBAR_SEARCH_CONSTANTS.attributes.DISABLED);
                }
                else {
                    this._adapter.removeHostAttribute(OMNIBAR_SEARCH_CONSTANTS.attributes.DISABLED);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarSearchFoundation.prototype, "value", {
        /** Gets/sets the input value. */
        get: function () {
            return this._adapter.getInputValue();
        },
        set: function (value) {
            this._adapter.setInputValue(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarSearchFoundation.prototype, "placeholder", {
        /** Sets the placeholder text. */
        set: function (value) {
            this._adapter.setInputPlaceholder(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarSearchFoundation.prototype, "combined", {
        /** Gets/sets the input value. */
        get: function () {
            return this._combined;
        },
        set: function (value) {
            if (this._combined !== value) {
                this._combined = value;
                this._adapter.setContextVisibility(this._combined);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarSearchFoundation.prototype, "combinedOptions", {
        /** Gets/sets options for the combined search dropdown. */
        get: function () {
            return this._combinedOptions;
        },
        set: function (value) {
            this._combinedOptions = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarSearchFoundation.prototype, "selectedCombinedOption", {
        /** Gets/sets selected option for the combined search dropdown. */
        get: function () {
            return this._selectedCombinedOption;
        },
        set: function (value) {
            if (this._selectedCombinedOption !== value) {
                this._selectedCombinedOption = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarSearchFoundation.prototype, "global", {
        /** Gets/sets the input value. */
        get: function () {
            return this._global;
        },
        set: function (value) {
            if (this._global !== value) {
                this._global = value;
                this._adapter.setGlobalIconVisibility(this._global);
            }
        },
        enumerable: true,
        configurable: true
    });
    return OmnibarSearchFoundation;
}());
export { OmnibarSearchFoundation };
