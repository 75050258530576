import { __extends } from "tslib";
import { isDefined } from '@tyler-components-web/core';
import { AbstractComponentDelegate } from '../core/delegates/abstract-component-delegate';
import { TEXT_FIELD_CONSTANTS } from '../text-field/text-field-constants';
import { DATEPICKER_CONSTANTS } from './datepicker-constants';
var DatepickerComponentDelegate = /** @class */ (function (_super) {
    __extends(DatepickerComponentDelegate, _super);
    function DatepickerComponentDelegate(config) {
        if (config === void 0) { config = {}; }
        return _super.call(this, config) || this;
    }
    DatepickerComponentDelegate.prototype.build = function () {
        var datepicker = document.createElement(DATEPICKER_CONSTANTS.elementName);
        this._textField = document.createElement(TEXT_FIELD_CONSTANTS.elementName);
        this._input = document.createElement('input');
        this._input.type = 'text';
        datepicker.appendChild(this._textField);
        this._textField.appendChild(this._input);
        return datepicker;
    };
    DatepickerComponentDelegate.prototype.configure = function () {
        if (isDefined(this._config.required)) {
            this._textField.required = this._config.required;
        }
        if (isDefined(this._config.label) && this._config.label) {
            var label = document.createElement('label');
            label.textContent = this._config.label;
            this._textField.appendChild(label);
        }
    };
    DatepickerComponentDelegate.prototype.getValue = function () {
        return this._input.value;
    };
    DatepickerComponentDelegate.prototype.setValue = function (value) {
        this._input.value = value;
    };
    DatepickerComponentDelegate.prototype.onChange = function (listener) {
        this._input.addEventListener('change', function (evt) { return listener(evt.target.value); });
    };
    DatepickerComponentDelegate.prototype.onFocus = function (listener) {
        this._input.addEventListener('focus', function (evt) { return listener(evt); });
    };
    DatepickerComponentDelegate.prototype.onBlur = function (listener) {
        this._input.addEventListener('blur', function (evt) { return listener(evt); });
    };
    DatepickerComponentDelegate.prototype.setDisabled = function (value) {
        this._input.disabled = value;
    };
    DatepickerComponentDelegate.prototype.setValidity = function (value) {
        this._textField.invalid = !value;
    };
    DatepickerComponentDelegate.prototype.validate = function () {
        return !!this._input.value.trim();
    };
    return DatepickerComponentDelegate;
}(AbstractComponentDelegate));
export { DatepickerComponentDelegate };
