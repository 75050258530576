import { getShadowElement, emitEvent, removeAllChildren } from '@tyler-components-web/core';
import { BUTTON_TOGGLE_GROUP_CONSTANTS } from './button-toggle-group-constants';
import { BUTTON_TOGGLE_CONSTANTS } from '../button-toggle/button-toggle-constants';
import { ICON_CLASS_NAME } from '../../constants';
var ButtonToggleGroupAdapter = /** @class */ (function () {
    function ButtonToggleGroupAdapter(_component) {
        this._component = _component;
        this._rootElement = getShadowElement(_component, BUTTON_TOGGLE_GROUP_CONSTANTS.selectors.ROOT);
    }
    ButtonToggleGroupAdapter.prototype.emitEvent = function (type, data, bubbles) {
        if (bubbles === void 0) { bubbles = true; }
        emitEvent(this._component, type, data, bubbles);
    };
    ButtonToggleGroupAdapter.prototype.addListener = function (type, listener) {
        this._rootElement.addEventListener(type, listener);
    };
    ButtonToggleGroupAdapter.prototype.removeListener = function (type, listener) {
        this._rootElement.removeEventListener(type, listener);
    };
    ButtonToggleGroupAdapter.prototype.deselect = function (selectedToggle) {
        var toggles = this._getButtonToggleElements();
        toggles
            .filter(function (t) { return t !== selectedToggle; })
            .forEach(function (t) { return t.selected = false; });
    };
    ButtonToggleGroupAdapter.prototype.applyAdjacentSelections = function (isVertical) {
        var toggles = this._getButtonToggleElements();
        for (var i = toggles.length - 1; i > 0; i--) {
            toggles[i].removeAttribute(BUTTON_TOGGLE_CONSTANTS.attributes.SELECTED_ADJACENT);
            toggles[i].removeAttribute(BUTTON_TOGGLE_CONSTANTS.attributes.SELECTED_ADJACENT_VERTICAL);
            if (toggles[i].selected && toggles[i - 1].selected) {
                var attr = isVertical ? BUTTON_TOGGLE_CONSTANTS.attributes.SELECTED_ADJACENT_VERTICAL : BUTTON_TOGGLE_CONSTANTS.attributes.SELECTED_ADJACENT;
                toggles[i].setAttribute(attr, '');
            }
        }
    };
    ButtonToggleGroupAdapter.prototype.setVertical = function (isVertical) {
        if (isVertical) {
            this._rootElement.classList.add(BUTTON_TOGGLE_GROUP_CONSTANTS.classes.VERTICAL);
        }
        else {
            this._rootElement.classList.remove(BUTTON_TOGGLE_GROUP_CONSTANTS.classes.VERTICAL);
        }
    };
    ButtonToggleGroupAdapter.prototype.setStretch = function (value) {
        if (value) {
            this._rootElement.classList.add(BUTTON_TOGGLE_GROUP_CONSTANTS.classes.STRETCH);
        }
        else {
            this._rootElement.classList.remove(BUTTON_TOGGLE_GROUP_CONSTANTS.classes.STRETCH);
        }
        var toggles = this._getButtonToggleElements();
        toggles.forEach(function (toggle) {
            if (value) {
                toggle.setAttribute('stretch', '');
            }
            else {
                toggle.removeAttribute('stretch');
            }
        });
    };
    ButtonToggleGroupAdapter.prototype.setDense = function (value) {
        var toggles = this._getButtonToggleElements();
        toggles.forEach(function (t) { return t.dense = value; });
    };
    ButtonToggleGroupAdapter.prototype.setDisabled = function (value) {
        var toggles = this._getButtonToggleElements();
        toggles.forEach(function (t) { return t.disabled = value; });
    };
    ButtonToggleGroupAdapter.prototype.getSelectedValues = function () {
        var toggles = this._getButtonToggleElements();
        return toggles.filter(function (t) { return t.selected; }).map(function (t) { return t.value; });
    };
    ButtonToggleGroupAdapter.prototype.applyValues = function (values) {
        var toggles = this._getButtonToggleElements();
        toggles.forEach(function (t) { return t.selected = values.indexOf(t.value) >= 0; });
    };
    ButtonToggleGroupAdapter.prototype.createOptions = function (options) {
        var _this = this;
        removeAllChildren(this._component);
        options.forEach(function (o) {
            _this._component.appendChild(_this._createButtonToggle(o));
        });
    };
    ButtonToggleGroupAdapter.prototype._createButtonToggle = function (option) {
        var buttonToggle = document.createElement(BUTTON_TOGGLE_CONSTANTS.elementName);
        buttonToggle.value = option.value;
        if (option.label) {
            buttonToggle.textContent = option.label;
        }
        else if (option.icon) {
            var icon = document.createElement('i');
            icon.textContent = option.icon;
            icon.classList.add(ICON_CLASS_NAME);
            icon.setAttribute('aria-hidden', 'true');
            buttonToggle.appendChild(icon);
        }
        if (option.leadingIcon) {
            var leadingIcon = document.createElement('i');
            leadingIcon.slot = 'leading';
            leadingIcon.textContent = option.leadingIcon;
            leadingIcon.classList.add(ICON_CLASS_NAME);
            leadingIcon.setAttribute('aria-hidden', 'true');
            buttonToggle.appendChild(leadingIcon);
        }
        if (option.trailingIcon) {
            var trailingIcon = document.createElement('i');
            trailingIcon.slot = 'trailing';
            trailingIcon.textContent = option.trailingIcon;
            trailingIcon.classList.add(ICON_CLASS_NAME);
            trailingIcon.setAttribute('aria-hidden', 'true');
            buttonToggle.appendChild(trailingIcon);
        }
        return buttonToggle;
    };
    ButtonToggleGroupAdapter.prototype._getButtonToggleElements = function () {
        return Array.from(this._component.querySelectorAll(BUTTON_TOGGLE_CONSTANTS.elementName));
    };
    return ButtonToggleGroupAdapter;
}());
export { ButtonToggleGroupAdapter };
