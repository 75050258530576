import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate } from '@tyler-components-web/core';
import { PAGE_STATE_CONSTANTS } from './page-state-constants';
var template = require('./page-state.html');
var styles = require('./page-state.css');
var PageStateComponent = /** @class */ (function (_super) {
    __extends(PageStateComponent, _super);
    function PageStateComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, PAGE_STATE_CONSTANTS.elementName, template, styles);
        return _this;
    }
    PageStateComponent = __decorate([
        CustomElement({
            name: PAGE_STATE_CONSTANTS.elementName
        })
    ], PageStateComponent);
    return PageStateComponent;
}(HTMLElement));
export { PageStateComponent };
