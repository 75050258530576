import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "linear-progress";
var selectors = {
    ROOT: '.mdc-linear-progress'
};
var attributes = {
    DETERMINATE: 'determinate',
    PROGRESS: 'progress',
    BUFFER: 'buffer'
};
export var LINEAR_PROGRESS_CONSTANTS = {
    elementName: elementName,
    selectors: selectors,
    attributes: attributes
};
