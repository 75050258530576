import { MENU_CONSTANTS } from '../../menu';
import { getLightElement } from '@tyler-components-web/core';
var OmnibarHelpButtonAdapter = /** @class */ (function () {
    function OmnibarHelpButtonAdapter(_component) {
        this._component = _component;
    }
    OmnibarHelpButtonAdapter.prototype.initialize = function () {
        this._menuElement = getLightElement(this._component, MENU_CONSTANTS.elementName);
    };
    OmnibarHelpButtonAdapter.prototype.setMenuOptions = function (options) {
        this._menuElement.options = options;
    };
    return OmnibarHelpButtonAdapter;
}());
export { OmnibarHelpButtonAdapter };
