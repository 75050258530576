import { getShadowElement, addClass, removeClass } from '@tyler-components-web/core';
import { BADGE_CONSTANTS } from './badge-constants';
var BadgeAdapter = /** @class */ (function () {
    function BadgeAdapter(_component) {
        this._component = _component;
        this._rootElement = getShadowElement(_component, BADGE_CONSTANTS.selectors.ROOT);
    }
    BadgeAdapter.prototype.setHostAttribute = function (name, value) {
        if (value === void 0) { value = ''; }
        this._component.setAttribute(name, value);
    };
    BadgeAdapter.prototype.removeHostAttribute = function (name) {
        this._component.removeAttribute(name);
    };
    BadgeAdapter.prototype.setRootClass = function (classes) {
        addClass(classes, this._rootElement);
    };
    BadgeAdapter.prototype.removeRootClass = function (classes) {
        removeClass(classes, this._rootElement);
    };
    return BadgeAdapter;
}());
export { BadgeAdapter };
