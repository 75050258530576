import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty } from '@tyler-components-web/core';
import { PAGINATOR_CONSTANTS } from './paginator-constants';
import { PaginatorFoundation } from './paginator-foundation';
import { PaginatorAdapter } from './paginator-adapter';
import { IconButtonComponent } from '../icon-button';
import { SelectComponent } from '../select';
var template = require('./paginator.html');
var styles = require('./paginator.css');
/**
 * The custom element class behind the `<tcw-paginator>` component.
 */
var PaginatorComponent = /** @class */ (function (_super) {
    __extends(PaginatorComponent, _super);
    function PaginatorComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, PAGINATOR_CONSTANTS.elementName, template, styles);
        _this._foundation = new PaginatorFoundation(new PaginatorAdapter(_this));
        return _this;
    }
    Object.defineProperty(PaginatorComponent, "observedAttributes", {
        get: function () {
            return [
                PAGINATOR_CONSTANTS.attributes.PAGE_INDEX,
                PAGINATOR_CONSTANTS.attributes.PAGE_SIZE,
                PAGINATOR_CONSTANTS.attributes.TOTAL,
                PAGINATOR_CONSTANTS.attributes.PAGE_SIZE_OPTIONS,
                PAGINATOR_CONSTANTS.attributes.LABEL,
                PAGINATOR_CONSTANTS.attributes.FIRST_LAST,
                PAGINATOR_CONSTANTS.attributes.FIRST,
                PAGINATOR_CONSTANTS.attributes.DISABLED
            ];
        },
        enumerable: true,
        configurable: true
    });
    PaginatorComponent.prototype.connectedCallback = function () {
        upgradeProperty(this, 'pageIndex');
        upgradeProperty(this, 'pageSize');
        upgradeProperty(this, 'total');
        upgradeProperty(this, 'pageSizeOptions');
        upgradeProperty(this, 'label');
        upgradeProperty(this, 'firstLast');
        upgradeProperty(this, 'first');
        upgradeProperty(this, 'disabled');
        this._foundation.initialize();
    };
    PaginatorComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    PaginatorComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case PAGINATOR_CONSTANTS.attributes.PAGE_INDEX:
                this.pageIndex = Number(newValue) || PAGINATOR_CONSTANTS.numbers.DEFAULT_PAGE_INDEX;
                break;
            case PAGINATOR_CONSTANTS.attributes.PAGE_SIZE:
                this.pageSize = Number(newValue) || PAGINATOR_CONSTANTS.numbers.DEFAULT_PAGE_SIZE;
                break;
            case PAGINATOR_CONSTANTS.attributes.TOTAL:
                this.total = Number(newValue) || PAGINATOR_CONSTANTS.numbers.DEFAULT_TOTAL;
                break;
            case PAGINATOR_CONSTANTS.attributes.PAGE_SIZE_OPTIONS:
                this.pageSizeOptions = newValue;
                break;
            case PAGINATOR_CONSTANTS.attributes.LABEL:
                this.label = newValue;
                break;
            case PAGINATOR_CONSTANTS.attributes.FIRST_LAST:
                this.firstLast = this.hasAttribute(PAGINATOR_CONSTANTS.attributes.FIRST_LAST);
                break;
            case PAGINATOR_CONSTANTS.attributes.FIRST:
                this.first = this.hasAttribute(PAGINATOR_CONSTANTS.attributes.FIRST);
                break;
            case PAGINATOR_CONSTANTS.attributes.DISABLED:
                this.disabled = this.hasAttribute(PAGINATOR_CONSTANTS.attributes.DISABLED);
                break;
        }
    };
    Object.defineProperty(PaginatorComponent.prototype, "pageIndex", {
        get: function () {
            return this._foundation.pageIndex;
        },
        /** The zero-based page index. Default is 0. */
        set: function (value) {
            this._foundation.pageIndex = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorComponent.prototype, "pageSize", {
        get: function () {
            return this._foundation.pageSize;
        },
        /** Number of items to display on a page. By default set to 25. */
        set: function (value) {
            this._foundation.pageSize = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorComponent.prototype, "total", {
        get: function () {
            return this._foundation.total;
        },
        /** The total number of items to be paginated. Default is 0. */
        set: function (value) {
            this._foundation.total = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorComponent.prototype, "pageSizeOptions", {
        get: function () {
            return this._foundation.pageSizeOptions;
        },
        /** The set of provided page size options to display to the user. */
        set: function (options) {
            this._foundation.pageSizeOptions = options;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorComponent.prototype, "label", {
        get: function () {
            return this._foundation.label;
        },
        /** A label for the paginator. Default is "Rows per page:". */
        set: function (value) {
            this._foundation.label = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorComponent.prototype, "firstLast", {
        get: function () {
            return this._foundation.firstLast;
        },
        /** Whether to show the first page and last page buttons. Default is false. */
        set: function (value) {
            this._foundation.firstLast = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorComponent.prototype, "first", {
        get: function () {
            return this._foundation.first;
        },
        /** Whether to show the first page button. Default is false. */
        set: function (value) {
            this._foundation.first = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorComponent.prototype, "disabled", {
        get: function () {
            return this._foundation.disabled;
        },
        /** Whether the paginator is disabled. Default is false. */
        set: function (value) {
            this._foundation.disabled = value;
        },
        enumerable: true,
        configurable: true
    });
    PaginatorComponent = __decorate([
        CustomElement({
            name: PAGINATOR_CONSTANTS.elementName,
            dependencies: [
                IconButtonComponent,
                SelectComponent
            ]
        })
    ], PaginatorComponent);
    return PaginatorComponent;
}(HTMLElement));
export { PaginatorComponent };
