import { COMPONENT_NAME_PREFIX } from '../../constants';
var elementName = COMPONENT_NAME_PREFIX + "omnibar-notification-button";
var attributes = {
    COUNT: 'count',
    DOT: 'dot',
    THEME: 'theme',
    SHOW_BADGE: 'show-badge'
};
export var OMNIBAR_NOTIFICATION_BUTTON_CONSTANTS = {
    elementName: elementName,
    attributes: attributes
};
