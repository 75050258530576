import { isValidDate, padLeft } from '@tyler-components-web/core';
import { DATEPICKER_CONSTANTS } from './datepicker-constants';
var DatepickerFoundation = /** @class */ (function () {
    function DatepickerFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._open = false;
        this._dismissListener = function () { return _this._closeCalendar(false); };
    }
    DatepickerFoundation.prototype.initialize = function () {
        var _this = this;
        if (!this._adapter.inputElement) {
            return;
        }
        this._adapter.addClass(DATEPICKER_CONSTANTS.classes.ICON_TRAILING);
        this._adapter.createIcon();
        this._adapter.addEventListener('click', function (evt) { return _this._iconInteraction(evt); }, this._adapter.iconElement);
        this._adapter.inputElement.addEventListener('blur', function () { return _this._setFormattedDate(); });
    };
    DatepickerFoundation.prototype.disconnect = function () {
        this._closeCalendar(false);
    };
    Object.defineProperty(DatepickerFoundation.prototype, "min", {
        set: function (value) {
            this._min = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatepickerFoundation.prototype, "max", {
        set: function (value) {
            this._max = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatepickerFoundation.prototype, "open", {
        get: function () {
            return this._open;
        },
        set: function (value) {
            var _this = this;
            value = Boolean(value);
            if (this._open !== value) {
                this._open = value;
                if (value) {
                    this._openCalendar(false);
                }
                else {
                    // because this isn't based off the popup element - like _iconInteraction is - have to wait for _openCalendar to finish. this should be cleared up when this component is reworked.
                    window.requestAnimationFrame(function () {
                        _this._closeCalendar(false, false);
                    });
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    DatepickerFoundation.prototype._iconInteraction = function (event) {
        event.stopPropagation();
        if (!this._adapter.inputElement.disabled) {
            if (this._adapter.popupElement && this._adapter.popupElement.open) {
                this._closeCalendar(true);
            }
            else {
                this._openCalendar();
            }
        }
    };
    DatepickerFoundation.prototype._openCalendar = function (triggerEvent) {
        var _this = this;
        if (triggerEvent === void 0) { triggerEvent = true; }
        this._open = true;
        this._adapter.setAttribute(DATEPICKER_CONSTANTS.attributes.OPEN, '');
        var inputDate = this._parseInputValue(this._adapter.inputElement.value);
        if (isValidDate(inputDate)) {
            this._selectedDate = inputDate;
        }
        else {
            this._selectedDate = new Date();
        }
        this._selectedMonth = this._selectedDate.getMonth();
        this._selectedYear = this._selectedDate.getFullYear();
        this._adapter.attachCalendar();
        window.requestAnimationFrame(function () {
            _this._adapter.renderCalendar(_this._selectedMonth, _this._selectedYear, _this._selectedDate, _this._min, _this._max);
            _this._adapter.addEventListener('click', function (evt) { return _this._calendarHeaderInteraction(evt); }, _this._adapter.prevMonthButtonElement);
            _this._adapter.addEventListener('click', function (evt) { return _this._calendarHeaderInteraction(evt); }, _this._adapter.nextMonthButtonElement);
            _this._adapter.addEventListener('change', function (evt) { return _this._calendarHeaderInteraction(evt); }, _this._adapter.monthSelectElement);
            _this._adapter.addEventListener('change', function (evt) { return _this._calendarHeaderInteraction(evt); }, _this._adapter.yearSelectElement);
            _this._adapter.addEventListener('click', function (evt) { return _this._calendarBodyInteraction(evt); }, _this._adapter.calendarBodyElement, true);
            _this._adapter.addDismissInteraction(_this._dismissListener);
            _this._adapter.popupElement.open = true;
            if (triggerEvent) {
                _this._adapter.dispatchEvent(DATEPICKER_CONSTANTS.events.OPEN);
            }
        });
    };
    DatepickerFoundation.prototype._closeCalendar = function (focusInput, triggerEvent) {
        if (triggerEvent === void 0) { triggerEvent = true; }
        this._open = false;
        this._adapter.removeAttribute(DATEPICKER_CONSTANTS.attributes.OPEN);
        if (this._adapter.popupElement) {
            this._adapter.popupElement.open = false;
            this._adapter.detachCalendar();
        }
        if (focusInput) {
            this._adapter.inputElement.focus();
        }
        if (triggerEvent) {
            this._adapter.dispatchEvent(DATEPICKER_CONSTANTS.events.CLOSE);
        }
    };
    DatepickerFoundation.prototype._setFormattedDate = function () {
        if (this._adapter.inputElement.value) {
            var parsedDate = this._parseInputValue(this._adapter.inputElement.value);
            if (parsedDate) {
                var formattedDate = this._formatInputValue(parsedDate);
                if (formattedDate) {
                    this._adapter.inputElement.value = formattedDate;
                }
            }
        }
    };
    DatepickerFoundation.prototype._calendarHeaderInteraction = function (event) {
        var targetElement = event.currentTarget;
        switch (event.type) {
            case 'click':
                if (targetElement === this._adapter.prevMonthButtonElement) {
                    if (this._selectedMonth === 0) {
                        this._selectedMonth = 11;
                        this._selectedYear--;
                    }
                    else {
                        this._selectedMonth--;
                    }
                }
                else if (targetElement === this._adapter.nextMonthButtonElement) {
                    if (this._selectedMonth === 11) {
                        this._selectedMonth = 0;
                        this._selectedYear++;
                    }
                    else {
                        this._selectedMonth++;
                    }
                }
                break;
            case 'change':
                var value = this._adapter.getSelectedValue(targetElement);
                if (targetElement === this._adapter.monthSelectElement) {
                    this._selectedMonth = value;
                }
                else if (targetElement === this._adapter.yearSelectElement) {
                    this._selectedYear = value;
                }
                break;
        }
        this._adapter.renderCalendar(this._selectedMonth, this._selectedYear, this._selectedDate, this._min, this._max);
    };
    DatepickerFoundation.prototype._calendarBodyInteraction = function (event) {
        var targetElement = event.target;
        if (targetElement.tagName !== 'BUTTON' || targetElement.disabled) {
            return;
        }
        switch (event.type) {
            case 'click':
                var value = this._adapter.getValue(targetElement);
                if (this._adapter.hasAttribute(DATEPICKER_CONSTANTS.attributes.PREVIOUS_MONTH, targetElement)) {
                    if (this._selectedMonth === 0) {
                        this._selectedMonth = 11;
                        this._selectedYear--;
                    }
                    else {
                        this._selectedMonth--;
                    }
                }
                else if (this._adapter.hasAttribute(DATEPICKER_CONSTANTS.attributes.NEXT_MONTH, targetElement)) {
                    if (this._selectedMonth === 11) {
                        this._selectedMonth = 0;
                        this._selectedYear++;
                    }
                    else {
                        this._selectedMonth++;
                    }
                }
                var inputValue = this._formatInputValue(new Date(this._selectedYear, this._selectedMonth, value));
                if (inputValue) {
                    this._adapter.inputElement.value = inputValue;
                }
                this._closeCalendar(true);
                this._adapter.dispatchEvent('input', this._adapter.inputElement);
                this._adapter.dispatchEvent('change', this._adapter.inputElement);
        }
    };
    DatepickerFoundation.prototype._formatInputValue = function (date) {
        if (!isValidDate(date)) {
            return;
        }
        return padLeft((date.getMonth() + 1).toString(), 2, '0') + '/' + padLeft(date.getDate().toString(), 2, '0') + '/' + date.getFullYear();
    };
    DatepickerFoundation.prototype._parseInputValue = function (value) {
        if (!value || !value.length) {
            return new Date(NaN);
        }
        var values = [];
        if (value.indexOf('/') !== -1) {
            values = value.split('/');
        }
        else if (value.indexOf('-') !== -1) {
            values = value.split('-');
        }
        else if ((value.length === 6 || value.length === 8) && !isNaN(+value)) {
            values = [value.substring(0, 2), value.substring(2, 4), value.substring(4)];
        }
        if (values.length !== 3 || (values[0].length !== 1 && values[0].length !== 2) || (values[1].length !== 1 && values[1].length !== 2) || (values[2].length !== 2 && values[2].length !== 4)) {
            return new Date(NaN);
        }
        if (values[2].length === 2) {
            if (+values[2] < 69) {
                values[2] = "20" + values[2];
            }
            else {
                values[2] = "19" + values[2];
            }
        }
        var date = new Date(+values[2], +values[0] - 1, +values[1]);
        return isValidDate(date) ? date : new Date(NaN);
    };
    return DatepickerFoundation;
}());
export { DatepickerFoundation };
