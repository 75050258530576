import { BUTTON_TOGGLE_CONSTANTS } from './button-toggle-constants';
var ButtonToggleFoundation = /** @class */ (function () {
    function ButtonToggleFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._selected = false;
        this._disabled = false;
        this._dense = false;
        this._clickListener = function (evt) { return _this._onClick(evt); };
    }
    ButtonToggleFoundation.prototype.initialize = function () {
        this._adapter.initializeRipple();
        this._adapter.addEventListener('click', this._clickListener);
        this._adapter.setSelected(this._selected);
        this._adapter.setDisabled(this._disabled);
        this._adapter.setDense(this._dense);
    };
    ButtonToggleFoundation.prototype.disconnect = function () {
        if (this._rippleInstance) {
            this._rippleInstance.destroy();
        }
        this._adapter.removeEventListener('click', this._clickListener);
    };
    ButtonToggleFoundation.prototype._onClick = function (evt) {
        this._selected = !this._selected;
        this._updateSelectedState();
        this._adapter.emitEvent(BUTTON_TOGGLE_CONSTANTS.events.SELECT, { value: this._value, selected: this._selected });
    };
    ButtonToggleFoundation.prototype._updateSelectedState = function () {
        this._adapter.setSelected(this._selected);
        this._adapter.setHostAttribute(BUTTON_TOGGLE_CONSTANTS.attributes.SELECTED, this._selected.toString());
    };
    Object.defineProperty(ButtonToggleFoundation.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (value) {
            this._value = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleFoundation.prototype, "selected", {
        get: function () {
            return this._selected;
        },
        set: function (value) {
            if (this._selected !== value) {
                this._selected = value;
                this._updateSelectedState();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleFoundation.prototype, "disabled", {
        get: function () {
            return this._selected;
        },
        set: function (value) {
            if (this._disabled !== value) {
                this._disabled = value;
                this._adapter.setDisabled(this._disabled);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonToggleFoundation.prototype, "dense", {
        get: function () {
            return this._dense;
        },
        set: function (value) {
            if (this._dense !== value) {
                this._dense = value;
                this._adapter.setDense(this._dense);
            }
        },
        enumerable: true,
        configurable: true
    });
    ButtonToggleFoundation.prototype.setFocus = function () {
        this._adapter.requestFocus();
    };
    return ButtonToggleFoundation;
}());
export { ButtonToggleFoundation };
