import { matchesSelectors } from 'shadow-utils';
import { LIST_ITEM_CONSTANTS } from './list-item-constants';
/**
 * The foundation class behind the `<tcw-list-item>` component.
 */
var ListItemFoundation = /** @class */ (function () {
    function ListItemFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._ripple = true;
        this._static = false;
        this._twoLine = false;
        this._threeLine = false;
        this._active = false;
        this._selected = false;
        this._disabled = false;
        this._dense = false;
        this._propagateClick = true;
        this._indented = false;
        this._clickListener = function (evt) { return _this._onClick(evt); };
        this._mouseDownListener = function (evt) { return _this._onMouseDown(evt); };
    }
    ListItemFoundation.prototype.initialize = function () {
        this._setRipple();
        this._adapter.setStatic(this._static);
        this._adapter.setDense(this._dense);
        this._adapter.setDisabled(this._disabled);
        this._adapter.setIndented(this._indented);
        if (!this._static) {
            this._adapter.addListener('click', this._clickListener);
            this._adapter.addListener('mousedown', this._mouseDownListener, { passive: false, capture: true });
        }
        if (this._threeLine) {
            this._adapter.setThreeLine(this._threeLine);
        }
        else if (this.twoLine) {
            this._adapter.setTwoLine(this._twoLine);
        }
    };
    ListItemFoundation.prototype.disconnect = function () {
        if (this._rippleInstance) {
            this._rippleInstance.destroy();
            this._rippleInstance = undefined;
        }
    };
    ListItemFoundation.prototype._onMouseDown = function (evt) {
        if (!this._propagateClick) {
            evt.preventDefault();
        }
    };
    /**
     * Handles clicking a list item.
     * @param evt
     */
    ListItemFoundation.prototype._onClick = function (evt) {
        if (this._static || this._disabled) {
            return;
        }
        if (this._href) {
            if (this._target) {
                window.open(this._href, this._target);
            }
            else {
                document.location.href = this._href;
            }
            return;
        }
        // If the target was not a checkbox or radio button, attempt to find one and toggle its checked state
        if (!matchesSelectors(evt.target, LIST_ITEM_CONSTANTS.selectors.CHECKBOX_RADIO_SELECTOR)) {
            this._adapter.tryToggleCheckboxRadio();
        }
        var data = {
            value: this._value,
            listItem: this._adapter.getListItem()
        };
        this._adapter.emitEvent(LIST_ITEM_CONSTANTS.events.SELECTED, data);
    };
    Object.defineProperty(ListItemFoundation.prototype, "ripple", {
        /** Gets/sets whether the list item has a ripple or not. */
        get: function () {
            return this._ripple;
        },
        set: function (value) {
            if (this._ripple !== value) {
                // We don't attach ripples to static items
                if (value && this._static) {
                    return;
                }
                this._ripple = value;
                this._setRipple();
                if (this._ripple) {
                    this._adapter.removeHostAttribute(LIST_ITEM_CONSTANTS.attributes.NO_RIPPLE);
                }
                else {
                    this._adapter.setHostAttribute(LIST_ITEM_CONSTANTS.attributes.NO_RIPPLE);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemFoundation.prototype, "static", {
        /** Gets/sets whether the static state of this list item. */
        get: function () {
            return this._static;
        },
        set: function (value) {
            if (this._static !== value) {
                this._static = value;
                this._adapter.setStatic(this._static);
                // Ensure we either add or remove the ripple
                this._setRipple();
                // Toggle the click listener
                if (this._static) {
                    this._adapter.removeListener('click', this._clickListener);
                }
                else {
                    this._adapter.addListener('click', this._clickListener);
                }
                if (this._static) {
                    this._adapter.setHostAttribute(LIST_ITEM_CONSTANTS.attributes.STATIC);
                }
                else {
                    this._adapter.removeHostAttribute(LIST_ITEM_CONSTANTS.attributes.STATIC);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemFoundation.prototype, "twoLine", {
        /** Gets/sets whether the list item displays two lines of text. */
        get: function () {
            return this._twoLine;
        },
        set: function (value) {
            if (this._twoLine !== value) {
                this._twoLine = value;
                this._adapter.setTwoLine(this._twoLine);
                if (this._twoLine) {
                    this._adapter.setHostAttribute(LIST_ITEM_CONSTANTS.attributes.TWO_LINE);
                }
                else {
                    this._adapter.removeHostAttribute(LIST_ITEM_CONSTANTS.attributes.TWO_LINE);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemFoundation.prototype, "threeLine", {
        /** Gets/sets whether the list item displays two lines of text. */
        get: function () {
            return this._threeLine;
        },
        set: function (value) {
            if (this._threeLine !== value) {
                this._threeLine = value;
                if (this._threeLine) {
                    this._adapter.setHostAttribute(LIST_ITEM_CONSTANTS.attributes.THREE_LINE);
                }
                else {
                    this._adapter.removeHostAttribute(LIST_ITEM_CONSTANTS.attributes.THREE_LINE);
                }
                if (this._threeLine) {
                    this._twoLine = false;
                }
                this._adapter.setTwoLine(this._twoLine);
                this._adapter.setThreeLine(this._threeLine);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemFoundation.prototype, "active", {
        /** Gets/sets whether the list item is active or not. */
        get: function () {
            return this._active;
        },
        set: function (value) {
            if (this._active !== value) {
                this._active = value;
                this._adapter.setActive(this._active);
                if (this._active) {
                    this._adapter.setHostAttribute(LIST_ITEM_CONSTANTS.attributes.ACTIVE);
                }
                else {
                    this._adapter.removeHostAttribute(LIST_ITEM_CONSTANTS.attributes.ACTIVE);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemFoundation.prototype, "selected", {
        /** Gets/sets whether the list item is selected or not. */
        get: function () {
            return this._selected;
        },
        set: function (value) {
            if (this._selected !== value) {
                this._selected = value;
                this._adapter.setSelected(this._selected);
                this._adapter.tryToggleCheckboxRadio();
                if (this._selected) {
                    this._adapter.setHostAttribute(LIST_ITEM_CONSTANTS.attributes.SELECTED);
                }
                else {
                    this._adapter.removeHostAttribute(LIST_ITEM_CONSTANTS.attributes.SELECTED);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemFoundation.prototype, "value", {
        /** Gets/sets the unique value for this list item. */
        get: function () {
            return this._value;
        },
        set: function (value) {
            this._value = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemFoundation.prototype, "href", {
        /** Gets/sets the href link that this list item will send the browser to when clicked. */
        get: function () {
            return this._href;
        },
        set: function (value) {
            if (this._href !== value) {
                this._href = value;
                if (this._href) {
                    this._adapter.setRole(LIST_ITEM_CONSTANTS.roles.LINK);
                }
                else {
                    this._adapter.setRole(LIST_ITEM_CONSTANTS.roles.LIST_ITEM);
                }
                this._adapter.setHostAttribute(LIST_ITEM_CONSTANTS.attributes.HREF, this._href);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemFoundation.prototype, "target", {
        /** Gets/sets the href link target. */
        get: function () {
            return this._target;
        },
        set: function (value) {
            this._target = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemFoundation.prototype, "disabled", {
        /** Gets/sets the disabled state. */
        get: function () {
            return this._disabled;
        },
        set: function (value) {
            if (this._disabled !== value) {
                this._disabled = value;
                this._adapter.setDisabled(this._disabled);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemFoundation.prototype, "dense", {
        /** Gets/sets the dense state. */
        get: function () {
            return this._dense;
        },
        set: function (value) {
            if (this._dense !== value) {
                this._dense = value;
                this._adapter.setDense(this._dense);
                if (this._dense) {
                    this._adapter.setHostAttribute(LIST_ITEM_CONSTANTS.attributes.DENSE);
                }
                else {
                    this._adapter.removeHostAttribute(LIST_ITEM_CONSTANTS.attributes.DENSE);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemFoundation.prototype, "propagateClick", {
        /** Gets/sets the dense state. */
        get: function () {
            return this._propagateClick;
        },
        set: function (value) {
            if (this._propagateClick !== value) {
                this._propagateClick = value;
                this._adapter.setHostAttribute(LIST_ITEM_CONSTANTS.attributes.PROPAGATE_CLICK, '' + !!this._propagateClick);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItemFoundation.prototype, "indented", {
        get: function () {
            return this._indented;
        },
        set: function (value) {
            if (this._indented !== value) {
                this._indented = value;
                this._adapter.setIndented(this._indented);
                if (this._indented) {
                    this._adapter.setHostAttribute(LIST_ITEM_CONSTANTS.attributes.INDENTED);
                }
                else {
                    this._adapter.removeHostAttribute(LIST_ITEM_CONSTANTS.attributes.INDENTED);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    ListItemFoundation.prototype._setRipple = function () {
        if (this._ripple && !this._static && !this._rippleInstance) {
            this._rippleInstance = this._adapter.createRipple();
        }
        else if ((!this._ripple || this._static) && this._rippleInstance) {
            this._rippleInstance.destroy();
            this._rippleInstance = undefined;
        }
    };
    /**
     * Sets focus to this list item.
     */
    ListItemFoundation.prototype.setFocus = function () {
        this._adapter.setFocus();
    };
    return ListItemFoundation;
}());
export { ListItemFoundation };
