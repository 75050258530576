import { __spreadArrays } from "tslib";
import { MDCRipple } from '@material/ripple';
import { addClass, removeElement, emitEvent, getLightElement } from '@tyler-components-web/core';
import { CHIP_CONSTANTS } from './chip-constants';
import { ICON_CLASS_NAME } from '../../constants';
/**
 * The DOM adapter for the chip component.
 */
var ChipAdapter = /** @class */ (function () {
    function ChipAdapter(_component) {
        this._component = _component;
        this._chipElement = getLightElement(_component, CHIP_CONSTANTS.selectors.CHIP);
        this._textElement = getLightElement(_component, CHIP_CONSTANTS.selectors.TEXT);
        this._checkmarkElement = getLightElement(_component, CHIP_CONSTANTS.selectors.CHECKMARK);
    }
    /**
     * Sets an attribute on the host element.
     * @param {string} name The name of the attribute.
     * @param {string} value The value for the attribute.
     */
    ChipAdapter.prototype.setHostAttribute = function (name, value) {
        if (this._component.getAttribute(name) !== value) {
            this._component.setAttribute(name, value);
        }
    };
    /**
     * Removes an attribute from the host element.
     * @param {string} name The attribute name.
     */
    ChipAdapter.prototype.removeHostAttribute = function (name) {
        this._component.removeAttribute(name);
    };
    /**
     * Emits an event from the host element.
     * @param {string} type The event type.
     * @param {object} [data={}] The event data.
     * @param {boolean} [bubble=true] Whether the event bubbles or not.
     */
    ChipAdapter.prototype.emitEvent = function (type, data, bubble) {
        if (data === void 0) { data = {}; }
        if (bubble === void 0) { bubble = true; }
        emitEvent(this._component, type, data, bubble);
    };
    /**
     * Sets the text of the text element.
     * @param {string} value The text value.
     */
    ChipAdapter.prototype.setText = function (value) {
        this._textElement.innerText = value;
    };
    /**
     * Sets the leading icon.
     * @param {string} icon The icon name.
     */
    ChipAdapter.prototype.setLeadingIcon = function (icon) {
        var leadingIconElement = getLightElement(this._component, CHIP_CONSTANTS.selectors.LEADING_ICON);
        if (leadingIconElement) {
            leadingIconElement.innerText = icon;
        }
        else {
            this._chipElement.insertAdjacentElement('afterbegin', this._createIconElement(icon, [CHIP_CONSTANTS.classes.LEADING_ICON]));
        }
    };
    /**
     * Updates the leading icon and checkmark based on the filtered state.
     * @param {boolean} value The filtered state.
     */
    ChipAdapter.prototype.setFiltered = function (value) {
        var leadingIconElement = getLightElement(this._component, CHIP_CONSTANTS.selectors.LEADING_ICON);
        if (leadingIconElement) {
            if (value) {
                leadingIconElement.classList.add(CHIP_CONSTANTS.classes.LEADING_ICON_HIDDEN);
            }
            else {
                leadingIconElement.classList.remove(CHIP_CONSTANTS.classes.LEADING_ICON_HIDDEN);
            }
        }
    };
    /**
     * Sets the visibility of the delete icon button.
     * @param {boolean} visible The visibility value.
     */
    ChipAdapter.prototype.setDeleteVisibility = function (visible) {
        var deleteIconElement = getLightElement(this._component, CHIP_CONSTANTS.selectors.TRAILING_ICON);
        if (visible) {
            if (deleteIconElement) {
                deleteIconElement.innerText = 'cancel';
            }
            else {
                deleteIconElement = this._createIconElement('cancel', [CHIP_CONSTANTS.classes.TRAILING_ICON]);
                deleteIconElement.tabIndex = 0;
                deleteIconElement.setAttribute('role', 'button');
                this._chipElement.insertAdjacentElement('beforeend', deleteIconElement);
            }
        }
        else if (deleteIconElement) {
            removeElement(deleteIconElement);
        }
    };
    /**
     * Sets an event listener of the delete button icon.
     * @param {string} type The event type.
     * @param {Function} listener The event listener.
     */
    ChipAdapter.prototype.setDeleteListener = function (type, listener) {
        var deleteIconElement = getLightElement(this._component, CHIP_CONSTANTS.selectors.TRAILING_ICON);
        if (!deleteIconElement) {
            throw new Error('Missing delete icon element.');
        }
        deleteIconElement.addEventListener(type, listener);
    };
    /**
     * Removes an event listener from the delete button icon.
     * @param {string} type The event type.
     * @param {Function} listener The event listener.
     */
    ChipAdapter.prototype.removeDeleteListener = function (type, listener) {
        var deleteIconElement = getLightElement(this._component, CHIP_CONSTANTS.selectors.TRAILING_ICON);
        if (deleteIconElement) {
            deleteIconElement.removeEventListener(type, listener);
        }
    };
    /**
     * Updates the DOM based on the selected state.
     * @param {boolean} value The selected state.
     */
    ChipAdapter.prototype.setSelected = function (value) {
        if (value) {
            this._chipElement.classList.add(CHIP_CONSTANTS.classes.CHIP_SELECTED);
        }
        else {
            this._chipElement.classList.remove(CHIP_CONSTANTS.classes.CHIP_SELECTED);
        }
    };
    /**
     * Updates the DOM based on the dense state.
     * @param {boolean} value The dense state.
     */
    ChipAdapter.prototype.setDense = function (value) {
        if (value) {
            this._chipElement.classList.add(CHIP_CONSTANTS.classes.CHIP_DENSE);
        }
        else {
            this._chipElement.classList.remove(CHIP_CONSTANTS.classes.CHIP_DENSE);
        }
    };
    /**
     * Sets the visibility of the checkmark element.
     * @param {boolean} visible The checkmark visibility.
     */
    ChipAdapter.prototype.toggleCheckmark = function (visible) {
        if (visible) {
            this._checkmarkElement.style.removeProperty('display');
        }
        else {
            this._checkmarkElement.style.setProperty('display', 'none');
        }
    };
    /**
     * Creates a `MDCRipple` instance on the chip element.
     */
    ChipAdapter.prototype.createRipple = function () {
        return new MDCRipple(this._chipElement);
    };
    /**
     * Sets a listener on the chip element.
     * @param {string} type The event type.
     * @param {Function} listener The event listener.
     */
    ChipAdapter.prototype.setChipListener = function (type, listener) {
        this._chipElement.addEventListener(type, listener);
    };
    ChipAdapter.prototype._createIconElement = function (icon, classes) {
        var leadingIconElement = document.createElement('i');
        addClass(__spreadArrays([
            ICON_CLASS_NAME
        ], classes), leadingIconElement);
        leadingIconElement.innerText = icon;
        return leadingIconElement;
    };
    return ChipAdapter;
}());
export { ChipAdapter };
