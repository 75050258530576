import { BADGE_CONSTANTS } from '../../badge';
import { getLightElement } from '@tyler-components-web/core';
var OmnibarNotificationButtonAdapter = /** @class */ (function () {
    function OmnibarNotificationButtonAdapter(_component) {
        this._component = _component;
    }
    OmnibarNotificationButtonAdapter.prototype.initialize = function () {
        this._badgeElement = getLightElement(this._component, BADGE_CONSTANTS.elementName);
    };
    OmnibarNotificationButtonAdapter.prototype.setHostAttribute = function (name, value) {
        this._component.setAttribute(name, value);
    };
    OmnibarNotificationButtonAdapter.prototype.setCount = function (value) {
        this._badgeElement.textContent = value;
    };
    OmnibarNotificationButtonAdapter.prototype.setBadgeType = function (dot) {
        this._badgeElement.dot = dot;
    };
    OmnibarNotificationButtonAdapter.prototype.setBadgeTheme = function (theme) {
        this._badgeElement.setAttribute(BADGE_CONSTANTS.attributes.THEME, theme);
    };
    OmnibarNotificationButtonAdapter.prototype.setBadgeVisible = function (isVisible) {
        this._badgeElement.open = isVisible;
    };
    return OmnibarNotificationButtonAdapter;
}());
export { OmnibarNotificationButtonAdapter };
