import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "paginator";
var classes = {
    LABEL: 'tyl-paginator__label',
    PAGE_SIZE_OPTIONS: 'tyl-paginator__page-size-options',
    RANGE_LABEL: 'tyl-paginator__range-label',
    FIRST_PAGE_BUTTON: 'tyl-paginator__first-page',
    PREVIOUS_PAGE_BUTTON: 'tyl-paginator__previous-page',
    NEXT_PAGE_BUTTON: 'tyl-paginator__next-page',
    LAST_PAGE_BUTTON: 'tyl-paginator__last-page'
};
var selectors = {
    LABEL: "." + classes.LABEL,
    PAGE_SIZE_SELECT: "." + classes.PAGE_SIZE_OPTIONS,
    RANGE_LABEL: "." + classes.RANGE_LABEL,
    FIRST_PAGE_BUTTON: "." + classes.FIRST_PAGE_BUTTON + " > button",
    FIRST_PAGE_ICON_BUTTON: "." + classes.FIRST_PAGE_BUTTON,
    PREVIOUS_PAGE_BUTTON: "." + classes.PREVIOUS_PAGE_BUTTON + " > button",
    NEXT_PAGE_BUTTON: "." + classes.NEXT_PAGE_BUTTON + " > button",
    LAST_PAGE_BUTTON: "." + classes.LAST_PAGE_BUTTON + " > button",
    LAST_PAGE_ICON_BUTTON: "." + classes.LAST_PAGE_BUTTON,
};
var attributes = {
    PAGE_INDEX: 'page-index',
    PAGE_SIZE: 'page-size',
    PAGE_SIZE_OPTIONS: 'page-size-options',
    TOTAL: 'total',
    LABEL: 'label',
    FIRST_LAST: 'first-last',
    FIRST: 'first',
    DISABLED: 'disabled'
};
var events = {
    CHANGE: 'change'
};
var numbers = {
    DEFAULT_PAGE_INDEX: 0,
    DEFAULT_TOTAL: 0,
    DEFAULT_PAGE_SIZE: 25,
    DEFAULT_PAGE_SIZE_OPTIONS: [5, 15, 25, 50, 100]
};
var strings = {
    DEFAULT_LABEL: 'Rows per page:',
    RANGE_SEPARATOR_LABEL: 'of',
    FIRST_PAGE: 'first-page',
    PREVIOUS_PAGE: 'previous-page',
    NEXT_PAGE: 'next-page',
    LAST_PAGE: 'last-page',
    PAGE_SIZE: 'page-size'
};
export var PAGINATOR_CONSTANTS = {
    elementName: elementName,
    classes: classes,
    selectors: selectors,
    attributes: attributes,
    events: events,
    numbers: numbers,
    strings: strings
};
