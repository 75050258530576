import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "stepper";
var classes = {
    STEPPER: 'tyl-stepper',
    STEPPER_LINEAR: 'tyl-stepper--linear',
    STEPPER_ALTERNATIVE: 'tyl-stepper--alternative',
    STEPPER_FIXED: 'tyl-stepper--fixed',
    STEPPER_STRETCHED: 'tyl-stepper--stretched',
    STEPPER_ALIGN_LEFT: 'tyl-stepper--align-left',
    STEPPER_ALIGN_CENTER: 'tyl-stepper--align-center',
    STEPPER_ALIGN_RIGHT: 'tyl-stepper--align-right',
    STEP: 'tyl-stepper__step',
    STEP_SELECTED: 'tyl-stepper__step--selected',
    STEP_COMPLETED: 'tyl-stepper__step--completed',
    STEP_ICON: 'tyl-stepper__step-icon',
    STEP_LABEL_CONTAINER: 'tyl-stepper__step-label-container',
    STEP_LABEL: 'tyl-stepper__step-label',
    STEP_OPTIONAL_LABEL: 'tyl-stepper__step-optional-label'
};
var selectors = {
    STEPPER: "." + classes.STEPPER
};
var attributes = {
    SELECTED_INDEX: 'selected-index',
    LINEAR: 'linear',
    ALTERNATIVE: 'alternative',
    LAYOUT_MODE: 'layout-mode',
    LAYOUT_ALIGN: 'layout-align'
};
var events = {
    SELECT: elementName + "-select"
};
var icons = {
    EDIT: 'mode_edit',
    CHECK: 'check'
};
export var STEPPER_CONSTANTS = {
    elementName: elementName,
    classes: classes,
    selectors: selectors,
    attributes: attributes,
    events: events,
    icons: icons
};
