import { COMPONENT_NAME_PREFIX } from '../constants';
import { PopupPlacement } from '../popup';
var elementName = COMPONENT_NAME_PREFIX + "toast";
var classes = {
    CONTAINER: 'tyl-toast__container',
    MESSAGE: 'tyl-toast__message',
    ACTION_BUTTON: 'tyl-toast__action-button',
    CLOSE_BUTTON: 'tyl-toast__close-button',
    TOP: 'tyl-toast__container--top',
    TOP_LEFT: 'tyl-toast__container--top-left',
    TOP_RIGHT: 'tyl-toast__container--top-right',
    BOTTOM: 'tyl-toast__container--bottom',
    BOTTOM_LEFT: 'tyl-toast__container--bottom-left',
    BOTTOM_RIGHT: 'tyl-toast__container--bottom-right',
    ACTIVE: 'tyl-toast__container--active',
    CUSTOM: 'tyl-toast__container--custom'
};
var selectors = {
    CONTAINER: "." + classes.CONTAINER,
    MESSAGE: "." + classes.MESSAGE,
    ACTION_BUTTON: "." + classes.ACTION_BUTTON,
    CLOSE_BUTTON: "." + classes.CLOSE_BUTTON
};
var attributes = {
    MESSAGE: 'message',
    ACTION_TEXT: 'action-text',
    DURATION: 'duration',
    PLACEMENT: 'placement',
    SHOW_CLOSE: 'show-close'
};
var events = {
    ACTION: elementName + "-action",
    CLOSE: elementName + "-close"
};
var defaults = {
    PLACEMENT: PopupPlacement.Bottom,
    DURATION: 2750
};
export var TOAST_CONSTANTS = {
    elementName: elementName,
    classes: classes,
    selectors: selectors,
    attributes: attributes,
    defaults: defaults,
    events: events
};
