import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty, ensureChildren } from '@tyler-components-web/core';
import { TextFieldAdapter } from './text-field-adapter';
import { TextFieldFoundation } from './text-field-foundation';
import { TEXT_FIELD_CONSTANTS } from './text-field-constants';
var template = require('./text-field.html');
var styles = require('./text-field.css');
var TextFieldComponent = /** @class */ (function (_super) {
    __extends(TextFieldComponent, _super);
    function TextFieldComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, TEXT_FIELD_CONSTANTS.elementName, template, styles);
        _this._foundation = new TextFieldFoundation(new TextFieldAdapter(_this));
        return _this;
    }
    Object.defineProperty(TextFieldComponent, "observedAttributes", {
        get: function () {
            return [
                TEXT_FIELD_CONSTANTS.attributes.DENSITY,
                TEXT_FIELD_CONSTANTS.attributes.FLOAT_LABEL_TYPE,
                TEXT_FIELD_CONSTANTS.attributes.SHAPE,
                TEXT_FIELD_CONSTANTS.attributes.INVALID,
                TEXT_FIELD_CONSTANTS.attributes.REQUIRED
            ];
        },
        enumerable: true,
        configurable: true
    });
    TextFieldComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'density');
        upgradeProperty(this, 'floatLabelType');
        upgradeProperty(this, 'shape');
        upgradeProperty(this, 'invalid');
        upgradeProperty(this, 'required');
    };
    TextFieldComponent.prototype.connectedCallback = function () {
        var _this = this;
        if (this.children.length) {
            this._initialize();
        }
        else {
            ensureChildren(this).then(function () { return _this._initialize(); });
        }
    };
    TextFieldComponent.prototype._initialize = function () {
        this._foundation.initialize();
    };
    TextFieldComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    TextFieldComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case TEXT_FIELD_CONSTANTS.attributes.DENSITY:
                this.density = newValue;
                break;
            case TEXT_FIELD_CONSTANTS.attributes.FLOAT_LABEL_TYPE:
                this.floatLabelType = newValue;
                break;
            case TEXT_FIELD_CONSTANTS.attributes.SHAPE:
                this.shape = newValue;
                break;
            case TEXT_FIELD_CONSTANTS.attributes.INVALID:
                this.invalid = this.hasAttribute(TEXT_FIELD_CONSTANTS.attributes.INVALID);
                break;
            case TEXT_FIELD_CONSTANTS.attributes.REQUIRED:
                this.required = this.hasAttribute(TEXT_FIELD_CONSTANTS.attributes.REQUIRED);
                break;
        }
    };
    /**
     * Controls whether the label should be floating or not.
     * @param {boolean} value
     */
    TextFieldComponent.prototype.floatLabel = function (value) {
        this._foundation.floatLabel(value);
    };
    Object.defineProperty(TextFieldComponent.prototype, "density", {
        /** Controls the density type. */
        get: function () {
            return this._foundation.density;
        },
        set: function (value) {
            this._foundation.density = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextFieldComponent.prototype, "floatLabelType", {
        /** Whether the label should always float, never float or float as the user types. */
        get: function () {
            return this._foundation.floatLabelType;
        },
        set: function (value) {
            this._foundation.floatLabelType = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextFieldComponent.prototype, "shape", {
        /** The shape type to use. */
        get: function () {
            return this._foundation.shape;
        },
        set: function (value) {
            this._foundation.shape = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextFieldComponent.prototype, "invalid", {
        /** Gets/sets the invalid state. */
        get: function () {
            return this._foundation.invalid;
        },
        set: function (value) {
            this._foundation.invalid = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextFieldComponent.prototype, "required", {
        /** Gets/sets the required state which controls the visibility of the asterisk in the label. */
        get: function () {
            return this._foundation.required;
        },
        set: function (value) {
            this._foundation.required = value;
        },
        enumerable: true,
        configurable: true
    });
    TextFieldComponent = __decorate([
        CustomElement({
            name: TEXT_FIELD_CONSTANTS.elementName
        })
    ], TextFieldComponent);
    return TextFieldComponent;
}(HTMLElement));
export { TextFieldComponent };
