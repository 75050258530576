import { getShadowElement, addClass, removeClass, generateTextShadow, Platform } from '@tyler-components-web/core';
import { PRODUCT_ICON_CONSTANTS } from './product-icon-constants';
var ProductIconAdapter = /** @class */ (function () {
    function ProductIconAdapter(_component) {
        this._component = _component;
        this._backgroundElement = getShadowElement(_component, PRODUCT_ICON_CONSTANTS.selectors.PRODUCT_ICON_BACKGROUND);
        this._iconElement = getShadowElement(_component, PRODUCT_ICON_CONSTANTS.selectors.PRODUCT_ICON);
    }
    ProductIconAdapter.prototype.setHostAttribute = function (name, value) {
        if (value === void 0) { value = ''; }
        this._component.setAttribute(name, value);
    };
    ProductIconAdapter.prototype.setBackgroundColor = function (color) {
        this._backgroundElement.style.backgroundColor = color;
    };
    ProductIconAdapter.prototype.setIconColor = function (color) {
        this._iconElement.style.color = color;
    };
    ProductIconAdapter.prototype.setElementSize = function (value) {
        this._backgroundElement.style.height = value + "px";
        this._backgroundElement.style.width = value + "px";
    };
    ProductIconAdapter.prototype.setFontSize = function (value) {
        if (Platform.TRIDENT) {
            this._iconElement.style.fontSize = value + "px";
        }
        else {
            this._iconElement.style.fontSize = "var(--tyl-product-icon-font-size, " + value + "px)";
        }
    };
    ProductIconAdapter.prototype.setIconClass = function (classes) {
        addClass(classes, this._iconElement);
    };
    ProductIconAdapter.prototype.removeIconClass = function (classes) {
        removeClass(classes, this._iconElement);
    };
    ProductIconAdapter.prototype.setTextShadow = function (hasShadow, iterations, shadowColor) {
        if (hasShadow) {
            this._iconElement.style.textShadow = generateTextShadow(iterations, shadowColor);
        }
        else {
            this._iconElement.style.removeProperty('text-shadow');
        }
    };
    return ProductIconAdapter;
}());
export { ProductIconAdapter };
