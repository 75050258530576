import { BACKDROP_CONSTANTS } from './backdrop-constants';
var BackdropFoundation = /** @class */ (function () {
    function BackdropFoundation(_adapter) {
        this._adapter = _adapter;
        this._delay = BACKDROP_CONSTANTS.numbers.DELAY;
        this._maxOpacity = BACKDROP_CONSTANTS.numbers.OPACITY;
        this._adapter.addBackdropEventListener('click', this._onClick.bind(this));
    }
    BackdropFoundation.prototype.initialize = function () {
        this._adapter.setBackdropOpacity(0);
        this._adapter.setHostAttribute(BACKDROP_CONSTANTS.attributes.DELAY, this._delay.toString());
        this.fadeIn();
    };
    /**
     * Starts the fade-out animation to animate the backdrop to 0% opacity.
     */
    BackdropFoundation.prototype.fadeOut = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this._adapter.setBackdropOpacity(0);
            setTimeout(function () { return resolve(); }, BACKDROP_CONSTANTS.numbers.TRANSITION_DURATION);
        });
    };
    /**
     * Starts the fade-in animation to animate the backdrop to max opacity.
     */
    BackdropFoundation.prototype.fadeIn = function () {
        var _this = this;
        return new Promise(function (resolve) {
            setTimeout(function () {
                _this._adapter.setBackdropOpacity(_this._maxOpacity);
                setTimeout(function () { return resolve(); }, BACKDROP_CONSTANTS.numbers.TRANSITION_DURATION);
            }, _this.delay);
        });
    };
    BackdropFoundation.prototype._onClick = function (evt) {
        evt.stopPropagation();
        this._adapter.emitHostEvent(BACKDROP_CONSTANTS.events.BACKDROP_CLICK);
    };
    BackdropFoundation.prototype.disconnect = function () {
        this._adapter.removeBackdropEventListener('click', this._onClick);
    };
    Object.defineProperty(BackdropFoundation.prototype, "delay", {
        get: function () {
            return this._delay;
        },
        /**
         * The time in milliseconds to delay the fade in animation of the opacity.
         */
        set: function (value) {
            if (this._delay !== value) {
                this._delay = value;
                this._adapter.setHostAttribute(BACKDROP_CONSTANTS.attributes.DELAY, this._delay.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BackdropFoundation.prototype, "maxOpacity", {
        get: function () {
            return this._maxOpacity;
        },
        /**
         * The max opacity to fade the element to. This defines how opaque the backdrop is when visible.
         */
        set: function (value) {
            if (this._maxOpacity !== value) {
                this._maxOpacity = value;
                this._adapter.setHostAttribute(BACKDROP_CONSTANTS.attributes.MAX_OPACITY, this._maxOpacity.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    return BackdropFoundation;
}());
export { BackdropFoundation };
