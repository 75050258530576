import { BUSY_INDICATOR_CONSTANTS } from './busy-indicator-constants';
/**
 * Provides facilities for creating and managing busy indicator component instance.
 */
var BusyIndicatorController = /** @class */ (function () {
    function BusyIndicatorController() {
    }
    /**
     * Creates a new busy indicator component instance in the specified parent (or the body if not specified).
     * If a busy indicator is already present in the parent, then that instance is returned.
     * @param {HTMLElement} [parent=document.body] The element to append the busy indicator to.
     * @returns {IBusyIndicatorComponent} The busy indicator component isntance.
     */
    BusyIndicatorController.show = function (config, parent) {
        if (parent === void 0) { parent = document.body; }
        var existingInstance = parent.querySelector(BUSY_INDICATOR_CONSTANTS.elementName);
        if (existingInstance) {
            return existingInstance;
        }
        var el = document.createElement(BUSY_INDICATOR_CONSTANTS.elementName);
        Object.assign(el, config);
        parent.appendChild(el);
        return el;
    };
    /**
     * Hides the specified message (or the whole busy indicator elements itself).
     * @param {string | number | null} messageId The message to hide. Pass null to hide the default message.
     * @param {boolean} [force=false] True if the busy indicator should be completely removed from the DOM.
     * @param {HTMLELement} [parent=document.body] The parent element containing the busy indicator. The same parent element should be provided here that was used when calling `show`.
     */
    BusyIndicatorController.hide = function (messageId, force, parent) {
        if (force === void 0) { force = false; }
        if (parent === void 0) { parent = document.body; }
        var existingInstance = parent.querySelector(BUSY_INDICATOR_CONSTANTS.elementName);
        if (existingInstance) {
            existingInstance.hide(messageId, force);
        }
    };
    return BusyIndicatorController;
}());
export { BusyIndicatorController };
