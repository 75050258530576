import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty, coerceBoolean } from '@tyler-components-web/core';
import { IconButtonComponent } from '../icon-button';
import { ColorPickerAdapter } from './color-picker-adapter';
import { ColorPickerFoundation } from './color-picker-foundation';
import { COLOR_PICKER_CONSTANTS } from './color-picker-constants';
import { TooltipComponent } from '../tooltip';
var template = require('./color-picker.html');
var styles = require('./color-picker.css');
var ColorPickerComponent = /** @class */ (function (_super) {
    __extends(ColorPickerComponent, _super);
    function ColorPickerComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, COLOR_PICKER_CONSTANTS.elementName, template, styles);
        _this._foundation = new ColorPickerFoundation(new ColorPickerAdapter(_this));
        return _this;
    }
    Object.defineProperty(ColorPickerComponent, "observedAttributes", {
        get: function () {
            return [
                COLOR_PICKER_CONSTANTS.attributes.VALUE,
                COLOR_PICKER_CONSTANTS.attributes.ALLOW_OPACITY
            ];
        },
        enumerable: true,
        configurable: true
    });
    ColorPickerComponent.prototype.connectedCallback = function () {
        upgradeProperty(this, 'value');
        upgradeProperty(this, 'opacity');
        upgradeProperty(this, 'allowOpacity');
        this._foundation.initialize();
    };
    ColorPickerComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    ColorPickerComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case COLOR_PICKER_CONSTANTS.attributes.VALUE:
                this.value = newValue;
                break;
            case COLOR_PICKER_CONSTANTS.attributes.ALLOW_OPACITY:
                this.allowOpacity = coerceBoolean(newValue);
                break;
        }
    };
    Object.defineProperty(ColorPickerComponent.prototype, "value", {
        /** Gets/sets the value using hex format only. */
        get: function () {
            return this._foundation.value;
        },
        set: function (value) {
            this._foundation.value = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorPickerComponent.prototype, "rgba", {
        /** Gets/sets the value using rgba format. */
        get: function () {
            return this._foundation.rgba;
        },
        set: function (value) {
            this._foundation.rgba = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorPickerComponent.prototype, "hsva", {
        /** Gets/sets the value using hsva format. */
        get: function () {
            return this._foundation.hsva;
        },
        set: function (value) {
            this._foundation.hsva = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorPickerComponent.prototype, "opacity", {
        /** Gets/sets the opacity value, if `allowOpacity` is true. */
        get: function () {
            return this._foundation.opacity;
        },
        set: function (value) {
            this._foundation.opacity = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorPickerComponent.prototype, "allowOpacity", {
        /** Gets/sets whether opacity is displayed and allowed be to changed. */
        get: function () {
            return this._foundation.allowOpacity;
        },
        set: function (value) {
            this._foundation.allowOpacity = value;
        },
        enumerable: true,
        configurable: true
    });
    ColorPickerComponent = __decorate([
        CustomElement({
            name: COLOR_PICKER_CONSTANTS.elementName,
            dependencies: [
                IconButtonComponent,
                TooltipComponent
            ]
        })
    ], ColorPickerComponent);
    return ColorPickerComponent;
}(HTMLElement));
export { ColorPickerComponent };
