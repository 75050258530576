import { isFunction } from '@tyler-components-web/core';
import { TOAST_CONSTANTS } from './toast-constants';
/**
 * The foundation class behind the `ToastComponent` class that manages the state of a toast component instance.
 */
var ToastFoundation = /** @class */ (function () {
    function ToastFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._duration = TOAST_CONSTANTS.defaults.DURATION;
        this._placement = TOAST_CONSTANTS.defaults.PLACEMENT;
        this._showClose = true;
        this._hasAction = false;
        this._isActive = false;
        this._actionListener = function (evt) { return _this._onAction(evt); };
        this._closeListener = function (evt) { return _this._onClose(evt); };
    }
    ToastFoundation.prototype.initialize = function () {
        this._adapter.setPlacement(this._placement);
        this._adapter.registerCloseListener(this._closeListener);
        this._adapter.setCloseButtonVisibility(this._showClose);
        this._adapter.setActionVisibility(!!this._actionText);
        this.show();
    };
    Object.defineProperty(ToastFoundation.prototype, "message", {
        /** The message to display in the toast. */
        get: function () {
            return this._message;
        },
        set: function (value) {
            if (this._message !== value) {
                this._message = value;
                this._adapter.setMessage(this._message);
                this._adapter.setHostAttribute(TOAST_CONSTANTS.attributes.MESSAGE, this._message);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToastFoundation.prototype, "actionText", {
        /** The text to display in the action button. */
        get: function () {
            return this._actionText;
        },
        set: function (value) {
            if (this._actionText !== value) {
                this._actionText = value;
                if (this._actionText) {
                    if (this._hasAction) {
                        this._adapter.setActionText(this._actionText);
                    }
                    else {
                        this._adapter.setActionText(this._actionText);
                        this._adapter.setActionVisibility(true);
                        this._adapter.registerActionListener('click', this._actionListener);
                        this._hasAction = true;
                    }
                }
                else if (this._hasAction) {
                    this._adapter.setActionText('');
                    this._adapter.setActionVisibility(false);
                    this._adapter.deregisterActionListener('click', this._actionListener);
                    this._hasAction = false;
                }
                this._adapter.setHostAttribute(TOAST_CONSTANTS.attributes.ACTION_TEXT, this._actionText);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToastFoundation.prototype, "duration", {
        /** The time in milliseconds to show the toast. */
        get: function () {
            return this._duration;
        },
        set: function (value) {
            var _this = this;
            if (this._duration !== value) {
                this._duration = value;
                if (this._hideTimeout) {
                    clearTimeout(this._hideTimeout);
                    this._hideTimeout = setTimeout(function () { return _this.hide(true); }, this._duration);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToastFoundation.prototype, "placement", {
        /** The placement of the toast. */
        get: function () {
            return this._placement;
        },
        set: function (value) {
            if (this._placement !== value) {
                this._placement = value;
                this._adapter.setPlacement(this._placement);
            }
            this._adapter.setHostAttribute(TOAST_CONSTANTS.attributes.PLACEMENT, this._placement);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToastFoundation.prototype, "builder", {
        set: function (value) {
            this._builder = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToastFoundation.prototype, "showClose", {
        get: function () {
            return this._showClose;
        },
        set: function (value) {
            if (this._showClose !== value) {
                this._showClose = value;
                this._adapter.setCloseButtonVisibility(this._showClose);
                this._adapter.setHostAttribute(TOAST_CONSTANTS.attributes.SHOW_CLOSE, this._showClose.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Starts the animation of the toast and hides it after the duration.
     */
    ToastFoundation.prototype.show = function () {
        var _this = this;
        // Check if we were provided a builder function and call it if so (this will override the message text)
        if (isFunction(this._builder)) {
            var tpl = this._builder();
            this._adapter.setMessageTemplate(tpl);
        }
        this._isActive = true;
        this._adapter.setActive(true);
        if (isFinite(this._duration) && this._duration > 0) {
            this._hideTimeout = setTimeout(function () { return _this.hide(true); }, this._duration);
        }
    };
    /**
     * Hides the toast and removes it from the DOM.
     */
    ToastFoundation.prototype.hide = function (emitEvent) {
        if (emitEvent === void 0) { emitEvent = false; }
        if (!this._isActive) {
            return;
        }
        this._isActive = false;
        this._adapter.setActive(false);
        if (this._hideTimeout) {
            clearTimeout(this._hideTimeout);
            this._hideTimeout = undefined;
        }
        if (emitEvent) {
            this._adapter.emitEvent(TOAST_CONSTANTS.events.CLOSE);
        }
    };
    /**
     * Handles click events from the optional action button.
     * @param {MouseEvent} evt The mouse event.
     */
    ToastFoundation.prototype._onAction = function (evt) {
        evt.stopPropagation();
        this._adapter.emitEvent(TOAST_CONSTANTS.events.ACTION);
    };
    ToastFoundation.prototype._onClose = function (evt) {
        this.hide(true);
    };
    return ToastFoundation;
}());
export { ToastFoundation };
