import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, coerceNumber, coerceBoolean, upgradeProperty } from '@tyler-components-web/core';
import { AvatarAdapter } from './avatar-adapter';
import { AvatarFoundation } from './avatar-foundation';
import { AVATAR_CONSTANTS } from './avatar-constants';
var template = require('./avatar.html');
var styles = require('./avatar.css');
/**
 * The custom element class behind the `<tcw-avatar>` element.
 */
var AvatarComponent = /** @class */ (function (_super) {
    __extends(AvatarComponent, _super);
    function AvatarComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, AVATAR_CONSTANTS.elementName, template, styles);
        _this._foundation = new AvatarFoundation(new AvatarAdapter(_this));
        return _this;
    }
    Object.defineProperty(AvatarComponent, "observedAttributes", {
        get: function () {
            return [
                AVATAR_CONSTANTS.attributes.TEXT,
                AVATAR_CONSTANTS.attributes.LETTER_COUNT,
                AVATAR_CONSTANTS.attributes.IMAGE_URL,
                AVATAR_CONSTANTS.attributes.AUTO_COLOR
            ];
        },
        enumerable: true,
        configurable: true
    });
    AvatarComponent.prototype.connectedCallback = function () {
        upgradeProperty(this, 'imageUrl');
        upgradeProperty(this, 'text');
        upgradeProperty(this, 'letterCount');
        upgradeProperty(this, 'autoColor');
        this._foundation.initialize();
    };
    AvatarComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    AvatarComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case AVATAR_CONSTANTS.attributes.TEXT:
                this.text = newValue;
                break;
            case AVATAR_CONSTANTS.attributes.LETTER_COUNT:
                this.letterCount = coerceNumber(newValue);
                break;
            case AVATAR_CONSTANTS.attributes.IMAGE_URL:
                this.imageUrl = newValue;
                break;
            case AVATAR_CONSTANTS.attributes.AUTO_COLOR:
                this.autoColor = coerceBoolean(newValue);
                break;
        }
    };
    Object.defineProperty(AvatarComponent.prototype, "text", {
        /** Gets/sets the text to display. */
        get: function () {
            return this._foundation.text;
        },
        set: function (value) {
            this._foundation.text = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarComponent.prototype, "letterCount", {
        /** Controls the number of letters to display from the text. By default the text is split on spaces and the first character of each word is used. */
        get: function () {
            return this._foundation.letterCount;
        },
        set: function (value) {
            this._foundation.letterCount = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarComponent.prototype, "imageUrl", {
        /** Sets the background image URL to use. */
        get: function () {
            return this._foundation.imageUrl;
        },
        set: function (value) {
            this._foundation.imageUrl = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarComponent.prototype, "autoColor", {
        /** Controls whether the background color is set automatically based on the text value. Does not have any effect when an image URL is specified. */
        get: function () {
            return this._foundation.autoColor;
        },
        set: function (value) {
            this._foundation.autoColor = value;
        },
        enumerable: true,
        configurable: true
    });
    AvatarComponent = __decorate([
        CustomElement({
            name: AVATAR_CONSTANTS.elementName
        })
    ], AvatarComponent);
    return AvatarComponent;
}(HTMLElement));
export { AvatarComponent };
