import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate, upgradeProperty, coerceBoolean } from '@tyler-components-web/core';
import { DialogFoundation } from './dialog-foundation';
import { DialogAdapter } from './dialog-adapter';
import { DIALOG_CONSTANTS } from './dialog-constants';
import { BackdropComponent } from '../backdrop';
var template = require('./dialog.html');
var styles = require('./dialog.css');
var DialogComponent = /** @class */ (function (_super) {
    __extends(DialogComponent, _super);
    function DialogComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, DIALOG_CONSTANTS.elementName, template, styles);
        _this._foundation = new DialogFoundation(new DialogAdapter(_this));
        return _this;
    }
    Object.defineProperty(DialogComponent, "observedAttributes", {
        get: function () {
            return [
                DIALOG_CONSTANTS.attributes.BACKDROP_CLOSE,
                DIALOG_CONSTANTS.attributes.ESCAPE_CLOSE,
                DIALOG_CONSTANTS.attributes.OPEN,
                DIALOG_CONSTANTS.attributes.FULLSCREEN,
            ];
        },
        enumerable: true,
        configurable: true
    });
    DialogComponent.prototype.connectedCallback = function () {
        upgradeProperty(this, 'backdropClose');
        upgradeProperty(this, 'escapeClose');
        upgradeProperty(this, 'open');
        upgradeProperty(this, 'fullscreen');
    };
    DialogComponent.prototype.disconnectedCallback = function () {
        this._foundation.destroy();
    };
    DialogComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case DIALOG_CONSTANTS.attributes.BACKDROP_CLOSE:
                this.backdropClose = newValue === 'true';
                break;
            case DIALOG_CONSTANTS.attributes.ESCAPE_CLOSE:
                this.escapeClose = newValue === 'true';
                break;
            case DIALOG_CONSTANTS.attributes.OPEN:
                this.open = coerceBoolean(newValue);
                break;
            case DIALOG_CONSTANTS.attributes.FULLSCREEN:
                this.fullscreen = this.hasAttribute(DIALOG_CONSTANTS.attributes.FULLSCREEN);
                break;
        }
    };
    Object.defineProperty(DialogComponent.prototype, "backdropClose", {
        get: function () {
            return this._foundation.backdropClose;
        },
        /** Controls whether clicking the backdrop closes the dialog or not. Default is true. */
        set: function (value) {
            this._foundation.backdropClose = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogComponent.prototype, "escapeClose", {
        get: function () {
            return this._foundation.escapeClose;
        },
        /** Controls whether pressing the escape key closes the dialog or not. Default is true. */
        set: function (value) {
            this._foundation.escapeClose = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogComponent.prototype, "open", {
        /** Controls whether the dialog is open or not. Default is false. */
        get: function () {
            return this._foundation.open;
        },
        set: function (value) {
            this._foundation.open = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogComponent.prototype, "fullscreen", {
        get: function () {
            return this._foundation.fullscreen;
        },
        /** Controls whether the dialog is full screen or not. Default is false. */
        set: function (value) {
            this._foundation.fullscreen = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogComponent.prototype, "openCallback", {
        /** The function to call when the dialog wants to open. */
        set: function (callback) {
            this._foundation.openCallback = callback;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogComponent.prototype, "closeCallback", {
        /** the function to call when the dialog wants to close. */
        set: function (callback) {
            this._foundation.closeCallback = callback;
        },
        enumerable: true,
        configurable: true
    });
    DialogComponent = __decorate([
        CustomElement({
            name: DIALOG_CONSTANTS.elementName,
            dependencies: [BackdropComponent]
        })
    ], DialogComponent);
    return DialogComponent;
}(HTMLElement));
export { DialogComponent };
