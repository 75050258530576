import { emitEvent } from '@tyler-components-web/core';
import { deepQuerySelectorAll, getActiveElement } from 'shadow-utils';
import { LIST_CONSTANTS } from './list-constants';
import { LIST_ITEM_CONSTANTS } from '../list-item';
/**
 * The DOM adapter for the `<tcw-list>` component.
 */
var ListAdapter = /** @class */ (function () {
    function ListAdapter(_component) {
        this._component = _component;
    }
    /**
     * Sets an attribute value on the `<tcw-list>` host element.
     * @param {string} name The attribute name.
     * @param {string} [value=''] The attribute value.
     */
    ListAdapter.prototype.setHostAttribute = function (name, value) {
        if (value === void 0) { value = ''; }
        this._component.setAttribute(name, value);
    };
    /**
     * Removes an attribute from the `<tcw-list>` host element.
     * @param {string} name The attribute name.
     */
    ListAdapter.prototype.removeHostAttribute = function (name) {
        this._component.removeAttribute(name);
    };
    /**
     * Adds an event listener to the `<tcw-list>` host element.
     * @param {string} type The event type.
     * @param {Function} listener The event callback.
     */
    ListAdapter.prototype.addListener = function (type, listener) {
        this._component.addEventListener(type, listener);
    };
    /**
     * Removes an event listener to the `<tcw-list>` host element.
     * @param {string} type The event type.
     * @param {Function} listener The event callback.
     */
    ListAdapter.prototype.removeListener = function (type, listener) {
        this._component.removeEventListener(type, listener);
    };
    /**
     * Returns all child `<tcw-list-item>` elements.
     */
    ListAdapter.prototype.getListItems = function () {
        return Array.from(this._component.children).filter(function (child) { return child.tagName === LIST_ITEM_CONSTANTS.elementName.toUpperCase(); });
    };
    /**
     * Sets focus to the next item in the list.
     */
    ListAdapter.prototype.focusNextListItem = function () {
        var listItems = deepQuerySelectorAll(this._component, LIST_CONSTANTS.selectors.FOCUSABLE_LIST_ITEMS, false);
        if (listItems && listItems.length > 0) {
            var focusedListItemIndex = listItems.indexOf(getActiveElement());
            var nextIndex = focusedListItemIndex < listItems.length - 1 ? focusedListItemIndex + 1 : 0;
            if (nextIndex <= listItems.length - 1) {
                listItems[nextIndex].focus();
            }
        }
    };
    /**
     * Sets focus to the previous item in the list.
     */
    ListAdapter.prototype.focusPreviousListItem = function () {
        var listItems = deepQuerySelectorAll(this._component, LIST_CONSTANTS.selectors.FOCUSABLE_LIST_ITEMS, false);
        if (listItems && listItems.length > 0) {
            var focusedListItemIndex = listItems.indexOf(getActiveElement());
            var nextIndex = focusedListItemIndex > 0 ? focusedListItemIndex - 1 : listItems.length - 1;
            if (nextIndex >= 0) {
                listItems[nextIndex].focus();
            }
        }
    };
    /**
     * Sets focus to the first item in the list.
     */
    ListAdapter.prototype.focusFirstListItem = function () {
        var listItems = deepQuerySelectorAll(this._component, LIST_CONSTANTS.selectors.FOCUSABLE_LIST_ITEMS, false);
        if (listItems && listItems.length > 0) {
            listItems[0].focus();
        }
    };
    /**
     * Sets focus to the last item in the list.
     */
    ListAdapter.prototype.focusLastListItem = function () {
        var listItems = deepQuerySelectorAll(this._component, LIST_CONSTANTS.selectors.FOCUSABLE_LIST_ITEMS, false);
        if (listItems && listItems.length > 0) {
            listItems[listItems.length - 1].focus();
        }
    };
    /**
     * Emits an event from the `<tcw-list>` host element.
     * @param {string} natypeme The attribute name.
     * @param {object} [data={}] The attribute value.
     */
    ListAdapter.prototype.emitEvent = function (type, data) {
        if (data === void 0) { data = {}; }
        emitEvent(this._component, type, data, true);
    };
    ListAdapter.prototype.setSelectedListItems = function (values) {
        var listItems = Array.from(this._component.querySelectorAll(LIST_ITEM_CONSTANTS.elementName));
        if (listItems && listItems.length) {
            for (var _i = 0, listItems_1 = listItems; _i < listItems_1.length; _i++) {
                var item = listItems_1[_i];
                item.selected = values.includes(item.value);
            }
        }
    };
    ListAdapter.prototype.updateListItems = function (cb) {
        this.getListItems().forEach(function (li) { return cb(li); });
    };
    return ListAdapter;
}());
export { ListAdapter };
