import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "table";
var classes = {
    TABLE: 'tyl-table',
    TABLE_DENSE: 'tyl-table--dense',
    TABLE_RESIZABLE: 'tyl-table--resizable',
    TABLE_RESIZING: 'tyl-table--resizing',
    TABLE_RESIZE_HANDLE: 'tyl-table__resize-handle',
    TABLE_NO_WRAP_CONTENT: 'tyl-table--no-wrap-content',
    TABLE_LAYOUT_FIXED: 'tyl-table--layout-fixed',
    TABLE_FILTER_VISIBLE: 'tyl-table--filter-visible',
    TABLE_FIXED: 'tyl-table--fixed',
    TABLE_HEAD: 'tyl-table-head',
    TABLE_HEAD_ROW: 'tyl-table-head__row',
    TABLE_HEAD_ROW_FILTER: 'tyl-table-head__row__filter',
    TABLE_HEAD_CELL: 'tyl-table-head__cell',
    TABLE_HEAD_CELL_CONTAINER: 'tyl-table-head__cell-container',
    TABLE_HEAD_CELL_SORTABLE: 'tyl-table-head__cell--sortable',
    TABLE_HEAD_CELL_SORT_ICON: 'tyl-table-head__cell-sort-icon',
    TABLE_HEAD_CELL_TEXT: 'tyl-table-head__cell-text',
    TABLE_HEAD_CELL_SORT_ICON_ACTIVE: 'tyl-table-head__cell-sort-icon--active',
    TABLE_HEAD_CELL_SORTED_ASCENDING: 'tyl-table-head__cell--sorted-ascending',
    TABLE_HEAD_CELL_SORTED_DESCENDING: 'tyl-table-head__cell--sorted-descending',
    TABLE_BODY: 'tyl-table-body',
    TABLE_BODY_ROW: 'tyl-table-body__row',
    TABLE_BODY_ROW_SELECTED: 'tyl-table-body__row--selected',
    TABLE_BODY_CELL: 'tyl-table-body__cell',
    TABLE_ROW: 'tyl-table-row',
    TABLE_ROW_EXPANDED: 'tyl-table-row--expanded',
    TABLE_ROW_EXPANDABLE_CONTENT: 'tyl-table-row__expandable-content',
    TABLE_ROW_EXPANDABLE_CONTENT_CELL: 'tyl-table-row__expandable-content-cell',
    TABLE_CELL: 'tyl-table-cell',
    TABLE_CELL_SELECT: 'tyl-table-cell__select',
    TABLE_CELL_SELECT_CHECKBOX: 'tyl-table-cell__select-checkbox',
    TABLE_CELL_SELECT_CHECKBOX_CONTAINER: 'tyl-table-cell__select-checkbox-container',
    TABLE_CELL_CONTAINER: 'tyl-table-cell__container',
    TABLE_CELL_CONTAINER_TEXT: 'tyl-table-cell__container-text',
    TABLE_CELL_CENTER: 'tyl-table-cell--center',
    TABLE_CELL_RIGHT: 'tyl-table-cell--right',
    TABLE_CELL_RESIZABLE: 'tyl-table-cell--resizing'
};
var selectors = {
    TABLE: '.tyl-table',
    CHECKBOX_INPUT: 'tcw-checkbox > input[type=checkbox]',
    ROW_SELECTED: "." + classes.TABLE_BODY_ROW_SELECTED
};
var attributes = {
    // External
    SELECT: 'select',
    MULTISELECT: 'multiselect',
    SELECT_KEY: 'select-key',
    DENSE: 'dense',
    FILTER: 'filter',
    FIXED_HEADERS: 'fixed-headers',
    LAYOUT_TYPE: 'layout-type',
    WRAP_CONTENT: 'wrap-content',
    RESIZABLE: 'resizable',
    // Internal
    CHECKBOX_TYPE: 'tcw-checkbox-type'
};
var events = {
    SELECT: elementName + "-select",
    SELECT_DOUBLE: elementName + "-select-double",
    SELECT_ALL: elementName + "-select-all",
    SORT: elementName + "-sort",
    FILTER: elementName + "-filter",
    INITIALIZED: elementName + "-initialized"
};
var strings = {
    SELECT_ALL: 'select-all',
    SELECT_ROW: 'select-row',
    DEFAULT_LAYOUT_TYPE: 'auto'
};
var icons = {
    SORT_DOWN: 'arrow_downward'
};
var numbers = {
    DEFAULT_FILTER_DEBOUNCE_TIME: 750
};
export var TABLE_CONSTANTS = {
    elementName: elementName,
    classes: classes,
    selectors: selectors,
    attributes: attributes,
    events: events,
    strings: strings,
    icons: icons,
    numbers: numbers
};
