import { __decorate, __extends } from "tslib";
import { CustomElement, upgradeProperty, isDefined, coerceBoolean, attachShadowTemplate } from '@tyler-components-web/core';
import { PopupFoundation } from './popup-foundation';
import { PopupAdapter } from './popup-adapter';
import { POPUP_CONSTANTS } from './popup-constants';
var template = require('./popup.html');
var styles = require('./popup.css');
var PopupComponent = /** @class */ (function (_super) {
    __extends(PopupComponent, _super);
    function PopupComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, POPUP_CONSTANTS.elementName, template, styles);
        _this._foundation = new PopupFoundation(new PopupAdapter(_this));
        return _this;
    }
    Object.defineProperty(PopupComponent, "observedAttributes", {
        get: function () {
            return [
                POPUP_CONSTANTS.attributes.OPEN,
                POPUP_CONSTANTS.attributes.PLACEMENT,
                POPUP_CONSTANTS.attributes.MANAGE_FOCUS,
                POPUP_CONSTANTS.attributes.ANIMATION_TYPE,
                POPUP_CONSTANTS.attributes.STATIC
            ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupComponent.prototype, "targetElement", {
        get: function () {
            return this._foundation.targetElement;
        },
        set: function (element) {
            this._foundation.targetElement = element;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupComponent.prototype, "placement", {
        get: function () {
            return this._foundation.placement;
        },
        set: function (placement) {
            this._foundation.placement = placement;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupComponent.prototype, "open", {
        get: function () {
            return this._foundation.open;
        },
        set: function (value) {
            this._foundation.open = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupComponent.prototype, "manageFocus", {
        get: function () {
            return this._foundation.manageFocus;
        },
        set: function (value) {
            this._foundation.manageFocus = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupComponent.prototype, "animationType", {
        get: function () {
            return this._foundation.animationType;
        },
        set: function (value) {
            this._foundation.animationType = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupComponent.prototype, "static", {
        get: function () {
            return this._foundation.static;
        },
        set: function (value) {
            this._foundation.static = value;
        },
        enumerable: true,
        configurable: true
    });
    PopupComponent.prototype.position = function () {
        this._foundation.position();
    };
    Object.defineProperty(PopupComponent.prototype, "openCallback", {
        set: function (callback) {
            this._foundation.openCallback = callback;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupComponent.prototype, "closeCallback", {
        set: function (callback) {
            this._foundation.closeCallback = callback;
        },
        enumerable: true,
        configurable: true
    });
    PopupComponent.prototype.initializedCallback = function () {
        upgradeProperty(this, 'targetElement');
        upgradeProperty(this, 'placement');
        upgradeProperty(this, 'open');
        upgradeProperty(this, 'manageFocus');
        upgradeProperty(this, 'animationType');
        upgradeProperty(this, 'static');
        upgradeProperty(this, 'openCallback');
        upgradeProperty(this, 'closeCallback');
    };
    PopupComponent.prototype.disconnectedCallback = function () {
        this._foundation.disconnect();
    };
    PopupComponent.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
        switch (name) {
            case POPUP_CONSTANTS.attributes.OPEN:
                this._foundation.open = isDefined(newValue);
                break;
            case POPUP_CONSTANTS.attributes.PLACEMENT:
                this._foundation.placement = newValue;
                break;
            case POPUP_CONSTANTS.attributes.MANAGE_FOCUS:
                this._foundation.manageFocus = coerceBoolean(newValue);
                break;
            case POPUP_CONSTANTS.attributes.ANIMATION_TYPE:
                this._foundation.animationType = newValue;
                break;
            case POPUP_CONSTANTS.attributes.STATIC:
                this._foundation.static = this.hasAttribute(POPUP_CONSTANTS.attributes.STATIC);
                break;
        }
    };
    PopupComponent = __decorate([
        CustomElement({
            name: POPUP_CONSTANTS.elementName
        })
    ], PopupComponent);
    return PopupComponent;
}(HTMLElement));
export { PopupComponent };
