import { COLOR_CONSTANTS } from '../theme';
/**
 * Gets a random hex color.
 * @returns {string} The CSS-compatible hex color string.
 */
export function randomHexColor() {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}
/**
 * Gets the color for the provided letter.
 */
export function getTextColor(text) {
    if (!text || typeof text !== 'string') {
        text = '';
    }
    var color;
    if (text.charCodeAt(0) < 65) {
        color = randomHexColor();
    }
    else {
        var colorIndex = Math.floor((text.charCodeAt(0) - 65) % COLOR_CONSTANTS.letterColors.length);
        color = COLOR_CONSTANTS.letterColors[colorIndex];
    }
    return color;
}
