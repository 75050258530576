import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "button";
var classes = {
    BUTTON: 'tyl-button',
    LABEL: 'tyl-button__label',
    ICON: 'tyl-button__icon',
    BUTTON_RAISED: 'tyl-button--raised',
    BUTTON_UNELEVATED: 'tyl-button--unelevated',
    BUTTON_OUTLINED: 'tyl-button--outlined',
    BUTTON_DENSE: 'tyl-button--dense'
};
var selectors = {
    BUTTON: 'button',
    LABEL: 'span',
    ICON: 'i'
};
var attributes = {
    TYPE: 'type'
};
export var BUTTON_CONSTANTS = {
    elementName: elementName,
    classes: classes,
    selectors: selectors,
    attributes: attributes
};
