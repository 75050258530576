import { defineCustomElement } from '@tyler-components-web/core';
import { AutocompleteComponent } from './autocomplete';
export * from './autocomplete-adapter';
export * from './autocomplete-component-delegate';
export * from './autocomplete-constants';
export * from './autocomplete-foundation';
export * from './autocomplete-utils';
export * from './autocomplete';
export function defineAutocompleteComponent() {
    defineCustomElement(AutocompleteComponent);
}
