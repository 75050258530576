import { COMPONENT_NAME_PREFIX } from '../constants';
var elementName = COMPONENT_NAME_PREFIX + "switch";
var classes = {
    SWITCH: 'mdc-switch',
    NATIVE_CONTROL: 'mdc-switch__native-control',
    CHECKED: 'mdc-switch--checked',
    DISABLED: 'mdc-switch--disabled',
    SWITCH_DENSE: 'tyl-switch--dense'
};
var selectors = {
    INPUT: 'input',
    THUMB: '.mdc-switch__thumb'
};
var attributes = {
    DENSE: 'dense'
};
export var SWITCH_CONSTANTS = {
    elementName: elementName,
    classes: classes,
    selectors: selectors,
    attributes: attributes
};
