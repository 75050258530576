import { __decorate, __extends } from "tslib";
import { CustomElement, attachShadowTemplate } from '@tyler-components-web/core';
import { DIVIDER_CONSTANTS } from './divider-constants';
var template = require('./divider.html');
var styles = require('./divider.css');
/**
 * The custom element class behind the `<tcw-divider>` element.
 */
var DividerComponent = /** @class */ (function (_super) {
    __extends(DividerComponent, _super);
    function DividerComponent() {
        var _this = _super.call(this) || this;
        attachShadowTemplate(_this, DIVIDER_CONSTANTS.elementName, template, styles);
        return _this;
    }
    DividerComponent = __decorate([
        CustomElement({
            name: DIVIDER_CONSTANTS.elementName
        })
    ], DividerComponent);
    return DividerComponent;
}(HTMLElement));
export { DividerComponent };
