import { defineCustomElement } from '@tyler-components-web/core';
import { ViewSwitcherComponent } from './view-switcher';
export * from './view-switcher-adapter';
export * from './view-switcher-constants';
export * from './view-switcher-foundation';
export * from './view-switcher';
export * from './view';
export function defineViewSwitcherComponent() {
    defineCustomElement(ViewSwitcherComponent);
}
