import { __awaiter, __generator, __spreadArrays } from "tslib";
import { debounce, isDefined } from '@tyler-components-web/core';
import { AUTOCOMPLETE_CONSTANTS } from './autocomplete-constants';
import { isOptionType, OptionType, getSelectedOption } from './autocomplete-utils';
/**
 * The foundation class behind the `<tcw-autocomplete>` element.
 */
var AutocompleteFoundation = /** @class */ (function () {
    function AutocompleteFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._isInitialized = false;
        this._isDropdownOpen = false;
        this._multiple = false;
        this._debounce = AUTOCOMPLETE_CONSTANTS.numbers.DEFAULT_DEBOUNCE_TIME;
        this._highlightFirst = true;
        this._allowUnmatched = false;
        this._popupClasses = [];
        this._filterOnFocus = true;
        this._options = [];
        this._filterText = '';
        this._selectedOptions = [];
        this._optionLimit = 0;
        this._values = [];
        this._pendingFilterPromises = [];
        this._observeScroll = false;
        this._observeScrollThreshold = 0;
        this._clickListener = function (evt) { return _this._onClick(evt); };
        this._blurListener = function (evt) { return _this._onBlur(evt); };
        this._keydownListener = function (evt) { return _this._onKeydown(evt); };
        this._dropdownListener = function (evt) { return _this._onDropdownClick(evt); };
        this._dropdownScrollEndListener = function () { return _this._onDropdownScrollEnd(); };
        this._dismissListener = function () { return _this._onDismiss(); };
    }
    AutocompleteFoundation.prototype.initialize = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._adapter.hasInputElement() && !this._adapter.setInputElement()) {
                            throw new Error('An input element is required as a child of the autocomplete component.');
                        }
                        this._adapter.setDropdownListener(this._dropdownListener);
                        this._setInputListener();
                        this._attachListeners();
                        this._initializeAccessibility();
                        if (!this._values.length) return [3 /*break*/, 3];
                        if (!!this._selectedOptions.length) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._executeFilter()];
                    case 1:
                        _a.sent();
                        this._updateSelectedOptions(this._values);
                        _a.label = 2;
                    case 2:
                        this._adapter.setSelectedText(this._getSelectedText());
                        _a.label = 3;
                    case 3:
                        this._isInitialized = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    AutocompleteFoundation.prototype.disconnect = function () {
        this._detachListeners();
        this._isInitialized = false;
    };
    AutocompleteFoundation.prototype._attachListeners = function () {
        this._adapter.addInputListener('click', this._clickListener);
        this._adapter.addInputListener('blur', this._blurListener);
        this._adapter.addInputListener('input', this._inputListener);
        this._adapter.addInputListener('keydown', this._keydownListener);
    };
    AutocompleteFoundation.prototype._detachListeners = function () {
        this._adapter.removeInputListener('click', this._clickListener);
        this._adapter.removeInputListener('blur', this._blurListener);
        this._adapter.removeInputListener('input', this._inputListener);
        this._adapter.removeInputListener('keydown', this._keydownListener);
    };
    AutocompleteFoundation.prototype._setInputListener = function () {
        var _this = this;
        var listener = function (evt) { return _this._onInput(evt); };
        this._inputListener = isDefined(this._debounce) && this._debounce > 0 ? debounce(listener, this._debounce, false) : listener;
    };
    AutocompleteFoundation.prototype._initializeAccessibility = function () {
        this._adapter.intializeInputAccessibility();
    };
    Object.defineProperty(AutocompleteFoundation.prototype, "_flatOptions", {
        get: function () {
            if (isOptionType(this._options, OptionType.Group)) {
                return this._options.reduce(function (previousValue, currentValue) { return previousValue.concat(currentValue.options); }, []);
            }
            return this._options;
        },
        enumerable: true,
        configurable: true
    });
    AutocompleteFoundation.prototype._onClick = function (evt) {
        if (!this._isDropdownOpen) {
            if (this._adapter.getInputValue()) {
                this._adapter.selectInputValue();
            }
            if (this._filterOnFocus) {
                this._showDropdown();
            }
        }
    };
    AutocompleteFoundation.prototype._onDropdownClick = function (evt) {
        var _this = this;
        if (!this._isDropdownOpen) {
            this._adapter.focus();
            window.requestAnimationFrame(function () { return _this._showDropdown(); });
        }
    };
    AutocompleteFoundation.prototype._onDropdownScrollEnd = function () {
        this._adapter.emitEvent(AUTOCOMPLETE_CONSTANTS.events.SCROLLED_BOTTOM);
    };
    AutocompleteFoundation.prototype._onBlur = function (evt) {
        // If the blur event was triggered by an element within the popup then ignore it
        if (this._adapter.focusWithinPopup(evt.relatedTarget)) {
            return;
        }
        if (this._isDropdownOpen) {
            this._closeDropdown();
        }
        if (!this._selectedOptions.length) {
            if (!this._allowUnmatched) {
                this._filterText = '';
                this._adapter.setSelectedText('');
            }
        }
        else {
            this._adapter.setSelectedText(this._getSelectedText());
        }
    };
    AutocompleteFoundation.prototype._onInput = function (evt) {
        var _this = this;
        if (this._selectedOptions.length && (!this._adapter.getInputValue() || this._allowUnmatched)) {
            this._selectedOptions = [];
            this._values = [];
            this._emitChangeEvent();
        }
        this._filterText = evt.target.value;
        if (this._isDropdownOpen) {
            this._adapter.showSpinner();
        }
        // Execute the filter, but make sure that we only complete when the last filter is done
        this._pendingFilterPromises.push(this._executeFilter().then(function () {
            _this._pendingFilterPromises.splice(0, 1);
            if (_this._pendingFilterPromises.length === 0) {
                _this._onFilterComplete();
            }
        }));
    };
    AutocompleteFoundation.prototype._onKeydown = function (evt) {
        var isEscapeKey = evt.key === 'Escape' && evt.keyCode === 27;
        var isArrowUpKey = evt.key === 'ArrowUp' || evt.keyCode === 38;
        var isArrowDownKey = evt.key === 'ArrowDown' || evt.keyCode === 40;
        var isHomeKey = evt.key === 'Home' || evt.keyCode === 36;
        var isEndKey = evt.key === 'End' || evt.keyCode === 35;
        var isEnterKey = evt.key === 'Enter' || evt.keyCode === 13;
        var isDeleteKey = evt.key === 'Delete' || evt.keyCode === 8 || evt.key === 'Backspace' || evt.keyCode === 46;
        var options = this._adapter.getOptions();
        var hasActiveOption = options.some(function (o) { return o.active || o.selected; });
        if (this._isDropdownOpen && hasActiveOption && (isEscapeKey || isArrowUpKey || isArrowDownKey || isEnterKey)) {
            evt.preventDefault();
        }
        if (isEscapeKey) {
            if (this._isDropdownOpen) {
                this._closeDropdown();
            }
        }
        else if (isArrowUpKey) {
            this._activateNextOption(options, false);
        }
        else if (isArrowDownKey) {
            this._activateNextOption(options, true);
        }
        else if (isHomeKey) {
            this._activateFirstOption(options);
        }
        else if (isEndKey) {
            this._activateLastOption(options);
        }
        else if (isEnterKey) {
            this._selectActiveOption(options);
        }
        else if (isDeleteKey) {
            if (!this._adapter.getInputValue()) {
                this._selectedOptions = [];
                this._values = [];
                this._emitChangeEvent();
            }
        }
    };
    AutocompleteFoundation.prototype._activateNextOption = function (options, down) {
        if (!this._isDropdownOpen) {
            this._showDropdown();
            options = this._adapter.getOptions();
            if (options && options.length) {
                options[0].active = true;
                this._adapter.setInputAttribute('aria-activedescendant', options[0].id);
            }
            return;
        }
        if (!options.some(function (o) { return o.active; })) {
            options[0].active = true;
            this._adapter.setInputAttribute('aria-activedescendant', options[0].id);
            return;
        }
        var activeOption = options.find(function (o) { return o.active; });
        if (!activeOption) {
            return;
        }
        var activeOptionIndex = options.indexOf(activeOption);
        activeOption.active = false;
        if (down) {
            var nextIndex = activeOptionIndex < options.length - 1 ? activeOptionIndex + 1 : 0;
            if (nextIndex >= 0) {
                options[nextIndex].active = true;
                this._adapter.setInputAttribute('aria-activedescendant', options[nextIndex].id);
                this._adapter.ensureActiveItemVisible(options[nextIndex]);
            }
        }
        else {
            var nextIndex = activeOptionIndex > 0 ? activeOptionIndex - 1 : options.length - 1;
            if (nextIndex <= options.length - 1) {
                options[nextIndex].active = true;
                this._adapter.setInputAttribute('aria-activedescendant', options[nextIndex].id);
                this._adapter.ensureActiveItemVisible(options[nextIndex]);
            }
        }
    };
    AutocompleteFoundation.prototype._activateFirstOption = function (options) {
        this._activateOption(options, 0);
    };
    AutocompleteFoundation.prototype._activateLastOption = function (options) {
        this._activateOption(options, options.length - 1);
    };
    AutocompleteFoundation.prototype._activateOption = function (options, index) {
        var activeOption = options.find(function (o) { return o.active; });
        if (activeOption) {
            activeOption.active = false;
        }
        if (options[index]) {
            options[index].active = true;
            this._adapter.ensureActiveItemVisible(options[index]);
            this._adapter.setInputAttribute('aria-activedescendant', options[index].id);
        }
    };
    AutocompleteFoundation.prototype._selectActiveOption = function (options, closeDropdown) {
        if (closeDropdown === void 0) { closeDropdown = false; }
        if (!this._isDropdownOpen) {
            return;
        }
        var activeOption = options.find(function (o) { return o.active; });
        if (!activeOption) {
            return;
        }
        var option = this._flatOptions.find(function (o) { return o.value === activeOption.value; });
        if (option) {
            if (this._multiple) {
                options.forEach(function (o) { return o.active = false; });
                this._adapter.toggleOption(activeOption);
                this._adapter.setDismissListener(this._dismissListener);
            }
            this._onSelect(option.value);
            if (closeDropdown) {
                this._closeDropdown();
            }
        }
    };
    AutocompleteFoundation.prototype._executeFilter = function (sendFilterText, sendValue) {
        var _this = this;
        if (sendFilterText === void 0) { sendFilterText = true; }
        if (sendValue === void 0) { sendValue = false; }
        if (!this._filter || typeof this._filter !== 'function') {
            throw new Error('A filter callback must be provided. Did you set the "filter" property?');
        }
        var filterText = sendFilterText ? this._filterText : '';
        var value = sendValue ? this._getValue() : null;
        return new Promise(function (resolve) {
            return Promise.resolve(_this._filter(filterText, value))
                .then(function (options) {
                _this._options = options;
                resolve(_this._options);
            });
        });
    };
    AutocompleteFoundation.prototype._onFilterComplete = function () {
        if (!this._adapter.hasFocus()) {
            if (this._isDropdownOpen) {
                this._closeDropdown();
            }
            return;
        }
        if (this._isDropdownOpen) {
            if (this._options.length) {
                this._adapter.setOptions(this._options, this._selectedOptions, this._filterText, this._optionBuilder, this._multiple);
                if (this._highlightFirst) {
                    this._activateFirstOption(this._adapter.getOptions());
                }
            }
            else {
                this._closeDropdown();
            }
        }
        else {
            this._showDropdown();
        }
    };
    AutocompleteFoundation.prototype._showDropdown = function () {
        return __awaiter(this, void 0, void 0, function () {
            var sendFilterText;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sendFilterText = this._allowUnmatched && !this._selectedOptions.length;
                        this._isDropdownOpen = true;
                        this._adapter.show([], this._selectedOptions, (sendFilterText ? this._filterText : ''), this._optionBuilder, function (value) { return _this._onSelect(value); }, this._multiple, this._highlightFirst, this._popupTarget, this._popupClasses, this._dropdownScrollEndListener);
                        if (this._observeScroll) {
                            this._adapter.setScrollBottomListener(this._dropdownScrollEndListener, this._observeScrollThreshold);
                        }
                        return [4 /*yield*/, this._executeFilter(sendFilterText)];
                    case 1:
                        _a.sent();
                        // When in multiple selection mode, we need to make sure that the selected options appear at the top of the list
                        if (this._multiple && this._selectedOptions.length && isOptionType(this._options, OptionType.Option)) {
                            this._options = this._sortOptions();
                        }
                        if (!this._isDropdownOpen || !this._options.length || !this._adapter.hasFocus()) {
                            this._closeDropdown();
                            return [2 /*return*/];
                        }
                        this._adapter.setOptions(this._options, this._selectedOptions, (sendFilterText ? this._filterText : ''), this._optionBuilder, this._multiple);
                        this._adapter.focus();
                        if (this._highlightFirst) {
                            this._activateFirstOption(this._adapter.getOptions());
                        }
                        this._adapter.setDismissListener(this._dismissListener);
                        return [2 /*return*/];
                }
            });
        });
    };
    AutocompleteFoundation.prototype._closeDropdown = function () {
        if (this._multiple) {
            this._filterText = '';
        }
        this._isDropdownOpen = false;
        this._adapter.hide();
    };
    AutocompleteFoundation.prototype._sortOptions = function () {
        var _this = this;
        var selectedOptions = [];
        var unselectedOptions = [];
        this._options.forEach(function (option) {
            if (_this._selectedOptions.find(function (o) { return o.value === option.value; })) {
                selectedOptions.push(option);
            }
            else {
                unselectedOptions.push(option);
            }
        });
        selectedOptions.sort(function (a, b) { return a.label.localeCompare(b.label); });
        unselectedOptions.sort(function (a, b) { return a.label.localeCompare(b.label); });
        return __spreadArrays(selectedOptions, unselectedOptions);
    };
    /**
     * Handles selecting an item in the dropdown.
     * @param {IOption} option The selected option.
     * @param {number} optionIndex The index of the selected option.
     */
    AutocompleteFoundation.prototype._onSelect = function (selectedValue) {
        var flatOptions = this._flatOptions;
        var option = flatOptions.find(function (o) { return o.value === selectedValue; });
        var value = option ? option.value : '';
        var label = option ? option.label : '';
        if (this._multiple) {
            var selectedOption = getSelectedOption(this._selectedOptions, value);
            if (selectedOption) {
                var index = this._selectedOptions.indexOf(selectedOption);
                this._selectedOptions.splice(index, 1);
            }
            else {
                this._selectedOptions.push(option);
            }
        }
        else {
            if (isDefined(value)) {
                this._selectedOptions[0] = option;
                this._filterText = label;
            }
            else {
                this._selectedOptions = [];
                this._filterText = '';
            }
        }
        // Keep the values in sync with the currently selected options
        this._values = this._selectedOptions.map(function (o) { return o.value; });
        this._adapter.setSelectedText(this._getSelectedText());
        if (!this._multiple) {
            this._closeDropdown();
        }
        this._emitChangeEvent();
    };
    AutocompleteFoundation.prototype._emitChangeEvent = function () {
        this._adapter.emitEvent(AUTOCOMPLETE_CONSTANTS.events.CHANGE, this._getValue());
    };
    /**
     * Retrieves the current value(s) from the selected options array based on whether
     * we are in multi-select mode or not.
     */
    AutocompleteFoundation.prototype._getValue = function () {
        if (!this._values || !this._values.length) {
            return null;
        }
        return this._multiple ? __spreadArrays(this._values) : this._values[0];
    };
    /**
     * Creates the selected text value from the selected label values.
     */
    AutocompleteFoundation.prototype._getSelectedText = function () {
        if (this._selectedTextBuilder) {
            return this._selectedTextBuilder(this._selectedOptions);
        }
        else {
            if (this._multiple) {
                if (this._values.length) {
                    return this._values.length + " " + (this._values.length === 1 ? 'option' : 'options') + " selected";
                }
                else {
                    return '';
                }
            }
            else {
                return this._selectedOptions.filter(function (o) { return o && o.label; }).map(function (o) { return o.label; }).join(' ').trim();
            }
        }
    };
    /**
     * Handles the user dismissing the dropdown.
     * @param keepFocus Keep focus on the dropdown or not.
     */
    AutocompleteFoundation.prototype._onDismiss = function () {
        this._closeDropdown();
    };
    AutocompleteFoundation.prototype._applyValue = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            var values, allOptions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        values = [];
                        this._selectedOptions = [];
                        if (!Array.isArray(value)) {
                            values = isDefined(value) ? [value] : [];
                        }
                        else {
                            values = value;
                        }
                        // If this is not a multi-select, then make sure we only allow one selected value
                        if (!this._multiple && values.length > 1) {
                            values = [values[0]];
                        }
                        if (isOptionType(values, OptionType.Option)) {
                            this._values = values.map(function (o) { return o.value; });
                            this._selectedOptions = values;
                        }
                        else {
                            this._values = values;
                        }
                        allOptions = this._flatOptions;
                        if (values.length && allOptions.length) {
                            this._updateSelectedOptions(values);
                        }
                        if (!(this._values.length && !this._selectedOptions.length && this._filter)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._executeFilter(false, true)];
                    case 1:
                        _a.sent();
                        this._updateSelectedOptions(this._values);
                        _a.label = 2;
                    case 2:
                        // Update filter text to match the label when on in multiselect mode
                        if (!this._multiple) {
                            this._filterText = this._selectedOptions.length ? this._selectedOptions[0].label : '';
                        }
                        else {
                            this._filterText = '';
                        }
                        // Update the state of the component based on the existence of a selected value
                        if (this._isInitialized) {
                            this._adapter.setSelectedText(this._getSelectedText());
                        }
                        // When the value is changed programatically we close the dropdown
                        if (this._isDropdownOpen) {
                            // TODO(kieran.nichols): Potentially just update the dropdown list instead of closing?
                            this._closeDropdown();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AutocompleteFoundation.prototype._updateSelectedOptions = function (values) {
        if (this._selectedOptions.length) {
            this._selectedOptions = [];
        }
        if (isOptionType(values, OptionType.Option)) {
            var _loop_1 = function (option) {
                var actualOption = this_1._flatOptions.find(function (o) { return o.value === option.value; });
                if (actualOption) {
                    this_1._selectedOptions.push(actualOption);
                }
                else {
                    this_1._selectedOptions.push(option);
                }
            };
            var this_1 = this;
            for (var _i = 0, _a = values; _i < _a.length; _i++) {
                var option = _a[_i];
                _loop_1(option);
            }
        }
        else {
            var _loop_2 = function (value) {
                var actualOption = this_2._flatOptions.find(function (o) { return o.value === value; });
                if (actualOption) {
                    this_2._selectedOptions.push(actualOption);
                }
                else if (this_2._allowUnmatched) {
                    this_2._selectedOptions.push({ label: value, value: value });
                }
            };
            var this_2 = this;
            for (var _b = 0, _c = values; _b < _c.length; _b++) {
                var value = _c[_b];
                _loop_2(value);
            }
        }
    };
    Object.defineProperty(AutocompleteFoundation.prototype, "multiple", {
        /** Gets/sets the multi-select state. */
        get: function () {
            return this._multiple;
        },
        set: function (value) {
            if (this._multiple !== value) {
                this._multiple = value;
                this._adapter.setHostAttribute(AUTOCOMPLETE_CONSTANTS.attributes.MULTIPLE, isDefined(this._multiple) ? this._multiple.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteFoundation.prototype, "value", {
        /** Gets/sets the value of the component. */
        get: function () {
            return this._getValue();
        },
        set: function (value) {
            var _this = this;
            var values = [];
            if (!Array.isArray(value)) {
                values = isDefined(value) ? [value] : [];
            }
            else {
                values = value;
            }
            var hasNewValue = values.length !== this._values.length || values.some(function (v) { return !_this._values.includes(v); });
            if (!hasNewValue) {
                return;
            }
            // We only apply the new values if they have actually changed
            this._applyValue(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteFoundation.prototype, "highlightFirst", {
        /** Gets/sets the highlight first option setting. */
        get: function () {
            return this._highlightFirst;
        },
        set: function (value) {
            if (this._highlightFirst !== value) {
                this._highlightFirst = value;
                this._adapter.setHostAttribute(AUTOCOMPLETE_CONSTANTS.attributes.HIGHLIGHT_FIRST, isDefined(this._highlightFirst) ? this._highlightFirst.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteFoundation.prototype, "filterOnFocus", {
        /** Gets/sets filter on focus settings which controls whether the dropdown displays automatically when focused. */
        get: function () {
            return this._filterOnFocus;
        },
        set: function (value) {
            if (this._filterOnFocus !== value) {
                this._filterOnFocus = value;
                this._adapter.setHostAttribute(AUTOCOMPLETE_CONSTANTS.attributes.FILTER_ON_FOCUS, isDefined(this._filterOnFocus) ? this._filterOnFocus.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteFoundation.prototype, "allowUnmatched", {
        /** Controls whether unmatched text entered by the user will stay visible an option in the dropdown is not found. */
        get: function () {
            return this._allowUnmatched;
        },
        set: function (value) {
            if (this._allowUnmatched !== value) {
                this._allowUnmatched = value;
                if (isDefined(this._allowUnmatched)) {
                    this._adapter.setHostAttribute(AUTOCOMPLETE_CONSTANTS.attributes.ALLOW_UNMATCHED, this._allowUnmatched.toString());
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteFoundation.prototype, "popupTarget", {
        /** Gets/sets the selector that will be used to find an element to attach the popup to. Defaults to the input element. */
        get: function () {
            return this._popupTarget;
        },
        set: function (value) {
            if (this._popupTarget !== value) {
                this._popupTarget = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteFoundation.prototype, "popupClasses", {
        /** Gets/sets the list of classes to apply to the popup element. */
        get: function () {
            return this._popupClasses;
        },
        set: function (value) {
            if (this._popupClasses !== value) {
                this._popupClasses = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteFoundation.prototype, "optionLimit", {
        /** Gets/sets the maximum number of options to display in the dropdown. */
        get: function () {
            return this._optionLimit;
        },
        set: function (value) {
            if (this._optionLimit !== value) {
                this._optionLimit = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteFoundation.prototype, "debounce", {
        /** Gets/sets the debounce delay (milliseconds) for keyboard events. */
        get: function () {
            return this._debounce;
        },
        set: function (value) {
            if (this._debounce !== value) {
                this._debounce = value;
                if (this._isInitialized) {
                    // When changing the debounce value we need to reset the input listener accordingly
                    this._adapter.removeInputListener('input', this._inputListener);
                    this._setInputListener();
                    this._adapter.addInputListener('input', this._inputListener);
                }
                this._adapter.setHostAttribute(AUTOCOMPLETE_CONSTANTS.attributes.DEBOUNCE, isDefined(this._debounce) ? this._debounce.toString() : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteFoundation.prototype, "optionBuilder", {
        /** Sets the item builder callback that will be executed when building the option list in the dropdown. */
        set: function (fn) {
            this._optionBuilder = fn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteFoundation.prototype, "filter", {
        /** Sets the filter callback that will be executed when fetching options for the autocomplete dropdown. */
        set: function (cb) {
            var _this = this;
            if (this._filter !== cb) {
                this._filter = cb;
                // If we have a value, but don't have any options yet then execute the filter
                if (this._isInitialized && this._values.length && !this._flatOptions.length && this._filter) {
                    this._executeFilter().then(function () {
                        _this._updateSelectedOptions(_this._values);
                        _this._adapter.setSelectedText(_this._getSelectedText());
                    });
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteFoundation.prototype, "selectedTextBuilder", {
        /** Sets the selected text builder callback that will be executed when getting the selected text. */
        set: function (fn) {
            this._selectedTextBuilder = fn;
            // If there are selected options, set the selected text again
            if (this._selectedOptions.length) {
                this._adapter.setSelectedText(this._getSelectedText());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteFoundation.prototype, "observeScroll", {
        get: function () {
            return this._observeScroll;
        },
        set: function (value) {
            if (this._observeScroll !== value) {
                this._observeScroll = value;
                if (this._isDropdownOpen) {
                    this._adapter.setScrollBottomListener(this._dropdownScrollEndListener, this._observeScrollThreshold);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteFoundation.prototype, "observeScrollThreshold", {
        get: function () {
            return this._observeScrollThreshold;
        },
        set: function (value) {
            this._observeScrollThreshold = value;
        },
        enumerable: true,
        configurable: true
    });
    AutocompleteFoundation.prototype.appendOptions = function (options) {
        if (!this._isDropdownOpen) {
            return;
        }
        this._options = __spreadArrays(this._options, options);
        this._adapter.setOptions(options, this._selectedOptions, this._filterText, this._optionBuilder, this._multiple, false);
    };
    return AutocompleteFoundation;
}());
export { AutocompleteFoundation };
