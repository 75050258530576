import { isFunction } from '@tyler-components-web/core';
import { PROFILE_CARD_CONSTANTS } from '../profile-card';
import { AVATAR_CONSTANTS } from '../../avatar';
var OmnibarProfileButtonFoundation = /** @class */ (function () {
    function OmnibarProfileButtonFoundation(_adapter) {
        var _this = this;
        this._adapter = _adapter;
        this._avatarLetterCount = AVATAR_CONSTANTS.numbers.DEFAULT_LETTER_COUNT;
        this._showSignOutButton = PROFILE_CARD_CONSTANTS.defaults.SHOW_SIGN_OUT_BUTTON;
        this._showProfileButton = PROFILE_CARD_CONSTANTS.defaults.SHOW_PROFILE_BUTTON;
        this._isOpen = false;
        this._isInitialized = false;
        this._clickListener = function (evt) { return _this._onClick(evt); };
        this._dismissListener = function () { return _this._onDimiss(); };
        this._keydownListener = function (evt) { return _this._onKeydown(evt); };
        this._profileButtonListener = function () { return _this._onProfileButtonClick(); };
        this._signOutButtonListener = function () { return _this._onSignOutButtonClick(); };
    }
    OmnibarProfileButtonFoundation.prototype.initialize = function () {
        this._adapter.initialize();
        this._adapter.setClickListener(this._clickListener);
        this._adapter.setAvatarImageUrl(this._avatarImageUrl);
        this._adapter.setAvatarLetterCount(this._avatarLetterCount);
        this._adapter.setAvatarText(this._avatarText);
        this._isInitialized = true;
    };
    OmnibarProfileButtonFoundation.prototype.disconnect = function () {
        if (this._isOpen) {
            this._close();
        }
        this._adapter.removeWindowListener('keydown', this._keydownListener);
        this._adapter.removeClickListener(this._clickListener);
        this._isInitialized = false;
    };
    OmnibarProfileButtonFoundation.prototype._onClick = function (evt) {
        if (!this._isOpen) {
            this._open();
        }
        else {
            this._close();
        }
    };
    OmnibarProfileButtonFoundation.prototype._onKeydown = function (evt) {
        if (evt.key === 'Escape' || evt.keyCode === 27) {
            this._close();
            this._adapter.requestFocus();
        }
    };
    OmnibarProfileButtonFoundation.prototype._onDimiss = function () {
        this._close();
    };
    OmnibarProfileButtonFoundation.prototype._onProfileButtonClick = function () {
        this._adapter.emitEvent(PROFILE_CARD_CONSTANTS.events.PROFILE);
        this._close();
        this._adapter.requestFocus();
    };
    OmnibarProfileButtonFoundation.prototype._onSignOutButtonClick = function () {
        this._adapter.emitEvent(PROFILE_CARD_CONSTANTS.events.SIGN_OUT);
        this._close();
        this._adapter.requestFocus();
    };
    OmnibarProfileButtonFoundation.prototype._open = function () {
        if (isFunction(this._cancelDismissListener)) {
            this._cancelDismissListener();
        }
        var profileCardConfig = {
            fullName: this._fullName,
            email: this._email,
            signOut: this._showSignOutButton,
            profile: this._showProfileButton
        };
        this._cancelDismissListener = this._adapter.openPopup(profileCardConfig, this._dismissListener, this._profileButtonListener, this._signOutButtonListener);
        this._adapter.addWindowListener('keydown', this._keydownListener);
        this._isOpen = true;
        // If we aren't showing the sign out or profile buttons then leave focus on our button
        if (!profileCardConfig.signOut && !profileCardConfig.profile) {
            this._adapter.requestFocus();
        }
    };
    OmnibarProfileButtonFoundation.prototype._close = function () {
        if (isFunction(this._cancelDismissListener)) {
            this._cancelDismissListener();
        }
        this._isOpen = false;
        this._adapter.removeWindowListener('keydown', this._keydownListener);
        this._adapter.closePopup();
    };
    Object.defineProperty(OmnibarProfileButtonFoundation.prototype, "fullName", {
        get: function () {
            return this._fullName;
        },
        set: function (value) {
            if (this._fullName !== value) {
                this._fullName = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarProfileButtonFoundation.prototype, "email", {
        get: function () {
            return this._email;
        },
        set: function (value) {
            if (this._email !== value) {
                this._email = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarProfileButtonFoundation.prototype, "avatarImageUrl", {
        get: function () {
            return this._avatarImageUrl;
        },
        set: function (value) {
            if (this._avatarImageUrl !== value) {
                this._avatarImageUrl = value;
                if (this._isInitialized) {
                    this._adapter.setAvatarImageUrl(this._avatarImageUrl);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarProfileButtonFoundation.prototype, "avatarLetterCount", {
        get: function () {
            return this._avatarLetterCount;
        },
        set: function (value) {
            if (this._avatarLetterCount !== value) {
                this._avatarLetterCount = value;
                if (this._isInitialized) {
                    this._adapter.setAvatarLetterCount(this._avatarLetterCount);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarProfileButtonFoundation.prototype, "avatarText", {
        get: function () {
            return this._avatarText;
        },
        set: function (value) {
            if (this._avatarText !== value) {
                this._avatarText = value;
                if (this._isInitialized) {
                    this._adapter.setAvatarText(this._avatarText);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarProfileButtonFoundation.prototype, "signOutButton", {
        get: function () {
            return this._showSignOutButton;
        },
        set: function (value) {
            if (this._showSignOutButton !== value) {
                this._showSignOutButton = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmnibarProfileButtonFoundation.prototype, "profileButton", {
        get: function () {
            return this._showProfileButton;
        },
        set: function (value) {
            if (this._showProfileButton !== value) {
                this._showProfileButton = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    return OmnibarProfileButtonFoundation;
}());
export { OmnibarProfileButtonFoundation };
